import isEmpty from 'lodash/isEmpty';
import React, { useContext, useState } from 'react';

import { ConnectionTestStatusModal } from '../components/modals';
import { useDevicePage } from '../components/pages/use-device-page';
import { StandardModalContext } from '../contexts';
import { LocationDeviceContext } from '../contexts/location-device-context';
import { StoreContext } from '../store';
import { useFeatures } from './use-features';

export function useDiscardChangesModal() {
  const { store } = useContext(StoreContext);
  const { isEquipmentEnabled } = useFeatures();
  const { connectionStatus } = store.pages.sensors;
  const { openModal, hideModal } = useContext(StandardModalContext);
  const { setIsAddingDevice } = useContext(LocationDeviceContext);
  const { removeActiveForm } = useDevicePage();

  const [isInitialDiscard, setIsInitialDiscard] = useState(true);

  function getInitialDiscardModalBoolean() {
    return isInitialDiscard;
  }

  function setInitialDiscardModalBoolean(value: boolean) {
    setIsInitialDiscard(value);
  }

  function showDiscardChangesModal(locationUUID: string) {
    openModal({
      primaryButton: {
        content: 'Cancel',
        onClick: async () => {
          hideModal();
        },
      },
      secondaryButton: {
        content: 'Discard',
        onClick: () => {
          setIsInitialDiscard(true);
          setIsAddingDevice(false);
          removeActiveForm();
          hideModal();

          // do not show connection test status modal if connectionStatus store is empty

          if (
            isEmpty(connectionStatus) ||
            isEmpty(connectionStatus[locationUUID])
          ) {
            return;
          }

          openModal({
            header: 'Connection test status',
            disableCloseOnOutsideClick: true,
            removeCloseIcon: true,
            allContent: (
              <ConnectionTestStatusModal locationUUID={locationUUID} />
            ),
            width: '752px',
          });
        },
      },
      header: 'Discard changes?',
      content: `Are you sure you want to discard changes made to this ${
        isEquipmentEnabled ? 'equipment' : 'sensor'
      }?`,
      disableCloseOnOutsideClick: true,
      removeCloseIcon: true,
    });
  }

  return {
    showDiscardChangesModal,
    getInitialDiscardModalBoolean,
    setInitialDiscardModalBoolean,
  };
}
