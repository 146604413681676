/**
 * This file will handle different combinations
 * for triggerComparator and alertTypes
 * and which min/max temperature/humidity exists
 */

import { AlertNotifConfigPayload, AlertType } from '../../hooks';
import { EquipmentPayloadData } from '../equipment/equipment-service';

export const ALERT_TYPES_ARR: AlertType[] = ['temperature', 'humidity'];
export const COMPARATOR_ARR = ['<', '>'];

/**
 * HACK:
 * The new equipment and alert config form allows for multiple optional values
 *
 * Example scenario
 * 1. User sets MAX temp and MIN humid
 * 2. User sets the following alert config: Below, Temperature
 *
 * But there's no MIN temp so what should the app do?
 * So I decided to just fallback to the max or min in vice versa
 *
 * Reason, either min/max of temp/humid is always present
 * so it's safe to fallback to the other value
 */
export function determineTriggerValue(
  equipmentData: EquipmentPayloadData | null,
  triggerComparator: string,
  alertType: AlertType = 'temperature',
): number | undefined {
  if (!equipmentData) return undefined;

  let val;

  /**
   * * min humidity, above humid
   * * use temperature instead since no max humid value
   */
  const noMaxHumid = !equipmentData.meta.humidityMaxThreshold;
  if (triggerComparator === '>' && alertType === 'humidity' && noMaxHumid) {
    return equipmentData?.meta.temperatureMaxThreshold;
  }

  switch (triggerComparator) {
    case '>':
      if (alertType === 'temperature') {
        // for temperature, no need to worry for above since MAX temp is required
        val = equipmentData?.meta.temperatureMaxThreshold;
      } else if (alertType === 'humidity') {
        // fallback to MIN humidity if no MAX value is present
        val = equipmentData?.meta.humidityMaxThreshold;
      }
      break;
    case '<':
      if (alertType === 'temperature') {
        // MIN temp is optional, so use MAX temp if MIN is not present
        val = equipmentData?.meta.temperatureMinThreshold;
      } else if (alertType === 'humidity') {
        // fallback to MAX humidity if no MIN value is present
        val = equipmentData?.meta.humidityMinThreshold;
      }
      break;
  }

  return val;
}

/**
 * If user selected both temp and humid alert types
 *
 * @param doAlertConfigAdd
 * @param alertConfigFormVal
 * @param triggerComparator
 * @param equipmentData
 */
export async function createTempHumidAlerts(
  doAlertConfigAdd: (
    alertConfigFormVal: AlertNotifConfigPayload,
    isAddAnother: boolean,
  ) => Promise<void>,
  alertConfigFormVal: AlertNotifConfigPayload,
  triggerComparator: string,
  equipmentData: EquipmentPayloadData,
  isAddAnother = false,
) {
  const noMinHumid = !equipmentData.meta.humidityMinThreshold;
  const noMaxHumid = !equipmentData.meta.humidityMaxThreshold;

  const promises: Promise<void>[] = [];
  ALERT_TYPES_ARR.map((alertType: AlertType) => {
    // * do not create alert for below humidity if no max humidity
    if (noMinHumid && triggerComparator === '<' && alertType === 'humidity') {
      return;
    }

    // * do not create alert for above humidity if no max humidity
    if (noMaxHumid && triggerComparator === '>' && alertType === 'humidity') {
      return;
    }

    promises.push(
      doAlertConfigAdd(
        {
          ...alertConfigFormVal,
          field: alertType as AlertType,
          triggerValue: determineTriggerValue(
            equipmentData,
            triggerComparator,
            alertType as AlertType,
          ),
        },
        isAddAnother,
      ),
    );
  });
  await Promise.allSettled(promises);
}

/**
 * If user selected above or below trigger comparator
 *
 * @param doAlertConfigAdd
 * @param alertConfigFormVal
 * @param alertType
 * @param equipmentData
 */
export async function createAboveBelowAlerts(
  doAlertConfigAdd: (
    alertConfigFormVal: AlertNotifConfigPayload,
    isAddAnother: boolean,
  ) => Promise<void>,
  alertConfigFormVal: AlertNotifConfigPayload,
  equipmentData: EquipmentPayloadData,
  isAddAnother = false,
  alertType: AlertType = 'temperature',
) {
  const noMinTemp = !equipmentData.meta.temperatureMinThreshold;
  const noMinHumid = !equipmentData.meta.humidityMinThreshold;
  const noMaxHumid = !equipmentData.meta.humidityMaxThreshold;

  const promises: Promise<void>[] = [];
  COMPARATOR_ARR.map((comparator: string) => {
    // * do not create alert for below temperature if no min temperature
    if (noMinTemp && comparator === '<' && alertType === 'temperature') {
      return;
    }

    // * do not create alert for below humidity if no min humidity
    if (noMinHumid && comparator === '<' && alertType === 'humidity') {
      return;
    }

    // * do not create alert for above humidity if no max humidity
    if (noMaxHumid && comparator === '>' && alertType === 'humidity') {
      return;
    }

    promises.push(
      doAlertConfigAdd(
        {
          ...alertConfigFormVal,
          field: alertType,
          triggerComparator: comparator,
          triggerValue: determineTriggerValue(
            equipmentData,
            comparator,
            alertType,
          ),
        },
        isAddAnother,
      ),
    );
  });

  await Promise.allSettled(promises);
}

/**
 * If user selected both temp and humid alert types
 * AND
 * above or below trigger comparator
 *
 * @param doAlertConfigAdd
 * @param alertConfigFormVal
 * @param equipmentData
 */
export async function createAboveBelowTempHumidAlerts(
  doAlertConfigAdd: (
    alertConfigFormVal: AlertNotifConfigPayload,
    isAddAnother: boolean,
  ) => Promise<void>,
  alertConfigFormVal: AlertNotifConfigPayload,
  equipmentData: EquipmentPayloadData,
  isAddAnother = false,
) {
  const noMinTemp = !equipmentData.meta.temperatureMinThreshold;
  const noMinHumid = !equipmentData.meta.humidityMinThreshold;
  const noMaxHumid = !equipmentData.meta.humidityMaxThreshold;

  const promises: Promise<void>[] = [];
  ALERT_TYPES_ARR.forEach((alertType: AlertType) => {
    COMPARATOR_ARR.forEach((comparator: string) => {
      // * do not create alert config for below temp if no min temp
      if (noMinTemp && comparator === '<' && alertType === 'temperature') {
        return;
      }

      // * do not create alert config for below humid if no min humid
      if (noMinHumid && comparator === '<' && alertType === 'humidity') {
        return;
      }

      // * do not create alert config for above humid if no max humid
      if (noMaxHumid && comparator === '>' && alertType === 'humidity') {
        return;
      }

      promises.push(
        doAlertConfigAdd(
          {
            ...alertConfigFormVal,
            field: alertType,
            triggerComparator: comparator,
            triggerValue: determineTriggerValue(
              equipmentData,
              comparator,
              alertType,
            ),
          },
          isAddAnother,
        ),
      );
    });
  });
}
