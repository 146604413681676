import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { DropdownItemProps } from 'semantic-ui-react';

import { useLocationService } from '../../../hooks';
import { SearchedAutocomplete } from '../../../services';
import { EnergyDropdown } from '../../dropdown';

interface LocationSearchProps {
  address?: string;
  setAddressSearchCompleted: (value: boolean) => void;
}

const LocationSearchInput: React.FC<LocationSearchProps> = (props) => {
  const { address, setAddressSearchCompleted } = props;
  const { searchAutoComplete } = useLocationService();
  const formMethods = useFormContext();

  const [displayValue, setDisplayValue] = useState();

  const handleSearch = debounce(async (query: string) => {
    const resultsAutocomplete = await searchAutoComplete(query);
    const joinedResults = [...resultsAutocomplete, query];
    const options =
      joinedResults.length &&
      joinedResults.map((result) => {
        const { description, place_id } = result as SearchedAutocomplete;

        return {
          text: description,
          value: description,
          key: place_id,
        };
      });
    setSearchResults(
      options && options[0].key
        ? options
        : [{ text: query, value: query, key: Date.now() }],
    );
  }, 500);

  const defaultResult = {
    key: address,
    text: address,
    value: address,
  };

  const [searchResults, setSearchResults] = useState<DropdownItemProps[]>([
    defaultResult,
  ]);

  const filteredSearchResults = searchResults.filter((res) => {
    return res.key;
  });

  return (
    <EnergyDropdown
      label="Address line 1 *"
      search
      options={filteredSearchResults}
      onSearchChange={async ({ currentTarget }) => {
        const { value } = currentTarget as HTMLInputElement;
        handleSearch(value);
      }}
      defaultValue={address}
      text={displayValue}
      required
      icon=""
      onChange={(event, data) => {
        formMethods.setValue('line1', data.value);
        // FIXME: data.value is treated as a string but it's possible:
        // string | number | boolean | (string | number | boolean)[] | undefined
        /* eslint-disable-next-line */
        // @ts-ignore
        const lineSection = data.value.split(',', 1)[0];
        setDisplayValue(lineSection);
        setAddressSearchCompleted(false);
        return data.value;
      }}
    />
  );
};

export default LocationSearchInput;
