import moment from 'moment';
import React, { FunctionComponent } from 'react';

import { AxisTickProps } from './index';

export interface XAxisTickProps extends AxisTickProps {
  labels: { [key: number]: string };
}

function isDateCorrectFormat(value: string): boolean {
  return moment(value, 'MM/DD', true).isValid();
}

export const XAxisTick: FunctionComponent<XAxisTickProps> = (props) => {
  const { x, y = 0, index = 0, labels } = props;
  // do not create tick for empty x-labels
  if (!labels[index]) {
    return null;
  }
  return (
    <g transform={`translate(${x},${y + 20})`}>
      <text x={-20} y={0} dy={0}>
        {labels[index]}
      </text>
      {isDateCorrectFormat(labels[index]) && (
        <text x={-15} y={0} dy={23} fontSize="12px" fill="#939AA5">
          {moment(labels[index], 'MM/DD').format('ddd')}
        </text>
      )}
    </g>
  );
};
