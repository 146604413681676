import { ThermaText400 } from '@coinspect/ui';
import React, { FC } from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import StandardModalActions from './standard-modal-actions';
import { StandardModalContentScrollable } from './standard-modal-content-scrollable';

export interface StandardModalContentProps {
  content?: string | JSX.Element; // content of modal
  subContent?: string | JSX.Element; // content of modal
  scrollable?: boolean; // makes content body scrollable
  scrollablePadding?: string;
  children: React.ReactNode;
}

interface WrappedModalProps {
  widthsize?: string;
}

const WrappedModalContent = styled(Modal.Content)<WrappedModalProps>`
  &&& {
    padding: 0px !important;
    margin: 0px;
    color: ${ThermaText400};
    font-family: proxima-nova;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const SubContent = styled.div`
  margin-top: 20px;
`;
SubContent.displayName = 'SubContent';

const ContentWrapper = styled.div`
  margin-top: 20px;
`;
const Content: FC<{ children: React.ReactNode }> = (props) => (
  <div>{props.children}</div>
);
Content.displayName = 'Content';

const SubHeader = styled(SubContent)`
  font-size: 12px;
`;
SubHeader.displayName = 'SubHeader';

interface StandardModalComponentInterface
  extends FC<StandardModalContentProps> {
  SubHeader: typeof SubHeader;
  Content: typeof Content;
  SubContent: typeof SubContent;
  Actions: typeof StandardModalActions;
}

export const getChild = (children: React.ReactNode, displayName = '') => {
  const childFunc = displayName
    ? (child) => {
        return child?.type?.displayName === displayName;
      }
    : (child) => {
        return !child?.type;
      };

  return React.Children.map(children, (child) =>
    childFunc(child) ? child : null,
  );
};

const StandardModalBody: StandardModalComponentInterface = (props) => {
  const { children, scrollable = false, scrollablePadding } = props;

  const filteredChildren = React.Children.toArray(children).filter(Boolean);

  const subHeaderChild = getChild(filteredChildren, 'SubHeader');
  const subContentChild = getChild(filteredChildren, 'SubContent');
  const actionsChild = getChild(filteredChildren, 'Actions');
  const contentChild = getChild(filteredChildren, 'Content');

  return (
    <>
      <WrappedModalContent>
        {subHeaderChild}
        {contentChild &&
          !!contentChild.length &&
          (scrollable ? (
            <StandardModalContentScrollable padding={scrollablePadding}>
              {contentChild}
            </StandardModalContentScrollable>
          ) : (
            <ContentWrapper>{contentChild}</ContentWrapper>
          ))}
        {subContentChild}
      </WrappedModalContent>
      {actionsChild}
    </>
  );
};

StandardModalBody.SubHeader = SubHeader;
StandardModalBody.SubContent = SubContent;
StandardModalBody.Content = Content;
StandardModalBody.Actions = StandardModalActions;

export default StandardModalBody;
