import {
  NewFeatureModalTypes,
  NotificationMethod,
} from '@coinspect/atka-types';
import { PaginationParams } from '@coinspect/utils';
import { AxiosRequestConfig } from 'axios';
import firebase from 'firebase/app';

import { request, setToken, UserProfile } from '../contexts';
import { BaseEntity } from './index';

export type TempUnits = 'f' | 'c';

export enum Role {
  admin = 'admin',
  account_owner = 'account_owner',
  user = 'user',
}

export interface User {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  signedUpDate: string;
  passwordChangeDate: string;
  companyId: number;
  profileImg: string | null;
  role: Role;
  companyUUID: string;
  preferredTempUnit: TempUnits;
  firebaseUid: string;
  thermaGoal?: string;
  meta?: Record<string, unknown>;
  notificationMethods?: NotificationMethod[];
}

export interface UserProfileEdit {
  success: UserProfile;
  fail: {
    email: string;
    phoneNumber: string;
  };
  preferredTempUnit: TempUnits;
}

export interface UserAuth {
  idToken: string;
  inviteToken?: string;
  firstName?: string;
  lastName?: string;
}

export interface UserModel extends BaseEntity, User {}

interface AuthResponse {
  user: UserProfile;
  token: string;
}

export interface JWTResponse {
  token: string;
}

export interface UserModelProps {
  data: UserModel[];
  meta: PaginationParams;
}

export interface ThermaGoal {
  quizID: string;
  description: string;
}
export class UserService {
  public static async authenticateFirebaseUser(
    user: firebase.User,
    inviteToken?: string,
  ) {
    const { displayName } = user;
    const idToken = await user.getIdToken();
    let firstName = '';
    let lastName = '';

    if (displayName) {
      const splittedName = displayName.split(new RegExp(/\s+/));
      firstName = splittedName.slice(0, splittedName.length - 1).join(' ');
      lastName = splittedName[splittedName.length - 1];
    }

    return UserService.auth({
      firstName,
      idToken,
      inviteToken,
      lastName,
    });
  }

  public static async getUserJWT(userObj: UserProfile) {
    const { email, companyUUID, role } = userObj;
    const { data: result } = await request.post(
      '/api/account/user/user-company-jwt',
      {
        role,
        email,
        companyUUID,
      },
    );
    return result.data;
  }

  public static async getConfig(configKey: string) {
    const { data: result } = await request.get(
      `/api/account/user/config/${configKey}`,
    );
    return result.data;
  }

  public static async auth(authParams: UserAuth): Promise<AuthResponse> {
    const res = await request.post('/api/account/auth', authParams);
    const { token, user } = res.data.data;

    if (token) {
      setToken(token);
    }
    return { token, user };
  }

  // this function will get all of the users
  // pagination used for account owner on user panel
  public static async browseAllUsers(options?: PaginationParams) {
    const url = '/api/account/user';

    const { data: result } = await request.get(url, { params: options });
    return result;
  }

  // additional logic to only get users that share the same teams
  // and users with no teams
  public static async browseAccessibleUsers(options?: PaginationParams) {
    const url = '/api/account/user/accessible-users';

    const { data: result } = await request.get(url, { params: options });
    return result;
  }

  /**
   * Get the current authenticated user information.
   * @return { IUser } The user information.
   */
  public static async self(): Promise<UserModel> {
    const { data: result } = await request.get('/api/account/user/self');
    return result.data;
  }

  /**
   * Used for editing a user information.
   * @param {string} uuid UUID of a target user.
   * @param {IUserEditableProps} edits Information updates.
   * @param {AxiosRequestConfig} config Request configuration.
   * @return {Promise<UserProfileEdit>} Data object returned from the API call.
   */
  public static async edit(
    uuid: string,
    edits: User,
    config?: AxiosRequestConfig,
  ) {
    try {
      const { data: result } = await request.put(
        `/api/account/user/${uuid}`,
        edits,
        config,
      );
      return result.data;
    } catch (e) {
      return e.response.data;
    }
  }

  /**
   * Used for editing user meta.outoutdate
   * @param {IUserEditableProps} data
   * @return {Promise<UserProfileEdit>}
   */
  public static async setOptOutDate() {
    try {
      return (await request.put('/api/account/user/set-optout-date')).data.data;
    } catch (e) {
      return e.response.data;
    }
  }

  /**
   * Used for editing user meta.outoutdate
   * @param {IUserEditableProps} data
   * @return {Promise<UserProfileEdit>}
   */
  public static async setHasSeenNewFeatureModal(
    newFeatureModalName: NewFeatureModalTypes,
  ) {
    try {
      return (
        await request.put(
          `/api/account/user/has-seen-new-feature-modal/${newFeatureModalName}`,
        )
      ).data.data;
    } catch (e) {
      return e.response.data;
    }
  }

  /**
   * Used for editing user's own information
   * @param {IUserEditableProps} data
   * @return {Promise<UserProfileEdit>}
   */
  public static async editSelf(data: User) {
    try {
      return (await request.put('/api/account/user', data)).data.data;
    } catch (e) {
      return e.response.data;
    }
  }

  /**
   * Delete user
   * @param {string} uuid UUID of a target user.
   * @param {AxiosRequestConfig} config Request configuration.
   * @return {IUserDeleteProps} Data object returned from the API call.
   */
  public static async delete(uuid: string, config?: AxiosRequestConfig) {
    const { data: result } = await request.delete(
      `/api/account/user/${uuid}`,
      config,
    );
    return result.data;
  }

  /**
   * Updates user's password
   * @param {string} email email of the target user.
   * @param {string} password newPassword of the target user.
   * @return {IUserDeleteProps} Data object returned from the API call.
   */
  public static async updatePassword(email: string, password: string) {
    const { data: result } = await request.post('/api/account/user/password', {
      email,
      password,
    });

    return result.data;
  }

  /**
   * Manually update users passwordChangeDate value
   * @param {string} uid uid of the target user.
   * @return {IUserDeleteProps} Data object returned from the API call.
   */
  public static async updatePasswordChangeDate(uid = '', email = '') {
    const { data: result } = await request.post(
      '/api/account/user/password-change-date',
      {
        uid,
        email,
      },
    );

    return result.data;
  }

  /**
   *
   * @param {string} uid firebase uid of user
   * @returns just success message
   */
  public static async verifyFirebaseEmail(
    firebaseUUID: string,
    emailVerified: boolean,
  ) {
    const { data: result } = await request.post(
      '/api/account/user/verify-firebase-email',
      {
        firebaseUUID,
        emailVerified,
      },
    );

    return result.data;
  }

  public static async unenrollUser2fa(userUUID: string) {
    const { data: result } = await request.post(
      `/api/account/user/unenroll-2fa-login`,
      {
        userUUID,
      },
    );

    return result.data;
  }

  public static async insertThermaGoals(thermaGoals: ThermaGoal[]) {
    const { data: result } = await request.post(`/api/account/user/goals`, {
      thermaGoals,
    });

    return result.data;
  }
}

export const extendParams = (
  user: UserProfile,
  options?: PaginationParams,
): PaginationParams => {
  let extendedParams = {};

  if (user.email.includes('monitoring+')) {
    extendedParams = {
      ...extendedParams,
      include: 'monitoring',
    };
  }

  return { ...options, ...extendedParams };
};
