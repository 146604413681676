import { BaseEntity, request } from '../contexts';
import { getItemWithExpiration, setItemWithExpiration } from '../hooks';

export interface EnergyLocation {
  demandReponseProvider: string;
  demandResponseProviderId: string;
  devices?: string[];
  monitoringLocationUuid?: string;
  operatingHourEnd?: string;
  operatingHourStart?: string;
  utilityProvider?: string;
  utilityProviderId?: string;
  metadata?: Metadata;
}

export interface OverridesObject {
  time: Date;
  device_uuid: string;
  reason: string;
  revertDelay: string | null;
  state: string;
  temperature: string | null;
  temperatureDelta: string | null;
  useDirectSetPoint: boolean;
  vendor: string;
  vendorId: string;
  vendorModel: string;
}

export type Metadata = {
  occupiedCoolSetPoint?: number;
  occupiedHeatSetPoint?: number;
  unoccupiedCoolSetPoint?: number;
  unoccupiedHeatSetPoint?: number;
  name: string;
};

export interface EnergyLocationModel extends BaseEntity, EnergyLocation {}

export const RAW_SAVINGS_CACHE = 'RAW_SAVINGS_CACHE';
export const OVERRIDES_CACHE = 'OVERRIDES_CACHE';

export class EnergyLocationService {
  public static async get(
    locationUUIDs: string[],
    sort: { field: string; order: string } = {
      field: 'location',
      order: 'asc',
    },
  ) {
    const { data } = locationUUIDs.length
      ? await request({
          method: 'POST',
          url: '/energy-locations/dashboard/by-monitoring-location/',
          baseURL:
            process.env.ENERGY_GATEWAY_DOMAIN ??
            'https://gateway.energy.dev.helloatka.com',
          data: {
            monitoringLocationUUIDs: locationUUIDs,
          },
          params: {
            sort: sort.field,
            order: sort.order,
          },
        })
      : {
          data: [],
        };

    const allDevices = data.reduce((accumulator, current) => {
      const devicesWithLocation = current.devices.map((device) => {
        return {
          ...device,
          monitoringLocationUuid: current.monitoringLocationUuid,
        };
      });
      return [...accumulator, ...devicesWithLocation];
    }, []);

    return allDevices;
  }

  public static async getLocations({
    monitoringLocationUUIDs,
  }: {
    monitoringLocationUUIDs: string[];
  }) {
    try {
      const { data } = await request({
        method: 'POST',
        url: `/energy-locations/by-monitoring-locations`,
        baseURL:
          process.env.ENERGY_GATEWAY_DOMAIN ??
          'https://gateway.energy.dev.helloatka.com',
        data: {
          monitoringLocationUUIDs,
        },
      });
      return data;
    } catch (e) {
      return [];
    }
  }
  public static async override({
    deviceUUID,
    active,
  }: {
    deviceUUID: string;
    active: boolean;
  }) {
    const { data } = await request({
      method: 'POST',
      url: `/device/toggle-active/${deviceUUID}`,
      baseURL:
        process.env.ENERGY_GATEWAY_DOMAIN ??
        'https://gateway.energy.dev.helloatka.com',
      data: {
        active,
      },
    });
    return data;
  }

  public static async getEnergyOverridesByLocation(
    monitoringLocationUUID: string,
  ): Promise<OverridesObject[]> {
    /**
     * Using the same caching strategy as the raw savings logic
     */
    const PER_KEY_CACHING = `${OVERRIDES_CACHE}_${monitoringLocationUUID}`;
    const cachedData = getItemWithExpiration(PER_KEY_CACHING);

    if (cachedData) {
      return cachedData;
    }

    const { data } = await request({
      method: 'GET',
      url: `/energy-locations/override-events-by-location/${monitoringLocationUUID}`,
      baseURL:
        process.env.ENERGY_GATEWAY_DOMAIN ??
        'https://gateway.energy.dev.helloatka.com',
    });

    setItemWithExpiration({
      key: PER_KEY_CACHING,
      value: data,
      expireMinutes: 5,
    });

    return data;
  }

  public static async browseHvacGraph({
    equipmentUUID,
    startTime,
    endTime,
  }: {
    equipmentUUID: string;
    startTime: string;
    endTime: string;
  }) {
    try {
      const { data } = await request({
        method: 'GET',
        url: `/data/hvac/${equipmentUUID}`,
        baseURL:
          process.env.ENERGY_GATEWAY_DOMAIN ??
          'https://gateway.energy.dev.helloatka.com',
        params: {
          startTime,
          endTime,
        },
      });
      return data;
    } catch (e) {
      return [];
    }
  }

  public static async updateHvacDevice({
    deviceUUID,
    metadata,
  }: {
    deviceUUID: string;
    metadata: Metadata;
  }) {
    const { data } = await request({
      method: 'PATCH',
      url: `/device/${deviceUUID}`,
      baseURL:
        process.env.ENERGY_GATEWAY_DOMAIN ??
        'https://gateway.energy.dev.helloatka.com',
      data: {
        metadata,
      },
    });
    return data;
  }
}
