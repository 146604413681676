import { FeatureFlag } from '@coinspect/feature-flag';

import { User } from '../../services';

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface BaseEntity {
  id: number;
  uuid: string;
  createdAt: string | Date;
  updatedAt: string;
  deletedAt?: string | null;
}

export interface Feature {
  name: string;
  enabled: boolean;
  deletedAt: string;
}

export interface Company extends BaseEntity {
  coinspectUrl?: string | null;
  name: string;
  features: Feature[] | null;
  customerSource: 'direct' | 'ecomm';
}

export interface UserProfile extends User, BaseEntity {
  company: Company;
  teams?: Array<Record<string, unknown>>;
}

export enum Features {
  PASSWORD_ROTATION = 'PASSWORD_ROTATION',
  TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION',
  AUDIT_LOG = 'AUDIT_LOG',
  DRAG_DROP_ALERTS = 'DRAG_DROP_ALERTS',
  GET_MORE_SENSORS = 'GET_MORE_SENSORS',
  EQUIPMENT_PAGE = 'EQUIPMENT_PAGE',
  ENERGY_DASHBOARD = 'ENERGY_DASHBOARD',
}

export { FeatureFlag };
