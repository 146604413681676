import { useContext } from 'react';

import { StandardModalContext } from '../contexts';

export interface DialogConfig {
  content: string | JSX.Element;
  onConfirm?: () => void;
  onCancel?: () => void;
  disableButtonsOnQuery?: string;
  header?: string;
}

export const useDialogs = () => {
  const { openModal, hideModal } = useContext(StandardModalContext);

  function openAlertDialog({
    content,
    onConfirm,
    disableButtonsOnQuery = '',
    header,
  }: DialogConfig) {
    openModal({
      content, // content of modal
      header,
      disableButtonsOnQuery,
      primaryButton: {
        content: 'Got it',
        onClick: () => {
          if (onConfirm) {
            onConfirm();
          }
          hideModal();
        },
      },
    });
  }

  function openDeleteDialog({
    content,
    onCancel,
    onConfirm,
    disableButtonsOnQuery = '',
  }: DialogConfig) {
    openModal({
      content, // content of modal
      header: 'Delete', // header of modal || else element not rendered
      disableButtonsOnQuery,
      primaryButton: {
        content: 'Delete',
        onClick: () => {
          if (onConfirm) {
            onConfirm();
          }
        },
      },
      secondaryButton: {
        content: 'Cancel',
        onClick: () => {
          if (onCancel) {
            onCancel();
          }
          hideModal();
        },
      },
    });
  }

  return {
    closeDialog: hideModal,
    openDeleteDialog,
    openAlertDialog,
  };
};
