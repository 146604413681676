/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';

//TODO: Restore React.StrictMode when home redirect is fixed

ReactDOM.createRoot(document.getElementById('coinspect')!).render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);
