import { darkViolet80 } from '@coinspect/ui';
import React from 'react';

export const equipmentInputLabelStyle: React.CSSProperties = {
  paddingLeft: '10px',
  color: darkViolet80,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  fontSize: '14px',
};
