import { arrayRemoveItem } from 'common-utils-pkg';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Media } from 'src/contexts/app-media';

import { StandardModalContext } from '../../../contexts';
import {
  TroubleshootSectionType,
  useDeviceService,
  useSetInterval,
  useTroubleshootingModal,
} from '../../../hooks';
import { DataStoreAction, StoreContext } from '../../../store';
import { SensorPageActions } from '../../../store/reducers';
import StandardModalBody from '../standard-modal-body';
import { ConnectionStatusMobile } from './connection-status-mobile';
import { ConnectionStatusTable } from './connection-status-table';
import { TestingResult } from './status-badge';
import { SignalTestingStatus } from './status-result';

const SIGNAL_TEST_RESULT_POLLING_TIME = 10000; // 10s

export const clearConnectionStatusLocation = (
  dispatch: (action: DataStoreAction) => void,
  locationUUID: string,
) => {
  dispatch({
    type: SensorPageActions.connectionStatusClearLocation,
    data: {
      locationUUID,
    },
  });
};

type ConnectionTestStatusModalProps = {
  locationUUID: string;
};

export const ConnectionTestStatusModal: React.FC<ConnectionTestStatusModalProps> = ({
  locationUUID,
}) => {
  const { showTroubleShootingModal } = useTroubleshootingModal();
  const { hideModal, openModal } = useContext(StandardModalContext);
  const { store, dispatch } = useContext(StoreContext);
  const { getSignalTestResult } = useDeviceService();
  const { connectionStatus, connectionTestStartDate } = store.pages.sensors;
  const [isAllStatusGood, setIsAllStatusGood] = useState(false);
  const [goodStatusDeviceUUIDs, setGoodStatusDeviceUUIDs] = useState<string[]>(
    [],
  );

  if (isEmpty(connectionStatus) || isEmpty(connectionStatus[locationUUID])) {
    return null;
  }

  const locationDevicesConnectionStatus = connectionStatus[locationUUID];

  const pollSignalTest = async () => {
    let deviceUUIDs: string[] = [];

    // poll each deviceUUID in the connectionStatus store for this location
    Object.keys(locationDevicesConnectionStatus).forEach(
      (deviceUUID: string) => {
        const deviceData = locationDevicesConnectionStatus[deviceUUID];
        if (isTestingDone(deviceData.testingStatus)) {
          deviceUUIDs = arrayRemoveItem(deviceUUIDs, deviceUUID);
          return;
        }
        deviceUUIDs.push(deviceUUID);
      },
    );

    // if no more devices to poll
    if (deviceUUIDs.length === 0) {
      return;
    }
    const { devices } = await getSignalTestResult(
      deviceUUIDs,
      connectionTestStartDate as Date,
    );
    devices.forEach(
      (deviceConnection: {
        deviceUUID: string;
        result: TestingResult;
        testingStatus: SignalTestingStatus;
      }) => {
        dispatch({
          type: SensorPageActions.connectionStatusResult,
          data: {
            deviceUUID: deviceConnection.deviceUUID,
            locationUUID:
              store.entities.devices.byUUID[deviceConnection.deviceUUID]
                .locationUUID,
            result: {
              testingResult: deviceConnection.result,
              testingStatus: deviceConnection.testingStatus,
              isTesting: !isTestingDone(deviceConnection.testingStatus),
            },
          },
        });
      },
    );
  };

  useEffect(() => {
    if (
      goodStatusDeviceUUIDs.length ===
      Object.keys(locationDevicesConnectionStatus).length
    ) {
      setIsAllStatusGood(true);
    }
  }, [goodStatusDeviceUUIDs]);

  useEffect(() => {
    if (isEmpty(connectionStatus) || isEmpty(connectionStatus[locationUUID])) {
      return;
    }

    Object.keys(locationDevicesConnectionStatus).forEach(
      (deviceUUID: string) => {
        const deviceData = locationDevicesConnectionStatus[deviceUUID];

        if (
          deviceData.testingResult === 'Good' &&
          !goodStatusDeviceUUIDs.includes(deviceUUID)
        ) {
          setGoodStatusDeviceUUIDs([...goodStatusDeviceUUIDs, deviceUUID]);
        }
      },
    );
  }, [locationDevicesConnectionStatus]);

  // check for result as soon as modal is loaded since result could be ready already
  useEffect(() => {
    pollSignalTest();
  }, []);

  // poll for hardware connection test status result every 10s
  useSetInterval(() => {
    pollSignalTest();
  }, SIGNAL_TEST_RESULT_POLLING_TIME);

  return (
    <>
      <StandardModalBody scrollable>
        <StandardModalBody.Content>
          <Media lessThan={'tablet'}>
            <ConnectionStatusTable
              connectionStatusObj={locationDevicesConnectionStatus}
            />
          </Media>

          <Media lessThan={'mobile'}>
            <ConnectionStatusMobile
              connectionStatusObj={locationDevicesConnectionStatus}
            />
          </Media>
        </StandardModalBody.Content>

        {isAllStatusGood ? (
          <StandardModalBody.Actions
            primaryButton={{
              content: 'Done',
              onClick: () => {
                hideModal();
                clearConnectionStatusLocation(dispatch, locationUUID);
              },
            }}
          />
        ) : (
          <StandardModalBody.Actions
            primaryButton={{
              content: 'Help me improve my connection',
              onClick: () =>
                showTroubleShootingModal(
                  TroubleshootSectionType.hubs,
                  locationUUID,
                ),
            }}
            secondaryButton={{
              content: 'Done',
              onClick: () => {
                hideModal();
                openModal({
                  header: 'Are you sure you want to leave?',
                  content: 'If you leave now, your results will not be saved.',
                  disableCloseOnOutsideClick: true,
                  removeCloseIcon: true,
                  primaryButton: {
                    content: 'Back to results',
                    onClick: () => {
                      hideModal();
                      openModal({
                        header: 'Connection test status',
                        disableCloseOnOutsideClick: true,
                        removeCloseIcon: true,
                        allContent: (
                          <ConnectionTestStatusModal
                            locationUUID={locationUUID}
                          />
                        ),
                        width: '752px',
                        onClose: () => {
                          clearConnectionStatusLocation(dispatch, locationUUID);
                        },
                      });
                    },
                  },
                  secondaryButton: {
                    content: 'Leave',
                    onClick: () => {
                      hideModal();
                      clearConnectionStatusLocation(dispatch, locationUUID);
                    },
                  },
                });
              },
            }}
          />
        )}
      </StandardModalBody>
    </>
  );
};

export function isTestingDone(testingStatus: string) {
  return ['done', 'join_failed', 'testing_failed'].includes(testingStatus);
}
