import * as React from 'react';

// check if element is overflow
export const useIsOverflow = (
  ref: React.MutableRefObject<undefined>,
  callback = null,
) => {
  const [isOverflow, setIsOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) trigger();
  }, [callback, ref]);

  return isOverflow;
};
