import React, { FunctionComponent } from 'react';

import { REPORT_TYPES, ReportConfigType } from '../../../../services';

export const SentAlertsLogSvg: FunctionComponent = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_282_2804)">
        <path
          d="M44.5033 32.8125V39.0625"
          stroke="#6C757D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.60379 39.0625V17.1875C4.60379 16.3587 4.92716 15.5638 5.50274 14.9778C6.07833 14.3917 6.85899 14.0625 7.67299 14.0625H15.346"
          stroke="#6C757D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.8408 39.0625C29.3082 40.0116 28.5387 40.8005 27.6101 41.3492C26.6816 41.8978 25.6271 42.1868 24.5536 42.1868C23.48 42.1868 22.4255 41.8978 21.497 41.3492C20.5685 40.8005 19.7989 40.0116 19.2664 39.0625H1.5346V42.1875C1.5346 43.8451 2.18132 45.4348 3.33249 46.6069C4.48366 47.779 6.04499 48.4375 7.67299 48.4375H41.4341C43.0621 48.4375 44.6235 47.779 45.7746 46.6069C46.9258 45.4348 47.5725 43.8451 47.5725 42.1875V39.0625H29.8408Z"
          stroke="#6C757D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.1767 2.10625C35.7692 1.37373 33.2049 1.3662 30.7933 2.08457C28.3816 2.80294 26.2257 4.21652 24.5855 6.15474C22.9454 8.09297 21.8912 10.473 21.55 13.0077C21.2089 15.5425 21.5954 18.1236 22.6629 20.4396L18.4418 29.1667L28.1036 26.4792C29.7087 27.3946 31.4856 27.9546 33.318 28.1226C35.1503 28.2906 36.9969 28.0628 38.7368 27.4541C40.4767 26.8454 42.0708 25.8694 43.4148 24.5902C44.7587 23.311 45.8222 21.7572 46.5358 20.0306C47.2493 18.304 47.5967 16.4435 47.5554 14.5707C47.514 12.698 47.0847 10.8552 46.2957 9.16303C45.5066 7.47085 44.3755 5.96736 42.9764 4.75095C41.5773 3.53453 39.9418 2.6326 38.1767 2.10416V2.10625Z"
          stroke="#6BA4B8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.5285 18.8167C34.6802 18.8167 34.8286 18.8625 34.9547 18.9483C35.0809 19.0342 35.1793 19.1562 35.2373 19.2989C35.2954 19.4417 35.3106 19.5988 35.281 19.7503C35.2514 19.9019 35.1783 20.0411 35.071 20.1503C34.9637 20.2596 34.827 20.334 34.6781 20.3641C34.5293 20.3943 34.375 20.3788 34.2348 20.3197C34.0946 20.2606 33.9748 20.1604 33.8905 20.0319C33.8062 19.9035 33.7612 19.7524 33.7612 19.5979C33.7609 19.4952 33.7805 19.3935 33.819 19.2986C33.8575 19.2037 33.914 19.1175 33.9853 19.0449C34.0566 18.9723 34.1413 18.9147 34.2345 18.8756C34.3277 18.8364 34.4276 18.8164 34.5285 18.8167"
          stroke="#6BA4B8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.5285 14.1292V7.87915"
          stroke="#6BA4B8"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_282_2804">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H45.1071C47.3163 0 49.1071 1.79086 49.1071 4V46C49.1071 48.2091 47.3163 50 45.1071 50H4C1.79086 50 0 48.2091 0 46V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TemperatureLogbookSvg: FunctionComponent = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_282_2817)">
        <path
          d="M47.4395 12.8396L41.1088 19.3104C40.921 19.5122 40.6851 19.661 40.4241 19.7424C40.1631 19.8238 39.8858 19.8351 39.6192 19.775L32.6624 18.1479C32.3747 18.0849 32.0756 18.1037 31.7977 18.2022C31.5199 18.3006 31.2739 18.475 31.0869 18.7063L23.019 28.125"
          stroke="#6C757D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.8806 32.1208V7.8125C16.8806 6.1549 16.2339 4.56518 15.0827 3.39308C13.9315 2.22098 12.3702 1.5625 10.7422 1.5625C9.11418 1.5625 7.55286 2.22098 6.40169 3.39308C5.25052 4.56518 4.60379 6.1549 4.60379 7.8125V32.1208C3.46497 33.1653 2.60067 34.483 2.08954 35.9542C1.5784 37.4255 1.43664 39.0035 1.67715 40.5447C1.91766 42.0859 2.5328 43.5415 3.46664 44.7789C4.40047 46.0164 5.62334 46.9965 7.02403 47.6301C8.42471 48.2637 9.95875 48.5306 11.4866 48.4067C13.0145 48.2828 14.4876 47.7719 15.7721 46.9205C17.0566 46.0691 18.1117 44.9042 18.8413 43.5318C19.5709 42.1594 19.952 40.6231 19.9498 39.0625C19.947 37.7506 19.6722 36.4542 19.1433 35.2579C18.6144 34.0617 17.8433 32.9927 16.8806 32.1208Z"
          stroke="#6C757D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.7422 42.1875C12.4373 42.1875 13.8114 40.7884 13.8114 39.0625C13.8114 37.3366 12.4373 35.9375 10.7422 35.9375C9.04712 35.9375 7.67299 37.3366 7.67299 39.0625C7.67299 40.7884 9.04712 42.1875 10.7422 42.1875Z"
          stroke="#6BA4B8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.0882 39.0625H29.1574"
          stroke="#6BA4B8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.2958 39.0625H38.365"
          stroke="#6BA4B8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.5033 39.0625H47.5725"
          stroke="#6BA4B8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_282_2817">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H45.1071C47.3163 0 49.1071 1.79086 49.1071 4V46C49.1071 48.2091 47.3163 50 45.1071 50H4C1.79086 50 0 48.2091 0 46V4Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const HumidityLogbookSvg: FunctionComponent = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.2937 12.5784L41.5198 18.752C41.3782 18.9445 41.2003 19.0864 41.0035 19.1641C40.8066 19.2418 40.5975 19.2525 40.3965 19.1952L35.1505 17.6429C34.9336 17.5828 34.708 17.6007 34.4985 17.6946C34.2889 17.7886 34.1035 17.955 33.9624 18.1756L27.8785 27.1617"
        stroke="#6C757D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.9016 38.8015H30.9477"
        stroke="#6BA4B8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.0631 38.8015H38.1092"
        stroke="#6BA4B8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.2245 38.8015H45.2706"
        stroke="#6BA4B8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5305 31.5581C23.5305 34.3553 22.4392 37.0379 20.4966 39.0158C18.554 40.9938 15.9192 42.105 13.172 42.105C10.4247 42.105 7.78998 40.9938 5.84738 39.0158C3.90477 37.0379 2.81343 34.3553 2.81343 31.5581C2.81343 23.9487 9.63932 12.4768 12.2082 8.43308C12.3116 8.26822 12.454 8.13253 12.6225 8.03853C12.7909 7.94454 12.9799 7.89526 13.172 7.89526C13.364 7.89526 13.553 7.94454 13.7215 8.03853C13.8899 8.13253 14.0324 8.26822 14.1357 8.43308C16.7046 12.4768 23.5305 23.9487 23.5305 31.5581Z"
        stroke="#6C757D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.172 37.4222C12.4159 37.4222 11.6672 37.2705 10.9687 36.9757C10.2702 36.6809 9.63556 36.2489 9.10112 35.7043C8.56669 35.1598 8.14289 34.5133 7.85396 33.8018C7.56503 33.0904 7.41662 32.328 7.41723 31.5581"
        stroke="#6BA4B8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const MeanKineticTemperatureLogSvg: FunctionComponent = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 7.8124L34.375 4.6499H45.3125"
        stroke="#6BA4B8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5 1.5625L34.375 4.65"
        stroke="#6BA4B8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 48.4374L15.625 45.2749H4.6875"
        stroke="#6BA4B8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 42.1875L15.625 45.275"
        stroke="#6BA4B8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4167 21.875L39.5833 21.875C40.1586 21.875 40.625 21.4086 40.625 20.8333V15.1042C40.625 14.5289 40.1586 14.0625 39.5833 14.0625L10.4167 14.0625C9.84137 14.0625 9.375 14.5289 9.375 15.1042V20.8333C9.375 21.4086 9.84137 21.875 10.4167 21.875Z"
        stroke="#6C757D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1042 35.9375H34.8958C35.4711 35.9375 35.9375 35.4711 35.9375 34.8958V29.1667C35.9375 28.5914 35.4711 28.125 34.8958 28.125L15.1042 28.125C14.5289 28.125 14.0625 28.5914 14.0625 29.1667V34.8958C14.0625 35.4711 14.5289 35.9375 15.1042 35.9375Z"
        stroke="#6C757D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 35.9375V48.4375"
        stroke="#6C757D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 21.875V28.125"
        stroke="#6C757D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 1.5625V14.0625"
        stroke="#6C757D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const AuditLogSvg: FunctionComponent = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_282_2866)">
        <path
          d="M31.528 42C37.327 42 42.028 37.299 42.028 31.5C42.028 25.701 37.327 21 31.528 21C25.729 21 21.028 25.701 21.028 31.5C21.028 37.299 25.729 42 31.528 42Z"
          stroke="#6BA4B8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.5 46.5001L38.952 38.9241"
          stroke="#6BA4B8"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 40.4719H4.5C4.10334 40.4818 3.70881 40.411 3.34036 40.2637C2.97192 40.1164 2.63725 39.8958 2.35668 39.6152C2.07611 39.3347 1.8555 39 1.70823 38.6316C1.56096 38.2631 1.49012 37.8686 1.5 37.4719V7.68592C1.47247 6.86212 1.77288 6.06105 2.33531 5.45849C2.89775 4.85594 3.67626 4.50113 4.5 4.47192H34.5C35.3237 4.50113 36.1023 4.85594 36.6647 5.45849C37.2271 6.06105 37.5275 6.86212 37.5 7.68592V16.4719"
          stroke="#6C757D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5 1.5V10.472"
          stroke="#6C757D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5 1.5V10.472"
          stroke="#6C757D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.5 1.5V10.472"
          stroke="#6C757D"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_282_2866">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const OverridesReportSvg: FunctionComponent = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.5125 21.1667C43.2069 21.1667 47.0124 17.3611 47.0124 12.6667C47.0124 7.97227 43.2069 4.16669 38.5125 4.16669C33.8181 4.16669 30.0125 7.97227 30.0125 12.6667C30.0125 17.3611 33.8181 21.1667 38.5125 21.1667Z"
        stroke="#6BA4B8"
        strokeWidth="2.60417"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5125 8.41669V16.9167"
        stroke="#6BA4B8"
        strokeWidth="2.60417"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2623 12.6667H42.7623"
        stroke="#6BA4B8"
        strokeWidth="2.60417"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7625 32.1208V7.8125C19.7625 6.1549 19.104 4.56518 17.9319 3.39308C16.7598 2.22098 15.1701 1.5625 13.5125 1.5625C11.8549 1.5625 10.2652 2.22098 9.0931 3.39308C7.92099 4.56518 7.26251 6.1549 7.26251 7.8125V32.1208C6.10298 33.1653 5.22297 34.483 4.70254 35.9542C4.18211 37.4255 4.03777 39.0035 4.28265 40.5447C4.52753 42.0859 5.15387 43.5415 6.10468 44.7789C7.05549 46.0164 8.3006 46.9965 9.72675 47.6301C11.1529 48.2637 12.7148 48.5306 14.2705 48.4067C15.8261 48.2828 17.3261 47.7719 18.6339 46.9205C19.9417 46.0691 21.016 44.9042 21.7589 43.5318C22.5018 42.1594 22.8897 40.6231 22.8875 39.0625C22.8847 37.7506 22.6049 36.4542 22.0664 35.2579C21.5278 34.0617 20.7428 32.9927 19.7625 32.1208V32.1208Z"
        stroke="#6C757D"
        strokeWidth="3.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5125 10.9375V35.9375"
        stroke="#6C757D"
        strokeWidth="3.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5125 42.1875C15.2383 42.1875 16.6375 40.7884 16.6375 39.0625C16.6375 37.3366 15.2383 35.9375 13.5125 35.9375C11.7866 35.9375 10.3875 37.3366 10.3875 39.0625C10.3875 40.7884 11.7866 42.1875 13.5125 42.1875Z"
        stroke="#6C757D"
        strokeWidth="3.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5125 46.1667C43.2069 46.1667 47.0124 42.3611 47.0124 37.6667C47.0124 32.9723 43.2069 29.1667 38.5125 29.1667C33.8181 29.1667 30.0125 32.9723 30.0125 37.6667C30.0125 42.3611 33.8181 46.1667 38.5125 46.1667Z"
        stroke="#6BA4B8"
        strokeWidth="2.60417"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2623 37.6666H42.7623"
        stroke="#6BA4B8"
        strokeWidth="2.60417"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ReportTemplateSvg = ({
  type,
}: {
  type: ReportConfigType;
}): JSX.Element => {
  switch (type) {
    case REPORT_TYPES.SENT_ALERTS:
      return (
        <SentAlertsLogSvg className="report-template__sent-alert-logs--icon" />
      );
    case REPORT_TYPES.LOGBOOK_TEMPERATURE:
      return (
        <TemperatureLogbookSvg className="report-template__temperature-logbook--icon" />
      );
    case REPORT_TYPES.LOGBOOK_HUMIDITY:
      return (
        <HumidityLogbookSvg className="report-template__humidity-logbook--icon" />
      );
    case REPORT_TYPES.MEAN_KINETIC_TEMPERATURE:
      return (
        <MeanKineticTemperatureLogSvg className="report-template__mean-kinetic-log--icon" />
      );
    case REPORT_TYPES.AUDIT_LOG:
      return <AuditLogSvg className="report-template__audit-log--icon" />;
    case REPORT_TYPES.OVERRIDES_REPORT:
      return (
        <OverridesReportSvg className="report-template__overrides-report--icon" />
      );
    default:
      return <></>;
  }
};
