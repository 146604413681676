import { ButtonContainer, FormInput, OpenInput } from '@coinspect/ui';
import { arrayToSentence } from '@coinspect/utils';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  AccountContext,
  ModalContext,
  StandardModalContext,
} from '../../contexts';
import { useReportConfigService, useTimeFilter } from '../../hooks';
import { downloadFile } from '../../lib';
import {
  CreateReportConfig,
  LocationModel,
  REPORT_FORM_BREAKPOINT,
  REPORT_SCHEDULE,
  REPORT_TITLES,
  REPORT_TYPES,
  ReportConfigSchedule,
  ReportConfigType,
  ReportProps,
  TemperatureUnit,
  UserModel,
} from '../../services';
import { StoreContext } from '../../store';
import { reportNextDeliveryDate } from '../../utils/report-next-delivery-date';
import { IconOutsideField } from '../icon-outside-field';
import { ReportsTimeFilter } from '../reports/reports-time-filter';
import { SpecialTruncatedDropdown } from '../special-truncated-dropdown';
import { Toggle } from '../toggle';
import { ReportTemplateSvg } from '../ui/images/svgs';
import { HiddenTextArea } from './hidden-text-area';
import {
  ReportDeliveryOptions,
  ReportScheduleSelector,
} from './report-schedule-selection';

const FormStyled = styled(Form)`
  @media only screen and (min-width: ${REPORT_FORM_BREAKPOINT}) {
    margin-left: 60px;
    margin-top: 25px;
  }
`;

export type ReportFormProps = {
  fields?: ReportProps;
  className?: string;
  validationOnLoad?: boolean;
  preferredTempUnit?: TemperatureUnit;
};

export type LocationMeta = {
  uuid: string;
  name: string;
};

const generateDefaultReportName = (type: ReportConfigType) => {
  const date = moment().format('MM/DD/YYYY');
  return `${REPORT_TITLES[type]}_${date}`;
};

const ReportForm: FunctionComponent<ReportFormProps> = (props) => {
  const { store } = useContext(StoreContext);
  const { user } = useContext(AccountContext);
  const { hideModal } = useContext(ModalContext);
  const { hideModal: hideStandardModal, openModal } = useContext(
    StandardModalContext,
  );
  const {
    dailyButtonTimeFrame,
    weeklyButtonTimeFrame,
    biweeklyButtonTimeFrame,
    monthlyButtonTimeFrame,
    calculateInitialDeliveryDate,
  } = useTimeFilter();
  const { locations } = store.entities;
  const {
    users: { allUsers },
    reports: {
      reportForm: { type, fields },
    },
  } = store.pages;
  const { isQueryInProgress } = store.pages;
  const { dateRange } = store.pages.reports;
  const {
    addReportConfig,
    editReportConfig,
    resetDateRange,
    getLatestReportHistory,
    setPaginationMetadata,
    paginationMetadata,
    getScheduleDateRange,
  } = useReportConfigService();
  const isEditing = Boolean(fields);
  const reportType = (fields?.type ?? type) as ReportConfigType;

  const userOptions = allUsers.all
    .map((userUUID: string) => {
      const _user = allUsers.byUUID[userUUID];

      if (!_user.signedUpDate) {
        return;
      }

      return {
        key: userUUID,
        text: `${_user.firstName} ${_user.lastName}`,
        value: userUUID,
      };
    })
    .filter(Boolean);

  const locationOptions = locations.all.map((locationUUID: string) => {
    const location = locations.byUUID[locationUUID];
    return {
      key: locationUUID,
      text: location.name,
      value: locationUUID,
    };
  });

  const { className, validationOnLoad = false } = props;

  const newReport: ReportProps = {
    name: generateDefaultReportName(reportType),
    locations: [],
    recipients: [user?.uuid as string],
    schedule: 'once',
    createdAt: new Date().toISOString(),
    meta: {
      temperatureUnit: user?.preferredTempUnit ?? 'f',
      startDate: '',
      endDate: '',
    },
    description: '',
    type: reportType,
  };

  const [isPollingDownloadUrl, setPollingDownloadUrl] = useState(false);
  const [hasDeletedLocation, setHasDeletedLocation] = useState(false);
  const [deletedLocationsError, setDeletedLocationsError] = useState('');
  // const [isFetchingUserLocations, setFetchingUserLocations] = useState(true);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  const reportData = fields ? { ...fields } : newReport;
  const methods = useForm<ReportProps>({
    defaultValues: reportData,
    mode: 'onChange',
  });
  const selectedSchedule = methods.watch('schedule') as ReportConfigSchedule;
  const selectedLocation = methods.watch('locations');
  const selectedTemperature =
    methods.watch('meta.temperatureUnit') || user?.preferredTempUnit || 'f';
  const nameVal = methods.watch('name');
  const dateRangeVal = methods.watch('dateRangeDummy');
  const recipientsVal = methods.watch('recipients');
  const isScheduleOnce =
    selectedSchedule === 'once' || fields?.schedule === 'once';
  let selectedDeliveryTime = fields?.deliveryTime ? fields.deliveryTime : '';

  // Convert legacy RunTime to deliveryTime (rounding to nearest quarter hour)
  if (!fields?.deliveryTime && fields?.runTime) {
    const dateTime = moment();
    const twentyFourHourFormat = moment(fields.runTime, 'HH:mm').format(
      'HH:mm A',
    );
    const hour = twentyFourHourFormat.split(':')[0];
    const mins = twentyFourHourFormat.split(':')[1].split(' ')[0];
    const roundedMins = (Math.round(parseInt(mins) / 15) * 15) % 60;
    dateTime.set({
      hour: parseInt(hour),
      minute: roundedMins,
      second: 0,
      millisecond: 0,
    });
    selectedDeliveryTime = dateTime.format('hh:mm A');
  }

  const timePresets = (() => {
    switch (selectedSchedule) {
      case REPORT_SCHEDULE.DAILY:
        return dailyButtonTimeFrame();
      case REPORT_SCHEDULE.WEEKLY:
        return weeklyButtonTimeFrame();
      case REPORT_SCHEDULE.BI_WEEKLY:
        return biweeklyButtonTimeFrame();
      case REPORT_SCHEDULE.MONTHLY:
        return monthlyButtonTimeFrame();
    }
  })();

  const metaLocations = reportData.meta.locations ?? [];
  const notFoundMetaLocation = metaLocations
    .filter((location: LocationMeta) => {
      return !locations.byUUID[location.uuid];
    })
    .map((location: LocationMeta) => {
      return {
        key: location.uuid,
        text: location.name,
        value: location.uuid,
        disabled: true,
      };
    });

  useEffect(() => {
    if (validationOnLoad) {
      methods.triggerValidation();
    }

    methods.register({ name: 'meta.temperatureUnit' });

    const selectedValues = Array.from<HTMLElement>(
      document.querySelectorAll('.location-dropdown .ui.label'),
    );

    selectedValues.forEach((selected: HTMLElement) => {
      const value = selected.getAttribute('value') || '';

      if (!locations.byUUID[value]) {
        selected.classList.add('not-exist');
      }
    });
  }, []);

  useEffect(() => {
    dateRange.start &&
      methods.setValue('dateRangeDummy', dateRange.start, true);
  }, [dateRange]);

  const mapLocations = (location: string[]) => {
    const arr: LocationModel[] = [];
    if (reportType === 'audit_log') return arr;
    return location.map((uuid: string) => {
      return locations.byUUID[uuid];
    });
  };

  const mapRecipients = (recipients: string[]) => {
    return recipients.map((uuid: string) => {
      return allUsers.byUUID[uuid];
    });
  };

  const isSuccess = (data: ReportProps) => {
    const { name, schedule, deliveryTime, deliveryDate } = data;
    let secondText = `The report will be sent to the recipient's email address`;
    secondText += ` on ${reportNextDeliveryDate(
      deliveryDate as string,
      schedule,
    )} at ${deliveryTime}`;

    openModal({
      content: `${name} was successfully scheduled. ${secondText}`,
      header: 'Your report was saved successfully',
      primaryButton: {
        content: 'Continue',
        onClick: () => {
          hideStandardModal();
        },
      },
    });
  };

  const pollDownloadUrl = async (reportConfigUUID: string) => {
    const reportHistory = await getLatestReportHistory(reportConfigUUID);

    if (!reportHistory) {
      setTimeout(() => {
        pollDownloadUrl(reportConfigUUID);
      }, 5000);
    } else {
      downloadFile(reportHistory.meta.downloadUrl);
      setPollingDownloadUrl(false);
      hideModal();
    }
  };

  const onCancel = () => {
    hideModal();
    resetDateRange();
  };

  const hasInvalidFields = (isSubmitted = false) => {
    const errors: string[] = [];

    checkDeletedLocation();

    if (isSubmitted) {
      !nameVal && errors.push('name');
      !dateRangeVal && errors.push('dateRange');
      reportType !== 'audit_log' &&
        (!selectedLocation.length || typeof selectedLocation === 'string') &&
        errors.push('locations');
      (!recipientsVal.length || typeof recipientsVal === 'string') &&
        errors.push('recipients');
    }

    if (errors.includes('name')) {
      const input = document.querySelector('#report-name') as HTMLElement;
      input.focus();
    }

    setInvalidFields(errors);
    return !!errors.length;
  };

  useEffect(() => {
    hasInvalidFields();
  }, [nameVal, dateRangeVal, selectedLocation, recipientsVal]);

  const handleFormSubmit = methods.handleSubmit(
    async (data): Promise<void> => {
      delete data.dateRangeDummy;
      const deliveryDate = calculateInitialDeliveryDate(data.schedule);
      const toSave: CreateReportConfig = {
        ...data,
        type: reportType as ReportConfigType,
        timezone: moment.tz.guess(true),
        meta: {
          ...data.meta,
          labelIndex: dateRange.labelIndex,
          startDate: dateRange.start,
          endDate: dateRange.end,
        },
        locations: mapLocations(data.locations) as LocationModel[],
        recipients: mapRecipients(data.recipients),
        companyUUID: user?.company?.uuid ?? user?.companyUUID,
        deliveryDate: deliveryDate.date,
        description: data.description ? data.description : '',
      } as const;
      try {
        if (!isEditing) {
          const { uuid } = await addReportConfig(toSave, 'unshift');
          setPollingDownloadUrl(true);
          setPaginationMetadata({
            ...paginationMetadata,
            recordCount: paginationMetadata.recordCount + 1,
          });
          isScheduleOnce && pollDownloadUrl(uuid);
          !isScheduleOnce &&
            (() => {
              hideModal();
              isSuccess(data);
            })();

          mixpanel.track('Report Added', {
            report_name: toSave.name,
            report_schedule: toSave.schedule,
            report_data_range: getScheduleDateRange(
              toSave.meta.startDate,
              toSave.meta.endDate,
              toSave.schedule,
            ),
            report_locations: (toSave.locations as LocationModel[]).map(
              (location) => location.name,
            ),
            report_recipients_by_uuid: data.recipients,
            report_recipients: (toSave.recipients as UserModel[]).map(
              (recipient) => `${recipient.firstName} ${recipient.lastName}`,
            ),
            report_description: toSave.description,
          });
        } else {
          // If editing an existing report and setting it to once, we will save a once version of the report
          if (isScheduleOnce) {
            toSave.name = toSave.name.concat(' (Once)');
            const { uuid } = await addReportConfig(toSave, 'unshift');
            setPollingDownloadUrl(true);
            setPaginationMetadata({
              ...paginationMetadata,
              recordCount: paginationMetadata.recordCount + 1,
            });
            pollDownloadUrl(uuid);
          } else if (fields?.uuid) {
            await editReportConfig(fields.uuid, toSave);
            hideModal();
          }
        }
      } finally {
      }
    },
  );

  const checkDeletedLocation = () => {
    if (!locations.all.length) return;
    if (
      intersection(
        selectedLocation,
        notFoundMetaLocation.map((location) => location.value),
      ).length
    ) {
      setHasDeletedLocation(true);
    } else {
      setHasDeletedLocation(false);
    }
  };

  useEffect(() => {
    const notExistingLocations = intersection(
      (typeof selectedLocation === 'string'
        ? [selectedLocation]
        : selectedLocation) as string[],
      notFoundMetaLocation.map((location) => location.value),
    );
    if (hasDeletedLocation) {
      const deletedLocationsName = notExistingLocations.map(
        (uuid: string) =>
          find(notFoundMetaLocation, { value: uuid })?.text as string,
      );

      setDeletedLocationsError(
        `${arrayToSentence(deletedLocationsName)} ${
          deletedLocationsName.length > 1 ? 'have' : 'has'
        } been deleted. Please update this report.`,
      );
    } else {
      setDeletedLocationsError('');
    }
  }, [fields?.locations, hasDeletedLocation, selectedLocation]);

  useEffect(() => {
    if (deletedLocationsError) {
      methods.setError('locations', 'hasDeletedLocationError');
    } else {
      methods.clearError('locations');
    }
  }, [deletedLocationsError]);

  return (
    <FormContext {...methods}>
      <div className={className}>
        <FormStyled
          onSubmit={async () => {
            if (hasInvalidFields(true)) return;

            await handleFormSubmit();
          }}
          className="report-form"
          noValidate
        >
          <IconOutsideField
            className="report-form__icon"
            icon={<ReportTemplateSvg type={reportType} />}
            iconStyle={{ left: '-70px', top: '-20px' }}
            iconPopupProps={{
              content: REPORT_TITLES[reportType],
              position: 'top center',
            }}
          >
            <OpenInput
              className="report-form__name"
              name="name"
              placeholder="Insert report name"
              required
              autoFocus
              id="report-name"
            />
          </IconOutsideField>

          <ReportScheduleSelector
            className="report-form__schedule-selector"
            selectedSchedule={selectedSchedule}
          />

          {REPORT_SCHEDULE.ONCE !== selectedSchedule && (
            <ReportDeliveryOptions
              className="report-form__schedule-selected"
              selectedSchedule={selectedSchedule}
              selectedDeliveryTime={selectedDeliveryTime}
            />
          )}

          <IconOutsideField
            icon="icon calendar outline alternate"
            iconStyle={{ fontSize: '24px', left: '-50px', top: '0px' }}
            iconPopupProps={{
              content: 'Select the range of data to display in the report.',
              position: 'right center',
            }}
          >
            <ReportsTimeFilter
              className="report-form__schedule-presets"
              hideLabel
              customPresets={timePresets}
              key={`date-range-${selectedSchedule}`}
              selectedSchedule={selectedSchedule}
              selectedLabelIndex={reportData.meta.labelIndex ?? -1}
              error={invalidFields.includes('dateRange')}
            />
            <div style={{ display: 'none' }}>
              <FormInput
                className="report-form__schedule--date-range"
                name="dateRangeDummy"
                customErrors={{
                  required: 'Date range is required',
                }}
                required
                customStyle={{
                  marginTop: '10px',
                }}
                hideErors
              />
            </div>
          </IconOutsideField>

          {REPORT_TYPES.AUDIT_LOG !== reportType && (
            <IconOutsideField
              className="report-form__location-icon"
              icon="icon-wifi-signal-2"
              iconStyle={{ fontSize: '24px', left: '-50px', top: '0px' }}
              iconPopupProps={{
                content: 'Select the locations you want to pull data from.',
                position: 'right center',
              }}
            >
              <SpecialTruncatedDropdown
                className="report-form__select-location"
                name="locations"
                options={[...locationOptions, ...notFoundMetaLocation]}
                placeholder="Select locations"
                searchPlaceholder="Search location"
                defaultValue={reportData.locations}
                required
                error={
                  invalidFields.includes('locations') ||
                  deletedLocationsError.length > 0
                }
              />

              {deletedLocationsError.length > 0 && (
                <div
                  style={{
                    color: 'red',
                    marginBottom: '15px',
                  }}
                >
                  {deletedLocationsError}
                </div>
              )}
            </IconOutsideField>
          )}

          <IconOutsideField
            icon="icon-add-team"
            iconStyle={{ fontSize: '24px', left: '-50px', top: '0px' }}
            iconPopupProps={{
              content: 'Select who will receive the report',
              position: 'right center',
            }}
          >
            <SpecialTruncatedDropdown
              className="report-form__recipients"
              name="recipients"
              options={
                userOptions as {
                  key: string;
                  value: string;
                  text: string;
                }[]
              }
              placeholder="Select recipients"
              searchPlaceholder="Search recipients"
              defaultValue={reportData.recipients}
              required
              error={invalidFields.includes('recipients')}
            />
          </IconOutsideField>

          {!['', 'logbook_humidity', 'audit_log', 'overrides'].includes(
            type,
          ) && (
            <IconOutsideField
              icon="icon-temperature-celsius-fahrenheit-convert"
              iconStyle={{ fontSize: '24px', left: '-50px', top: '0px' }}
              iconPopupProps={{
                content: 'Temperature unit for the report.',
                position: 'right center',
              }}
            >
              <Toggle
                className="report-form__temperature-unit"
                name="meta.temperatureUnit"
                options={[
                  {
                    className: 'report-form__temperature-unit--fahrenheit',
                    label: '°F',
                    value: 'f',
                  },
                  {
                    className: 'report-form__temperature-unit--celcius',
                    label: '°C',
                    value: 'c',
                  },
                ]}
                value={selectedTemperature}
                onChange={(value) => {
                  methods.setValue(
                    'meta.temperatureUnit',
                    value as TemperatureUnit,
                  );
                }}
              />
            </IconOutsideField>
          )}

          <IconOutsideField
            icon="icon-common-file-horizontal-text"
            iconStyle={{ fontSize: '24px', left: '-50px', top: '0px' }}
            iconPopupProps={{
              content:
                'Add a short description of the report. This will be shown in the email sent to all recipients.',
              position: 'right center',
            }}
          >
            <HiddenTextArea
              className="report-form__add-description"
              label="Add description"
              name="description"
              placeholder="Add description"
              maxLength={280}
            />
          </IconOutsideField>

          {invalidFields.length > 0 && (
            <div
              style={{ color: 'red', marginTop: '25px', marginLeft: '-50px' }}
            >
              Please input required fields
            </div>
          )}

          <ButtonContainer right>
            <Button
              className="report-form__cancel-button bg-none"
              disabled={
                isPollingDownloadUrl ||
                isQueryInProgress['reportConfig:add'] ||
                isQueryInProgress['reportConfig:edit']
              }
              type="button"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              className="report-form__submit-button"
              primary
              type="submit"
              disabled={
                hasDeletedLocation ||
                isPollingDownloadUrl ||
                isQueryInProgress['reportConfig:add'] ||
                isQueryInProgress['reportConfig:edit']
              }
              loading={
                isPollingDownloadUrl ||
                isQueryInProgress['reportConfig:add'] ||
                isQueryInProgress['reportConfig:edit']
              }
            >
              {isScheduleOnce ? 'Download' : 'Schedule Report'}
            </Button>
          </ButtonContainer>
        </FormStyled>
      </div>
    </FormContext>
  );
};

export { ReportForm };
