import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import jwtDecode from 'jwt-decode';
import React, { createContext, useEffect, useState } from 'react';

import { AxiosService } from '../services';
import { UserProfile } from './account-context';

let token = '';
let logoutTimer: number | null = null;

const instance: AxiosInstance = AxiosService.request();

function autoLogoutTimer(timeInSeconds?: number): void {
  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }

  const defaultTimeout = 15 * 60; // 15mins
  const timeout = (timeInSeconds || defaultTimeout) * 1000;

  logoutTimer = window.setTimeout(async () => {
    await firebase.auth().signOut();
    window.location.href = '/';
  }, timeout);
}

export const setToken = (newToken: string): void => {
  token = newToken;
};

export const getToken = () => {
  return token;
};

export const request = instance;

export default instance;

interface AxiosContext {
  role?: string;
  cancelPendings: () => void;
}

export const AxiosContext = createContext<AxiosContext>({
  role: '',
  cancelPendings: (): void => undefined,
});

export const Axios: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [activeRole, setActiveRole] = useState<string | undefined>('');

  function decodeJWT(jwt: string) {
    if (!jwt) {
      return null;
    }
    return jwtDecode<{
      issuedAt: number;
      user: UserProfile;
      iat: number;
      exp: number;
    }>(token);
  }
  function tokenToRole(jwtToken: string) {
    const decoded = decodeJWT(jwtToken);
    if (!decoded) return;
    setActiveRole(decoded.user.role);
  }

  const pending = new Map();
  const [toCancel, setToCancel] = useState(new Map());

  const removePending = (config: AxiosRequestConfig) => {
    const url = [config.method, config.url].join('&');
    if (pending.has(url)) {
      pending.delete(url);
    }
    setToCancel(pending);
  };

  const cancelPendings = () => {
    toCancel.forEach((cancel) => {
      cancel();
    });
    toCancel.clear();
  };

  const addPending = (config: AxiosRequestConfig) => {
    const url = [config.method, config.url].join('&');
    config.cancelToken =
      config.cancelToken ||
      new axios.CancelToken((cancel) => {
        if (!pending.has(url)) {
          pending.set(url, cancel);
        }
      });
    setToCancel(pending);
  };

  const clearPending = () => {
    for (const [url, cancel] of pending) {
      cancel(url);
    }
    pending.clear();
    setToCancel(pending);
  };

  useEffect(() => {
    instance.interceptors.request.use((config: AxiosRequestConfig) => {
      if (token) {
        config.headers = {
          Authorization: token,
        };
        removePending(config);
        addPending(config);
      }
      return config;
    });

    instance.interceptors.response.use(
      (response: AxiosResponse) => {
        if (response.headers.authorization) {
          tokenToRole(response.headers.authorization);
          setToken(response.headers.authorization);
          const decodedToken = decodeJWT(response.headers.authorization);

          if (decodedToken) {
            const timeout =
              decodedToken.exp - Math.round(decodedToken.issuedAt / 1000);

            if (timeout > 0) {
              autoLogoutTimer(timeout);
            }
          }
        }
        removePending(response);
        return response;
      },
      (err: AxiosError) => {
        const { response } = err;
        if (!response) {
          throw err;
        }

        if (response.status > 400 && response.status < 500) {
          if (response.headers.authorization) {
            tokenToRole(response.headers.authorization);
            setToken(response.headers.authorization);
          } else {
            setToken('');
          }
        }

        throw err;
      },
    );
    return () => {
      clearPending();
    };
  }, []);

  return (
    <AxiosContext.Provider value={{ role: activeRole, cancelPendings }}>
      {children}
    </AxiosContext.Provider>
  );
};
