import { NotificationMethod } from '@coinspect/atka-types';
import {
  ButtonContainer,
  FormDropdown,
  FormInput,
  FormPhoneNumber,
  unFormatNumber,
} from '@coinspect/ui';
import { isValidEmail } from '@coinspect/utils';
import _get from 'lodash/get';
import React, { FC } from 'react';
import { FormContextValues, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import styled from 'styled-components';
import { isNumeric } from 'validator';

import { GeocodingResponse, useUserRole } from '../../hooks';
import { isValidJoiEmail } from '../../lib';
import { Role, TempUnits } from '../../services';
import { Toggle } from '../toggle';
import { TeamsSection } from './teams-section';

const UserEditToggle = styled.div`
  margin: 16px 0 16px;
`;

export const NotifMethodDisplayValue: { [key: string]: string } = {
  email: 'Email',
  sms: 'Text message',
  call: 'Phone call',
  pushNotification: 'Push notification',
};

export const NotifMethodOptions = Object.keys(NotifMethodDisplayValue).map(
  (key) => ({
    key: key,
    text: NotifMethodDisplayValue[key],
    value: key,
  }),
);

export interface Fields {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role?: string;
  preferredTempUnit: TempUnits;
  uuid?: string;
  meta?: Record<string, unknown>;
  notificationMethods?: NotificationMethod[];
}

interface InvitationMedium {
  email: string;
  phoneNumber: string;
}

interface UserDetailsActionsFromProps {
  onCancel?: (...args: any[]) => void | Promise<void>;
  cancelDisabled?: boolean;
  onSubmit: (...args: any[]) => void | Promise<void>;
  submitDisabled?: boolean;
  submitLoading?: boolean;
}

interface EmailLinkSectionProps {
  fields: Fields;
  isModal?: boolean;
}

interface UserDetailsFormFieldsProps extends EmailLinkSectionProps {
  withRoleToggle?: boolean;
  disableEmail?: boolean;
  disableTeams?: boolean;
  disableField?: boolean;
  withNameFields?: boolean;
  uuid?: string;
}

export interface UserDetailsFormProps extends UserDetailsFormFieldsProps {
  geolocationData?: GeocodingResponse | null;
  onSubmit: (
    ...args: any[]
  ) => boolean | Promise<void | InvitationMedium | undefined>;
  onCancel?: (...args: any[]) => void | Promise<void>;
  validationOnLoad?: boolean;
  isModal?: boolean;
  formMethods: FormContextValues<UserDetailsValidationSchema>;
}

export interface UserDetailsValidationSchema extends UserDetailsFormProps {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  role: string;
}

export const UserDetailsActionsFrom: FC<UserDetailsActionsFromProps> = (
  props,
) => {
  const {
    onCancel,
    cancelDisabled,
    onSubmit,
    submitDisabled,
    submitLoading,
  } = props;

  return (
    <ButtonContainer right>
      {props.onCancel && (
        <Button
          className="user-details-form__button--cancel"
          secondary
          type="button"
          onClick={onCancel}
          disabled={cancelDisabled}
        >
          Cancel
        </Button>
      )}
      <Button
        className="user-details-form__button--submit"
        primary
        onClick={onSubmit}
        disabled={submitDisabled}
        loading={submitLoading}
      >
        Save
      </Button>
    </ButtonContainer>
  );
};

export const UserDetailsFormFields: FC<UserDetailsFormFieldsProps> = (
  props,
) => {
  const {
    withRoleToggle = false,
    withNameFields = false,
    fields,
    disableEmail = false,
    disableField = false,
    disableTeams = false,
    uuid,
  } = props;
  const { setValue, watch } = useFormContext();
  const { displayRole } = useUserRole();

  const preferredTempUnit = watch('preferredTempUnit');
  const role = watch('role');

  const location = useLocation();
  const isEnergy = location.pathname.includes('energy');

  return (
    <>
      {withRoleToggle ? (
        <UserEditToggle>
          <Toggle
            label="User type:"
            name="role"
            options={[
              {
                label: displayRole('user'),
                value: 'user',
              },
              {
                label: displayRole('admin'),
                value: 'admin',
              },
            ]}
            value={role}
            onChange={(value) => setValue('role', value as Role)}
          />
        </UserEditToggle>
      ) : (
        <Form.Field
          className={
            isEnergy
              ? 'energy-user-role-field-wrapper'
              : 'user-details-form__first-name'
          }
        >
          <>
            <label
              style={{ fontSize: '12px' }}
              className={
                isEnergy
                  ? 'energy-user-role-field'
                  : 'user-details-form__first-name'
              }
            >
              User role:
            </label>
            <span
              className={
                isEnergy
                  ? 'energy-user-role-field'
                  : 'user-details-form__first-name'
              }
            >
              {displayRole(fields.role)}
            </span>
          </>
        </Form.Field>
      )}

      {withNameFields && (
        <>
          <FormInput
            showrequired={!isEnergy}
            className={
              isEnergy ? 'energy-user-fields' : 'user-details-form__first-name'
            }
            name="firstName"
            label="First name:"
            labelClassName={isEnergy ? 'energy-user-label' : ''}
            required
            disabled={disableField}
            customErrors={{
              required: 'First name is required.',
            }}
          />

          <FormInput
            showrequired={!isEnergy}
            className={
              isEnergy ? 'energy-user-fields' : 'user-details-form__last-name'
            }
            name="lastName"
            label="Last name:"
            labelClassName={isEnergy ? 'energy-user-label' : ''}
            required
            disabled={disableField}
            customErrors={{
              required: 'Last name is required.',
            }}
          />
        </>
      )}

      <FormInput
        showrequired={!isEnergy}
        className={
          isEnergy ? 'energy-user-fields' : 'user-details-form__email-address'
        }
        name="email"
        label="Email address:"
        labelClassName={isEnergy ? 'energy-user-label' : ''}
        type="email"
        required
        disabled={disableEmail}
        customErrors={{
          email: 'Invalid email format.',
          emailDuplicate: 'Email already exists',
          required: 'Email address is required.',
        }}
        validation={{
          email: (val): boolean => isValidEmail(val) && isValidJoiEmail(val),
        }}
      />

      <FormPhoneNumber
        className={
          isEnergy
            ? 'energy-user-phone-number-form'
            : 'user-details-form__phone-number'
        }
        name="phoneNumber"
        formLabel="Phone number:"
        labelClassName={isEnergy ? 'energy-user-phone-label' : ''}
        title="Used for notification purposes only"
        value={fields.phoneNumber}
        required
        disabled={disableField}
        customErrors={{
          phoneNumber: 'Invalid phone number format.',
          required: 'Phone number is required.',
          invalidaNumber2fa: 'Cannot send 2FA code to phone number.',
        }}
        validation={{
          phoneNumber: (phoneNumber: string): boolean => {
            const unformatted = unFormatNumber(phoneNumber);

            // TODO: to be used for validation
            // const countryCode = getValues('countryCode');

            if (!isNumeric(unformatted) || unformatted.length !== 10) {
              return false;
            }
            return true;
          },
        }}
      />

      <FormDropdown
        label="Alert notification methods:"
        name="notificationMethods"
        options={NotifMethodOptions}
        required
        showrequired={!isEnergy}
        search
        selection
        multiple
        disabled={disableField}
        placeholder="Select one or more"
        defaultValue={fields.notificationMethods ?? []}
        labelStyle={
          isEnergy
            ? { color: '#FFFFFF', fontWeight: 'normal', fontSize: '12px' }
            : {}
        }
        className={isEnergy ? 'energy-user-notification-method' : ''}
      />

      <div style={{ marginBottom: '14px' }}>
        <Toggle
          className={
            isEnergy
              ? 'energy-user-temp-toggle'
              : 'user-details-form__temperature-toggle'
          }
          label={isEnergy ? 'Temperature unit:' : 'Show temperature in:'}
          name="preferredTempUnit"
          isDisabled={disableField}
          options={[
            {
              className: 'user-details-form__temperature-toggle--fahrenheit',
              label: '°F',
              value: 'f',
            },
            {
              className: 'user-details-form__temperature-toggle--celcius',
              label: '°C',
              value: 'c',
            },
          ]}
          value={preferredTempUnit}
          onChange={(value) => {
            setValue('preferredTempUnit', value as string);
          }}
        />
      </div>
      {!isEnergy && (
        <TeamsSection uuid={uuid ?? ''} disabled={disableTeams || !isEnergy} />
      )}
    </>
  );
};
