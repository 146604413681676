import React, { useLayoutEffect } from 'react';
import { isMobile } from 'react-device-detect';

function getHeaderElement() {
  if (isMobile) {
    return document.querySelector('.therma-mobile-nav-container');
  }

  return document.querySelector('.therma-desktop-nav-container');
}

export default function useScrollIntoView(
  targetRefToScrollInto?: React.MutableRefObject<HTMLElement | null>,
  deps?: React.DependencyList,
) {
  useLayoutEffect(() => {
    const timeoutId = setTimeout(() => {
      if (targetRefToScrollInto && targetRefToScrollInto.current) {
        const appRoot = document.getElementById('coinspect');
        const rootContainerHeight = appRoot?.clientHeight || 0;
        const maxScroll = rootContainerHeight - window.innerHeight;
        const scrollIntoElement = targetRefToScrollInto.current;
        const scrollIntoElementRect = scrollIntoElement.getBoundingClientRect();
        const scrollTopValue = scrollIntoElementRect.top + window.pageYOffset;
        const headerElement = getHeaderElement();
        const positionOffset = headerElement?.clientHeight || 0;
        const finalTopValue =
          scrollTopValue - positionOffset >= maxScroll
            ? rootContainerHeight
            : scrollTopValue - positionOffset;

        window.scrollTo({
          top: finalTopValue,
          behavior: 'smooth',
        });
      }
    }, 0);

    return () => {
      clearTimeout(timeoutId);
    };
  }, deps);
}

export { useScrollIntoView };
