import React, { FunctionComponent } from 'react';

export const HomesEnergyIcon: FunctionComponent = () => {
  return (
    <span>
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.333374" width="48" height="48" rx="24" fill="#E1EDF1" />
        <g clipPath="url(#clip0_1733_15304)">
          <path
            d="M25.0834 33H17.6716C17.2504 33 16.8464 32.8288 16.5486 32.524C16.2507 32.2193 16.0834 31.806 16.0834 31.375V23.25"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.0834 21.75L22.5972 13.8762C22.8906 13.6333 23.2626 13.5 23.647 13.5C24.0315 13.5 24.4035 13.6333 24.6969 13.8762L32.5834 20.4031"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M28.8334 13.5H32.5834V17.25"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.8082 24L35.4627 29.36C35.5203 29.4124 35.5596 29.4792 35.5755 29.5519C35.5914 29.6246 35.5832 29.7 35.552 29.7685C35.5208 29.8369 35.468 29.8955 35.4002 29.9367C35.3324 29.9779 35.2527 29.9999 35.1712 30H33.1085V34.5L27.454 29.14C27.3964 29.0876 27.3572 29.0208 27.3413 28.9481C27.3254 28.8754 27.3336 28.8 27.3648 28.7315C27.396 28.6631 27.4488 28.6045 27.5165 28.5633C27.5843 28.5221 27.664 28.5001 27.7455 28.5H29.8082V24Z"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1733_15304">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(12.3334 12)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};
