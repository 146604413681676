import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { WelcomeContainer } from '../../style/login-signup-styles';

const WelcomeMessage = styled.div`
  max-width: 250px;
  margin-bottom: 72px;
  line-height: 1.25;
`;

export const WelcomeBackHeader: React.FC = () => {
  const location = useLocation();

  const pathThatHidesWelcomeBack = [
    '/forgot-password',
    '/resend-activation',
    '/reset-password',
  ].includes(location.pathname);

  return (
    <>
      {!pathThatHidesWelcomeBack && (
        <WelcomeContainer>
          <WelcomeMessage />
        </WelcomeContainer>
      )}
    </>
  );
};
