import { plainGrey, powerButton, powerButtonFocus } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  computerMinWidth,
  mobileMaxWidthPx,
} from '../../constants/responsive-constants';
import { Tooltip } from '../tooltip';

interface PowerButtonProps {
  itemName: string;
  isActive: boolean;
  onClick: (...args: any[]) => void;
  isQueryInProgress?: boolean;
  disabled?: boolean;
}

const PowerButtonContainer = styled.div<{ active: boolean }>`
  float: right;
  &&& .button__power.ui.button {
    background-color: ${(props): string =>
      props.active ? powerButton : plainGrey};
    i {
      color: white;
    }

    &:focus {
      outline: none;
      background-color: ${(props): string =>
        props.active ? powerButtonFocus : plainGrey};
    }

    @media only screen and (min-width: ${computerMinWidth}px) {
      &:hover {
        outline: none;
        background-color: ${(props): string =>
          props.active ? powerButtonFocus : plainGrey};
      }
    }
    @media only screen and (max-width: ${mobileMaxWidthPx}) {
      margin-left: 0;
    }
  }
`;
const PowerButton: FunctionComponent<PowerButtonProps> = (props) => {
  const {
    itemName,
    isActive,
    onClick,
    isQueryInProgress = false,
    disabled = false,
  } = props;
  const visibleClass = isQueryInProgress ? 'not-visible' : '';

  return (
    <PowerButtonContainer active={isActive}>
      <Tooltip
        content={`${itemName} ${isActive ? 'enabled' : 'disabled'}`}
        trigger={
          <Button
            circular
            floated="right"
            className="button__power"
            icon
            loading={isQueryInProgress}
            disabled={isQueryInProgress || disabled}
            onClick={onClick}
          >
            <Icon
              className={`icon-sign-out ${visibleClass}`}
              size="large"
              aria-label={`Enable/Disable ${itemName}`}
            />
          </Button>
        }
      />
    </PowerButtonContainer>
  );
};

export { PowerButton };
