import React, { useContext, useRef } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  ConnectionTestStatusModal,
  TroubleshootingModalContent,
  TroubleshootingModalHeaderTagline,
} from '../components/modals';
import { RESOURCE_HUB } from '../constants';
import { StandardModalContext } from '../contexts';
import { StoreContext } from '../store';

const StyledIcon = styled(Icon)`
  height: auto !important;
`;

export const TroubleshootSectionType = {
  hubs: 'hubs',
  sensors: 'sensors',
};

const troubleshootingSections = {
  [TroubleshootSectionType.hubs]: {
    title: 'Hubs',
    images: [
      {
        text:
          'Ensure the antennas are properly screwed in and power adapter plugged in.',
        image: require('../components/ui/images/troubleshooting/hub-1.png'),
      },
      {
        text:
          'Unplug the hub and plug back in after 90 seconds. Remove and reinsert the SIM card on the back.',
        image: require('../components/ui/images/troubleshooting/hub-2.png'),
      },
      {
        text:
          'Relocate your hub for a better 4G signal. Alternatively, connect your hub and router by Ethernet using the WAN port on the back.',
        image: require('../components/ui/images/troubleshooting/hub-3.png'),
      },
    ],
  },
  [TroubleshootSectionType.sensors]: {
    title: 'Sensors',
    images: [
      {
        text:
          'Ensure the sensor is upright and not placed in food, on top of food, in water, in Ziploc bags, or next to fans and doorways.',
        image: require('../components/ui/images/troubleshooting/sensor-1.png'),
      },
      {
        text:
          'Move your hub to be on the same floor as the sensors, limiting as many walls, obstructions, and distance as possible.',
        image: require('../components/ui/images/troubleshooting/sensor-2.png'),
      },
      {
        text:
          'Reset your sensor by holding the “ACT” button for 5 seconds until  you see a flashing green light.',
        image: require('../components/ui/images/troubleshooting/sensor-3.png'),
      },
    ],
  },
};

export function useTroubleshootingModal() {
  const { openModal, hideModal } = useContext(StandardModalContext);
  const { store } = useContext(StoreContext);
  const { devices } = store.entities;
  const { connectionStatus } = store.pages.sensors;

  const modalData = {
    [TroubleshootSectionType.hubs]: {
      ...troubleshootingSections[TroubleshootSectionType.hubs],
      modalRef: useRef<HTMLInputElement>(null),
    },
    [TroubleshootSectionType.sensors]: {
      ...troubleshootingSections[TroubleshootSectionType.sensors],
      modalRef: useRef<HTMLInputElement>(null),
    },
  };

  function scrollToSection(type: string, instant = false) {
    const sectionRef = modalData[type]?.modalRef;

    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        block: 'start',
        behavior: instant ? 'auto' : 'smooth',
      });
    }
  }

  function showTroubleShootingModal(
    defaultSection = TroubleshootSectionType.hubs,
    locationUUID: string,
  ) {
    const locationDevicesConnectionStatus = connectionStatus[locationUUID];
    const device =
      devices.byUUID[Object.keys(locationDevicesConnectionStatus)[0]];

    const sensorID = device.serialNumber.toLowerCase();
    const slicedSensorID = sensorID.substring(0, 5);

    if (slicedSensorID === 'HT65N'.toLowerCase()) {
      modalData[
        TroubleshootSectionType.sensors
      ].images[0].image = require('../components/ui/images/troubleshooting/ht-placement.png');
      modalData[
        TroubleshootSectionType.sensors
      ].images[2].image = require('../components/ui/images/sensor-act-button/ht-act.png');
    }

    if (
      slicedSensorID === 'LHT65'.toLowerCase() ||
      slicedSensorID === 'LST25'.toLowerCase()
    ) {
      modalData[
        TroubleshootSectionType.sensors
      ].images[0].image = require('../components/ui/images/troubleshooting/lht-placement.png');
      modalData[
        TroubleshootSectionType.sensors
      ].images[2].image = require('../components/ui/images/sensor-act-button/lht-act.png');
    }

    openModal({
      width: '608px',
      scrollable: true,
      header: 'Troubleshooting',
      headerIcon: <StyledIcon fitted className="icon-troubleshootingsvg" />,
      removeCloseIcon: true,
      disableCloseOnOutsideClick: true,
      headerTagline: (
        <TroubleshootingModalHeaderTagline
          onTroubleshootingTypeChange={scrollToSection}
          defaultSection={defaultSection}
        />
      ),
      content: (
        <TroubleshootingModalContent contentData={Object.values(modalData)} />
      ),
      primaryButton: {
        content: 'Okay',
        onClick: () => {
          hideModal();
          openModal({
            header: 'Connection test status',
            disableCloseOnOutsideClick: true,
            removeCloseIcon: true,
            allContent: (
              <ConnectionTestStatusModal locationUUID={locationUUID} />
            ),
            width: '752px',
          });
        },
      },
      secondaryButton: {
        content: 'Contact Support',
        onClick: () => {
          window.open(`${RESOURCE_HUB}#hs-chat-open`, '_blank');
        },
      },
      scrollablePadding: '0px 108px',
    });
  }

  return {
    showTroubleShootingModal,
  };
}
