// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

type browserNames =
  | 'edge'
  | 'chromium based edge (dev or canary)'
  | 'opera'
  | 'chrome'
  | 'ie'
  | 'firefox'
  | 'safari'
  | 'other';

// https://stackoverflow.com/a/56361977/4110257
// changelog https://gist.github.com/iamdevlinph/409807abc775172f059ca71f18b9ac4e
export const detectBrowser = (): browserNames => {
  const agent = window.navigator.userAgent.toLowerCase();

  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'edge';
    case agent.indexOf('edg') > -1:
      return 'chromium based edge (dev or canary)';
    case agent.indexOf('opr') > -1 && !!window.opr:
      return 'opera';
    case agent.indexOf('chrome') > -1 && !!window.chrome:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'ie';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
};

export const unsupportedBrowsers = ['ie', 'edge'];

export const isBrowserOld = () => {
  return unsupportedBrowsers.includes(detectBrowser());
};
