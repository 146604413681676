import React, { FunctionComponent } from 'react';

import { ContactUsLink } from '../../style/login-signup-styles';

export const ContactUs: FunctionComponent = () => {
  return (
    <ContactUsLink href="mailto:support@glaciergrid.com">
      Contact us
    </ContactUsLink>
  );
};
