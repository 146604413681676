export const setUnscrollable = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.classList.add('unscrollable');
};

export const setScrollable = () => {
  const scrollY = document.body.style.top;
  document.body.classList.remove('unscrollable');
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
};
