import { DataStoreAction, NormalEntity } from '../reducer';

type EntityNames =
  | 'location'
  | 'device'
  | 'alertConfig'
  | 'alertNotif'
  | 'activeAlert'
  | 'notifConfig'
  | 'user'
  | 'teams'
  | 'teamLocation'
  | 'reportConfig'
  | 'invite'
  | 'equipment';

/**
 * Returns a reducer function which handles normalized entities
 * Structure is
 * @example
 * store: {
 *    entities : {
 *       [entityName] : {
 *         byUUID: { [entityUUID] : entityObject },
 *         all: [entityUUID]
 *       }
 *    }
 * ```
 * The result of this function can be assigned directly to entity name and the return must be provided with the state
 * at entity and the action.
 *
 * @example
 * const myEntityReducer = genericEntityReducer('location', true);
 * // in reducer:
 * return {
 *   entities: {
 *     location: myEntityReducer(state.entities.location, action),
 *   }
 * }
 *
 *
 * @param entityName: EntityNames - Limited to type of EntityNames
 * @param addToTop: boolean - Whether or not an addition should be added to the top or bottom of the list, defaults to bottom.
 * @param eventNameSpace?: string - An override for events which will allow an entity to be
 *                                  updated on other entity events, defaults to entity name
 */
export function genericEntityReducer<T>(
  entityName: EntityNames,
  addToTop = false,
  eventNameSpace?: string,
) {
  if (!eventNameSpace) {
    eventNameSpace = entityName;
  }
  return (state: NormalEntity<T>, action: DataStoreAction): NormalEntity<T> => {
    switch (action.type) {
      case `${eventNameSpace}:append`:
      case `${eventNameSpace}:set`:
        return {
          all: [...new Set(state.all.concat(action.data.result))],
          byUUID: { ...state.byUUID, ...action.data.entities[entityName] },
        };

      case `${eventNameSpace}:add`:
        return {
          // this addToTop is a shim to serve a product requirement
          // and we should probably have it be rolled into the dispatch instead
          // but for now , until all those cases are apparent, we should leave here.
          all: addToTop
            ? [...new Set([...action.data.result, ...state.all])]
            : [...new Set(state.all.concat(action.data.result))],
          byUUID: {
            ...state.byUUID,
            ...action.data.entities[entityName],
          },
        };

      case `${eventNameSpace}:unshift`:
        return {
          all: [...new Set([...action.data.result, ...state.all])],
          byUUID: {
            ...state.byUUID,
            ...action.data.entities[entityName],
          },
        };

      case `${eventNameSpace}:edit`:
        return {
          ...state,
          byUUID: {
            ...state.byUUID,
            ...action.data.entities[entityName],
          },
        };

      case `${eventNameSpace}:delete`: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [action.data]: val, ...byUUID } = state.byUUID;
        const uuidIdx = state.all.indexOf(action.data);
        const all = state.all
          .slice(0, uuidIdx)
          .concat(state.all.slice(uuidIdx + 1));
        return {
          all,
          byUUID,
        };
      }
      case `${eventNameSpace}:reset`: {
        return {
          all: action.data.result,
          byUUID: action.data.entities[entityName] || {},
        };
      }

      // device:toggle:smart_alert
      case `${eventNameSpace}:toggle:smart_alert`: {
        return {
          ...state,
          byUUID: {
            ...state.byUUID,
            [action.data.uuid]: {
              ...state.byUUID[action.data.uuid],
              isSmartAlert: action.data.isSmartAlert,
            },
          },
        };
      }

      // set report_config history
      case `${eventNameSpace}:history:set`:
        return {
          ...state,
          byUUID: {
            ...state.byUUID,
            [action.data.reportConfigUUID]: {
              ...state.byUUID[action.data.reportConfigUUID],
              history: action.data,
              lastRan: action.data.meta.reportConfig.lastRan,
            },
          },
        };

      // device:delete_cascade
      case `${eventNameSpace}:delete_cascade`:
        const { data: deletedLocationUUID } = action;
        const allDevices = { ...state.byUUID };
        const filteredDevicesUUIDs = Object.keys(allDevices).filter(
          (key) => allDevices[key].locationUUID !== deletedLocationUUID,
        );
        const filteredDevices: { [key: string]: T } = {};
        filteredDevicesUUIDs.forEach(
          (key) => (filteredDevices[key] = allDevices[key]),
        );
        return {
          ...state,
          byUUID: {
            ...filteredDevices,
          },
        };

      // invite:resent_today
      // update updatedAt date of invite to today from client side
      case `${eventNameSpace}:resent_today`:
        return {
          ...state,
          byUUID: {
            ...state.byUUID,
            [action.data]: {
              ...state.byUUID[action.data],
              updatedAt: new Date(),
            },
          },
        };

      // TODO: need to determine if `hard_reset` should take a value
      // or reset to default empty state
      case `${eventNameSpace}:userPage:hard_reset`:
        return {
          all: [...action.data.result],
          byUUID: { ...action.data.entities[entityName] },
        };

      case `${eventNameSpace}:hard_reset`:
        return {
          ...state,
          all: [],
          byUUID: {},
        };

      default:
        return state;
    }
  };
}
