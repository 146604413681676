import React from 'react';

const HelpLogo = () => (
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 20L8.75 17H8.5C6.13333 17 4.125 16.175 2.475 14.525C0.825 12.875 0 10.8667 0 8.5C0 6.13333 0.825 4.125 2.475 2.475C4.125 0.825 6.13333 0 8.5 0C9.68333 0 10.7873 0.220667 11.812 0.662C12.8373 1.104 13.7377 1.71233 14.513 2.487C15.2877 3.26233 15.8957 4.16233 16.337 5.187C16.779 6.21233 17 7.31667 17 8.5C17 11.0333 16.2417 13.304 14.725 15.312C13.2083 17.3207 11.3 18.8833 9 20ZM8.475 13.975C8.75833 13.975 9 13.875 9.2 13.675C9.4 13.475 9.5 13.2333 9.5 12.95C9.5 12.6667 9.4 12.425 9.2 12.225C9 12.025 8.75833 11.925 8.475 11.925C8.19167 11.925 7.95 12.025 7.75 12.225C7.55 12.425 7.45 12.6667 7.45 12.95C7.45 13.2333 7.55 13.475 7.75 13.675C7.95 13.875 8.19167 13.975 8.475 13.975ZM8.5 10.8C8.68333 10.8 8.85 10.7333 9 10.6C9.15 10.4667 9.25 10.2833 9.3 10.05C9.33333 9.83333 9.425 9.63733 9.575 9.462C9.725 9.28733 9.98333 9.01667 10.35 8.65C10.65 8.35 10.9 8.025 11.1 7.675C11.3 7.325 11.4 6.95 11.4 6.55C11.4 5.7 11.1127 5.06233 10.538 4.637C9.96267 4.21233 9.28333 4 8.5 4C7.91667 4 7.41267 4.13333 6.988 4.4C6.56267 4.66667 6.21667 5 5.95 5.4C5.83333 5.58333 5.81233 5.775 5.887 5.975C5.96233 6.175 6.11667 6.31667 6.35 6.4C6.51667 6.46667 6.68333 6.46667 6.85 6.4C7.01667 6.33333 7.16667 6.20833 7.3 6.025C7.43333 5.84167 7.596 5.69167 7.788 5.575C7.97933 5.45833 8.21667 5.4 8.5 5.4C8.95 5.4 9.28767 5.525 9.513 5.775C9.73767 6.025 9.85 6.3 9.85 6.6C9.85 6.88333 9.76667 7.13733 9.6 7.362C9.43333 7.58733 9.23333 7.81667 9 8.05C8.53333 8.45 8.22067 8.775 8.062 9.025C7.904 9.275 7.80833 9.61667 7.775 10.05C7.75833 10.25 7.821 10.425 7.963 10.575C8.10433 10.725 8.28333 10.8 8.5 10.8ZM11 15V16.35C12.1833 15.35 13.1457 14.1793 13.887 12.838C14.629 11.496 15 10.05 15 8.5C15 6.68333 14.371 5.14567 13.113 3.887C11.8543 2.629 10.3167 2 8.5 2C6.68333 2 5.146 2.629 3.888 3.887C2.62933 5.14567 2 6.68333 2 8.5C2 10.3167 2.62933 11.8543 3.888 13.113C5.146 14.371 6.68333 15 8.5 15H11Z"
      fill="#999CA8"
    />
  </svg>
);

export default HelpLogo;
