/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: This needs to be rewritten in order to remove the disable clauses.
// JSON.parse should receive a string, but method getItem returns an object.
import LZString from 'lz-string';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * setItem is use for storing data in the local storage
 * @param key key-value pair key name in the local storage.
 * @param value key-value pair value to store in the local storage.
 * @example
 *  const value = { name: 'sample name' };
 *  setItem('sampleKey', value);
 */
export function setItem(key: string, value: any) {
  const compressed = LZString.compress(JSON.stringify(value));
  window.localStorage.setItem(key, compressed);
}

/**
 * setItemWithExpiration is a wrapper around setItem which
 * allows for a ttl to be set as part of the data stored in
 * local storage.
 *
 * Should be used with getItemWithExpiration
 */
export function setItemWithExpiration({
  key,
  value,
  expireMinutes,
}: {
  key: string;
  value: any;
  expireMinutes: number;
}) {
  const currentTime = new Date().getTime();
  const expiry = currentTime + expireMinutes * 60000;
  setItem(key, JSON.stringify({ value, expiry }));
}

function parseItem(item: string): Record<string, unknown> | null {
  try {
    return JSON.parse(item);
  } catch {
    return null;
  }
}
/**
 * getItem is use for reading local storage with initial value if key don't exist.
 * @param key key-value pair key name in the local storage.
 * @param initialValue key-value pair value initial.
 * @example
 *  const initialValue = [{}];
 *  const item = getItem('sampleKey', initialValue);
 */
export function getItem(key: string, initialValue?: any) {
  const item = window.localStorage.getItem(key) || '';
  let data: Record<string, unknown> | null = null;
  for (const i of [item, LZString.decompress(item)]) {
    data = parseItem(i);
    if (data) {
      break;
    }
  }
  return data || initialValue;
}

/**
 * getItemWithExpiration is a wrapper around getItem which
 * allows for an expiration time to be checked as part of the
 * data stored in local storage. Cleans up the item if the ttl
 * has expired.
 *
 * Should be used with setItemWithExpiration
 *
 * @param key key-value pair key name in the local storage.
 * @param initialValue (optional) key-value pair value initial.
 */
export function getItemWithExpiration(key: string, initialValue?: any) {
  const currentTime = new Date().getTime();
  if (localStorage.hasOwnProperty(key)) {
    const data = JSON.parse(getItem(key, {}));

    if (currentTime < data.expiry) {
      return data.value;
    } else {
      // clean up expired item
      removeItem(key);
    }
  }
  return initialValue;
}

/**
 * removeItem is use for deleting local storage value.
 * @param key Target key to delete in local storage.
 * @example
 *  removeItem('sampleKey');
 */
export function removeItem(key: string) {
  window.localStorage.removeItem(key);
}

/**
 * clear is use for clearing local storage
 * @example
 *  clear();
 */
export function clear() {
  window.localStorage.clear();
}

/**
 * useLocalStorage is a custom hook for localstorage.
 * @param key key-value pair key name in the local storage.
 * @param initialValue key-value initial value to use if key don't have data yet.
 * @example
 *  const initialValue = [{}];
 *  const [sampleStorage, setSampleStorage] = useLocalStorage('sampleStorage', initialValue);
 */

//  <S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>]
export function useLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState(() =>
    getItem(key, initialValue),
  );

  function setValue(value: T): void {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    setItem(key, valueToStore);
  }

  return [storedValue, setValue as Dispatch<SetStateAction<T>>];
}

/**
 * useLocalStorageOnValueChange is a custom hook for saving data on value change in the local storage.
 * @param key key-value pair key name in the local storage.
 * @param observableValue value to observe and stored in the local storage.
 * @example
 *  const observableValue = [{}];
 *  const [sampleStorage, setSampleStorage] = useLocalStorageOnValueChange('sampleStorage', observableValue);
 */
export function useLocalStorageOnValueChange(
  key: string,
  observableValue: any,
) {
  const [storedValue, setStoredValue] = useLocalStorage(key, observableValue);

  useEffect(() => {
    setStoredValue(observableValue);
  }, [observableValue]);

  return [storedValue, setStoredValue];
}
