import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { PANEL_NAME_PREFIX } from '../components/devices/device-list-item';
import { LOC_PANEL_PREFIX } from '../components/locations/location-line-item';
import { useAccordion } from '../components/ui/accordion';
import { StandardModalContext } from '../contexts';
import { DeviceModel, UserModel } from '../services';
import { useUserService } from '.';

export const ModalHeader = styled.div`
  color: #000000;
`;

export const ModalHeaderText = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  font-family: proxima-nova;
`;

export interface CancelReminderDataInterface {
  user: UserModel;
  alerts: ReminderAlerts[];
}

export interface ReminderContentInterface {
  title: string;
  confirm: string;
  cancelButton: string;
  hasAlertConfigs: boolean;
  doCancel: (data: CancelReminderDataInterface) => Promise<void>;
}

export interface ReminderAlerts {
  deviceUUID: string;
  locationUUID: string;
  alertName: string;
}

export function useAlertConfigReminderModal() {
  const { openModal, hideModal } = useContext(StandardModalContext);
  const { openPanel, closeAllPanels } = useAccordion();
  const { setOptOutDate } = useUserService();
  const history = useHistory();

  const startAlertConfigTooltipTutorial = (devices: DeviceModel[] = []) => {
    closeAllPanels();
    history.push('/sensors');
    openPanel(`${LOC_PANEL_PREFIX}${devices[0].locationUUID}`);
    openPanel(`${PANEL_NAME_PREFIX}${devices[0].uuid}`);
  };

  const showReminder = (devices: DeviceModel[] = []) => {
    // sort from oldest sensor
    devices.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    openModal({
      content:
        "The following sensors don't have alerts set up yet. Alerts help avoid product loss by notifying you when the temperature or humidity passes the set threshold.",
      subContent: devices.map((dev) => dev.name).join(', '),
      header: 'Warning',
      primaryButton: {
        content: 'Configure Alert',
        onClick: () => {
          if (devices.length > 0) {
            startAlertConfigTooltipTutorial(devices);
          }
          hideModal();
        },
      },
      secondaryButton: {
        content: 'Stop sending reminders',
        onClick: async () => {
          hideModal();
          await setOptOutDate();
        },
      },
    });
  };

  return {
    hideReminder: hideModal,
    showReminder,
  };
}
