// https://stackoverflow.com/a/9039885/4110257

export function isAppleDevice(): boolean {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    navigator.platform.toLowerCase().indexOf('mac') === 0 ||
    // iPad on iOS 13 detection
    navigator.userAgent.includes('Mac')
  );
}
