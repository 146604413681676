import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';
import styled from 'styled-components';

const NotFoundPageComponent = styled.div`
  text-align: center;
  padding: 16%;
`;

export const NotFoundPage: FunctionComponent = () => {
  return (
    <NotFoundPageComponent>
      <Laptop />
      <Header as="h2">
        404
        <Header.Subheader>Page cannot be found.</Header.Subheader>
      </Header>

      <NavLink to="/">
        <Button primary>Go to my dashboard</Button>
      </NavLink>
    </NotFoundPageComponent>
  );
};

const Laptop = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="351"
      height="217"
      viewBox="0 0 351 217"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-156 -369) translate(158 369) translate(40) translate(0 64)">
          <rect
            width="218"
            height="137"
            x="24"
            y="0"
            stroke="#D1D7E3"
            strokeWidth="4"
            rx="4"
          />
          <rect
            width="190"
            height="111"
            x="38"
            y="13"
            stroke="#D1D7E3"
            strokeWidth="4"
            rx="4"
          />
          <rect
            width="266"
            height="14"
            x="0"
            y="137"
            stroke="#D1D7E3"
            strokeWidth="4"
            rx="4"
          />
          <g fill="#D1D7E3" fillRule="nonzero">
            <path
              d="M29.795 59.59c16.41 0 29.795-13.334 29.795-29.795C59.59 13.333 46.205 0 29.795 0 13.385 0 0 13.385 0 29.795 0 46.205 13.385 59.59 29.795 59.59zm0-55.385c14.102 0 25.59 11.487 25.59 25.59 0 14.102-11.488 25.59-25.59 25.59-14.103 0-25.59-11.488-25.59-25.59 0-14.103 11.487-25.59 25.59-25.59z"
              transform="translate(103 39)"
            />
            <path
              d="M18.615 40.667c.41.41.923.615 1.488.615.564 0 1.076-.205 1.487-.615l8.205-8.205L38 40.667c.41.41.923.615 1.487.615.564 0 1.077-.205 1.487-.615.82-.82.82-2.154 0-2.975l-8.205-8.205 8.205-8.205c.82-.82.82-2.154 0-2.974a2.105 2.105 0 00-2.974 0l-8.205 8.205-8.205-8.205a2.105 2.105 0 00-2.975 0c-.82.82-.82 2.154 0 2.974l8.206 8.205-8.206 8.205c-.82.82-.82 2.154 0 2.975z"
              transform="translate(103 39)"
            />
          </g>
        </g>
        <g fill="#D1D7E3" fillRule="nonzero">
          <path
            d="M2.735 53.265a.784.784 0 00.567.235c.215 0 .411-.078.568-.235L7 50.135l3.13 3.13a.784.784 0 00.568.235.803.803 0 00.567-1.37L8.135 49l3.13-3.13a.803.803 0 00-1.135-1.135L7 47.865l-3.13-3.13a.803.803 0 00-1.135 1.135L5.865 49l-3.13 3.13a.803.803 0 000 1.135zM30.657 12.343a.522.522 0 00.378.157.522.522 0 00.378-.157l2.087-2.086 2.087 2.086a.522.522 0 00.378.157.535.535 0 00.378-.913L34.257 9.5l2.086-2.087a.535.535 0 00-.756-.756L33.5 8.743l-2.087-2.086a.535.535 0 00-.756.756L32.743 9.5l-2.086 2.087a.535.535 0 000 .756zM17.5 33v18a1.5 1.5 0 003 0V33a1.5 1.5 0 00-3 0zM19 29a9 9 0 100-18 9 9 0 000 18zm0-3a6 6 0 110-12 6 6 0 010 12zM35 41a6 6 0 100-12 6 6 0 000 12zm0-3a3 3 0 110-6 3 3 0 010 6zM5 10A5 5 0 105 0a5 5 0 000 10zm0-3a2 2 0 110-4 2 2 0 010 4zM23 40.5V51a1.5 1.5 0 003 0V40.5a1.5 1.5 0 00-3 0z"
            transform="translate(-156 -369) translate(158 369) translate(40) translate(113)"
          />
        </g>
        <g stroke="#D1D7E3" strokeLinecap="round" strokeWidth="4">
          <path
            d="M347 1H18M11 1H0"
            transform="translate(-156 -369) translate(158 369) matrix(-1 0 0 1 347 214)"
          />
        </g>
      </g>
    </svg>
  );
};
