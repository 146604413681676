import moment from 'moment';

import { LocationModel } from '../../../location-service/dist';
import { LocationMeta } from '../components';
import { BaseEntity, request } from '../contexts';
import { UserModel } from './user-service';

export const REPORT_FORM_BREAKPOINT = '544px';
export const TEMPLATE_SELECTION_BREAKPOINT = '600px';

export const REPORT_TYPES = {
  LOGBOOK_TEMPERATURE: 'logbook_temperature',
  LOGBOOK_HUMIDITY: 'logbook_humidity',
  SENT_ALERTS: 'sent_alerts',
  MEAN_KINETIC_TEMPERATURE: 'mean_kinetic_temperature',
  AUDIT_LOG: 'audit_log',
  OVERRIDES_REPORT: 'overrides',
  SAVINGS_REPORT: 'savings',
  HVAC_COOLING_CYCLE: 'hvac_cooling_cycle',
} as const;
export const REPORT_TYPES_ARRAY = Object.values(REPORT_TYPES);
export type ReportConfigType = typeof REPORT_TYPES[keyof typeof REPORT_TYPES];
export const REPORT_TITLES = {
  [REPORT_TYPES.LOGBOOK_TEMPERATURE]: 'Temperature logbook',
  [REPORT_TYPES.LOGBOOK_HUMIDITY]: 'Humidity logbook',
  [REPORT_TYPES.SENT_ALERTS]: 'Sent alerts log',
  [REPORT_TYPES.MEAN_KINETIC_TEMPERATURE]: 'Mean kinetic temperature',
  [REPORT_TYPES.AUDIT_LOG]: 'Audit log',
  [REPORT_TYPES.OVERRIDES_REPORT]: 'Overrides report',
} as const;

export type ReportProps = {
  uuid?: string;
  name: string;
  locations: string[];
  recipients: string[];
  schedule: ReportConfigSchedule;
  dateRange?: string;
  createdAt?: string;
  meta: {
    startDate?: string;
    endDate?: string;
    labelIndex?: number;
    locations?: LocationMeta[];
    minsPerSample?: number;
    temperatureUnit?: TemperatureUnit;
  };
  runTime?: string;
  dateRangeDummy?: string;
  type: ReportConfigType;
  description?: string;
  deliveryTime?: string;
  deliveryDate?: string;
};

export type TemperatureUnit = 'f' | 'c';

export const REPORT_SCHEDULE = {
  ONCE: 'once',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  BI_WEEKLY: 'bi_weekly',
  MONTHLY: 'monthly',
} as const;
export type ReportConfigSchedule = typeof REPORT_SCHEDULE[keyof typeof REPORT_SCHEDULE];
export const getReportSchedules = () => {
  return Object.keys(REPORT_SCHEDULE).map((sched: string) => {
    const obj: {
      text: string;
      value: ReportConfigSchedule;
    } = {
      text: (sched[0].toUpperCase() + sched.substr(1).toLowerCase()).replace(
        '_',
        '-',
      ),
      value: REPORT_SCHEDULE[sched as keyof typeof REPORT_SCHEDULE],
    };
    return obj;
  });
};

export interface BaseMeta {
  dateRange?: string;
}
export interface ReportConfigLogbookMeta extends BaseMeta {
  temperatureUnit: TemperatureUnit;
}

export interface ReportConfigDataExportMeta extends BaseMeta {
  temperatureUnit: TemperatureUnit;
}

export type ReportConfigSentAlertsMeta = BaseMeta;

export interface CreateReportConfig {
  name: string;
  type: ReportConfigType;
  deliveryDate: string;
  deliveryTime: string;
  schedule: ReportConfigSchedule;
  timezone: string;
  recipients: UserModel[];
  locations?: LocationModel[];
  meta: {
    startDate: string;
    labelIndex: number;
    endDate: string;
  };
  companyUUID: string;
  description?: string;
}

export interface ReportConfigRecipient {
  uuid: string;
  userUUID: string;
  companyUUID: string;
}

export interface ReportConfigLocation {
  uuid: string;
  locationUUID: string;
  companyUUID: string;
}

export interface ReportConfigBaseModel {
  name: string;
  type: ReportConfigType;
  schedule: ReportConfigSchedule;
  lastRan: string | Date;
  runTime: string;
  deliveryDate: string;
  deliveryTime: string;
  recipients: ReportConfigRecipient[];
  locations: ReportConfigLocation[];
  meta: {
    endDate?: string;
    startDate?: string;
  };
  history?: {
    meta?: {
      downloadUrl: string;
    };
  };
  description?: string;
}

export interface ReportConfigLocationModel {
  uuid: string;
  locationUUID: string;
  companyUUID: string;
}

export interface ReportConfigModel extends BaseEntity, ReportConfigBaseModel {}

export class ReportConfigService {
  public static async browse({
    page = 1,
    perPage = 0,
    keyword = '',
    sortOrder = 'desc',
  }) {
    const { data: result } = await request.get('/api/report/report-config', {
      params: { page, limit: perPage, keyword, sortOrder },
    });

    return [result.data, result.metadata];
  }

  public static async read(uuid: string) {
    const { data: result } = await request.get(
      `/api/report/report-config/${uuid}`,
    );
    return result.data;
  }

  public static async add(payload: CreateReportConfig) {
    const { data: result } = await request.post(
      '/api/report/report-config',
      payload,
    );
    return result.data;
  }

  public static async edit(uuid: string, payload: CreateReportConfig) {
    const { data: result } = await request.put(
      `/api/report/report-config/${uuid}`,
      payload,
    );
    return result.data;
  }

  public static async delete(uuid: string) {
    const { data: result } = await request.delete(
      `/api/report/report-config/${uuid}`,
    );
    return result.data;
  }

  public static async latestReportHistory(uuid: string) {
    const { data: result } = await request.get(
      `/api/report/report-config/${uuid}/report-history`,
    );

    return result.data;
  }
}

export const createReportFormObj = (report: ReportConfigModel): ReportProps => {
  const {
    name,
    type,
    locations,
    recipients,
    meta,
    schedule,
    deliveryTime,
    createdAt,
    ...rest
  } = report;
  return {
    name,
    type,
    deliveryTime,
    locations: locations.map((location) => location.locationUUID),
    recipients: recipients.map((recipient) => recipient.userUUID),
    schedule,
    meta,
    createdAt: moment(createdAt).format('MM/DD/YYYY'),
    ...rest,
  };
};
