export function useUserRole() {
  function displayRole(userRole?: string) {
    switch (userRole) {
      case 'account_owner':
        return 'Account owner';
      case 'admin':
        return 'Admin';
      case 'user':
        return 'Viewer';
      default:
        return 'No role';
    }
  }

  return {
    displayRole,
  };
}
