export const downloadFile = (url: string): void => {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  a.href = url;
  a.download = '';
  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
