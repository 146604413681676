import {
  FormDropdown,
  GrayItemSelected,
  GrayItemUnselected,
} from '@coinspect/ui';
import styled from 'styled-components';

export const StyledFormDropdown = styled(FormDropdown)`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;

  & .item {
    color: ${GrayItemUnselected};
  }

  & .selected.active.item {
    color: ${GrayItemSelected};
  }
`;
