import { EquipmentModel } from '@coinspect/therma-entities';
import { Reducer } from 'react';

import { NormalAlertModel } from '../hooks';
import {
  AlertNotifModel,
  DeviceModel,
  InviteModel,
  LocationModel,
  NotifConfigModel,
  ReportConfigModel,
  TeamLocationModel,
  TeamModel,
  UserModel,
} from '../services';
import {
  accordionReducer,
  AccordionState,
  activeAlertEntityReducer,
  alertConfigEntityReducer,
  alertNotifConfigRelationshipReducer,
  alertNotifEntityReducer,
  configStoreInitialState,
  configStoreReducer,
  ConfigStoreState,
  dashboardPageReducer,
  DashboardPageState,
  defaultFilters,
  deviceAlertConfigsRelationshipReducer,
  deviceEntityReducer,
  energyLocationsReducer,
  EnergyLocationsState,
  equipmentDevicesRelationshipReducer,
  equipmentReducer,
  featuresReducer,
  FeaturesState,
  initialEnergyLocationsStore,
  initialLocationsStore,
  initialReportPageStore,
  initialTeamsStore,
  initialUserPageStore,
  inviteEntityReducer,
  joyrideFlowInitialState,
  joyrideFlowReducer,
  JoyrideFlowState,
  locationDeviceRelationshipReducer,
  locationEntityReducer,
  locationEquipmentRelationshipReducer,
  locationsReducer,
  LocationsState,
  notifConfigEntityReducer,
  ONBOARDING_KEY,
  queryReducer,
  reportConfigReducer,
  reportPageReducer,
  ReportPageState,
  sensorPageInitialState,
  sensorPageReducer,
  SensorPageState,
  teamLocationReducer,
  teamPageReducer,
  TeamPageState,
  teamsReducer,
  userEntityReducer,
  userPageReducer,
  UserPageState,
} from './reducers';
import {
  BannerAlert,
  bannerAlertsReducer,
} from './reducers/banner-alerts-reducer';
import {
  equipmentGraphReducer,
  EquipmentGraphState,
  initialEquipmentGraphStore,
} from './reducers/equipment-graph-reducer';
export interface NormalRelationship {
  [key: string]: string[];
}

export interface NormalEntity<T> {
  byUUID: {
    [key: string]: T;
  };
  all: string[];
}

export function initialStoreEntity<T>(): NormalEntity<T> {
  return {
    all: [],
    byUUID: {},
  };
}

export interface NormalEntities {
  activeAlert: NormalEntity<AlertNotifModel>;
  alertConfigs: NormalEntity<NormalAlertModel>;
  alertNotifs: NormalEntity<AlertNotifModel>;
  devices: NormalEntity<DeviceModel>;
  locations: NormalEntity<LocationModel>;
  notifConfigs: NormalEntity<NotifConfigModel>;
  users: NormalEntity<UserModel>;
  invites: NormalEntity<InviteModel>;
  teams: NormalEntity<TeamModel>;
  teamLocations: NormalEntity<TeamLocationModel>;
  reportConfig: NormalEntity<ReportConfigModel>;
  equipment: NormalEntity<EquipmentModel>;
}

export interface NormalRelationships {
  alertNotifConfig: NormalRelationship;
  deviceAlertConfigs: NormalRelationship;
  locationDevices: NormalRelationship;
  locationEquipments: NormalRelationship;
  equipmentDevices: NormalRelationship;
}

export interface DataStoreState {
  entities: NormalEntities;
  relationships: NormalRelationships;
  pages: {
    dashboard: DashboardPageState;
    isQueryInProgress: QueryInProgress;
    sensors: SensorPageState;
    users: UserPageState;
    teams: TeamPageState;
    reports: ReportPageState;
    locations: LocationsState;
    energyLocations: EnergyLocationsState;
    equipmentGraph: EquipmentGraphState;
  };
  ui: {
    accordion: AccordionState;
    bannerAlerts: BannerAlert[];
  };
  features: FeaturesState;
  joyrideFlows: JoyrideFlowState;
  config: ConfigStoreState;
}

export interface DataStoreAction {
  type: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  data?: any;
  meta?: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

export interface QueryInProgress {
  [key: string]: boolean | undefined;
}

export const initialStoreState: DataStoreState = {
  entities: {
    activeAlert: initialStoreEntity<AlertNotifModel>(),
    alertConfigs: initialStoreEntity<NormalAlertModel>(),
    alertNotifs: initialStoreEntity<AlertNotifModel>(),
    devices: initialStoreEntity<DeviceModel>(),
    invites: initialStoreEntity<InviteModel>(),
    locations: initialStoreEntity<LocationModel>(),
    notifConfigs: initialStoreEntity<NotifConfigModel>(),
    teamLocations: initialStoreEntity<TeamLocationModel>(),
    teams: initialStoreEntity<TeamModel>(),
    users: initialStoreEntity<UserModel>(),
    reportConfig: initialStoreEntity<ReportConfigModel>(),
    equipment: initialStoreEntity<EquipmentModel>(),
  },
  pages: {
    dashboard: {
      alertNotifTable: { totalCount: 0 },
      devicesColor: {},
      employeeTimeSaved: 0,
      filters: defaultFilters(),
      temperatureGraphData: [],
      humidityGraphData: [],
      isOnboarding:
        localStorage.getItem(ONBOARDING_KEY) === 'true' ? true : false,
      latestSensorData: {},
      showFilterRail: true,
      temperatureUnit: 'f',
      timeAlertFree: 0,
      keysWithGraph: [],
      filterRailMeta: {
        page: 0,
        totalPage: 0,
        totalRecords: 0,
      },
      filterRailSearchKey: '',
      showNiceWork: false,
      showThermaGoals: false,
      flow: '',
      showCongratsScreen: false,
    },
    isQueryInProgress: {},
    sensors: sensorPageInitialState,
    teams: initialTeamsStore,
    users: initialUserPageStore,
    reports: initialReportPageStore,
    locations: initialLocationsStore,
    energyLocations: initialEnergyLocationsStore,
    equipmentGraph: initialEquipmentGraphStore,
  },
  relationships: {
    alertNotifConfig: {},
    deviceAlertConfigs: {},
    locationDevices: {},
    locationEquipments: {},
    equipmentDevices: {},
  },
  ui: {
    accordion: {},
    bannerAlerts: [],
  },
  features: {
    GET_MORE_SENSORS: true, // TODO: To enable this for now and might be change in the future for better solution on enabling or disabling feature
  },
  joyrideFlows: joyrideFlowInitialState,
  config: configStoreInitialState,
};

export const RootReducer: Reducer<DataStoreState, DataStoreAction> = (
  state: DataStoreState,
  action: DataStoreAction,
): DataStoreState => {
  if (action.type === 'dukeTheNuke') {
    return initialStoreState;
  }

  return {
    entities: {
      activeAlert: activeAlertEntityReducer(state.entities.activeAlert, action),
      alertConfigs: alertConfigEntityReducer(
        state.entities.alertConfigs,
        action,
      ),
      alertNotifs: alertNotifEntityReducer(state.entities.alertNotifs, action),
      devices: deviceEntityReducer(state.entities.devices, action),
      equipment: equipmentReducer(state.entities.equipment, action),
      invites: inviteEntityReducer(state.entities.invites, action),
      locations: locationEntityReducer(state.entities.locations, action),
      notifConfigs: notifConfigEntityReducer(
        state.entities.notifConfigs,
        action,
      ),
      teamLocations: teamLocationReducer(state.entities.teamLocations, action),
      teams: teamsReducer(state.entities.teams, action),
      users: userEntityReducer(state.entities.users, action),
      reportConfig: reportConfigReducer(state.entities.reportConfig, action),
    },
    pages: {
      dashboard: dashboardPageReducer(state.pages.dashboard, action),
      isQueryInProgress: queryReducer(state.pages.isQueryInProgress, action),
      sensors: sensorPageReducer(state.pages.sensors, action),
      teams: teamPageReducer(state.pages.teams, action),
      users: userPageReducer(state.pages.users, action),
      reports: reportPageReducer(state.pages.reports, action),
      locations: locationsReducer(state.pages.locations, action),
      energyLocations: energyLocationsReducer(
        state.pages.energyLocations,
        action,
      ) as EnergyLocationsState,
      equipmentGraph: equipmentGraphReducer(state.pages.equipmentGraph, action),
    },
    relationships: {
      alertNotifConfig: alertNotifConfigRelationshipReducer(
        state.relationships.alertNotifConfig,
        action,
      ),
      deviceAlertConfigs: deviceAlertConfigsRelationshipReducer(
        state.relationships.deviceAlertConfigs,
        action,
      ),
      locationDevices: locationDeviceRelationshipReducer(
        state.relationships.locationDevices,
        action,
      ),
      locationEquipments: locationEquipmentRelationshipReducer(
        state.relationships.locationEquipments,
        action,
      ),
      equipmentDevices: equipmentDevicesRelationshipReducer(
        state.relationships.equipmentDevices,
        action,
      ),
    },
    ui: {
      accordion: accordionReducer(state.ui.accordion, action),
      bannerAlerts: bannerAlertsReducer(state.ui.bannerAlerts, action),
    },
    features: featuresReducer(state.features, action),
    joyrideFlows: joyrideFlowReducer(state.joyrideFlows, action),
    config: configStoreReducer(state.config, action),
  };
};
