import axios, { AxiosInstance } from 'axios';

import { getToken } from '../contexts';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.API_PAYMENT_HOST || 'http://localhost:8001',
});
const request = axiosInstance; // alias

axiosInstance.interceptors.request.use((config) => {
  return {
    ...config,
    headers: {
      Authorization: getToken(),
    },
  };
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createPortalSession = async (cancelTokenSource: any = {}) => {
  return request.post(
    '/api/stripe/portal-session',
    {},
    {
      cancelToken: cancelTokenSource.token,
    },
  );
};

export { createPortalSession };
