import { primaryColor } from '@coinspect/ui';
import styled from 'styled-components';

export const Links = styled.a`
  text-decoration: underline;
  color: ${primaryColor};

  &:hover {
    text-decoration: underline;
    color: ${primaryColor};
  }
`;
