import { normalize, schema } from 'normalizr';
import { useContext } from 'react';

import {
  EnergyLocationService,
  Location,
  LocationModel,
  LocationService,
} from '../services';
import { StoreContext } from '../store';
import { useFeatures } from './use-features';

const LocationSchema = new schema.Entity(
  'location',
  {},
  {
    idAttribute: 'uuid',
  },
);

const LocationListSchema = [LocationSchema];

export function useLocationService() {
  const { dispatch, store } = useContext(StoreContext);
  const { isEnergyDashboardEnabled } = useFeatures();

  async function browseLocations(): Promise<void> {
    const locations = await LocationService.browse();
    if (locations && isEnergyDashboardEnabled) {
      const locationUUIDs = locations.map(({ uuid }) => uuid);
      const foundEnergyLocations = await EnergyLocationService.getLocations({
        monitoringLocationUUIDs: locationUUIDs,
      });
      const energyLocations = locations.filter(
        ({ uuid }) =>
          !!foundEnergyLocations.find(
            ({ monitoringLocationUuid }: { monitoringLocationUuid: string }) =>
              monitoringLocationUuid === uuid,
          ),
      );

      // * map energyLocationUUID to monitoringLocationUUID
      const newEnergyLocations = energyLocations.map((location) => {
        return {
          ...location,
          energyLocationUUID: foundEnergyLocations.find(
            ({ monitoringLocationUuid }: { monitoringLocationUuid: string }) =>
              monitoringLocationUuid === location.uuid,
          ).uuid,
        };
      });

      if (newEnergyLocations) {
        dispatch({
          data: newEnergyLocations,
          type: 'energyLocations:set',
        });
      }
    }

    const normalized = normalize(locations, LocationListSchema);
    dispatch({
      data: normalized,
      type: 'allLocations:set',
    });
  }

  async function browseLocationsPaginated(
    {
      page,
      limit,
    }: {
      page: number;
      limit: number;
    },
    isReset?: boolean,
  ): Promise<{
    locations: LocationModel[];
    meta: { page: number; limit: number; total: number };
  }> {
    dispatch({ type: 'location:browse:paginated_REQUEST' });

    try {
      const locationsData = await LocationService.browseLocationsPaginated({
        page,
        limit,
      });

      const normalized = normalize(locationsData.locations, LocationListSchema);

      dispatch({
        data: normalized,
        type: isReset ? 'location:reset' : 'location:set',
      });

      return locationsData;
    } finally {
      dispatch({ type: 'location:browse:paginated_FINALLY' });
    }
  }

  async function addLocation(payload: Location): Promise<LocationModel | null> {
    dispatch({ type: 'location:add_REQUEST' });
    let location;
    try {
      location = await LocationService.add(payload);
      const normalized = normalize([location], LocationListSchema);
      dispatch({
        data: normalized,
        type: 'location:add',
      });
    } finally {
      dispatch({ type: 'location:add_FINALLY' });
    }

    return location;
  }

  async function editLocation(uuid: string, payload: Location): Promise<void> {
    dispatch({ type: 'location:edit_REQUEST' });
    try {
      const location = await LocationService.edit(uuid, payload);
      const normalized = normalize([location], LocationListSchema);
      dispatch({
        data: normalized,
        type: 'location:edit',
      });
    } finally {
      dispatch({ type: 'location:edit_FINALLY' });
    }
  }

  async function deleteLocation(locationUUID: string): Promise<void> {
    dispatch({ type: 'location:delete_REQUEST' });

    try {
      const data = await LocationService.delete(locationUUID);
      dispatch({
        data: locationUUID,
        type: 'location:delete',
      });
      dispatch({
        type: 'sensorPage:activeForm:reset',
      });

      dispatch({
        data: locationUUID,
        type: 'device:delete_cascade',
      });

      return data;
    } finally {
      dispatch({ type: 'location:delete_FINALLY' });
    }
  }

  function resetLocations() {
    dispatch({
      type: 'location:hard_reset',
    });

    return Promise.resolve();
  }

  async function searchLocations(keyword = ''): Promise<LocationModel[]> {
    dispatch({ type: 'location:search_REQUEST' });
    try {
      const locations = await LocationService.searchLocations(keyword);

      const normalized = normalize(locations, LocationListSchema);

      dispatch({
        data: normalized,
        type: 'location:set',
      });

      return locations;
    } finally {
      dispatch({ type: 'location:search_FINALLY' });
    }
  }

  async function searchGooglePlaces(keyword = '') {
    try {
      const locations = await LocationService.searchGooglePlaces(keyword);
      return locations;
    } catch {
      return [];
    }
  }

  async function getLocationInfo(locationUUID: string): Promise<LocationModel> {
    return store.entities.locations.byUUID[locationUUID];
  }

  async function searchGeocode(keyword = '') {
    try {
      const locations = await LocationService.searchGeocode(keyword);
      return locations;
    } catch {
      return [];
    }
  }

  async function searchAutoComplete(keyword = '') {
    try {
      const locations = await LocationService.searchAutocomplete(keyword);

      return locations;
    } catch {
      return [];
    }
  }

  return {
    addLocation,
    browseLocations,
    browseLocationsPaginated,
    deleteLocation,
    editLocation,
    resetLocations,
    searchLocations,
    searchGooglePlaces,
    getLocationInfo,
    searchGeocode,
    searchAutoComplete,
  };
}
