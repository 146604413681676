/**
 * https://stackoverflow.com/a/42234988/4110257
 * Detects if user clicks outside the wrapped component
 */
import React, { useEffect, useRef } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter<T extends HTMLElement, D>(
  ref: React.RefObject<T>,
  outsideClickEvent: () => void,
  data?: D,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current?.contains(event.target as Node)) {
        outsideClickEvent();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, data]);
}

type OutsideAlerterProps = {
  children: React.ReactNode;
  outsideClickEvent: () => void;
};

/**
 * Component that alerts if you click outside of it
 */
export const OutsideAlerter = (props: OutsideAlerterProps) => {
  const { children, outsideClickEvent } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, outsideClickEvent);

  return <div ref={wrapperRef}>{children}</div>;
};
