import {
  CheckBoxButton,
  FlexPaddedContent,
  GhostList,
  GhostListItem,
  PillContent,
  PillIcon,
  SearchInput,
} from '@coinspect/ui';
import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Divider, Header, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { isTabletAndBelow } from '../../../constants';
import { SlidingSidebarContext } from '../../../contexts';
import {
  SearchFilterOptions,
  SearchResult,
  SearchResultMeta,
} from '../../../hooks';
import { DeviceModel } from '../../../services/device-service';
import { StoreContext } from '../../../store';
import { Tooltip } from '../../tooltip';
import { ClearFilterButton } from './clear-filter-button';
import { DashboardTimeFilter } from './dashboard-time-filter';
import { PlaceholderDeviceSearch } from './placeholder-device';
import { SearchLocationAccordion } from './search-location-accordion';
import { SelectableDeviceList } from './selectable-device-list';

interface DisplayLocationDevices {
  locations: string[];
  locationDevices: { [key: string]: string[] };
}

const SelectAllItems = styled(CheckBoxButton)`
  margin: 10px 30px;
`;

const FilterHeader = styled(FlexPaddedContent)`
  height: 100px;
`;

const FilterIcons = styled(PillIcon)`
  && {
    margin: 0 10px 0 0;
  }
`;

type SearchProps = {
  deviceSearchFilter: {
    data: SearchResult[];
    setOptions: React.Dispatch<React.SetStateAction<SearchFilterOptions>>;
    isLoading: boolean;
    meta: SearchResultMeta;
  };
};

export const Search = (props: SearchProps): JSX.Element => {
  const { deviceSearchFilter } = props;
  const { store, dispatch } = useContext(StoreContext);
  const { filterRailSearchKey: searchKey } = store.pages.dashboard;
  const [isAllFilteredSelected, setIsAllFilteredSelected] = useState<boolean>(
    false,
  );
  const { closeSidebar } = useContext(SlidingSidebarContext);
  const [displayLocationDevices, setDisplayLocationDevicesState] = useState<
    DisplayLocationDevices
  >({ locations: [], locationDevices: {} });
  const { locations, devices, teamLocations } = store.entities;
  const { locationDevices } = store.relationships;
  const {
    isQueryInProgress,
    dashboard: {
      filterRailMeta: { page, totalPage = 0, totalRecords },
    },
  } = store.pages;
  const isFetchingDevices = isQueryInProgress['location-device:get'];
  const [pageData, setPageData] = useState({
    page: 1,
    pageSize: 5, // placeholder page size
  });

  const setDisplayLocationDevices = (data: DisplayLocationDevices) => {
    setDisplayLocationDevicesState({
      locationDevices: data.locationDevices,
      locations: data.locations || [],
    });
  };

  const allFilteredItems = (): string[] =>
    displayLocationDevices.locations.reduce(
      (acc: string[], locationUUID: string) => {
        return acc.concat(displayLocationDevices.locationDevices[locationUUID]);
      },
      [],
    );

  const checkAllFilteredSelection = (): void => {
    if (searchKey.length === 0) {
      return;
    }
    setIsAllFilteredSelected(
      allFilteredItems().every((uuid: string) =>
        store.pages.dashboard.filters.devices.includes(uuid),
      ),
    );
  };

  useEffect(() => {
    setDisplayLocationDevices({
      locationDevices,
      locations: locations.all,
    });

    deviceSearchFilter.setOptions({
      page: pageData.page,
      perPage: 5,
    });
  }, [pageData]);

  useEffect(() => {
    setDisplayLocationDevices({
      locationDevices,
      locations: locations.all,
    });
  }, [locations.all, devices.all, teamLocations, deviceSearchFilter.data]);

  useEffect(() => {
    checkAllFilteredSelection();
  }, [store.pages.dashboard.filters.devices]);

  useEffect(() => {
    // deviceSearchFilter.setSearchKey(searchKey);
    if (searchKey) {
      setPageData({
        ...pageData,
        page: 1,
      });
    }
  }, [searchKey]);

  const getDevices = (locationUUID: string): DeviceModel[] => {
    if (!displayLocationDevices.locationDevices[locationUUID]) {
      return [];
    }

    return displayLocationDevices.locationDevices[locationUUID].reduce(
      (acc: DeviceModel[], uuid) => {
        if (!devices.byUUID[uuid]) {
          return acc;
        }

        return [...acc, devices.byUUID[uuid]];
      },
      [],
    );
  };

  const getDeviceCount = (locationUUID: string): number =>
    locationDevices[locationUUID] ? locationDevices[locationUUID].length : 0;

  function updateDeviceFilters(add: boolean, data: string[]): void {
    const type = `dashboard:deviceFilter:${add ? 'add' : 'remove'}`;
    dispatch({
      data,
      type,
    });
    dispatch({
      data: {
        currentPage: 0,
      },
      type: 'dashboard:currentPage:set',
    });
  }

  function clearSearchKey(): void {
    dispatch({
      type: 'dashboard:filterRail:search:set',
      data: '',
    });
  }

  const handleFilteredSelectAll = (): void => {
    const allFiltered = allFilteredItems();
    updateDeviceFilters(!isAllFilteredSelected, allFiltered);
  };

  return (
    <>
      <FilterHeader className="filter-header">
        <Tooltip
          content="Hide filters"
          trigger={
            <span>
              <FilterIcons
                className="icon-filters therma-blue filter-header__icon--filters"
                size="big"
                onClick={
                  isTabletAndBelow()
                    ? closeSidebar
                    : (): void =>
                        dispatch({ type: 'dashboard:filterRail:toggle' })
                }
              />
            </span>
          }
        />
        <PillContent className="header">
          <Header as="h1" className="filter-header__header--filters">
            Filters
          </Header>
        </PillContent>
      </FilterHeader>
      <Divider fitted />
      <FlexPaddedContent size="0 30px">
        <Tooltip
          content="Filter by date range"
          trigger={
            <span>
              <FilterIcons
                className="icon-date-filter"
                size="big"
                color="grey"
              />
            </span>
          }
        />
        <PillContent>
          <DashboardTimeFilter closeRail={closeSidebar} />
        </PillContent>
      </FlexPaddedContent>
      <Divider fitted />
      <SearchInput
        className="search-input"
        loading={isFetchingDevices}
        defaultValue={searchKey}
        onChange={(val: string) => {
          dispatch({
            type: 'dashboard:filterRail:search:set',
            data: val,
          });
        }}
      />
      <Divider fitted />
      <Accordion>
        <GhostList className="filtered-device-list">
          {searchKey.length > 0 && (
            <GhostListItem key="SelectAll">
              {displayLocationDevices.locations.length !== 0 && (
                <SelectAllItems
                  className="filtered-device-list__button--selectAll"
                  value={'all'}
                  isActive={isAllFilteredSelected}
                  onChange={handleFilteredSelectAll}
                  content={
                    isAllFilteredSelected ? 'Deselect all' : 'Select all'
                  }
                />
              )}
            </GhostListItem>
          )}

          {isFetchingDevices && <PlaceholderDeviceSearch />}

          {!isFetchingDevices &&
            displayLocationDevices.locations.map((locationUUID: string) => {
              const location = locations.byUUID[locationUUID];
              if (!location) {
                return;
              }

              return (
                <GhostListItem
                  className="search-location-list"
                  key={locationUUID}
                >
                  <SearchLocationAccordion
                    className="search-location-list__name"
                    location={location}
                    deviceCount={getDeviceCount(locationUUID)}
                  >
                    <>
                      {displayLocationDevices.locationDevices.hasOwnProperty(
                        locationUUID,
                      ) && (
                        <SelectableDeviceList
                          className="search-location-list__sensors"
                          showSelectAll={searchKey.length === 0}
                          totalItems={
                            (locationDevices[locationUUID] || []).length
                          }
                          items={getDevices(locationUUID)}
                          onUpdate={updateDeviceFilters}
                          selections={store.pages.dashboard.filters.devices}
                        />
                      )}
                    </>
                  </SearchLocationAccordion>
                </GhostListItem>
              );
            })}
        </GhostList>
      </Accordion>
      <FlexPaddedContent>
        <PillContent>
          <ClearFilterButton
            id="button--clear-filter"
            clearSearchBar={clearSearchKey}
            searchBarValue={searchKey}
          />
        </PillContent>
      </FlexPaddedContent>
      {!totalRecords ? null : (
        <FlexPaddedContent>
          <div>{`${page} of ${totalPage}`}</div>
          {totalPage > 1 && (
            <div>
              <Tooltip
                content="Previous"
                trigger={
                  <Icon
                    className="search-prev-icon"
                    name="chevron left"
                    onClick={() => {
                      setPageData({
                        ...pageData,
                        page: pageData.page - 1,
                      });
                    }}
                    disabled={pageData.page === 1 || isFetchingDevices}
                  />
                }
              />
              <Tooltip
                content="Next"
                trigger={
                  <Icon
                    className="search-next-icon"
                    name="chevron right"
                    onClick={() => {
                      setPageData({
                        ...pageData,
                        page: pageData.page + 1,
                      });
                    }}
                    disabled={pageData.page >= totalPage || isFetchingDevices}
                  />
                }
              />
            </div>
          )}
        </FlexPaddedContent>
      )}
    </>
  );
};
