import React, { FunctionComponent } from 'react';

export const FoodProtectedIcon: FunctionComponent = () => {
  return (
    <span>
      <svg
        width="48"
        height="49"
        viewBox="0 0 48 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="48" height="48" rx="24" fill="#E1EDF1" />
        <g clipPath="url(#clip0_1733_15259)">
          <path
            d="M24.005 29.6499H18.755C18.5561 29.6499 18.3653 29.7289 18.2247 29.8696C18.084 30.0102 18.005 30.201 18.005 30.3999V34.8999C18.005 35.0988 18.084 35.2896 18.2247 35.4302C18.3653 35.5709 18.5561 35.6499 18.755 35.6499H23.255C23.4539 35.6499 23.6447 35.5709 23.7853 35.4302C23.926 35.2896 24.005 35.0988 24.005 34.8999V29.6499Z"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.255 29.6499H24.005V34.8999C24.005 35.0988 24.084 35.2896 24.2247 35.4302C24.3653 35.5709 24.5561 35.6499 24.755 35.6499H29.255C29.4539 35.6499 29.6447 35.5709 29.7853 35.4302C29.926 35.2896 30.005 35.0988 30.005 34.8999V30.3999C30.005 30.201 29.926 30.0102 29.7853 29.8696C29.6447 29.7289 29.4539 29.6499 29.255 29.6499Z"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.255 23.6499H21.755C21.5561 23.6499 21.3653 23.7289 21.2247 23.8696C21.084 24.0102 21.005 24.201 21.005 24.3999V29.6499H27.005V24.3999C27.005 24.201 26.926 24.0102 26.7853 23.8696C26.6447 23.7289 26.4539 23.6499 26.255 23.6499Z"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.255 35.6501V20.0501C35.2551 19.7782 35.1813 19.5113 35.0414 19.2781C34.9016 19.0449 34.7009 18.8541 34.461 18.7261L24.711 13.5261C24.4939 13.4104 24.2516 13.3499 24.0055 13.3499C23.7594 13.3499 23.5172 13.4104 23.3 13.5261L13.55 18.7261C13.3099 18.854 13.1091 19.0447 12.969 19.2779C12.829 19.5112 12.755 19.7781 12.755 20.0501V35.6501"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1733_15259">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(12 12.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};
