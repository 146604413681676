import React from 'react';

export const ReportsIcon = ({ fill = '#B2FFFF' }: { fill?: string }) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10C4.71667 10 4.47933 9.904 4.288 9.712C4.096 9.52067 4 9.28333 4 9C4 8.71667 4.096 8.479 4.288 8.287C4.47933 8.09567 4.71667 8 5 8H11C11.2833 8 11.521 8.09567 11.713 8.287C11.9043 8.479 12 8.71667 12 9C12 9.28333 11.9043 9.52067 11.713 9.712C11.521 9.904 11.2833 10 11 10H5ZM5 6C4.71667 6 4.47933 5.90433 4.288 5.713C4.096 5.521 4 5.28333 4 5C4 4.71667 4.096 4.479 4.288 4.287C4.47933 4.09567 4.71667 4 5 4H11C11.2833 4 11.521 4.09567 11.713 4.287C11.9043 4.479 12 4.71667 12 5C12 5.28333 11.9043 5.521 11.713 5.713C11.521 5.90433 11.2833 6 11 6H5ZM2 12H9.5C9.98333 12 10.4333 12.104 10.85 12.312C11.2667 12.5207 11.6167 12.8167 11.9 13.2L14 15.95V2H2V12ZM2 18H13.05L10.325 14.425C10.225 14.2917 10.1043 14.1877 9.963 14.113C9.821 14.0377 9.66667 14 9.5 14H2V18ZM14 20H2C1.45 20 0.979333 19.8043 0.588 19.413C0.196 19.021 0 18.55 0 18V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H14C14.55 0 15.021 0.195667 15.413 0.587C15.8043 0.979 16 1.45 16 2V18C16 18.55 15.8043 19.021 15.413 19.413C15.021 19.8043 14.55 20 14 20ZM2 18V2V18ZM2 14V12V14Z"
      fill={fill}
    />
  </svg>
);
