import { FormError } from '@coinspect/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { EnergyFieldErrorStyle } from '../../../style/global-energy-styles';
import { Button } from '../../buttons';
import SettingsSuggest from '../energy-dashboard-page/icons/SettingsSuggest';
import { SetpointInput } from './setpoint-input';
import { OccupancyType } from './types';

type SetpointModuleProps = {
  type: OccupancyType;
  hspName: string;
  cspName: string;
  isDisabled: boolean;
  onRecommendationClick?: () => void;
};

const SetpointModuleContainer = styled.div`
  display: flex;
  background-color: #1c2124;
  width: 500px;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
`;

const ButtonContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SubHeader = styled.h3`
  font-size: 18px;
  font-weight: 400;
  font-family: proxima-nova;
`;

const Description = styled.p`
  color: #a1aaaa;
`;

const SetpointInputContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-around;
`;

export const SetpointModule = ({
  type,
  hspName,
  cspName,
  isDisabled,
  onRecommendationClick,
}: SetpointModuleProps) => {
  const formMethods = useFormContext();
  return (
    <SetpointModuleContainer>
      <SubHeader>Setpoints for {type} hours</SubHeader>
      <Description>
        Set temperatures for the hours that this location is{' '}
        {type === 'occupied' ? type : 'empty'}. The heating setpoint indicates
        how cold the space will be before starting to heat. The cooling setpoint
        indicates how warm the space will be before starting to cool.{' '}
      </Description>
      <SetpointInputContainer>
        <div>
          <SetpointInput
            showrequired
            className="energy-user-fields"
            name={hspName}
            label="Heating setpoint"
            labelClassName="energy-user-label"
            required
            customStyle={EnergyFieldErrorStyle}
            hideErors
            disabled={isDisabled}
          />

          {formMethods.errors[hspName] && (
            <FormError
              error={{ type: 'required' }}
              customErrors={{
                required: 'Please set heating setpoint.',
              }}
              customStyle={EnergyFieldErrorStyle}
            />
          )}
        </div>
        <div>
          <SetpointInput
            showrequired
            className="energy-user-fields"
            name={cspName}
            label="Cooling setpoint"
            labelClassName="energy-user-label"
            required
            customStyle={EnergyFieldErrorStyle}
            hideErors
            disabled={isDisabled}
          />
          {formMethods.errors[cspName] && (
            <FormError
              error={{ type: 'required' }}
              customErrors={{
                required: 'Please set cooling setpoint.',
              }}
              customStyle={EnergyFieldErrorStyle}
            />
          )}
        </div>
      </SetpointInputContainer>
      {onRecommendationClick && (
        <Button
          disabled={isDisabled}
          as="text"
          style={{ alignSelf: 'center', marginTop: '12px' }}
          onClick={onRecommendationClick}
        >
          <ButtonContentContainer>
            <Icon fill="#97DBF8" as={SettingsSuggest} />
            <span>Use Recommendation</span>
          </ButtonContentContainer>
        </Button>
      )}
    </SetpointModuleContainer>
  );
};
