import {
  darkViolet55,
  darkViolet80,
  greyGeyser45,
  warnColor,
} from '@coinspect/ui';
import truncate from 'lodash/truncate';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Divider,
  Icon,
  Placeholder,
  Popup,
  Ref,
  Segment,
  Table,
} from 'semantic-ui-react';
import { mobileMaxWidth } from 'src/constants';
import styled from 'styled-components';

import { LocalStorageContext } from '../../contexts';
import { useFetchAlertNotif } from '../../hooks/use-fetch-alert-notif';
import { AlertNotifRecipient } from '../../services';
import { StoreContext } from '../../store/';
import { extractLog } from '../../utils/alert-util';
import { Tooltip } from '../tooltip';
import { DashboardSubHeading } from './';
import DashboardPlaceholder from './dashboard-placeholder';

interface TableFooterProps {
  onNext: () => void;
  onPrev: () => void;
  startRow: number;
  endRow: number;
  totalCount: number;
  newPage: number;
  maxPage: number;
  isDisabled: boolean;
  currentPage: number;
}

export interface RowDataModel {
  alert: string;
  date: string;
  endedAt: Date | undefined;
  recipients: AlertNotifRecipient[];
  sentTo: string;
  time: string;
  field: string;
  deviceName: string | undefined;
  locationName: string | undefined;
  activeMins: number | null;
  triggerValue: number | undefined;
  rawSummary: string | undefined;
}

const PaginationButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

const TextTruncate = styled.div`
  && {
    max-width: 150px;
    cursor: pointer;
  }
`;

const AlertNotifTableWrapper = styled.div`
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    padding: 10px 25px;
  }

  .segment {
    padding: 0;
  }

  .header {
    padding: 25px;
    padding-bottom: 0;
  }

  &&& tr > td {
    border-top: 2px solid ${greyGeyser45};
  }

  tr {
    color: ${darkViolet80};
  }

  tr.warning-row {
    color: ${warnColor};
  }

  && tr > th {
    border-bottom: 0;
    color: ${darkViolet80};
  }

  .span-grey {
    color: ${darkViolet55};
  }

  &&&&& tr > td.warning-sign-cell {
    padding-left: 25px;
    padding-right: 0;
    text-align: right;
    width: 30px;
  }

  &&&&& tr > td:last-child {
    padding-right: 46px;
  }

  &&&&& tr > td.footer-cell {
    padding-right: 46px;
    text-align: right;
  }
`;

const TableLoading = styled(Table)`
  height: ${({ height }) => height}px;
`;

const popupStyle = {
  border: 'none',
  color: darkViolet80,
  fontSize: '14px',
  fontWeight: 'bold',
  padding: '26px',
};

const AlertTableHeading = styled(DashboardSubHeading)`
  &&&& {
    padding-bottom: 15px;
  }
`;

function TableHeader(props: { iconCellWidth?: string }) {
  const { iconCellWidth: width = '50px' } = props;

  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell style={{ width }}></Table.HeaderCell>
        <Table.HeaderCell className="past-alert-log__date-label" width={3}>
          Date
        </Table.HeaderCell>
        <Table.HeaderCell className="past-alert-log__time-label" width={3}>
          Time
        </Table.HeaderCell>
        <Table.HeaderCell className="past-alert-log__alert-label" width={6}>
          Alert
        </Table.HeaderCell>
        <Table.HeaderCell className="past-alert-log__sent-to-label" width={3}>
          Sent to
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
}

const TableFooter: React.FC<TableFooterProps> = (props) => {
  const {
    onNext,
    onPrev,
    startRow = 1,
    endRow,
    totalCount,
    newPage,
    maxPage,
    isDisabled,
  } = props;

  if (!totalCount) {
    return null;
  }

  return (
    <Table.Footer>
      <Table.Row>
        <Table.Cell className="footer-cell" colSpan={5}>
          <span>
            {startRow} - {endRow}
          </span>
          <span className="span-grey"> of {totalCount}</span>
          {maxPage > 1 && (
            <>
              <Tooltip
                content="Previous"
                trigger={
                  <PaginationButton
                    onClick={onPrev}
                    disabled={newPage === 0 || isDisabled}
                  >
                    <Icon
                      className="past-alert-log__left-arrow"
                      name="chevron left"
                    />
                  </PaginationButton>
                }
              />
              <Tooltip
                content="Next"
                trigger={
                  <PaginationButton
                    onClick={onNext}
                    disabled={newPage + 1 === maxPage || isDisabled}
                  >
                    <Icon
                      className="past-alert-log__right-arrow"
                      name="chevron right"
                    />
                  </PaginationButton>
                }
              />
            </>
          )}
        </Table.Cell>
      </Table.Row>
    </Table.Footer>
  );
};

export function AlertNotifTable(props: { isFetching: boolean }) {
  /**
   * ? HACK: Return a placeholder element while graph is still fetching
   * ? to not trigger the hook to fetch alert notifs
   */
  if (props.isFetching) {
    return (
      <AlertNotifTableWrapper>
        <Segment>
          <AlertTableHeading
            className="past-alert-log__header"
            heading="Past alerts log"
          />
          <Divider className="flat" />
          <TableLoading
            padded
            basic="very"
            className="table--layout-fixed"
            height={0}
          >
            <TableHeader />
            <Table.Body>
              {Array(5)
                .fill(0)
                .map((z, idx) => {
                  return (
                    <Table.Row key={`placeholder-${idx}`}>
                      <Table.Cell
                        className="warning-sign-cell"
                        style={{ height: '50px' }}
                      ></Table.Cell>
                      <Table.Cell>
                        <Placeholder fluid>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      </Table.Cell>
                      <Table.Cell>
                        <Placeholder fluid>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      </Table.Cell>
                      <Table.Cell>
                        <Placeholder fluid>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      </Table.Cell>
                      <Table.Cell>
                        <Placeholder fluid>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
            {/* <TableFooter {...tableFooterProps} /> */}
          </TableLoading>
        </Segment>
      </AlertNotifTableWrapper>
    );
  }

  const { store } = useContext(StoreContext);
  const { setItem, getItem } = useContext(LocalStorageContext);
  const { alertNotifs, devices } = store.entities;
  const {
    alertNotifTable,
    filters,
    currentDesktopPage = 0,
  } = store.pages.dashboard;
  const hasLoadedDevices = devices.all.length ? true : false;
  const { totalCount } = alertNotifTable || {};
  const limit = 5;
  const {
    handlePaginationClick,
    setPageThenFetch,
    isLoading,
    displayPreferredTemp,
    handleCancelToken,
    newDesktopPage = 0,
  } = useFetchAlertNotif({
    limit,
  });
  const currentLocalDesktopPage = () =>
    Number(getItem('currentDesktopPage', currentDesktopPage));
  const maxPage = Math.ceil(totalCount / limit);
  const startRow = Math.max(1, limit * currentLocalDesktopPage() + 1);
  const endRow = Math.min(limit * (currentLocalDesktopPage() + 1), totalCount);

  const tableRef = useRef<HTMLTableElement>(null);
  const [prevTableHeight, setPrevTableHeight] = useState<number>(0);

  const fetchLogs = () => {
    setPageThenFetch({
      nextPage: currentDesktopPage,
      cancelToken: handleCancelToken().token,
    });
  };

  const onNext = () => {
    const options = {
      isDesktop: true,
    };
    handlePaginationClick(1, options);
  };

  const onPrev = () => {
    const options = {
      isDesktop: true,
    };
    handlePaginationClick(-1, options);
  };

  const tableFooterProps = {
    onNext,
    onPrev,
    startRow,
    endRow,
    maxPage,
    totalCount,
    newPage: newDesktopPage,
    currentPage: currentDesktopPage,
    isDisabled: isLoading,
  };

  useEffect(() => {
    if (!isLoading) {
      if (tableRef && tableRef.current) {
        setPrevTableHeight(tableRef.current.clientHeight);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    setItem('currentLocalPage', 0);
    setItem('currentDesktopPage', 0);
  }, []);

  useEffect(() => {
    fetchLogs();
  }, [filters]);

  const DisplayEmpty = () => {
    const placeholder = () => {
      return !hasLoadedDevices ? (
        <DashboardPlaceholder type="alerts" />
      ) : (
        <p>No results found. Please try another search.</p>
      );
    };
    return (
      <>
        {!alertNotifs.all.length && (
          <Table.Row>
            <Table.Cell
              textAlign="center"
              colSpan="5"
              className="past-alert-log__empty-state"
            >
              {placeholder()}
            </Table.Cell>
          </Table.Row>
        )}
      </>
    );
  };

  return (
    <AlertNotifTableWrapper>
      <Segment>
        <AlertTableHeading
          className="past-alert-log__header"
          heading="Past alerts log"
        />
        <Divider className="flat" />
        {isLoading && (
          <TableLoading
            padded
            basic="very"
            className="table--layout-fixed"
            height={prevTableHeight}
          >
            <TableHeader />
            <Table.Body>
              {Array(alertNotifs.all.length || 5)
                .fill(0)
                .map((z, idx) => {
                  return (
                    <Table.Row key={`placeholder-${idx}`}>
                      <Table.Cell
                        className="warning-sign-cell"
                        style={{ height: '50px' }}
                      ></Table.Cell>
                      <Table.Cell>
                        <Placeholder fluid>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      </Table.Cell>
                      <Table.Cell>
                        <Placeholder fluid>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      </Table.Cell>
                      <Table.Cell>
                        <Placeholder fluid>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      </Table.Cell>
                      <Table.Cell>
                        <Placeholder fluid>
                          <Placeholder.Line length="full" />
                        </Placeholder>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
            <TableFooter {...tableFooterProps} />
          </TableLoading>
        )}
        {!isLoading && (
          <Ref innerRef={tableRef}>
            <Table padded basic="very" className="table--layout-fixed">
              <TableHeader />
              <Table.Body>
                <DisplayEmpty />
                {alertNotifs.all.map((id: string) => {
                  const rowData = extractLog(alertNotifs.byUUID[id]);
                  return (
                    <Table.Row
                      key={id}
                      className={!rowData.endedAt ? 'warning-row' : ''}
                    >
                      <Table.Cell
                        className="warning-sign-cell"
                        style={{ height: '50px' }}
                      >
                        {!rowData.endedAt && (
                          <Tooltip
                            content="Active alert"
                            trigger={
                              <Icon
                                className="icon-warning"
                                size="big"
                                color="red"
                              />
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell className="past-alert-log__row-date">
                        {rowData.date}
                      </Table.Cell>
                      <Table.Cell className="past-alert-log__row-time">
                        {rowData.time}
                      </Table.Cell>
                      <Table.Cell>
                        <div className="break-word">
                          {rowData.field === 'temperature'
                            ? truncate(displayPreferredTemp(rowData), {
                                length: 200,
                              })
                            : truncate(rowData.alert, { length: 200 })}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <Popup
                          style={popupStyle}
                          trigger={
                            <TextTruncate className="ellipse-text">
                              {rowData.sentTo}
                            </TextTruncate>
                          }
                          position="bottom left"
                          basic
                          wide="very"
                        >
                          {rowData.recipients &&
                            rowData.recipients.map(
                              (recipient: AlertNotifRecipient) => (
                                <p
                                  className="past-alert-log__recipient"
                                  key={recipient.uuid}
                                >
                                  {recipient.firstName} {recipient.lastName}
                                </p>
                              ),
                            )}
                        </Popup>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <TableFooter {...tableFooterProps} />
            </Table>
          </Ref>
        )}
      </Segment>
    </AlertNotifTableWrapper>
  );
}
