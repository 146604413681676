import React, { FunctionComponent } from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

const LaptopSvg = styled('svg')`
  max-width: 352px;
  width: 100%;
`;

interface ErrorPageProps {
  error?: string;
}

const ErrorPageWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 16%;
  p {
    text-align: left;
  }
`;

const LaptopImage = () => {
  return (
    <LaptopSvg
      xmlns="http://www.w3.org/2000/svg"
      width="351"
      height="217"
      viewBox="0 0 351 217"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform="translate(-546 -369) translate(548 369)"
      >
        <rect
          width="218"
          height="137"
          x="64"
          y="64"
          stroke="#D1D7E3"
          strokeWidth="4"
          rx="4"
        />
        <rect
          width="190"
          height="111"
          x="78"
          y="77"
          stroke="#D1D7E3"
          strokeWidth="4"
          rx="4"
        />
        <rect
          width="266"
          height="14"
          x="40"
          y="201"
          stroke="#D1D7E3"
          strokeWidth="4"
          rx="4"
        />
        <g fill="#D1D7E3" fillRule="nonzero">
          <path
            d="M2.735 53.265a.784.784 0 00.567.235c.215 0 .411-.078.568-.235L7 50.135l3.13 3.13a.784.784 0 00.568.235.803.803 0 00.567-1.37L8.135 49l3.13-3.13a.803.803 0 00-1.135-1.135L7 47.865l-3.13-3.13a.803.803 0 00-1.135 1.135L5.865 49l-3.13 3.13a.803.803 0 000 1.135zM30.657 12.343a.522.522 0 00.378.157.522.522 0 00.378-.157l2.087-2.086 2.087 2.086a.522.522 0 00.378.157.535.535 0 00.378-.913L34.257 9.5l2.086-2.087a.535.535 0 00-.756-.756L33.5 8.743l-2.087-2.086a.535.535 0 00-.756.756L32.743 9.5l-2.086 2.087a.535.535 0 000 .756zM17.5 33v18a1.5 1.5 0 003 0V33a1.5 1.5 0 00-3 0zM19 29a9 9 0 100-18 9 9 0 000 18zm0-3a6 6 0 110-12 6 6 0 010 12zM35 41a6 6 0 100-12 6 6 0 000 12zm0-3a3 3 0 110-6 3 3 0 010 6zM5 10A5 5 0 105 0a5 5 0 000 10zm0-3a2 2 0 110-4 2 2 0 010 4zM23 40.5V51a1.5 1.5 0 003 0V40.5a1.5 1.5 0 00-3 0z"
            transform="translate(153)"
          />
        </g>
        <g stroke="#D1D7E3" strokeLinecap="round" strokeWidth="4">
          <path d="M347 1H18M11 1H0" transform="matrix(-1 0 0 1 347 214)" />
        </g>
        <g fill="#D1D7E3" fillRule="nonzero" stroke="#D1D7E3" strokeWidth="1.5">
          <path
            d="M26.621 53.242C11.941 53.242 0 41.301 0 26.622 0 11.941 11.942 0 26.621 0c14.68 0 26.621 11.943 26.621 26.621a1.522 1.522 0 11-3.044 0c0-13.001-10.577-23.58-23.579-23.58S3.04 13.62 3.04 26.621C3.04 39.622 13.617 50.2 26.62 50.2c8.318 0 16.11-4.458 20.335-11.634a1.521 1.521 0 012.623 1.544c-4.767 8.1-13.565 13.133-22.956 13.133z"
            transform="translate(145 105)"
          />
          <path
            d="M51.72 28.143a1.52 1.52 0 01-.771-.21l-9.534-5.63a1.52 1.52 0 111.548-2.618l8.318 4.912 5.354-7.668a1.521 1.521 0 012.494 1.74l-6.161 8.821a1.518 1.518 0 01-1.248.653z"
            transform="translate(145 105)"
          />
        </g>
      </g>
    </LaptopSvg>
  );
};

export const ErrorPage: FunctionComponent<ErrorPageProps> = (props) => {
  const { error } = props;
  return (
    <ErrorPageWrapper>
      <LaptopImage />
      <Header as="h2">
        Something unexpected has occurred.
        <Header.Subheader>Please reload the page.</Header.Subheader>
      </Header>
      {process.env.STAGE === 'development' && (
        <details style={{ whiteSpace: 'pre-wrap' }}>
          <summary>Details</summary>
          <p>{error}</p>
        </details>
      )}
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
