import React, { FunctionComponent, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Loader, Message } from 'semantic-ui-react';
import styled from 'styled-components';

import { AccountContext } from '../../contexts/account-context';
import { useSessionPortal } from '../../hooks/use-session-portal';
import { AppLogo } from '../base/logo.svg';

const SessionPortalPageWrapper = styled.div`
  height: calc(100vh - 72px); /* height of navbar */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  margin-top: 75px;
  position: relative;
`;

const SessionPortalPage: FunctionComponent = () => {
  const accountContext = useContext(AccountContext);
  const { user } = accountContext;
  const { url: sessionPortalURL = '', isFetching, error } = useSessionPortal();

  if (!user || user.role !== 'account_owner') {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <SessionPortalPageWrapper>
      <div>
        <AppLogo
          width="300"
          height="100%"
          aria-label="GlacierGrid Logo"
          heightIE="65px"
          color="#000"
        />
        <LoaderContainer>
          {isFetching && (
            <Loader active content="Requesting session portal link" />
          )}
          {!isFetching && !error && (
            <Message success>
              <Message.Header>Session successfully created</Message.Header>
              <p>
                <a href={sessionPortalURL} className="underline">
                  Click here to access your session portal.
                </a>
              </p>
            </Message>
          )}
          {!isFetching && error && (
            <Message negative>
              <Message.Header>Session portal error</Message.Header>
              <p>Sorry we are having problem retrieving your session link</p>
            </Message>
          )}
        </LoaderContainer>
      </div>
    </SessionPortalPageWrapper>
  );
};

export { SessionPortalPage };
