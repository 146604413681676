import { GrayGraphite } from '@coinspect/ui';
import { CustomErrors } from '@coinspect/ui/lib/form-error';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import inflection from 'lodash-inflection';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Dropdown,
  DropdownItemProps,
  DropdownProps,
  Form,
} from 'semantic-ui-react';
import styled from 'styled-components';

type ValueType = (boolean | number | string)[];
export interface FormDropdownProps extends CustomErrors, DropdownProps {
  label?: string;
  name: string;
  required?: boolean;
  options?: DropdownItemProps[];
  placeholder?: string;
  key?: string; // to make component re-render if this changes
  value?: ValueType;
  isScrollableMenu?: boolean;
}

interface TruncatedDropdownProps extends DropdownProps {
  selected?: number;
}

const TruncatedDropdownElement = styled(Dropdown)<TruncatedDropdownProps>`
  min-width: max-content;
  white-space: nowrap;
  width: 100%;

  & > .text::after {
    ${({ selected = 0 }) =>
      selected > 1
        ? `content: " +${inflection.pluralize('other', selected - 1, true)}";`
        : 'display: none;'}
    color: ${GrayGraphite};
  }

  &&& > .text {
    margin-left: 0px;
    width: 100%;
  }

  i {
    vertical-align: text-bottom;
  }
`;

const DropDownMenu = styled(Dropdown.Menu)`
  overflow-y: ${(props) => (props.isscrollablemenu ? 'scroll' : 'visible')};
  max-height: ${(props) => (props.isscrollablemenu ? '150px' : 'none')};
`;

export const TruncatedDropdown: React.FC<FormDropdownProps> = (props) => {
  const { errors, control } = useFormContext<Record<string, string>>();

  const {
    name,
    options = [],
    label,
    placeholder,
    value = [],
    defaultValue,
    isScrollableMenu,
    ...rest
  } = props;

  const finalPlaceHolder = placeholder ?? `Select ${rest.name ?? ''}`;
  const [numSelectedItems, setNumSelectedItems] = useState(0);
  const [finalText, setFinalText] = useState(finalPlaceHolder);
  const [displayOptions, setDisplayOptions] = useState<DropdownItemProps[]>([]);

  const isSelected = (option: DropdownItemProps) =>
    option.value !== undefined && value.includes(option.value);

  const getText = (val: ValueType) => {
    const text = options?.find((opt) => val.includes(opt.value ?? ''))?.text;
    return text
      ? `${text}${value && val.length > 1 ? ',' : ''}`
      : finalPlaceHolder;
  };

  const onClickOption = (
    event: React.MouseEvent,
    { value: optVal }: DropdownItemProps = { value: '' },
  ) => {
    if (optVal) {
      value.includes(optVal)
        ? value.splice(value.indexOf(optVal), 1)
        : value.push(optVal);

      const getValue = getText(value);
      const hasComma = value.length > 1;
      const numToTruncate = 15;
      setFinalText(
        getValue.length > numToTruncate
          ? getValue.substring(0, numToTruncate) + `...${hasComma ? ',' : ''}`
          : getValue,
      );
      setNumSelectedItems(value.length);
      updateDisplayOptions();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      control.setValue(name, value as any);
    }
  };

  const updateDisplayOptions = () => {
    setDisplayOptions(
      options.map((opt) => ({
        value: opt.value,
        text: opt.text,
        selected: isSelected(opt),
        active: isSelected(opt),
        onClick: (e, d) => {
          onClickOption(e, d);
          if (props.onChange) {
            props.onChange(e, props);
          }
        },
      })),
    );
  };

  useEffect(() => {
    updateDisplayOptions();
  }, []);
  return (
    <>
      <Form.Field error={!!errors[name]}>
        <Controller
          as={
            <TruncatedDropdownElement {...rest} selected={numSelectedItems}>
              <DropDownMenu isscrollablemenu={Number(isScrollableMenu)}>
                {/* options explicitly set to avoid removal of options on select */}
                {displayOptions.map((opt) => (
                  <Dropdown.Item key={opt.value as string} {...opt} />
                ))}
              </DropDownMenu>
            </TruncatedDropdownElement>
          }
          control={control}
          value={value}
          defaultValue={defaultValue}
          name={name}
          label={label}
          placeholder={placeholder}
          text={finalText}
          multiple
          error={!!errors[name]}
        />
      </Form.Field>
    </>
  );
};
