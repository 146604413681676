import find from 'lodash/find';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface BatteryIconProps {
  percentage: number | null;
}

const BatteryIconStyle = styled('div')`
  border: 2px solid #333;
  width: 28px;
  height: 15px;
  padding: 3px;
  border-radius: 4px;
  position: relative;
  margin: 1px 1px 1px 7px;

  &:before {
    content: '';
    height: 8px;
    width: 2px;
    right: -4px;
    background: #333;
    display: block;
    position: absolute;
    border-radius: 4px 4px 0 0;
    top: 1px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid #fff;
    border-radius: 2px;
  }
`;

interface BatteryLevelProps {
  isVertical?: boolean;
  percentage: number;
  color: string;
}

const BatteryLevel = styled.div<BatteryLevelProps>`
  background: ${({ color }) => color};
  position: absolute;
  ${({ isVertical }) => !isVertical && 'top: 0;'}
  bottom: 0;
  left: 0;
  right: 0;

  width: ${({ percentage }) => percentage}%;
  height: 12px;
`;

const BatteryIconContainer = styled.div`
  display: flex;
  align-items: center;
`;

function getBatteryInfo(perc: number) {
  const range = [
    {
      color: '#D0021B',
      level: 20,
      min: 0,
      max: 15,
    },
    {
      color: '#FFEB07',
      level: 75,
      min: 16,
      max: 39,
    },
    {
      color: '#67C500',
      level: 100,
      min: 40,
      max: 100,
    },
  ];

  return find(range, (value) => {
    return perc >= value.min && perc <= value.max;
  });
}

export const BatteryIcon: FunctionComponent<BatteryIconProps> = (props) => {
  const { percentage } = props;

  if (typeof percentage !== 'number') {
    return null;
  }

  const batteryInfo = getBatteryInfo(percentage);
  return (
    <BatteryIconStyle>
      {batteryInfo && (
        <BatteryLevel
          percentage={batteryInfo.level}
          color={batteryInfo.color}
        />
      )}
    </BatteryIconStyle>
  );
};

export const Battery: FunctionComponent<BatteryIconProps> = (props) => {
  const { percentage } = props;

  if (percentage === null || percentage === undefined) {
    // [TD-5463] If sensor just added, display dummy battery life since there's no data yet for battery
    // return <span>--</span>;
    return (
      <BatteryIconContainer>
        <BatteryIcon percentage={100} />
      </BatteryIconContainer>
    );
  }

  return (
    <BatteryIconContainer>
      <BatteryIcon percentage={percentage} />
    </BatteryIconContainer>
  );
};
