export const ACCOUNT_BLACKLIST = [
  'brandawn@hellotherma.com',
  'danmartin@hellotherma.com',
  'derick@hellotherma.com',
  'genelyn@coinspectapp.com',
  'genelyn+1@hellotherma.com',
  'jeiel@hellotherma.com',
  'monitoring+qa-nonecoom-autoamtion@hellotherma.com',
  'monitoring+qadev-automationprodnonecomm-hellotherma-co@hellotherma.com',
  'qadev+automationadmin@hellotherma.com',
  'qadev+automationadminstg@hellotherma.com',
  'qadev+automationdev@hellotherma.com',
  'qadev+automationprod@hellotherma.com',
  'qadev+automationprodadmin@hellotherma.com',
  'qadev+automationprodviewer@hellotherma.com',
  'qadev+automationstaging@hellotherma.com',
  'qadev+automationviewer@hellotherma.com',
  'qadev+automationviewerstg@hellotherma.com',
  'vincent@hellotherma.com',
  'vincetester123@gmail.com',
  'wilecoyote@coinspectapp.com',
];
