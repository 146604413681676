import { InviteModel, UserModel } from '../../../../services';
/**
 *
 * Creates a dropdown data from store.pages.users.allUsers.byUUID
 *
 */
export const createRecipientsDropdown = (
  allUsers: {
    [key: string]: UserModel;
  },
  allRecipientsSelected: boolean,
  allInvites?: InviteModel[],
  options?: {
    withAllUsers: boolean;
  },
): { text: string; value: string }[] => {
  const { withAllUsers = true } = options || {};
  const recipientDropdownOptions = [];
  const hasInvites = !!allInvites?.length;

  const allUsersText = allRecipientsSelected
    ? `Unselect all users`
    : `Select all users`;

  const allUsersValue = allRecipientsSelected ? `unselect` : `all`;

  if (withAllUsers) {
    recipientDropdownOptions.push({
      text: allUsersText,
      value: allUsersValue,
    });
  }

  for (const userId in allUsers) {
    const userObj = allUsers[userId];
    recipientDropdownOptions.push({
      text: `${userObj.firstName} ${userObj.lastName}`,
      value: userObj.uuid,
    });
  }

  if (hasInvites) {
    allInvites.forEach((invite) => {
      const inviteDisplay = invite.phoneNumber || invite.email;
      recipientDropdownOptions.push({
        text: inviteDisplay,
        value: inviteDisplay,
      });
    });
  }

  return recipientDropdownOptions;
};
