import { PaginationParams } from '@coinspect/utils';
import uniqBy from 'lodash/uniqBy';
import mixpanel from 'mixpanel-browser';
import { normalize, schema } from 'normalizr';
import React, { useContext } from 'react';
import { Icon } from 'semantic-ui-react';

import { Invite, InviteModel, InviteService } from '../services';
import { StoreContext } from '../store';
import { useBannerAlerts } from './use-banner-alerts';
import { useTeamService } from './use-team';
import { useUserRole } from './use-user-role';

const InviteSchema = new schema.Entity(
  'invite',
  {},
  {
    idAttribute: 'uuid',
  },
);

const InviteListSchema = [InviteSchema];

function getUniqRecipients(invites: Invite[]) {
  return uniqBy(invites, ({ email, phoneNumber }) =>
    [email, phoneNumber].join(),
  );
}

function removeEmptyRequiredField(invites: Invite[]) {
  return getUniqRecipients(invites).filter(
    (invite) => invite.email || invite.phoneNumber,
  );
}

export function useInviteService() {
  const { dispatch } = useContext(StoreContext);
  const { set } = useBannerAlerts();
  const { getTeamNames } = useTeamService();
  const { displayRole } = useUserRole();

  async function browseInvites(
    options?: PaginationParams,
  ): Promise<InviteModel[] | null> {
    const invites = await InviteService.browse(options);
    const normalized = normalize(invites, InviteListSchema);
    dispatch({
      data: normalized,
      type: 'invite:set',
    });
    return invites;
  }

  async function resendAllPending(): Promise<InviteModel[] | null> {
    dispatch({ type: 'resend-invite:all_REQUEST' });
    try {
      const invites = await InviteService.resendAll();
      if (!invites) {
        return null;
      }
      const normalized = normalize(invites, InviteListSchema);
      dispatch({
        data: normalized,
        type: 'invite:set',
      });
      return invites.success;
    } finally {
      dispatch({ type: 'resend-invite:all_FINALLY' });
    }
  }

  async function addInvite(payload: Invite[]): Promise<InviteModel[] | null> {
    dispatch({ type: 'invite:add_REQUEST' });
    try {
      const invites = await InviteService.add({
        invites: removeEmptyRequiredField(payload),
      });
      if (!invites) {
        return null;
      }

      if (invites.success.length > 0) {
        mixpanel.track('User Invited', {
          invite_method: payload[0].type,
          invite_role: displayRole(payload[0].role),
          invite_contact:
            payload[0].type === 'email'
              ? payload[0].email
              : InviteService.formatInvitePhoneNumber(
                  payload[0].countryCode as string,
                  payload[0].phoneNumber as string,
                ),
          invite_team: await getTeamNames({
            teamUUIDs: payload[0].teams as string[],
          }),
        });
      }

      const normalized = normalize(invites.success, InviteListSchema);
      dispatch({
        data: normalized,
        type: 'invite:add',
      });
      return invites.success;
    } finally {
      dispatch({ type: 'invite:add_FINALLY' });
    }
  }

  async function deleteInvite(token: string): Promise<InviteModel> {
    dispatch({ type: 'invite:delete_REQUEST' });
    try {
      const removed = await InviteService.delete(token);
      if (!removed) {
        throw new Error('Invite could not be deleted.');
      }
      dispatch({
        data: removed.uuid,
        type: 'invite:delete',
      });
      return removed;
    } finally {
      dispatch({ type: 'invite:delete_FINALLY' });
    }
  }

  const resendInvite = async (inviteUUID: string) => {
    try {
      dispatch({
        type: `resend-invite:${inviteUUID}_REQUEST`,
      });
      await InviteService.resendInvite(inviteUUID);
      dispatch({
        type: 'invite:resent_today',
        data: inviteUUID,
      });

      mixpanel.track('Invite Resent');
    } catch (e) {
      set([
        {
          icon: <Icon name="exclamation triangle" />,
          message:
            'Whoops! This one is on us. We could not resend the invite. Please try again, or if the problem persists, contact support@glaciergrid.com.',
        },
      ]);
    } finally {
      dispatch({
        type: `resend-invite:${inviteUUID}_FINALLY`,
      });
    }
  };

  return {
    addInvite,
    browseInvites,
    deleteInvite,
    resendInvite,
    resendAllPending,
  };
}
