import styled from 'styled-components';

// values are from semantic
// switch to hardcode to avoid possiblity of 0
export const mobileMinWidth = 320;
export const mobileMaxWidth = 767;
export const tabletMinWidth = 768;
export const tabletMaxWidth = 991;
export const computerMinWidth = 992;
export const largeScreenMinWidth = 1200;
export const largeScreenMaxWidth = 1919;
export const wideScreenMinWidth = 1920;

// with px
export const mobileMinWidthPx = `${mobileMinWidth}px`;
export const mobileMaxWidthPx = `${mobileMaxWidth}px`;
export const tabletMinWidthPx = `${tabletMinWidth}px`;
export const tabletMaxWidthPx = `${tabletMaxWidth}px`;
export const computerMinWidthPx = `${computerMinWidth}px`;
export const largeScreenMinWidthPx = `${largeScreenMinWidth}px`;
export const largeScreenMaxWidthPx = `${largeScreenMaxWidth}px`;
export const wideScreenMinWidthPx = `${wideScreenMinWidth}px`;

function isBrowser() {
  return typeof window !== undefined;
}

function getWidth() {
  return isBrowser() ? window.innerWidth : 0;
}

// booleans
export const isMobileOnly = () =>
  getWidth() >= mobileMinWidth && getWidth() <= mobileMaxWidth;
export const isTabletOnly = () =>
  getWidth() >= tabletMinWidth && getWidth() <= tabletMaxWidth;
export const isComputerOnly = () =>
  getWidth() >= computerMinWidth && getWidth() <= largeScreenMinWidth;

export const isMobileAndAbove = () => getWidth() >= mobileMinWidth;
export const isTabletAndAbove = () => getWidth() >= tabletMinWidth;
export const isComputerAndAbove = () => getWidth() >= computerMinWidth;

export const isMobileAndBelow = () => getWidth() <= mobileMaxWidth;
export const isTabletAndBelow = () => getWidth() <= tabletMaxWidth;
export const isComputerAndBelow = () => getWidth() <= largeScreenMinWidth;

// CUSTOM
export const w397 = 397;
export const w414 = 414;
export const w415 = 415;
export const w430 = 430;
export const w540 = 540;
export const w1024 = 1024;
export const w1079 = 1079;
export const w1078 = 1078;
export const w768 = 768;
export const w1280 = 1280;

export const DesktopOnly = styled.div`
  @media only screen and (max-width: ${tabletMaxWidthPx}) {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  @media only screen and (min-width: ${tabletMinWidthPx}) {
    display: none;
  }
`;

export const MobileAndTablet = styled.div`
  @media only screen and (min-width: ${computerMinWidthPx}) {
    display: none;
  }
`;

export const TabletOnly = styled.div`
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    display: none;
  }
  @media only screen and (min-width: ${computerMinWidthPx}) {
    display: none;
  }
`;

export const DesktopAndTablet = styled.div`
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    display: none;
  }
`;
