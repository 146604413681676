import { useContext } from 'react';

import { AccountContext, LocalStorageContext } from '../contexts';

export function useAnnouncementTooltip() {
  const { user } = useContext(AccountContext);
  const { getItem, setItem } = useContext(LocalStorageContext);

  // display once per user, stored in local storage
  const userId = user?.id || '';
  const reportTypesAnnounced: {
    [key: string]: boolean;
  } = getItem('reportTypesAnnounced', { [userId]: false });

  function hideReportTypeAnnouncement(setUser = false) {
    if (setUser) {
      setItem('reportTypesAnnounced', {
        ...reportTypesAnnounced,
        [userId]: true,
      });
    }
  }

  return {
    hideReportTypeAnnouncement,
  };
}
