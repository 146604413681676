import { ModalHeaderText, ThermaSecondaryAction400 } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const TroubleshootingTitle = styled.div`
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${ModalHeaderText};
`;

const TroubleshootingContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const TroubleshootingContent = styled.div`
  padding-bottom: 30px !important;
`;

const TroubleshootingGraphics = styled.div`
  font-family: 'sf-pro';
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${ThermaSecondaryAction400};
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 24px 24px 8px;
  border: 1px solid #d1d7e3;
  border-radius: 8px;
  align-items: center;
`;

const GraphicsImg = styled.img`
  width: 240px;
  height: 240px;
`;

interface ImageData {
  text: string;
  image: string;
}

interface ContentData {
  modalRef: React.RefObject<HTMLInputElement>;
  title: string;
  images: {
    text: string;
    image: string;
  }[];
}

interface TroubleshootingModalContentProps {
  contentData: ContentData[];
}

export const TroubleshootingModalContent: FunctionComponent<TroubleshootingModalContentProps> = (
  props,
) => {
  const { contentData } = props;

  return (
    <TroubleshootingContent>
      {contentData.map((dat, ind) => (
        <TroubleshootingContainer ref={dat.modalRef} key={ind}>
          <TroubleshootingTitle>{dat.title}</TroubleshootingTitle>
          {dat.images.map((imgData: ImageData, index) => (
            <TroubleshootingGraphics key={index}>
              <span>{imgData.text}</span>
              <GraphicsImg src={imgData.image} />
            </TroubleshootingGraphics>
          ))}
        </TroubleshootingContainer>
      ))}
    </TroubleshootingContent>
  );
};
