import React from 'react';

import { ResendActivationContainer } from '../../style/resend-styles';
import { ResendActivationForm } from '../forms/resend-account-form';

export const ResendActivation: React.FC = () => {
  return (
    <ResendActivationContainer>
      <ResendActivationForm />
    </ResendActivationContainer>
  );
};
