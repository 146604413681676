import { DataStoreAction } from '../../store';

export type FeaturesState = {
  [key: string]: boolean;
};

export enum FeatureActions {
  set = 'feature:set',
}

export function featuresReducer(state: FeaturesState, action: DataStoreAction) {
  switch (action.type) {
    case FeatureActions.set:
      return {
        ...state,
        ...action.data,
      };
  }
  return state;
}
