import {
  AddButtonTextColor,
  thermaPink,
  ThermaSecondaryAction400,
} from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Tooltip } from '../tooltip';

interface AddButtonContainerProps {
  color?: string;
  hoverColor?: string;
  margin?: string;
  padding?: string;
  disabled?: boolean;
  hasInfo?: boolean;
  infoText?: string;
  infoHeader?: string;
}

const AddButtonContainer = styled.div<AddButtonContainerProps>`
  display: inline-flex;
  padding: ${({ padding }) => padding};
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  :hover {
    cursor: pointer;
    color: ${({ hoverColor, disabled }) => !disabled && hoverColor};
  }
`;

const AddButtonText = styled.span`
  font-family: proxima-nova;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
`;

const AddIcon = styled(Icon)`
  margin-right: 8px;
`;

const PopOver = styled.div`
  display: grid;
  grid-template-columns: 23px 1fr;
`;

const InfoContainer = styled.div`
  position: relative;
  padding: 15px 18px;
  margin: 0;
`;

const InfoHeader = styled.div`
  padding-left: 10px;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
`;

const InfoIcon = styled(Icon)`
  &&&& {
    color: ${ThermaSecondaryAction400};
    box-shadow: 0 0 0 2px rgb(86 131 147) inset;
    font-size: 12px;
    &::before {
      position: relative;
      top: -2px;
      margin: 0 auto;
      text-align: center;
      right: -0.5px;
      font-size: 10px;
    }
  }
`;

const InfoText = styled.div`
  padding-top: 10px;
`;

interface AddButtonProps extends AddButtonContainerProps {
  onClick?: () => void | Promise<void>;
  className?: string;
  label: string;
  id?: string;
  options?: unknown[];
}

export const AddTextButton: FunctionComponent<AddButtonProps> = (props) => {
  const {
    onClick,
    className = '',
    label = '',
    margin = '0',
    padding = '12px 16px 12px 0px',
    disabled = false,
    color = thermaPink,
    hoverColor = AddButtonTextColor,
    id,
    hasInfo = false,
    infoText = '',
    infoHeader = '',
  } = props;

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  return (
    <AddButtonContainer
      id={id}
      onClick={handleClick}
      className={className}
      color={color}
      margin={margin}
      padding={padding}
      disabled={disabled}
      hoverColor={hoverColor}
    >
      <AddIcon className="icon-add" size="large" />
      <AddButtonText>{label}</AddButtonText>
      {hasInfo && (
        <>
          <Tooltip
            content={
              <>
                <InfoContainer>
                  <PopOver>
                    <InfoIcon circular name="info" />
                    <InfoHeader>{infoHeader}</InfoHeader>
                  </PopOver>
                  <InfoText>{infoText}</InfoText>
                </InfoContainer>
              </>
            }
            trigger={
              <Button
                style={{
                  margin: '0',
                  paddin: '0',
                  background: 'none',
                  width: '20px',
                  height: '20px',
                  position: 'relative',
                  top: '-12px',
                  left: '-11px',
                }}
              >
                <InfoIcon circular name="info" />
              </Button>
            }
          />
        </>
      )}
    </AddButtonContainer>
  );
};
