import get from 'lodash/get';

import { DataStoreAction, NormalRelationship } from '../reducer';

/**
 * The generic relationship reducer state structure is
 * { parentUUID: [childUUID1, childUUID2] }
 * @param child
 * @param childKey
 */
export function genericRelationshipReducer(
  [child, childKey]: [string, string],
  []: [string] = [],
) {
  return (
    state: NormalRelationship,
    action: DataStoreAction,
  ): NormalRelationship => {
    switch (action.type) {
      case `${child}:append`: {
        const { result, entities } = action.data;
        return {
          ...state,
          ...result.reduce(
            (acc: { [key: string]: string[] }, resultUUID: string) => {
              const parentUUID = get(entities, [child, resultUUID, childKey]);
              if (!parentUUID) {
                return acc;
              }
              acc[parentUUID] = acc[parentUUID]
                ? acc[parentUUID].concat(resultUUID)
                : [resultUUID];
              return acc;
            },
            {},
          ),
        };
      }

      case `${child}:set`: {
        const { result, entities } = action.data;
        return result.reduce(
          (acc: { [key: string]: string[] }, resultUUID: string) => {
            const parentUUID = get(entities, [child, resultUUID, childKey]);
            if (!parentUUID) {
              return acc;
            }
            acc[parentUUID] = acc[parentUUID]
              ? acc[parentUUID].concat(resultUUID)
              : [resultUUID];
            return acc;
          },
          {},
        );
      }

      case `${child}:reset`: {
        const { result, entities } = action.data;
        return result.reduce(
          (acc: { [key: string]: string[] }, resultUUID: string) => {
            const parentUUID = get(entities, [child, resultUUID, childKey]);

            if (!parentUUID) {
              return acc;
            }

            acc[parentUUID] = acc[parentUUID]
              ? [...acc[parentUUID], resultUUID]
              : [resultUUID];

            return acc;
          },
          {},
        );
      }

      case `${child}:add`: {
        const { result, entities } = action.data;
        const parentUUID = get(entities, [child, result[0], childKey]);
        const list = state[parentUUID]
          ? state[parentUUID].concat(result[0])
          : [result[0]];
        return {
          ...state,
          [parentUUID]: list,
        };
      }

      case `${child}:delete`: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return Object.keys(state).reduce((acc: any, item) => {
          const idx = state[item].indexOf(action.data);
          if (idx !== -1) {
            acc[item] = [
              ...state[item].slice(0, idx),
              ...state[item].slice(idx + 1),
            ];
          } else {
            acc[item] = [...state[item]];
          }

          return acc;
        }, {});
      }

      /**
       * * special delete for equipmentDevices relationship
       * * since we're using equipmentUUID as the parent for the devices
       * * need to delete the property instead of an object value only
       */
      case 'equipment:delete': {
        const { [action.data]: _, ...rest } = state;
        return {
          ...rest,
        };
      }

      case `${child}:hard_reset`:
        return {};
    }
    return state;
  };
}
