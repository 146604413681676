import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const SVG = styled.div`
  margin: 15px;
  clear: both;
`;

export const EmptyIcon: FunctionComponent = () => {
  return (
    <SVG>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="224"
        height="154"
        viewBox="0 0 224 154"
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="1"
          transform="translate(-615 -717) translate(617 719)"
        >
          <g stroke="#D1D7E3" strokeWidth="4">
            <rect width="219.404" height="150" x="0" y="0" rx="4" />
            <rect width="177.133" height="93" x="21" y="39" rx="4" />
            <path d="M1.006 26.19l217.391-1.008" />
          </g>
          <path
            fill="#D1D7E3"
            fillRule="nonzero"
            d="M122 83.9026369L122 87.0973631 111.075203 87.0973631 111.075203 98 107.924797 98 107.924797 87.0973631 97 87.0973631 97 83.9026369 107.924797 83.9026369 107.924797 73 111.075203 73 111.075203 83.9026369z"
          />
        </g>
      </svg>
    </SVG>
  );
};

export const NoUsersFound: FunctionComponent = () => {
  return (
    <SVG>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="224"
        height="154"
        viewBox="0 0 224 154"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g
            stroke="#D1D7E3"
            strokeWidth="4"
            transform="translate(-220 -717) translate(222 719)"
          >
            <rect width="219.404" height="150" x="0" y="0" rx="4" />
            <rect width="177.133" height="94" x="21.135" y="39.263" rx="4" />
            <path d="M1.006 26.175l217.391-1.007" />
          </g>
          <path
            fill="#92A7BC"
            fillRule="nonzero"
            stroke="#92A7BC"
            d="M40.053.145a.615.615 0 00-.65-.09L.347 17.704A.585.585 0 000 18.23a.584.584 0 00.337.531l11.052 5.276a.616.616 0 00.625-.056l10.746-7.813-8.436 8.441a.576.576 0 00-.168.453l.84 10.64a.589.589 0 00.412.511.619.619 0 00.643-.173l5.866-6.613 7.252 3.37c.158.074.34.077.5.01.16-.068.281-.2.334-.362L40.23.761a.575.575 0 00-.177-.616z"
            transform="translate(-220 -717) translate(222 719) translate(173.462 32.154)"
          />
          <path
            stroke="#D1D7E3"
            strokeDasharray="4,4"
            strokeLinecap="round"
            strokeWidth="2"
            d="M186.154 69.769c-2.908 5.59-12.52 5.432-28.835-.476-24.473-8.863 22.602-27.978 13.616-5.3-5.99 15.12-16.468 22.122-31.435 21.007"
            transform="translate(-220 -717) translate(222 719)"
          />
          <path
            fillRule="nonzero"
            stroke="#D1D7E3"
            strokeWidth="4"
            d="M109.795 55C93.34 55 80 68.344 80 84.804s13.34 29.804 29.795 29.804c16.454 0 29.795-13.345 29.795-29.804 0-16.46-13.34-29.804-29.795-29.804zm.09 43.865h0-12.909c0-9.31 8.175-9.309 9.989-11.743l.207-1.11c-2.548-1.292-4.347-4.407-4.347-8.05 0-4.799 3.12-8.69 6.97-8.69 3.848 0 6.97 3.891 6.97 8.69 0 3.612-1.768 6.707-4.282 8.02l.236 1.261c1.99 2.317 9.893 2.468 9.893 11.622h-12.727 0z"
            transform="translate(-220 -717) translate(222 719)"
          />
        </g>
      </svg>
    </SVG>
  );
};
