import React, { useContext } from 'react';
import styled from 'styled-components';

import { StoreContext } from '../../../store';
import { ConnectionStatusDeviceType } from '../../../store/reducers';
import { StatusBadge } from './status-badge';
import { StatusResult } from './status-result';
import { TestAgainText } from './test-again';

const ConnectionStatusMobileStyled = styled.div`
  background: #f1f3f7;
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 16px 20px;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  border-radius: 6px;
  margin-bottom: 10px;
`;

export const ConnectionStatusMobile: React.FC<{
  connectionStatusObj: ConnectionStatusDeviceType;
}> = ({ connectionStatusObj }) => {
  const { store } = useContext(StoreContext);
  const { devices } = store.entities;
  const { connectionStatus } = store.pages.sensors;

  return (
    <div>
      {Object.keys(connectionStatusObj).map((deviceUUID: string) => {
        const device = devices.byUUID[deviceUUID];
        return (
          <ConnectionStatusMobileStyled key={device.uuid}>
            <div>
              <div className="bold">Sensor name</div>
              <div>{device.name}</div>
            </div>
            <div>
              <div className="bold">ID</div>
              <div>{device.serialNumber}</div>
            </div>
            <div>
              <div className="bold">Status</div>
              <StatusBadge
                testingResult={
                  connectionStatus[device.locationUUID][deviceUUID]
                    .testingResult
                }
              />
              <div style={{ fontSize: '12px' }}>
                <StatusResult
                  testingStatus={
                    connectionStatus[device.locationUUID][deviceUUID]
                      .testingStatus
                  }
                />
              </div>
            </div>
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                <TestAgainText
                  testingStatus={
                    connectionStatus[device.locationUUID][deviceUUID]
                      .testingStatus
                  }
                  disabled={
                    connectionStatus[device.locationUUID][deviceUUID].isTesting
                  }
                  deviceUUID={deviceUUID}
                  locationUUID={device.locationUUID}
                />
              </div>
            </div>
          </ConnectionStatusMobileStyled>
        );
      })}
    </div>
  );
};
