// import { API_VERSION } from '../constants';
import { request } from '../contexts';

export type InstallationOptions = 'built_in' | 'free_Standing' | 'other';
export type EquipmentTypeOptions =
  | 'freezer'
  | 'refrigerator'
  | 'hvac'
  | 'other';

export type GatewayData = {
  id: number;
  uuid: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  name: string;
  companyUuid: string;
  locationUuid: string;
  chirpstackGatewayId: string;
  hologramDeviceId: string;
  firmwareVersion: string;
  chirpstackStatus: string;
  hologramStatus: string;
  vendor: string;
  model: string;
  sim: string;
  gatewayId: string;
  updateStatus: string;
  testingMode: boolean;
};

export class GatewayService {
  public static async browseGateways() {
    const { data } = await request.get(`/api/gateway`);

    return data.data;
  }

  public static async getGatewayByLocation(locationUUID: string) {
    const { data } = await request.get(`/api/gateway/location/${locationUUID}`);

    return data.data;
  }
}
