import { PaginationParams } from '@coinspect/utils';
import filter from 'lodash/filter';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import { normalize, schema } from 'normalizr';
import { useContext } from 'react';

import { CreateTeam, TeamModel, TeamService } from '../services';
import { StoreContext } from '../store';

const TeamSchema = new schema.Entity(
  'teams',
  {},
  {
    idAttribute: 'uuid',
  },
);

const TeamLocationSchema = new schema.Entity(
  'teamLocation',
  {},
  {
    idAttribute: 'uuid',
  },
);

export function useTeamService() {
  const { dispatch, store } = useContext(StoreContext);

  function hasActiveForm(): boolean {
    return store.pages.teams.isActiveForm;
  }

  function toggleActiveForm() {
    dispatch({
      type: 'teamPage:isActiveForm:toggle',
    });
  }

  function resetActiveForm() {
    dispatch({
      type: 'teamPage:isActiveForm:reset',
    });
  }

  async function browseTeam(options?: PaginationParams) {
    const result = await TeamService.browse(options);
    const normalized = normalize(result.data, [TeamSchema]);

    dispatch({
      data: normalized,
      type: 'teams:set',
    });

    return result;
  }

  async function addTeam(payload: CreateTeam) {
    const addedTeam = await TeamService.add(payload);
    const normalized = normalize([addedTeam], [TeamSchema]);

    dispatch({
      data: normalized,
      type: 'teams:set',
    });

    return addedTeam;
  }

  async function editTeam(uuid: string, payload: CreateTeam) {
    const modifiedTeam = await TeamService.edit(uuid, payload);
    const normalized = normalize([modifiedTeam], [TeamSchema]);

    dispatch({
      data: normalized,
      type: 'teams:edit',
    });

    return modifiedTeam;
  }

  async function deleteTeam(uuid: string) {
    dispatch({ type: 'teams:delete_REQUEST' });

    try {
      const deletedTeam = await TeamService.delete(uuid);

      dispatch({
        data: uuid,
        type: 'teams:delete',
      });

      return deletedTeam;
    } finally {
      dispatch({ type: 'teams:delete_FINALLY' });
    }
  }

  async function browseTeamLocations() {
    const teamLocations = await TeamService.browseUserTeamLocations();
    const normalized = normalize(teamLocations, [TeamLocationSchema]);

    dispatch({
      data: normalized,
      type: 'teamLocation:reset',
    });
  }

  async function browseTeamByUserUUID(userUUID: string) {
    const response = await TeamService.browseTeamByUserUUID(userUUID);

    if (response.errors && response.errors.length) {
      throw response.errors;
    }

    return response;
  }

  function browseTeamsLocallyOnlyLocationUUID(
    locationUUID: string,
  ): TeamModel[] {
    const { byUUID: teams } = store.entities.teams;
    return filter(teams, (team) => {
      const { locations } = team;
      return (
        locations.length === 1 && locations[0].locationUUID === locationUUID
      );
    });
  }

  function browseTeamsLocallyOnlyUserUUID(userUUID: string): string[] {
    const { byUUID: teams } = store.entities.teams;
    return reduce(
      teams,
      (result: string[], team) => {
        const { members, name } = team;
        if (members.length === 1 && members[0].userUUID === userUUID) {
          result.push(name);
        }
        return result;
      },
      [],
    );
  }

  async function browseTeamByUser(userUUID: string) {
    const response = await TeamService.browseTeamByUser(userUUID);
    if (response.errors && response.errors.length) {
      throw response.errors;
    }

    return response;
  }

  async function getTeamNames({
    teamUUIDs,
  }: {
    teamUUIDs: string[];
  }): Promise<string[]> {
    if (!teamUUIDs) return [];
    const { data: teams } = await browseTeam();
    const teamNames = teamUUIDs.map(
      (teamUUID: string) => find(teams, { uuid: teamUUID }).name,
    );
    return teamNames;
  }
  return {
    addTeam,
    browseTeam,
    browseTeamByUserUUID,
    browseTeamLocations,
    deleteTeam,
    editTeam,
    hasActiveForm,
    toggleActiveForm,
    resetActiveForm,
    browseTeamsLocallyOnlyLocationUUID,
    browseTeamsLocallyOnlyUserUUID,
    browseTeamByUser,
    getTeamNames,
  };
}
