import React, { useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { StandardModalContext } from '../../contexts';

const SensorIDButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: right;
`;
const SensorIDContainer = styled.div`
  margin-top: 20px;
  align-items: center;
`;

const SensorImageContainer = styled.div`
  display: flex;
  justify-content: center;
  & img {
    width: 250px;
  }
`;

export interface SensorIDProps {
  serialNumber: string;
}

export const SensorIDInfoModal: React.FC<SensorIDProps> = () => {
  const { hideModal, openModal } = useContext(StandardModalContext);

  return (
    <SensorIDButtonContainer>
      <Button
        type="button"
        floated="right"
        circular
        icon
        aria-label="Sensor ID"
        data-title="Sensor ID"
        size="small"
        onClick={() => {
          openModal({
            headerIcon: <Icon fitted className="icon-support-1" size="large" />,
            content: (
              <SensorIDContainer>
                <p>
                  The Sensor ID is a string of numbers and letters found on the
                  front of the device. Or switch to the mobile app to scan the
                  QR code with ease.
                </p>
                <p>Make sure your sensor is placed in an upright position.</p>
                <SensorImageContainer>
                  <img
                    src={require('../ui/images/sensor-ids/generic-sensor-id.png')}
                  />
                </SensorImageContainer>
              </SensorIDContainer>
            ),
            header: 'Sensor ID',
            primaryButton: {
              content: 'Okay',
              onClick: () => {
                hideModal();
              },
            },
          });
        }}
        className="therma-off-white"
      >
        <Icon className="icon-support-1" size="big" aria-label="Sensor ID" />
      </Button>
    </SensorIDButtonContainer>
  );
};
