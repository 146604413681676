import React from 'react';

import { DataStoreAction } from '../reducer';

export class BannerAlert {
  icon?: React.ReactNode;
  message: string;
  onDismiss?: () => void;

  constructor(alertBannerError: BannerAlert) {
    const {
      icon,
      message,
      onDismiss = () => {
        // default
        console.debug('dismissed');
      },
    } = alertBannerError;

    this.icon = icon;
    this.message = message;
    this.onDismiss = onDismiss;
  }
}

const initialState: BannerAlert[] = [];

export enum BannerAlertActions {
  append = 'bannerAlert:append',
  dismiss = 'bannerAlert:dismiss',
  set = 'bannerAlert:set',
}

export const bannerAlertsReducer = (
  state = initialState,
  action: DataStoreAction,
) => {
  switch (action.type) {
    case BannerAlertActions.append:
      const bannerAlert = action.data;

      return [...state, bannerAlert];

    case BannerAlertActions.dismiss:
      const index = action.data as number;
      return state.filter((_, i) => i !== index);

    case BannerAlertActions.set:
      return action.data;
  }

  return state;
};
