import React from 'react';

export const EnergyFieldErrorStyle: React.CSSProperties = {
  color: '#FFABAB',
  marginTop: '8px',
};

export const EnergyTimeFieldErrorStyle: React.CSSProperties = {
  color: '#FFABAB',
  marginTop: '8px',
  width: '90px',
  whiteSpace: 'nowrap',
};

export const EnergyTimeZoneFieldErrorStyle: React.CSSProperties = {
  color: '#FFABAB',
  width: '90px',
  whiteSpace: 'nowrap',
};

export const EnergyTimeFieldErrorStyleSmall: React.CSSProperties = {
  color: '#FFABAB',
  marginTop: '8px',
  width: '70px',
  display: 'flex',
  paddingLeft: '26px',
};
