import React, { useContext } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { computerMinWidthPx, tabletMaxWidthPx } from '../../constants';
import { StoreContext } from '../../store';

type DashboardLoader = {
  query?: string;
  active?: boolean;
};

const StyledDimmer = styled(Dimmer)`
  && {
    @media only screen and (min-width: ${computerMinWidthPx}) {
      z-index: 100;
    }
    @media only screen and (max-width: ${tabletMaxWidthPx}) {
      z-index: 10;
    }
  }
`;

export const DashboardLoader: React.FC<DashboardLoader> = (props) => {
  const { store } = useContext(StoreContext);
  const { isQueryInProgress } = store.pages;
  const { query, active } = props;
  const isQueryActive = (query && isQueryInProgress[query] === true) || active;

  return (
    <StyledDimmer active={isQueryActive} inverted>
      <Loader />
    </StyledDimmer>
  );
};
