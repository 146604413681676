import { Button, neutralGray900 } from '@coinspect/ui';
import React, { FunctionComponent, useContext } from 'react';
import styled, { css } from 'styled-components';

import { ModalContext } from '../../contexts';
import { useAnnouncementTooltip } from '../../hooks';
import { TEMPLATE_SELECTION_BREAKPOINT } from '../../services';
import { ReportTemplateSelection } from '../reports';

export const ModalHeader = styled.div`
  color: ${neutralGray900};
  padding: 14.5px 0px;

  @media only screen and (min-width: ${TEMPLATE_SELECTION_BREAKPOINT}) {
    padding: 14.5px 29px;
  }
`;

export const ModalHeaderText = styled.span`
  color: ${neutralGray900};
  font-style: normal;
  font-size: 36px;
  font-weight: 400;
  line-height: 43px;
  font-family: proxima-nova;
`;

export const ReportTemplateSelectionModal: FunctionComponent = () => {
  const { openModal } = useContext(ModalContext);
  const { hideReportTypeAnnouncement } = useAnnouncementTooltip();

  const onClick = () => {
    hideReportTypeAnnouncement(true);

    openModal({
      closeIcon: true,
      content: <ReportTemplateSelection />,
      fullScreenOnMobile: false,
      hasModalActions: false,
      // fixed the warning where an h2 is under an h2
      header: (
        <ModalHeader>
          <ModalHeaderText>Choose a template</ModalHeaderText>
        </ModalHeader>
      ),
      widthSize: css`
        width: 100%;

        @media only screen and (min-width: ${TEMPLATE_SELECTION_BREAKPOINT}) {
          width: max-content;
        }
      `,
      padding: 'none',
      closeOnOutsideClick: true,
    });
  };

  return (
    <Button
      className="new-report-types"
      onClick={onClick}
      primary
      content="Add a report"
    />
  );
};
