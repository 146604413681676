import axios from 'axios';

class AxiosService {
  static cancelTokenSources = axios.CancelToken.source();

  static request() {
    return axios.create({
      baseURL: process.env.API_HOST || 'http://localhost:9000',
      cancelToken: this.cancelTokenSources.token,
    });
  }

  static cancellAlRequests() {
    this.cancelTokenSources.cancel();
    this.cancelTokenSources = axios.CancelToken.source();
  }
}

export { AxiosService };
