import { formatNumber, ThermaText300 } from '@coinspect/ui';
import React from 'react';
import styled from 'styled-components';

import { UserModel } from '../../services';
import { Tooltip } from '../tooltip';
import { NotifMethodDisplayValue } from '../users';

const UserTooltipText = styled.span`
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

export const UserInfoTooltip: React.FC<{
  user: UserModel;
  max: number; // length of all recipients
  idx: number; // current index of recipient
}> = ({ user, max, idx }) => {
  const { firstName, lastName, email, phoneNumber, notificationMethods } = user;
  const fullName = `${firstName} ${lastName}`;
  const readableNotificationMethods = notificationMethods?.map(
    (method) => NotifMethodDisplayValue[method],
  );
  return (
    <Tooltip
      content={
        <div>
          <div
            className="text-bold"
            style={{
              fontFamily: 'futura-maxi',
              fontSize: 12,
              lineHeight: '16px',
              color: 'rgba(26, 17, 51, 0.8)',
              marginBottom: 5,
            }}
          >
            {fullName}
          </div>
          <div
            style={{
              fontFamily: 'proxima-nova',
              fontSize: 12,
              lineHeight: '20px',
              color: 'rgba(26, 17, 51, 0.8)',
              marginBottom: 10,
            }}
          >
            <div>{email}</div>
            <div>{formatNumber(phoneNumber)}</div>
          </div>
          <div
            style={{
              fontFamily: 'proxima-nova',
              fontSize: 12,
              lineHeight: '150%',
              color: ThermaText300,
            }}
          >
            {(readableNotificationMethods as string[]).join(' • ')}
          </div>
        </div>
      }
      trigger={
        <UserTooltipText>
          {fullName}
          {/* logic to show the comma to separate users */}
          {idx < max - 1 && ', '}
        </UserTooltipText>
      }
    />
  );
};
