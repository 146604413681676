import { darkViolet55, darkViolet80, greyGeyser45 } from '@coinspect/ui';
import React, { FunctionComponent, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { tabletMaxWidthPx } from '../../constants';
import { UserProfile } from '../../contexts';
import { Tooltip } from '../tooltip';
import { Team } from '../users/';
import { TeamTags } from './team-tags';
interface UserListItemStyle {
  bottom: boolean;
}

interface UserRoleArea {
  hasinvite: boolean;
}

const UserListItemCss = css`
  border-bottom: 1px solid ${greyGeyser45};
`;

const UserListItemStyle = styled.div<UserListItemStyle>`
  display: flex;
  flex-flow: row wrap;
  padding: 20px 24px;
  color: ${darkViolet80};
  line-height: 1.7;
  ${({ bottom }) => bottom && UserListItemCss}
    @media only screen and (max-width: ${tabletMaxWidthPx}) {
      flex-flow: column;
      align-items: unset;
    }
`;

const NameArea = styled.div`
  width: calc(100% / 5);
  font-weight: bold;

  @media only screen and (max-width: ${tabletMaxWidthPx}) {
    width: auto;
  }
`;

const UserRoleAreaCss = css`
  @media only screen and (max-width: ${tabletMaxWidthPx}) {
    &:after {
      content: ' | ';
      white-space: pre-wrap;
    }
  }
`;

const UserRoleArea = styled.span<UserRoleArea>`
  ${({ hasinvite }) => hasinvite && UserRoleAreaCss};
`;

const RoleStatusArea = styled.div`
  width: calc(100% / 5);
  justify-content: space-between;

  @media only screen and (max-width: ${tabletMaxWidthPx}) {
    margin-right: 10px;
    display: inline;
    color: ${darkViolet55};
    width: auto;
    *::after {
      content: none !important;
    }
  }
`;

const InvitePendingArea = styled.div`
  width: calc(100% / 5);
  justify-content: space-around;

  @media only screen and (max-width: ${tabletMaxWidthPx}) {
    color: ${darkViolet55};
    width: auto;
    justify-content: left;
  }
`;

const ActionsArea = styled.div`
  width: calc(100% / 5);
  flex-direction: row-reverse;

  &&& button {
    margin-left: 0.4em;
    color: ${darkViolet80};
  }

  @media only screen and (max-width: ${tabletMaxWidthPx}) {
    display: flex;
    width: 25%;
    padding-top: 10px;
    flex-direction: row;

    && button:first-child {
      margin-left: 0;
    }
  }
`;

const UserNameToolTip = styled.div`
  word-break: break-all;
`;

export interface UserListItem {
  bottom: boolean;
  userName: string;
  userRole: string;
  inviteStatus?: JSX.Element;
  actions: JSX.Element | undefined;
  teams?: Team[];
  user: UserProfile;
  editUserFunc: (user: UserProfile) => void;
}

export const UserListItem: FunctionComponent<UserListItem> = (props) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const {
    bottom,
    userName = null,
    userRole,
    inviteStatus,
    actions,
    teams = [],
    user,
    editUserFunc,
  } = props;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  return (
    <UserListItemStyle bottom={bottom}>
      {userName && (
        <NameArea className="ellipse-text user-list__pending-invite">
          {userName.length > 20 ? (
            <Tooltip
              content={<UserNameToolTip>{userName}</UserNameToolTip>}
              trigger={<span>{userName}</span>}
            />
          ) : (
            userName
          )}
        </NameArea>
      )}

      {width > parseInt(tabletMaxWidthPx) ? (
        <>
          <RoleStatusArea>
            {userRole && (
              <UserRoleArea hasinvite={!!inviteStatus}>{userRole}</UserRoleArea>
            )}
          </RoleStatusArea>

          <TeamTags teams={teams} user={user} editUserFunc={editUserFunc} />
        </>
      ) : (
        <div>
          <RoleStatusArea>
            {userRole && (
              <UserRoleArea hasinvite={!!inviteStatus}>{userRole}</UserRoleArea>
            )}
          </RoleStatusArea>

          <TeamTags teams={teams} user={user} editUserFunc={editUserFunc} />
        </div>
      )}

      <InvitePendingArea>{inviteStatus}</InvitePendingArea>
      {actions && <ActionsArea>{actions}</ActionsArea>}
    </UserListItemStyle>
  );
};
