import { GhostListItem } from '@coinspect/ui';
import React from 'react';
import { Placeholder } from 'semantic-ui-react';

export const PlaceholderDeviceSearch: React.FC = () => (
  <GhostListItem>
    <Placeholder style={{ margin: 25 }}>
      <Placeholder.Header>
        <Placeholder.Line length="long" />
        <Placeholder.Line length="short" />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder style={{ height: '70px' }}>
          <Placeholder.Image></Placeholder.Image>
        </Placeholder>
      </Placeholder.Paragraph>
    </Placeholder>
  </GhostListItem>
);
