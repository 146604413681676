import React, { FunctionComponent } from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { mobileMaxWidthPx, tabletMaxWidth, w397, w430 } from '../../constants';

interface DashboardSubHeadingProps {
  heading: string | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
}

const DashboardHeadingText = styled(Header)`
  &&&& {
    margin-top: 0;
    padding-top: 15px;
    @media only screen and (max-width: ${mobileMaxWidthPx}) {
      margin-bottom: 0;
    }
  }
`;

const DashboardSubHeadingBase: FunctionComponent<DashboardSubHeadingProps> = ({
  children,
  className,
  heading,
}) => {
  return (
    <div className={className}>
      <DashboardHeadingText as={'h2'}>{heading}</DashboardHeadingText>
      {children}
    </div>
  );
};

export const DashboardSubHeading = styled(DashboardSubHeadingBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 27px;
  height: 72px;
  &&& {
    @media only screen and (max-width: ${tabletMaxWidth}px) {
      height: 76px;
    }
    @media only screen and (max-width: ${mobileMaxWidthPx}) {
      flex-flow: column;
      align-items: flex-start;
      margin-bottom: 0;
      padding-bottom: 0;
      height: auto;
    }
    h2 {
      @media only screen and (max-width: ${mobileMaxWidthPx}) {
        margin-top: 0;
      }
    }
  }
`;

export const GraphHeader = styled(DashboardSubHeading)`
  &&& {
    @media only screen and (max-width: ${w430}px) {
      height: fit-content;
    }
    @media only screen and (max-width: ${w397}px) {
      padding-top: 0px;
    }
  }
`;
