import React from 'react';

import { useDevicePage } from '../components/pages/use-device-page';
import { isMobileAndBelow } from '../constants';
import { Features } from '../contexts/account-context/types';
import { StoreContext } from '../store';
import {
  FeatureActions,
  FeaturesState,
} from '../store/reducers/features-reducer';

export function useFeatures() {
  const { dispatch, store } = React.useContext(StoreContext);
  const { hasActiveForm } = useDevicePage();

  const flags = store.features;

  const set = (features: FeaturesState) => {
    dispatch({
      type: FeatureActions.set,
      data: features,
    });
  };

  const isEnabled = (feature: Features): boolean => flags[feature];

  const {
    GET_MORE_SENSORS,
    DRAG_DROP_ALERTS,
    AUDIT_LOG,
    TWO_FACTOR_AUTHENTICATION,
    PASSWORD_ROTATION,
    EQUIPMENT_PAGE,
    ENERGY_DASHBOARD,
  } = Features;

  const isGetMoreSensorsEnabled = isEnabled(GET_MORE_SENSORS);
  const isDragEnabled =
    (isEnabled(DRAG_DROP_ALERTS) ?? true) && !isMobileAndBelow();
  const isAuditLogEnabled = isEnabled(AUDIT_LOG);
  const is2faEnabled = isEnabled(TWO_FACTOR_AUTHENTICATION);
  const isPassRotationEnabled = isEnabled(PASSWORD_ROTATION);
  const isEquipmentEnabled = isEnabled(EQUIPMENT_PAGE);
  const isEnergyDashboardEnabled = isEnabled(ENERGY_DASHBOARD);

  // checks if drag is allowed on device page
  // maybe enabled but could be disallowed by some interactions
  const isDragAllowed = isDragEnabled && !hasActiveForm();

  return {
    set,
    isDragAllowed,
    isDragEnabled,
    isGetMoreSensorsEnabled,
    isAuditLogEnabled,
    is2faEnabled,
    isPassRotationEnabled,
    isEquipmentEnabled,
    isEnergyDashboardEnabled,
    flags,
  };
}
