export const equipmentInstallation = [
  {
    text: 'Reach-in refrigerator',
    value: 'refrigerator+free_standing',
  },
  {
    text: 'Walk-in refrigerator',
    value: 'refrigerator+built_in',
  },
  {
    text: 'Reach-in freezer',
    value: 'freezer+free_standing',
  },
  {
    text: 'Walk-in freezer',
    value: 'freezer+built_in',
  },
  {
    text: 'Other',
    value: 'other',
  },
];
