import { EquipmentModel } from '@coinspect/therma-entities';

import { NormalAlertModel } from '../../hooks';
import {
  AlertNotifModel,
  DeviceModel,
  InviteModel,
  LocationModel,
  NotifConfigModel,
  ReportConfigModel,
  TeamLocationModel,
  TeamModel,
  UserModel,
} from '../../services';
import { genericEntityReducer } from '../utils';

export const deviceEntityReducer = genericEntityReducer<DeviceModel>('device');
export const userEntityReducer = genericEntityReducer<UserModel>('user');
export const teamsReducer = genericEntityReducer<TeamModel>('teams');

export const inviteEntityReducer = genericEntityReducer<InviteModel>(
  'invite',
  true,
);

export const activeAlertEntityReducer = genericEntityReducer<AlertNotifModel>(
  'activeAlert',
);

export const alertConfigEntityReducer = genericEntityReducer<NormalAlertModel>(
  'alertConfig',
);

export const alertNotifEntityReducer = genericEntityReducer<AlertNotifModel>(
  'alertNotif',
);

export const locationEntityReducer = genericEntityReducer<LocationModel>(
  'location',
  true,
);

export const notifConfigEntityReducer = genericEntityReducer<NotifConfigModel>(
  'notifConfig',
  false,
  'alertConfig',
);

export const reportConfigReducer = genericEntityReducer<ReportConfigModel>(
  'reportConfig',
);

export const teamLocationReducer = genericEntityReducer<TeamLocationModel>(
  'teamLocation',
);

export const equipmentReducer = genericEntityReducer<EquipmentModel>(
  'equipment',
);
