import React, { FunctionComponent } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { tabletMaxWidth } from 'src/constants';
import styled from 'styled-components';

import { DashboardLoader } from '../../components/dashboard-loader';
import { Tooltip } from '../tooltip';

interface DashboardCardProps {
  title?: string;
  tooltip?: string;
  query?: string;
  className?: string;
}

const DashboardCardWrapper = styled.div`
  position: relative;
  &&&&&& .ui.segment {
    &.segment--wrapped {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;
      align-self: center;
      width: 100%;
      padding: 25px;
      @media only screen and (max-width: ${tabletMaxWidth}px) {
        padding: 25px;
      }
      h2 {
        margin-bottom: 20px;
      }

      box-shadow: none; // hide box-shadow d
    }
  }
`;

const DashboardCardTooltip = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  opacity: 0;
`;

export const DashboardCard: FunctionComponent<DashboardCardProps> = (props) => {
  const { title, tooltip, children, query, className } = props;

  return (
    <>
      <DashboardCardWrapper className={className}>
        {tooltip && (
          <Tooltip content={tooltip} trigger={<DashboardCardTooltip />} />
        )}
        <Segment className="collapsed segment--wrapped">
          {query && <DashboardLoader query={query} />}

          {title && <Header as="h2">{title}</Header>}
          {children}
        </Segment>
      </DashboardCardWrapper>
    </>
  );
};
