import { ThermaSurface400 } from '@coinspect/ui';
import React, { useContext, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';

import { AccountContext, StandardModalContext } from '../../contexts';
import { EquipmentType, InstallationType } from '../../services';
import { StoreContext } from '../../store';
import {
  convertTriggerValue,
  SelectedOptionsType,
} from '../devices/device-alert-config-list';
import StandardModalBody from '../modals/standard-modal-body';

const recommendedAlertMapping = {
  maximum: {
    label: 'Maximum',
    subText: (temp: string) => `notify when above ${temp} for 2 hrs`,
    defaultChecked: true,
  },
  minimum: {
    label: 'Minimum',
    subText: (temp: string) => `notify when below ${temp} for 2 hrs`,
    defaultChecked: false,
  },
};

const RecommendedAlertOptions: React.FC<{
  type: 'maximum' | 'minimum';
  temp: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}> = (props) => {
  const { type, temp, checked, onChange } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '7px',
      }}
    >
      <Checkbox
        className="RecommendedAlertOptionsCheckbox"
        defaultChecked={checked}
        onClick={() => onChange(!checked)}
        id={`checkbox-${type}`}
      />
      <label htmlFor={`checkbox-${type}`} style={{ cursor: 'pointer' }}>
        <div
          style={{
            fontStyle: 'bold',
            paddingBottom: '4px',
            color: 'rgba(25, 40, 63, 0.8)',
            fontWeight: 700,
            letterSpacing: '0.25px',
          }}
        >
          {recommendedAlertMapping[type].label}
        </div>
        <div
          style={{ fontWeight: 'normal', fontSize: '34px', lineHeight: '36px' }}
        >
          {temp}
        </div>
        <span
          style={{
            fontSize: '12px',
            color: '#979B9B',
            letterSpacing: '-0.02em',
          }}
        >
          {recommendedAlertMapping[type].subText(temp)}
        </span>
      </label>
    </div>
  );
};

type ShowRecommendedAlertModalProps = {
  equipmentType: EquipmentType;
  installation: InstallationType;
  handleNormalAlert: () => void;
  handleRecommendedAlert: (data: SelectedOptionsType) => void;
  selectedOptions: SelectedOptionsType;
};

export const RecommendedAlertsModal: React.FC<ShowRecommendedAlertModalProps> = ({
  equipmentType,
  installation,
  handleNormalAlert,
  handleRecommendedAlert,
  selectedOptions,
}) => {
  const { store } = useContext(StoreContext);
  const { user } = useContext(AccountContext);
  const [minimumChecked, setMinimumChecked] = useState(false);
  const [maximumChecked, setMaximumChecked] = useState(true);
  const { recommended_alerts } = store.config;
  const tempUnit = user?.preferredTempUnit.toUpperCase();

  const { hideModal } = useContext(StandardModalContext);

  const recommendedAlertValues =
    recommended_alerts?.[equipmentType as 'freezer'][installation];
  const minimumTemp = recommendedAlertValues?.[0].triggerValue;
  const maximumTemp = recommendedAlertValues?.[1].triggerValue;
  const displayMinimumTemp = convertTriggerValue(
    recommendedAlertValues?.[0].triggerValue as number,
    user?.preferredTempUnit,
  );
  const displayMaximumTemp = convertTriggerValue(
    recommendedAlertValues?.[1].triggerValue as number,
    user?.preferredTempUnit,
  );

  if (equipmentType === 'other') return null;

  return (
    <StandardModalBody>
      <StandardModalBody.Content>
        <div>
          <p style={{ marginBottom: '20px' }}>
            These recommendations are calculated based on how our customers set
            up temperature alerts. Please check food safety regulations to be
            compliant.
          </p>
          <div
            style={{
              display: 'flex',
              border: `1px solid ${ThermaSurface400}`,
              justifyContent: 'space-between',
              padding: '20px 16px',
            }}
          >
            <RecommendedAlertOptions
              type="maximum"
              temp={`${displayMaximumTemp}°${tempUnit}`}
              checked={maximumChecked}
              onChange={setMaximumChecked}
            />
            <RecommendedAlertOptions
              type="minimum"
              temp={`${displayMinimumTemp}°${tempUnit}`}
              checked={minimumChecked}
              onChange={setMinimumChecked}
            />
          </div>
        </div>
      </StandardModalBody.Content>
      <StandardModalBody.Actions
        primaryButton={{
          content: 'Save',
          onClick: () => {
            if (!maximumChecked && !minimumChecked) {
              handleNormalAlert();
            } else {
              const selectedArr = [];
              if (maximumChecked) selectedArr.push('maximum');
              if (minimumChecked) selectedArr.push('minimum');
              handleRecommendedAlert({
                ...selectedOptions,
                maximum: {
                  ...selectedOptions.maximum,
                  selected: maximumChecked,
                  temp: maximumTemp as number,
                  triggerComparator: '>',
                },
                minimum: {
                  ...selectedOptions.minimum,
                  selected: minimumChecked,
                  temp: minimumTemp as number,
                  triggerComparator: '<',
                },
                selected: selectedArr,
              });
            }
            hideModal();
          },
        }}
        secondaryButton={{
          content: 'Skip',
          onClick: () => {
            handleNormalAlert();
            hideModal();
          },
        }}
        leftButton={{
          content: 'Cancel',
          onClick: () => hideModal(),
        }}
      />
    </StandardModalBody>
  );
};
