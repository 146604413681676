import React, { FunctionComponent, useContext } from 'react';
import { Message } from 'semantic-ui-react';

import { AccountContext } from '../../contexts';

export const FailedPasswordResetMessage: FunctionComponent = () => {
  const account = useContext(AccountContext);

  return (
    <Message negative>
      <Message.Header>{account.error}</Message.Header>
    </Message>
  );
};
