/* eslint-disable @typescript-eslint/no-explicit-any */
// for 2fa stuff
declare global {
  interface Window {
    resolver: any;
    verificationId: any;
    recaptchaVerifier: any;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

import firebase from 'firebase/app';
import 'firebase/auth';

import { UserService } from '../../services';
import { UserProfile } from '../account-context';

async function verifyEmail(firebaseUUID: string, emailVerified: true) {
  await UserService.verifyFirebaseEmail(firebaseUUID, emailVerified);
}

async function send2faCode(phoneNumber: string, toVerify: boolean) {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    return;
  }

  if (toVerify) {
    await verifyEmail(currentUser.uid, true);
  }

  const session = await currentUser.multiFactor.getSession();
  const phoneOpts = {
    phoneNumber: phoneNumber.replace('-', ''),
    session,
  };

  const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

  // this will send code to phone number
  try {
    window.verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneOpts,
      window.recaptchaVerifier,
    );
  } catch (e) {
    switch (e.code) {
      case 'auth/invalid-phone-number':
      // console.error(`${e.code}: ${phoneOpts.phoneNumber}`);
      case 'auth/requires-recent-login':
        throw e;

      default:
        // eslint-disable-next-line no-console
        console.error('2FAE1', e.code);
    }
  }
}

async function isTwoAuthFlow(
  currentUser: firebase.User,
  user: UserProfile,
  logout: () => Promise<void>,
) {
  const { signedUpDate, phoneNumber } = user;
  const {
    emailVerified,
    multiFactor: { enrolledFactors },
  } = currentUser;
  try {
    const hasEnrolledFactors = !!enrolledFactors.length;
    // if old user and not verified then verify it manually
    if (signedUpDate && !emailVerified) {
      // send 2fa so user can be enrolled to 2fa
      // await send2faCode(phoneNumber, true);
      return send2faCode(phoneNumber, true);
    }

    // if old user and verified but haven't enrolled to 2fa yet
    // send 2fa code again until user enrolls
    if (signedUpDate && emailVerified && !hasEnrolledFactors) {
      // resend code
      return send2faCode(phoneNumber, false);
    }
  } catch (e) {
    switch (e.code) {
      case 'auth/requires-recent-login':
        logout();
        break;
    }
  }
}

async function enrollCurrentUser(code: string) {
  const { currentUser } = firebase.auth();
  if (!currentUser) return;

  const { enrolledFactors } = currentUser.multiFactor;
  const enrolled = !!enrolledFactors.find(
    (f) => f.displayName === 'phone number',
  );

  if (enrolled) return;

  const cred = firebase.auth.PhoneAuthProvider.credential(
    window.verificationId,
    code,
  );

  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
    cred,
  );

  await currentUser?.multiFactor.enroll(multiFactorAssertion, 'phone number');
}

async function verify2faCode(code: string) {
  await enrollCurrentUser(code);

  const cred = firebase.auth.PhoneAuthProvider.credential(
    window.verificationId,
    code,
  );

  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
    cred,
  );

  if (window.resolver) {
    await window.resolver.resolveSignIn(multiFactorAssertion);
  }
}

function initCaptcha(cb?: () => void) {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      '2fa-captcha',
      {
        size: 'invisible',
        callback: () => {
          console.info('captcha solved!');
          cb && cb();
        },
      },
    );
  }
}

async function verifyUser() {
  const phoneOpts = {
    multiFactorHint: window.resolver.hints[0],
    session: window.resolver.session,
  };

  const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

  window.verificationId = await phoneAuthProvider.verifyPhoneNumber(
    phoneOpts,
    window.recaptchaVerifier,
  );
}

export { send2faCode, isTwoAuthFlow, verify2faCode, initCaptcha, verifyUser };
