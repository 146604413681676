import { NotifConfigService } from '../services';

export function useNotifConfig() {
  async function browseNotifConfigByUserUUID(userUUID: string) {
    const response = await NotifConfigService.browseNotifConfigByUserUUID(
      userUUID,
    );

    if (response.errors?.length) {
      throw response.errors;
    }

    return response;
  }

  return {
    browseNotifConfigByUserUUID,
  };
}
