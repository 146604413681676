import { ThermaSecondaryAction400 } from '@coinspect/ui';
import cuid from 'cuid';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import uniqWith from 'lodash/uniqWith';
import moment from 'moment-timezone';
import React, { FunctionComponent, SyntheticEvent, useState } from 'react';
import {
  Button,
  Divider,
  Dropdown,
  DropdownItemProps,
  Icon,
} from 'semantic-ui-react';
import styled from 'styled-components';

import {
  DesktopAndTablet,
  mobileMaxWidthPx,
  MobileOnly,
} from '../../../constants';
import { AddTextButton } from '../../buttons';
import StandardModalBody from '../../modals/standard-modal-body';
import { Tooltip } from '../../tooltip';

export interface AlertSchedule {
  day: number;
  fromTime: string;
  key: string;
  toTime: string;
}

interface AlertScheduleFormProps {
  existingAlertSchedules?: AlertSchedule[];
  existingTimezone: string;
  onSubmit?: (
    data: AlertSchedule[],
    timezoneSchule: string,
  ) => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
}

const ScheduleFormRow = styled.div`
  display: grid;
  grid-template-areas: 'date fromTime toTime button';
  grid-template-columns: 40% 130px 130px 100px;
`;

const ScheduleFormRowLast = styled(ScheduleFormRow)`
  padding-top: 20px;
`;

const TimezoneRow = styled(ScheduleFormRow)`
  &&& {
    grid-template-areas: 'timezone button';
    grid-template-columns: 84% 100px;

    @media only screen and (min-width: ${mobileMaxWidthPx}) {
      grid-template-columns: 81% 100px;
    }

    @media only screen and (max-width: ${mobileMaxWidthPx}) {
      grid-template-areas: 'timezone';
      grid-template-columns: 1fr;
      row-gap: 15px;
      padding: 20px 0;
    }
  }
`;

const SchedulerFormField = styled.div<{
  $gridArea?: string;
}>`
  padding-right: 10px;
  grid-area: ${({ $gridArea }): string | undefined => $gridArea};

  label {
    display: block;
    font-weight: bolder;
    padding-bottom: 10px;
  }

  &.form-field--day-field {
    flex-grow: 5;
  }
`;

const FieldActions = styled(SchedulerFormField)`
  width: 65px;
  height: 45px;
  align-self: flex-end;
  padding: 0px 15px 5px;
`;

const RowDivider = styled(Divider)`
  && {
    margin: 16px -15px;
  }
`;

const ScheduleFormContainer = styled.div`
  .action-align {
    align-self: flex-end;
  }

  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    ${ScheduleFormRow} {
      grid-template-areas:
        'date date'
        'fromTime fromTime'
        'toTime button';
      grid-template-columns: 1fr max-content;
      row-gap: 15px;
      padding: 20px 0;
    }
  }
`;

const DuplicateMessage = styled.div`
  text-align: left;
`;

const defaultAlertSchedule: AlertSchedule = {
  day: 0,
  fromTime: '12:00 AM',
  key: cuid(),
  toTime: '12:30 AM',
};

export const AlertSchedulerForm: FunctionComponent<AlertScheduleFormProps> = (
  props,
) => {
  const {
    existingAlertSchedules = [defaultAlertSchedule],
    existingTimezone = moment.tz.guess(true),
    onCancel,
    onSubmit,
  } = props;
  const [alertSchedules, setAlertSchedules] = useState<AlertSchedule[]>([
    ...existingAlertSchedules.map((schedule: AlertSchedule) => ({
      ...schedule,
      key: cuid(),
    })),
  ]);
  const [timezone, setTimezone] = useState<string>(existingTimezone);
  const [hasDuplicates, setHasDuplicates] = useState<boolean>(false);

  const dayOptions = [
    { text: 'Monday', value: 1 },
    { text: 'Tuesday', value: 2 },
    { text: 'Wednesday', value: 3 },
    { text: 'Thursday', value: 4 },
    { text: 'Friday', value: 5 },
    { text: 'Saturday', value: 6 },
    { text: 'Sunday', value: 0 },
  ];

  const getTimeOptions = (): DropdownItemProps[] => {
    const timeOptions = [];
    for (let meridiem = 0; meridiem < 2; meridiem++) {
      const timeSuffix = ['AM', 'PM'];
      let time = '';
      for (let hour = 0; hour < 12; hour++) {
        for (let minuteMult = 0; minuteMult < 2; minuteMult++) {
          time =
            (hour === 0 ? 12 : hour) +
            ':' +
            (minuteMult === 0 ? '00' : 30 * minuteMult);
          const formattedTime = `${time} ${timeSuffix[meridiem]}`;
          timeOptions.push({ text: formattedTime, value: formattedTime });
        }
      }
    }
    return timeOptions;
  };

  const getTimezones = () => {
    const timezones = moment.tz.names();
    const regions = ['America', 'Asia', 'Canada'];

    const UnitedKingdomTimezones = [
      'Europe/Gibraltar',
      'Europe/Guernsey',
      'Europe/Jersey',
    ];

    const parsableTimezones = [
      { timezone: 'America/Denver', suffix: 'MST' },
      { timezone: 'America/Chicago', suffix: 'CST' },
      { timezone: 'America/New_York', suffix: 'EST' },
      { timezone: 'America/Los_Angeles', suffix: 'PST' },
    ];

    const filteredTimezones = timezones
      .filter((tz) => regions.includes(tz.split('/')[0]))
      .concat(UnitedKingdomTimezones);

    return filteredTimezones.map((tz, i) => {
      const isParsable = parsableTimezones.find(
        (parsable) => parsable.timezone === tz,
      );

      const description = isParsable ? `${tz}/${isParsable.suffix}` : tz;

      return {
        key: i,
        text: `${description} (GMT${moment.tz(tz).format('Z')})`,
        value: tz,
      };
    });
  };

  const handleAddAlertSchedule = (): void => {
    setAlertSchedules([
      ...alertSchedules,
      { ...defaultAlertSchedule, key: cuid() },
    ]);
  };

  const checkDuplicates = (
    schedule: AlertSchedule[],
    tz: string,
  ): undefined => {
    const newSchedule = [];
    for (const sched of schedule) {
      const omitted = omit(sched, 'key');
      newSchedule.push(omitted);
    }

    const uniqueSched = uniqWith(newSchedule, isEqual);
    if (uniqueSched.length !== schedule.length) {
      setHasDuplicates(true);
      return;
    }

    if (onSubmit) {
      onSubmit(schedule, tz);
      setHasDuplicates(false);
    }
  };

  const handleDeleteAlertSchedule = (key: string) => {
    const selectedSchedule = alertSchedules.findIndex(
      (alertSchedule) => alertSchedule.key === key,
    );
    const schedules = alertSchedules.slice(0, selectedSchedule);
    const updatedList = schedules.concat(
      alertSchedules.slice(selectedSchedule + 1),
    );
    setAlertSchedules(updatedList);
  };

  const handleFieldValueChange = (
    key: string,
    field: string,
    value:
      | string
      | number
      | boolean
      | Array<string | number | boolean>
      | undefined,
  ): void => {
    const selectedSchedule = alertSchedules.findIndex(
      (alertSchedule) => alertSchedule.key === key,
    );
    const schedules = alertSchedules.slice();
    schedules[selectedSchedule] = {
      ...alertSchedules[selectedSchedule],
      [field]: value,
    };
    setAlertSchedules(schedules);
  };

  return (
    <StandardModalBody>
      <StandardModalBody.SubHeader>
        Select the day(s) and time(s) you would like to receive alerts.
      </StandardModalBody.SubHeader>
      <StandardModalBody.Content>
        <ScheduleFormContainer>
          <TimezoneRow>
            <SchedulerFormField>
              <Tooltip
                content="The time zone in which your alerts will be sent"
                trigger={
                  <>
                    <label>Time zone:</label>
                    <Dropdown
                      fluid
                      search
                      selection
                      onChange={(
                        e: SyntheticEvent<HTMLElement, Event>,
                        field,
                      ): void => setTimezone(field.value as string)}
                      defaultValue={timezone}
                      options={getTimezones()}
                    />
                  </>
                }
              />
            </SchedulerFormField>
          </TimezoneRow>
          <MobileOnly>
            <RowDivider />
          </MobileOnly>
          {alertSchedules.map((alertSchedule: AlertSchedule, idx: number) => (
            <div key={alertSchedule.key}>
              <ScheduleFormRowLast>
                <SchedulerFormField
                  className="form-field--day-field"
                  $gridArea="date"
                >
                  {idx === 0 && <label>Day:</label>}
                  {idx !== 0 && (
                    <MobileOnly>
                      <label>Day:</label>
                    </MobileOnly>
                  )}
                  <Dropdown
                    fluid
                    onChange={(
                      e: SyntheticEvent<HTMLElement, Event>,
                      { value },
                    ): void =>
                      handleFieldValueChange(alertSchedule.key, 'day', value)
                    }
                    options={dayOptions}
                    selection
                    defaultValue={alertSchedule.day}
                  />
                </SchedulerFormField>
                <SchedulerFormField $gridArea="fromTime">
                  {idx === 0 && <label>From:</label>}
                  {idx !== 0 && (
                    <MobileOnly>
                      <label>From:</label>
                    </MobileOnly>
                  )}
                  <Dropdown
                    fluid
                    onChange={(
                      e: SyntheticEvent<HTMLElement, Event>,
                      { value },
                    ): void =>
                      handleFieldValueChange(
                        alertSchedule.key,
                        'fromTime',
                        value,
                      )
                    }
                    options={getTimeOptions()}
                    selection
                    defaultValue={alertSchedule.fromTime}
                  />
                </SchedulerFormField>
                <SchedulerFormField $gridArea="toTime">
                  {idx === 0 && <label>To:</label>}
                  {idx !== 0 && (
                    <MobileOnly>
                      <label>To:</label>
                    </MobileOnly>
                  )}
                  <Dropdown
                    fluid
                    onChange={(
                      e: SyntheticEvent<HTMLElement, Event>,
                      { value },
                    ): void =>
                      handleFieldValueChange(alertSchedule.key, 'toTime', value)
                    }
                    options={getTimeOptions()}
                    selection
                    defaultValue={alertSchedule.toTime}
                  />
                </SchedulerFormField>
                <div className="action-align">
                  <FieldActions $gridArea="button">
                    <Tooltip
                      content="Delete"
                      trigger={
                        <Button
                          circular
                          icon
                          onClick={(): void =>
                            handleDeleteAlertSchedule(alertSchedule.key)
                          }
                        >
                          <Icon
                            aria-label="Delete Alert Schedule"
                            size="large"
                            className="icon-delete"
                          />
                        </Button>
                      }
                    />
                  </FieldActions>
                </div>
              </ScheduleFormRowLast>
              <MobileOnly>
                <RowDivider />
              </MobileOnly>
            </div>
          ))}
          {hasDuplicates ? (
            <DuplicateMessage className="ui negative message">
              <div className="header">{`Please select another day or time. The values you selected already exist.`}</div>
            </DuplicateMessage>
          ) : (
            ''
          )}
        </ScheduleFormContainer>
      </StandardModalBody.Content>
      <StandardModalBody.Actions
        primaryButton={{
          content: 'Save',
          onClick: (): void => checkDuplicates(alertSchedules, timezone),
        }}
        secondaryButton={{
          content: 'Cancel',
          onClick: onCancel,
        }}
      >
        <StandardModalBody.Actions.BeforeButtonArea>
          <DesktopAndTablet>
            <ScheduleFormRow>
              <SchedulerFormField>
                <AddTextButton
                  onClick={(): void => handleAddAlertSchedule()}
                  label="Add another rule"
                  color={ThermaSecondaryAction400}
                  hoverColor={ThermaSecondaryAction400}
                />
              </SchedulerFormField>
            </ScheduleFormRow>
          </DesktopAndTablet>
          <MobileOnly>
            <AddTextButton
              onClick={(): void => handleAddAlertSchedule()}
              label="Add another rule"
              color={ThermaSecondaryAction400}
              hoverColor={ThermaSecondaryAction400}
            />
          </MobileOnly>
        </StandardModalBody.Actions.BeforeButtonArea>
      </StandardModalBody.Actions>
    </StandardModalBody>
  );
};
