import { FormInput, FormInputProps } from '@coinspect/ui';
import { isValidEmail } from '@coinspect/utils';
import React from 'react';

import { isValidJoiEmail } from '../../../lib';

export const EmailField: React.FC<FormInputProps> = (props) => {
  const { name, onBlur } = props;
  return (
    <FormInput
      name={name}
      type="email"
      required
      label="Email:"
      placeholder="Email"
      customErrors={{
        email: 'Invalid email format.',
        required: 'Email is required',
      }}
      validation={{
        email: (val): boolean => isValidEmail(val) && isValidJoiEmail(val),
      }}
      id="invite-email-address"
      onBlur={onBlur}
    />
  );
};
