import { ModalHeaderText, ThermaSecondaryAction400 } from '@coinspect/ui';
import React, { FC } from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { mobileMaxWidthPx } from '../../constants';
import { getChild } from './standard-modal-body';

export interface StandardModalButtons {
  className?: string;
  content: string;
  onClick?: (...args: any[]) => void | Promise<void>;
  disabled?: boolean;
  loading?: boolean;
}

export interface StandardModalActionsProps {
  primaryButton?: StandardModalButtons;
  secondaryButton?: StandardModalButtons;
  leftButton?: StandardModalButtons;
  children: React.ReactNode;
}

export interface ModalButtonProps extends StandardModalButtons {
  secondary?: boolean;
  primary?: boolean;
}

const ModalButton: React.FC<ModalButtonProps> = (props) => {
  const { loading = false, icon, content, primary = false, ...rest } = props;

  return (
    <Button loading={primary && loading} primary={primary} {...rest}>
      {icon && <Icon name={icon} />}
      {content}
    </Button>
  );
};

const BeforeButtonArea = styled.div`
  &&& {
    padding: 0px !important;

    @media only screen and (min-width: ${mobileMaxWidthPx}) {
      margin-top: 20px;
      text-align: left;
    }

    @media only screen and (max-width: ${mobileMaxWidthPx}) {
      margin-top: 0px;
      text-align: center;
    }
  }
`;
BeforeButtonArea.displayName = 'BeforeButtonArea';

const ButtonArea = styled.div`
  &&& {
    padding: 0px !important;

    @media only screen and (min-width: ${mobileMaxWidthPx}) {
      margin-top: 30px;
      display: flex;
    }

    @media only screen and (max-width: ${mobileMaxWidthPx}) {
      margin-top: 0px;
    }
  }
`;

const FlexPad = styled.div`
  flex-grow: 1;
`;

const AfterButtonArea = styled.div`
  &&& {
    font-family: proxima-nova;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    text-align: center;
    color: ${ModalHeaderText};

    padding-top: 20px;

    a {
      cursor: pointer;
      color: ${ThermaSecondaryAction400};
      text-decoration-line: underline;
    }

    @media only screen and (min-width: ${mobileMaxWidthPx}) {
      text-align: right;
    }

    @media only screen and (max-width: ${mobileMaxWidthPx}) {
      text-align: center;
    }
  }
`;
AfterButtonArea.displayName = 'AfterButtonArea';

const WrappedModalActions = styled(Modal.Actions)`
  &&& {
    padding: 0px !important;

    @media only screen and (max-width: ${mobileMaxWidthPx}) {
      margin-top: 30px;
    }

    @media only screen and (min-width: ${mobileMaxWidthPx}) {
      margin-top: 0px;
    }

    ${ButtonArea} > .button {
      padding: 14.5px 24px;
      margin: 0px;

      @media only screen and (min-width: ${mobileMaxWidthPx}) {
        margin-left: 12px;
      }

      @media only screen and (max-width: ${mobileMaxWidthPx}) {
        margin-top: 6px;
        width: 100%;
      }
    }
  }
`;

interface StandardModalActionsInterface extends FC<StandardModalActionsProps> {
  BeforeButtonArea: typeof BeforeButtonArea;
  AfterButtonArea: typeof AfterButtonArea;
}

const StandardModalActions: StandardModalActionsInterface = (props) => {
  const { primaryButton, secondaryButton, leftButton, children } = props;

  const beforeButtonAreaChild = getChild(children, 'BeforeButtonArea');
  const afterButtonAreaChild = getChild(children, 'AfterButtonArea');

  return (
    <WrappedModalActions>
      {beforeButtonAreaChild ?? <></>}
      {primaryButton || secondaryButton ? (
        <ButtonArea>
          {leftButton && <ModalButton {...leftButton} secondary />}
          <FlexPad />
          {secondaryButton && <ModalButton {...secondaryButton} secondary />}
          {primaryButton && <ModalButton {...primaryButton} primary />}
        </ButtonArea>
      ) : (
        <></>
      )}
      {afterButtonAreaChild ?? <></>}
    </WrappedModalActions>
  );
};
StandardModalActions.displayName = 'Actions';

StandardModalActions.BeforeButtonArea = BeforeButtonArea;
StandardModalActions.AfterButtonArea = AfterButtonArea;

export default StandardModalActions;
