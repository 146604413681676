import { toCelsius } from '@coinspect/utils';
import moment from 'moment';

import { ControlEventsParams, TempUnits } from '../../../services';
import { GraphDataPoint, HvacGraphDataPoint } from '../../../store/reducers';
import {
  convertToPreferredUnit,
  oppositePreferredUnit,
} from '../../../utils/temperature-util';
import { allGraphXTickLabels, getInterval } from '../../dashboard';
import {
  ANNOTATION_COLORS,
  ECO_COLOR_TRANSPARENT,
  SETPOINT_COLOR,
  THRESHOLD_LINE_WIDTH,
} from '../constants/chart-constants';
import { EnergyGraphType } from '../types';

export const TIME_FORMAT = 'h:mmA';
const DATE_FORMAT = 'MM/DD';

/**
 *
 * Determine the y axis scale to be used
 */
export const getGraphScale = (
  type: 'refrigerator' | 'hvac',
  temperatureUnit: 'f' | 'c',
  isHumidity = false,
) => {
  if (isHumidity) {
    return { min: 0, max: 100, interval: 20 };
  }
  switch (type) {
    case 'hvac':
      switch (temperatureUnit) {
        case 'c':
          return { min: -20, max: 40, interval: 10 };
        case 'f':
        default:
          return { min: -20, max: 120, interval: 20 };
      }
    case 'refrigerator':
    default:
      switch (temperatureUnit) {
        case 'c':
          return { min: -30, max: 30, interval: 10 };
        case 'f':
        default:
          return { min: -40, max: 100, interval: 20 };
      }
  }
};

/**
 *
 * Generates the labels for the x axis
 */
export const generateLabels = (
  graphDataToUse: GraphDataPoint[],
  start: string,
  end: string,
) => {
  // if no graph data for both temp and humid then generate labels from range
  if (graphDataToUse.length === 0) {
    const interval = getInterval(start, end);
    const values: string[] = Array.from(
      Array(30),
      (_: undefined, idx: number) => {
        const startDate = new Date(start);
        startDate.setMinutes(startDate.getMinutes() + idx * interval);
        return startDate.toISOString();
      },
    );
    const labels = allGraphXTickLabels(
      values,
      interval,
      true,
      true,
      TIME_FORMAT,
      DATE_FORMAT,
    );
    return labels as [];
  }
  // if temp has data then use it for labels, else use humid
  // generate labels from the data
  const data = graphDataToUse.map(({ time }) => time);
  return data;
};

export const generateGraphData = (
  graphData: GraphDataPoint[],
  type?: 'hvac',
  preferredTempUnit?: TempUnits,
) => {
  const data = graphData.map(({ value }) => {
    if (preferredTempUnit) {
      if (type === 'hvac') {
        return preferredTempUnit === 'c' ? toCelsius(value) : value;
      }
      return convertToPreferredUnit(value, 'temperature', preferredTempUnit);
    }
    return value;
  });
  return data;
};

/**
 *
 * Controls how the x axis label is displayed
 */
export const generateDisplayLabel = (
  start: string,
  end: string,
  value: string,
  index: number,
  graphDataToUse: GraphDataPoint[],
) => {
  if (index % 3 !== 0) {
    return '';
  }

  // if no graphData, just use the label generated from range
  if (graphDataToUse.length === 0) {
    return value;
  }

  const interval = getInterval(start, end);

  if (interval > 48) {
    return moment(value).format(DATE_FORMAT);
  } else {
    return moment(value).format(TIME_FORMAT);
  }
};

/**
 *
 * Generate horizontal line (annotation) for threshold and set point
 */
export function generateAnnotation(
  type:
    | 'setPoint'
    | 'temperatureMinThreshold'
    | 'temperatureMaxThreshold'
    | 'humidityMinThreshold'
    | 'humidityMaxThreshold'
    | 'occupiedCoolSetPoint'
    | 'occupiedHeatSetPoint'
    | 'unoccupiedCoolSetPoint'
    | 'unoccupiedHeatSetPoint',
  value: number,
  preferredTempUnit: TempUnits,
) {
  const isHvac = [
    'occupiedCoolSetPoint',
    'occupiedHeatSetPoint',
    'unoccupiedCoolSetPoint',
    'unoccupiedHeatSetPoint',
  ].includes(type);
  const converter = isHvac ? oppositePreferredUnit : convertToPreferredUnit;

  const convertedValue = converter(value, 'temperature', preferredTempUnit);

  switch (type) {
    case 'setPoint':
      return {
        type: 'line',
        yMin: convertedValue,
        yMax: convertedValue,
        borderColor: SETPOINT_COLOR,
        borderWidth: 3,
        borderDash: [15],
      };

    default:
      return {
        type: 'line',
        yMin: convertedValue,
        yMax: convertedValue,
        borderColor: ANNOTATION_COLORS[type],
        borderWidth: THRESHOLD_LINE_WIDTH,
        borderDash: [7],
      };
  }
}

/**
 *
 * This function determines where to put the alert notif dot
 */
export function matchAlertNotifClosestGraphTime(
  alertNotif: {
    endedAt: string;
    deviceUUID: string;
    metadata: { lastSensorValue: number };
  }[],
  temperatureData: GraphDataPoint[],
  preferredTempUnit?: TempUnits,
) {
  const values: GraphDataPoint[] = Array(temperatureData.length).fill({
    value: null,
    time: null,
  });

  alertNotif.forEach(
    ({ endedAt, deviceUUID, metadata: { lastSensorValue } }) => {
      const graphDataIndex = temperatureData.findIndex(
        ({ time }) => Date.parse(endedAt) < Date.parse(time),
      );

      const graphDataAfter = temperatureData[graphDataIndex];
      const graphDataBefore = temperatureData[graphDataIndex - 1];

      const afterDiff = graphDataAfter
        ? Date.parse(graphDataAfter.time) - Date.parse(endedAt)
        : 0;
      const beforeDiff = graphDataBefore
        ? Date.parse(endedAt) - Date.parse(graphDataBefore.time)
        : 0;

      let indexToInsert = -1;

      /**
       * Decide where to put the alert notif dot
       * And also check if there's an existing alert notif already to avoid duplicate
       */
      if (afterDiff > beforeDiff) {
        if (graphDataBefore && values[graphDataIndex - 1]?.time === null) {
          indexToInsert = graphDataIndex - 1;
        } else {
          indexToInsert = graphDataIndex;
        }
      } else {
        if (graphDataAfter && values[graphDataIndex]?.time === null) {
          indexToInsert = graphDataIndex;
        } else {
          indexToInsert = graphDataIndex - 1;
        }
      }
      values[indexToInsert] = {
        device_uuid: deviceUUID,
        value:
          convertToPreferredUnit(
            lastSensorValue,
            'temperature',
            preferredTempUnit,
          ) || lastSensorValue,
        time: endedAt,
      };
    },
  );

  return values;
}

/**
 * Extracted script for pointBackgroundColor for datasets
 */
export function pointBackgroundColorScript(context: {
  dataIndex: number;
  dataset: { data: (number | null)[]; borderColor: string };
}) {
  const index = context.dataIndex;
  const dataset = context.dataset;
  const beforeIdx = index - 1;
  const afterIdx = index + 1;

  if (dataset.data[beforeIdx] === null && dataset.data[afterIdx] === null) {
    return dataset.borderColor;
  }
  return 'rgba(0, 0, 0, 0)';
}

export function mutateHvacGraphData(
  data: {
    humidity: number;
    temperature: number;
    timestamp: string;
    energyDeviceUUID: string;
  }[],
) {
  const obj: { temperature: GraphDataPoint[]; humidity: GraphDataPoint[] } = {
    temperature: [],
    humidity: [],
  };

  data.forEach(({ humidity, temperature, timestamp, energyDeviceUUID }) => {
    obj.temperature.push({
      value: temperature,
      device_uuid: energyDeviceUUID,
      time: timestamp,
    });
    obj.humidity.push({
      value: humidity,
      device_uuid: energyDeviceUUID,
      time: timestamp,
    });
  });

  return obj;
}

/**
 * This will match the ecoMode startDate and endDate
 * with temperature time which is the x-axis label
 */
export function matchControlEventClosestGraphTime(
  events: ControlEventsParams[],
  temperatureData: GraphDataPoint[] | HvacGraphDataPoint[],
  maxTempValue?: number,
  type?: 'hvac',
) {
  const eventsArr = [];

  for (const event of events) {
    /**
     * Create dummy array with length of temperature data
     * This will be filled with the ecoMode points if found any
     */
    const values: (number | null)[] = Array(temperatureData.length).fill(null);
    const { endDate, startDate } = event;
    let indexStartEco = -1;
    let indexEndEco = -1;

    /**
     * For each control event
     * Loop temperature data to determine where to insert each eco startDate and endDate
     */

    for (const [idx, tempData] of temperatureData.entries()) {
      const timeTempData =
        type === 'hvac'
          ? (tempData as HvacGraphDataPoint).timestamp
          : (tempData as GraphDataPoint).time;
      /**
       * to handle start eco
       * check if eco startDate is later than the currentTemp time
       * then start eco there
       * - Loop from the other side to easily determine when to insert startDate
       */
      const fromEndTempDataIdx = temperatureData.length - (idx + 1);
      const fromEndTempData = temperatureData[fromEndTempDataIdx];

      const dateToParse =
        type === 'hvac'
          ? (fromEndTempData as HvacGraphDataPoint).timestamp
          : (fromEndTempData as GraphDataPoint).time;

      if (
        Date.parse(startDate) >= Date.parse(dateToParse) &&
        indexStartEco === -1
      ) {
        indexStartEco = fromEndTempDataIdx;
        values[fromEndTempDataIdx] = maxTempValue ?? null;
      }

      /**
       * to handle end eco
       * check if eco endDate is earlier than the currentTemp time
       * then end eco there
       */
      if (
        Date.parse(endDate) <= Date.parse(timeTempData) &&
        indexEndEco === -1
      ) {
        indexEndEco = idx;
        values[idx] = maxTempValue ?? null;
      }
    }

    eventsArr.push(values);
  }

  return eventsArr;
}

export function createEcoChartData(data: number[], idx: number) {
  return {
    dataType: `eco-${idx}`,
    type: 'line',
    label: 'Eco',
    data: data,
    fill: { value: -40 }, // eco graph height: ;
    backgroundColor: ECO_COLOR_TRANSPARENT,
    borderColor: ECO_COLOR_TRANSPARENT,
    showLine: false,
    pointRadius: 0,
    pointHoverRadius: 0,
    pointHoverBackgroundColor: 'rgba(255, 255, 255, 0)',
    yAxisID: 'y',
    order: 2,
    spanGaps: true,
  };
}

export type GraphScale = {
  min: number;
  max: number;
  interval: number;
};

export function generateYAxis(
  graphType: EnergyGraphType,
  config: { graphScale: GraphScale; preferredTempUnit: TempUnits },
) {
  const { graphScale, preferredTempUnit } = config;
  if (graphType === 'temperature') {
    return {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
      grid: {
        drawBorder: false,
        drawTicks: false,
        lineWidth: 2,
        color: '#506169',
      },
      min: graphScale.min,
      max: graphScale.max,
      ticks: {
        padding: 20,
        color: '#A1AAAA',
        stepSize: graphScale.interval,
        count: 6,
        callback: (tickValue: unknown) => {
          return `${tickValue}°${preferredTempUnit.toUpperCase()}`;
        },
        maxTicksLimit: 8, // force show fixed y-axis tick values
      },
    };
  }

  return {
    type: 'linear' as const,
    display: true,
    position: 'left' as const,
    grid: {
      drawBorder: false,
      drawTicks: false,
      lineWidth: 2,
      color: '#506169',
    },
    min: 0,
    max: 100,
    ticks: {
      padding: 20,
      color: '#A1AAAA',
      stepSize: 20,
      count: 6,
      callback: (tickValue: unknown) => {
        return `${tickValue}%`;
      },
      maxTicksLimit: 6,
    },
  };
}
