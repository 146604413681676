import React, { FunctionComponent } from 'react';

export const CoalBurnedIcon: FunctionComponent = () => {
  return (
    <span>
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.666687" width="48" height="48" rx="24" fill="#E1EDF1" />
        <path
          d="M27.0215 19.8771C26.9943 19.701 26.9045 19.5406 26.7686 19.4254C26.6326 19.3102 26.4597 19.248 26.2815 19.2501H20.0815C19.9034 19.248 19.7304 19.3102 19.5945 19.4254C19.4586 19.5406 19.3687 19.701 19.3415 19.8771C19.0341 21.952 18.563 23.9994 17.9325 26.0001C17.23 27.9823 16.26 29.8593 15.0495 31.5791C14.9729 31.6915 14.9283 31.8227 14.9205 31.9585C14.9128 32.0944 14.9423 32.2298 15.0057 32.3502C15.0691 32.4706 15.1642 32.5714 15.2806 32.6419C15.397 32.7123 15.5305 32.7497 15.6665 32.75H20.1845"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.6667 30C31.2461 28.5182 30.9319 27.0016 30.727 25.4645C30.7089 25.334 30.649 25.2153 30.5585 25.1299C30.468 25.0446 30.3528 24.9985 30.2341 25H26.1048C25.9862 24.9985 25.871 25.0446 25.7804 25.1299C25.6899 25.2153 25.6301 25.334 25.612 25.4645C25.4072 27.0015 25.0935 28.5181 24.6736 30C24.2056 31.4683 23.5596 32.8587 22.7534 34.1326C22.7024 34.2159 22.6727 34.3131 22.6675 34.4137C22.6624 34.5143 22.682 34.6146 22.7242 34.7038C22.7665 34.793 22.8298 34.8677 22.9073 34.9199C22.9849 34.9721 23.0737 34.9998 23.1644 35H30.6734"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.6667 30C25.0873 28.5182 25.4015 27.0016 25.6064 25.4645C25.6245 25.334 25.6844 25.2153 25.7749 25.1299C25.8654 25.0446 25.9806 24.9985 26.0993 25H30.2286C30.3472 24.9985 30.4624 25.0446 30.553 25.1299C30.6435 25.2153 30.7033 25.334 30.7214 25.4645C30.9262 27.0015 31.2399 28.5181 31.6599 30C32.1278 31.4683 32.7738 32.8587 33.58 34.1326C33.631 34.2159 33.6607 34.3131 33.6659 34.4137C33.671 34.5143 33.6514 34.6146 33.6092 34.7038C33.5669 34.793 33.5036 34.8677 33.4261 34.9199C33.3485 34.9721 33.2597 34.9998 33.169 35H30.16"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6667 17C21.1667 15.5 22.1667 15.1828 23.3002 15.4932C23.5504 15.1219 23.9057 14.8337 24.3206 14.6654C24.7355 14.4971 25.1912 14.4565 25.6294 14.5487C26.0675 14.6408 26.4682 14.8616 26.7802 15.1828C26.9141 14.7099 27.1621 14.2771 27.5025 13.9225C27.8429 13.5678 28.2653 13.3023 28.7324 13.1492C29.1995 12.9961 29.697 12.9601 30.1813 13.0445C30.6655 13.1288 31.1217 13.3308 31.5095 13.6327C31.8974 13.9346 32.2052 14.3273 32.4057 14.776C32.6063 15.2248 32.6935 15.716 32.6596 16.2064C32.6258 16.6967 32.472 17.1713 32.2118 17.5883"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.6666 22.6795C27.6667 22.3595 27.7627 22.0469 27.9423 21.7816C28.1219 21.5162 28.377 21.3103 28.6749 21.19C28.9728 21.0698 29.2999 21.0407 29.6145 21.1066C29.929 21.1725 30.2167 21.3303 30.4406 21.5598C30.5367 21.2218 30.7148 20.9125 30.9592 20.6591C31.2036 20.4058 31.5068 20.216 31.8421 20.1066C32.1774 19.9972 32.5346 19.9715 32.8823 20.0318C33.2299 20.092 33.5574 20.2363 33.8359 20.4521C34.1143 20.6679 34.3353 20.9484 34.4792 21.2691C34.6232 21.5898 34.6858 21.9407 34.6615 22.2912C34.6372 22.6416 34.5268 22.9807 34.34 23.2786C34.1532 23.5766 33.8957 23.8243 33.5901 24"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
