import { darkViolet55 } from '@coinspect/ui';
import styled from 'styled-components';

export const ForgotPasswordContainer = styled.div`
  .password-form-header {
    padding-bottom: 2em;
    line-height: 2em;
  }
  .message-result {
    color: ${darkViolet55};
    font-size: 16px;
    line-height: 24px;
  }
  .message-default {
    padding-bottom: 20px;
    color: ${darkViolet55};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const PinkLink = styled.div`
  color: #dc7f9b;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  transition: color 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  padding-top: 40px;

  &:hover {
    color: #dc7f9b;
  }
`;

export const ConfirmingContainer = styled.div`
  padding-top: 80px;
  padding-bottom: 100px;
  font-size: 16px;
  font-family: proxima-nova;
  line-height: 24px;
`;
