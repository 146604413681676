import { mobileMaxWidth, tabletMinWidth } from 'src/constants';
import styled from 'styled-components';

export const PeoplePageContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: #121212;
  color: white;
  min-height: 100vh;
  @media only screen and (min-width: ${mobileMaxWidth}px) {
    flex-direction: row;
    min-height: 100vh;
    padding-left: 56px;
  }
`;

export const PeopleContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding-top: 90px;
  @media only screen and (min-width: ${tabletMinWidth}px) {
    padding-top: 80px;
    padding-left: 80px;
    padding-right: 36px;
  }
`;

export const EnergyPeopleHeader = styled.div`
  display: flex;
  width: 100%;
  flex-shrink: 1;
  justify-content: space-between;
`;

export const EnergyPeopleHeaderText = styled.h1`
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  margin-top: 0 !important;
  align-self: center;
  font-family: proxima-nova;
`;

export const PeopleMainContainer = styled.div`
  margin-top: 40px;
`;

export const EnergyPeopleHeaderMenu = styled.div`
  display: flex;
  width: 50%;
`;

export const EnergyPeopleActionGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const StyledButtonContents = styled.span`
  display: flex;
  align-items: center;
`;
