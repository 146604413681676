import React from 'react';

const AppLogoSmall = (props: { id: string; _fill?: string }) => (
  <svg
    width="36"
    viewBox="0 0 95 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3404_5484)">
      <path
        d="M56.4916 27.655L42.4116 11.355H25.3616L21.4016 17.125H39.7716L43.8816 21.885H11.8016L22.4616 6.595H38.7816L34.0516 0.824997H19.4416L0.741577 27.655H56.4916Z"
        fill="#FFF"
      />
      <path
        d="M39.1216 0.824997L53.1916 17.125H70.2416L74.2116 11.355H55.8316L51.7316 6.595H83.8016L73.1516 21.885H56.8316L61.5516 27.655H76.1616L94.8616 0.824997H39.1216Z"
        fill="#FFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_3404_5484">
        <rect
          width="94.12"
          height="26.83"
          fill="white"
          transform="translate(0.741577 0.824997)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default AppLogoSmall;
