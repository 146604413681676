import { black80, greyGeyser45 } from '@coinspect/ui';
import { isValidNumber } from '@coinspect/utils';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';

import { AccountContext } from '../../../../contexts/account-context';
import { StoreContext } from '../../../../store';

const TooltipContainer = styled.div`
  font-size: 16px;
  line-height: 20px;
  padding: 20px;
  background: white;
  box-shadow: 0px 0px 11px -4px ${black80};
  border-radius: 5px;
  width: 240px;

  strong {
    padding-bottom: 5px;
    display: block;
  }

  hr {
    color: ${black80};
    border-top: none;
    border-bottom: 1px solid ${greyGeyser45};
  }
`;

const TooltipIconColor = styled.div`
  display: inline-block;
  background: ${({ color }): string | undefined => color};
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 10px;
`;

const TooltipRow = styled.div`
  color: grey;
  margin: 5px 0;

  div {
    margin: 3px 0;
  }
`;

interface PayloadObj {
  deviceUUID: string;
  mean: number;
  time: string;
  y: number;
  x: number;
}

interface TooltipProps {
  xAxisAllLabels?: { [key: string]: string };
  // from recharts
  payload?: [{ payload: PayloadObj }] | [];
  coordinate?: { x?: number; y?: number };
  interval?: number;
}

const INTERVAL_FAKE_TIME = 48; // check if <=
const INTERVAL_FAKE_DATE = 1056; // check if >

export const GraphTooltip: FunctionComponent<TooltipProps> = (props) => {
  const { store } = useContext(StoreContext);
  const { preferredTempUnit } = useContext(AccountContext).user;
  const {
    devicesColor,
    filters: { sensorType },
  } = store.pages.dashboard;
  const { devices } = store.entities;
  const { payload = [], xAxisAllLabels, interval = 0 } = props;

  // check if has payload and devices is not empty
  if (payload.length === 0 || isEmpty(devices.byUUID)) {
    return null;
  }

  const { deviceUUID: id, mean, time: dateTime, y, x } = payload[0].payload;

  if (!y || !id || !isValidNumber(mean)) {
    return null;
  }

  const color = devicesColor[id];
  const deviceName = devices?.byUUID[id]?.name || '';

  let date = moment(dateTime, 'YYYY-M-DTH:mm:ssZ').format('MMMM D, YYYY');
  let time = moment(dateTime, 'YYYY-M-DTH:mm:ssZ').format('h:mm a');

  // when interval is <= 48
  // meaning x-axis is in time
  // use the x-axis as value for the tooltip instead from data
  if (interval <= INTERVAL_FAKE_TIME && xAxisAllLabels) {
    time = xAxisAllLabels[x];
  }

  if (interval > INTERVAL_FAKE_DATE && xAxisAllLabels) {
    date = moment(xAxisAllLabels[x], 'MM/DD/YYYY').format('MMMM D, YYYY');
  }

  const isHumidity = sensorType === 'humidity';
  const valueSuffix = !isHumidity ? `°${preferredTempUnit.toUpperCase()}` : '%';

  return (
    <TooltipContainer>
      <strong>
        <TooltipIconColor color={color} />
        {deviceName}
      </strong>
      <TooltipRow>
        <div>{date}</div>
        <div>{time}</div>
      </TooltipRow>
      <hr />
      <TooltipRow>
        <div>{!isHumidity ? 'Temperature' : 'Humidity'}:</div>
        <div>
          {mean.toFixed(1)}
          {valueSuffix}
        </div>
      </TooltipRow>
      {/* When data point is an alert */}
      {/* {false && (
        <>
          <hr />
          <TooltipRow>
            <div>This is the metadata summary.</div>
          </TooltipRow>
        </>
      )} */}
    </TooltipContainer>
  );
};
