export const truncateDropDownOptions = (
  selectedItems: string[],
  numItemsToDisplay: number,
) => {
  //const displayItems = [];
  //const truncatedItems = [];
  const numItemsToTruncate = selectedItems.length - numItemsToDisplay;

  const displayItems = Array.from({ length: numItemsToDisplay }).map(
    (_, i) => selectedItems[i],
  );
  const truncatedItems = Array.from({ length: numItemsToTruncate }).map(
    (_, i) => selectedItems[i + numItemsToDisplay],
  );

  return [displayItems, truncatedItems];
};
