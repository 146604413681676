import { thermaBlue, thermaH2FontFamily } from '@coinspect/ui';
import { Styles } from 'react-joyride';

export const TourStyles: { styles: Styles } = {
  styles: {
    tooltipTitle: {
      fontFamily: thermaH2FontFamily,
      marginBottom: '10px',
      fontSize: '24px',
    },
    tooltipContent: {
      padding: '0px',
      fontWeight: 'normal',
      fontSize: '18px',
    },
    tooltipContainer: {
      textAlign: 'left',
      padding: '0px',
      fontFamily: thermaH2FontFamily,
    },
    options: {
      zIndex: 10000,
      arrowColor: thermaBlue,
      backgroundColor: thermaBlue,
      primaryColor: '#000',
      textColor: '#FFFFFF',
      width: '425px',
    },
    tooltipFooter: {
      justifyContent: 'flex-start',
    },
    buttonSkip: {
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: '18px',
      fontWeight: 'normal',
    },
  },
};
