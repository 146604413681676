import {
  FormDropdown,
  FormInput,
  GrayGraphite,
  rose25,
  thermaPink,
} from '@coinspect/ui';
import { convertOffset } from '@coinspect/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { timezones } from '../../constants';
import { useTimeFilter } from '../../hooks';
import {
  getReportSchedules,
  REPORT_FORM_BREAKPOINT,
  ReportConfigSchedule,
} from '../../services';
import { IconOutsideField } from '../icon-outside-field';

export const generateTimeDropdown = () => {
  const times: string[] = [];
  for (let i = 0; i < 24; i++) {
    const quarterHours = ['00', '15', '30', '45'];
    for (let j = 0; j < 4; j++) {
      const time = i + ':' + quarterHours[j];
      const normalTime = moment(time, 'HH:mm:').format('hh:mm A');
      times.push(normalTime);
    }
  }
  const timeDropDownValues: { key: number; text: string; value: string }[] = [];
  times.map((time, index) => {
    timeDropDownValues.push({
      key: index,
      text: time,
      value: time,
    });
  });

  return timeDropDownValues;
};

const ScheduleOptionStyled = styled.div<{ selected?: boolean }>`
  display: inline-block;
  padding: 3px 10px;
  margin-right: 15px;
  border-radius: 2px;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 400;

  color: ${({ selected }) => (selected ? thermaPink : GrayGraphite)};
  background-color: ${({ selected }) => (selected ? rose25 : 'inherit')};

  @media only screen and (min-width: ${REPORT_FORM_BREAKPOINT}) {
    flex: inherit;
  }
`;

const ScheduleItem = styled.div`
  flex-basis: 50%;

  @media only screen and (min-width: ${REPORT_FORM_BREAKPOINT}) {
    flex-basis: initial;
  }
`;

const ScheduleContainer = styled.div`
  margin-bottom: 15px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (min-width: ${REPORT_FORM_BREAKPOINT}) {
    flex-wrap: inherit;
  }
`;

export const ReportScheduleSelector: React.FC<{
  selectedSchedule: ReportConfigSchedule;
  className?: string;
}> = (props) => {
  const { setValue } = useFormContext<Record<string, string>>();
  const { selectedSchedule } = props;

  return (
    <ScheduleContainer>
      {/* to hold schedule value */}
      <FormInput className="hidden-imp" name="schedule" />

      {getReportSchedules().map(({ text, value }) => {
        return (
          <ScheduleItem key={value}>
            <ScheduleOptionStyled
              onClick={() => {
                setValue('schedule', value);
                setValue('dateRangeDummy', '');
              }}
              selected={selectedSchedule === value}
            >
              {text}
            </ScheduleOptionStyled>
          </ScheduleItem>
        );
      })}
    </ScheduleContainer>
  );
};

export const checkTimezone = (guessedZone: string) => {
  return timezones.find((zone) => {
    return zone.utc.includes(guessedZone);
  });
};

export const ReportDeliveryOptions: React.FC<{
  selectedSchedule: ReportConfigSchedule;
  selectedDeliveryTime?: string;
  className?: string;
}> = (props) => {
  const { selectedSchedule, selectedDeliveryTime } = props;
  const { calculateInitialDeliveryDate } = useTimeFilter();
  const guessedZone = moment.tz.guess();
  const timezone = moment.tz(guessedZone).format('z');
  const timeOffset = moment
    .tz(guessedZone)
    .format('Z')
    .split(':');
  const offset = convertOffset(timeOffset);
  const [specificZoneAbbr, setSpecificZoneAbbr] = useState('');

  useEffect(() => {
    const res = checkTimezone(guessedZone);
    setSpecificZoneAbbr(res?.abbr ? res.abbr : timezone);
  }, []);

  return (
    <IconOutsideField
      icon="icon time outline"
      iconStyle={{ fontSize: '24px', left: '-50px', top: '0px' }}
      iconPopupProps={{
        content:
          'Select the date to start sending your report as well as the time it should be sent.',
        position: 'right center',
      }}
      key={selectedSchedule}
    >
      <div
        style={{
          display: 'inline-block',
          marginRight: '10px',
          width: 'auto',
        }}
      >
        {calculateInitialDeliveryDate(selectedSchedule).text}
      </div>
      <div
        style={{ display: 'inline-block', marginRight: '10px', width: '110px' }}
      >
        <FormDropdown
          name="deliveryTime"
          options={generateTimeDropdown()}
          selection
          fluid
          required
          value={selectedDeliveryTime}
          defaultValue={generateTimeDropdown()[0].value}
        />
      </div>
      <span style={{ color: '#8C8CA1' }}>
        {specificZoneAbbr} (UTC {offset})
      </span>
    </IconOutsideField>
  );
};
