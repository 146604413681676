import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button, Item } from 'semantic-ui-react';
import styled from 'styled-components';

import { isAppleDevice } from '../../lib';
import { AppleIcon } from '../ui/images/tags/apple-icon';
import { GoogleIcon } from '../ui/images/tags/google-icon';

const IconContainer = styled.span`
  position: relative;
  left: -10px;
  top: ${isAppleDevice() ? '3' : '2'}px;
  vertical-align: middle;
`;

const AppleIconContainer = styled(IconContainer)`
  top: 1px;
`;

const AppleSpan = styled.span`
  display: inline-block;
  top: -2px;
  position: relative;
`;

interface SignInButtonsProps {
  displayText?: 'Log in' | 'Sign up' | string;
  setShowEmailForm?: (showEmailForm: boolean) => void;
  isLoading: boolean;
  handleAppleAuth: () => void;
  handleGoogleAuth: () => void;
  token?: string;
}

export const SignInButtons: FunctionComponent<SignInButtonsProps> = (props) => {
  const {
    displayText,
    isLoading,
    handleAppleAuth,
    handleGoogleAuth,
    token,
  } = props;

  return (
    <Item.Group>
      <Item>
        <Button
          className="signin-with-email"
          fluid
          primary
          as={Link}
          to={token ? `/signup?token=${token}` : '/signin'}
        >
          {displayText} with email
        </Button>
      </Item>
      <Item>
        <Button
          className="signin-with-google"
          icon
          fluid
          secondary
          loading={isLoading}
          onClick={(e) => {
            e.preventDefault();
            return handleGoogleAuth();
          }}
        >
          <IconContainer>
            <GoogleIcon />
          </IconContainer>
          {displayText} with Google
        </Button>
      </Item>
      {isAppleDevice() && (
        <Item>
          <Button
            className="signin-with-appleId"
            icon
            fluid
            secondary
            loading={isLoading}
            onClick={(e) => {
              e.preventDefault();
              return handleAppleAuth();
            }}
          >
            <AppleIconContainer>
              <AppleIcon />
            </AppleIconContainer>
            <AppleSpan>{displayText} with Apple</AppleSpan>
          </Button>
        </Item>
      )}
    </Item.Group>
  );
};
