import Fuse, { FuseOptions } from 'fuse.js';
import { useMemo, useState } from 'react';

export interface ClientSearch<T> {
  keyword: string;
  result: T[];
  resetSearch: () => void;
  search: (keyword: string) => void;
}

export function useSearch<T>(
  data: T[],
  options: FuseOptions<T>,
): ClientSearch<T> {
  const [keyword, setKeyword] = useState('');
  const resetSearch = () => setKeyword('');

  const searcher = useMemo(() => {
    const defaultOptions = { tokenize: true, threshold: 0.2 };
    return new Fuse(data, { ...defaultOptions, ...options });
  }, [data, options]);
  const result: T[] = keyword ? (searcher.search(keyword) as T[]) : data;

  return {
    keyword,
    resetSearch,
    result,
    search: setKeyword,
  };
}
