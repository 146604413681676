export * from './invite-form-mode-toggler';

export type InviteModes = 'sms' | 'email';

export const InviteOptions = [
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
];

export const ROLE_OPTIONS = [
  {
    text: 'Viewer',
    value: 'user',
  },
  {
    text: 'Admin',
    value: 'admin',
  },
];

// eslint-disable-next-line no-undef
export const replaceClass = (elem: Element, from: string, to: string): void => {
  elem.classList.remove(from);
  elem.classList.add(to);
};
