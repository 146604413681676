import { TableDataItem } from '../../components/pages/energy-dashboard-page/dashboard-table/energy-table';
import { EnergyLocationModel } from '../../services';
import { DataStoreAction, initialStoreEntity, NormalEntity } from '../reducer';

export type EnergyLocationsState = {
  allLocations: NormalEntity<EnergyLocationModel>;
  locationsWithAlerts: string[];
  currentEnergyLocations: string[];
  copyEquipmentTable: TableDataItem[];
};

export const initialEnergyLocationsStore = {
  allLocations: initialStoreEntity<EnergyLocationModel>(),
  locationsWithAlerts: [],
  currentEnergyLocations: [],
  copyEquipmentTable: [],
};

export enum EnergyLocationsActions {
  setLocationsWithAlerts = 'setLocationsWithAlerts',
  setCurrentEnergyLocations = 'setCurrentEnergyLocations',
  // have a copy of the equipment table in global store to access easily
  setCopyEquipmentTable = 'setCopyEquipmentTable',
}

export const energyLocationsReducer = (
  state: EnergyLocationsState,
  action: DataStoreAction,
) => {
  switch (action.type) {
    case 'energyLocations:set':
      return {
        ...state,
        allLocations: {
          byUUID: [...new Set(action.data)],
          all: [...new Set(action.data)],
        },
      };

    case EnergyLocationsActions.setLocationsWithAlerts:
      return {
        ...state,
        locationsWithAlerts: [...state.locationsWithAlerts, action.data],
      };
    case EnergyLocationsActions.setCurrentEnergyLocations:
      return {
        ...state,
        currentEnergyLocations: action.data,
      };

    case EnergyLocationsActions.setCopyEquipmentTable:
      return {
        ...state,
        copyEquipmentTable: action.data,
      };
  }
  return state;
};
