import { Step } from 'react-joyride';

import { TourStyles } from '../../style/tour-styles';
import { sharedStepOptions } from '../index';

export const newFeatureFlow = (): Step[] => {
  return [
    {
      ...sharedStepOptions,
      styles: {
        ...TourStyles.styles,
        tooltipTitle: {
          ...TourStyles.styles.tooltipTitle,
          lineHeight: '28.8px',
          fontWeight: 400,
        },
        tooltipContent: {
          ...TourStyles.styles.tooltipContent,
          lineHeight: '21.6px',
        },
        options: {
          ...TourStyles.styles.options,
          overlayColor: 'rgba(0, 0, 0, 0)',
        },
      },
      placement: 'bottom-end',
      target: '.new-report-types',
      title: 'New Report Types',
      showSkipButton: false,
      content:
        'We’ve improved our report creation experience! Now you’ll have more flexibility to create meaningful reports by selecting multiple locations, adjust report delivery times, and add comments for your recipients!',
    },
  ];
};
