import { request } from '../contexts';

export interface ScheduleBrowseParams {
  companyUUID: string;
  locationUUID: string;
}

export type Schedule = {
  uuid: string;
  startTime: string;
  endTime: string;
  state: string;
  entityUUID: string;
  type: string;
  day: number;
  timezone: string;
  companyUUID: string;
  entityType: string;
};

type ScheduleResults = {
  data: Schedule[];
};

export class ScheduleService {
  public static async browseSchedules({
    companyUUID,
    locationUUID,
  }: ScheduleBrowseParams): Promise<ScheduleResults> {
    const { data } = await request.get(`/api/v2/schedule`, {
      params: {
        companyUUID,
        entityUUID: locationUUID,
      },
    });

    return data;
  }

  public static async editSchedule(schedule: Schedule) {
    const { data } = await request.post(`/api/v2/schedule/bulk`, schedule);

    return data;
  }

  public static async deleteSchedule(scheduleData: Schedule) {
    const { data } = await request.delete(
      `/api/v2/schedule/${scheduleData.entityType}/${scheduleData.entityUUID}/${scheduleData.state}`,
    );

    return data;
  }

  public static async getSchedules(scheduleData: Schedule) {
    const { data } = await request.get(
      `/api/v2/schedule/${scheduleData.entityType}/${scheduleData.entityUUID}/${scheduleData.state}`,
    );

    return data;
  }
}
