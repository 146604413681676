import styled from 'styled-components';

export const EnergyReportsMainContent = styled.div`
  margin-top: 60px;
  background: linear-gradient(
      0deg,
      rgba(145, 208, 242, 0.05) 0%,
      rgba(145, 208, 242, 0.05) 100%
    ),
    #121212;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px 24px;
  min-height: 700px;
`;

export const ReportRowContent = styled.div`
  display: flex;
  gap: 40px;
`;

export const ReportRowButtons = styled(ReportRowContent)`
  justify-content: flex-end;
  margin-top: 250px;
`;

export const FORM_WIDTH = '450px';
