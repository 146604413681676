import { ButtonContainer, FormInput } from '@coinspect/ui';
import {
  invalidSensorIdMsg,
  isValidNumber,
  isValidSensorId,
  sensorNameExists,
  toCelsius,
  toFahrenheit,
} from '@coinspect/utils';
import mixpanel from 'mixpanel-browser';
import React, { useContext, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Divider, Form, Grid, Icon } from 'semantic-ui-react';

import { AccountContext, StandardModalContext } from '../../contexts';
import {
  START_TIMER_KEY,
  useAddMoreSensorModal,
  useAlertConfigService,
  useDeviceService,
  useLocationService,
} from '../../hooks';
import { isFormAcceptable, readableEquipmentInfo } from '../../lib';
import { TempUnits } from '../../services';
import { StoreContext } from '../../store';
import {
  generateDefaultAlertFormFieldValues,
  roundNumber,
  validHumidity,
} from '../alert';
import { FormInputContainer } from '../devices/device-form';
import { StyledFormDropdown } from '../forms';
import { ActivateSensorModal } from '../modals/activate-test-sensor-modal';
import { SensorIDInfoModal } from '../modals/sensor-id-modal';
import { Text } from '../text';
import { Tooltip } from '../tooltip';
import { TextButtonAccordion } from '../ui/accordion';
import { InfoSvg } from '../ui/images/svgs/info-svg';
import { equipmentInputLabelStyle } from './equipment-input-label';
import { equipmentInstallation } from './equipment-installation';
import { EquipmentPayloadData } from './equipment-service';
import {
  transformToEquipmentFormData,
  transformToEquipmentPayloadData,
} from './equipment-utils';
import { CheckSerialProps, useEquipmentService } from './use-equipment-service';

export type EquipmentFormData = {
  name: string;
  equipmentTypeInstallation: string;
  setPoint?: string;
  meta: {
    serialNumber: string;
    deviceSerialNumber: string;
    temperatureMinThreshold?: string;
    temperatureMaxThreshold: string;
    humidityMinThreshold?: string;
    humidityMaxThreshold?: string;
    status: 'active' | 'disabled';
  };
  locationUUID: string;
  companyUUID: string;
  serialNumber?: string; // put in the type but remove when submitting
};

export const EquipmentForm: React.FC<{
  locationUUID: string;
  onCancel: () => void;
  onSubmit: () => void;
  equipmentUUID?: string;
}> = (props) => {
  localStorage.removeItem(START_TIMER_KEY);
  const { locationUUID, onCancel, onSubmit, equipmentUUID = '' } = props;

  const { user } = useContext(AccountContext);
  const { store } = useContext(StoreContext);
  const { equipment } = store.entities;
  const { equipmentDevices } = store.relationships;
  const { isQueryInProgress } = store.pages;
  const { startTimer, startTimerOnce } = useDeviceService();
  const { getLocationInfo } = useLocationService();

  const {
    addEquipment,
    updateEquipment,
    getRecommendedValues,
    checkSerialNumber,
  } = useEquipmentService();
  const { hideModal, openModal } = useContext(StandardModalContext);
  const { addAlertConfig } = useAlertConfigService();

  const { showAddMoreSensorModal } = useAddMoreSensorModal();

  const { preferredTempUnit = 'f' } = user || {};

  const isEditingEquipment = !!equipmentUUID;
  // let editingEquipment: EquipmentPayloadData;
  // if (isEditingEquipment) {
  //   editingEquipment = equipment.byUUID[equipmentUUID];
  // }
  const methods = useForm<EquipmentFormData>({
    mode: 'onChange',
    defaultValues: isEditingEquipment
      ? transformToEquipmentFormData(
          equipment.byUUID[equipmentUUID] as EquipmentPayloadData,
          preferredTempUnit,
        )
      : {
          meta: {
            status: 'active',
          },
        },
  });

  let hasHumidityData = false;

  if (isEditingEquipment) {
    const editingFormValues = methods.getValues({ nest: true });

    if (
      editingFormValues.meta.humidityMinThreshold ||
      editingFormValues.meta.humidityMaxThreshold
    ) {
      hasHumidityData = true;
    }
  }

  const submitEquipment = methods.handleSubmit(async (data) => {
    // remove not needed fields before sending as payload
    try {
      if (isEditingEquipment) {
        const firstIndex = 0;
        if (!equipmentDevices[equipmentUUID][firstIndex]) return;
        const isUnique = await checkSerialNumber({
          deviceUUID: equipmentDevices[equipmentUUID][firstIndex],
          serialNumber: data.meta.deviceSerialNumber,
        } as CheckSerialProps);
        if (!isUnique) {
          methods.setError('meta.deviceSerialNumber', 'noDuplicateSensorId');
          return;
        }

        await updateEquipment(
          transformToEquipmentPayloadData(
            data,
            locationUUID,
            user?.company.uuid as string,
            preferredTempUnit,
          ),
          equipmentUUID,
        );
      } else {
        const isUnique = await checkSerialNumber({
          serialNumber: data.meta.deviceSerialNumber,
        } as CheckSerialProps);
        if (!isUnique) {
          methods.setError('meta.deviceSerialNumber', 'noDuplicateSensorId');
          return;
        }

        const [_equipment, equipmentDevice] = await addEquipment(
          transformToEquipmentPayloadData(
            data,
            locationUUID,
            user?.company.uuid as string,
            preferredTempUnit,
          ),
        );

        await addAlertConfig({
          ...generateDefaultAlertFormFieldValues('battery'),
          deviceUUID: equipmentDevice.uuid,
          equipmentUUID: _equipment.uuid,
          methods: [],
          alertType: 'battery',
          recipients: [user?.uuid as string],
        });

        openModal({
          headerIcon: <InfoSvg />,
          header: (
            <>
              Hold the ACT button for 5s to activate <br /> sensor and test your
              connection
            </>
          ),
          content: (
            <>
              {window.addEventListener('beforeunload', () => {
                startTimerOnce(() =>
                  startTimer({
                    deviceUUIDs: [equipmentDevice.uuid],
                    isTestAgain: false,
                  }),
                );
              })}
              <ActivateSensorModal
                serialNumber={equipmentDevice.serialNumber}
              />
            </>
          ),
          primaryButton: {
            content: 'I have pressed ACT button',
            onClick: () => {
              startTimerOnce(() => {
                startTimer({
                  deviceUUIDs: [equipmentDevice.uuid],
                  isTestAgain: false,
                });
              });
              hideModal();
              showAddMoreSensorModal(equipmentDevice.uuid, locationUUID);
            },
          },
          onClose: () => {
            startTimerOnce(() => {
              startTimer({
                deviceUUIDs: [equipmentDevice.uuid],
                isTestAgain: false,
              });
            });
            showAddMoreSensorModal(equipmentDevice.uuid, locationUUID);
          },
        });

        mixpanel.track('Equipment Added', {
          equipment_name: _equipment.name,
          sensor_serial_number: _equipment.meta.deviceSerialNumber,
          sensor_model: (() => {
            if (
              new RegExp(/^(HT65N|LHT65|HT65N|LST25)/).test(
                _equipment.meta.deviceSerialNumber,
              )
            ) {
              return _equipment.meta.deviceSerialNumber.substring(0, 5);
            }
            return '';
          })(),
          location_name: (await getLocationInfo(_equipment.locationUUID)).name,
          qr_code_used: 'False',
          equipment_thresholds_types: (() => {
            const thresholdTypesArr = [];
            if (isValidNumber(_equipment.meta.temperatureMaxThreshold)) {
              thresholdTypesArr.push('Max Temperature');
            }
            if (isValidNumber(_equipment.meta.temperatureMinThreshold)) {
              thresholdTypesArr.push('Min Temperature');
            }
            if (isValidNumber(_equipment.meta.humidityMaxThreshold)) {
              thresholdTypesArr.push('Max Humidity');
            }
            if (isValidNumber(_equipment.meta.humidityMinThreshold)) {
              thresholdTypesArr.push('Min Humidity');
            }

            return thresholdTypesArr;
          })(),
          equipment_thresholds_max_temperature:
            _equipment.meta.temperatureMaxThreshold,
          equipment_thresholds_min_temperature:
            _equipment.meta.temperatureMinThreshold,
          equipment_thresholds_max_humidity:
            _equipment.meta.humidityMaxThreshold,
          equipment_thresholds_min_humidity:
            _equipment.meta.humidityMinThreshold,
          equipment_type: readableEquipmentInfo(_equipment.equipmentType),
          equipment_configuration: readableEquipmentInfo(
            _equipment.installation,
          ),
        });
      }

      onSubmit();
    } finally {
    }
  });

  const [isDefaultTempUnit, setDefaultTempUnit] = useState(true);
  const { setValue } = methods;

  useEffect(() => {
    // if (isEditingEquipment) {
    //   const editingEquipment = equipment.byUUID[equipmentUUID];
    //   // console.log(equipment.byUUID[equipmentUUID]);
    //   // const editingEquipment = console.log();
    //   methods.reset({
    //     ...editingEquipment,
    //   });
    // }
    const hasSetPoint = isValidNumber(methods.watch('setPoint'));
    const hasMinTemperatureThreshold = isValidNumber(
      methods.watch('meta.temperatureMinThreshold'),
    );
    const hasMaxTemperatureThreshold = methods.watch(
      'meta.temperatureMaxThreshold',
    );

    // IF-ELSE will check user's setpoint input to know the equivalent temperature value on edit/add
    // isDefaultTempUnit is just a flag for first load if user's preferredTempUnit is 'c', this will prevent celsius to convert itself
    if (!isDefaultTempUnit && preferredTempUnit === 'c') {
      // CELSIUS CONVERSION: setPoint, maxTempThreshold, minTempThreshold (optional)
      if (hasSetPoint)
        setValue(
          'setPoint',
          toCelsius(Number(methods.getValues('setPoint')))
            .toFixed(2)
            .toString(),
        );
      if (hasMinTemperatureThreshold)
        setValue(
          'meta.temperatureMinThreshold',
          toCelsius(Number(methods.getValues('meta.temperatureMinThreshold')))
            .toFixed(2)
            .toString(),
        );
      if (hasMaxTemperatureThreshold)
        setValue(
          'meta.temperatureMaxThreshold',
          toCelsius(Number(methods.getValues('meta.temperatureMaxThreshold')))
            .toFixed(2)
            .toString(),
        );
    } else if (preferredTempUnit === 'f') {
      // FAHRENHEIT CONVERSION: setPoint, maxTempThreshold, minTempThreshold (optional)
      if (hasSetPoint)
        setValue(
          'setPoint',
          toFahrenheit(Number(methods.getValues('setPoint')), 1).toString(),
        );
      if (hasMinTemperatureThreshold)
        setValue(
          'meta.temperatureMinThreshold',
          toFahrenheit(
            Number(methods.getValues('meta.temperatureMinThreshold')),
            1,
          ).toString(),
        );
      if (hasMaxTemperatureThreshold)
        setValue(
          'meta.temperatureMaxThreshold',
          toFahrenheit(
            Number(methods.getValues('meta.temperatureMaxThreshold')),
            1,
          ).toString(),
        );
    }
    setDefaultTempUnit(false);
  }, [preferredTempUnit]);

  return (
    <div>
      <FormContext {...methods}>
        <Form onSubmit={submitEquipment} size="big" noValidate>
          {/* register status manually since it's not part of the form */}
          <input hidden {...methods.register('meta.status')} />

          {/* EQUIPMENT */}
          <Grid stackable columns={2}>
            {/* Equipment name */}
            <Grid.Column style={{ marginBottom: '-20px' }}>
              <FormInput
                label="Equipment name:"
                name="name"
                type="text"
                required
                customErrors={{
                  required: 'Equipment name is required.',
                  noDuplicateSensorName: sensorNameExists,
                }}
                description="Give your equipment a unique name "
                placeholder="e.g. Kitchen Freezer"
              />
            </Grid.Column>
            {/* Sensor ID */}
            <Grid.Column style={{ marginBottom: '-20px' }}>
              <Form.Field>
                <Tooltip
                  content="Your sensor ID is the 11+ alphanumeric code on your sensor device, which is preceded by SN or Sensor ID."
                  trigger={<label>Sensor ID: *</label>}
                />
                <FormInputContainer>
                  <FormInput
                    required
                    name="meta.deviceSerialNumber"
                    type="text"
                    customErrors={{
                      required: 'Sensor ID is required.',
                      noDuplicateSensorId:
                        'This sensor ID is already associated with a different equipment.',
                      isValidSensorId: invalidSensorIdMsg,
                    }}
                    onChange={(value) => value.toUpperCase()}
                    validation={{
                      isValidSensorId: (val: string) =>
                        !val ? true : isValidSensorId(val),
                    }}
                    description="Input the serial number found on your device"
                    placeholder="ID number"
                  />
                  <SensorIDInfoModal
                    serialNumber={methods.watch('meta.deviceSerialNumber')}
                  />
                </FormInputContainer>
              </Form.Field>
            </Grid.Column>
            {/* Equipment type */}
            <Grid.Column style={{ marginBottom: '-20px' }}>
              <StyledFormDropdown
                name="equipmentTypeInstallation"
                label="Equipment type:"
                placeholder="Select"
                description="Please select the type of equipment this sensor is monitoring."
                options={equipmentInstallation}
                selection
                fluid
                required
              />
              {/* Other equipment type */}
              {methods.watch('equipmentTypeInstallation') === 'other' && (
                <FormInput
                  name="otherDescription"
                  type="text"
                  required
                  placeholder="Other equipment type"
                />
              )}
            </Grid.Column>
            {/* Set point */}
            <Grid.Column width={6} style={{ marginBottom: '-20px' }}>
              <FormInput
                name="setPoint"
                label="Set point:"
                customErrors={{
                  required: `A temperature is required.`,
                }}
                placeholder="Value"
                fluid
                step="0.1"
                customStyle={{ whiteSpace: 'nowrap' }}
                pattern={/(?!^-)[^0-9.]/g}
                onChange={roundNumber}
                min={0}
                showrequired={false}
                defaultValue={undefined}
                description="Enter the set temperature of your unit"
                inputAppendLabel={
                  <strong>&deg;{preferredTempUnit.toUpperCase()}</strong>
                }
                inputAppendLabelStyle={equipmentInputLabelStyle}
              />
            </Grid.Column>
          </Grid>

          {/* Advance Details - keeping this layout for future use */}
          {/* <Grid stackable>
            <Grid.Row>
              <Grid.Column>
                <Divider />
                Advance detail header
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>Brand</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>Model number</Grid.Column>
              <Grid.Column>Serial number</Grid.Column>
            </Grid.Row>
          </Grid> */}

          {/* TEMPERATURE & HUMIDITY */}
          <Grid stackable>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Divider />
                <h2
                  style={{
                    lineHeight: '30px',
                    marginBottom: '2px',
                  }}
                >
                  Set temperature threshold:
                </h2>
                <Text as="p">
                  Recommended values are calculated based on your selected
                  equipment and health code standards. The minimum and maximum
                  threshold takes into account expected temperature fluctuation
                  to ensure that alerts are only sent when equipment needs
                  attention.
                </Text>
              </Grid.Column>
              {/* Use recommendation button */}
              <Grid.Column>
                <div style={{ marginTop: '5px' }}>
                  <Button
                    type="button"
                    compact
                    onClick={() => {
                      const recommendedValues = getRecommendedValues(
                        methods.getValues('equipmentTypeInstallation'),
                        user?.preferredTempUnit as TempUnits,
                      );
                      methods.setValue(
                        'meta.temperatureMaxThreshold',
                        recommendedValues.max,
                      );
                      methods.triggerValidation('meta.temperatureMaxThreshold');
                    }}
                    secondary
                    disabled={
                      !methods.watch('equipmentTypeInstallation') ||
                      methods.watch('equipmentTypeInstallation') === 'other'
                    }
                  >
                    <Icon.Group size="large">
                      <Icon fitted className="icon-tips-updates" />
                    </Icon.Group>
                    <span style={{ marginLeft: '10px', marginRight: '10px' }}>
                      Use recommendation
                    </span>
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
            {/* Row for MIN and MAX Temperature Threshold */}
            <Grid.Row columns={2} style={{ marginTop: '-14px' }}>
              {/* Minimum temperature threshold */}
              <Grid.Column width={7}>
                <FormInput
                  name="meta.temperatureMinThreshold"
                  label="Minimum:"
                  required={false}
                  customErrors={{
                    required: `A temperature is required.`,
                  }}
                  placeholder="Optional min value"
                  fluid
                  step="0.1"
                  customStyle={{ whiteSpace: 'nowrap' }}
                  pattern={/(?!^-)[^0-9.]/g}
                  onChange={roundNumber}
                  min={0}
                  showrequired={false}
                  defaultValue={undefined}
                  inputAppendLabel={
                    <strong>&deg;{preferredTempUnit.toUpperCase()}</strong>
                  }
                  inputAppendLabelStyle={equipmentInputLabelStyle}
                />
              </Grid.Column>
              {/* Maximum temperature threshold */}
              <Grid.Column width={7}>
                <FormInput
                  name="meta.temperatureMaxThreshold"
                  label="Maximum:"
                  required
                  customErrors={{
                    required: `A temperature is required.`,
                  }}
                  placeholder="Max value"
                  fluid
                  step="0.1"
                  pattern={/(?!^-)[^0-9.]/g}
                  customStyle={{ whiteSpace: 'nowrap' }}
                  onChange={roundNumber}
                  min={0}
                  showrequired={false}
                  defaultValue={undefined}
                  inputAppendLabel={
                    <strong>&deg;{preferredTempUnit.toUpperCase()}</strong>
                  }
                  inputAppendLabelStyle={equipmentInputLabelStyle}
                />
              </Grid.Column>
            </Grid.Row>

            {/* Add humidity button */}
            <Grid.Row style={{ marginTop: '-10px', paddingLeft: '10px' }}>
              <TextButtonAccordion
                isActive={hasHumidityData}
                label="Add humidity threshold"
                header={
                  <>
                    <Grid>
                      <Grid.Row columns={1} style={{ marginTop: '-20px' }}>
                        <Grid.Column>
                          <Divider />
                          <h2
                            style={{ lineHeight: '30px', marginBottom: '2px' }}
                          >
                            Set humidity threshold:
                          </h2>
                          <Text as="p">
                            If you would like to also receive humidity alerts,
                            set a minimum and/or maximum humidity threshold.
                            Both of these fields are optional and humidity will
                            still be measured on the dashboard.
                          </Text>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </>
                }
                content={
                  <Grid stackable columns={2}>
                    {/* Minimum humidity threshold */}
                    <Grid.Column width={7}>
                      <FormInput
                        name="meta.humidityMinThreshold"
                        label="Minimum humidity:"
                        customErrors={{
                          required: `A minimum humidity is required.`,
                        }}
                        placeholder="Value"
                        fluid
                        step="0.1"
                        customStyle={{ whiteSpace: 'nowrap' }}
                        pattern={/[^\d.]+/g}
                        onChange={validHumidity}
                        min={0}
                        showrequired={false}
                        defaultValue={undefined}
                        inputAppendLabel={<strong>%</strong>}
                        inputAppendLabelStyle={equipmentInputLabelStyle}
                      />
                    </Grid.Column>
                    {/* Maximum humidity threshold */}
                    <Grid.Column width={7}>
                      <FormInput
                        name="meta.humidityMaxThreshold"
                        label="Maximum humidity:"
                        customErrors={{
                          required: `A maximum humidity is required.`,
                        }}
                        placeholder="Value"
                        fluid
                        step="0.1"
                        customStyle={{ whiteSpace: 'nowrap' }}
                        onChange={validHumidity}
                        pattern={/[^\d.]+/g}
                        min={0}
                        showrequired={false}
                        defaultValue={undefined}
                        inputAppendLabel={<strong>%</strong>}
                        inputAppendLabelStyle={equipmentInputLabelStyle}
                      />
                    </Grid.Column>
                  </Grid>
                }
              />
            </Grid.Row>
          </Grid>

          <ButtonContainer right>
            {/* TODO: replace true with props.onCancel checking */}
            <Button
              secondary
              type="button"
              content="Cancel"
              onClick={onCancel}
              disabled={
                isQueryInProgress['equipment:add'] ||
                methods.formState.isSubmitting
              }
            />

            <Button
              primary
              content="Next"
              type="submit"
              disabled={
                !isFormAcceptable(methods.formState) ||
                isQueryInProgress['equipment:add'] ||
                methods.formState.isSubmitting
              }
              loading={isQueryInProgress['equipment:add']}
            />
          </ButtonContainer>
        </Form>
      </FormContext>
    </div>
  );
};
