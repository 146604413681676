import { darkViolet80, PaddedContent } from '@coinspect/ui';
import React, { FunctionComponent, useContext } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Divider, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { RESOURCE_HUB, tabletMaxWidth } from '../../constants';
import {
  SlidingSidebarContext,
  UserAccountContext,
  UserProfile,
} from '../../contexts';
import { useEditUser } from '../../hooks';
import { ButtonAsLink } from '../buttons';
import { AvatarCircle } from '../users/avatar-circle';
import { UserCard } from '../users/user-card';
import { NavList } from './index';

const MobileAccountCard = styled.div`
  display: flex;
  align-items: center;
  margin: 23px;
  cursor: pointer;

  button {
    font-size: 14px;
  }
`;

const MobileActions = styled.div`
  cursor: pointer;
  color: ${darkViolet80};
  span {
    vertical-align: middle;
  }
  &&& > i {
    font-size: 30px;
    margin-left: -7px;
  }
  padding: 10px 0;
`;

const MobileNavLinksPaddedContent = styled(PaddedContent)`
  flex-grow: 1;
`;

const MobileNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  height: 100%;
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    height: auto;
    overflow: visible;
  }
`;

const MobileNavListWrapper = styled.div`
  margin: 30px 0 50px 0;
`;

const MobileNavNameWrapper = styled.div`
  min-width: 0;
`;

interface MobileNavProps
  extends RouteComponentProps<Record<string, string | undefined>> {
  user: UserProfile;
  userContext: UserAccountContext;
}

export const MobileSliderMenu: FunctionComponent<MobileNavProps> = (
  props: MobileNavProps,
) => {
  const location = useLocation();
  const { user, userContext } = props;
  const { closeSidebar } = useContext(SlidingSidebarContext);
  const { editUser } = useEditUser();

  const logoutClick = () => {
    closeSidebar();
    userContext.logout();
  };

  function handleMyAccountButtonClick() {
    closeSidebar();
    editUser(user, { header: 'Edit my account', isFieldsDisabled: false });
  }

  return (
    <MobileNavContainer>
      <MobileAccountCard onClick={handleMyAccountButtonClick}>
        <AvatarCircle
          margin="0 15px 0 0"
          firstName={user.firstName}
          lastName={user.lastName}
          fontColor="#858D9B"
          bgColor="#F2F3F7"
        />
        <MobileNavNameWrapper>
          <UserCard
            firstName={user.firstName}
            lastName={user.lastName}
            role={user.role}
          />
          <ButtonAsLink>My account</ButtonAsLink>
        </MobileNavNameWrapper>
      </MobileAccountCard>
      <Divider fitted />
      <MobileNavLinksPaddedContent size="0 23px">
        {!location.pathname.includes('registration') && user.signedUpDate && (
          <MobileNavListWrapper onClick={closeSidebar}>
            <NavList />
          </MobileNavListWrapper>
        )}
        <MobileActions onClick={() => window.open(RESOURCE_HUB, '_blank')}>
          <Icon className="icon-support-1" size="large" />
          <span className="name">Support</span>
        </MobileActions>
        {user && user.role === 'account_owner' && (
          <MobileActions
            onClick={() => window.open('mailto:support@glaciergrid.com')}
          >
            <Icon className="icon-account" size="large" />
            <span className="name">Account Management</span>
          </MobileActions>
        )}
        <MobileActions onClick={logoutClick}>
          <Icon className="icon-sign-out" size="large" />
          <span className="name">Sign out</span>
        </MobileActions>
      </MobileNavLinksPaddedContent>
    </MobileNavContainer>
  );
};
