import { grey25, white100 } from '@coinspect/ui';
import { tabletMaxWidth } from 'src/constants';
import styled from 'styled-components';

export const LocationFormContainer = styled.div`
  position: relative;
  background: ${white100};
  box-shadow: 0 0 4px 0 ${grey25};
  border: 1px solid ${grey25};
  border-radius: 5px;
  margin-bottom: 10px;
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    border: none;
  }
`;
