import { ThermaSecondaryAction400, ThermaText300 } from '@coinspect/ui';
import React, { FC, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { AddTextButton } from '../../buttons/add-text-button';

export interface TextButtonAccordionProps {
  label: string;
  isActive?: boolean;
}

const ContentHeader: FC = (props) => <div>{props.children}</div>;

const Content: FC = (props) => <div>{props.children}</div>;

const ContentHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;

const FlexPad = styled.div`
  flex-grow: 1;
`;

const TextButtonAccordionContainer = styled.div`
  padding: 0px 0px;
`;

const CloseIcon = styled(Icon)`
  font-size: 14px !important;
  margin: 0px 0px 0px 17px !important;
  color: ${ThermaText300};
`;

export const TextButtonAccordion = ({
  label,
  isActive = false,
  header,
  content,
}: {
  label: string;
  isActive: boolean;
  header: React.ReactNode;
  content: React.ReactNode;
}) => {
  const [active, setActive] = useState(isActive);

  return (
    <TextButtonAccordionContainer>
      {active ? (
        <>
          <ContentHeaderContainer>
            <ContentHeader>{header}</ContentHeader>
            <FlexPad />
            <CloseIcon
              className="icon-close"
              aria-hidden="true"
              onClick={() => {
                setActive(false);
              }}
            />
          </ContentHeaderContainer>
          <Content>{content}</Content>
        </>
      ) : (
        <AddTextButton
          color={ThermaSecondaryAction400}
          hoverColor={ThermaSecondaryAction400}
          label={label}
          onClick={() => {
            setActive(true);
          }}
        />
      )}
    </TextButtonAccordionContainer>
  );
};
