import { FormInput } from '@coinspect/ui';
import firebase from 'firebase/app';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Form, Header } from 'semantic-ui-react';

import { useAccountContext } from '../../contexts';
import { verify } from '../../contexts/account-context/utils';
import { useUserService } from '../../hooks';

export interface ConfirmPasswordFormFields {
  confirmPassword: string;
  password: string;
  currentPassword: string;
}

export const RenewPasswordForm: React.FC = () => {
  const formContext = useForm<ConfirmPasswordFormFields>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setAuthedUser, authorizeFirebaseIdToken } = useAccountContext();
  const { updatePassword } = useUserService();

  const onSubmit = async () => {
    try {
      const { currentUser } = firebase.auth();
      const { password, currentPassword } = formContext.getValues();

      if (!currentUser || !currentUser.email) throw new Error('noUser');

      setIsSubmitting(true);

      if (!verify(currentUser.email, currentPassword))
        throw new Error('notRecognized');

      if (password === currentPassword)
        throw new Error('sameAsCurrentPassword');

      const user = await updatePassword(currentUser.email, password);
      setAuthedUser(user);
      authorizeFirebaseIdToken();
    } catch (e) {
      setIsSubmitting(false);

      switch (e?.message) {
        case 'notRecognized':
          formContext.setError('currentPassword', `notRecognized`);
          break;
        case 'sameAsCurrentPassword':
          formContext.setError('password', `sameAsCurrentPassword`);
          break;
        default:
          console.error(e);
          break;
      }
    }
  };

  return (
    <>
      <Header
        as="h2"
        className="password-form-header"
        content={'Update your password'}
      />
      <p className="message-default">
        Your organization requires a password change to keep your account
        secure.
      </p>
      <FormContext {...formContext}>
        <Form onSubmit={formContext.handleSubmit(onSubmit)} noValidate>
          <FormInput
            name="currentPassword"
            label="Current password"
            type="password"
            required
            customErrors={{
              notRecognized: 'Invalid Password',
            }}
          />
          <FormInput
            name="password"
            label="New password:"
            type="password"
            required
            customErrors={{
              passwordLength: 'Password must be more than 6 characters.',
              required: 'Password is required.',
              sameAsCurrentPassword:
                'Your new password should not be the same as your current password',
            }}
            validation={{
              passwordLength: (val: string) => val.length > 5,
            }}
          />
          <FormInput
            name="confirmPassword"
            label="Confirm new password:"
            type="password"
            required
            customErrors={{
              match: `Passwords don't match`,
              passwordLength: 'Password must be more than 6 characters.',
              required: 'Please confirm your password.',
            }}
            validation={{
              match: (val) => val === formContext.watch('password'),
              passwordLength: (val: string) => val.length > 5,
            }}
          />
          <Button
            primary
            fluid
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            Save new password
          </Button>
        </Form>
      </FormContext>
    </>
  );
};
