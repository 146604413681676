import moment from 'moment';

import { WEEKDAYS } from '../components/pages/energy-location-details/utils/schedule-utils';
import { WEEKDAY_TYPES_VALUES } from '../components/pages/energy-reports-add/components/report-date-picker';

export type TimePresets = {
  endDate: () => string;
  label: string;
  startDate: () => string;
}[];

export function useTimeFilter() {
  function now(): string {
    return new Date().toISOString();
  }

  function minuteRemover(mins: number) {
    return (): string => {
      const date = new Date();
      date.setMinutes(date.getMinutes() - mins);
      return date.toISOString();
    };
  }

  function dayRemover(days: number) {
    return (): string => {
      const date = new Date();
      date.setDate(date.getDate() - days);
      return date.toISOString();
    };
  }

  function dateNow() {
    const d = Date.now();
    const newDate = new Date(d);
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();
    return `${month}/${day}/${year}`;
  }

  function dailyButtonTimeFrame(): TimePresets {
    return [
      {
        endDate: now,
        label: 'Last 24 hours',
        startDate: dayRemover(1),
      },
      {
        endDate: now,
        label: 'Last 7 days',
        startDate: dayRemover(7),
      },
    ];
  }

  function weeklyButtonTimeFrame(): TimePresets {
    return [
      {
        endDate: now,
        label: 'Last 7 days',
        startDate: dayRemover(7),
      },
      {
        endDate: now,
        label: 'Last 14 days',
        startDate: dayRemover(14),
      },
      {
        endDate: now,
        label: 'Last 30 days',
        startDate: dayRemover(30),
      },
    ];
  }

  function biweeklyButtonTimeFrame(): TimePresets {
    return [
      {
        endDate: now,
        label: 'Last 14 days',
        startDate: dayRemover(14),
      },
      {
        endDate: now,
        label: 'Last 30 days',
        startDate: dayRemover(30),
      },
    ];
  }

  function monthlyButtonTimeFrame(): TimePresets {
    return [
      {
        endDate: now,
        label: 'Last month',
        startDate: dayRemover(30),
      },
      {
        endDate: now,
        label: 'Last 2 months',
        startDate: dayRemover(60),
      },
      {
        endDate: now,
        label: 'Last 6 months',
        startDate: dayRemover(180),
      },
    ];
  }

  function reportTimeFramePresets() {
    return [
      {
        endDate: now,
        label: 'Last 12 hours',
        startDate: minuteRemover(720),
      },
      {
        endDate: now,
        label: 'Last 24 hours',
        startDate: dayRemover(1),
      },
      {
        endDate: now,
        label: 'Last 7 days',
        startDate: dayRemover(7),
      },
      {
        endDate: now,
        label: 'Last 30 days',
        startDate: dayRemover(30),
      },
      {
        endDate: now,
        label: 'Last 6 months',
        startDate: dayRemover(180),
      },
      {
        endDate: now,
        label: 'Last year',
        startDate: (): string => {
          const date = new Date();
          date.setFullYear(date.getFullYear() - 1);
          return date.toISOString();
        },
      },
    ];
  }

  function getDate(date: moment.Moment) {
    return `${date.format('dddd')} ${date.month()}/${date.date()}`;
  }

  function calculateInitialDeliveryDate(
    schedule: string,
    weekDay?: WEEKDAY_TYPES_VALUES,
  ) {
    // if has weekDay. calculate for next weekday
    if (weekDay) {
      const dayINeed = WEEKDAYS.indexOf(weekDay) + 1;
      const today = moment().isoWeekday();
      let dateObj;

      if (today < dayINeed) {
        // then just give me this week's instance of that day
        dateObj = {
          date: moment()
            .isoWeekday(dayINeed)
            .format('dddd MM/DD'),
        };
      } else {
        // otherwise, give me *next week's* instance of that same day
        dateObj = {
          date: moment()
            .add(1, 'weeks')
            .isoWeekday(dayINeed)
            .format('dddd MM/DD'),
        };
      }
      return dateObj;
    }

    let initialDate = {
      text: '',
      date: getDate(moment()),
    };

    switch (schedule) {
      case 'daily':
        const nextDay = moment().add(1, 'd');
        initialDate = {
          text: 'Every day at',
          date: getDate(nextDay),
        };
        break;
      case 'bi_weekly':
        const nextBiWeek = moment()
          .startOf('isoWeek')
          .add(1, 'week');
        initialDate = {
          text: `Every other Monday at`,
          date: getDate(nextBiWeek),
        };
        break;
      case 'weekly':
        const nextWeek = moment()
          .startOf('isoWeek')
          .add(1, 'week');
        initialDate = {
          text: `Every Monday at`,
          date: getDate(nextWeek),
        };
        break;
      case 'monthly':
        const nextMonth = moment()
          .add(1, 'M')
          .startOf('month');
        initialDate = {
          text: `On the 1st day of each month`,
          date: getDate(nextMonth),
        };
        break;
    }
    return initialDate;
  }

  return {
    minuteRemover,
    dayRemover,
    now,
    dateNow,
    reportTimeFramePresets,
    dailyButtonTimeFrame,
    weeklyButtonTimeFrame,
    biweeklyButtonTimeFrame,
    monthlyButtonTimeFrame,
    getDate,
    calculateInitialDeliveryDate,
  };
}
