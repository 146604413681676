import { FormDropdown } from '@coinspect/ui';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { useTeamService } from '../../../hooks';
import { DataStoreState, StoreContext } from '../../../store';

const getTeamOptions = (store: DataStoreState) => {
  return store.entities.teams.all.map((uuid) => {
    const { name: text, uuid: value } = store.entities.teams.byUUID[uuid];
    return {
      text,
      value,
    };
  });
};

export const TeamSelector = () => {
  const { store } = useContext(StoreContext);
  const teamOptions = getTeamOptions(store);
  const [visible, setVisible] = useState(false);
  const { browseTeam } = useTeamService();

  const onSearchChange = debounce(
    (searchKeyword = '') => browseTeam({ searchKeyword }),
    1000,
  );

  useEffect(() => {
    if (visible) {
      browseTeam();
    }
  }, [visible]);

  if (!visible) {
    return (
      <VisibilityToggler onClick={() => setVisible(true)}>
        <FixedIcon name="add circle" size="large" />
        <span>Add user to team(s)</span>
      </VisibilityToggler>
    );
  }

  return (
    <FormDropdown
      placeholder="Select team(s)"
      name="teams"
      options={teamOptions}
      selection
      multiple
      fluid
      search
      onSearchChange={(_, { searchQuery }) => onSearchChange(searchQuery)}
    />
  );
};

const FixedIcon = styled(Icon)`
  position: relative;
  top: -3px;
`;

const VisibilityToggler = styled.span`
  display: inline-flex;
  margin-bottom: 1em;
  align-items: center;
  height: 32px;
  :hover {
    cursor: pointer;
  }
`;
