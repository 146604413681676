import { neutralGray200, neutralGray900 } from '@coinspect/ui';
import React, { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';

import { ModalContext } from '../../contexts';
import { useFeatures } from '../../hooks';
import {
  REPORT_TYPES,
  ReportConfigType,
  TEMPLATE_SELECTION_BREAKPOINT,
} from '../../services';
import { StoreContext } from '../../store';
import { REPORT_ACTIONS } from '../../store/reducers';
import { ReportForm } from '../forms';
import { AdvancedDivider } from '../forms/advanced-divider';
import { ReportTemplateSvg } from '../ui';

interface TemplateProps {
  title: string;
  body: string;
  value: ReportConfigType;
}

const basicTemplates: TemplateProps[] = [
  {
    title: 'Sent alerts log',
    body: 'Export your alerts across locations and teams.',
    value: REPORT_TYPES.SENT_ALERTS,
  },
  {
    title: 'Temperature logbook',
    body: 'Export your temperature logbook across locations and teams.',
    value: REPORT_TYPES.LOGBOOK_TEMPERATURE,
  },
  {
    title: 'Humidity logbook',
    body: 'Export your temperature logbook across locations and teams.',
    value: REPORT_TYPES.LOGBOOK_HUMIDITY,
  },
  {
    title: 'Overrides report',
    body: 'Export overrides submitted across locations, equipment, and people.',
    value: REPORT_TYPES.OVERRIDES_REPORT,
  },
  {
    title: 'Mean kinetic temperature',
    body:
      'Export an MKT report, used primarily in the pharmaceutical industry.',
    value: REPORT_TYPES.MEAN_KINETIC_TEMPERATURE,
  },
];

const TemplateSelect = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  color: ${neutralGray900};
  &:hover {
    cursor: pointer;
    background-color: ${neutralGray200};
  }

  @media only screen and (min-width: ${TEMPLATE_SELECTION_BREAKPOINT}) {
    margin-left: 33px;
    flex: 1;
  }
`;

const TemplateTitle = styled.div`
  font-size: 18px;
`;

const TemplateBody = styled.div`
  font-size: 14px;
`;

const TemplateSelectArea = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  font-weight: 400;
  color: ${neutralGray900};

  & > svg {
    display: none;
  }

  @media only screen and (min-width: ${TEMPLATE_SELECTION_BREAKPOINT}) {
    margin-bottom: 20px;
    & > svg {
      display: block;
    }
  }
`;

const ReportTemplateSelectionArea = styled.div`
  @media only screen and (min-width: ${TEMPLATE_SELECTION_BREAKPOINT}) {
    padding: 7.5px 50px 10px;
  }
`;

interface TemplateSelectionProps {
  templates: TemplateProps[];
  onSelect: (type: ReportConfigType) => void;
  advanced?: boolean;
  className?: string;
}

const TemplateSelection: FunctionComponent<TemplateSelectionProps> = ({
  templates = [],
  onSelect,
  advanced = false,
}) => {
  return (
    <>
      {!!templates.length && (
        <>
          {advanced && <AdvancedDivider content="Advanced" />}
          {templates.map((temp) => (
            <TemplateSelectArea key={temp.value}>
              <ReportTemplateSvg type={temp.value} />
              <TemplateSelect
                className="report-template__select"
                onClick={() => {
                  if (onSelect) {
                    onSelect(temp.value);
                  }
                }}
              >
                <TemplateTitle className="report-template__title">
                  {temp.title}
                </TemplateTitle>
                <TemplateBody className="report-template__body">
                  {temp.body}
                </TemplateBody>
              </TemplateSelect>
            </TemplateSelectArea>
          ))}
        </>
      )}
    </>
  );
};

export const ReportTemplateSelection: FunctionComponent = () => {
  const { hideModal, openModal } = useContext(ModalContext);
  const { dispatch } = useContext(StoreContext);
  const { isAuditLogEnabled } = useFeatures();
  const advanced: TemplateProps[] = [];

  if (isAuditLogEnabled) {
    advanced.push({
      title: 'Audit Log',
      body:
        'Export all changes made to your account across locations and teams.',
      value: REPORT_TYPES.AUDIT_LOG,
    });
  }

  const openReportsModal = (template: ReportConfigType) => {
    dispatch({
      type: REPORT_ACTIONS.SET_REPORT_FORM_DATA,
      data: {
        type: template,
      },
    });
    hideModal();

    openModal({
      closeIcon: true,
      content: <ReportForm />,
      size: 'small',
      hasModalActions: false,
    });
  };

  return (
    <ReportTemplateSelectionArea className="report-template">
      <TemplateSelection
        className="report-template__basic-selection"
        templates={basicTemplates}
        onSelect={openReportsModal}
      />
      <TemplateSelection
        className="report-template__advanced-selection"
        templates={advanced}
        onSelect={openReportsModal}
        advanced
      />
    </ReportTemplateSelectionArea>
  );
};
