import {
  black12,
  CustomErrors,
  FormError,
  StyleProps,
  thermaH2FontFamily,
} from '@coinspect/ui';
import get from 'lodash/get';
import React, { KeyboardEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Icon, Input, InputProps } from 'semantic-ui-react';
import styled, { createGlobalStyle } from 'styled-components';
import { trim } from 'validator';

interface PasswordFieldProps extends InputProps, CustomErrors, StyleProps {
  label?: string;
  required?: boolean;
  showrequired?: boolean;
  name: string;
  type?: 'text' | 'number' | 'email' | 'password' | 'hidden';
  validation?: { [key: string]: (...args: any[]) => boolean };
  title?: string;
  placeholder?: string;
  defaultValue?: string;
  pattern?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any[]) => any | Promise<any>;
  disabledBg?: boolean;
  hideErors?: boolean;
  description?: string;
}

const LabelOptional = styled.span`
  font-weight: normal;
`;

const LabelDescription = styled.span`
  font-size: 12px;
  font-family: ${thermaH2FontFamily};
  color: rgb(150, 155, 160);
  display: block;
  padding-bottom: 5px;
`;

const FormInputStyle = createGlobalStyle<{ disabledBg?: string }>`
  .disabled-bg input {
    background-color: ${black12} !important;
  }

  .field {
    .ui.error.input.ui.error.input {
      input,
      input:focus {
        background: #fff6f6;
        border-color: #e0b4b4;
        color: #9f3a38;
      }
    }
  }
`;

const FormPasswordStyle = createGlobalStyle`
  .icon-reveal {
    opacity: 0.4 !important;
    width: 50px !important;
  }

  .icon-reveal:hover {
    opacity: 0.8 !important;
  }
`;

export const PasswordField: React.FC<PasswordFieldProps> = (props) => {
  const {
    required = false,
    name,
    type = 'text',
    showrequired = true,
    customErrors,
    validation,
    title,
    placeholder = '',
    label = '',
    defaultValue = '',
    pattern = '',
    customStyle,
    className,
    disabledBg,
    hideErors,
    description = '',
    labeltext = '',
  } = props;

  const { errors, control, setValue } = useFormContext<
    Record<string, string>
  >();

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  let fieldDisplayLabel = label;
  if (required && showrequired && label) {
    fieldDisplayLabel = `${label} `;
  }

  const iconElement = React.createElement(Icon, {
    link: true,
    name: showPassword ? 'eye' : 'eye slash outline',
    onClick: toggleShowPassword,
  });

  const classes = trim(
    `
      ${type === 'hidden' ? 'hidden' : ''} ${className ?? ''}
      ${props.disabled && disabledBg ? 'disabled-bg' : ''}
    `,
  );

  const CHARCODE_LENGTH = 13;
  const IDX = 0;
  const VAL_IDX = 1;

  return (
    <>
      <FormInputStyle />
      {type === 'password' && <FormPasswordStyle />}

      <Form.Field error={!!errors[name]}>
        <label htmlFor={name}>
          {fieldDisplayLabel ?? ''} <LabelOptional>{labeltext}</LabelOptional>
        </label>
        {description && (
          <>
            <LabelDescription>{description}</LabelDescription>
          </>
        )}
        <Controller
          as={Input}
          control={control}
          name={name}
          className={classes}
          placeholder={placeholder}
          defaultValue={defaultValue}
          title={title}
          type={showPassword ? 'text' : 'password'}
          icon={iconElement}
          rules={{
            required,
            validate: validation,
          }}
          onBlur={(e) => {
            setValue(name, e[IDX].target.value.trim());
            if (props?.onBlur) {
              props.onBlur();
            }
          }}
          onChange={(e) => {
            let { value } = e[VAL_IDX];
            if (!props.onChange) {
              return value;
            }

            if (pattern) {
              const trimmedVal = value.toString().trim();
              value = trimmedVal.replace(pattern, '');
            }

            return props.onChange(value);
          }}
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.charCode === CHARCODE_LENGTH) {
              setValue(name, (e.target as HTMLInputElement).value.trim());
            }
          }}
        />
        {!hideErors && (
          <FormError
            error={get(errors, name)}
            customErrors={customErrors}
            customStyle={customStyle}
          />
        )}
      </Form.Field>
    </>
  );
};
