import React from 'react';

export const HorizontalRuler = () => {
  return (
    <hr
      style={{
        width: 'calc(100% + 4px)',
        margin: '0px',
        marginLeft: '-4px',
        border: 'none',
        height: '1px',
        backgroundColor: '#41484d',
      }}
    />
  );
};
