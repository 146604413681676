import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Toggle } from '../../components/toggle';

const FlexCont = styled.div`
  display: flex;
`;

const FlexPad = styled.div`
  flex-grow: 1;
`;

interface TroubleshootingModalHeaderTaglineProps {
  onTroubleshootingTypeChange: (val: string, bool?: boolean) => void;
  defaultSection: string;
}

export const TroubleshootingModalHeaderTagline: FunctionComponent<TroubleshootingModalHeaderTaglineProps> = (
  props,
) => {
  const { onTroubleshootingTypeChange, defaultSection = 'hubs' } = props;
  const [troubleshootingType, setTroubleshootingType] = useState<string>(
    defaultSection,
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onTroubleshootingTypeChange(troubleshootingType, loading);
    if (loading) {
      setLoading(false);
    }
  }, [troubleshootingType]);

  return (
    <FlexCont>
      <FlexPad>Try one or more method to improve your connection.</FlexPad>

      <Toggle
        less
        className="inline"
        name="troubleshootType"
        options={[
          {
            label: 'Hubs',
            value: 'hubs',
          },
          {
            label: 'Sensors',
            value: 'sensors',
          },
        ]}
        value={troubleshootingType}
        onChange={(val) => {
          setTroubleshootingType(val as string);
        }}
      />
    </FlexCont>
  );
};
