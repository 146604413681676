import { darkViolet55 } from '@coinspect/ui';
import React, { FunctionComponent, useContext } from 'react';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { tabletMaxWidthPx } from '../../constants';
import { UserProfile } from '../../contexts';
import { LocationModel, TeamLocationModel } from '../../services';
import { NormalEntity, StoreContext } from '../../store';
import { Team } from '../users/';

const TeamsArea = styled.span`
  width: calc(100% / 5);
  justify-content: space-between;
  max-width: 170px;
  font-weight: 700;

  @media only screen and (max-width: ${tabletMaxWidthPx}) {
    display: inline;
    color: ${darkViolet55};
    width: auto;
  }
`;

const TeamTag = styled.button`
  font-size: 12px;
  font-weight: 400;
`;

const TeamName = styled.div`
  margin-top: 2px;
  font-size: 14px;
  font-weight: bold;
`;

const TeamLocation = styled.div`
  font-size: 12px;
`;

const ViewMoreTag = styled.div`
  cursor: pointer;
  color: #6da4b8;
  margin-top: 10px;
  float: right;
`;

export interface TeamLocationsProps {
  team: Team;
  allLocations: NormalEntity<LocationModel>;
}
export const TeamLocations = ({
  team,
  allLocations,
}: TeamLocationsProps): JSX.Element => (
  <>
    <TeamName className="team-tooltip">{team.name}</TeamName>
    {team.locations.map((location: TeamLocationModel, index) => (
      <TeamLocation key={index} className="team-tooltip">
        {location.locationUUID && allLocations.byUUID[location.locationUUID]
          ? allLocations.byUUID[location.locationUUID].name
          : ''}
      </TeamLocation>
    ))}
  </>
);

export const TeamTagPopup = (args: {
  trigger: JSX.Element | string;
  content: JSX.Element | JSX.Element[];
}): JSX.Element => (
  <Popup
    trigger={
      typeof args.trigger == 'string' ? (
        <TeamTag className="ui compact button team-tooltip">
          {args.trigger}
        </TeamTag>
      ) : (
        args.trigger
      )
    }
    content={args.content}
    on="click"
    position="top right"
    disabled={false}
  />
);

export interface TeamTagsProps {
  teams: Team[];
  user: UserProfile;
  editUserFunc: (user: UserProfile) => void;
}

const maxLines = 9; // max of 10 lines on tooltip
const maxTeams = 1;

export const TeamTags: FunctionComponent<TeamTagsProps> = ({
  teams,
  user,
  editUserFunc,
}) => {
  const { store } = useContext(StoreContext);
  const {
    locations: { allLocations },
  } = store.pages;

  let lineCount = 0;

  const teamsToShowMore =
    teams.length > maxTeams ? (
      teams
        .slice(maxTeams)
        .filter((team, ind) => {
          lineCount += 1 + team.locations.length;
          return lineCount <= maxLines || ind == 0;
        })
        .map((team, index, arr) => (
          <div key={index}>
            <TeamLocations
              team={
                team.locations.length > maxLines - 1
                  ? {
                      ...team,
                      locations: team.locations.slice(0, maxLines - 1),
                    }
                  : team
              }
              allLocations={allLocations}
            />
            {index == arr.length - 1 && lineCount > maxLines && (
              <ViewMoreTag onClick={() => editUserFunc(user)}>
                View more
              </ViewMoreTag>
            )}
          </div>
        ))
    ) : (
      <></>
    );

  return (
    <TeamsArea>
      {(teams.length > maxTeams ? teams.slice(0, maxTeams) : teams).map(
        (team, index) => (
          <TeamTagPopup
            key={index}
            trigger={team.name}
            content={<TeamLocations team={team} allLocations={allLocations} />}
          />
        ),
      )}
      {teams.length > maxTeams && (
        <TeamTagPopup
          trigger={`+${teams.length - 1}`}
          content={teamsToShowMore}
        />
      )}
    </TeamsArea>
  );
};
