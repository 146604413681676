export const CANADA_STATES = [
  {
    text: 'Alberta',
    value: 'AB',
  },
  {
    text: 'British Columbia',
    value: 'BC',
  },
  {
    text: 'Manitoba',
    value: 'MB',
  },
  {
    text: 'New Brunswick',
    value: 'NB',
  },
  {
    text: 'Newfoundland and Labrador',
    value: 'NL',
  },
  {
    text: 'Northwest Territories',
    value: 'NT',
  },
  {
    text: 'Nova Scotia',
    value: 'NS',
  },
  {
    text: 'Nunavut',
    value: 'NU',
  },
  {
    text: 'Ontario',
    value: 'ON',
  },
  {
    text: 'Prince Edward Island',
    value: 'PE',
  },
  {
    text: 'Quebec',
    value: 'QC',
  },
  {
    text: 'Saskatchewan',
    value: 'SK',
  },
  {
    text: 'Yukon Territory',
    value: 'YT',
  },
];
