import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { DataStoreAction } from '..';
import { ConnectionTestStatusModal } from '../../components/modals';
import { TestingResult } from '../../components/modals/connection-test-status-modal/status-badge';
import { SignalTestingStatus } from '../../components/modals/connection-test-status-modal/status-result';

export type RecommendedThreshold = {
  timeframe: number;
  triggerComparator: string;
  triggerValue: number;
  methods: string[];
};

export type ConnectionStatusType = {
  testingResult: TestingResult;
  testingStatus: SignalTestingStatus;
  isTesting: boolean;
  locationUUID: string;
  deviceUUID: string;
};

export type ConnectionStatusDeviceType = {
  [deviceUUID: string]: ConnectionStatusType;
};

export type ConnectionStatusObjType = {
  [locationUUID: string]: ConnectionStatusDeviceType;
};

export interface SensorPageState {
  activeForms: number;
  latestHumidity?: Record<string, unknown>;
  isReorderInProgress: boolean;
  connectionStatus: ConnectionStatusObjType;
  connectionTestStartDate: Date | undefined;
}

export enum SensorPageActions {
  setReorderInProgress = 'sensorPage:dnd:setReorderInProgress',
  activeFormAdd = 'sensorPage:activeForm:add',
  activeFormRemove = 'sensorPage:activeForm:remove',
  activeFormReset = 'sensorPage:activeForm:reset',
  activeFormSet = 'sensorPage:lastestHumidity:set',
  connectionStatusSet = 'sensorPage:connectionStatus:set',
  connectionStatusTesting = 'sensorPage:connectionStatus:testing',
  connectionStatusResult = 'sensorPage:connectionStatus:result',
  connectionStatusClearLocation = 'sensorsPage:connectionStatus:clearLocation',
  openConnectionStatusModal = 'openConnectionStatusModal',
}

export const sensorPageInitialState = {
  activeForms: 0,
  latestHumidity: {},
  isReorderInProgress: false,
  connectionStatus: {},
  connectionTestStartDate: undefined,
};

export function sensorPageReducer(
  state: SensorPageState,
  action: DataStoreAction,
): SensorPageState {
  switch (action.type) {
    case SensorPageActions.setReorderInProgress: {
      return {
        ...state,
        isReorderInProgress: action.data,
      };
    }
    case SensorPageActions.activeFormAdd: {
      return {
        ...state,
        activeForms: state.activeForms + 1,
      };
    }
    case SensorPageActions.activeFormRemove: {
      return {
        ...state,
        activeForms: state.activeForms - 1,
      };
    }
    case SensorPageActions.activeFormReset: {
      return {
        ...state,
        activeForms: 0,
      };
    }
    case SensorPageActions.activeFormSet: {
      return {
        ...state,
        latestHumidity: {
          ...state.latestHumidity,
          ...action.data,
        },
      };
    }
    case SensorPageActions.connectionStatusSet: {
      const { locationUUID, deviceUUID } = action.data;

      return {
        ...state,
        connectionStatus: {
          ...state.connectionStatus,
          [locationUUID]: {
            ...state.connectionStatus[locationUUID],
            [deviceUUID]: {
              ...action.data,
            },
          },
        },
        connectionTestStartDate: state.connectionTestStartDate ?? new Date(),
      };
    }
    case SensorPageActions.connectionStatusTesting: {
      const { locationUUID, deviceUUIDs } = action.data;

      let newState = {
        ...state,
      };

      deviceUUIDs.forEach((deviceUUID: string) => {
        newState = {
          ...state,
          connectionStatus: {
            ...state.connectionStatus,
            [locationUUID]: {
              ...state.connectionStatus[locationUUID],
              [deviceUUID]: {
                ...state.connectionStatus[locationUUID][deviceUUID],
                isTesting: action.data.isTesting,
              },
            },
          },
        };
      });

      return newState;
    }
    case SensorPageActions.connectionStatusResult: {
      const { locationUUID, deviceUUID } = action.data;
      return {
        ...state,
        connectionStatus: {
          ...state.connectionStatus,
          [locationUUID]: {
            ...state.connectionStatus[locationUUID],
            [deviceUUID]: {
              ...state.connectionStatus[locationUUID][deviceUUID],
              ...action.data.result,
              testingStatus: action.data.result.testingStatus || 'connecting',
            },
          },
        },
      };
    }
    case SensorPageActions.connectionStatusClearLocation: {
      const cloneConnectionStatus = {
        ...state.connectionStatus,
      };
      delete cloneConnectionStatus[action.data.locationUUID];
      return {
        ...state,
        connectionStatus: {},
        connectionTestStartDate: undefined,
      };
    }

    case SensorPageActions.openConnectionStatusModal: {
      const { openModal, locationUUID } = action.data;

      // do not show connection test status modal if connectionStatus store is empty
      if (
        !isEmpty(state.connectionStatus) &&
        !isEmpty(state.connectionStatus[locationUUID])
      ) {
        openModal({
          header: 'Connection test status',
          disableCloseOnOutsideClick: true,
          removeCloseIcon: true,
          allContent: <ConnectionTestStatusModal locationUUID={locationUUID} />,
          width: '752px',
        });
      }
    }
  }
  return state;
}
