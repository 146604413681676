import { useContext } from 'react';

import { StoreContext } from '../../../src/store';

export function useDevicePage() {
  const { dispatch, store } = useContext(StoreContext);

  function hasActiveForm(): boolean {
    return store.pages.sensors.activeForms > 0;
  }

  function addActiveForm(): void {
    dispatch({
      type: 'sensorPage:activeForm:add',
    });
  }

  function removeActiveForm(): void {
    dispatch({
      type: 'sensorPage:activeForm:remove',
    });
  }

  function resetActiveForms(): void {
    dispatch({
      type: 'sensorPage:activeForm:reset',
    });
  }

  return {
    addActiveForm,
    hasActiveForm,
    removeActiveForm,
    resetActiveForms,
  };
}
