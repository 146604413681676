import { ThermaText300 } from '@coinspect/ui';
import React from 'react';

export type TextProps = {
  children: React.ReactNode;
  className?: string;
  bold?: boolean;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'small' | 'span';
  asNew?:
    | 'headline'
    | 'title'
    | 'title2'
    | 'bodyLarge'
    | 'bodyRegular'
    | 'bodySmall'
    | 'smallCaps'
    | 'numerical'
    | 'numericalSmall'
    | 'button'
    | 'metricLarge'
    | 'metricSmall';
  useNew?: boolean;
  id?: string;
  style?: React.CSSProperties;
};

export const Text: React.FC<TextProps> = ({
  bold = null,
  as = 'p',
  asNew = 'bodyRegular',
  useNew = null,
  ...rest
}) => {
  let text = null;

  const isDefaultBold =
    ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(as) && bold === null;

  const defaultStyle: React.CSSProperties = {
    fontWeight: isDefaultBold ? 'bold' : 'normal',
  };

  if (!useNew) {
    switch (as) {
      case 'h1':
        text = <h1 style={{ ...defaultStyle }} {...rest} />;
        break;

      case 'h2':
        text = <h2 style={{ ...defaultStyle }} {...rest} />;
        break;

      case 'h3':
        text = <h3 style={{ ...defaultStyle }} {...rest} />;
        break;

      case 'h4':
        text = <h4 style={{ ...defaultStyle }} {...rest} />;
        break;

      case 'h5':
        text = <h5 style={{ ...defaultStyle }} {...rest} />;
        break;

      case 'h6':
        text = <h6 style={{ ...defaultStyle }} {...rest} />;
        break;

      case 'span':
        text = <span style={{ ...defaultStyle }} {...rest} />;
        break;

      case 'small':
        text = <small style={{ ...defaultStyle }} {...rest} />;
        break;

      case 'p':
      default:
        text = (
          <p
            style={{
              ...defaultStyle,
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '18.2px',
              color: ThermaText300,
            }}
            {...rest}
          />
        );
    }
  }

  if (useNew) {
    const { style, ...actualRest } = rest;
    const customStyle = {
      ...style,
    };
    switch (asNew) {
      case 'headline':
        text = (
          <h3 style={{ ...customStyle, fontSize: '24px' }} {...actualRest} />
        );
        break;

      case 'title':
        text = (
          <h4 style={{ ...customStyle, fontSize: '22px' }} {...actualRest} />
        );
        break;

      case 'title2':
        text = (
          <h5
            style={{
              ...customStyle,
              fontSize: '18px',
              fontWeight: 'normal',
              fontFamily: "'proxima-nova-medium', 'proxima-nova'",
            }}
            {...actualRest}
          />
        );
        break;

      case 'bodyLarge':
        text = (
          <h6 style={{ ...customStyle, fontSize: '16px' }} {...actualRest} />
        );
        break;

      case 'bodyRegular':
        text = (
          <p style={{ ...customStyle, fontSize: '14px' }} {...actualRest} />
        );
        break;

      case 'bodySmall':
        text = (
          <span style={{ ...customStyle, fontSize: '12px' }} {...actualRest} />
        );
        break;

      case 'smallCaps':
        text = (
          <span style={{ ...customStyle, fontSize: '10px' }} {...actualRest} />
        );
        break;

      case 'numerical':
        text = (
          <span style={{ ...customStyle, fontSize: '14px' }} {...actualRest} />
        );
        break;

      case 'numericalSmall':
        text = (
          <span style={{ ...customStyle, fontSize: '10px' }} {...actualRest} />
        );
        break;

      case 'button':
        text = (
          <span
            style={{
              fontSize: '14px',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              ...customStyle,
            }}
            {...actualRest}
          />
        );
        break;

      case 'metricLarge':
        text = (
          <h1
            style={{
              ...customStyle,
              fontSize: '38px',
              fontFamily: 'proxima-nova',
              fontWeight: 400,
            }}
            {...actualRest}
          />
        );
        break;

      case 'metricSmall':
        text = <h2 style={{ fontSize: '32px' }} {...actualRest} />;
        break;
    }
  }

  return text;
};
