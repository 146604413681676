import { PaginationParams } from '@coinspect/utils';

import { BaseEntity, request } from '../contexts';

export interface CreateTeam {
  members: string[];
  name: string;
  locations: string[];
}

export interface TeamMember {
  uuid: string;
  userUUID: string;
  companyUUID: string;
}

export interface TeamLocation {
  uuid: string;
  locationUUID: string;
  companyUUID: string;
}

export interface TeamBaseModel {
  name: string;
  members: TeamMember[];
  locations: TeamLocation[];
}

export interface TeamLocationModel {
  uuid: string;
  locationUUID: string;
  companyUUID: string;
}

export interface TeamModel extends BaseEntity, TeamBaseModel {}

export class TeamService {
  public static async browse(options?: PaginationParams) {
    const { page, limit, searchKeyword } = (options as PaginationParams) || {};
    const url = '/api/account/team';
    const { data: result } = await request.get(url, {
      params: { page, limit, searchKeyword },
    });
    return result;
  }

  public static async add(payload: CreateTeam) {
    const { data: result } = await request.post('/api/account/team', payload);
    return result.data;
  }

  public static async edit(uuid: string, payload: CreateTeam) {
    const { data: result } = await request.put(
      `/api/account/team/${uuid}`,
      payload,
    );
    return result.data;
  }

  public static async delete(uuid: string) {
    const { data: result } = await request.delete(`/api/account/team/${uuid}`);
    return result.data;
  }

  public static async browseUserTeamLocations() {
    const { data: result } = await request.get('/api/account/team/location');
    return result.data;
  }

  public static async browseTeamByUserUUID(userUUID: string) {
    const { data: result } = await request.get(
      `/api/account/team/user/only/${userUUID}`,
    );
    return result.data;
  }

  public static async browseTeamsWithOnlyThisLocationUUID(
    locationUUID: string,
  ) {
    const { data: result } = await request.get(
      `/api/account/team/location/${locationUUID}/only`,
    );
    return result.data;
  }

  public static async browseTeamByUser(userUUID: string) {
    const { data: result } = await request.get(
      `/api/account/team/user/${userUUID}`,
    );
    return result.data;
  }
}
