import React, { useContext } from 'react';
import { createGlobalStyle } from 'styled-components';

import { StoreContext } from '../../../store';
import { ConnectionStatusDeviceType } from '../../../store/reducers';
import { StatusBadge } from './status-badge';
import { StatusResult } from './status-result';
import { TestAgainText } from './test-again';

const ConnectionTestStatusModalStyle = createGlobalStyle`
  table.connection-test-status {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 10px;
  }

  table.connection-test-status td {
    background: #F1F3F7
  }

  /* sensor name */
  table.connection-test-status td:nth-child(1) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

    padding: 14px 0;
    padding-left: 20px;
    padding-right: 16px;

    width: 170px;
  }

  /* id */
  table.connection-test-status td:nth-child(2) {
    width: 110px;
  }

  /* status */
  table.connection-test-status td:nth-child(3) {
    width: 145px;
    white-space: nowrap;
    padding: 0 16px;
  }

  /* remark */
  table.connection-test-status td:nth-child(4) {
    width: 1%;
  }

  /* test again */
  table.connection-test-status td:nth-child(5) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

    padding-left: 16px;
    padding-right: 20px;

    width: 1%;
    white-space: nowrap
  }
`;

export const ConnectionStatusTable: React.FC<{
  connectionStatusObj: ConnectionStatusDeviceType;
}> = ({ connectionStatusObj }) => {
  const { store } = useContext(StoreContext);
  const { devices } = store.entities;
  const { connectionStatus } = store.pages.sensors;

  return (
    <>
      <ConnectionTestStatusModalStyle />
      <table cellSpacing="0" className="connection-test-status">
        <thead>
          <tr>
            <th>Sensor name</th>
            <th>ID</th>
            <th />
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {Object.keys(connectionStatusObj).map((deviceUUID: string) => {
            const device = devices.byUUID[deviceUUID];
            return (
              <tr key={device.uuid}>
                <td>{device.name}</td>
                <td>{device.serialNumber}</td>
                <td>
                  <StatusResult
                    testingStatus={
                      connectionStatus[device.locationUUID][deviceUUID]
                        .testingStatus
                    }
                  />
                </td>
                <td>
                  <StatusBadge
                    testingResult={
                      connectionStatus[device.locationUUID][deviceUUID]
                        .testingResult
                    }
                  />
                </td>
                <td>
                  <TestAgainText
                    testingStatus={
                      connectionStatus[device.locationUUID][deviceUUID]
                        .testingStatus
                    }
                    disabled={
                      connectionStatus[device.locationUUID][deviceUUID]
                        .isTesting
                    }
                    deviceUUID={deviceUUID}
                    locationUUID={device.locationUUID}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
