import { DataStoreAction } from '../reducer';

export interface AccordionState {
  [key: string]: boolean;
}

export const accordionReducer = (
  state: AccordionState,
  action: DataStoreAction,
) => {
  switch (action.type) {
    case 'accordion:open':
      return {
        ...state,
        [action.data]: true,
      };

    case 'accordion:close':
      return {
        ...state,
        [action.data]: false,
      };
    case 'accordion:closeAll':
      return {};
  }
  return state;
};
