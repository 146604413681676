import React from 'react';
import {
  AccordionContent as SemanticAccordionContent,
  AccordionContentProps,
} from 'semantic-ui-react';

export const AccordionContent: React.FC<AccordionContentProps> = (props) => {
  return (
    <SemanticAccordionContent {...props}>
      {props.active && props.children}
    </SemanticAccordionContent>
  );
};
