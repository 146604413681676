import { FlexPaddedContent, white100 } from '@coinspect/ui';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  mobileMaxWidthPx,
  tabletMinWidthPx,
  w1079,
} from '../../../constants/responsive-constants';
import { AccountContext, ModalContext } from '../../../contexts';
import { useCarousel } from '../../../hooks';
import { StoreContext } from '../../../store';
import { DashboardActions, ONBOARDING_KEY } from '../../../store/reducers';
import { OnboardingSlide, PageDots } from './index';

interface Slide {
  altText?: string;
  content: string | React.ReactNode;
  header?: string;
  imageUrl?: string;
  slideTitle: string;
}

const SLIDES: { [key: string]: Slide } = {
  welcome: {
    slideTitle: 'Welcome to GlacierGrid!',
    altText: 'Let’s take a brief tour around all of our cool features!',
    header: 'Let’s take a brief tour around all of our cool features!',
    content: `This is what your dashboard will look like once you’ve added your sensors.
      All of your data in one place. View real-time data, search historical data, gain
      insights into performance and monitor alerts. Pretty cool right? `,
    imageUrl: 'https://cdn.hellotherma.com/dashboard/images/1_Dashboard.gif',
  },
  dashboard: {
    // sample slide title
    slideTitle: `Welcome to GlacierGrid!`,
    altText: `GlacierGrid dashboard view showing graph of historical temperature data recorded`,
    content: `All your data in one place, instantly searchable.
     View real-time and historical temperature data, filter by date,
     locations and sensors, gain insights into performance, and view past alerts.`,
    header: 'Dashboard',
    // sample gif
    imageUrl: 'https://cdn.hellotherma.com/dashboard/images/1_Dashboard.gif',
  },
  reports: {
    slideTitle: 'Reports',
    altText: `GlacierGrid sensor set up view showing set up location, sensor, and two alerts`,
    content: `Easily create one-time or recurring reports to learn more about your equipment health patterns. `,
    header: 'Love data? We do too!',
    imageUrl: 'https://cdn.hellotherma.com/dashboard/images/2_Reports.gif',
  },
  users: {
    slideTitle: 'Users',
    altText: `GlacierGrid users set up view showing two team members added and name of user`,
    content: `Safety in numbers! Allow others join your dashboard to help monitor equipment and get alerted for failure events. The best part? It’s free to add additional users!`,
    header: 'Invite others to view your data!',
    imageUrl: 'https://cdn.hellotherma.com/dashboard/images/3_Users.gif',
  },
  sensors: {
    slideTitle: 'Sensors',
    altText: `GlacierGrid sensor set up view showing set up location, sensor, and two alerts`,
    content: `Each sensor will be paired with 1 hub (location) and can be configured with customized alerts. You’ll be able to manage your equipment and custom configurations in the Sensors tab.`,
    header: 'Ensure you’re in the know 24/7!',
    imageUrl: 'https://cdn.hellotherma.com/dashboard/images/4_Sensors.gif',
  },
};

const onboardingSlides: Slide[] = [SLIDES.welcome, SLIDES.reports];

const adminSlides: Slide[] = [
  SLIDES.welcome,
  SLIDES.reports,
  SLIDES.users,
  SLIDES.sensors,
];

const FlexItem = styled.div`
  flex-grow: 1;
`;

const ButtonLink = styled(Link).attrs({
  className: `ui primary button`,
})``;

const CarouselContent = styled.div<{ fill: boolean }>`
  background-color: ${({ fill }) => (fill ? `rgba(129,216,208,1)` : white100)};
  border-radius: 4px;
`;

const PageDotContent = styled.div`
  position: relative;
  top: 5px;
  width: 100%;
  height: 0px;
  top: 15px;
`;

const SlideButton = styled(Button)`
  &&&& {
    position: absolute;
    background: none;
    border: 0;
    color: ${white100};
    font-size: 50px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    &.left--button {
      left: -125px;
    }
    &.right--button {
      right: -135px;
    }
    @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${w1079}px) {
      position: absolute;
      z-index: 1;
      top: 95px;
      line-height: 0.6em;
      font-size: 40px;
      width: 50px;
      height: 50px;
      margin: 0;
      padding: 0;
      &.left--button {
        left: 0;
      }
      &.right--button {
        right: 0;
      }
    }
    @media only screen and (max-width: ${mobileMaxWidthPx}) {
      position: absolute;
      z-index: 1;
      top: 70px;
      line-height: 0.6em;
      font-size: 40px;
      width: 50px;
      height: 50px;
      margin: 0;
      padding: 0;
      color: ${white100};
      transform: none;

      &.left--button {
        left: 30px;
      }
      &.right--button {
        right: 30px;
      }
    }
  }
`;

const IndicatorContainer = styled(FlexPaddedContent)`
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    position: relative;
  }
  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${w1079}px) {
    position: relative;
    top: 0;
  }
  padding: 0;
`;

export function OnboardingCarousel(): JSX.Element {
  const {
    carouselIndex,
    carouselSlides,
    initCarousel,
    isActiveSlide,
    nextSlide,
    previousSlide,
  } = useCarousel();
  const { store, dispatch } = useContext(StoreContext);
  const { showThermaGoals, isRepeatTutorial } = store.pages.dashboard;
  const userContext = useContext(AccountContext);
  const { hideModal } = useContext(ModalContext);
  const [slides, setSlides] = useState<Slide[]>(onboardingSlides);
  const { isAdmin } = userContext;

  const closeOnboardingCarousel = (): void => {
    hideModal();
    dispatch({
      data: false,
      type: 'dashboard:onboarding:set',
    });
  };

  const showNextSlideOrCloseModal = (): void => {
    const isLastSlide = isActiveSlide(slides.length - 1);

    if (!isLastSlide) {
      return nextSlide();
    }

    if (!showThermaGoals && isRepeatTutorial) {
      return dispatch({
        type: 'dashboard:onboarding:quiz:finish',
      });
    }

    hideModal();

    dispatch({
      type: DashboardActions.showQuizScreen,
    });
  };

  useEffect(() => {
    const selectedSlides = isAdmin() ? adminSlides : onboardingSlides;
    setSlides(selectedSlides);
    initCarousel(selectedSlides.length - 1);
    // remove local key for onboarding
    localStorage.removeItem(ONBOARDING_KEY);
  }, []);
  const fill = slides[carouselIndex].header === 'Congrats';
  return (
    <CarouselContent fill={fill}>
      {slides.map((slide, idx) => {
        const { altText, header, imageUrl, content, slideTitle } = slide;

        return (
          <OnboardingSlide
            slideTitle={slideTitle}
            header={header}
            image={imageUrl}
            key={header}
            isVisible={isActiveSlide(idx)}
            content={content}
            altText={altText}
          />
        );
      })}

      <IndicatorContainer size="0px 25px 25px 30px">
        <FlexItem>
          {carouselIndex !== 0 && (
            <SlideButton
              icon
              primary
              size="big"
              className="left--button float-left"
              onClick={previousSlide}
            >
              <Icon
                className="onboarding-slide__previous--icon"
                name="chevron left"
              />
            </SlideButton>
          )}

          <SlideButton
            icon
            primary
            size="big"
            className="right--button float-right"
            onClick={showNextSlideOrCloseModal}
          >
            <Icon
              className="onboarding-slide__next--icon"
              name="chevron right"
            />
          </SlideButton>

          {carouselIndex === carouselSlides && isActiveSlide(5) && (
            <ButtonLink
              to="/users"
              onClick={closeOnboardingCarousel}
              className="float-right"
            >
              Create my team now
            </ButtonLink>
          )}
          {/* TODO: COMMENTING OUT FOR NOW. THIS WILL BE HANDLED BY OTHER TICKETS {carouselIndex === carouselSlides && isActiveSlide(1) && (
            <Button
              primary
              className="float-right"
              onClick={closeOnboardingCarousel}
            >
              Show me the data
            </Button>
          )} */}
        </FlexItem>
        <PageDotContent className="text-center">
          <PageDots
            className="onboarding-slide__carousel--icon"
            numberOfSlides={slides.length}
            currentSlide={carouselIndex}
          />
        </PageDotContent>
      </IndicatorContainer>
    </CarouselContent>
  );
}
