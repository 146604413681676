import { PaddedContent, thermaBodyBg } from '@coinspect/ui';
import { toCelsius } from '@coinspect/utils';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import { FormContext, useForm } from 'react-hook-form';
import { Divider, Icon, List } from 'semantic-ui-react';
import { mobileMaxWidth, tabletMaxWidth } from 'src/constants';
import styled from 'styled-components';

import { StandardModalContext } from '../../contexts';
import { AccountContext } from '../../contexts/account-context';
import {
  AlertNotifConfigPayload,
  AlertType,
  useAddMoreSensorModal,
  useAlertConfigService,
} from '../../hooks';
import useScrollIntoView from '../../hooks/use-scroll-into-view';
import { readableEquipmentInfo } from '../../lib';
import { EquipmentType, TempUnits } from '../../services';
import { StoreContext } from '../../store';
import {
  AlertConfigForm,
  AlertConfigLineItemDraggable,
  AlertListHeading,
  generateDefaultAlertFormFieldValues,
} from '../alert';
import { AlertClickedType } from '../locations/location-device-list';
import { RecommendedAlertsModal } from '../modals';
import { useDevicePage } from '../pages/use-device-page';

const AlertLineItem = styled(List)`
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    &&&&&& {
      padding-left: 30px;
    }
  }
`;

const AlertConfigFormContainer = styled(PaddedContent)`
  @media only screen and (min-width: ${tabletMaxWidth}px) and (max-width: ${tabletMaxWidth}px) {
    padding: 20px 40px 20px 0;
  }
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    padding: 20px 40px 20px 60px;
  }
`;

export interface DeviceAlertConfigListProps {
  deviceAlertConfigs: string[];
  deviceUUID: string;
  droppableProvided: DroppableProvided;
  droppableSnapshot: DroppableStateSnapshot;
  setAddAlertClicked: (uuid: string, value?: AlertType | null) => void;
  addAlertClicked: AlertClickedType;
}

export type SelectedOptionsType = {
  minimum: {
    temp: number;
    selected: boolean;
    triggerComparator: '<';
  };
  maximum: {
    temp: number;
    selected: boolean;
    triggerComparator: '>';
  };
  selected?: string[];
};

export const convertTriggerValue = (
  triggerValue: number,
  tempUnit?: TempUnits,
) => {
  return (tempUnit as string).toLowerCase() === 'c'
    ? +toCelsius(triggerValue).toFixed(2)
    : triggerValue;
};

export const DeviceAlertConfigList: FunctionComponent<DeviceAlertConfigListProps> = (
  props,
) => {
  const { user } = useContext(AccountContext);
  const { openModal } = useContext(StandardModalContext);
  const { showAddMoreSensorModal } = useAddMoreSensorModal();
  const {
    deviceAlertConfigs = [],
    deviceUUID,
    droppableProvided,
    droppableSnapshot,
    addAlertClicked,
    setAddAlertClicked,
  } = props;
  const { store, dispatch } = useContext(StoreContext);
  const { addActiveForm, removeActiveForm } = useDevicePage();
  const { alertConfigs, devices } = store.entities;
  const { addAlertConfig, hasRecommendedAlert } = useAlertConfigService();
  const [isAddingAlert, setIsAddingAlert] = useState<boolean>(false);
  const [hasActiveAlert, setHasActiveAlert] = useState<boolean>(false);
  const [isDefaultRequired, setIsDefaultRequired] = useState<boolean>(false);
  const [isRecommendedAlert, setIsRecommendedAlert] = useState(false);
  const [recommendedOptionsSelected, setRecommendedOptions] = useState<
    SelectedOptionsType
  >({
    maximum: { temp: 0, selected: false, triggerComparator: '>' },
    minimum: { temp: 0, selected: false, triggerComparator: '<' },
    selected: [],
  });
  const isInitialMount = useRef(true);

  const deviceDetails = devices.byUUID[deviceUUID];
  const equipmentWithRecommended =
    deviceDetails.equipment &&
    ['freezer', 'refrigerator'].includes(deviceDetails.equipment.equipmentType);
  const [doneRecommendedAlert, setDoneRecommendedAlert] = useState(
    hasRecommendedAlert(deviceUUID),
  );
  const equipmentType = deviceDetails.equipment?.equipmentType as 'freezer';
  const installation = deviceDetails?.equipment
    ?.installation as 'free_standing';
  const recommendedAlertValues =
    equipmentWithRecommended && installation
      ? store.config.recommended_alerts?.[equipmentType][installation][1]
      : null;
  const { name: deviceName, locationUUID, isSmartAlert } = deviceDetails;
  const formMethods = useForm<AlertNotifConfigPayload>({
    mode: 'onChange',
  });

  const alertConfigFormValue = formMethods.watch();

  function hideAddAlert() {
    setIsAddingAlert(false);
    removeActiveForm();
    setIsRecommendedAlert(false);
  }

  const targetRefToScroll = useRef();
  useScrollIntoView(targetRefToScroll, [isAddingAlert]);

  useEffect(() => {
    if (addAlertClicked[deviceUUID]) {
      // if other or no equipment type
      // or already has recommended alert
      if (
        !installation ||
        !equipmentType ||
        (equipmentType as EquipmentType) === 'other' ||
        doneRecommendedAlert ||
        !recommendedAlertValues ||
        addAlertClicked[deviceUUID] === 'humidity'
      ) {
        showAddAlert({ value: addAlertClicked[deviceUUID] as AlertType });
      } else {
        openModal({
          headerIcon: (
            <Icon fitted className="icon-tips-updates" size="small" />
          ),
          header: 'Recommended alerts',
          headerTagline: `${readableEquipmentInfo(
            equipmentType,
          )} • ${readableEquipmentInfo(installation)}`,
          allContent: (
            <RecommendedAlertsModal
              equipmentType={equipmentType}
              installation={installation}
              handleNormalAlert={() =>
                showAddAlert({
                  value: addAlertClicked[deviceUUID] as AlertType,
                })
              }
              handleRecommendedAlert={(
                selectedOptions: SelectedOptionsType,
              ) => {
                if (recommendedAlertValues) {
                  formMethods.reset({
                    deviceUUID: deviceUUID,
                    field: 'temperature',
                    recipients: [],
                    isSmartAlert,
                    ...recommendedAlertValues,
                    triggerValue: convertTriggerValue(
                      recommendedAlertValues.triggerValue as number,
                      user?.preferredTempUnit,
                    ),
                  });
                  setRecommendedOptions(selectedOptions);
                }
                setIsAddingAlert(true);
                addActiveForm();
                setIsRecommendedAlert(true);
              }}
              selectedOptions={recommendedOptionsSelected}
            />
          ),
        });
      }
    }
    setAddAlertClicked(deviceUUID);
  }, [addAlertClicked[deviceUUID]]);

  useEffect(() => {
    const isRequired = !deviceAlertConfigs.length;

    if (isRequired) {
      formMethods.reset({
        ...generateDefaultAlertFormFieldValues('battery'),
        ...alertConfigFormValue,
        isSmartAlert,
        deviceUUID,
      });

      dispatch({
        data: `location-line-item-${locationUUID}`,
        type: 'accordion:open',
      });
      addActiveForm();
    }

    setIsDefaultRequired(isRequired);
  }, [deviceAlertConfigs.length]);

  useEffect(() => {
    if (!isInitialMount.current) {
      setTimeout(() => {
        const yOffset = -200;
        const element = document.querySelectorAll('form')[0];
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 200);
    }
  }, [isInitialMount.current]);

  // listen to store changes and update accordingly if already has recommended alert
  useEffect(() => {
    setDoneRecommendedAlert(hasRecommendedAlert(deviceUUID));
  }, [store.relationships.deviceAlertConfigs[deviceUUID]]);

  function showAddAlert({ value: type }: { value: AlertType }) {
    formMethods.reset({
      ...generateDefaultAlertFormFieldValues(type),
      isSmartAlert,
      deviceUUID,
    });
    setIsAddingAlert(true);
    addActiveForm();
  }

  async function handleAddAlertConfig(data: AlertNotifConfigPayload) {
    try {
      if (isRecommendedAlert) {
        await addAlertConfig({
          ...data,
          meta: { isRecommendedAlert },
        });
      } else {
        await addAlertConfig({
          ...data,
          ...alertConfigFormValue,
        });
      }

      hideAddAlert();

      if (isDefaultRequired) {
        showAddMoreSensorModal(data.deviceUUID);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      if (!recommendedAlertValues) return;
      formMethods.reset({
        deviceUUID: deviceUUID,
        field: 'temperature',
        recipients: [],
        isSmartAlert,
        ...recommendedAlertValues,
        triggerValue: convertTriggerValue(
          recommendedAlertValues.triggerValue as number,
          user?.preferredTempUnit,
        ),
      });
    }
  }, [user?.preferredTempUnit]);

  return (
    <>
      <Divider fitted />
      <div
        {...droppableProvided.droppableProps}
        style={{
          background: droppableSnapshot.isDraggingOver
            ? thermaBodyBg
            : 'transparent',
        }}
      >
        {deviceAlertConfigs &&
          deviceAlertConfigs.map((alertConfigUUID, idx) => (
            <div key={alertConfigUUID}>
              <AlertConfigLineItemDraggable
                deviceUUID={deviceUUID}
                key={alertConfigUUID}
                alertConfig={alertConfigs.byUUID[alertConfigUUID]}
                index={idx}
              />
            </div>
          ))}
        {droppableProvided.placeholder}
      </div>
      <FormContext {...formMethods}>
        {isDefaultRequired && (
          <>
            <AlertLineItem id="alert-one-container">
              <AlertListHeading
                index={0}
                deviceName={deviceName}
                alertFields={alertConfigFormValue}
                hasActiveAlerts={hasActiveAlert}
              />
              <AlertConfigFormContainer size="0 74px 0 100px">
                <AlertConfigForm
                  onSubmit={handleAddAlertConfig}
                  hasActiveAlerts={setHasActiveAlert}
                  isFirstAlert
                />
              </AlertConfigFormContainer>
            </AlertLineItem>
            <Divider fitted />
          </>
        )}
        {isAddingAlert && deviceAlertConfigs && (
          <div ref={targetRefToScroll}>
            <AlertLineItem id="alert-two-container">
              {!isRecommendedAlert && (
                <AlertListHeading
                  deviceName={deviceName}
                  index={deviceAlertConfigs.length}
                  alertFields={alertConfigFormValue}
                  hasActiveAlerts={hasActiveAlert}
                  preferredTempUnit={user?.preferredTempUnit}
                  showFormCloseBtn={isAddingAlert}
                  onAlertFormCancel={hideAddAlert}
                />
              )}
              {isRecommendedAlert &&
                (recommendedOptionsSelected.selected as string[]).map(
                  (val, idx) => {
                    if (
                      !recommendedOptionsSelected[val as 'minimum'].selected
                    ) {
                      return;
                    }
                    return (
                      <AlertListHeading
                        key={idx}
                        deviceName={deviceName}
                        index={deviceAlertConfigs.length + idx}
                        alertFields={{
                          ...alertConfigFormValue,
                          ...recommendedOptionsSelected[val as 'minimum'],
                          triggerValue: convertTriggerValue(
                            recommendedOptionsSelected[val as 'minimum'].temp,
                            user?.preferredTempUnit,
                          ),
                        }}
                        hasActiveAlerts={hasActiveAlert}
                        preferredTempUnit={user?.preferredTempUnit}
                        showFormCloseBtn={isAddingAlert && idx === 0}
                        onAlertFormCancel={hideAddAlert}
                        isRecommendedAlert={isRecommendedAlert}
                      />
                    );
                  },
                )}
              <AlertConfigFormContainer size="20px 100px 0">
                <AlertConfigForm
                  onSubmit={handleAddAlertConfig}
                  onCancel={hideAddAlert}
                  deviceUUID={deviceUUID}
                  hasActiveAlerts={setHasActiveAlert}
                  isRecommendedAlert={isRecommendedAlert}
                  recommendedOptionsSelected={recommendedOptionsSelected}
                />
              </AlertConfigFormContainer>
            </AlertLineItem>
            <Divider fitted />
          </div>
        )}
      </FormContext>
    </>
  );
};
