import { useEffect, useRef } from 'react';

export function useDidUpdate(fn: () => void, dependencies: unknown[]) {
  const hasMountedRef = useRef(false);

  useEffect(() => {
    if (hasMountedRef.current) {
      return fn();
    }

    hasMountedRef.current = true;
  }, dependencies);
}

export default useDidUpdate;
