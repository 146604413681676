import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';

import { SubmitProps } from '../firebaseui/reset-password';
import { PasswordField } from '../forms/invite-form/password-field';

export interface ConfirmPasswordFormFields {
  confirmPassword: string;
  password: string;
}

interface ResetPasswordFormProps {
  onSubmit: (fields: SubmitProps) => Promise<void>;
  isSubmitting: boolean;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const methods = useForm<ConfirmPasswordFormFields>();
  const oobCode = urlParams.get('oobCode');
  const { isSubmitting } = props;

  if (!oobCode) {
    return null;
  }

  const submitForm = methods.handleSubmit(async (data) => {
    await props.onSubmit({ ...data, oobCode });
  });

  return (
    <FormContext {...methods}>
      <Form className="reset-password-form" onSubmit={submitForm} noValidate>
        <PasswordField
          className="reset-password-form__new-password"
          name="password"
          label="New password:"
          type="password"
          required
          showrequired
          customErrors={{
            passwordLength: 'Password must be more than 6 characters.',
            required: 'Password is required.',
          }}
          validation={{
            passwordLength: (val: string) => val.length > 5,
          }}
        />

        <PasswordField
          className="reset-password-form__new-password--confirm"
          name="confirmPassword"
          label="Confirm new password:"
          type="password"
          required
          customErrors={{
            match: `Passwords don't match`,
            passwordLength: 'Password must be more than 6 characters.',
            required: 'Please confirm your password',
          }}
          validation={{
            match: (val) => val === methods.watch('password'),
            passwordLength: (val: string) => val.length > 5,
          }}
        />

        <Button
          className="reset-password-form__button--submit"
          primary
          fluid
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Save new password
        </Button>
      </Form>
    </FormContext>
  );
};
