import { CheckBoxButton, GhostList, GhostListItem } from '@coinspect/ui';
import React, {
  ChangeEvent,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Placeholder } from 'semantic-ui-react';
import { mobileMaxWidth } from 'src/constants';
import styled, { createGlobalStyle } from 'styled-components';

import config from '../../../config';
import { AccountContext } from '../../../contexts';
import { DeviceModel } from '../../../services';
import { StoreContext } from '../../../store';
import {
  getUpdateStatus,
  updateTempStatusText,
} from '../../../utils/dashboard-filter-rail-sensor';
import { Tooltip } from '../../tooltip';
import { Battery } from '../battery';
import { Humidity, Temperature, ValuePlaceholder } from '../temperature';
const SelectableDeviceStyles = createGlobalStyle`
  .selectable-device--disabled *,
  label.selectable-device--disabled {
    opacity: 0.7;
    pointer-events: none;
  }
`;

export interface SelectableDeviceListProps {
  showSelectAll: boolean;
  totalItems?: number;
  items?: DeviceModel[];
  onUpdate: (checked: boolean, uuids: string[]) => void;
  selections: string[];
}

const DeviceItemStatusGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  padding: 0 10px;
  min-width: 130px;
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 0;
    grid-gap: 3px;
    justify-content: left;
    span {
      padding-right: 15px;
    }
  }
`;

export const SelectableDeviceList: FunctionComponent<SelectableDeviceListProps> = (
  props,
) => {
  const { store } = useContext(StoreContext);
  const { isQueryInProgress, dashboard } = store.pages;
  const { devicesColor, latestSensorData, filters } = dashboard;
  const { showSelectAll, items, onUpdate, selections } = props;
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const { user } = useContext(AccountContext);
  const isMaximumDeviceFilter =
    filters.devices.length >= Number(config.maxDeviceFilter);

  function isAllItemsSelected(
    currentItems: DeviceModel[] | undefined,
    currentSelections: string[],
  ) {
    if (!currentSelections.length || !currentItems) {
      return false;
    }

    return currentItems.every(({ uuid }) => currentSelections.includes(uuid));
  }

  useEffect(() => {
    setAllSelected(isAllItemsSelected(items, selections));
  }, [selections, items]);

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    if (!items) {
      return;
    }
    const { checked } = e.target;
    setAllSelected(checked);
    onUpdate(
      checked,
      items.map((device) => device.uuid),
    );
  };

  function onSelect(e: ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    if (allSelected && !checked) {
      setAllSelected(false);
    }
    onUpdate(checked, [e.target.value]);
  }

  return (
    <>
      <SelectableDeviceStyles />
      <GhostList className="selectable-device-list">
        {showSelectAll && (
          <GhostListItem key="SelectAll">
            <CheckBoxButton
              className="selectable-device-list__button--selectAll"
              value={'all'}
              isActive={allSelected}
              onChange={handleSelectAll}
              content={allSelected ? 'Deselect all' : 'Select all'}
            />
          </GhostListItem>
        )}
        {items &&
          items.map((device) => {
            const temperatureStatusUpdate = getUpdateStatus(
              latestSensorData[device.uuid],
              device.createdAt,
            );
            const ghostListItem = (
              <GhostListItem key={device.uuid}>
                <CheckBoxButton
                  className={`selectable-device__button--select selectable-device--${
                    isMaximumDeviceFilter &&
                    selections.indexOf(device.uuid) === -1
                      ? 'disabled'
                      : device.status
                  }`}
                  color={devicesColor[device.uuid]}
                  value={device.uuid}
                  isActive={allSelected || selections.indexOf(device.uuid) >= 0}
                  onChange={onSelect}
                  contentTooltip={device.name}
                  content={device.name}
                  subtitle={device.serialNumber}
                  updateTemperatureStatus={temperatureStatusUpdate?.updateText}
                  textColor={
                    temperatureStatusUpdate?.passedMin > 1440
                      ? '#C24349'
                      : '#7F8182'
                  }
                >
                  <DeviceItemStatusGroup>
                    {isQueryInProgress['latestSensorData:set'] ||
                    isQueryInProgress['browseGraph:set'] ? (
                      <>
                        <span>
                          <ValuePlaceholder>
                            <Placeholder.Line />
                          </ValuePlaceholder>
                        </span>
                        <span>
                          <ValuePlaceholder>
                            <Placeholder.Line />
                          </ValuePlaceholder>
                        </span>
                      </>
                    ) : (
                      <>
                        {filters.sensorType === 'temperature' ? (
                          <Temperature
                            className="selectable-device__latest--temp-data"
                            temperature={
                              latestSensorData[device.uuid] &&
                              latestSensorData[device.uuid].temperature
                            }
                            tempUnit={user ? user.preferredTempUnit : 'c'}
                          />
                        ) : (
                          <Humidity
                            humidity={
                              latestSensorData[device.uuid] &&
                              latestSensorData[device.uuid].humidity
                            }
                          />
                        )}

                        <Battery
                          className="selectable-device__latest--battery-data"
                          percentage={
                            latestSensorData[device.uuid] &&
                            latestSensorData[device.uuid].battery
                          }
                        />
                      </>
                    )}
                  </DeviceItemStatusGroup>
                </CheckBoxButton>
              </GhostListItem>
            );
            return temperatureStatusUpdate?.toolTipText ? (
              // Show tooltip when hovering the whole block IF sensor has no data for 2-hours
              <Tooltip
                key={device.uuid}
                content={updateTempStatusText.toolTipText}
                trigger={ghostListItem}
              />
            ) : (
              ghostListItem
            );
          })}
      </GhostList>
    </>
  );
};
