import { thermaPink } from '@coinspect/ui';
import React from 'react';
import styled from 'styled-components';

import { isBrowserOld } from '../../lib/detectBrowser';

const browsers = {
  chrome: {
    name: 'Google Chrome',
    url: 'https://www.google.com/intl/en_us/chrome/',
  },
  firefox: {
    name: 'Mozilla Firefox',
    url: 'https://www.mozilla.org/en-US/firefox/new/',
  },
  edge: {
    name: 'Microsoft Edge',
    url: 'https://www.microsoft.com/en-us/edge',
  },
};

const StyledLink = styled.a`
  color: white;
  text-decoration: underline;
`;

export const Link: React.FC<{
  browser: { name: string; url: string };
}> = ({ browser }) => {
  return (
    <StyledLink href={browser.url} target="_blank" rel="noopener noreferrer">
      {browser.name}
    </StyledLink>
  );
};

const BannerContainer = styled.div`
  position: absolute;
  width: 100%;
  background: ${thermaPink};
  z-index: 202;
  text-align: center;
  color: white;
  padding: 5px 0;
`;

export const BrowserBanner: React.FC = () => {
  if (isBrowserOld()) {
    return (
      <BannerContainer>
        This site does not support your current browser. Please upgrade to{' '}
        <Link browser={browsers['chrome']} />,{' '}
        <Link browser={browsers['firefox']} />, or{' '}
        <Link browser={browsers['edge']} /> to access full functionality.
      </BannerContainer>
    );
  }

  return null;
};
