import { white100 } from '@coinspect/ui';
import React from 'react';
import { Step } from 'react-joyride';
import styled from 'styled-components';

import { sharedStepOptions } from '../index';

const BestPracticesLink = styled.a`
  color: ${white100};
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    color: ${white100};
  }
`;

export const primaryFlow = (): Step[] => {
  return [
    {
      ...sharedStepOptions,
      placement: 'bottom',
      target: '.sensors-tab',
      title: 'Manage your sensors',
      content:
        'Start by heading to the sensors tab. You can add, edit, configure alerts here.',
    },
    {
      ...sharedStepOptions,
      placement: 'bottom',
      target: '#sensor-add-location-btn',
      title: 'Add a location',
      content:
        'Location refers to the space your hub will live. GlacierGrid uses LoRaWAN technology so sensors can connect to a hub within a 1,200 ft range!',
    },
    {
      ...sharedStepOptions,
      placement: 'right',
      target: `#location-form-container`,
      title: 'Create your location',
      content:
        'Your location represents the hub that your sensors connect to. Once you name your location, you can add up to 50 sensors to a single location (hub)',
    },
    {
      ...sharedStepOptions,
      placement: 'bottom',
      target: '#location-sensor-1',
      title: 'Place and add your sensors',
      content:
        'Try adding future sensors with our mobile app. Our scan feature makes this step a breeze!',
    },
    {
      ...sharedStepOptions,
      placement: 'right',
      target: '#sensor-container',
      title: 'Save your sensor',
      content:
        'Save your sensor information and you’ll be ready to add an alert.',
    },
    {
      ...sharedStepOptions,
      placement: 'right',
      target: '#alert-one-container',
      title: 'Save your Alert 1',
      content:
        'Alert 1 is a standard alert for all sensors that will notify you when your sensor is offline. Be sure to confirm the recipient is correct.',
    },
    {
      ...sharedStepOptions,
      placement: 'left',
      target: '#add-an-alert-btn',
      title: 'Add an alert',
      content: (
        <>
          Alerts keep you in the know while you’re away. Check out our&nbsp;
          <BestPracticesLink
            href="https://www.glaciergrid.com/resource/best-practices-for-alerts/"
            target="_blank"
            rel="noopener noreferrer"
          >
            best practices guide
          </BestPracticesLink>
          &nbsp;for tips or enable smart alerts to let GlacierGrid do the work.
        </>
      ),
    },
    {
      ...sharedStepOptions,
      placement: 'right',
      target: '#alert-two-container',
      title: 'Configure and save your alert',
      content:
        'Choose your thresholds, who to notify, and how to notify them. Additionally you have the option to schedule alerts if you’d like muted times',
    },
  ];
};
