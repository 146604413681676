import { thermaBlue } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';

export interface SvgProps {
  color?: string;
}

export const AnnouncementSvg: FunctionComponent<SvgProps> = (props) => {
  const { color = thermaBlue } = props;

  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 7.08332V8.91666H19.1667V7.08332H15.5ZM13.6667 13.1425C14.5467 13.7933 15.6925 14.655 16.6 15.3333C16.9667 14.8475 17.3333 14.3525 17.7 13.8667C16.7925 13.1883 15.6467 12.3267 14.7667 11.6667C14.4 12.1617 14.0333 12.6567 13.6667 13.1425ZM17.7 2.13332C17.3333 1.64749 16.9667 1.15249 16.6 0.666656C15.6925 1.34499 14.5467 2.20666 13.6667 2.86666C14.0333 3.35249 14.4 3.84749 14.7667 4.33332C15.6467 3.67332 16.7925 2.82082 17.7 2.13332ZM2.66668 5.24999C1.65834 5.24999 0.833344 6.07499 0.833344 7.08332V8.91666C0.833344 9.92499 1.65834 10.75 2.66668 10.75H3.58334V14.4167H5.41668V10.75H6.33334L10.9167 13.5V2.49999L6.33334 5.24999H2.66668ZM13.2083 7.99999C13.2083 6.78082 12.6767 5.68082 11.8333 4.92916V11.0617C12.6767 10.3192 13.2083 9.21916 13.2083 7.99999Z"
        fill={color}
      />
    </svg>
  );
};
