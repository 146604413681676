/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SearchControlsContainer } from '@coinspect/ui';
import { debounce } from 'lodash';
import inflection from 'lodash-inflection';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button, Loader } from 'semantic-ui-react';

import { useSearch, useTeamService, useUserService } from '../../hooks';
import { CreateTeam, TeamLocationModel, TeamModel } from '../../services';
import { StoreContext } from '../../store';
import { LazyLoad } from '../lazy-load';
import { TeamPageData } from '../pages/user-page';
import { SearchBar } from '../search-bar';
import { EmptyTeams, TeamListItem } from '../teams';
import { TeamForm } from './team-form';
export interface Team {
  locations: TeamLocationModel[];
  locationNames?: string[];
  members: string[];
  name: string;
  uuid: string;
}

interface TeamPageOptions {
  teamPageData: TeamPageData;
  currentTeamTotal: number;
  setTeamPageData: (data: TeamPageData) => void;
  setCurrentTeamTotal: (data: number) => void;
}

interface TeamsPanelProps {
  options: TeamPageOptions;
}

export const TeamsPanel: FunctionComponent<TeamsPanelProps> = (props) => {
  const { options } = props;
  const {
    teamPageData: pageData,
    setTeamPageData,
    currentTeamTotal,
    setCurrentTeamTotal,
  }: TeamPageOptions = options;
  const { store, dispatch } = useContext(StoreContext);
  const {
    addTeam,
    hasActiveForm,
    resetActiveForm,
    toggleActiveForm,
    browseTeam,
  } = useTeamService();
  const [isLoading, setIsLoading] = useState(false);
  const { browseAllUsers } = useUserService();
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const { teams: teamsEntity } = store.entities;
  const { teams: teamsPage } = store.pages;
  const noSearchResults =
    "We couldn't find any teams matching your search criteria.";
  const [isSearching, setIsSearching] = useState(false);

  const [loadingMoreData, setLoadingMoreData] = useState(false);

  const loadMoreTeams = async (isVisible?: boolean) => {
    if (!isVisible) return;
    let { page } = pageData;
    setLoadingMoreData(true);
    const result = await browseTeam({
      ...pageData,
      page: ++page,
    });

    if (!result.data.length) {
      setLoadingMoreData(false);
      setTeamPageData(result.metadata);
      return;
    } else {
      setIsLoading(false);
    }

    setCurrentTeamTotal(currentTeamTotal + result.data.length);
    setLoadingMoreData(false);
    setTeamPageData(result.metadata);
  };

  useEffect(() => {
    if (teamsPage.isDataLoaded) return;
    (async () => {
      setIsLoading(true);
      try {
        await Promise.allSettled([browseAllUsers()]);
        const res = await browseTeam(pageData);
        setTeamPageData(res.metadata);
      } finally {
        dispatch({
          data: true,
          type: 'teamPage:isDataLoaded:set',
        });
        setIsLoading(false);
      }
    })();
  }, []);

  const teams = teamsEntity.all
    .map((teamUUID: string) => teamsEntity.byUUID[teamUUID])
    .sort((a: TeamModel, b: TeamModel) => {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

  const { search, result: filteredTeams } = useSearch<TeamModel>(teams, {
    keys: ['name'],
    // @ts-ignore
    sortFn: (a: { item: Team }, b: { item: Team }) => {
      return a.item.name.toLowerCase().localeCompare(b.item.name.toLowerCase());
    },
  });

  const onCancel = () => {
    setIsAddingTeam(false);
    resetActiveForm();
  };

  const displayEmpty = () => {
    if (filteredTeams.length === 0 && searchKeyword && teams.length !== 0) {
      return <EmptyTeams message={noSearchResults} />;
    }
    if (filteredTeams.length === 0) {
      return <EmptyTeams />;
    }
  };

  const handleSearch = debounce(async (keyword: string) => {
    setSearchKeyword(keyword);
    setIsSearching(true);
    if (!keyword) {
      search(keyword);
      setIsSearching(false);
      return;
    }
    const res = await browseTeam({ searchKeyword: keyword });
    if (res) setIsSearching(false);
    search(keyword);
  }, 1000);

  async function handleSubmit(data: CreateTeam) {
    try {
      await addTeam(data);
      setCurrentTeamTotal(pageData.total + 1);
      setTeamPageData({ ...pageData, total: pageData.total + 1 });
      setIsAddingTeam(false);
    } catch (err) {
      // TODO: put error handler here.
    } finally {
      resetActiveForm();
    }
  }

  // TODO: temporary loader
  if (isLoading) {
    return <Loader active inline="centered" />;
  }

  return (
    <>
      <SearchControlsContainer className="team-panel">
        <SearchBar
          className="team-panel__search-bar"
          placeholder="Search teams"
          onChange={(e) => handleSearch(e)}
          isDisabled={isAddingTeam || hasActiveForm()}
        />
        <Button
          className="team-panel__buttons-add-teams"
          primary
          content="Add a team"
          disabled={isAddingTeam || hasActiveForm()}
          onClick={() => {
            setIsAddingTeam(true);
            toggleActiveForm();
          }}
        />
      </SearchControlsContainer>
      {isSearching && <Loader active inline="centered" />}
      {isAddingTeam && <TeamForm onSubmit={handleSubmit} onCancel={onCancel} />}
      {!isSearching &&
        filteredTeams &&
        filteredTeams.map((team: TeamModel) => {
          return (
            <TeamListItem
              team={team}
              key={team.uuid}
              options={{
                setTeamPageData,
                setCurrentTeamTotal,
                pageData,
                filteredTeams,
              }}
            />
          );
        })}
      {!isAddingTeam && !isSearching && displayEmpty()}
      {loadingMoreData && (
        <div className="loading-more-data">Loading more teams</div>
      )}
      {filteredTeams.length > 0 && pageData.total > -1 && !loadingMoreData && (
        <div className="page-data">{`Displaying ${inflection.pluralize(
          'teams',
          filteredTeams.length,
          true,
        )} out of ${pageData.total}`}</div>
      )}
      {!searchKeyword && filteredTeams.length < pageData.total && !isSearching && (
        <LazyLoad onVisible={loadMoreTeams}>
          <div className="not-visible">load more</div>
        </LazyLoad>
      )}
    </>
  );
};
