import { CancelToken } from 'axios';
import React from 'react';

import { API_VERSION } from '../constants';
import { request } from '../contexts';
import { NormalAlertModel } from '../hooks';
import { BaseEntity, NotifConfigModel } from './index';

export interface AlertConfig {
  deviceUUID: string;
  triggerComparator: string;
  field: 'battery' | 'temperature' | 'humidity' | 'hvac_zone_temperature';
  triggerValue: number;
  timeframe: number;
  schedule?: Record<string, unknown>;
  sleepSchedule?: Record<string, unknown>;
  companyUUID?: string;
  entityType?: string;
  meta?: Record<string, unknown>;
}

interface PartialEditAlertConfig extends Partial<AlertConfig> {
  status?: string;
  schedule?: Record<string, unknown>;
  snoozeEndAt?: string | null;
}

export type EditAlertConfig = Omit<PartialEditAlertConfig, 'deviceUUID'>;

export interface AlertConfigModel extends BaseEntity, AlertConfig {
  notifConfigs?: NotifConfigModel[];
  companyUUID: string;
  equipmentUUID?: string;
}

export interface ReorderAlertConfigPayload {
  alertConfigUUID: string;
  deviceUUID: string;
  listOrder: number;
}

type BrowseParams = {
  entityType?: string;
  entityUUID?: string;
  withBatteryAlert?: boolean;
};

export class AlertService {
  public static async browse(
    params?: BrowseParams,
  ): Promise<AlertConfigModel[]> {
    const {
      data: alertConfig,
    } = await request.get(`/api/${API_VERSION}/alert-config`, { params });
    return alertConfig.data;
  }

  public static async add(payload: AlertConfig) {
    const { data: alertConfig } = await request.post(
      `api/${API_VERSION}/alert-config`,
      payload,
    );
    return alertConfig.data;
  }

  public static async read(uuid: string) {
    const alertConfig = await request.get(
      `/api/${API_VERSION}/alert-config/${uuid}`,
    );
    return alertConfig.data;
  }

  public static async edit(
    payload: EditAlertConfig,
    uuid: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch?: React.Dispatch<any>,
    isEquipmentEnabled?: boolean,
    alertConfigData?: NormalAlertModel,
    companyUUID?: string,
  ) {
    if (dispatch) {
      dispatch({ type: `alertService:edit:${uuid}_REQUEST` });
    }
    const endpoint = isEquipmentEnabled
      ? `/api/${API_VERSION}/alert-config/${uuid}`
      : `/api/alert/alert-config/${uuid}`;
    const updatedPayload = isEquipmentEnabled
      ? {
          ...alertConfigData,
          ...payload,
          isToggleStatus: true,
          alertConfigUUID: uuid,
          companyUUID,
          meta: {
            triggerValue: alertConfigData?.triggerValue as number,
          },
        }
      : payload;
    try {
      const { data: alertConfig } = await request.put(endpoint, updatedPayload);
      return alertConfig.data;
    } finally {
      if (dispatch) {
        dispatch({ type: `alertService:edit:${uuid}_FINALLY` });
      }
    }
  }

  public static async destroy(uuid: string) {
    return request.delete(`/api/${API_VERSION}/alert-config/${uuid}`);
  }

  public static async browseAlertConfigsByDevice(
    deviceUUIDs: string[],
    cancelToken?: CancelToken,
    withBatteryAlert?: boolean,
  ) {
    const { data: alertConfigs } = await request.post(
      `/api/${API_VERSION}/alert-config/by-device-uuid`,
      {
        deviceUUIDs,
        cancelToken,
        withBatteryAlert,
      },
    );

    return alertConfigs.data;
  }

  // no longer implemented on v2 equipment based
  public static async reorderAlertConfig(payload: ReorderAlertConfigPayload) {
    const { data: alertConfig } = await request.put(
      'api/alert/alert-config/re-order',
      payload,
    );
    return alertConfig.data;
  }

  public static async setSnooze(deviceUUID: string, snoozeEndAt: Date | null) {
    const data = await request.patch(
      `/api/${API_VERSION}/alert-config/edit/snooze`,
      { deviceUUID, snoozeEndAt },
    );

    return data.data;
  }

  public static async setEnergySnooze(
    entityUUID: string,
    snoozeEndAt: Date | null,
  ) {
    const data = await request.patch(
      `/api/${API_VERSION}/alert-config/edit/snooze/energy_device/${entityUUID}`,
      { snoozeEndAt },
    );

    return data.data;
  }

  public static async preview(methods: string[]) {
    const data = await request.post(
      `/api/${API_VERSION}/alert-config/preview`,
      { methods },
    );

    return data.data;
  }
}
