import React from 'react';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';

const NewStyledDropdown = styled(Dropdown)`
  width: ${(p) => p.width || '100%'};
  min-height: 50px;

  &:before {
    content: "${(p) => p.label}";
    position: absolute;
    font-size: 12px;
    top: 4px;
    color: #7DD2F6;
  }

  &.ui.dropdown {
    background: #1C2124;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    padding-top: 28px;
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid #41484D;
    min-height: 58px;

    &.error {
      border-bottom: 2px solid #FFABAB;

      &:before {
        color: #FFABAB;
      }
    }

    &.search {
      > input {
        color: #FFF;

        ~ div[data-lastpass-icon-root] {
          display: none;
        }
      }

      ::selection {
        color: #FFF;
      }
    }

    .menu {
      top: 105%;
      background: #20272B;
      min-width: ${(p) => p.width || '100%'};

      .item {
        color: #FFF;
        background: #20272B;
      }

      .item.selected {
        background: #263036;

        &:after {
          content: "\\2713";
          position: absolute;
          right: 16px;
        }
      }

      .message {
        color: #FFF;
      }
    }

    > .icon {
      position: absolute;
      right: 16px;
    }
  }

  &.ui.selection.dropdown {
    height: fit-content;
    border: none;
    background: #1C2124;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    padding-top: 28px;
    border-radius: 4px 4px 0 0;
    border-bottom: 2px solid #41484D;
    flex-wrap: wrap;

    &.error {
      border-bottom: 2px solid #FFABAB;

      &:hover {
        border-bottom: 2px solid #FFABAB;
      }

      .default.text {
        color: rgba(191, 191, 191, 0.87);
      }

      .search {
        color: #FFF;
      }

      &:before {
        color: #FFABAB;
      }
    }

    &:hover {
      border: none;
      border-bottom: 2px solid #7DD2F6;
    }

    .label {
      background: #506169;
      color: #FFF;
    }

    .menu {
      top: 105%;
      background: #20272B;
      min-width: ${(p) => p.width || '100%'};
      border: none;

      .item {
        color: #FFF;
        background: #20272B;
        border: none;
      }

      .item.selected {
        background: #263036;

        &:after {
          content: "";
          position: absolute;
          right: 16px;
        }
      }

      .message {
        color: #FFF;
      }
    }

    .search {
      color: #FFF;

      ::selection {
        color: #FFF;
      }
    }
  }

  &.active.ui.dropdown {
    border-bottom: 2px solid #7DD2F6;
  }

  .dropdown.icon:before {
    display: block;
    color: #FFF;
  }

  &.active > .dropdown.icon:before {
    transform: rotate(180deg);
    margin-top: -4px;
  }
`;

export const EnergyDropdown = (props: DropdownProps) => {
  const { label, text, selection, style = {} } = props;
  const dropdownProps = {
    ...props,
    label: text === label && !selection ? '' : label,
    placeholder: text,
  };
  return <NewStyledDropdown {...dropdownProps} style={style} />;
};
