import {
  stoneBlue30,
  thermaGrey,
  thermaH2FontFamily,
  white100,
} from '@coinspect/ui';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Header, Menu } from 'semantic-ui-react';
import styled from 'styled-components';

import { UserAccountContext, UserProfile } from '../../contexts';
import { AppLogo } from '../base';
import { HeaderUserMenu, NavList } from './';

const NavbarContainer = styled(Menu)`
  && {
    background-color: ${thermaGrey};
    display: block;
    color: ${white100};
    padding: 20px 68px 16px;
    box-shadow: 0 2px 2px 0 ${stoneBlue30};
    transform: translate3d(0, 0, 0);

    .ui.header {
      font-family: ${thermaH2FontFamily};
    }
  }
`;

const NavBarPlaceholder = styled.div`
  position: relative;
  height: 73px;
`;

interface MobileNavProps {
  user: UserProfile;
  userContext: UserAccountContext;
}

const noNavRegExp = new RegExp(['registration', 'two-factor-auth'].join('|'));

const Navbar: React.FC<MobileNavProps> = (props) => {
  const { pathname } = useLocation();
  const { user } = props;

  return (
    <>
      <NavBarPlaceholder />
      <NavbarContainer className="therma-desktop-nav-container" fixed="top">
        <Header>
          <Link to="/">
            <AppLogo width="250" height="100%" heightIE="33px" color="#FFF" />
          </Link>
          <div className="right">
            {!noNavRegExp.test(pathname) && user.signedUpDate && <NavList />}

            {!noNavRegExp.test(pathname) && <HeaderUserMenu />}
          </div>
        </Header>
      </NavbarContainer>
    </>
  );
};

export { Navbar };
