export const getMode = (active?: boolean, state?: string) => {
  if (state === 'on' || state === 'unknown') {
    return 'on';
  }

  if (!active) {
    return 'override';
  }

  if (state === 'off') {
    return 'eco';
  }
  if (state === 'resume') {
    return 'on';
  }
  if (state === 'hold') {
    return 'eco';
  }

  return state ?? '-';
};
