import React, { FunctionComponent } from 'react';

export const EmptyAlertsLog: FunctionComponent = () => {
  return (
    <svg
      width="170"
      height="170"
      viewBox="0 0 170 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.4375 53.125L103.417 80.6579C104.188 81.1297 105.07 81.3906 105.974 81.4146C106.878 81.4385 107.772 81.2246 108.568 80.7943C109.363 80.364 110.032 79.7323 110.506 78.9625C110.981 78.1927 111.245 77.3118 111.272 76.4079C111.905 70.586 110.763 64.7087 107.996 59.5474C105.229 54.3861 100.966 50.182 95.7667 47.4867C91.2842 45.2501 86.439 43.8301 81.4583 43.2933"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.4375 53.125H10.625C9.77186 53.0829 8.94057 52.8407 8.19828 52.418C7.45599 51.9953 6.82351 51.404 6.35192 50.6918C5.88033 49.9796 5.58285 49.1665 5.4835 48.3181C5.38415 47.4697 5.48572 46.6098 5.78001 45.8079C7.93857 40.1494 11.7863 35.2923 16.8007 31.8961C21.8151 28.4999 27.7532 26.7293 33.8088 26.8246C40.3535 27.004 46.7498 28.8155 52.4167 32.0946"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.12 45.5388L114.559 31.5846C115.209 31.2097 115.758 30.6823 116.159 30.0478C116.56 29.4134 116.8 28.6909 116.859 27.9428C116.919 27.1947 116.795 26.4434 116.499 25.7537C116.203 25.064 115.744 24.4567 115.161 23.9842C110.889 20.3587 105.694 17.9928 100.155 17.1509C94.6156 16.3091 88.9516 17.0244 83.7959 19.2171C79.167 21.2948 75.3562 24.8465 72.9584 29.3179"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.8413 27.9012L33.3767 12.1833C32.985 11.4631 32.7908 10.652 32.8139 9.83246C32.8369 9.01291 33.0764 8.21405 33.508 7.51698C33.9397 6.81991 34.548 6.24946 35.2714 5.86352C35.9948 5.47759 36.8074 5.28992 37.6267 5.31957C42.8586 5.63202 48.0485 6.44561 53.125 7.74915C57.9395 9.46439 62.2651 12.3236 65.7298 16.0807C69.1946 19.8379 71.6947 24.3806 73.015 29.3179"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.688 143.438H101.88C99.9835 143.438 98.1218 142.931 96.4877 141.97C94.8535 141.008 93.5065 139.627 92.5863 137.969L74.375 100.938"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.452 143.438L148.764 164.688"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.4042 141.907L85.0142 164.687"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.2163 116.875H116.875"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3125 164.688C6.14125 150.578 5.95 90.865 53.125 53.125"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.4375 53.125C43.7276 88.353 38.2424 126.75 42.5 164.688"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const EmptyGraph: FunctionComponent = () => {
  return (
    <svg
      width="170"
      height="169"
      viewBox="0 0 170 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58875 94.6197V157.732"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.839 147.213C111.839 141.634 109.6 136.283 105.615 132.338C101.63 128.392 96.2246 126.176 90.5887 126.176H64.0262C64.0262 120.596 61.7874 115.245 57.8023 111.3C53.8171 107.355 48.4121 105.138 42.7762 105.138H5.58875V147.213H111.839Z"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.7762 126.176H64.0262"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M85.2762 63.0634C85.2762 63.0634 74.4317 81.4362 58.7137 78.8415C26.8387 73.5822 32.1512 31.5072 21.5262 15.729C31.8178 11.5639 42.959 9.8691 54.0389 10.7832C65.1188 11.6973 75.823 15.1943 85.2762 20.9884C101.214 31.5072 101.214 47.2853 85.2762 63.0634Z"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M120.14 78.4769C120.14 78.4769 127.67 91.2326 138.557 89.4374C160.693 85.7839 156.974 56.5628 164.383 45.6023C157.236 42.7102 149.499 41.5336 141.805 42.1685C134.11 42.8035 126.677 45.2321 120.112 49.2558C109.083 56.5628 109.083 67.5163 120.14 78.4769Z"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.0262 42.0259C64.0262 42.0259 90.5887 47.2853 101.214 99.8791C107.416 87.4246 116.521 76.6085 127.776 68.3228"
        stroke="#6BA4B8"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
