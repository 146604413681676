export * from './secondary-flow';
export * from './primary-flow';
export * from './new-feature-flow';
export * from './alert-config-tutorial-flow';

import { alertConfigTutorialFlow } from '../joyride-steps/alert-config-tutorial-flow';
import { newFeatureFlow } from '../joyride-steps/new-feature-flow/new-feature-flow';
import { primaryFlow } from '../joyride-steps/primary-flow';
import { secondaryFlow } from '../joyride-steps/secondary-flow';
import { JoyrideFlows } from '../store/reducers';
import { TourStyles } from '../style/tour-styles';

export const sharedStepOptions = {
  disableBeacon: true,
  disableCloseOnEsc: true,
  disableScrolling: true,
  hideBackButton: true,
  hideCloseButton: true,
  hideFooter: true,
  showSkipButton: true,
  spotlightClicks: true,
  styles: TourStyles.styles,
};

export const JoyrideFlowFunctions = {
  [JoyrideFlows.PRIMARY_FLOW]: primaryFlow,
  [JoyrideFlows.SECONDARY_FLOW]: secondaryFlow,
  [JoyrideFlows.NEW_FEATURE_FLOW]: newFeatureFlow,
  [JoyrideFlows.ALERT_CONFIG_TUTORIAL_FLOW]: alertConfigTutorialFlow,
};
