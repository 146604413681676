import { FlexPaddedContent, PaddedContent } from '@coinspect/ui';
import mixpanel from 'mixpanel-browser';
import React, {
  PropsWithoutRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Accordion, Button, Divider, Icon, List } from 'semantic-ui-react';
import { mobileMaxWidth, tabletMaxWidth } from 'src/constants';
import styled from 'styled-components';

import { LocationDeviceContext } from '../../contexts/location-device-context';
import {
  useAlertConfigService,
  useDeviceService,
  useDialogs,
  useDiscardChangesModal,
  useFeatures,
  useLocationService,
  useTeamService,
} from '../../hooks';
import useScrollIntoView from '../../hooks/use-scroll-into-view';
import {
  Device,
  DeviceModel,
  Location,
  LocationModel,
  TeamModel,
} from '../../services';
import { StoreContext } from '../../store';
import { AddTextButton } from '../buttons/add-text-button';
import { DeviceForm } from '../devices';
import { EquipmentForm } from '../equipment/equipment-form';
import { FormCloseButton } from '../forms';
import { LineItemHeader } from '../locations/line-item-header';
import { useDevicePage } from '../pages/use-device-page';
import { Tooltip } from '../tooltip';
import { AccordionContent, useAccordion } from '../ui/accordion';
import { LocationForm } from './location-form';
import { LocationFormContainer } from './location-form-container';

const LocationActionButtons = styled.div`
  display: flex;
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    && {
      display: flex;
      width: 100%;
      padding-top: 15px;
      padding-left: 3px;
      justify-content: unset;
      .button {
        margin-right: 0.5em;
        width: 43px;
        height: 43px;
      }
    }
  }
`;

const LocationHeader = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    && {
      width: 100%;
    }
  }
`;

const HeaderContainer = styled(FlexPaddedContent)`
  justify-content: left;
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    && {
      flex-direction: column;
    }
  }
`;

const SensorHeaderContainer = styled(FlexPaddedContent)`
  position: relative;
  justify-content: left;
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    && {
      padding-left: 45px;
    }
  }

  i.chevron.down {
    position: absolute;
    top: 30px;
    left: 30px;
  }
`;

export const SensorFormContainer = styled(PaddedContent)`
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    && {
      padding-left: 45px;
    }
  }
`;

export interface LocationLineItemProps
  extends PropsWithoutRef<Record<string, unknown>> {
  location: LocationModel;
  locationIndex: number;
  onDelete?: (...args: any[]) => void;
  isScrollLocation?: boolean;
  children: React.ReactNode;
}

export const LOC_PANEL_PREFIX = 'location-line-item-';

export const LocationLineItem: React.FC<LocationLineItemProps> = (props) => {
  const { addActiveForm, removeActiveForm, hasActiveForm } = useDevicePage();
  const { initPanel, openPanel, panels } = useAccordion();
  const { store, dispatch } = useContext(StoreContext);
  const { isEquipmentEnabled } = useFeatures();
  const { isAddingDevice, setIsAddingDevice, showAddDevice } = useContext(
    LocationDeviceContext,
  );
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const { editLocation, deleteLocation } = useLocationService();
  const {
    showDiscardChangesModal,
    getInitialDiscardModalBoolean,
    setInitialDiscardModalBoolean,
  } = useDiscardChangesModal();
  const { browseTeamsLocallyOnlyLocationUUID } = useTeamService();
  const { browseDevicesByLocation, addDevice } = useDeviceService();
  const { deleteAlertConfig } = useAlertConfigService();
  const { location, onDelete, locationIndex } = props;
  const { openAlertDialog, openDeleteDialog, closeDialog } = useDialogs();
  const { isQueryInProgress } = store.pages;
  const { alertConfigs } = store.entities;
  const { locationDevices, deviceAlertConfigs } = store.relationships;
  const panelName = `${LOC_PANEL_PREFIX}${location.uuid}`;
  const targetRefToScroll = useRef<HTMLDivElement>(null);

  useScrollIntoView(targetRefToScroll, [isAddingDevice]);

  useEffect(() => {
    (async () => {
      dispatch({
        type: `${panelName}_REQUEST`,
      });
      let devices;
      if (locationDevices.hasOwnProperty(location.uuid)) {
        devices = locationDevices[location.uuid];

        initPanel(panelName, devices.length === 0);
      } else {
        // only fetch devices if no data in store yet
        devices = await browseDevicesByLocation([location.uuid]);
        initPanel(panelName, devices.length === 0);
      }
      openPanel(panelName);
      dispatch({
        type: `${panelName}_FINALLY`,
      });
    })();
  }, []);

  function hideAddDevice() {
    if (showDiscardChanges()) {
      return;
    }
    setIsAddingDevice(false);
    removeActiveForm();
  }

  function hideEditLocation() {
    setIsEditingLocation(false);
    removeActiveForm();
  }

  function showDiscardChanges() {
    const isInitialDiscard = getInitialDiscardModalBoolean();
    if (isInitialDiscard === false) {
      showDiscardChangesModal(location.uuid);
      return true;
    } else {
      setIsAddingDevice(false);
      removeActiveForm();
    }
  }

  async function submitDevice(fields: Device): Promise<DeviceModel> {
    try {
      const device = await addDevice(fields);
      hideAddDevice();
      setInitialDiscardModalBoolean(false);

      return device;
    } catch (e) {
      throw new Error(
        `Failed to create device in location line item. ${
          props.location.uuid
        } ${JSON.stringify(fields)}`,
      );
    }
  }

  async function submitLocationEdit(fields: Location): Promise<void> {
    try {
      await editLocation(props.location.uuid, fields);
      hideEditLocation();
    } catch (e) {
      throw new Error(
        `Failed to edit location in location line item. ${
          props.location.uuid
        } ${JSON.stringify(fields)}`,
      );
      // some future error handler
    }
  }

  async function confirmDelete() {
    const teamLocations: TeamModel[] = browseTeamsLocallyOnlyLocationUUID(
      location.uuid,
    );
    if (teamLocations.length > 0) {
      openAlertDialog({
        content: (
          <>
            This is the only location listed on:
            <ul>
              {teamLocations.map((data, index: number) => {
                return (
                  <li key={'block-location-delete-' + index}>
                    <strong>{data.name}</strong>
                  </li>
                );
              })}
            </ul>
            Please update these teams before removing&nbsp;
            <strong>{location.name}</strong>.
          </>
        ),
        header: 'Location cannot be removed',
      });
      return;
    }
    openDeleteDialog({
      content: (
        <>
          The {location.name}, including all sensor and alert data, will be
          deleted for <strong className="break-word">ALL USERS</strong> at your
          company.
          <p>This action cannot be undone!</p>
          <p>
            <strong className="break-word">
              Are you sure you want to delete the {location.name} location and
              data?
            </strong>
          </p>
        </>
      ),
      onConfirm: async () => {
        await deleteLocation(location.uuid);
        mixpanel.track('Location Deleted', {
          location_name: location.name,
          location_uuid: location.uuid,
        });

        closeDialog();
        onDelete?.();
      },
      disableButtonsOnQuery: 'location:delete',
    });
  }

  function showEditLocation() {
    setIsEditingLocation(true);
    addActiveForm();
  }

  let deviceCount = 0;
  if (locationDevices[location.uuid]) {
    deviceCount = locationDevices[location.uuid].length;
  }

  const removeDuplicateBatteryAlerts = () => {
    if (deviceCount > 0) {
      locationDevices[location.uuid].map((deviceUUID) => {
        return (
          deviceAlertConfigs[deviceUUID] &&
          deviceAlertConfigs[deviceUUID].map(async (uuid) => {
            if (
              uuid &&
              alertConfigs.byUUID[uuid].listOrder > 0 &&
              alertConfigs.byUUID[uuid].field === 'battery' &&
              alertConfigs.byUUID[uuid].deviceUUID
            ) {
              await deleteAlertConfig(
                alertConfigs.byUUID[uuid].deviceUUID,
                uuid,
              );
            }
          })
        );
      });
    }
  };

  useEffect(() => {
    removeDuplicateBatteryAlerts();
  }, [locationDevices[location.uuid]]);

  return (
    <LocationFormContainer>
      <List.Item key={location.uuid}>
        <Accordion.Title>
          <HeaderContainer size="20px 30px">
            <LocationHeader>
              <LineItemHeader
                name={location.name}
                isNew={false}
                count={0}
                subheader={location.address}
              />
              {isEditingLocation && (
                <FormCloseButton
                  onClick={hideEditLocation}
                  style={{ right: '32px' }}
                />
              )}
            </LocationHeader>

            {!isEditingLocation && (
              <LocationActionButtons>
                <AddTextButton
                  onClick={() => showAddDevice(panelName)}
                  label={`Add ${isEquipmentEnabled ? 'equipment' : 'sensor'}`}
                  className="add-sensor"
                  disabled={hasActiveForm()}
                  id={`location-sensor-${locationIndex}`}
                />
                <Tooltip
                  content="Delete"
                  trigger={
                    <Button
                      disabled={
                        hasActiveForm() ||
                        isQueryInProgress['device:browse:byLocation']
                      }
                      circular
                      icon
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDelete();
                      }}
                      className="therma-off-white"
                    >
                      <Icon
                        className="icon-delete"
                        size="large"
                        aria-label="Delete Location"
                      />
                    </Button>
                  }
                />
                <Tooltip
                  content="Edit"
                  trigger={
                    <Button
                      disabled={
                        hasActiveForm() ||
                        isQueryInProgress['device:browse:byLocation']
                      }
                      circular
                      icon
                      onClick={(e) => {
                        e.stopPropagation();
                        showEditLocation();
                      }}
                      className="therma-off-white"
                    >
                      <Icon
                        className="icon-edit"
                        size="large"
                        aria-label="Edit location"
                      />
                    </Button>
                  }
                />
              </LocationActionButtons>
            )}
          </HeaderContainer>
        </Accordion.Title>

        {isEditingLocation && (
          <PaddedContent withIndention size="30px 75px">
            <LocationForm
              location={props.location}
              onSubmit={submitLocationEdit}
              onCancel={hideEditLocation}
            />
          </PaddedContent>
        )}
        <AccordionContent active={panels[panelName]}>
          {props.children}
          {isAddingDevice && (
            <>
              <Divider fitted />
              <SensorHeaderContainer
                withIndention
                size="20px 30px 20px 75px"
                ref={targetRefToScroll}
              >
                <Icon name="chevron down" />
                <LocationHeader>
                  <LineItemHeader
                    name=""
                    count={0}
                    isNew={true}
                    type={isEquipmentEnabled ? 'equipment' : 'sensor'}
                    unit="alert"
                  />
                  <FormCloseButton
                    onClick={hideAddDevice}
                    style={{ right: '32px' }}
                  />
                </LocationHeader>
              </SensorHeaderContainer>
              <SensorFormContainer
                id="sensor-container"
                withIndention
                size="30px 75px 15px 75px"
              >
                {isEquipmentEnabled ? (
                  <EquipmentForm
                    locationUUID={location.uuid}
                    onCancel={showDiscardChanges}
                    onSubmit={() => {
                      setIsAddingDevice(false);
                      removeActiveForm();
                      setInitialDiscardModalBoolean(false);
                    }}
                  />
                ) : (
                  <DeviceForm
                    locationUUID={location.uuid}
                    onSubmit={submitDevice}
                    onCancel={showDiscardChanges}
                  />
                )}
              </SensorFormContainer>
            </>
          )}
        </AccordionContent>
      </List.Item>
    </LocationFormContainer>
  );
};
