import { DataStoreAction } from '../reducer';
import { GraphDataPoint } from './dashboard-page-reducer';

const initDefaultFilters = () => {
  const startDate = new Date();
  // 1 day
  startDate.setDate(startDate.getDate() - 1);
  return {
    end: new Date().toISOString(),
    start: startDate.toISOString(),
  };
};

export interface EquipmentGraphState {
  rangePickerIndex: number;
  filter: {
    start: string;
    end: string;
  };
  graphData: {
    temperature: GraphDataPoint[];
    humidity?: GraphDataPoint[];
    alertSent: GraphDataPoint[];
    ecoMode: number[][];
  };
}

export enum EquipmentGraphActions {
  setFilter = 'setFilter',
  resetFilter = 'resetFilter',
  setGraphData = 'setGraphData',
}

export const initialEquipmentGraphStore: EquipmentGraphState = {
  rangePickerIndex: 1, // 1D
  filter: initDefaultFilters(),
  graphData: {
    temperature: [],
    humidity: [],
    alertSent: [],
    ecoMode: [],
  },
};

export function equipmentGraphReducer(
  state: EquipmentGraphState,
  action: DataStoreAction,
) {
  const { data } = action;

  switch (action.type) {
    case EquipmentGraphActions.resetFilter:
      return {
        ...state,
        ...initialEquipmentGraphStore,
      };
    case EquipmentGraphActions.setFilter:
      return {
        ...state,
        ...data,
      };
    case EquipmentGraphActions.setGraphData:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
}
