import React from 'react';
import styled from 'styled-components';

import { Button } from '../buttons';
import EnergyModal from './energy-modal';

const ModalText = styled.div`
  color: #ffffff;
`;

type DeleteModalProps = {
  /** The name of the entity being acted on. Used in body and button text */
  entityName: string;
  handleClose: () => void;
  handleDelete: () => void;
};

const EnergyDeleteModal: React.FC<DeleteModalProps> = ({
  entityName,
  handleClose,
  handleDelete,
}) => {
  return (
    <EnergyModal title={`Delete ${entityName}?`} handleClose={handleClose}>
      <>
        <ModalText style={{ paddingBottom: '10px' }}>
          Are you sure you want to delete this {entityName}?
        </ModalText>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}
        >
          <Button title="Cancel" onClick={handleClose} as="text" />
          <Button
            title={`Delete ${entityName}`}
            onClick={handleDelete}
            as="text"
            style={{ color: '#FFABAB' }}
          />
        </div>
      </>
    </EnergyModal>
  );
};

export default EnergyDeleteModal;
