import { ButtonContainer, FormInput } from '@coinspect/ui';
import {
  invalidSensorIdMsg,
  isValidSensorId,
  sensorNameExists,
} from '@coinspect/utils';
import { isEmpty } from 'lodash';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { StandardModalContext } from '../../contexts';
import { useDeviceService } from '../../hooks';
import { isFormAcceptable } from '../../lib/isFormAcceptable';
import { Device, DeviceModel, InstallationType } from '../../services';
import { StoreContext } from '../../store';
import { AdvancedDivider, StyledFormDropdown } from '../forms';
import { ActivateSensorModal } from '../modals/activate-test-sensor-modal';
import { SensorIDInfoModal } from '../modals/sensor-id-modal';
import { Tooltip } from '../tooltip';
import { InfoSvg } from '../ui/images/svgs/info-svg';

export const equipmentTypeOptions = [
  { text: 'Freezer', value: 'freezer' },
  { text: 'Refrigerator', value: 'refrigerator' },
  { text: 'Other', value: 'other' },
];

const equipmentInstallOptions = [
  {
    text: 'Reach-in',
    value: 'free_standing',
  },
  {
    text: 'Walk-in',
    value: 'built_in',
  },
];

export const FormInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 55px;

  & .field {
    margin-bottom: 0 !important;
  }

  & .field > label {
    display: none !important;
  }

  & button {
    height: 45px;
  }
`;

interface DeviceExtended extends Device {
  uuid?: string;
}

interface DeviceFormProps {
  device?: DeviceExtended;
  locationUUID: string;
  onCancel: () => void;
  onSubmit: (
    fields: Device,
  ) => DeviceModel | Promise<DeviceModel> | Promise<void>;
  editing?: boolean;
}

interface DeviceFormFields {
  name: string;
  serialNumber: string;
  equipmentType?: string;
  equipmentInstallation?: string;
  otherEquipmentType?: string | null;
  testingMode?: boolean;
}

export const DeviceForm: FunctionComponent<DeviceFormProps> = (props) => {
  const { openModal, hideModal } = useContext(StandardModalContext);
  const { device } = props;
  const methods = useForm<DeviceFormFields>({
    defaultValues: {
      ...device,
      equipmentType: device?.equipment?.equipmentType,
      equipmentInstallation: device?.equipment?.installation,
      otherEquipmentType: device?.equipment?.other_description,
      // testingMode: props.editing ? false : true,
    },
    mode: 'onChange',
  });

  const [isDisableSubmit, setIsDisableSubmit] = useState(true);

  const equipmentType = methods.watch('equipmentType');

  if (device && !isValidSensorId(device?.serialNumber)) {
    methods.triggerValidation('serialNumber');
  }

  const { store } = useContext(StoreContext);
  const {
    checkIfUniqueSensorId,
    checkIfUniqueSensorNameInLocation,
    startTimer,
  } = useDeviceService();
  const { isQueryInProgress } = store.pages;

  const submitDevice = methods.handleSubmit(async (data) => {
    const { locationUUID } = props;
    const deviceUUID = device?.uuid || '';
    const {
      name,
      serialNumber,
      equipmentInstallation,
      otherEquipmentType,
    } = data;
    const { unique } = await checkIfUniqueSensorId({
      sensorId: serialNumber,
      deviceUUID,
    });

    if (!unique) {
      methods.setError('serialNumber', 'noDuplicateSensorId');
      return;
    }

    const {
      unique: uniqueSensorName,
    } = await checkIfUniqueSensorNameInLocation({
      sensorName: name,
      deviceUUID,
      locationUUID,
    });

    if (!uniqueSensorName) {
      methods.setError('name', 'noDuplicateSensorName');
      return;
    }
    const DeviceFormData: Device = {
      name,
      serialNumber,
      locationUUID,
      equipment: {
        equipmentType,
        installation: equipmentType === 'other' ? '' : equipmentInstallation,
        other_description: equipmentType === 'other' ? otherEquipmentType : '',
      },
      testingMode: props.editing ? false : true,
    };

    if (!props.editing) {
      DeviceFormData.testingStatus = 'connecting';
    }
    const deviceRes = await props.onSubmit(DeviceFormData);

    const { editing } = props;
    if (!editing) {
      openModal({
        headerIcon: <InfoSvg />,
        header: (
          <>
            Hold the ACT button for 5s to activate <br /> sensor and test your
            connection
          </>
        ),
        content: (
          <ActivateSensorModal
            serialNumber={(deviceRes as DeviceModel).serialNumber}
          />
        ),
        primaryButton: {
          content: 'I have pressed ACT button',
          onClick: () => {
            startTimer({
              deviceUUIDs: [(deviceRes as DeviceModel).uuid],
              isTestAgain: false,
            });
            hideModal();
          },
        },
      });
    }
  });

  useEffect(() => {
    const formValues = methods.getValues();
    Object.values(formValues).map((value) => {
      if (formValues.equipmentType === 'other' && isEmpty(value)) {
        setIsDisableSubmit(false);
        return;
      }
      if (isEmpty(value)) {
        setIsDisableSubmit(true);
        return;
      }
      return setIsDisableSubmit(false);
    });
  }, [methods.formState]);

  return (
    <FormContext {...methods}>
      <Form onSubmit={submitDevice} size="big" noValidate>
        <Form.Field>
          <Tooltip
            content="Your sensor ID is the 11+ alphanumeric code on your sensor device, which is preceded by SN or Sensor ID."
            trigger={<label>Sensor ID: *</label>}
          />
          <FormInputContainer>
            <FormInput
              name="serialNumber"
              type="text"
              required
              customErrors={{
                required: 'Sensor ID is required.',
                noDuplicateSensorId:
                  'This sensor already exists. Please check the ID and try again.',
                isValidSensorId: invalidSensorIdMsg,
              }}
              onChange={(value) => value.toUpperCase()}
              validation={{
                isValidSensorId: (val: string) => isValidSensorId(val),
              }}
            />
            <SensorIDInfoModal serialNumber={methods.watch('serialNumber')} />
          </FormInputContainer>
        </Form.Field>
        <FormInput
          name="name"
          label="Sensor name:"
          type="text"
          required
          customErrors={{
            required: 'Sensor name is required.',
            noDuplicateSensorName: sensorNameExists,
          }}
        />

        <AdvancedDivider content="Equipment details" />

        <StyledFormDropdown
          name="equipmentType"
          label="Equipment type:"
          placeholder="Select equipment type"
          description="Please select the type of equipment this sensor is monitoring."
          options={equipmentTypeOptions}
          selection
          fluid
          required
          search
        />

        {equipmentType === 'other' && (
          <FormInput
            name="otherEquipmentType"
            placeholder="Insert description"
            type="text"
            required
          />
        )}

        <StyledFormDropdown
          name="equipmentInstallation"
          label="Equipment configuration:"
          placeholder="Select equipment configuration"
          description="Please select the equipment configuration."
          options={equipmentInstallOptions}
          disabled={equipmentType === 'other'}
          selection
          fluid
          search
          required={equipmentType !== 'other'}
          showrequired={false}
          validation={{
            required: (value: InstallationType) => {
              // if 'other' or no value then required is fulfilled to not trigger required error
              return equipmentType === 'other' || !equipmentType
                ? true
                : !!value;
            },
          }}
        />

        <ButtonContainer right>
          {props.onCancel && (
            <Button
              secondary
              type="button"
              content="Cancel"
              onClick={props.onCancel}
              disabled={
                isQueryInProgress['device:add'] ||
                isQueryInProgress[`device:edit:${device?.uuid}`] ||
                methods.formState.isSubmitting
              }
            />
          )}
          <Button
            primary
            content="Save"
            type="submit"
            disabled={
              !isFormAcceptable(methods.formState) ||
              isQueryInProgress['device:add'] ||
              isQueryInProgress[`device:edit:${device?.uuid}`] ||
              methods.formState.isSubmitting ||
              isDisableSubmit
            }
            loading={
              isQueryInProgress['device:add'] ||
              isQueryInProgress[`device:edit:${device?.uuid}`] ||
              methods.formState.isSubmitting
            }
          />
        </ButtonContainer>
      </Form>
    </FormContext>
  );
};
