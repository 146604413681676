export function getHelloThermaLink(subdir: string): string {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  let link;
  switch (subdomain) {
    case 'dev':
    case 'stg':
      link = subdomain;
      break;
    case 'dashboard':
      link = 'www';
      break;
    default:
      link = 'dev';
      break;
  }
  return `https://${link}.glaciergrid.com/${subdir}`;
}
