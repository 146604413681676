import { grey25, SearchControlsContainer, white100 } from '@coinspect/ui';
import { debounce } from 'lodash';
import inflection from 'lodash-inflection';
import {
  assign,
  concat,
  filter,
  get,
  map,
  pipe,
  propEq,
  reject,
  values,
} from 'lodash/fp';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { UserProfile } from '../../contexts';
import {
  useInviteService,
  useLocationService,
  useSearch,
  useUserService,
} from '../../hooks';
import { StoreContext } from '../../store';
import ErrorBoundary from '../error-boundary/error-boundary';
import { LazyLoad } from '../lazy-load';
import { InvitationModal } from '../modals/invitation-modal';
import { PageData } from '../pages/user-page';
import { SearchBar } from '../search-bar';
import { EmptyUsers } from './empty-users';
import { UserInviteList } from './user-invite-list';
import { UserList } from './user-list';

interface UserOptions {
  userPageData: PageData;
  currentUserTotal: number;
  setUserPageData: (data: PageData) => void;
  setCurrentUserTotal: (data: number) => void;
}
interface UsersPanel {
  account: UserProfile;
  options?: UserOptions;
}

interface SearchableData {
  searchEntity: 'invite' | 'user';
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

const ListHolder = styled.div``;

const UsersContainer = styled.div`
  background: ${white100};
  border-radius: 4px;
  box-shadow: 0 0 4px 0 ${grey25};
  border: 1px solid ${grey25};
  margin: 15px 0;
`;

const addEntityType = (searchEntity: string) => map(assign({ searchEntity }));

const extractEntityData = pipe(get('byUUID'), values);

const getResultsByEntity = (searchEntity: string) =>
  filter(propEq('searchEntity', searchEntity));

const removeItemByUUID = (uuid: string) => reject(propEq('uuid', uuid));

export const UsersPanel: FunctionComponent<UsersPanel> = (props) => {
  const { account, options } = props;
  const {
    userPageData: pageData,
    setUserPageData,
    currentUserTotal,
    setCurrentUserTotal,
  }: UserOptions = options as UserOptions;
  const { populateUserPage } = useUserService();
  const { browseInvites } = useInviteService();
  const { resetLocations } = useLocationService();
  const { store, dispatch } = useContext(StoreContext);
  const { isQueryInProgress, users: userPage } = store.pages;
  const { invites, users } = store.entities;
  const isFetchingLazyLoadData = isQueryInProgress['user:search'];
  const [isLoading, setIsLoading] = useState(false);
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState(0);
  const [isSearching, setSearching] = useState(false);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const loadMoreUsers = async (isVisible?: boolean) => {
    if (!isVisible) return;
    const { page, ...rest } = pageData;
    setLoadingMoreData(true);
    const result = await populateUserPage({
      ...rest,
      page: page + 1,
    });

    setLoadingMoreData(false);
    setTotalNumberOfUsers((prev) =>
      !!result.metadata?.total ? result.metadata.total : prev,
    );
    setUserPageData({
      page: result.metadata?.page,
      limit: result.metadata?.limit,
    });

    if (!result.data.length) return;
    setIsLoading(false);
    setCurrentUserTotal(currentUserTotal + result.data.length);
  };

  useEffect(() => {
    if (userPage.isDataLoaded) return;
    (async () => {
      setIsLoading(true);
      try {
        await Promise.allSettled([
          loadMoreUsers(true),
          browseInvites(),
          resetLocations(),
        ]);
      } finally {
        dispatch({
          data: true,
          type: 'userPage:isDataLoaded:set',
        });
        setIsLoading(false);
      }
    })();
  }, []);

  const usersTempData = pipe(extractEntityData, addEntityType('user'))(users);

  const usersData =
    account.role !== 'account_owner'
      ? pipe(removeItemByUUID(account.uuid))(usersTempData)
      : usersTempData;

  const invitesData = pipe(extractEntityData, addEntityType('invite'))(invites);

  const searchableData: SearchableData[] = concat(invitesData)(usersData);
  const { search, result: searchResults } = useSearch<SearchableData>(
    searchableData,
    {
      keys: ['email', 'phoneNumber', 'firstName', 'lastName'],
    },
  );

  const handleSearch = debounce(async (keyword: string) => {
    setSearching(true);
    if (!keyword) {
      search(keyword);
      setSearching(false);
      return;
    }

    await Promise.allSettled([
      populateUserPage({ searchKeyword: keyword }),
      browseInvites({ searchKeyword: keyword }),
    ]);

    search(keyword);
  }, 1000);

  const foundInvites = getResultsByEntity('invite')(searchResults);
  const foundUsers = getResultsByEntity('user')(searchResults).filter((user) =>
    Boolean(user.signedUpDate),
  );

  const hasValidData = (): boolean => {
    const flag = !!invites.all.length || users.all.length >= 2;
    return flag;
  };

  // TODO: temporary loader
  if (isLoading) {
    return <Loader active inline="centered" />;
  }
  const numFoundUsers = foundUsers.length;
  const allUsersFound = totalNumberOfUsers === numFoundUsers;

  return (
    <>
      <SearchControlsContainer>
        <SearchBar
          placeholder="Search users"
          onChange={(keyword) => handleSearch(keyword)}
          isDisabled={isFetchingLazyLoadData}
          isSearching={isQueryInProgress['user:search']}
        />
        <InvitationModal invites={foundInvites} />
      </SearchControlsContainer>
      {hasValidData() ? (
        <ErrorBoundary>
          <ListHolder>
            {searchResults.length ? (
              <UsersContainer>
                <UserInviteList invites={foundInvites} />
                <UserList users={foundUsers} account={account} />
              </UsersContainer>
            ) : (
              <EmptyUsers
                message={
                  "We couldn't find any users matching your search criteria."
                }
              />
            )}

            {totalNumberOfUsers > 0 && loadingMoreData && (
              <div className="loading-more-data">Loading more users</div>
            )}
            {!loadingMoreData && numFoundUsers > 0 && allUsersFound && (
              <div className="page-data">
                {`Displaying ${inflection.pluralize(
                  'user',
                  numFoundUsers,
                  true,
                )} out of ${totalNumberOfUsers}`}
              </div>
            )}
            {!isSearching && !allUsersFound && (
              <LazyLoad onVisible={loadMoreUsers}>
                <div className="not-visible">load more</div>
              </LazyLoad>
            )}
          </ListHolder>
        </ErrorBoundary>
      ) : (
        <EmptyUsers />
      )}
    </>
  );
};
