import { toFahrenheit } from '@coinspect/utils';
import React, { FunctionComponent, useContext } from 'react';

import { AccountContext } from '../../../../contexts/account-context';
import { StoreContext } from '../../../../store';
import { AxisTickProps } from './index';

export interface YAxisTickProps extends AxisTickProps {
  labels: (string | number)[];
}

export const YAxisTick: FunctionComponent<YAxisTickProps> = (props) => {
  const { store } = useContext(StoreContext);
  const { preferredTempUnit } = useContext(AccountContext).user;
  const {
    filters: { sensorType },
  } = store.pages.dashboard;

  const { x = 0, y = 0, index = 0, labels } = props;
  const isHumidity = sensorType === 'humidity';
  // do not create tick for empty y-labels
  if (!labels[index]) {
    return null;
  }
  const yAxis =
    // ignore temp conversion if humidity
    preferredTempUnit === 'f' && !isHumidity
      ? +toFahrenheit(+labels[index])
      : +labels[index];

  const labelSuffix = !isHumidity ? `°${preferredTempUnit.toUpperCase()}` : '%';
  return (
    <g transform={`translate(${x - 40},${y + 5})`}>
      <text className="yaxis-label">{`${yAxis.toFixed(1)}${labelSuffix}`}</text>
    </g>
  );
};
