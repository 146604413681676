import {
  ButtonContainer,
  FormInput,
  FormTextStyle,
  LimitedContainer,
  stoneBlue30,
  thermaGrey,
  thermaH2FontFamily,
  white100,
} from '@coinspect/ui';
import React, { useContext, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';
import styled, { createGlobalStyle } from 'styled-components';

import {
  computerMinWidthPx,
  tabletMaxWidth,
} from '../../constants/responsive-constants';
import { AccountContext } from '../../contexts';
import {
  send2faCode,
  verify2faCode,
  verifyUser,
} from '../../contexts/utils/send2faCode';
import { useSetInterval } from '../../hooks';
import { AppLogo } from '../base';
import { SectionHeading } from '../section-heading/section-heading';
import { Links } from '../ui';

const TwoFAPageStyle = createGlobalStyle`
  body {
    background-color: white !important;
  }
`;

const TwoFAWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;

  &&&& h1 {
    font-size: 23px;
  }
`;

export const DummyNavbar = styled.div`
  && {
    background-color: ${thermaGrey};
    display: block;
    color: ${white100};
    padding: 20px 68px 16px;
    box-shadow: 0 2px 2px 0 ${stoneBlue30};
    transform: translate3d(0, 0, 0);

    .ui.header {
      font-family: ${thermaH2FontFamily};
    }

    @media only screen and (max-width: ${computerMinWidthPx}) {
      padding: 20px 15px 16px;
    }
  }
`;

const TwoFACodeWrapper = styled.div`
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    padding: 0 25px;
  }
`;

const RecaptchaDisclaimer = styled.div`
  margin-bottom: 20px;
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    padding: 0 25px;
  }
`;

const CodeMessage = styled.div`
  ${FormTextStyle}
`;

export const TwoFactorAuthPage: React.FC = () => {
  const { user, logout, isCaptchaReady } = useContext(AccountContext);
  const resendTimer = 30;
  const [resendCountdown, setResendCountdown] = useState<number>(resendTimer);
  const [stopCounter, setStopCounter] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isResent, setResent] = useState(false);

  const methods = useForm<{ code: string }>({
    defaultValues: {
      code: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    setResent(false);
  }, [methods.watch('code')]);

  const handleSubmit = methods.handleSubmit(async ({ code }) => {
    setSubmitLoading(true);

    try {
      await verify2faCode(code);
      window.location.reload();
    } catch (e) {
      switch (e.code) {
        // this is caused when user refreshes 2fa page
        case 'auth/argument-error':
          logout();
          break;

        case 'auth/invalid-verification-code':
          methods.setError('code', 'invalidCode');
          break;

        default:
          methods.setError('code', 'verifyError');
          console.error('err', e);
      }
    } finally {
      setResendCountdown(0);
      setStopCounter(true);
      setSubmitLoading(false);
    }
  });

  const runCountDown = () => {
    useSetInterval(countDown, stopCounter ? null : 1000);
  };

  const resendCode = async () => {
    methods.reset();
    setResent(false);
    try {
      if (!user) {
        await verifyUser();
      } else {
        await send2faCode(user.phoneNumber, false);
      }
      setResent(true);
      setResendCountdown(resendTimer);
      setStopCounter(false);
    } catch (e) {
      switch (e.code) {
        case 'auth/requires-recent-login':
          logout();
          break;

        case 'auth/too-many-requests':
          methods.setError('code', 'tooManyRequest');
          break;

        default:
          // eslint-disable-next-line no-console
          console.log('e', e);
      }

      setResent(false);
    }
  };

  const countDown = () => {
    setResendCountdown(resendCountdown - 1);

    // Check if we're at zero.
    if (resendCountdown === 0) {
      setStopCounter(true);
    }
  };

  runCountDown();

  return (
    <>
      <DummyNavbar>
        <AppLogo width="150" height="100%" heightIE="33px" />
      </DummyNavbar>

      <LimitedContainer>
        <TwoFAPageStyle />
        <TwoFAWrapper>
          <SectionHeading heading="Two Factor Authentication" />

          <RecaptchaDisclaimer>
            This site is protected by reCAPTCHA and the Google
            <Links target="_blank" href="https://policies.google.com/privacy">
              {' '}
              Privacy Policy
            </Links>{' '}
            and{' '}
            <Links target="_blank" href="https://policies.google.com/terms">
              Terms of Service
            </Links>{' '}
            apply.
          </RecaptchaDisclaimer>

          <TwoFACodeWrapper>
            <FormContext {...methods}>
              <Form onSubmit={handleSubmit}>
                <FormInput
                  name="code"
                  label="Code:"
                  required
                  customErrors={{
                    required: 'Code is required',
                    invalidCode: 'Invalid verification code',
                    resendError:
                      'Something went wrong resending the code. Try again later',
                    verifyError: 'Something went wrong verifying the code',
                    tooManyRequest:
                      'We have blocked all requests from this device due to unusual activity. Try again later.',
                  }}
                  disabled={!isCaptchaReady}
                  disabledBg
                />

                {isResent && <CodeMessage>Code has been resent.</CodeMessage>}

                <ButtonContainer>
                  <Button
                    type="submit"
                    content="Submit"
                    loading={submitLoading}
                    disabled={submitLoading || !isCaptchaReady}
                    primary
                    onClick={() => {
                      setResent(false);
                    }}
                  />
                </ButtonContainer>

                <small>
                  {`Didn't receive a code? `}
                  <span
                    onClick={() => {
                      if (resendCountdown > 0) return;
                      resendCode();
                    }}
                  >
                    {resendCountdown > 0 && !stopCounter ? (
                      `Resend in ${resendCountdown}.`
                    ) : (
                      <Links className="cursor-pointer">Resend now.</Links>
                    )}
                  </span>
                </small>
              </Form>
            </FormContext>
          </TwoFACodeWrapper>
        </TwoFAWrapper>
      </LimitedContainer>
    </>
  );
};

export default TwoFactorAuthPage;
