import React, { FunctionComponent } from 'react';

export const CarsGasIcon: FunctionComponent = () => {
  return (
    <span>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="24" fill="#E1EDF1" />
        <path
          d="M33 25.5L31.3 21.71C31.1393 21.2812 30.8516 20.9116 30.4753 20.6507C30.0989 20.3898 29.6519 20.25 29.194 20.25H18.809C18.3506 20.2494 17.9029 20.3889 17.526 20.6499C17.149 20.9108 16.8608 21.2807 16.7 21.71L15 25.5"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.5 32.219V33.75C13.5 34.1478 13.658 34.5293 13.9393 34.8107C14.2206 35.092 14.6022 35.25 15 35.25C15.3978 35.25 15.7794 35.092 16.0607 34.8107C16.342 34.5293 16.5 34.1478 16.5 33.75V32.25"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.875 28.5C31.9745 28.5 32.0698 28.5395 32.1402 28.6098C32.2105 28.6802 32.25 28.7755 32.25 28.875"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.5 28.875C31.5 28.7755 31.5395 28.6802 31.6098 28.6098C31.6802 28.5395 31.7755 28.5 31.875 28.5"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.875 29.25C31.7755 29.25 31.6802 29.2105 31.6098 29.1402C31.5395 29.0698 31.5 28.9745 31.5 28.875"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.25 28.875C32.25 28.9745 32.2105 29.0698 32.1402 29.1402C32.0698 29.2105 31.9745 29.25 31.875 29.25"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.125 28.5C16.0255 28.5 15.9302 28.5395 15.8598 28.6098C15.7895 28.6802 15.75 28.7755 15.75 28.875"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 28.875C16.5 28.7755 16.4605 28.6802 16.3902 28.6098C16.3198 28.5395 16.2245 28.5 16.125 28.5"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.125 29.25C16.2245 29.25 16.3198 29.2105 16.3902 29.1402C16.4605 29.0698 16.5 28.9745 16.5 28.875"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.75 28.875C15.75 28.9745 15.7895 29.0698 15.8598 29.1402C15.9302 29.2105 16.0255 29.25 16.125 29.25"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.75 25.5H33.25C33.7804 25.5 34.2891 25.7107 34.6642 26.0858C35.0393 26.4609 35.25 26.9696 35.25 27.5V31.25C35.25 31.5152 35.1446 31.7696 34.9571 31.9571C34.7696 32.1446 34.5152 32.25 34.25 32.25H13.75C13.4848 32.25 13.2304 32.1446 13.0429 31.9571C12.8554 31.7696 12.75 31.5152 12.75 31.25V27.5C12.75 26.9696 12.9607 26.4609 13.3358 26.0858C13.7109 25.7107 14.2196 25.5 14.75 25.5V25.5Z"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.5 32.219V33.75C34.5 34.1478 34.342 34.5293 34.0607 34.8107C33.7794 35.092 33.3978 35.25 33 35.25C32.6022 35.25 32.2206 35.092 31.9393 34.8107C31.658 34.5293 31.5 34.1478 31.5 33.75V32.25"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.0002 18.0001C24.0001 17.5523 23.8663 17.1148 23.6162 16.7435C23.366 16.3721 23.0107 16.0839 22.5958 15.9156C22.1808 15.7474 21.7251 15.7067 21.287 15.7989C20.8488 15.8911 20.4481 16.1119 20.1362 16.4331C20.0023 15.9601 19.7542 15.5273 19.4138 15.1727C19.0734 14.8181 18.6511 14.5526 18.184 14.3995C17.7169 14.2464 17.2194 14.2104 16.7351 14.2947C16.2508 14.379 15.7947 14.581 15.4068 14.8829C15.019 15.1849 14.7112 15.5775 14.5107 16.0263C14.3101 16.4751 14.2567 17.4566 14.2567 17.4566C14.2567 17.4566 13.1249 18.0001 13.1249 19.1251C13.125 20.2501 13.8749 21.0001 14.9999 21.0001"
          stroke="#6BA4B8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};
