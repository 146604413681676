import { BaseBlack } from '@coinspect/ui';
import { isValidEmail, isValidPhoneNumber } from '@coinspect/utils';
import React, { useState } from 'react';
import { TextAreaProps } from 'semantic-ui-react';

import { useTeamService } from '../../../hooks';
import { isValidJoiEmail } from '../../../lib';
import { StyledTextArea } from '../../forms';
import StandardModalBody from '../../modals/standard-modal-body';
import { BulkInviteTable } from './bulk-invite-table';
import { InviteModes, replaceClass } from './index';

export type BulkInviteSectionProps = {
  inviteType: InviteModes;
  onCancelPrepare?: React.Dispatch<boolean>;
};

const textArea = {
  sms: {
    label: 'Mobile numbers',
    msg: `Each number comma separated or as a new line. Format requires country code, area code, phone number.
+#-###-###-####`,
    error: () =>
      'Formatting error. Please be sure to include the country code and area code and no spaces for each phone number. Example +#-###-###-####',
  },
  email: {
    label: 'Email addresses',
    msg: 'Each email address comma separated or as a new line.',
    error: (err: string) =>
      `The following email addresses were entered incorrectly. Please adjust them and try again: ${err}`,
  },
};

export const BulkInviteSection: React.FC<BulkInviteSectionProps> = (props) => {
  const { inviteType, onCancelPrepare } = props;
  const { browseTeam } = useTeamService();
  const [bulkInviteError, setBulkInviteError] = useState('');
  const [inviteList, setInviteList] = useState('');

  const [bulkInviteStatus, setBulkInviteStatus] = useState<
    'prepare' | 'configure'
  >('prepare');

  const configureInviteList = async () => {
    // combine all entries then split
    const splitted = inviteList
      .replace(/\n*$/, '') // replace trailing new line
      .replace(/^(?=\n)$|^\s*|\s*$|\n\n+/gm, '') // remove empty lines in between lines
      .replace(/\n/g, ',') // replace new lines with comma
      .replace(/\s/g, '') // remove any white spaces
      .toLowerCase()
      .split(',');

    // get invalid entries
    const invalidEntries = splitted.filter((val) => {
      switch (inviteType) {
        case 'sms':
          return !isValidPhoneNumber(val.trim());
        case 'email':
          return !isValidEmail(val.trim()) || !isValidJoiEmail(val.trim());
      }
    });

    // save invalid entries
    setBulkInviteError(invalidEntries.join(', '));

    if (invalidEntries.length === 0) {
      await browseTeam(); // browse on continue so teams will be ready already on bulk table
      setInviteList(splitted.join()); // set the inviteList to the cleanup list (splitted)
      setBulkInviteStatus('configure');
      const modal = document.getElementsByClassName('ui modal')[0];
      replaceClass(modal, 'tiny', 'large');
    }
  };

  const handleTextareaChange = (data: string) => {
    setBulkInviteError('');
    setInviteList(data);
  };

  const handleConfigureCancel = (data: string) => {
    setBulkInviteStatus('prepare');
    const modal = document.getElementsByClassName('ui modal')[0];
    replaceClass(modal, 'large', 'tiny');
    setInviteList(data);
  };

  return bulkInviteStatus === 'prepare' ? (
    <StandardModalBody>
      <StandardModalBody.Content>
        <div style={{ fontSize: '18px', color: BaseBlack }}>
          {textArea[inviteType]?.label}
        </div>
        <StyledTextArea
          placeholder={textArea[inviteType]?.msg}
          rows={10}
          name="invite-list"
          onChange={(_e: React.FormEvent, data: TextAreaProps) =>
            handleTextareaChange(data.value as string)
          }
          value={inviteList}
        />

        {bulkInviteError && (
          <div id="error-msg" style={{ color: 'red' }}>
            {textArea[inviteType]?.error(bulkInviteError)}
          </div>
        )}
      </StandardModalBody.Content>
      <StandardModalBody.Actions
        primaryButton={{
          content: 'Configure',
          onClick: configureInviteList,
          disabled: !inviteList.length || !!bulkInviteError,
        }}
        secondaryButton={{
          content: 'Cancel',
          onClick: () => onCancelPrepare?.(true),
        }}
      />
    </StandardModalBody>
  ) : (
    <BulkInviteTable
      inviteList={inviteList}
      inviteType={inviteType}
      onCancel={handleConfigureCancel}
    />
  );
};
