import React, { useContext, useEffect, useState } from 'react';

import { MobileNavbar, Navbar } from '../../components/navigation';
import {
  DesktopOnly,
  MobileAndTablet,
} from '../../constants/responsive-constants';
import { AccountContext } from '../../contexts/';

export const TopNav = () => {
  const userContext = useContext(AccountContext);
  const [isRegistrationRoute, setIsRegistrationRoute] = useState(false);

  useEffect(() => {
    setIsRegistrationRoute(location.pathname === '/registration');
  }, [location.pathname]);

  const { user, doPassRenewal, do2FA } = userContext;
  if (!user || doPassRenewal || do2FA || isRegistrationRoute) {
    return null;
  }

  return (
    <>
      <DesktopOnly>
        <Navbar user={user} userContext={userContext} />
      </DesktopOnly>
      <MobileAndTablet>
        <MobileNavbar />
      </MobileAndTablet>
    </>
  );
};
