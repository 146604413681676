import { thermaInputFocus, white100 } from '@coinspect/ui';
import React, { ChangeEvent, useState } from 'react';
import { Icon, Input } from 'semantic-ui-react';
import styled from 'styled-components';

export interface SearchBarProps {
  placeholder?: string;
  onChange: (...args: string[]) => void | Promise<void>;
  isDisabled?: boolean;
  isSearching?: boolean;
  className?: string;
  defaultKeyword?: string;
}

const SearchBarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${white100};
  border: 1px solid ${white100};
  border-radius: 4px;

  :focus {
    border-color: ${thermaInputFocus};
  }

  &&& i.icon.search:before {
    font-size: 1.8em;
    content: 'I';
  }

  && input {
    font-weight: 300;
    border: none;
    :focus {
      border: none;
    }
  }
`;

const ClearSearchIcon = styled(Icon)`
  display: flex;
  align-items: center;
  padding-right: 14px;
  margin: 0;

  && {
    width: auto;
    height: auto;
  }
`;

const SearchBar: React.FC<SearchBarProps> = (props) => {
  const {
    placeholder = 'Search...',
    onChange,
    isDisabled = false,
    isSearching = false,
    defaultKeyword = '',
  } = props;
  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearchKeyword(value);
    onChange(value);
  };

  const resetSearch = () => {
    const elem = document.getElementById('search-input') as HTMLInputElement;

    if (elem) {
      elem.value = '';
    }

    setSearchKeyword(elem.value);
    onChange(elem.value);
  };

  return (
    <SearchBarContainer>
      <Input
        icon
        disabled={isDisabled}
        placeholder={placeholder}
        iconPosition="left"
      >
        <input
          onChange={handleOnChange}
          id="search-input"
          className="no-border"
          defaultValue={defaultKeyword}
        />
        <Icon
          name={isSearching ? 'circle notch' : 'search'}
          className={isSearching ? 'rotating' : ''}
        />
      </Input>
      {(searchKeyword?.length > 0 || defaultKeyword) && (
        <ClearSearchIcon
          disabled={isDisabled}
          className="icon-close"
          onClick={resetSearch}
        />
      )}
    </SearchBarContainer>
  );
};

export { SearchBar };
