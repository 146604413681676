import React, { FC, useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { StandardModalContext } from '../../contexts';
import { AlertSchedule, AlertSchedulerForm } from '../alert';

interface AlertSchedulerModalProps {
  rules?: AlertSchedule[];
  timezone: string;
  onSubmit?: (data: AlertSchedule[], scheduleTimezone: string) => void;
  isDisabled?: boolean;
}

const AlertSchedulerButton = styled(Button)`
  &&&& {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    font-size: 28px;
    background-color: ${(props) =>
      props.rules.length ? '#6ba4b8' : '#d8dbe2'};
    border-color: ${(props) => (props.rules.length ? '#6ba4b8' : '#d8dbe2')};
    color: ${(props) => (props.rules.length ? '#fff' : 'rgba(10, 10, 10, .6)')};
    &:hover {
      background-color: #bbc0cc;
      border-color: #bbc0cc;
    }
  }
`;

export const AlertSchedulerModal: FC<AlertSchedulerModalProps> = (props) => {
  const { hideModal, openModal } = useContext(StandardModalContext);
  const { rules = [], timezone, isDisabled } = props;
  return (
    <AlertSchedulerButton
      disabled={isDisabled}
      primary={Boolean(rules.length)}
      circular
      size="huge"
      aria-label="Schedule alerts"
      type="button"
      rules={rules}
      className="icon-schedule-alert"
      onClick={() =>
        openModal({
          width: '720px',
          headerIcon: (
            <Icon fitted className="icon-schedule-alert" size="large" />
          ),
          allContent: (
            <AlertSchedulerForm
              existingAlertSchedules={rules.length ? rules : undefined}
              existingTimezone={timezone}
              onSubmit={(data, scheduleTimezone) => {
                if (props.onSubmit) {
                  props.onSubmit(data, scheduleTimezone);
                }
                hideModal();
              }}
              onCancel={hideModal}
            />
          ),
          header: 'Schedule alert',
        })
      }
      data-title="Schedule alerts"
    />
  );
};
