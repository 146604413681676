import { LimitedContainer } from '@coinspect/ui';
import React from 'react';
import styled from 'styled-components';

import { AppLogo } from '../base';
import { RenewPasswordForm } from '../forms';
import { DummyNavbar } from './two-factor-auth-page';

const ButtonsAndForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 2em 25% 0 25%;
  flex-grow: 1;
  gap: 1em;
`;

const RenewPasswordPage: React.FC = () => {
  return (
    <>
      <DummyNavbar>
        <AppLogo width="150" height="100%" heightIE="33px" />
      </DummyNavbar>

      <LimitedContainer>
        <ButtonsAndForm>
          <RenewPasswordForm />
        </ButtonsAndForm>
      </LimitedContainer>
    </>
  );
};

export default RenewPasswordPage;
