import mixpanel from 'mixpanel-browser';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { mobileMaxWidth } from 'src/constants';
import styled from 'styled-components';

import { AccountContext } from '../../../contexts';
import { Role } from '../../../services';
import AppLogoLarge from './icons/AppLogoLarge';
import AppLogoSmall from './icons/AppLogoSmall';
import CloseIcon from './icons/CloseIcon';
import HamburgerIcon from './icons/HamburgerIcon';
import HelpIcon from './icons/HelpIcon';
import HomeIcon from './icons/HomeIcon';
import LocationIcon from './icons/LocationIcon';
import LogoutIcon from './icons/LogoutIcon';
import { PeopleIcon } from './icons/PeopleIcon';
import { ReportsIcon } from './icons/ReportsIcon';

const DrawerContainer = styled.div`
  &.inactive {
    display: none;
  }
  &.active {
    display: block;
    position: fixed;
    width: calc(100% - 150px);
    height: 100%;
    max-height: 450px;
    top: 0;
    left: 150px;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: 3;

    animation: slide-in 0.35s both;
  }

  &.slide-out {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    max-height: 450px;
    top: 0;
    left: 150px;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: 3;

    animation: slide-out 0.35s both;
  }

  @keyframes slide-out {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(100%);
    }
  }
  @keyframes slide-in {
    from {
      transform: translateX(100%);
    }

    to {
      transform: translateX(0);
    }
  }
`;

const MobileNavContainer = styled.div`
  display: flex;
  padding-top: 28;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  background: #121212;
  z-index: 4;
  @media only screen and (min-width: ${mobileMaxWidth}px) {
    padding-top: 0;
    display: none;
  }
`;

const NavIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const NavText = styled.span`
  padding-left: 16px;
`;

const DrawerContent = styled.div`
  padding-left: 12px;
`;

const EnergyMobileNav = ({ page }: { page?: string }) => {
  const [activeClass, setActiveClass] = useState('inactive');
  const { user, logout } = useContext(AccountContext);
  const adminOrOwner = [Role.admin, Role.account_owner].includes(
    user?.role || Role.user,
  );
  return (
    <MobileNavContainer>
      <NavIconContainer>
        <Link
          to="/"
          onClick={() => {
            mixpanel.track('Home tab clicked');
          }}
        >
          <div style={{ display: 'flex' }}>
            <AppLogoLarge
              id="large-logo"
              style={{ transform: 'scale(0.8, 0.8)' }}
            />
          </div>
        </Link>
      </NavIconContainer>
      <div
        style={{ paddingRight: 24, paddingTop: 24 }}
        onClick={() => {
          if (activeClass === 'inactive') {
            setActiveClass('active');
            return;
          }
          setActiveClass('slide-out');
          setTimeout(() => {
            setActiveClass('inactive');
          }, 500);
        }}
      >
        <HamburgerIcon />
      </div>
      <DrawerContainer className={activeClass}>
        <DrawerContent>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: 9,
              paddingRight: 15,
            }}
          >
            <span style={{ paddingTop: 9 }}>
              <Link to="/">
                <AppLogoSmall id="small-logo" />
              </Link>
            </span>
            <Button
              style={{
                padding: 0,
                width: 30,
                height: 30,
                background: '#D3E5F0',
              }}
              onClick={() => {
                setActiveClass('slide-out');
                setTimeout(() => {
                  setActiveClass('inactive');
                }, 500);
              }}
              circular
            >
              <CloseIcon />
            </Button>
          </div>
          <NavIconContainer>
            <Link to="/energy">
              <div
                style={{
                  color: `${!page || page === 'HOME' ? '#B2FFFF' : '#8B9198'}`,
                  paddingTop: 40,
                  paddingLeft: 8,
                  display: 'flex',
                }}
              >
                <HomeIcon
                  fill={`${!page || page === 'HOME' ? '#B2FFFF' : '#8B9198'}`}
                />{' '}
                <NavText>HOME</NavText>
              </div>
            </Link>
          </NavIconContainer>
          <NavIconContainer>
            <Link
              to="/energy/location"
              onClick={() => {
                mixpanel.track('Locations tab clicked');
              }}
            >
              <div
                style={{
                  color: `${page === 'LOCATIONS' ? '#B2FFFF' : '#8B9198'}`,
                  paddingTop: 40,
                  paddingLeft: 4,
                  display: 'flex',
                }}
              >
                <LocationIcon
                  fill={`${page === 'LOCATIONS' ? '#B2FFFF' : '#8B9198'}`}
                />{' '}
                <NavText style={{ paddingLeft: '12px' }}>LOCATIONS</NavText>
              </div>
            </Link>
          </NavIconContainer>
          {adminOrOwner && (
            <NavIconContainer>
              <Link
                to="/energy/people"
                onClick={() => {
                  mixpanel.track('People tab clicked');
                }}
              >
                <div
                  style={{
                    color: `${page === 'PEOPLE' ? '#B2FFFF' : '#8B9198'}`,
                    display: 'flex',
                    paddingLeft: 6,
                    paddingTop: 40,
                  }}
                >
                  <PeopleIcon
                    fill={`${page === 'PEOPLE' ? '#B2FFFF' : '#8B9198'}`}
                  />{' '}
                  <NavText style={{ paddingLeft: '12px' }}>PEOPLE</NavText>
                </div>
              </Link>
            </NavIconContainer>
          )}
          <NavIconContainer>
            <Link
              to="/energy/reports"
              onClick={() => {
                mixpanel.track('Report tab clicked');
              }}
            >
              <div
                style={{
                  color: `${page === 'REPORTS' ? '#B2FFFF' : '#8B9198'}`,
                  display: 'flex',
                  paddingLeft: 6,
                  paddingTop: 40,
                }}
              >
                <ReportsIcon
                  fill={`${page === 'REPORTS' ? '#B2FFFF' : '#8B9198'}`}
                />{' '}
                <NavText style={{ paddingLeft: '16px' }}>REPORTS</NavText>
              </div>
            </Link>
          </NavIconContainer>
          <NavIconContainer>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.glaciergrid.com/helpcenter"
            >
              {' '}
              <div
                style={{
                  paddingTop: 40,
                  color: '#8B9198',
                  display: 'flex',
                  paddingLeft: 8,
                }}
              >
                <HelpIcon />
                <NavText>HELP</NavText>
              </div>
            </a>
          </NavIconContainer>
          <NavIconContainer style={{ paddingBottom: 100 }}>
            <Link to="/" onClick={() => logout()}>
              <div
                style={{
                  color: '#8B9198',
                  display: 'flex',
                  paddingLeft: 6,
                  paddingTop: 40,
                }}
              >
                <LogoutIcon />{' '}
                <NavText style={{ paddingLeft: '12px' }}>LOGOUT</NavText>
              </div>
            </Link>
          </NavIconContainer>
        </DrawerContent>
      </DrawerContainer>
    </MobileNavContainer>
  );
};

export default EnergyMobileNav;
