import { toSentenceCase } from 'common-utils-pkg';
import React from 'react';
import styled from 'styled-components';

import { Text } from '../text';

interface RadioProps {
  label: string;
  value: string;
  active?: boolean;
  onChange: () => void;
  reverse?: boolean;
}

const CustomRadio = styled.div`
  width: 100%;

  /* Customize the label (the container) */
  .left-radio-container {
    display: block;
    position: relative;

    p {
      padding-left: 32px;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 4px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid #fff;
    }
  }

  .right-radio-container {
    display: flex;
    justify-content: space-between;

    .checkmark {
      position: relative;
      height: 20px;
      width: 20px;
      border: 2px solid #fff;
      border-radius: 50%;
    }
  }

  .container {
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    /* background-color: #ccc; */
    border-color: var(--new-primary-color);
  }

  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    border-color: var(--new-primary-color);
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 2.5px;
    left: 2.5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: var(--new-primary-color);
  }
`;

export const Radio = ({
  label,
  active = false,
  onChange,
  reverse = false,
}: RadioProps) => {
  return (
    <CustomRadio>
      <label
        className={`container ${
          reverse ? 'left-radio-container' : 'right-radio-container'
        }`}
      >
        <Text useNew>{toSentenceCase(label)}</Text>
        <input type="radio" checked={active} onChange={onChange} />
        <span className="checkmark" />
      </label>
    </CustomRadio>
  );
};
