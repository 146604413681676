import moment from 'moment';

import { DeviceSensorData } from '../../src/services';

export const updateTempStatusText = {
  updateText: '',
  toolTipText: '',
  passedMin: 0,
};

// Retrieve latest temperature/humidity data for dashboard filter rail sensor behavior
export function getUpdateStatus(
  latestData: DeviceSensorData,
  createdDate: Date,
) {
  if (latestData != null) {
    // This will dynamically update dashboard filter rail status regardless when temperature or humidity
    const sensorTypeValue = latestData.temperature ?? latestData.humidity;

    const x = moment();
    const timeCreated = moment.duration(x.diff(createdDate));
    const duration = moment.duration(x.diff(latestData.time));
    const passedTimeCreated = Math.round(timeCreated.asMinutes());

    let passedMin;
    let updateTemp;

    // Check if device is just created within 2-hours
    const justCreated = passedTimeCreated <= 120;

    // Check if sensor is just created && no data to show
    if (justCreated && !sensorTypeValue) {
      updateTempStatusText.updateText = 'Updated –';
      updateTempStatusText.toolTipText = '';
      updateTempStatusText.passedMin = 0;
    } else if (sensorTypeValue) {
      // Check if sensor has latest temperature/humidity data
      passedMin = Math.round(duration.asMinutes());

      // Check if update is made within the day
      if (
        moment()
          .endOf('day')
          .diff(latestData.time, 'days') <= 0
      ) {
        // format('h:mm A'); -> 9:59 AM current update display
        updateTemp = moment(
          latestData.time.toString(),
          'YYYY-M-DTH:mm:ssZ',
        ).format('h:mm A');
      } else {
        // Check if sensor update is from previous day
        // IF sensor has no data for 24 hours
        // format('MMM D h:mm A'); -> Jan 6 9:59 AM, should be displayed as timestamp from the previous update
        updateTemp = moment(
          latestData.time.toString(),
          'YYYY-M-DTH:mm:ssZ',
        ).format('MMM D h:mm A');
      }

      updateTempStatusText.updateText = `Updated ${updateTemp} `;
      updateTempStatusText.toolTipText = `${updateTempStatusText.updateText}`;
      updateTempStatusText.passedMin = passedMin;
    } else {
      // Sensor is not just created and never sent an update
      passedMin = Math.round(timeCreated.asMinutes());
      updateTempStatusText.updateText = `Updated –`;
      updateTempStatusText.toolTipText = '';
      updateTempStatusText.passedMin = 0;
    }
  }
  return updateTempStatusText;
}
