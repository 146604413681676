import { TeamModel } from '../../services';
import { DataStoreAction, initialStoreEntity, NormalEntity } from '../reducer';

const _initTeam = initialStoreEntity<TeamModel>();

export const initialTeamsStore = {
  ..._initTeam,
  isActiveForm: false,
  isDataLoaded: false,
};

type InitalTeamPageState = NormalEntity<TeamModel>;

export interface TeamPageState extends InitalTeamPageState {
  isActiveForm: boolean;
  isDataLoaded: boolean;
}

export function teamPageReducer(state: TeamPageState, action: DataStoreAction) {
  switch (action.type) {
    case 'teamPage:isActiveForm:toggle': {
      return {
        ...state,
        isActiveForm: !state.isActiveForm,
      };
    }
    case 'teamPage:isActiveForm:reset': {
      return {
        ...state,
        isActiveForm: false,
      };
    }
    case 'teamPage:isDataLoaded:set': {
      return {
        ...state,

        isDataLoaded: action.data,
      };
    }
    case 'teams:set':
      return {
        ...state,
        all: [...new Set(state.all.concat(action.data.result))],
        byUUID: {
          ...state.byUUID,
          ...action.data.entities['teams'],
        },
      };
    case 'teams:edit':
      return {
        ...state,
        byUUID: {
          ...state.byUUID,
          ...action.data.entities['teams'],
        },
      };
    case 'teams:delete':
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.data]: val, ...byUUID } = state.byUUID;
      const uuidIdx = state.all.indexOf(action.data);
      const all = state.all
        .slice(0, uuidIdx)
        .concat(state.all.slice(uuidIdx + 1));
      return {
        ...state,
        all,
        byUUID,
      };
  }
  return state;
}
