import React from 'react';
import styled from 'styled-components';

import StandardModalBody from './standard-modal-body';

const TextContent = styled.p`
  text-align: left;
  margin-bottom: 20px;
  color: #545656;
`;

const DeviceImageContainer = styled.div`
  display: flex;
  justify-content: center;
  & img {
    width: 250px;
  }
`;

export interface ACTProps {
  serialNumber: string;
}

export function SwitchACTIllustration(serialNumber: string) {
  if (serialNumber) {
    const sensorID = serialNumber.toLowerCase();
    const slicedSensorID = sensorID.substring(0, 5);

    if (slicedSensorID === 'HT65N'.toLowerCase()) {
      return require('../ui/images/sensor-act-button/ht-act.png');
    } else if (
      slicedSensorID === 'LHT65'.toLowerCase() ||
      slicedSensorID === 'LST25'.toLowerCase()
    ) {
      return require('../ui/images/sensor-act-button/lht-act.png');
    } else {
      return require('../ui/images/sensor-ids/generic-sensor-id.png');
    }
  } else {
    return require('../ui/images/sensor-ids/generic-sensor-id.png');
  }
}

export const ActivateSensorModal: React.FC<ACTProps> = (props) => {
  const { serialNumber } = props;

  return (
    <StandardModalBody>
      <StandardModalBody.Content>
        <TextContent>
          Your sensor is ready to test! After the sensor is placed upright in
          your equipment, hold the ACT button for 5 seconds until you see a
          flashing green light followed by a red flash. View the connection
          status after configuring all sensors for this location.
        </TextContent>
        <DeviceImageContainer>
          <img src={SwitchACTIllustration(serialNumber)} />
        </DeviceImageContainer>
      </StandardModalBody.Content>
    </StandardModalBody>
  );
};
