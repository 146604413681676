import React, { createContext, useReducer } from 'react';

import { DataStoreState } from '.';
import { DataStoreAction, initialStoreState, RootReducer } from './reducer';

interface StoreContextInterface {
  dispatch: (action: DataStoreAction) => void;
  store: DataStoreState;
}

export const StoreContext = createContext<StoreContextInterface>({
  dispatch: () => {
    throw new Error('dispatch not implemented');
  },
  store: {} as DataStoreState,
});

export const Store: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [store, dispatch] = useReducer(RootReducer, initialStoreState);
  const dataContextApi = {
    dispatch,
    store,
  };

  return (
    <StoreContext.Provider value={dataContextApi}>
      {children}
    </StoreContext.Provider>
  );
};
