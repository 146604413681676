export const formatClimateUnit = (
  number: number,
  // the kilogram unit just serves to truncate the values with the suffix K (thousand), M (million)
  unit: 'pound' | 'mile' | 'kilogram',
  unitDisplay?: 'short' | 'long',
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: unit,
    unitDisplay: unitDisplay ?? 'short',
    localeMatcher: 'best fit',
    notation: 'compact',
    maximumFractionDigits: 1,
  }).format(number);
};
