import React, { FunctionComponent } from 'react';

export interface SvgProps {
  color?: string;
}

export const InfoSvg: FunctionComponent<SvgProps> = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="38" height="38" rx="19" fill="#E1EDF1" />
      <path
        d="M18.084 23.583H19.9173V18.083H18.084V23.583ZM19.0007 16.2497C19.2604 16.2497 19.4782 16.1617 19.6542 15.9857C19.8296 15.8103 19.9173 15.5927 19.9173 15.333C19.9173 15.0733 19.8296 14.8554 19.6542 14.6794C19.4782 14.504 19.2604 14.4163 19.0007 14.4163C18.7409 14.4163 18.5234 14.504 18.348 14.6794C18.172 14.8554 18.084 15.0733 18.084 15.333C18.084 15.5927 18.172 15.8103 18.348 15.9857C18.5234 16.1617 18.7409 16.2497 19.0007 16.2497ZM19.0007 28.1663C17.7326 28.1663 16.5409 27.9256 15.4257 27.444C14.3104 26.9631 13.3402 26.3101 12.5152 25.4851C11.6902 24.6601 11.0373 23.69 10.5563 22.5747C10.0748 21.4594 9.83398 20.2677 9.83398 18.9997C9.83398 17.7316 10.0748 16.54 10.5563 15.4247C11.0373 14.3094 11.6902 13.3393 12.5152 12.5143C13.3402 11.6893 14.3104 11.036 15.4257 10.5544C16.5409 10.0735 17.7326 9.83301 19.0007 9.83301C20.2687 9.83301 21.4604 10.0735 22.5757 10.5544C23.6909 11.036 24.6611 11.6893 25.4861 12.5143C26.3111 13.3393 26.964 14.3094 27.445 15.4247C27.9265 16.54 28.1673 17.7316 28.1673 18.9997C28.1673 20.2677 27.9265 21.4594 27.445 22.5747C26.964 23.69 26.3111 24.6601 25.4861 25.4851C24.6611 26.3101 23.6909 26.9631 22.5757 27.444C21.4604 27.9256 20.2687 28.1663 19.0007 28.1663ZM19.0007 26.333C21.0479 26.333 22.7819 25.6226 24.2027 24.2018C25.6236 22.7809 26.334 21.0469 26.334 18.9997C26.334 16.9525 25.6236 15.2184 24.2027 13.7976C22.7819 12.3768 21.0479 11.6663 19.0007 11.6663C16.9534 11.6663 15.2194 12.3768 13.7986 13.7976C12.3777 15.2184 11.6673 16.9525 11.6673 18.9997C11.6673 21.0469 12.3777 22.7809 13.7986 24.2018C15.2194 25.6226 16.9534 26.333 19.0007 26.333Z"
        fill="#6BA4B8"
      />
    </svg>
  );
};
