import LogRocket from 'logrocket';

import { ACCOUNT_BLACKLIST } from './account-blacklist';

let isInitialized = false;

export function useLogRocket() {
  return { LogRocket };
}

export function initLogRocket(authedUserEmail: string) {
  const onBlackList = ACCOUNT_BLACKLIST.includes(authedUserEmail);
  if (!isInitialized && !onBlackList) {
    LogRocket.init('ls0o0d/therma');
    isInitialized = true;
  }
}
