import { removeDecimalPoint } from '@coinspect/utils';
import React, { Fragment } from 'react';

import {
  AlertNotifConfigPayload,
  AlertType,
  NormalAlertModel,
} from '../../hooks';
import { TempUnits } from '../../services';
import { NormalEntity } from '../../store';
import { convertToPreferredUnit } from '../../utils/temperature-util';
import { getEquipmentTriggerValue } from '../alert/alert-config';
import { EquipmentPayloadData } from '../equipment/equipment-service';
import {
  ALERT_TYPES_ARR,
  COMPARATOR_ARR,
  determineTriggerValue,
} from './equipment-alert-config-utils';

export const createComparatorHeadingElem = (
  comparator: string,
  alertType: AlertType,
  forceBold = false,
) => {
  return (
    <span
      className={
        alertType === 'temperature' || forceBold ? 'text-bold break-all' : ''
      }
      key={'comparator' + comparator}
    >
      {comparator === '<' ? 'below' : 'above'}&nbsp;
    </span>
  );
};

export const createTempHeadingElem = (
  value: number,
  preferredTempUnit: TempUnits,
) => {
  const convertedValue = convertToPreferredUnit(
    Number(value),
    'temperature',
    preferredTempUnit,
  );
  return (
    <span key={'temp-heading'}>
      {convertedValue && removeDecimalPoint(convertedValue)}
      &deg;{`${preferredTempUnit.toUpperCase()} `}
    </span>
  );
};

export const createHumidHeadingElem = (value: number, isLast = false) => {
  return (
    <React.Fragment key={'humid-heading'}>
      {value}&#37;&nbsp;
      {isLast && <span className="text-bold break-all">humidity </span>}
    </React.Fragment>
  );
};

/**
 * This is a helper function to determine which values
 * to display on the alert list heading
 * when creating alert for equipment based
 */
export const readableEquipmentAlertHeading = (
  equipmentData: EquipmentPayloadData,
  alertFields: AlertNotifConfigPayload,
  preferredTempUnit: TempUnits = 'f',
  isEditing = false,
  alertConfigUUID: string,
  alertConfigs?: NormalEntity<NormalAlertModel>,
): Array<[boolean, JSX.Element]> => {
  const { triggerComparator } = alertFields;
  let { alertType = 'temperature' } = alertFields;
  if (alertFields.field === 'humidity') {
    alertType = alertFields.field;
  }
  let returnArr: Array<[boolean, JSX.Element]> = [];

  if (isEditing) {
    const editingAlertConfig = alertConfigs?.byUUID[alertConfigUUID];
    const value = getEquipmentTriggerValue(editingAlertConfig, equipmentData);
    const editTriggerComparator = editingAlertConfig?.triggerComparator as string;
    return [
      [
        true,
        (() => {
          return (
            <>{createComparatorHeadingElem(editTriggerComparator, alertType)}</>
          );
        })(),
      ],
      [
        true,
        (() => {
          return (
            <Fragment key={'triggerValue' + value}>
              {alertType === 'temperature' && (
                <>{createTempHeadingElem(value as number, preferredTempUnit)}</>
              )}
              {alertType === 'humidity' && (
                <>{createHumidHeadingElem(value as number, true)}</>
              )}
              for&nbsp;
            </Fragment>
          );
        })(),
      ],
    ];
  }

  const noMinTemp = !equipmentData.meta.temperatureMinThreshold;
  const noMinHumid = !equipmentData.meta.humidityMinThreshold;
  const noMaxHumid = !equipmentData.meta.humidityMaxThreshold;
  /**
   * above or below and temperature and humidity configuration
   * Example
   * - When Test is below 1°F or above 2°F or below 3% or above 4% humidity fr 30 minutes
   */
  if (alertType === 'both' && triggerComparator === '<>') {
    const minTemp = determineTriggerValue(equipmentData, '<', 'temperature');
    const maxTemp = determineTriggerValue(equipmentData, '>', 'temperature');
    const minHumid = determineTriggerValue(equipmentData, '<', 'humidity');
    const maxHumid = determineTriggerValue(equipmentData, '>', 'humidity');

    returnArr = [
      [true, <>{createComparatorHeadingElem('<', 'temperature')}</>],
      [
        true,
        <>
          <>{createTempHeadingElem(minTemp as number, preferredTempUnit)}</>
          or&nbsp;
        </>,
      ],
      [true, <>{createComparatorHeadingElem('>', 'temperature')}</>],
      [
        true,
        <>
          <>{createTempHeadingElem(maxTemp as number, preferredTempUnit)}</>
          or&nbsp;
        </>,
      ],
      [true, <>{createComparatorHeadingElem('<', 'humidity')}</>],
      [
        true,
        <>
          <>{createHumidHeadingElem(minHumid as number, noMaxHumid)}</>
          {noMaxHumid ? 'for' : 'or'}&nbsp;
        </>,
      ],
      [true, <>{createComparatorHeadingElem('>', 'humidity')}</>],
      [
        true,
        <>
          <>{createHumidHeadingElem(maxHumid as number, true)}</>
          for&nbsp;
        </>,
      ],
    ];

    if (noMinTemp) {
      returnArr.splice(0, 2);
    }

    if (noMinHumid) {
      returnArr.splice(returnArr.length - 4, 2);
    }

    if (noMaxHumid) {
      returnArr.splice(returnArr.length - 2, 2);
    }

    return returnArr;
  }

  /**
   * above or below configuration
   * Example:
   * - When Test is below 1°F or above 2°F for 30 minutes
   * - When Test is below 3% or above 4% humidity for 30 minutes
   */
  if (triggerComparator === '<>') {
    const [belowComparator, aboveComparator] = COMPARATOR_ARR.map(
      (comparator: string) => {
        return <>{createComparatorHeadingElem(comparator, alertType)}</>;
      },
    );
    const [belowValueFragment, aboveValueFragment] = COMPARATOR_ARR.map(
      (comparator: string, idx) => {
        const value = determineTriggerValue(
          equipmentData,
          comparator,
          alertType,
        );
        return (
          <Fragment key={'triggerValue' + value}>
            {alertType === 'temperature' && (
              <>{createTempHeadingElem(value as number, preferredTempUnit)}</>
            )}
            {alertType === 'humidity' && (
              <>
                {createHumidHeadingElem(
                  value as number,
                  idx === 1 || noMaxHumid,
                )}
              </>
            )}
            {orVsFor(
              COMPARATOR_ARR,
              idx,
              noMaxHumid && alertType === 'humidity' ? 'for' : undefined,
            )}
            &nbsp;
          </Fragment>
        );
      },
    );
    returnArr = [
      [true, belowComparator],
      [true, belowValueFragment],
      [true, aboveComparator],
      [true, aboveValueFragment],
    ];

    if (noMinTemp && alertType === 'temperature') {
      returnArr.splice(0, 2);
    }

    if (noMinHumid && alertType === 'humidity') {
      returnArr.splice(0, 2);
    }

    if (noMaxHumid && alertType === 'humidity') {
      returnArr.splice(2, 2);
    }

    return returnArr;
  }

  /**
   * temp and humid configuration
   * Example:
   * - When Test is above 2°F or above 4% humidity for 30 minutes
   * - When Test is below 1°F or below 3% humidity for 30 minutes
   */
  if (alertType === 'both') {
    const [tempComparator, humidComparator] = ALERT_TYPES_ARR.map((type) => {
      return <>{createComparatorHeadingElem(triggerComparator, type)}</>;
    });
    const [tempValueFragment, humidValueFragment] = ALERT_TYPES_ARR.map(
      (type: string, idx) => {
        const value = determineTriggerValue(
          equipmentData,
          triggerComparator,
          type as AlertType,
        );
        return (
          <Fragment key={'triggerValue' + value}>
            {type === 'temperature' && (
              <>{createTempHeadingElem(value as number, preferredTempUnit)}</>
            )}
            {type === 'humidity' && (
              <>{createHumidHeadingElem(value as number, true)}</>
            )}
            {orVsFor(ALERT_TYPES_ARR, idx, noMaxHumid ? 'for' : undefined)}
            &nbsp;
          </Fragment>
        );
      },
    );
    returnArr = [
      [true, tempComparator],
      [true, tempValueFragment],
      [true, humidComparator],
      [true, humidValueFragment],
    ];

    if (noMaxHumid) {
      returnArr.splice(2, 2);
    }

    return returnArr;
  }

  /**
   * Normal configuration
   * Example:
   * - When Test is above 2°F for 30 minutes
   * - When Test is below 1°F for 30 minutes
   * - When Test is above 4% humidity for 30 minutes
   * - When Test is below 3% humidity for 30 minutes,
   */
  return [
    [
      true,
      (() => {
        /**
         * * comparator needs to be bold for temperature
         * * force the text to be bold if above humidity alert but no max humidity value
         */
        if (
          triggerComparator === '>' &&
          alertType === 'humidity' &&
          noMaxHumid
        ) {
          return (
            <>
              {createComparatorHeadingElem(triggerComparator, alertType, true)}
            </>
          );
        }
        return <>{createComparatorHeadingElem(triggerComparator, alertType)}</>;
      })(),
    ],
    [
      true,
      (() => {
        /**
         * * override if above humidity alert type but no max humidity value
         * * just show temperature
         */
        if (
          triggerComparator === '>' &&
          alertType === 'humidity' &&
          noMaxHumid
        ) {
          const maxTemp = equipmentData.meta.temperatureMaxThreshold;
          return (
            <Fragment key={'triggerValue' + maxTemp}>
              <>{createTempHeadingElem(maxTemp as number, preferredTempUnit)}</>
              for&nbsp;
            </Fragment>
          );
        }
        const value = determineTriggerValue(
          equipmentData,
          triggerComparator,
          alertType,
        );
        return (
          <Fragment key={'triggerValue' + value}>
            {alertType === 'temperature' && (
              <>{createTempHeadingElem(value as number, preferredTempUnit)}</>
            )}
            {alertType === 'humidity' && (
              <>{createHumidHeadingElem(value as number, true)}</>
            )}
            for&nbsp;
          </Fragment>
        );
      })(),
    ],
  ];
};

function orVsFor(array: unknown[], idx: number, bypass?: 'or' | 'for') {
  if (bypass) return bypass;
  return idx === array.length - 1 ? 'for' : 'or';
}

export function getNewAlertText(
  equipmentData: EquipmentPayloadData,
  isEditing: boolean,
  alertFields: AlertNotifConfigPayload,
  preferredTempUnit: TempUnits,
  alertConfigs?: NormalEntity<NormalAlertModel>,
  alertConfigUUID?: string,
) {
  return readableEquipmentAlertHeading(
    equipmentData as EquipmentPayloadData,
    isEditing
      ? {
          /**
           * When editing equipment alert config
           * The alertType is not on load, but will be populated when editing already
           */
          ...alertFields,
          alertType: alertFields.alertType ?? alertFields.field,
        }
      : { ...alertFields },
    preferredTempUnit,
    isEditing,
    alertConfigUUID as string,
    alertConfigs,
  );
}
