import { genericRelationshipReducer } from '../utils';

export const alertNotifConfigRelationshipReducer = genericRelationshipReducer(
  ['notifConfig', 'alertConfigUUID'],
  ['alertConfig'],
);

export const deviceAlertConfigsRelationshipReducer = genericRelationshipReducer(
  ['alertConfig', 'deviceUUID'],
  ['device'],
);

export const locationDeviceRelationshipReducer = genericRelationshipReducer(
  ['device', 'locationUUID'],
  ['location'],
);

export const locationEquipmentRelationshipReducer = genericRelationshipReducer(
  ['equipment', 'locationUUID'],
  ['location'],
);

export const equipmentDevicesRelationshipReducer = genericRelationshipReducer(
  ['device', 'equipmentUUID'],
  ['equipment'],
);
