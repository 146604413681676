import {
  Schedule,
  ScheduleService,
} from '../../../../services/schedule-service';

type EnergyLocation = {
  companyUUID: string;
  energyLocationUUID: string;
  uuid: string;
};

export const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;

const MIDNIGHT_HOUR = 0;
const NOON_HOUR = 12;

export interface FormattedSchedule extends Schedule {
  closedHoursString: string;
  isOpen: boolean;
  name: string;
  timeOpen: number;
  twelveHourEnd: string;
  twelveHourStart: string;
}

export interface FormScheduleData {
  MondayStartTime: string;
  MondayEndTime: string;
  TuesdayStartTime: string;
  TuesdayEndTime: string;
  WednesdayStartTime: string;
  WednesdayEndTime: string;
  ThursdayStartTime: string;
  ThursdayEndTime: string;
  FridayStartTime: string;
  FridayEndTime: string;
  SaturdayStartTime: string;
  SaturdayEndTime: string;
  SundayStartTime: string;
  SundayEndTime: string;
  MondayOpen: string;
  TuesdayOpen: string;
  WednesdayOpen: string;
  ThursdayOpen: string;
  FridayOpen: string;
  SaturdayOpen: string;
  SundayOpen: string;
  day: number;
  type: string;
}

const getNumHour = (time: string): [number, string] => {
  const [hour, minute] = time.split(':');

  return [Number(hour), String(minute)];
};

export const convertTo12Hour = (time?: string): string | undefined => {
  if (!time) {
    return;
  }

  const [numHour, numMinute] = getNumHour(time);
  if (numHour > NOON_HOUR) {
    return `${numHour - NOON_HOUR}:${numMinute}PM`;
  } else if (numHour === NOON_HOUR) {
    return `12:${numMinute}PM`;
  } else if (numHour === MIDNIGHT_HOUR) {
    return `12:${numMinute}AM`;
  }

  return `${numHour}:${numMinute}AM`;
};

const calculateTimeOpen = (start?: string, end?: string): number => {
  const NO_HOURS = 0;

  if (!start || !end) {
    return NO_HOURS;
  }
  const openHours = getNumHour(end)[0] - getNumHour(start)[0];
  return openHours > NO_HOURS ? openHours : NO_HOURS;
};

const calculateCarryOver = (
  start?: string,
  end?: string,
): string | undefined => {
  if (!start || !end) {
    return undefined;
  }

  return getNumHour(end)[0] < getNumHour(start)[0] ? end : undefined;
};

const calculateClosedString = (
  start?: string,
  end?: string,
  newStart?: string,
): string => {
  if ((!start || !end) && newStart) {
    const newStartTime = convertTo12Hour(newStart);
    return `${newStartTime} - 11:59PM`;
  } else if ((!start || !end) && !newStart) {
    return 'Closed';
  }

  const startTime = convertTo12Hour(start);
  const endTime = convertTo12Hour(end);
  const newStartTime = convertTo12Hour(newStart);

  //time to start time
  const toStart =
    startTime === '12:00AM'
      ? ''
      : `${newStartTime || '12:00AM'} - ${startTime}`;
  //time from end time
  const toEnd =
    endTime === '12:00AM' || getNumHour(end)[0] < getNumHour(start)[0]
      ? ''
      : `${endTime} - 11:59PM`;

  return toStart || toEnd ? `${toStart} ${toEnd}` : '--';
};

export const formatSchedules = (scheduleData: FormScheduleData) => {
  let schedules = [
    {
      startTime: scheduleData.MondayStartTime,
      endTime: scheduleData.MondayEndTime,
      day: 1,
      type: 'weekly',
    },
    {
      startTime: scheduleData.TuesdayStartTime,
      endTime: scheduleData.TuesdayEndTime,
      day: 2,
      type: 'weekly',
    },
    {
      startTime: scheduleData.WednesdayStartTime,
      endTime: scheduleData.WednesdayEndTime,
      day: 3,
      type: 'weekly',
    },
    {
      startTime: scheduleData.ThursdayStartTime,
      endTime: scheduleData.ThursdayEndTime,
      day: 4,
      type: 'weekly',
    },
    {
      startTime: scheduleData.FridayStartTime,
      endTime: scheduleData.FridayEndTime,
      day: 5,
      type: 'weekly',
    },
    {
      startTime: scheduleData.SaturdayStartTime,
      endTime: scheduleData.SaturdayEndTime,
      day: 6,
      type: 'weekly',
    },
    {
      startTime: scheduleData.SundayStartTime,
      endTime: scheduleData.SundayEndTime,
      day: 7,
      type: 'weekly',
    },
  ];

  if (!scheduleData.MondayOpen) {
    schedules = schedules.filter((schedule) => schedule.day !== 1);
  }
  if (!scheduleData.TuesdayOpen) {
    schedules = schedules.filter((schedule) => schedule.day !== 2);
  }
  if (!scheduleData.WednesdayOpen) {
    schedules = schedules.filter((schedule) => schedule.day !== 3);
  }
  if (!scheduleData.ThursdayOpen) {
    schedules = schedules.filter((schedule) => schedule.day !== 4);
  }
  if (!scheduleData.FridayOpen) {
    schedules = schedules.filter((schedule) => schedule.day !== 5);
  }
  if (!scheduleData.SaturdayOpen) {
    schedules = schedules.filter((schedule) => schedule.day !== 6);
  }
  if (!scheduleData.SundayOpen) {
    schedules = schedules.filter((schedule) => schedule.day !== 7);
  }

  return schedules;
};

export const fetchFormattedSchedule = async (
  setScheduleData: (schedule: FormattedSchedule[]) => void,
  energyLocation = {},
): Promise<FormattedSchedule[] | undefined> => {
  const { companyUUID, uuid } = energyLocation as EnergyLocation;

  if (!companyUUID || !uuid) {
    return;
  }

  const { data } = await ScheduleService.browseSchedules({
    companyUUID,
    locationUUID: uuid,
  });

  const results = WEEKDAYS.map((day, index) => {
    const schedule =
      data.find((entry: Schedule) => entry.day === index + 1) ||
      ({} as Schedule);

    const prevSchedule =
      data.find(
        (entry: Schedule) => entry.day === (index || WEEKDAYS.length),
      ) || ({} as Schedule);

    // Determine if yesterday's closing time was actually today (i.e. 5PM - 2AM)
    const carryOver = calculateCarryOver(
      prevSchedule.startTime,
      prevSchedule.endTime,
    );

    return {
      closedHoursString: calculateClosedString(
        schedule.startTime,
        schedule.endTime,
        carryOver,
      ),
      isOpen: schedule.state === 'occupied',
      name: day,
      timeOpen: calculateTimeOpen(schedule.startTime, schedule.endTime),
      twelveHourEnd: convertTo12Hour(schedule.endTime),
      twelveHourStart: convertTo12Hour(schedule.startTime),
      ...schedule,
    };
  });

  setScheduleData(results as FormattedSchedule[]);

  return results as FormattedSchedule[];
};
