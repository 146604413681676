import { formatNumber, FormDropdown, FormInput, Ocean25 } from '@coinspect/ui';
import React, { useContext, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { DropdownItemProps, Table } from 'semantic-ui-react';
import styled, { createGlobalStyle } from 'styled-components';

import { StandardModalContext } from '../../../contexts';
import { useInviteService } from '../../../hooks';
import { Invite, Role } from '../../../services';
import { StoreContext } from '../../../store';
import StandardModalBody from '../../modals/standard-modal-body';
import { InviteModes, ROLE_OPTIONS } from './index';
import { TruncatedDropdown } from './truncated-dropdown';

type BulkInviteTableProps = {
  inviteList: string;
  inviteType: InviteModes;
  onCancel?: (data: string) => void;
};

export type BulkInviteForm = {
  email?: string;
  phoneNumber?: string;
  type: InviteModes;
  role: Role;
  teams?: string[];
};

const recipient = {
  sms: 'phoneNumber',
  email: 'email',
};

const tableHeaderText = {
  sms: 'Number',
  email: 'Email',
};

const TableHeader = styled(Table.HeaderCell)`
  font-size: 18px;
  background-color: ${Ocean25} !important;
`;

// just to align the arrow properly
const StyledFormDropdown = styled(FormDropdown)`
  i {
    vertical-align: text-bottom;
  }
  padding-right: 0px;
`;

const StyledTableCell = styled(Table.Cell)`
  border-top: 1px solid ${Ocean25} !important;
  border-bottom: 1px solid ${Ocean25} !important;

  &:first-child {
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &&& {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

const StyledTableHeader = styled(TableHeader)`
  &&&&& {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 0px;
  }
`;

const TruncatedDropdownStyle = createGlobalStyle`
  .dropdown.dropdown.dropdown .truncated-dropdown-bg {
    background-color: rgba(0,0,0,0);
    padding: 0;
  }

  .dropdown.dropdown.dropdown .truncated-dropdown-label {
    max-width: 76px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    vertical-align: text-bottom;
  }
`;

export const BulkInviteTable: React.FC<BulkInviteTableProps> = (props) => {
  const { inviteList, inviteType, onCancel } = props;
  const newInviteList = inviteList;
  const { hideModal } = useContext(StandardModalContext);
  const { store } = useContext(StoreContext);
  const { addInvite } = useInviteService();
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<{ invites: Invite[] }>({
    mode: 'onChange',
  });

  const { teams: teamsEntity } = store.entities;
  const teamOptions = [
    // {
    //   text: 'None',
    //   value: 'none',
    // },
    ...teamsEntity.all
      .map((teamUUID: string) => {
        const team = teamsEntity.byUUID[teamUUID];
        return {
          text: team.name,
          value: team.uuid,
        };
      })
      .sort((a: DropdownItemProps, b: DropdownItemProps) => {
        return (a.text as string)
          .toLowerCase()
          .localeCompare((b.text as string).toLowerCase());
      }),
  ];

  const handleFormSubmit = async (data: { invites: Invite[] }) => {
    const { invites } = data;
    let smsInvites: Invite[] = [];

    // only need to manipulate data when sms
    if (inviteType === 'sms') {
      smsInvites = invites.map((val: Invite) => {
        const formatted = formatNumber(val.phoneNumber as string, true);
        const countryCode = formatted.split(' ')[0];
        const phoneNumber = formatted.split(countryCode)[1].trim();
        return {
          ...val,
          countryCode,
          phoneNumber,
        };
      });
    }

    setIsLoading(true);
    try {
      await addInvite(inviteType === 'email' ? invites : smsInvites);
      hideModal();
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <StandardModalBody>
      <StandardModalBody.Content>
        <TruncatedDropdownStyle />
        <Table
          style={{
            borderColor: Ocean25,
            borderCollapse: 'collapse',
          }}
        >
          <Table.Header>
            <Table.Row>
              <StyledTableHeader style={{ width: '30%' }}>
                {tableHeaderText[inviteType]}
              </StyledTableHeader>
              <StyledTableHeader style={{ width: '50%' }}>
                Team{' '}
                <span style={{ fontWeight: 'normal', fontSize: '13px' }}>
                  (optional)
                </span>
              </StyledTableHeader>
              <StyledTableHeader style={{ width: '20%' }}>
                Role
              </StyledTableHeader>
            </Table.Row>
          </Table.Header>

          <FormContext {...methods}>
            <Table.Body>
              {newInviteList.split(',').map((invite: string, idx: number) => {
                return (
                  <Table.Row key={idx}>
                    <StyledTableCell style={{ width: '30%' }}>
                      {inviteType === 'email'
                        ? invite
                        : formatNumber(invite, true)}
                      {/* to register the value to form object */}
                      <FormInput
                        type="hidden"
                        name={`invites[${idx}][${recipient[inviteType]}]`}
                        defaultValue={invite}
                        style={{ display: 'none' }}
                      />
                      {/* to register invite type to form object */}
                      <FormInput
                        type="hidden"
                        name={`invites[${idx}].type`}
                        defaultValue={inviteType}
                        style={{ display: 'none' }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <TruncatedDropdown
                        name={`invites[${idx}].teams`}
                        options={teamOptions}
                        placeholder="None"
                        defaultValue={[]}
                        disabled={isLoading}
                        isScrollableMenu={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledFormDropdown
                        name={`invites[${idx}].role`}
                        options={ROLE_OPTIONS}
                        required
                        // selection
                        value="user"
                        compact
                        disabled={isLoading}
                      />
                    </StyledTableCell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </FormContext>
        </Table>
      </StandardModalBody.Content>
      <StandardModalBody.Actions
        primaryButton={{
          content: 'Invite',
          onClick: methods.handleSubmit((data) => handleFormSubmit(data)),
          disabled: isLoading,
        }}
        secondaryButton={{
          content: 'Cancel',
          onClick: () => {
            onCancel?.(inviteList);
          },
          disabled: isLoading,
        }}
      />
    </StandardModalBody>
  );
};
