import { MIXPANEL_TOKEN } from '@coinspect/utils';
import mixpanel from 'mixpanel-browser';
import React, { FunctionComponent, useContext, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-less/semantic.less';
import { Button } from 'semantic-ui-react';

import ErrorBoundary from '../src/components/error-boundary/error-boundary';
import { BasePage } from './components/base/base-page';
import {
  Account,
  AccountContext,
  Axios,
  JoyrideProvider,
  LocalStorage,
  Modal,
  SlidingSidebar,
  StandardizedModal,
} from './contexts';
import { MediaContextProvider } from './contexts/app-media';
import * as localStorage from './hooks/use-local-storage';
import { Store } from './store/store-context';
import './style/custom-fonts.less';
import './style/energy-account-modal.less';
import { GlobalStyles } from './style/global-styles';
import './style/system-icons.less';
import './style/utility.less';

declare global {
  interface Window {
    setDevUtils: (isOn: boolean) => void;
    setEnableFeature: (featureName: string, enable: boolean) => void;
  }
}

interface AccountParams {
  display: string;
  email: string;
  pass: string;
}

export const TEST_USERS = [
  {
    display: 'Wile E.',
    email: 'wilecoyote@coinspectapp.com',
    pass: 'coyote',
  },
  {
    display: 'Road',
    email: 'testroad+success@simulator.amazonses.com',
    pass: 'testroad',
  },
  {
    display: 'Tweety',
    email: 'success@simulator.amazonses.com',
    pass: 'tweetybird',
  },
  {
    display: 'Jessica Pearson',
    email: 'testpearson+success@simulator.amazonses.com',
    pass: 'suitspearson',
  },
  {
    display: 'Harvey Specter',
    email: 'testspecter+success@simulator.amazonses.com',
    pass: 'suitsspecter',
  },
  {
    display: 'Louis Litt',
    email: 'testlitt+success@simulator.amazonses.com',
    pass: 'suitslitt',
  },
  {
    display: 'Mike Ross',
    email: 'testross+success@simulator.amazonses.com',
    pass: 'suitsross',
  },
  {
    display: 'Gustavo Fring',
    email: 'testgus+success@simulator.amazonses.com',
    pass: 'breakingbadgus',
  },
];

const DevAuth: FunctionComponent = () => {
  if (process.env.STAGE === 'development') {
    const account = useContext(AccountContext);
    const [isVisible, setIsVisible] = useState(
      Boolean(process.env.DEV_UTILS_ENABLE || false),
    );
    const { user, isInitializing } = account;

    window.setDevUtils = (isOn = false): void => {
      setIsVisible(isOn);
    };

    const setTestUser = async (
      email: string,
      password: string,
    ): Promise<void> => {
      account.authorizeEmailPassword(email, password);
    };

    if (!isVisible) {
      return null;
    }

    const clearToken = (): void => {
      account.logout();
    };

    if (isInitializing) {
      return null;
    }

    const seedButtons = (): JSX.Element => {
      return (
        <>
          {TEST_USERS.map(
            (acct: AccountParams, idx: number): JSX.Element => (
              <Button
                key={idx}
                type="button"
                onClick={(): Promise<void> =>
                  setTestUser(acct.email, acct.pass)
                }
                content={acct.display}
                basic
              />
            ),
          )}
        </>
      );
    };

    return (
      <div>
        {!user && seedButtons()}
        {user && (
          <Button type="button" onClick={clearToken} content="Logout" basic />
        )}
      </div>
    );
  }

  return null;
};

export const App: React.FunctionComponent = () => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  const isRedirect = url.searchParams.get('redirect');
  mixpanel.init(process.env.MIXPANEL_TOKEN ?? MIXPANEL_TOKEN, {
    debug: process.env.STAGE === 'development',
    ignore_dnt: false,
  });

  // For redirecting back to wp-checkout page for upselling
  if (isRedirect) {
    localStorage.setItem('redirectToCheckout', 'true');
  }
  return (
    <ErrorBoundary>
      <GlobalStyles />
      <MediaContextProvider>
        <Axios>
          <Router>
            <Store>
              <LocalStorage>
                <StandardizedModal>
                  <Modal>
                    <JoyrideProvider>
                      <Account>
                        <Modal>
                          <StandardizedModal>
                            <DevAuth />

                            <SlidingSidebar>
                              <ToastContainer />
                              <BasePage />
                            </SlidingSidebar>
                          </StandardizedModal>
                        </Modal>
                      </Account>
                    </JoyrideProvider>
                  </Modal>
                </StandardizedModal>
              </LocalStorage>
            </Store>
          </Router>
        </Axios>
      </MediaContextProvider>
    </ErrorBoundary>
  );
};
