import { hexToRgbA, isValidNumber } from '@coinspect/utils';
import React, { FunctionComponent } from 'react';

interface ActiveDotProps {
  color: string;

  // from recharts
  dataKey?: string;
  cx?: number;
  cy?: number;
  mean?: number;
  hasSingleDataPoint: boolean;
}

function mouseEnter(
  e: React.MouseEvent<SVGCircleElement, MouseEvent>,
  color: string,
): void {
  const elem = e.target as HTMLElement;
  if (elem) {
    // do not show dot if no mean
    // mean is appended to the class because custom attribute doesn't work
    if (elem.classList.value.split('mean-')[1] === 'null') {
      return;
    }

    // active dot styling
    elem.style.strokeWidth = '11';
    elem.style.stroke = hexToRgbA(color, 0.4);

    elem.style.opacity = '1';
    const nextElem = elem.nextSibling as HTMLElement;
    if (nextElem) {
      nextElem.style.opacity = '1';
    }
  }
}

function mouseLeave(
  e: React.MouseEvent<SVGCircleElement, MouseEvent>,
  hasSingleDataPoint = false,
  color: string,
): void {
  const elem = e.target as HTMLElement;
  if (elem) {
    elem.style.opacity = '0';

    // reset to inactive dot styling for single data points
    if (hasSingleDataPoint) {
      elem.style.strokeWidth = '0';
      elem.style.stroke = hexToRgbA(color, 0.4);
      elem.style.opacity = '1';
    }
    const nextElem = elem.nextSibling as HTMLElement;
    if (nextElem) {
      nextElem.style.opacity = '0';
    }
  }
}

export const CustomActiveDot: FunctionComponent<ActiveDotProps> = (props) => {
  const {
    cx = 0,
    cy = 0,
    color = '#000',
    mean,
    hasSingleDataPoint = false,
  } = props;
  if (!isValidNumber(mean)) {
    return null;
  }
  return (
    <>
      <circle
        cursor="pointer"
        onMouseOver={(e) => mouseEnter(e, color)}
        onMouseOut={(e) => mouseLeave(e, hasSingleDataPoint, color)}
        r="6"
        fill={color}
        opacity={hasSingleDataPoint ? 1 : 0}
        className={`recharts-dot cx-${cx} cy-${cy} mean-${mean}`}
        cx={cx}
        cy={cy}
      />

      <g opacity={0} pointerEvents="none">
        <line
          x1={cx}
          y1={50}
          x2={cx}
          y2={cy - 8}
          style={{
            stroke: '#ccc',
            strokeWidth: 1,
          }}
        />
        <line
          x1={cx}
          y1={cy + 8}
          x2={cx}
          y2="320"
          style={{
            stroke: '#ccc',
            strokeWidth: 1,
          }}
        />
      </g>
    </>
  );
};
