import { daysAgoSent } from '@coinspect/utils';
import React from 'react';

import { InviteModel } from '../../services';

export const InviteTimer: React.FC<{ invite: InviteModel }> = (props) => {
  return (
    <p className="invite-timer" style={{ fontSize: '10px' }}>
      {daysAgoSent(String(props.invite.updatedAt))}
    </p>
  );
};
