import React, { FunctionComponent } from 'react';
import { Header } from 'semantic-ui-react';
import { tabletMaxWidth } from 'src/constants';
import styled from 'styled-components';

export interface SectionHeadingProps {
  heading: string;
  className?: string;
}

export const SectionHeadingBase: FunctionComponent<SectionHeadingProps> = (
  props,
) => {
  const { heading, children, className } = props;
  return (
    <div className={className}>
      <Header as="h1" className="section-header">
        {heading}
      </Header>
      {children}
    </div>
  );
};

export const SectionHeading = styled(SectionHeadingBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  .section-header {
    flex-grow: 1;
    margin: 0;
  }

  &&& {
    @media only screen and (max-width: ${tabletMaxWidth}px) {
      padding: 31px 25px;
      .section-header {
        font-size: 18px;
      }
    }
  }
`;
