import React, { FC, useContext } from 'react';
import { Button, StrictButtonProps } from 'semantic-ui-react';

import { ModalContext } from '../../contexts';

export interface ContactSalesModalProps {
  onClick: StrictButtonProps['onClick'];
}

export const ContactSalesModal: FC<ContactSalesModalProps> = ({ onClick }) => {
  const { openModal } = useContext(ModalContext);

  return (
    <Button
      onClick={(event, data) => {
        if (onClick) {
          onClick(event, data);
        }
        openModal({
          closeIcon: true,
          content: (
            <>
              <p>
                Add more sensors to existing locations or order hardware for a
                new location by contacting our sales team below.
              </p>
              <a href="mailto:sales@glaciergrid.com?subject=Get more GlacierGrid">
                <Button primary content="Contact sales now" />
              </a>
            </>
          ),
          fullScreenOnMobile: true,
          hasModalActions: false,
          header: <>Get more GlacierGrid</>,
          size: 'small',
        });
      }}
      primary
      content="Buy More"
    />
  );
};
