import inflection from 'lodash-inflection';
import React from 'react';

export interface PaginationLoadMoreProps {
  processing?: boolean;
  currentTotal?: number;
  totalRecord?: number;
}

export function PaginationLoadMore(props: PaginationLoadMoreProps) {
  const { processing, currentTotal = 0, totalRecord = 0 } = props;

  return (
    <>
      {totalRecord > 0 && processing && (
        <div className="pagination-loadmore__loading-indicator">
          Loading more items
        </div>
      )}
      {totalRecord > 0 && !processing && (
        <div className="pagination-loadmore__page-indicator">{`Displaying ${inflection.pluralize(
          'item',
          currentTotal,
          true,
        )} out of ${totalRecord}`}</div>
      )}
    </>
  );
}

export default PaginationLoadMore;
