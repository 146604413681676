interface DataPoint {
  deviceUUID: string;
  mean: number;
  time: string;
  x: number;
  y: number | null;
}
interface GraphData {
  [key: string]: DataPoint[];
}

function dataPointBehind(data: DataPoint[], idx: number): DataPoint | null {
  let behindObj = null;
  for (let i = idx - 1; i > -1; i--) {
    if (data[i] && data[i].mean) {
      behindObj = data[i];
      break;
    }
  }

  return behindObj;
}

function dataPointAhead(data: DataPoint[], idx: number): DataPoint | null {
  let aheadObj = null;
  for (let i = idx + 1; i < data.length; i++) {
    if (data[i].mean) {
      aheadObj = data[i];
      break;
    }
  }

  return aheadObj;
}

function calculateForM(point1: DataPoint, point2: DataPoint): number | null {
  if (!point2.y || !point1.y) return null;
  return (point2.y - point1.y) / (point2.x - point1.x);
}

function calculateForB(point: DataPoint, m: number): number | null {
  if (!point.y) return null;
  return point.y - m * point.x;
}

function calculateForY(point: DataPoint, m: number, b: number): number {
  return m * point.x + b;
}

function findAllNullMeans(arr: DataPoint[]): DataPoint[] {
  // hold the behind and ahead obj of a null mean
  let behindObj = null;
  let aheadObj = null;
  // y=mx+b
  let b = null;
  let m = null; // slope

  return arr.map((curDataPoint: DataPoint, idx: number) => {
    const hasNoMean = !curDataPoint.mean;
    const unNullDataPoint = { ...curDataPoint };
    if (hasNoMean) {
      /**
       * if has no mean then check for the following
       * 1. has a valid data point behind
       * 2. has a valid data point ahead
       *
       * if both are true then proceed
       */

      behindObj = dataPointBehind(arr, idx);
      aheadObj = dataPointAhead(arr, idx);

      if (!behindObj || !aheadObj) {
        return unNullDataPoint;
      }
      /**
       * you get a value, they get a value
       * EVERYBODY GETS A VALUEEEEEE
       *
       * 1. store the behind and ahead values
       * these values will be used by the next null value
       * 2. use y=mx+b
       * 3. calculate for slope m
       * 4. calculate for b
       * 5. calculate for y
       */
      m = calculateForM(behindObj, aheadObj);
      if (m !== null) {
        b = calculateForB(behindObj, m);
        if (b !== null) {
          const y = calculateForY(curDataPoint, m, b);
          unNullDataPoint.y = y;
        }
      }
    } else {
      /**
       * If it has a mean then clear the slope related data
       * They will be populated on the next null mean point
       */
      behindObj = aheadObj = b = m = null;
    }

    return unNullDataPoint;
  });
}

export function connectNullData(graphData: GraphData): GraphData {
  const unNullGraphData: GraphData = {};
  Object.entries(graphData).forEach(([key, val]) => {
    unNullGraphData[key] = findAllNullMeans(val);
  });

  return unNullGraphData;
}
