import {
  ButtonContainer,
  FormDropdown,
  FormInput,
  grey25,
  PaddedContent,
  violetWine100,
  white100,
} from '@coinspect/ui';
import filter from 'lodash/filter';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { mobileMaxWidthPx } from '../../constants/responsive-constants';
import { useTeamService } from '../../hooks';
import { StoreContext } from '../../store';
import { FormCloseButton } from '../forms';

const DropdownContainer = styled.div`
  margin-bottom: 14px;
`;

const TeamFormContainer = styled.div`
  background: ${white100};
  box-shadow: 0 0 4px 0 ${grey25};
  border: 1px solid ${grey25};
  border-radius: 5px;
  margin-bottom: 10px;

  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    margin-bottom: 0;
    border-radius: 0;
  }
`;

const HeaderContainer = styled.div`
  padding: 30px 75px 0px;
  overflow-wrap: break-word;
  h3 {
    color: ${violetWine100};
  }
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    padding: 30px 30px 0px;
  }
  position: relative;
`;

interface TeamFormProps {
  onCancel?: () => void;
  onSubmit: (fields: TeamFormFields) => Promise<void>;
  team?: TeamFormFields;
}

interface TeamFormFields {
  locations: string[];
  members: string[];
  name: string;
  uuid?: string;
}

const teamFormDefaults = {
  locations: [],
  members: [],
  name: '',
};

export const TeamForm: FunctionComponent<TeamFormProps> = (props) => {
  const { onCancel, onSubmit, team } = props;
  const { store } = useContext(StoreContext);
  const { teams } = store.entities;
  const {
    locations: { allLocations },
  } = store.pages;
  const {
    users: { allUsers },
  } = store.pages;
  const { resetActiveForm } = useTeamService();

  useEffect(() => {
    return () => {
      resetActiveForm();
    };
  }, []);

  const userOptions = allUsers.all
    .map((userUUID: string) => {
      const user = allUsers.byUUID[userUUID];

      if (!user.signedUpDate) {
        return;
      }

      return {
        key: userUUID,
        text: `${user.firstName} ${user.lastName}`,
        value: userUUID,
      };
    })
    .filter(Boolean);

  const locationOptions = allLocations.all.map((locationUUID: string) => {
    const location = allLocations.byUUID[locationUUID];
    return {
      key: locationUUID,
      text: `${location.name}`,
      value: locationUUID,
    };
  });

  const defaultLocation =
    allLocations.all.length === 1 ? [allLocations.all[0]] : [];
  const methods = useForm<TeamFormFields>({
    defaultValues: team
      ? { ...team }
      : { ...teamFormDefaults, locations: defaultLocation },
    mode: 'onChange',
  });

  const [isSubmitInProgress, setSubmitInProgress] = useState<boolean>(false);

  const submitTeam = methods.handleSubmit(async (data) => {
    setSubmitInProgress(true);
    try {
      await onSubmit(data);
    } finally {
      resetActiveForm();
      setSubmitInProgress(false);
    }
  });

  function formIsAcceptable() {
    return methods.formState.isValid && methods.formState.dirty;
  }

  return (
    <TeamFormContainer className="team-form">
      <HeaderContainer>
        <h2 className="team-form__header">{team?.name || 'New Team'} </h2>
        <FormCloseButton
          onClick={onCancel}
          style={{ right: '32px', top: '30px' }}
        />
      </HeaderContainer>
      <PaddedContent withIndention size="45px 75px">
        <FormContext {...methods}>
          <Form onSubmit={submitTeam}>
            <FormInput
              className="team-form__team-name"
              name="name"
              label="Team name:"
              required
              customErrors={{
                required: 'Team name is required.',
                duplicateTeam: 'Team name must be unique',
              }}
              onBlur={() => {
                const input = methods.getValues({ nest: true }).name;
                const duplicateTeam = filter(
                  teams.byUUID,
                  (item) =>
                    item.name.toLowerCase() === input.toLowerCase() &&
                    item.uuid !== team?.uuid,
                );
                if (duplicateTeam.length) {
                  methods.setError('name', 'duplicateTeam');
                }
              }}
            />
            <DropdownContainer>
              <FormDropdown
                className="team-form__dropdown--location"
                label="Locations:"
                name="locations"
                options={locationOptions}
                size="big"
                selection
                multiple
                fluid
                search
                required
                customErrors={{
                  required: 'At least one location is required',
                }}
              />
            </DropdownContainer>
            <DropdownContainer>
              <FormDropdown
                className="team-form__dropdown--members"
                label="Team members:"
                name="members"
                options={userOptions}
                size="big"
                selection
                search
                multiple
                fluid
                required
                customErrors={{
                  required: 'At least one team member is required.',
                }}
              />
            </DropdownContainer>
            <ButtonContainer right>
              {onCancel && (
                <Button
                  className="team-form__button--cancel"
                  secondary
                  type="button"
                  content="Cancel"
                  onClick={onCancel}
                  disabled={isSubmitInProgress}
                />
              )}
              <Button
                className="team-form__button--submit"
                primary
                type="submit"
                content="Save"
                disabled={!formIsAcceptable() || isSubmitInProgress}
                loading={isSubmitInProgress}
              />
            </ButtonContainer>
          </Form>
        </FormContext>
      </PaddedContent>
    </TeamFormContainer>
  );
};
