import { darkViolet55 } from '@coinspect/ui';
import styled from 'styled-components';

export const ResendActivationContainer = styled.div`
  .resend-activation-form-header {
    padding-bottom: 2em;
    line-height: 2em;
  }
  .message-result {
    color: ${darkViolet55};
    font-size: 16px;
    line-height: 24px;
  }
  .message-default {
    padding-bottom: 20px;
    color: ${darkViolet55};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;
