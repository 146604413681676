import React, { FunctionComponent } from 'react';
import { Message } from 'semantic-ui-react';

interface ErrorProps {
  message?: string;
}

export const FailedMessage: FunctionComponent<ErrorProps> = (props) => {
  const { message } = props;

  return (
    <Message negative>
      <Message.Header>{message}</Message.Header>
    </Message>
  );
};
