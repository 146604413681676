import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { OutsideAlerter } from '../../hooks';

const DropdownMenuStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 12px;

  position: absolute;
  width: 337px;
  z-index: 10;
  right: -24px;
  top: -24px;

  background: linear-gradient(
      0deg,
      rgba(145, 208, 242, 0.08),
      rgba(145, 208, 242, 0.08)
    ),
    #121212;

  box-shadow: 0px 4px 8px 3px rgba(18, 18, 18, 0.15),
    0px 1px 3px rgba(18, 18, 18, 0.3);
  border-radius: 4px;
`;

interface PopupBoxProps {
  label: string;
  children: React.ReactNode;
  title: string;
  icon?: string;
  isShown?: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  popupBoxContainerStyle?: React.CSSProperties;
  popupBoxContentStyle?: React.CSSProperties;
}

export const PopupBox: React.FC<PopupBoxProps> = ({
  label,
  children,
  title,
  icon = 'icon-chevron-down',
  isShown = false,
  handleClose,
  handleOpen,
  popupBoxContainerStyle = {},
  popupBoxContentStyle = {},
}) => {
  return (
    <div className="relative" style={{ ...popupBoxContainerStyle }}>
      <label htmlFor="" className="cursor-pointer" onClick={handleOpen}>
        <span>
          {label}{' '}
          <Icon
            className={icon}
            style={{
              lineHeight: '20px',
              fontSize: '14px',
              marginLeft: '15px',
              verticalAlign: '-3px',
            }}
          />
        </span>
      </label>
      {isShown && (
        <OutsideAlerter outsideClickEvent={handleClose}>
          <DropdownMenuStyled
            className="absolute"
            style={{ ...popupBoxContentStyle }}
          >
            <div className="w-full flex space-between">
              <h2>{title}</h2>
              <Icon className="icon-cancel" onClick={handleClose} />
            </div>

            {children}
          </DropdownMenuStyled>
        </OutsideAlerter>
      )}
    </div>
  );
};
