import {
  black20,
  darkBlue80,
  FlexPaddedContent,
  GhostList,
  GhostListItem,
  thermaInputFocus,
  white100,
} from '@coinspect/ui';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import { Form, Icon, Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { tabletMaxWidthPx } from '../../constants';
import { TimePresets, useTimeFilter } from '../../hooks';
import { REPORT_SCHEDULE } from '../../services';
import { StoreContext } from '../../store';

const FlexDateContent = styled(FlexPaddedContent)`
  align-items: start;
`;

const FlexItem = styled.div`
  flex-grow: 1;

  &.full-width {
    width: 100%;
  }
`;

const TimeItem = styled(GhostListItem)`
  padding: 5px 10px;
`;

const FluidTimeFilter = styled(Input)`
  width: 100%;

  .bolder {
    font-weight: 700;
  }

  &&&.left > i.icon {
    font-size: 2em;
    left: -15px;
    opacity: 1;
  }

  input {
    cursor: pointer;
  }
`;

const PresetLabel = styled.span`
  color: ${darkBlue80};
  cursor: pointer;
  &.active {
    color: ${thermaInputFocus};
    font-weight: 700;
  }
`;

const DateRangeInputContainer = styled.div`
  position: relative;

  & input {
    cursor: pointer;
  }
`;

const DatePickerContainer = styled.div`
  position: absolute;
  margin: -14px 0 15px;
  z-index: 1;
  background: ${white100};
  width: 100%;
  border-radius: 3px;
  box-shadow: 1px 1px 15px ${black20};
  padding: 15px 5px;
  max-height: 400px;
  overflow-y: scroll;

  @media only screen and (max-width: ${tabletMaxWidthPx}) {
    max-height: 300px;
  }
`;

const DatePickerBg = styled.div`
  position: fixed;
  background: black;
  opacity: 0;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
`;

type ReportsTimeFilterProps = {
  hideLabel?: boolean;
  customPresets?: TimePresets;
  error?: boolean;
  selectedSchedule?: string;
  selectedLabelIndex?: number;
  className?: string;
};

// adjust presets to display in 3 equal columns if possible
const presetsToColumns = (len: number) => {
  const cols = len > 3 ? 3 : len;
  const numMaxRows = len % 3 === 0 ? 3 : len % 3;
  const first = len <= 3 ? 1 : Math.ceil(len / cols);
  const second =
    len <= 3 ? 2 : numMaxRows === 3 ? len - len / 3 : len - (first - 1);
  return [first, second];
};

export const ReportsTimeFilter: React.FC<ReportsTimeFilterProps> = (props) => {
  const {
    hideLabel,
    customPresets,
    error,
    selectedSchedule,
    selectedLabelIndex = -1,
  } = props;

  const { dispatch } = useContext(StoreContext);
  const { reportTimeFramePresets } = useTimeFilter();
  const timeframePresets = customPresets ?? reportTimeFramePresets();
  const presetsLen = timeframePresets.length;
  const [selectedIndex, setSelectedIndex] = useState<number>(
    selectedLabelIndex,
  );
  const [toFrom, setToFrom] = useState<string>('');
  const timeFrameLabel =
    timeframePresets.length <= selectedIndex || selectedIndex === -1
      ? ''
      : timeframePresets[selectedIndex].label;
  const [triggerLabel, setTriggerLabel] = useState<string>(
    selectedLabelIndex < 0 ? '' : timeFrameLabel,
  );
  const [showToFrom, setShowToFrom] = useState<boolean>(false);

  const [firstEnd, secondEnd] = presetsToColumns(presetsLen);
  const firstSet = timeframePresets.slice(0, firstEnd);
  const secondSet = timeframePresets.slice(firstEnd, secondEnd);
  const thirdSet = timeframePresets.slice(secondEnd);
  const [openDate, setOpenDate] = useState<boolean>(false);

  function close(): JSX.Element | void {
    setShowToFrom(false);
    setOpenDate(false);
  }

  // dispatch the selected range ex. last 24 hours, last 30 days,
  function onSelect(idx: number, label: string): void {
    setTriggerLabel(label);
    setSelectedIndex(idx);
    setToFrom('');
    close();
  }

  // dispatch the selected range to the context
  function handleDateRange(
    evt: SyntheticEvent<HTMLElement, Event>,
    { value }: { value: string },
  ): void {
    setToFrom(value);
    setSelectedIndex(-1);
    const [from, to] = value.split(' - ');
    if (from && to) {
      setTriggerLabel(`${from} - ${to}`);
      close();
    }
  }

  function dispatchStartEnd(start: string, end: string): void {
    dispatch({
      data: {
        end,
        labelIndex: selectedIndex,
        start,
      },
      type: 'reportForm:dateRange:set',
    });
  }

  function updateStartEndPreset(): void {
    let start = '';
    let end = '';

    if (timeframePresets.length > selectedIndex) {
      const { startDate, endDate } = timeframePresets[selectedIndex];
      start = startDate();
      end = endDate();
    }
    dispatchStartEnd(start, end);
  }

  useEffect(() => {
    if (selectedIndex >= 0) {
      updateStartEndPreset();
    } else {
      const [from, to] = toFrom.split(' - ');

      // to have empty dateRange on load
      if (!from || !to) {
        dispatchStartEnd('', '');
        return;
      }

      const start = new Date(from).toISOString();
      const unprocessedEnd = new Date(to);
      unprocessedEnd.setHours(23, 59, 59, 999);
      const end = unprocessedEnd.toISOString();
      dispatchStartEnd(start, end);
    }
  }, [triggerLabel]);

  function handleOpenDate() {
    setOpenDate(!openDate);
  }

  return (
    <>
      {openDate && <DatePickerBg onClick={() => setOpenDate(false)} />}
      <DateRangeInputContainer>
        <Form.Field>
          {!hideLabel && <label>Date range: *</label>}
          <Input
            icon="chevron down"
            type="text"
            name="dateRange"
            placeholder="Select date range"
            readOnly
            value={triggerLabel}
            onClick={handleOpenDate}
            error={error}
          />
        </Form.Field>
        {openDate && (
          <DatePickerContainer>
            <FlexDateContent size="0">
              <FlexItem>
                <GhostList>
                  {firstSet.map(({ label }, idx: number) => {
                    return (
                      <TimeItem key={idx} onClick={() => onSelect(idx, label)}>
                        <PresetLabel
                          className={selectedIndex === idx ? 'active' : ''}
                        >
                          {label}
                        </PresetLabel>
                      </TimeItem>
                    );
                  })}
                </GhostList>
              </FlexItem>
              <FlexItem>
                {secondSet.map(({ label }, idx: number) => {
                  idx += firstEnd;
                  return (
                    <TimeItem onClick={() => onSelect(idx, label)} key={idx}>
                      <PresetLabel
                        className={selectedIndex === idx ? 'active' : ''}
                      >
                        {label}
                      </PresetLabel>
                    </TimeItem>
                  );
                })}
              </FlexItem>
              <FlexItem>
                {thirdSet.map(({ label }, idx: number) => {
                  idx += secondEnd;
                  return (
                    <TimeItem onClick={() => onSelect(idx, label)} key={idx}>
                      <PresetLabel
                        className={selectedIndex === idx ? 'active' : ''}
                      >
                        {label}
                      </PresetLabel>
                    </TimeItem>
                  );
                })}
              </FlexItem>
            </FlexDateContent>

            {REPORT_SCHEDULE.ONCE === selectedSchedule && (
              <FlexPaddedContent size="20px 10px">
                <FlexItem className="full-width">
                  <FluidTimeFilter
                    value={toFrom}
                    iconPosition="left"
                    placeholder="From - To"
                    onClick={(): void => setShowToFrom(!showToFrom)}
                    readOnly
                  >
                    <Icon className="icon-calendar therma-blue" size="large" />
                    <input className="bolder" />
                  </FluidTimeFilter>
                  {showToFrom && (
                    <DatesRangeInput
                      maxDate={new Date()}
                      inline={true}
                      pickerStyle={{ minWidth: '20em' }}
                      name="datesRange"
                      dateFormat="MM/DD/YYYY"
                      placeholder="From - To"
                      value={toFrom}
                      iconPosition="left"
                      onChange={handleDateRange}
                    />
                  )}
                </FlexItem>
              </FlexPaddedContent>
            )}
          </DatePickerContainer>
        )}
      </DateRangeInputContainer>
    </>
  );
};
