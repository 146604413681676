import { FormInput } from '@coinspect/ui';
import mixpanel from 'mixpanel-browser';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FormContext, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Form, FormFieldProps } from 'semantic-ui-react';
import { LocationModel } from 'src/services';

import { CANADA_STATES, USA_STATES } from '../../../constants';
import { AccountContext } from '../../../contexts';
import { useLocationService, useScheduleService } from '../../../hooks';
import { Schedule } from '../../../services/schedule-service';
import { StoreContext } from '../../../store';
import { EnergyFieldErrorStyle } from '../../../style/global-energy-styles';
import { Button } from '../../buttons';
import { useClimateImpactService } from '../../climate-impact';
import { EnergyDropdown } from '../../dropdown';
import ErrorBoundary from '../../error-boundary/error-boundary';
import EnergyDeleteModal from '../../modals/energy-delete-modal';
import EnergyMobileNav from '../energy-dashboard-page/energy-mobile-nav';
import EnergyNav from '../energy-dashboard-page/energy-nav';
import ArrowIcon from '../energy-dashboard-page/icons/ArrowIcon';
import { EnergyLocationHoursSelector } from '../energy-location-details/components/energy-location-hours-selector';
import { formatSchedules } from '../energy-location-details/utils/schedule-utils';
import { EnergyReportsMainContent } from '../energy-reports-add/styles';
import {
  EnergyReportsHeader,
  EnergyReportsHeaderMenu,
  EnergyReportsHeaderText,
  ReportsContent,
  ReportsPageContainer,
} from '../energy-reports-page/index-styles';
import LocationSearchInput from './location-search-input';

export const FORM_WIDTHS = '450px';

type LocationFormData = {
  name: string;
  line1: string;
  line2: string;
  locality: string;
  state: string;
  postalCode: string;
  country: string;
  scheduleTimeZone: string;
};

export const EditLocationPage = (args) => {
  const formMethods = useForm<LocationFormData>({
    mode: 'onChange',
  });

  const [onSubmitError, setOnSubmitError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [schedulesAtLocation, setSchedulesAtLocation] = useState<Schedule[]>(
    [],
  );

  const [showEnergyDeleteModal, setEnergyDeleteModal] = useState(false);
  const [isEnergyLocation, setIsEnergyLocation] = useState(false);

  const history = useHistory();

  const { setValue } = formMethods;
  const {
    addLocation,
    editLocation,
    browseLocations,
    deleteLocation,
    searchGeocode,
  } = useLocationService();
  const { resetClimateImpactRequest } = useClimateImpactService();
  const {
    editScheduleBulk,
    deletePreviousSchedules,
    getSchedules,
  } = useScheduleService();

  const { store } = useContext(StoreContext);
  const { user } = useContext(AccountContext);

  // Monitoring location UUID
  const allLocations = store.pages.locations.allLocations.byUUID;
  const locationUUID = args.match.params.locationUUID;
  const location =
    locationUUID && (allLocations[locationUUID] as LocationModel);

  // Get a list of the monitoringLocationUUIDs from the EnergyLocationModels in the store.
  const allEnergyLocations = store.pages.energyLocations.allLocations.byUUID;
  const allEnergyLocationModels = Object.values(allEnergyLocations);

  useEffect(() => {
    const allMonitoringLocationUUIDs = allEnergyLocationModels
      .map((EnergyLocationModel) => EnergyLocationModel.uuid)
      .filter((uuid) => uuid);

    const foundMonitoringLocationUUID = allMonitoringLocationUUIDs.includes(
      locationUUID,
    );
    setIsEnergyLocation(foundMonitoringLocationUUID);
  }, []);

  const isEditMode = locationUUID && location;
  const goBackUrl = isEditMode
    ? `/energy/locations/${locationUUID}`
    : '/energy/locations';

  const watchCountry = formMethods.watch('country');

  const onCancel = () => {
    // Ensure climate information reloads when user navigates back
    resetClimateImpactRequest();
    history.push(goBackUrl);
  };

  const closeModal = () => {
    setEnergyDeleteModal(false);
  };

  const handleDeleteLocation = async () => {
    try {
      await deleteLocation(location.uuid);
    } catch (err) {
      setOnSubmitError(err.response.data.errors[0].details);
      return;
    }

    history.push('/energy/locations');
    // Ensure location page reloads when user returns so the deleted location does not appear.
    history.go(0);
  };

  const onSubmit = async (data: LocationFormData) => {
    setIsSubmitting(true);
    const locationData = {
      name: data.name,
      address: `${data.line1} ${data.line2}, ${data.locality}, ${data.state}, ${data.postalCode}, ${data.country}`,
      line1: data.line1,
      line2: data.line2,
      locality: data.locality,
      state: data.state,
      postalCode: data.postalCode,
      country: data.country,
    };

    try {
      if (isEditMode) {
        const schedules = formatSchedules(data);

        const scheduleData = {
          companyUUID: location.companyUUID,
          entityType: 'location',
          entityUUID: location.uuid,
          metadata: {},
          schedule: schedules,
          timezone: data.scheduleTimeZone,
          state: 'occupied',
        };

        try {
          if (schedulesAtLocation.length > 0) {
            await deletePreviousSchedules(scheduleData);
          }
          if (schedules.length > 0) {
            await editScheduleBulk(scheduleData);
          }
        } catch (e) {
          setOnSubmitError(
            'Something went wrong updating this location, please try again',
          );
          return;
        }

        // The below code is used for MixPanel tracking events
        const updatedFields: string[] = [];

        if (locationData.name !== location.name) {
          updatedFields.push('name');
        }
        if (locationData.line1 !== location.line1) {
          updatedFields.push('address');
        }

        mixpanel.track('Location Edited', {
          location_name: locationData.name,
          company_name: user?.company.name,
          updated_fields: updatedFields,
        });

        await editLocation(locationUUID, locationData);
      } else {
        await addLocation(locationData);
      }
      await browseLocations();
      history.push(goBackUrl);
    } catch (e) {
      setOnSubmitError(
        'Something went wrong updating this location, please try again',
      );
      return;
    }

    setOnSubmitError('');
    setIsSubmitting(false);
    resetClimateImpactRequest();

    history.push(goBackUrl);
  };

  const isFormInvalid = () => {
    const formData: FormFieldProps = formMethods.getValues();

    return !(
      formData.name &&
      formData.line1 &&
      formData.locality &&
      formData.state &&
      formData.postalCode &&
      formData.country
    );
  };

  useMemo(() => {
    isFormInvalid();
  }, [formMethods.getValues()]);

  useEffect(() => {
    if (isEditMode) {
      setValue('name', location.name);
      setValue('line1', location.line1);
      setValue('line2', location.line2);
      setValue('locality', location.locality);
      setValue('postalCode', location.postalCode);
      setValue('state', location.state);
      setValue('country', location.country);
    }
  }, [isEditMode]);

  useEffect(() => {
    const params = {
      companyUUID: location?.companyUUID,
      locationUUID: location?.uuid,
    };

    (async () => {
      const schedules = await getSchedules(params);

      const locationSchedules = schedules.filter((schedule) => {
        return schedule.entityUUID === location?.uuid;
      });
      setSchedulesAtLocation(locationSchedules);
    })();
  }, []);

  // The below constants and useEffect function are used for the location form address auto-complete feature
  const [_geoLocation, setGeoLocation] = useState('');
  const [_isAddressLoaded, setIsAddressLoaded] = useState(false);
  const [locFormDetails, setLocFormDetails] = useState([]);
  const addressWatch = formMethods.watch('line1');
  const [_country, setCountry] = useState(args.location?.country);

  const [addressSearchCompleted, setAddressSearchCompleted] = useState(false);

  useEffect(() => {
    if (addressSearchCompleted) return;
    (async () => {
      try {
        if (!addressWatch) return;
        const geo = await searchGeocode(addressWatch);
        if (!geo[0]) return;
        setGeoLocation(geo[0]);
        setIsAddressLoaded(false);
        const addressDetails = geo[0].address_components.map(
          (address: {
            long_name?: string;
            short_name?: string;
            types?: Array<string>;
          }) => {
            const { types } = address;
            const filteredType = types?.filter((t: string) => {
              return (
                (t === 'locality' ||
                  t === 'street_number' ||
                  t === 'route' ||
                  t === 'postal_code' ||
                  t === 'country' ||
                  t === 'administrative_area_level_1') &&
                t
              );
            });

            return (
              filteredType &&
              filteredType?.length > 0 && {
                type: filteredType[0],
                value: address.short_name,
                text: address.long_name,
              }
            );
          },
        );
        setLocFormDetails(addressDetails);
      } finally {
        setIsAddressLoaded(true);
      }
    })();
  }, [addressWatch]);

  useEffect(() => {
    if (!formMethods.formState.touched.line1) return;
    let streetNumberTemp = '';
    let routeTemp = '';
    locFormDetails.length &&
      locFormDetails.map(
        (add: { type: string; value: string; text: string }) => {
          if (!add) return;
          switch (add.type) {
            case 'locality':
              formMethods.setValue('locality', add.value);
              formMethods.triggerValidation('locality');
              break;
            case 'postal_code':
              formMethods.setValue('postalCode', add.value);
              formMethods.triggerValidation('postalCode');
              break;
            case 'country':
              setCountry(add.text);
              formMethods.setValue('country', add.text);
              formMethods.triggerValidation('country');
              break;
            case 'administrative_area_level_1':
              formMethods.setValue('state', add.value);
              formMethods.triggerValidation('state');
              break;
            case 'route':
              streetNumberTemp = add.value;
              break;
            case 'street_number':
              routeTemp = add.value;
              break;
          }
        },
      );

    const line1Value = `${routeTemp} ${streetNumberTemp} `.trim();
    if (line1Value) {
      formMethods.setValue('line1', line1Value);
      formMethods.triggerValidation('line1');
    }
    setAddressSearchCompleted(true);
  }, [locFormDetails]);

  return (
    <ErrorBoundary>
      <ReportsPageContainer>
        <EnergyNav page="LOCATIONS" />
        <EnergyMobileNav page="LOCATIONS" />
        <ReportsContent>
          <EnergyReportsHeader>
            <EnergyReportsHeaderMenu>
              <EnergyReportsHeaderText>
                <Link
                  to={goBackUrl}
                  onClick={() => resetClimateImpactRequest()}
                >
                  <ArrowIcon
                    style={{
                      height: '24px',
                      transform: 'rotate(270deg)',
                      width: '24px',
                    }}
                  />
                </Link>
                {isEditMode ? 'Edit' : 'Add'} Location
              </EnergyReportsHeaderText>
            </EnergyReportsHeaderMenu>
          </EnergyReportsHeader>
          <FormContext {...formMethods}>
            <Form>
              <EnergyReportsMainContent>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div style={{ fontSize: '22px' }}>
                    {isEditMode ? 'Edit location address' : 'Location address'}
                    {onSubmitError && (
                      <p style={{ color: '#FFABAB', fontSize: '12px' }}>
                        {onSubmitError}
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div style={{ width: FORM_WIDTHS }}>
                    <FormInput
                      showrequired
                      className="energy-user-fields"
                      name="name"
                      label="Location nickname or store number"
                      labelClassName="energy-user-label"
                      required
                      customErrors={{
                        required: 'Location name is required.',
                      }}
                      customStyle={EnergyFieldErrorStyle}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div style={{ width: FORM_WIDTHS }}>
                    <Controller
                      name="line1"
                      as={
                        <LocationSearchInput
                          setAddressSearchCompleted={setAddressSearchCompleted}
                          address={location?.line1}
                        />
                      }
                      rules={{
                        validate: {
                          required: (value) => value.length !== 0,
                        },
                      }}
                    />
                  </div>
                  <div style={{ width: FORM_WIDTHS }}>
                    <FormInput
                      showrequired
                      className="energy-user-fields"
                      name="line2"
                      label="Address line 2"
                      labelClassName="energy-user-label"
                      customStyle={EnergyFieldErrorStyle}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div style={{ width: FORM_WIDTHS }}>
                    <FormInput
                      showrequired
                      className="energy-user-fields"
                      name="locality"
                      label="City"
                      labelClassName="energy-user-label"
                      required
                      customErrors={{
                        required: 'City is required.',
                      }}
                      customStyle={EnergyFieldErrorStyle}
                    />
                  </div>
                  <div style={{ width: FORM_WIDTHS }}>
                    <Controller
                      as={
                        <EnergyDropdown
                          text={formMethods.watch('state') || 'Select state'}
                          options={getStateOptions(watchCountry)}
                          label="State *"
                          scrolling
                          width={FORM_WIDTHS}
                          error={!!formMethods.errors['state']}
                          onChange={(event) => event[1].value}
                        />
                      }
                      search
                      name="state"
                      defaultValue={''}
                      rules={{
                        validate: {
                          required: (value) => value.length !== 0,
                        },
                      }}
                      onChange={(event) => event[1].value}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div style={{ width: FORM_WIDTHS }}>
                    <FormInput
                      showrequired
                      className="energy-user-fields"
                      name="postalCode"
                      label="Zip code"
                      labelClassName="energy-user-label"
                      required
                      customErrors={{
                        required: 'Zip code is required.',
                      }}
                      customStyle={EnergyFieldErrorStyle}
                    />
                  </div>
                  <div style={{ width: FORM_WIDTHS }}>
                    <Controller
                      as={
                        <EnergyDropdown
                          text={
                            formMethods.watch('country') || 'Select country'
                          }
                          options={[
                            {
                              value: 'United States',
                              text: 'United States',
                            },
                            {
                              value: 'Canada',
                              text: 'Canada',
                            },
                          ]}
                          label="Country *"
                          width={FORM_WIDTHS}
                          error={!!formMethods.errors['state']}
                          onChange={(event) => event[1].value}
                        />
                      }
                      name="country"
                      defaultValue={''}
                      rules={{
                        validate: {
                          required: (value) => value.length !== 0,
                        },
                      }}
                      onChange={(event) => {
                        if (
                          willStateReset(
                            formMethods.getValues('state'),
                            getStateOptions(event[1].value),
                          )
                        ) {
                          setValue('state', '');
                        }
                        return event[1].value;
                      }}
                    />
                  </div>
                </div>
                {isEnergyLocation && (
                  <div>
                    <Controller
                      as={
                        <EnergyLocationHoursSelector
                          energyLocationUUID={locationUUID}
                        />
                      }
                      name="locationHours"
                    />
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    gap: '40px',
                    justifyContent: 'flex-end',
                    marginTop: '250px',
                  }}
                >
                  {!isEnergyLocation && isEditMode && (
                    <Button
                      style={{ color: '#FFABAB' }}
                      onClick={() => setEnergyDeleteModal(true)}
                      as="text"
                    >
                      Delete location
                    </Button>
                  )}
                  <Button onClick={onCancel} as="text">
                    Cancel
                  </Button>
                  <Button
                    onClick={formMethods.handleSubmit((data) => {
                      onSubmit(data);
                    })}
                    loading={isSubmitting}
                    disabled={isSubmitting || isFormInvalid()}
                  >
                    Save
                  </Button>
                </div>
              </EnergyReportsMainContent>
            </Form>
          </FormContext>
        </ReportsContent>
        {showEnergyDeleteModal && (
          <EnergyDeleteModal
            entityName="location"
            handleClose={closeModal}
            handleDelete={handleDeleteLocation}
          />
        )}
      </ReportsPageContainer>
    </ErrorBoundary>
  );
};

export default EditLocationPage;

function isCountryUSA(watchCountry: string) {
  return ['United States', 'USA', 'US'].includes(watchCountry);
}

/**
 * check if the selected state falls under the selected country
 * if not then reset the state value
 */
function willStateReset(
  selectedState: string,
  stateOptions: {
    text: string;
    value: string;
  }[],
) {
  // check if current state is in the stateOptions
  const foundCurrentState = stateOptions.find(
    ({ value }) => selectedState === value,
  );

  // reset if state not found in current options
  if (!foundCurrentState) {
    return true;
  }

  return false;
}

function getStateOptions(watchCountry: string) {
  return isCountryUSA(watchCountry) ? USA_STATES : CANADA_STATES;
}
