/**
 * create a browser copy of the joi email validation
 * use the address validation directly of @hapi since we can't use Joi in the browser
 * and joi-browser doesn't seem to work
 */
import address from '@hapi/address';

export const isValidJoiEmail = (email: string) => {
  return address.email.isValid(email);
};
