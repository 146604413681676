import axios from 'axios';
import { useEffect, useState } from 'react';

import { request } from '../contexts/axios';

export interface GeocodingParams {
  [index: string]: unknown;
}

export interface GeocodingResponse {
  plus_code: unknown;
  results: unknown[];
  status: string;
}

export const useGeocoding = () => {
  const [params, setParams] = useState<GeocodingParams | null>(null);
  const [data, setData] = useState<GeocodingResponse | null | undefined>(null);
  const [error, setError] = useState();

  useEffect(() => {
    const axiosCancelSource = axios.CancelToken.source();

    const fetch = async (_params = {}) => {
      try {
        const { data: axiosData } = await request.get('api/data/geocode', {
          cancelToken: axiosCancelSource.token,
          params: _params,
        });

        const { data: responseData } = axiosData;

        setData(responseData);
      } catch (err) {
        setError(err);
      }
    };

    if (params) {
      fetch(params);
    }

    return () => {
      axiosCancelSource.cancel();
    };
  }, [params]);

  return {
    setParams,
    data,
    error,
  };
};

export default useGeocoding;
