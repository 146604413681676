import { API_VERSION } from '../constants';
import { request } from '../contexts';
import { getItem, setItem } from '../hooks';
import { BaseEntity } from './index';

export type EquipmentType = 'freezer' | 'refrigerator' | 'other';
export type InstallationType = 'free_standing' | 'built_in';

export interface Device {
  locationUUID: string;
  name: string;
  serialNumber: string;
  status?: string;
  isSmartAlert?: boolean;
  equipment?: EquipmentProps;
  testingMode?: boolean;
  testingStatus?: string;
  equipmentUUID?: string;
  state?: string;
  type?: string;
}

export interface EquipmentProps {
  equipmentType?: string;
  installation?: string;
  other_description?: string | null;
  locationUUID?: string;
  id?: number;
  uuid?: string;
  meta?: {
    status?: string;
    equipment_update?: boolean;
    humidityMaxThreshold?: number | null;
    humidityMinThreshold?: number | null;
    temperatureMaxThreshold?: number | null;
    temperatureMinThreshold?: number | null;
  };
  name?: string;
  companyUUID?: string;
  setPoint?: string | number | null;
  installationTypeOther?: string | null;
}
export interface EditDevice {
  name?: string;
  serialNumber?: string;
  status?: string;
  equipment?: EquipmentProps;
}

export interface DeviceModel extends BaseEntity, Device {}

export interface DeviceInfo extends DeviceModel {
  isSmartAlert: boolean;
}

export interface SignalTestingProps {
  deviceUUIDs: string[];
  isTesting: boolean;
  locationUUID: string;
}

export interface StartTimerProps {
  deviceUUIDs: string[];
  isTestAgain: boolean;
  timeout?: number;
}

export const browseDevicesByManyEquipmentUUID_CACHE =
  'browseDevicesByManyEquipmentUUID';

export class DeviceService {
  public static async browse() {
    const { data: result } = await request.get(`/api/${API_VERSION}/devices`);
    return result.data;
  }

  public static async add(payload: Device): Promise<DeviceModel> {
    const { data: device } = await request.post(
      `/api/${API_VERSION}/devices`,
      payload,
    );
    return device.data;
  }

  public static async read(uuid: string) {
    const { data: device } = await request.get(
      `/api/${API_VERSION}/devices/${uuid}`,
    );
    return device.data;
  }

  public static async edit(
    uuid: string,
    payload: EditDevice,
  ): Promise<Response> {
    const device = await request.put(
      `/api/${API_VERSION}/devices/${uuid}`,
      payload,
    );
    return device.data.data;
  }

  public static async delete(uuid: string): Promise<Response> {
    return request.delete(`/api/device/device/${uuid}`);
  }

  public static async endByAlertConfig(alertConfigUUID: string) {
    const { data: result } = await request.get(
      `/api/${API_VERSION}/alert-notif/healthy/alert-config/${alertConfigUUID}`,
    );
    return result.data;
  }

  public static async toggleDeviceSmartAlert(
    deviceUUID: string,
    isSmartAlert: boolean,
  ) {
    const { data: result } = await request.put(
      `/api/device/device/${deviceUUID}/toggle-smart-alert`,
      {
        flag: isSmartAlert,
      },
    );

    return result.data;
  }

  public static async browseDevicesByLocation(
    locationUUIDs: string[],
    method?: 'get' | 'post',
  ) {
    method = method ?? 'get';

    const endpoint = '/api/device/device/location/device';

    if (method === 'get') {
      const { data: result } = await request.get(endpoint, {
        params: { includeDeleted: false, locationUUID: locationUUIDs },
      });

      return result.data;
    }

    if (method === 'post') {
      const { data: result } = await request.post(endpoint, {
        locationUUIDs: locationUUIDs,
      });

      return result.data;
    }
  }

  public static async checkIfUniqueSensorId({
    sensorId,
    deviceUUID,
  }: {
    sensorId: string;
    deviceUUID: string;
  }) {
    const endpoint = `/api/${API_VERSION}/devices/check-serial?serialNumber=${sensorId}&deviceUUID=${deviceUUID}`;
    const { data: result } = await request.get(endpoint);

    return result.data;
  }

  public static async checkIfUniqueSensorNameInLocation({
    sensorName,
    locationUUID,
    deviceUUID = '',
  }: {
    sensorName: string;
    locationUUID: string;
    deviceUUID: string;
  }) {
    const endpoint = `api/device/device/is-sensor-name-unique-in-location?sensorName=${encodeURIComponent(
      sensorName,
    )}&locationUUID=${locationUUID}&deviceUUID=${deviceUUID}`;
    const { data: result } = await request.get(endpoint);

    return result.data;
  }

  public static async testSignal(deviceUUIDs: string[], isTesting: boolean) {
    const endpoint = `/api/device/device/testing`;
    const { data: result } = await request.post(endpoint, {
      testing: isTesting,
      device: deviceUUIDs,
    });

    return result.data;
  }

  public static async startTimer(
    deviceUUID: string,
    isTestAgain: boolean,
    timeout?: number,
  ) {
    const endpoint = `/api/device/device/signal-test`;
    const { data: result } = await request.post(endpoint, {
      deviceUUID,
      isTestAgain,
      timeout,
    });

    return result.data;
  }

  public static async getSignalTestResult(
    deviceUUIDs: string[],
    startDate: Date,
  ) {
    const endpoint = `/api/device/device/signal-test-result`;
    const { data: result } = await request.get(endpoint, {
      params: { deviceUUID: deviceUUIDs, startDate },
    });

    return result.data;
  }

  public static async browseDevicesByManyEquipmentUUID(
    equipmentUUID: string[],
  ) {
    try {
      if (equipmentUUID.length <= 0) {
        return [];
      }

      if (localStorage.hasOwnProperty(browseDevicesByManyEquipmentUUID_CACHE)) {
        return JSON.parse(getItem(browseDevicesByManyEquipmentUUID_CACHE, []));
      }

      setItem(browseDevicesByManyEquipmentUUID_CACHE, JSON.stringify([]));

      const { data: result } = await request.post(
        `/api/${API_VERSION}/devices/browse`,
        {
          equipmentUUID,
        },
      );

      setItem(
        browseDevicesByManyEquipmentUUID_CACHE,
        JSON.stringify(result.data),
      );
      return result.data;
    } catch (e) {
      return [];
    }
  }
}
