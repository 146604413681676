import {
  FilterRailAddressText,
  FlexPaddedContent,
  PillContent,
} from '@coinspect/ui';
import React, { FunctionComponent, useEffect } from 'react';
import { Accordion, Divider, Icon } from 'semantic-ui-react';
import { mobileMaxWidth } from 'src/constants';
import styled from 'styled-components';

import { LocationModel } from '../../../services';
import { Tooltip } from '../../tooltip';
import { useAccordion } from '../../ui/accordion/accordion';

export interface SearchLocationAccordionProps {
  location: LocationModel;
  deviceCount: number;
}

const MutedText = styled.div`
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${FilterRailAddressText};
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FilterLocationName = styled(PillContent)`
  width: 250px;
  font-size: 16px;
  margin-bottom: 8px;
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    width: auto;
  }
`;

const StyledTooltip = styled(Tooltip)`
  white-space: nowrap;
  padding: 8px 12px !important;

  & > .content {
    font-size: 12px;
    line-height: 16px;
  }
`;

const CaretIcon = styled(PillContent)`
  text-align: right;
`;

export const SearchLocationAccordion: FunctionComponent<SearchLocationAccordionProps> = ({
  children,
  deviceCount,
  location,
}) => {
  const { initPanel, togglePanel, panels } = useAccordion();
  const panelName = `location-${location.uuid}`;
  useEffect(() => {
    initPanel(panelName, true);
  }, []);
  return (
    <>
      <Accordion.Title active={panels[panelName]}>
        <FlexPaddedContent
          size={panels[panelName] ? '30px 30px 0' : '30px'}
          onClick={() => deviceCount > 0 && togglePanel(panelName)}
        >
          <PillContent className="overflow-hidden search-location">
            <FilterLocationName
              as="h2"
              className="ellipse-text search-location__name"
            >
              {location.name}{' '}
            </FilterLocationName>
            <StyledTooltip
              trigger={
                <MutedText className="search-location__sensor-count">
                  {location.line1}
                </MutedText>
              }
              content={
                <>
                  <div>{location.line1}</div>
                  <div>
                    {location.locality}, {location.state}
                  </div>
                </>
              }
            />
          </PillContent>
          <CaretIcon>
            <Icon
              className="icon-chevron-down ease-transition search-location__location--dropdown"
              color="grey"
              flipped={
                panels[panelName] && deviceCount > 0 ? 'vertically' : undefined
              }
              disabled={deviceCount === 0}
            />
          </CaretIcon>
        </FlexPaddedContent>
      </Accordion.Title>
      <Accordion.Content active={panels[panelName]}>
        <FlexPaddedContent size="0 20px 30px">
          <PillContent>{children}</PillContent>
        </FlexPaddedContent>
      </Accordion.Content>
      <Divider fitted />
    </>
  );
};
