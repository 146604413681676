import React, { useContext } from 'react';

import { SensorType } from '../../services';
import { StoreContext } from '../../store';
import { Toggle } from '../toggle';

export const GraphToggle: React.FC = () => {
  const { dispatch, store } = useContext(StoreContext);
  const handleChange = (type: SensorType) => {
    dispatch({
      data: {
        sensorType: type,
      },
      type: 'dashboard:filters:set',
    });
  };
  const { sensorType } = store.pages.dashboard.filters;
  return (
    <Toggle
      name="sensorType"
      options={[
        {
          className: 'graph__button--temperature',
          label: 'Temperature',
          value: 'temperature',
        },
        {
          className: 'graph__button--humidity',
          label: 'Humidity',
          value: 'humidity',
        },
      ]}
      value={sensorType}
      onChange={(type) => handleChange(type as SensorType)}
    />
  );
};
