import { Invite } from '../../../services';
import { AddPeopleFormData } from './add-people-page';

export const toAddPeoplePayload = (
  formData: AddPeopleFormData,
  recipients: { value: string; text: string }[],
): Invite[] => {
  const { role, teams = [], type, countryCode } = formData;
  const invitePayload: Invite[] = [];

  const recipientKey = type === 'sms' ? 'phoneNumber' : 'email';

  recipients.forEach((recipient) => {
    invitePayload.push({
      role,
      teams,
      type,
      [recipientKey]: recipient.text,
      countryCode,
    });
  });

  return invitePayload;
};
