import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DropdownProps, Icon, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

import { OutsideAlerter } from '../../hooks/use-click-outside-component';

type DROPDWON_OPTION = {
  text: string;
  value: string;
};

const MORE_TEXT_WIDTH = 72; // "...99+ more"

type SpecialTruncatedDropdownProps = {
  options: DROPDWON_OPTION[];
  name: string;
  searchPlaceholder?: string;
  value?: string[];
  error?: boolean;
  isLoading?: boolean;
} & Omit<DropdownProps, 'value'>;

type DROPDOWN_STATE = {
  invalid: boolean;
  touched: boolean;
  pristine: boolean;
};

export const SpecialTruncatedDropdown: React.FC<SpecialTruncatedDropdownProps> = (
  props,
) => {
  if (props.isLoading) {
    return (
      <div className="relative field">
        <DropdownLoader active />
        <DropdownMainStyled isLoading />
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { setValue, control } = useFormContext<Record<string, any>>();
  const {
    options,
    name,
    searchPlaceholder = 'Search here',
    className = '',
    defaultValue = [],
    error,
    placeholder,
  } = props;

  const DROPDOWN_OPTIONS = options;

  const [isDropdownActive, setDropdownActive] = useState(false);
  const [displayedSelectedOptions, setDisplayedSelectedOptions] = useState<{
    ROW_1: string[];
    ROW_2: string[];
  }>({
    ROW_1: [],
    ROW_2: [],
  });
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    defaultValue ?? [],
  );
  const [dropdownOptions, setDropdownOptions] = useState(DROPDOWN_OPTIONS);
  const [isSelectAll, setSelectAll] = useState(
    DROPDOWN_OPTIONS.length === selectedOptions.length,
  );
  const [numOfTruncatedOptions, setNumOfTruncatedOptions] = useState(0);
  const [elemWidth, setElemWidth] = useState(0);

  const handleOptionClick = (selectedVal: string) => {
    if (selectedOptions.includes(selectedVal)) {
      const filteredList = selectedOptions.filter((val) => val !== selectedVal);
      setSelectedOptions(filteredList);
    } else {
      setSelectedOptions([...selectedOptions, selectedVal]);
    }
  };

  const findTextFromvalue = (selectedVal: string) => {
    const optionObj = find(DROPDOWN_OPTIONS, { value: selectedVal });
    return optionObj?.text;
  };

  const handleSelectAllOptions = () => {
    if (isSelectAll) {
      setSelectedOptions([]);
      setDisplayedSelectedOptions({ ROW_1: [], ROW_2: [] });
    } else {
      const arr = [];
      for (let i = 0; i < DROPDOWN_OPTIONS.length; i++) {
        const val = DROPDOWN_OPTIONS[i].value;
        if (!selectedOptions.includes(val)) {
          arr.push(val);
        }
      }
      setSelectedOptions([...selectedOptions, ...arr]);
    }
  };

  const handleSearchOptions = (searchKeyword: string) => {
    const searched = DROPDOWN_OPTIONS.filter(({ text }) =>
      text.toLowerCase().includes(searchKeyword),
    );
    setDropdownOptions(searched);
  };

  // handle selected options to display on the field
  useEffect(() => {
    if (DROPDOWN_OPTIONS.length === 0 || !elemWidth) return;
    const ROW_1: string[] = [];
    const ROW_2: string[] = [];
    setSelectAll(DROPDOWN_OPTIONS.length === selectedOptions.length);
    let ctr = 0;

    // ROW 1 of displayed options
    let row1len = 0;
    for (; ctr < selectedOptions.length; ) {
      const optionObj = find(DROPDOWN_OPTIONS, { value: selectedOptions[ctr] });
      const displayedOptionWidth = calculateDisplayedOptionWidth(
        optionObj?.text as string,
      );
      const partialSum = row1len + displayedOptionWidth;

      if (partialSum < elemWidth) {
        ROW_1.push(optionObj?.value as string);
        ctr++;
      } else {
        break;
      }
      row1len += displayedOptionWidth;
    }

    // ROW 2 of displayed options
    let row2len = 0;
    for (; ctr < selectedOptions.length; ctr++) {
      const optionObj = find(DROPDOWN_OPTIONS, {
        value: selectedOptions[ctr],
      });
      const displayedOptionWidth = calculateDisplayedOptionWidth(
        optionObj?.text as string,
      );
      const partialSum = row2len + displayedOptionWidth;
      if (partialSum <= elemWidth - MORE_TEXT_WIDTH) {
        ROW_2.push(optionObj?.value as string);
      }
      row2len += displayedOptionWidth;
    }

    const canBeDisplayedOptions = [...ROW_1, ...ROW_2];
    setDisplayedSelectedOptions({ ROW_1, ROW_2 });
    setNumOfTruncatedOptions(
      selectedOptions.length - canBeDisplayedOptions.length,
    );

    setValue(name, selectedOptions);
  }, [selectedOptions, elemWidth]);

  useEffect(() => {
    if (props.isLoading) return;
    const elem = document.querySelector('#dropdown-main');
    const ELEM_PADDING = 34;
    const clientWidth = (elem?.clientWidth as number) - ELEM_PADDING;
    setElemWidth(clientWidth);
  }, [props.isLoading]);

  useEffect(() => {
    if (isDropdownActive) {
      handleSearchOptions('');
    }
  }, [isDropdownActive]);

  return (
    <OutsideAlerter
      outsideClickEvent={() => {
        setDropdownActive(false);
      }}
    >
      <Controller
        defaultValue={defaultValue}
        control={control}
        name={name}
        as={
          <div
            style={{ width: '100%' }}
            className={`relative field ${className}`}
          >
            <DropdownMainStyled
              active={isDropdownActive}
              onClick={() => {
                setDropdownActive(!isDropdownActive);
              }}
              error={error}
              id="dropdown-main"
            >
              {displayedSelectedOptions.ROW_1.length > 0 ||
              displayedSelectedOptions.ROW_2.length > 0 ? (
                <>
                  {displayedSelectedOptions.ROW_1.length > 0 && (
                    <div>
                      {displayedSelectedOptions.ROW_1.map((selectedValue) => {
                        return (
                          <DropdownSelectedItem key={selectedValue}>
                            {findTextFromvalue(selectedValue)}
                            <i
                              aria-hidden="true"
                              className="icon delete"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOptionClick(selectedValue);
                              }}
                            />
                          </DropdownSelectedItem>
                        );
                      })}
                    </div>
                  )}
                  {displayedSelectedOptions.ROW_2.length > 0 && (
                    <div className="inline-block">
                      {displayedSelectedOptions.ROW_2.map((selectedValue) => {
                        return (
                          <DropdownSelectedItem key={selectedValue}>
                            {findTextFromvalue(selectedValue)}
                            <i
                              aria-hidden="true"
                              className="icon delete"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleOptionClick(selectedValue);
                              }}
                            />
                          </DropdownSelectedItem>
                        );
                      })}
                    </div>
                  )}
                </>
              ) : (
                <div className="placeholder">{placeholder}</div>
              )}
              {numOfTruncatedOptions !== 0 && (
                <div className="inline-block">
                  <div>
                    <span className="more-text">
                      {`...${numOfTruncatedOptions}+ more`}
                    </span>
                  </div>
                </div>
              )}
              <i
                aria-hidden="true"
                className="chevron down icon dropdown-icon"
              />
            </DropdownMainStyled>
            {isDropdownActive && (
              <DropdownMenuStyled>
                <div>
                  <DropdownMenuSearchInput
                    type="text"
                    placeholder={searchPlaceholder}
                    onChange={(e) =>
                      handleSearchOptions(e.target.value.toLowerCase())
                    }
                  />
                  <Icon name="search" />
                </div>
                <DropdownMenuItem onClick={handleSelectAllOptions}>
                  {isSelectAll ? 'Unselect' : 'Select'} all {name}
                </DropdownMenuItem>
                <div
                  style={{
                    maxHeight: '140px',
                    overflowY: 'scroll',
                  }}
                >
                  {dropdownOptions.length ? (
                    dropdownOptions.map((opt) => {
                      return (
                        <DropdownMenuSelectable
                          selected={selectedOptions.includes(opt.value)}
                          key={opt.value}
                          onClick={() => {
                            handleOptionClick(opt.value);
                          }}
                        >
                          {opt.text}
                        </DropdownMenuSelectable>
                      );
                    })
                  ) : (
                    <DropdownMenuSelectable key="none-found" disabled>
                      No result found
                    </DropdownMenuSelectable>
                  )}
                </div>
              </DropdownMenuStyled>
            )}
          </div>
        }
      />
    </OutsideAlerter>
  );
};

const DropdownMainStyled = styled.div<{
  active?: boolean;
  state?: DROPDOWN_STATE;
  error?: boolean;
  isLoading?: boolean;
}>`
  cursor: pointer;
  min-height: 2.71428571em;
  border-radius: 0.28571429rem;
  padding: 0.22619048em 2.1em 0.22619048em 0.35714286em;

  border-bottom-width: 0;
  border-width: 2px;
  border-style: solid;
  border-color: #d1d7e3;

  ${({ isLoading }) =>
    isLoading &&
    `
    background-color: #f5f5f5;
    `};

  ${({ active }) =>
    active &&
    `
    border-color:#0055ff;
    border-bottom-color: #d1d7e3;
    box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
    border-bottom-left-radius: 0em !important;
    border-bottom-right-radius: 0em !important;
    z-index: 10;
    `};

  ${({ error }) =>
    error &&
    `
    background: #fff6f6;
    border-color: #e0b4b4;
    color: #9f3a38;
    `};

  & > .placeholder {
    color: rgba(191, 191, 191, 0.87);
    margin-left: 1%;
    margin-top: 1%;
  }

  & .more-text {
    vertical-align: -10px;
    white-space: nowrap;
  }

  & > .dropdown-icon {
    position: absolute;
    line-height: 2.5;
    top: 0px;
    right: 0px;
    margin: 0em;
    width: 2.67142857em;
    opacity: 0.5;
  }
`;

const DropdownSelectedItem = styled.a`
  line-height: 1;
  border: 0px solid transparent;
  border-radius: 0.28571429rem;
  color: rgba(26, 17, 51, 0.8);
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  margin: 0.14285714rem 0.28571429rem 0.14285714rem 0em;
  background-color: rgba(209, 215, 227, 0.8);
  padding: 10px 20px;

  &:hover {
    color: rgba(0, 0, 0, 0.8);
  }

  & > i {
    width: auto;
    margin: 0em 0.75em 0em 0em;
    margin-right: 0em;
    font-size: 0.92857143em;
    opacity: 0.5;
    transition: background 0.1s ease;
    position: relative;
    top: 2px;
    left: 8px;

    &:hover {
      opacity: 1;
    }
  }
`;

const DropdownMenuStyled = styled.div`
  position: absolute;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  z-index: 11;
  border-top-width: 0px !important;
  max-height: 16.02857143rem;
  margin: 0px -2px;
  box-shadow: 0px 2px 3px 0px rgba(34, 36, 38, 0.15);
  border-color: #0055ff;
  border-width: 2px;
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;

  width: 100%;
  left: 2px;
  top: 96%; // to cover the bottom border of the dropdown main

  .icon {
    position: absolute;
    right: 3px;
    top: 8px;
  }
`;

const DropdownMenuSearchInput = styled.input`
  border: none !important; // overridden by form
  background: #e1e1e173 !important;
  margin-left: 0px !important;
  border-radius: 0 !important;
`;

const DropdownMenuItem = styled.div`
  cursor: pointer;
  line-height: 1em;
  font-weight: normal;
  border-top: 1px solid #fafafa;
  padding: 0.78571429rem 1.14285714rem !important;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.95);
    z-index: 13;
  }
`;

const DropdownMenuSelectable = styled(DropdownMenuItem)<{
  selected?: boolean;
  disabled?: boolean;
}>`
  ${({ selected }) =>
    selected &&
    `
    background: rgba(0, 0, 0, 0.03);
    font-weight: bold;
    `};

  pointer-events: ${({ disabled }) => disabled && 'none'};
`;

const DropdownLoader = styled(Loader)`
  &&&&&&&:after {
    border-color: #767676 transparent transparent; // semantic @grey
  }
`;

// https://stackoverflow.com/a/21015393/4110257
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
function getTextWidth(text, font) {
  // re-use canvas object for better performance
  // @ts-ignore
  const canvas =
    // @ts-ignore
    getTextWidth.canvas ||
    // @ts-ignore
    (getTextWidth.canvas = document.createElement('canvas'));
  // @ts-ignore
  const context = canvas.getContext('2d');
  context.font = font;
  // @ts-ignore
  const metrics = context.measureText(text);
  return metrics.width;
}

function calculateDisplayedOptionWidth(text: string) {
  const EMPTY_DISPLAY_OPTION = 60; // width of box without characters. added more extra space for the extra space in the text
  const FONT_STYLE = '14px proxima-nova';
  const textWidth = Math.ceil(getTextWidth(text, FONT_STYLE));
  const MARGIN_RIGHT = 5; // extra 1 for rounding numbers
  const displayedOptionWidth = textWidth + MARGIN_RIGHT + EMPTY_DISPLAY_OPTION;
  return displayedOptionWidth;
}
/* eslint-enable @typescript-eslint/ban-ts-comment */
