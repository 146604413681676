import React, { createContext, Dispatch, SetStateAction } from 'react';

import { clear, getItem, removeItem, setItem, useLocalStorage } from '../hooks';

const contextInitiallValue = {
  clear,
  getItem,
  removeItem,
  setItem,
  useLocalStorage,
};

export interface LocalStorageContext {
  clear: () => void;
  getItem: (
    key: string,
    initialValue: number | boolean | { [key: string]: boolean },
  ) => Record<string, boolean>;
  removeItem: (key: string) => void;
  setItem: (
    key: string,
    value: number | boolean | { [key: string]: boolean },
  ) => void;
  useLocalStorage: <T>(
    key: string,
    initailValue: T,
  ) => [T, Dispatch<SetStateAction<T>>];
}

interface LocalStorageProps {
  children: React.ReactNode;
}

export const LocalStorageContext = createContext<LocalStorageContext>(
  contextInitiallValue,
);

export function LocalStorage(props: LocalStorageProps) {
  const { children } = props;
  const api: LocalStorageContext = {
    clear,
    getItem,
    removeItem,
    setItem,
    useLocalStorage,
  };

  return (
    <LocalStorageContext.Provider value={api}>
      {children}
    </LocalStorageContext.Provider>
  );
}
