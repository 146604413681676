import { API_VERSION } from '../../constants';
import { request } from '../../contexts';
import { NotifConfigPayload } from '../../services';

export interface EquipmentNotifConfigPayload extends NotifConfigPayload {
  companyUUID: string;
}

export class EquipmentNotifConfigService {
  public static async addEquipmentNotifConfig(
    payload: EquipmentNotifConfigPayload,
    alertUUID: string,
  ) {
    const { data } = await request.post(
      `/api/${API_VERSION}/notif-config/${alertUUID}`,
      {
        ...payload,
      },
    );
    return data.data;
  }

  public static async editEquipmentNotifConfig(
    payload: NotifConfigPayload,
    notifConfigUUID: string,
    alertUUID: string,
  ) {
    const { data } = await request.put(
      `/api/${API_VERSION}/notif-config/${alertUUID}/${notifConfigUUID}`,
      {
        ...payload,
      },
    );
    return data.data;
  }
}
