import { useEffect, useState } from 'react';

import withCancelToken from '../lib/with-cancel-token';
import { createPortalSession } from '../services/payment-service';

const useSessionPortal = () => {
  const [data, setData] = useState({});
  const [url, setUrl] = useState('');
  const [error, setError] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const [cancelToken, fetchSessionPortal] = withCancelToken(
      createPortalSession,
    );

    async function executeGetSessionPortal() {
      try {
        setError(null);
        setIsFetching(true);

        const { data: axiosData } = await fetchSessionPortal();
        const _data = axiosData.data;
        const sessionPortal = _data.sessionPortal || {};

        setData(_data);
        setUrl(sessionPortal.url || '');
      } catch (err) {
        setError(err);
      } finally {
        setIsFetching(false);
      }
    }

    executeGetSessionPortal();

    return () => {
      cancelToken.cancel('Fetching portal session canceled');
    };
  }, [setUrl]);

  return {
    data,
    url,
    isFetching,
    error,
  };
};

export default useSessionPortal;
export { useSessionPortal };
