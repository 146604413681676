import { TempUnits } from '../../services';
import { DataStoreAction } from '../../store';
import { RecommendedThreshold } from './sensor-page-reducer';

export const ConfigKeys = {
  RECOMMENDED_ALERTS_CONFIG: 'recommended_alerts',
} as const;
export type ConfigKeysTypes = typeof ConfigKeys[keyof typeof ConfigKeys];

export type ConfigStoreState = {
  recommended_alerts: {
    temperatureUnit: TempUnits;
    freezer: {
      free_standing: RecommendedThreshold[];
      built_in: RecommendedThreshold[];
    };
    refrigerator: {
      free_standing: RecommendedThreshold[];
      built_in: RecommendedThreshold[];
    };
  } | null;
};

export enum ConfigStoreActions {
  set = 'configStore:set',
}

export const configStoreInitialState: ConfigStoreState = {
  recommended_alerts: null,
};

export function configStoreReducer(
  state: ConfigStoreState,
  action: DataStoreAction,
) {
  switch (action.type) {
    case ConfigStoreActions.set:
      return {
        ...state,
        [action.data.key]: {
          ...action.data.values,
        },
      };
  }
  return state;
}
