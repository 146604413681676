import { plainGrey, thermaBlue, white100 } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Tooltip } from '../tooltip';

type SmartAlertButtonProps = {
  isActive: boolean;
  onClick: (...args: any[]) => void;
  isQueryInProgress?: boolean;
  disabled?: boolean;
};

const SmartAlertButtonContainer = styled.div<{ active: boolean }>`
  && {
    .smart-alert__button {
      background-color: ${({ active }): string =>
        active ? thermaBlue : plainGrey};
      outline: none;
      padding: 8px 0;
      height: 43px;
      width: 43px;
      i {
        font-size: 28px;
      }
      &:focus {
        outline: none;
        background-color: ${({ active }): string =>
          active ? thermaBlue : plainGrey};
      }
      &:hover {
        outline: none;
        background-color: ${({ active }): string =>
          active ? thermaBlue : plainGrey};
      }
    }
    .smart-alert__button__icon {
      color: ${white100};
    }
  }
`;

export const SmartAlertButton: FunctionComponent<SmartAlertButtonProps> = (
  props,
) => {
  const {
    isActive,
    onClick,
    isQueryInProgress = false,
    disabled = false,
  } = props;
  const visibleClass = isQueryInProgress ? 'not-visible' : '';

  return (
    <SmartAlertButtonContainer active={isActive}>
      <Tooltip
        header={`${isActive ? 'Disable' : 'Enable'} Smart Alerts`}
        thin={false}
        content={
          isActive
            ? 'This will leave any Smart updates unchanged.'
            : 'Smart Alerts intelligently adjust your alert temperature thresholds to help prevent excessive notifications.'
        }
        trigger={
          <Button
            className="smart-alert__button"
            circular
            floated="right"
            icon
            loading={isQueryInProgress}
            disabled={isQueryInProgress || disabled}
            active={isActive}
            onClick={onClick}
          >
            <Icon
              className={`icon-smart-alert-1 ${visibleClass} smart-alert__button__icon`}
              aria-label="Toggle Smart Alert"
              size="large"
            />
          </Button>
        }
      />
    </SmartAlertButtonContainer>
  );
};
