import React from 'react';

const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3 17.3C12.1167 17.1167 12.025 16.8833 12.025 16.6C12.025 16.3167 12.1167 16.0833 12.3 15.9L15.2 13H3C2.71667 13 2.47933 12.904 2.288 12.712C2.096 12.5207 2 12.2833 2 12C2 11.7167 2.096 11.479 2.288 11.287C2.47933 11.0957 2.71667 11 3 11H15.2L12.3 8.1C12.1167 7.91667 12.025 7.68333 12.025 7.4C12.025 7.11667 12.1167 6.88333 12.3 6.7C12.4833 6.51667 12.7167 6.425 13 6.425C13.2833 6.425 13.5167 6.51667 13.7 6.7L18.3 11.3C18.4 11.4 18.4707 11.5083 18.512 11.625C18.554 11.7417 18.575 11.8667 18.575 12C18.575 12.1333 18.554 12.2583 18.512 12.375C18.4707 12.4917 18.4 12.6 18.3 12.7L13.7 17.3C13.5167 17.4833 13.2833 17.575 13 17.575C12.7167 17.575 12.4833 17.4833 12.3 17.3ZM21 18C20.7167 18 20.4793 17.904 20.288 17.712C20.096 17.5207 20 17.2833 20 17V7C20 6.71667 20.096 6.479 20.288 6.287C20.4793 6.09567 20.7167 6 21 6C21.2833 6 21.5207 6.09567 21.712 6.287C21.904 6.479 22 6.71667 22 7V17C22 17.2833 21.904 17.5207 21.712 17.712C21.5207 17.904 21.2833 18 21 18Z"
      fill="black"
    />
  </svg>
);

export default CloseIcon;
