import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { ResetPasswordForm } from '../../components/forms/reset-password-form';
import { AccountContext } from '../../contexts';
import { confirmPasswordReset } from '../../services';

export interface SubmitProps {
  oobCode: string;
  password: string;
}

const ResetPassword: React.FC = (props) => {
  const account = useContext(AccountContext);
  const [redirect, setRedirect] = useState(false);
  const [isSubmitting, setIsSubmmitting] = useState<boolean>(false);
  const handleSubmit = async (payload: SubmitProps): Promise<void> => {
    setIsSubmmitting(true);
    try {
      const isSuccess = await confirmPasswordReset(
        payload.oobCode,
        payload.password,
      );

      if (isSuccess) {
        account.setResetPWsuccess(true);

        setTimeout(() => {
          setRedirect(!!isSuccess);
          account.setResetPWsuccess(false);
        }, 3000);
      } else {
        account.setError('Password reset failed');
        setIsSubmmitting(false);
      }
    } catch (e) {
      account.setError('Password reset failed');
      setIsSubmmitting(false);
    }
  };

  return (
    <>
      {redirect && <Redirect to="/signin" />}
      <ResetPasswordForm
        {...props}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export { ResetPassword };
