import { useContext } from 'react';

import { LOC_PANEL_PREFIX } from '../components/locations/location-line-item';
import { LocationDeviceContext, StandardModalContext } from '../contexts';
import { StoreContext } from '../store';
import { SensorPageActions } from '../store/reducers';
import { useFeatures } from './use-features';

export function useAddMoreSensorModal() {
  const { isEquipmentEnabled } = useFeatures();
  const { openModal, hideModal } = useContext(StandardModalContext);
  const { showAddDevice } = useContext(LocationDeviceContext);
  const {
    store: {
      entities: { locations, devices },
    },
    dispatch,
  } = useContext(StoreContext);

  function showAddMoreSensorModal(deviceUUID: string, locationUUID?: string) {
    const location =
      locations.byUUID[
        devices.byUUID[deviceUUID]?.locationUUID ?? locationUUID
      ] ?? {};

    openModal({
      primaryButton: {
        content: 'Done',
        onClick: () => {
          hideModal();
          dispatch({
            type: SensorPageActions.openConnectionStatusModal,
            data: { openModal, locationUUID },
          });
        },
      },
      secondaryButton: {
        content: isEquipmentEnabled ? 'Add Equipment' : 'Add Sensor',
        onClick: async () => {
          hideModal();
          showAddDevice(`${LOC_PANEL_PREFIX}${location.uuid}`);
        },
      },
      header: isEquipmentEnabled
        ? `Do you have more equipments for ${location.name}?`
        : `Do you have more sensors for ${location.name}?`,
      content: isEquipmentEnabled
        ? 'Add the next equipment now, or finalize this location and test your connections.'
        : 'Add the next sensor now, or finalize this location and test your connections.',
      disableCloseOnOutsideClick: true,
      removeCloseIcon: true,
    });
  }

  return {
    showAddMoreSensorModal,
  };
}
