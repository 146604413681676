import { darkBlue80, thermaH2FontFamily } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { StopwatchSvg } from '../../ui';

const DEFAULT_TOP_FONT_SIZE = 72;
const DEFAULT_BOTTOM_FONT_SIZE = 40;

interface StopwatchGraphProps {
  size: number;
  value: number;
  label: string;
}

const StopwatchGraphWrapper = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: ${(props): number => props.height}px;
  }
`;

interface StopwatchLabelProps {
  className?: string;
  text: string | number;
  x: number;
  y: number;
  dy: number;
  textAnchor?: 'start' | 'middle' | 'end';
  style?: React.CSSProperties;
}

const StopwatchLabel: FunctionComponent<StopwatchLabelProps> = (props) => {
  const { className, text, x, y, dy, textAnchor = 'inherit', style } = props;
  const defaultStyle = {
    fontFamily: thermaH2FontFamily,
    stroke: 'transparent',
    ...style,
  };
  return (
    <text direction="inherit" dx="0" dy={dy} x={x} y={y}>
      <tspan
        className={className}
        x={x}
        dx="0"
        textAnchor={textAnchor}
        style={defaultStyle}
      >
        {text}
      </tspan>
    </text>
  );
};

const StopwatchGraph: FunctionComponent<StopwatchGraphProps> = (props) => {
  const { size, value, label } = props;

  return (
    <StopwatchGraphWrapper height={size}>
      <svg
        viewBox="0, 0, 400, 400"
        className="employee-time-saved__graph-image"
      >
        <StopwatchSvg />
        <StopwatchLabel
          className="employee-time-saved__graph-value"
          textAnchor="middle"
          text={value}
          style={{
            fill: darkBlue80,
            fontSize: DEFAULT_TOP_FONT_SIZE,
            fontWeight: 'bold',
          }}
          x={200}
          y={200}
          dy={26}
        />
        <StopwatchLabel
          className="employee-time-saved__graph-label"
          textAnchor="middle"
          text={label}
          style={{
            fill: darkBlue80,
            fontSize: DEFAULT_BOTTOM_FONT_SIZE,
          }}
          x={200}
          y={200}
          dy={75}
        />
      </svg>
    </StopwatchGraphWrapper>
  );
};

export { StopwatchGraph };
