import { request } from '../contexts';
import { BaseEntity } from './index';

export interface Location {
  address: string;
  name: string;
  storeNumber?: string;
  postalCode?: string;
  country?: string;
  state?: string;
  locality?: string;
  line1?: string;
  line2?: string;
  longitude?: string;
  latitude?: string;
}

export interface SearchedLocation {
  formatted_address: string;
  geometry: { location: { lat: number; lng: number } };
  place_id: string;
}

export interface SearchedAutocomplete {
  description: string;
  place_id: string;
}

export interface LocationModel extends BaseEntity, Location {
  isThermaEnergyLocation?: boolean;
  overrides?: unknown;
}

export class LocationService {
  public static async search(searchKey = '', options = {}) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const urlParams: { [key: string]: any } = {
      ...options,
      searchKey,
    };

    const { data: axiosResponseObject } = await request.get(
      'api/location/location/location-device-search',
      {
        params: urlParams,
      },
    );

    return {
      data: axiosResponseObject.data,
      metadata: axiosResponseObject.metadata,
    };
  }

  public static async browse(): Promise<LocationModel[] | null> {
    const { data: locations } = await request.get('/api/location/location');
    return locations.data;
  }

  public static async add(payload: Location): Promise<LocationModel | null> {
    const { data: location } = await request.post(
      '/api/location/location',
      payload,
    );
    return location.data;
  }

  public static async edit(
    uuid: string,
    payload: Location,
  ): Promise<LocationModel | null> {
    const location = await request.put(
      `/api/location/location/${uuid}`,
      payload,
    );
    return location.data.data;
  }

  public static async delete(locationUUID: string) {
    const { data: location } = await request.delete(
      `/api/location/location/${locationUUID}`,
    );
    return location.data;
  }

  public static async browseLocationsPaginated({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) {
    const { data: res } = await request.get(
      `/api/location/location/bypage?page=${page}&limit=${limit}`,
    );

    return res.data;
  }

  public static async searchLocations(keyword = '') {
    const endpoint = `api/location/location/search?keyword=${keyword}`;
    const { data: result } = await request.get(endpoint);

    return result.data;
  }
  public static async searchGooglePlaces(keyword = '') {
    const endpoint = `api/location/location/places?keyword=${keyword}`;
    const { data: result } = await request.get(endpoint);
    const filteredResult = [...new Set(result.data)];
    const countryList = ['United States', 'USA', 'US', 'Canada', 'Ca'];
    if (result.data) {
      const validData = filteredResult.filter((locations) => {
        const {
          formatted_address,
        }: SearchedLocation = locations as SearchedLocation;
        const splittedAddress = formatted_address.split(',');
        const isIncluded = countryList.includes(
          splittedAddress[splittedAddress.length - 1].trim(),
        );
        return isIncluded && locations;
      });
      return validData.map((locations) => {
        const {
          formatted_address,
          geometry,
          place_id,
        }: SearchedLocation = locations as SearchedLocation;

        return {
          formatted_address: formatted_address,
          geometry: geometry,
          place_id: place_id,
        };
      });
    }

    return [];
  }

  public static async searchGeocode(keyword = '') {
    const endpoint = `api/location/location/geocode?keyword=${keyword}`;
    const { data: result } = await request.get(endpoint);

    return result.data;
  }

  public static async searchAutocomplete(keyword = '') {
    const endpoint = `api/location/location/autocomplete?keyword=${keyword}`;

    const { data: result } = await request.get(endpoint);
    const filteredResult = [...new Set(result.data)];
    const countryList = ['United States', 'USA', 'US', 'Canada', 'Ca'];

    if (result.data) {
      const validData = filteredResult.filter((locations) => {
        const {
          description,
        }: SearchedAutocomplete = locations as SearchedAutocomplete;
        const splittedAddress = description.split(',');
        const isIncluded = countryList.includes(
          splittedAddress[splittedAddress.length - 1].trim(),
        );
        return isIncluded && locations;
      });

      return validData.map((locations) => {
        const {
          description,
          place_id,
        }: SearchedAutocomplete = locations as SearchedAutocomplete;

        return {
          description,
          place_id,
        };
      });
    }

    return [];
  }
}
