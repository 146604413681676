import { API_VERSION } from '../constants';
import { request } from '../contexts';
import { BaseEntity } from './index';

export interface NotifConfig {
  alertConfigUUID: string;
  recipients?: string[];
  methods: string[];
  teamRecipients?: string[];
}

export interface NotifConfigPayload {
  recipients: string[];
  methods: string[];
  teamRecipients: string[];
}
export interface NotifConfigModel extends BaseEntity, NotifConfig {
  companyUUID: string;
}

interface AlertConfigNotifConfig {
  alertConfigUUID: string;
  index: number;
  notifConfigUUID: string[];
}

interface Device {
  uuid: string;
  name: string;
  serialNumber: string;
  alertConfigs: AlertConfigNotifConfig[];
}

export interface LocationDeviceNotifConfig {
  uuid: string;
  name: string;
  companyUUID: string;
  storeNumber: string;
  address: string;
  devices: Device[];
  alertConfigs: {
    alertConfigUUID: string;
    index: number;
    notifConfigUUID: string;
  }[];
}

interface LocationDeviceNotifConfigResponse {
  data: LocationDeviceNotifConfig[];
  errors: Error[];
  meta: Record<string, unknown>;
}

export class NotifConfigService {
  // not used anymore for v2
  public static async add(payload: NotifConfigPayload, alertUUID: string) {
    const { data: notifConfig } = await request.post(
      `/api/alert/alert-config/${alertUUID}/notif-config`,
      payload,
    );
    return notifConfig.data;
  }

  /**
   * browseNotifConfigByUserUUID
   * @param {string} userUUID  of a target user.
   * @return list of location - device - notification config that the use is the only recipient.
   */
  public static async browseNotifConfigByUserUUID(
    userUUID: string,
  ): Promise<LocationDeviceNotifConfigResponse> {
    const { data: notifConfig } = await request.get(
      `/api/${API_VERSION}/notif-config/user/${userUUID}`,
    );
    return notifConfig.data;
  }

  // not used anymore for v2
  public static async edit(
    payload: NotifConfigPayload,
    uuid: string,
    alertUUID: string,
  ) {
    const { data: notifConfig } = await request.put(
      `/api/alert/alert-config/${alertUUID}/notif-config/${uuid}`,
      payload,
    );
    return notifConfig.data;
  }
}
