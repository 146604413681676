import { FormPhoneNumber, unFormatNumber } from '@coinspect/ui';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button, Form, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { isNumeric } from 'validator';

import { request } from '../../contexts/axios';
import { InviteModel } from '../../services';
import {
  ConfirmingContainer,
  PinkLink,
} from '../../style/forgot-password-styles';
import {
  ContactUsLink,
  ResendAccText,
  ResendContainer,
  ResendSubText,
} from '../../style/login-signup-styles';
import { EmailField } from '../forms/invite-form/email-field';
import { FailedMessage } from '../warning-messages/error-message';

interface FormData {
  invite: InviteModel;
}

const ResendButton = styled(Button)`
  padding-top: 30px;
  &&& {
    margin-top: 1.5em;
  }
`;

const phoneNumberValidator = (phoneNumber: string) => {
  const unformatted = unFormatNumber(phoneNumber);
  const EXPECTED_LENGTH = 10;
  if (!isNumeric(unformatted) || unformatted.length !== EXPECTED_LENGTH) {
    return false;
  }
  return true;
};

export const ResendActivationForm: React.FC = () => {
  // USE STATES
  const [isLoading, setIsLoading] = useState(false);
  const [inviteType, setInviteType] = useState('email');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const methods = useForm<FormData>({
    mode: 'onChange',
  });

  const { handleSubmit, watch, formState } = methods;

  const email = watch('email');
  const phoneNumber = watch('phoneNumber');
  const countryCode = watch('countryCode');

  const phoneNumberString = phoneNumber ? phoneNumber.toString() : '';
  const formVal = `${countryCode}-${phoneNumberString.replace(/[^0-9]/gi, '')}`;

  const changeType = inviteType === 'email' ? 'email address' : 'phone number';
  const changeTypeText = inviteType === 'email' ? 'email' : 'phone number';
  const changeHeaderType = inviteType === 'email' ? 'email' : 'number';

  // HANDLE RESEND SUBMIT FORM
  const handleResend = async () => {
    try {
      setIsLoading(true);

      const input = inviteType === 'sms' ? formVal : email;
      const response = await request.put(
        `/api/account/invite/resend/type/${inviteType}/input/${input}`,
      );

      setIsLoading(false);

      const message = !response
        ? `Invalid ${changeType}.`
        : `The activation link has been sent to your ${changeTypeText}.`;

      setSuccessMessage(message);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(error.response.data.message);
    }
  };

  const handleToggleClick = (type: string) => {
    setInviteType(type);
    setErrorMessage(null);
  };

  const resendActivationHeader = (
    <>
      <Header as="h2" className="resend-activation-form-header">
        <ResendAccText>Resend account activation</ResendAccText>
        <ResendSubText>
          Please enter your {changeType}. We will resend an activation link if
          your {changeHeaderType} is in our database.
        </ResendSubText>
      </Header>
    </>
  );

  const SUCCESS_LENGTH = 0;

  return (
    <>
      <ResendContainer>
        {successMessage.length === SUCCESS_LENGTH && (
          <>
            {errorMessage != null && <FailedMessage message={errorMessage} />}
            {resendActivationHeader}
            <FormContext {...methods}>
              <Form>
                {inviteType === 'sms' ? (
                  <>
                    <FormPhoneNumber
                      name="phoneNumber"
                      value={formVal}
                      required
                      formLabel="Phone number:"
                      customErrors={{
                        phoneNumber: 'Invalid phone number format.',
                        required: 'Phone number is required',
                      }}
                      countryCodeName="countryCode"
                      validation={{
                        phoneNumber: phoneNumberValidator,
                      }}
                    />
                    <p
                      onClick={() => handleToggleClick('email')}
                      style={{
                        color: '#dc7f9b',
                        display: 'flex',
                        fontFamily: 'proxima-nova',
                        fontWeight: 'bold',
                        justifyContent: 'left',
                        paddingBottom: '5px',
                        paddingTop: '5px',
                        textDecoration: 'underline',
                      }}
                    >
                      I was invited by email
                    </p>
                  </>
                ) : (
                  <>
                    <EmailField name="email" />
                    <p
                      onClick={() => handleToggleClick('sms')}
                      style={{
                        color: '#dc7f9b',
                        display: 'flex',
                        fontFamily: 'proxima-nova',
                        fontWeight: 'bold',
                        justifyContent: 'left',
                        paddingBottom: '5px',
                        paddingTop: '5px',
                        textDecoration: 'underline',
                      }}
                    >
                      I was invited by text message
                    </p>
                  </>
                )}

                <ResendButton
                  className="resend-activation-form__button--resend"
                  primary
                  fluid
                  type="submit"
                  disabled={!formState.isValid || isLoading}
                  onClick={handleSubmit(() => handleResend())}
                >
                  Resend
                </ResendButton>
              </Form>
            </FormContext>

            <Link to="/">
              <PinkLink>Back to log in</PinkLink>
            </Link>
          </>
        )}

        {successMessage.length > SUCCESS_LENGTH && (
          <>
            <ConfirmingContainer>
              <p className="resend-activation-form__header--success">
                {successMessage}
              </p>

              <Link to="/">
                <PinkLink>Back to log in</PinkLink>
              </Link>
            </ConfirmingContainer>

            <div style={{ paddingTop: '150px', textAlign: 'center' }}>
              <ContactUsLink href="mailto:support@glaciergrid.com">
                Contact us
              </ContactUsLink>
            </div>
          </>
        )}
      </ResendContainer>
    </>
  );
};
