import { darkBlue80, primaryColor } from '@coinspect/ui';
import uniqueId from 'lodash/uniqueId';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, SemanticCOLORS } from 'semantic-ui-react';
import { tabletMaxWidth } from 'src/constants';
import styled from 'styled-components';

import { Tooltip } from '../tooltip';

interface ToggleOptions {
  value: string;
  label: string;
  title?: string; // temporary
  className?: string;
}

interface ToggleProps {
  required?: boolean;
  options: ToggleOptions[];
  color?: SemanticCOLORS;
  label?: string;
  display?: 'inline-block' | 'block';
  title?: string;
  onChange?: (value: unknown) => void;
  className?: string;
  value?: unknown;
  isDisabled?: boolean;
  name: string;
  less?: boolean; // less vertical padding
  isNew?: boolean;
}

const ToggleStyle = styled(Form.Field)<{ $isDisabled: boolean }>`
  display: ${({ display }) => display};
  &&& {
    margin: 0;
  }

  div {
    opacity: ${({ $isDisabled }) => ($isDisabled ? '0.45' : '1')};

    input[type='radio'] {
      display: none;
    }

    label {
      display: inline-block;
      font-size: 14px;
      cursor: pointer;
      text-transform: capitalize;

      line-height: 1em;
      font-weight: 700;
      padding: 13px 20px;
      color: ${darkBlue80};
      letter-spacing: 0.5px;
      border: 2px solid #d1d7e3;
    }

    label:first-of-type {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 4px;
      border-right: none;
    }

    label:last-of-type {
      border-top-right-radius:25px;
      border-bottom-right-radius: 4px;
      border-left: none;
    }

    input[type='radio']:checked + label {
      background: ${primaryColor};
      border-color: ${primaryColor};
      border-width: 2px;
      border-style: solid;
      color: white;
    }

    ${({ $isNew }) =>
      $isNew
        ? `
      label {
        border: 1px solid #8B9198;
        color: #FFF;
      }

      label:first-of-type {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        border-right: none;
      }

      label:last-of-type {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        border-left: none;
      }

      input[type='radio']:checked + label {
        background: #47A1C3;
        border: 1px solid #8B9198;
        color: #000;

        :first-of-type {
          border-right: none;
        }

        :last-of-type {
          border-left: none;
        }
      }
    `
        : ''}

    @media only screen and (max-width: ${tabletMaxWidth}px) {
      label {
        padding: 13px 19px;
      }
    }
  }
`;

const OptionStyled = styled.div`
  width: max-content;
`;

export const Toggle: FC<ToggleProps> = (props) => {
  const {
    required = false,
    options,
    label,
    onChange,
    display,
    title,
    className = '',
    value,
    isDisabled = false,
    name,
    less = false,
    isNew,
  } = props;

  const Options = (
    <OptionStyled>
      {options &&
        options.map((option) => {
          const toggleId = `${uniqueId('toggle_')}_${option.value}`;
          const checked = option.value === value;
          return (
            <React.Fragment key={option.value}>
              <input
                className={option.className}
                type="radio"
                id={toggleId}
                value={option.value}
                name={name}
                checked={checked}
                onChange={() => {
                  onChange?.(option.value);
                }}
              />
              <label
                style={{ padding: less ? '6px 20px' : '13px 20px' }}
                title={option.title}
                htmlFor={toggleId}
              >
                {option.label}
              </label>
            </React.Fragment>
          );
        })}
    </OptionStyled>
  );

  return (
    <ToggleStyle
      required={required}
      display={display}
      className={className}
      disabled={isDisabled}
      $isDisabled={isDisabled}
      $isNew={isNew}
    >
      {label && <label className="bold">{label}</label>}
      {title && <Tooltip content={title} trigger={Options} />}
      {!title && Options}
    </ToggleStyle>
  );
};

export const FormToggle: FC<ToggleProps> = (props) => {
  const { name } = props;
  const { register, setValue, watch } = useFormContext();

  const value = watch(name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (_value: any) => setValue(name, _value);

  return (
    <>
      <input type="hidden" name={name} ref={register} />
      <Toggle {...props} value={value} onChange={handleChange} />
    </>
  );
};
