import {
  darkBlue80,
  FlexPaddedContent,
  GhostList,
  GhostListItem,
  PaddedContent,
  thermaBlue,
} from '@coinspect/ui';
import moment from 'moment';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import { Button, Divider, Icon, Input, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { tabletMaxWidthPx } from '../../../constants';
import { tabletMaxWidth } from '../../../constants/responsive-constants';
import { LocalStorageContext } from '../../../contexts';
import { useTimeFilter } from '../../../hooks';
import { StoreContext } from '../../../store';

const minutesToMs = (minutes: number) => minutes * 60 * 1000; // to milliseconds
const UPDATE_INTERVAL = minutesToMs(10); // minutes
const { minuteRemover, dayRemover, now } = useTimeFilter();

const timeframePresets = [
  {
    endDate: now,
    label: 'Last 30 minutes',
    startDate: minuteRemover(30),
  },
  {
    endDate: now,
    label: 'Last hour',
    startDate: minuteRemover(60),
  },
  {
    endDate: now,
    label: 'Last 3 hours',
    startDate: minuteRemover(180),
  },
  {
    endDate: now,
    label: 'Last 6 hours',
    startDate: minuteRemover(360),
  },
  {
    endDate: now,
    label: 'Last 12 hours',
    startDate: minuteRemover(720),
  },
  {
    endDate: now,
    label: 'Last 24 hours',
    startDate: dayRemover(1),
  },
  {
    endDate: now,
    label: 'Last 10 days',
    startDate: dayRemover(10),
  },
  {
    endDate: now,
    label: 'Last 30 days',
    startDate: dayRemover(30),
  },
  {
    endDate: now,
    label: 'Last 90 days',
    startDate: dayRemover(90),
  },
  {
    endDate: now,
    label: 'Last 6 months',
    startDate: (): string => {
      const date = new Date();
      date.setMonth(date.getMonth() - 6);
      return date.toISOString();
    },
  },
  {
    endDate: now,
    label: 'Last year',
    startDate: (): string => {
      const date = new Date();
      date.setFullYear(date.getFullYear() - 1);
      return date.toISOString();
    },
  },
];

const PopperContainer = styled.div`
  width: 320px;
`;

const FlexDateContent = styled(FlexPaddedContent)`
  align-items: start;
`;

const FlexItem = styled.div`
  flex-grow: 1;
`;

const PopupDivider = styled(Divider)`
  &&& {
    margin: 0 -15px;
  }
`;

const TimeItem = styled(GhostListItem)`
  padding: 5px 10px;
`;

const FluidTimeFilter = styled(Input)`
  width: 100%;

  .bolder {
    font-weight: 700;
  }

  &&&.left > i.icon {
    font-size: 2em;
    left: -15px;
    opacity: 1;
  }

  input {
    cursor: pointer;
  }
`;
const ButtonContainer = styled(PaddedContent)`
  text-align: right;
`;

const RangeLabel = styled(FlexItem)`
  font-weight: 700;
  padding: 5px 10px;
`;

const PresetLabel = styled.span`
  color: ${darkBlue80};
  cursor: pointer;
  &.active {
    color: ${thermaBlue};
    font-weight: 700;
  }
`;

const TimeFilterTrigger = styled(PaddedContent)`
  cursor: pointer;
`;

const TimeFilterPopup = styled(Popup)`
  &::before {
    display: none;
  }
  &&& {
    z-index: 100;
  }

  @media only screen and (max-width: ${tabletMaxWidthPx}) {
    &&& {
      z-index: 202;
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      top: 0 !important;
      transform: unset !important;
      right: 0 !important;
      width: 400px;
      left: calc(100% - 350px) !important;
      margin: 0;
      position: fixed !important;
    }
  }
`;

interface DashboardTimeFilterProps {
  closeRail?: () => void;
}

export const DashboardTimeFilter: React.FC<DashboardTimeFilterProps> = () => {
  const { store, dispatch } = useContext(StoreContext);
  const [active, setActive] = useState<boolean>(false);
  const { labelIndex, start, end } = store.pages.dashboard.filters.date;
  const formattedStartDate = moment(start).format('MM/DD/YY');
  const formattedEndDate = moment(end).format('MM/DD/YY');
  const [selectedIndex, setSelectedIndex] = useState<number>(labelIndex);
  const [toFrom, setToFrom] = useState<string>('');
  const [triggerLabel, setTriggerLabel] = useState<string>(
    labelIndex >= 0
      ? timeframePresets[labelIndex].label
      : `${formattedStartDate} - ${formattedEndDate}`,
  );
  const [showToFrom, setShowToFrom] = useState<boolean>(false);
  const firstSet = timeframePresets.slice(0, 6);
  const secondSet = timeframePresets.slice(6);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { setItem } = useContext(LocalStorageContext);

  function onSelect(idx: number): void {
    setSelectedIndex(idx);
    setToFrom('');
    setIsDisabled(false);
  }

  function handleDateRange(
    evt: SyntheticEvent<HTMLElement, Event>,
    { value }: { value: string },
  ): void {
    setToFrom(value);
    setSelectedIndex(-1);
    const [from, to] = value.split(' - ');
    if (from && to) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }

  function dispatchStartEnd(strStart: string, strEnd: string): void {
    dispatch({
      data: {
        end: strEnd,
        labelIndex: selectedIndex,
        start: strStart,
      },
      type: 'dashboard:dateFilter:set',
    });
  }

  function updateStartEndPreset(): void {
    const { startDate, endDate } = timeframePresets[selectedIndex];
    const _start = startDate();
    const _end = endDate();
    dispatchStartEnd(_start, _end);
  }

  function updatePresetIfIndexSelected() {
    if (selectedIndex >= 0) {
      updateStartEndPreset();
    }
  }

  useEffect(() => {
    if (selectedIndex >= 0) {
      setTriggerLabel(timeframePresets[selectedIndex].label);
      setShowToFrom(false);
      setIsDisabled(true);
    }
  }, [selectedIndex]);

  useEffect(updatePresetIfIndexSelected, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (window.innerWidth > tabletMaxWidth) {
      interval = setInterval(updatePresetIfIndexSelected, UPDATE_INTERVAL);
    }

    updatePresetIfIndexSelected();

    return (): void => {
      clearInterval(interval);
    };
  }, [triggerLabel]);

  function applyRange(): JSX.Element | void {
    if (selectedIndex >= 0) {
      setTriggerLabel(timeframePresets[selectedIndex].label);
      updateStartEndPreset();
    } else {
      setTriggerLabel(toFrom);
      const [from, to] = toFrom.split(' - ');
      const _start = new Date(from).toISOString();
      const unprocessedEnd = new Date(to);
      unprocessedEnd.setHours(23, 59, 59, 999);
      const _end = unprocessedEnd.toISOString();
      dispatchStartEnd(_start, _end);
    }
    setItem('currentLocalPage', 0);
    setItem('currentDesktopPage', 0);
    dispatch({
      data: {
        currentPage: 0,
      },
      type: 'dashboard:currentPage:set',
    });
    if (selectedIndex > 0) {
      setShowToFrom(false);
      setIsDisabled(true);
      setActive(true);
      return;
    }
    setShowToFrom(true);
    setActive(true);
    setIsDisabled(true);
  }

  return (
    <div>
      <TimeFilterPopup
        className="time-filter"
        flowing
        trigger={
          <TimeFilterTrigger className="time-filter__trigger" size="30px 0">
            {triggerLabel}
            <Icon
              className="icon-chevron-down float-right time-filter__icon-dropdown"
              color="grey"
            />
          </TimeFilterTrigger>
        }
        on="click"
        onOpen={(): void => {
          setActive(true);
          setIsDisabled(true);
        }}
        onClose={(): void => {
          setActive(false);
          setShowToFrom(false);
          setIsDisabled(false);
        }}
        open={active}
      >
        <PopperContainer>
          <FlexPaddedContent size="0">
            <RangeLabel>Ranges</RangeLabel>
          </FlexPaddedContent>
          <FlexDateContent size="0 0 30px">
            <FlexItem>
              <GhostList>
                {firstSet.map(({ label }, idx: number) => {
                  return (
                    <TimeItem
                      className="time-filter__hours"
                      onClick={(): void => {
                        onSelect(idx);
                        applyRange();
                      }}
                      key={idx}
                    >
                      <PresetLabel
                        className={selectedIndex === idx ? 'active' : ''}
                      >
                        {label}
                      </PresetLabel>
                    </TimeItem>
                  );
                })}
              </GhostList>
            </FlexItem>
            <FlexItem>
              {secondSet.map(({ label }, idx: number) => {
                idx += 6;
                return (
                  <TimeItem
                    className="time-filter__days"
                    onClick={(): void => {
                      onSelect(idx);
                      applyRange();
                    }}
                    key={idx}
                  >
                    <PresetLabel
                      className={selectedIndex === idx ? 'active' : ''}
                    >
                      {label}
                    </PresetLabel>
                  </TimeItem>
                );
              })}
            </FlexItem>
          </FlexDateContent>
          <PopupDivider fitted />
          <FlexPaddedContent size="20px 10px">
            <FlexItem className="custom-filter">
              <FluidTimeFilter
                className="custom-filter__input-date"
                value={toFrom}
                iconPosition="left"
                placeholder="From - To"
                onClick={(): void => setShowToFrom(!showToFrom)}
                readOnly
              >
                <Icon
                  className="icon-calendar therma-blue custom-filter__icon-calendar"
                  size="large"
                />
                <input className="bolder" />
              </FluidTimeFilter>
              {showToFrom && (
                <DatesRangeInput
                  maxDate={new Date()}
                  inline={true}
                  name="datesRange"
                  dateFormat="MM/DD/YY"
                  placeholder="From - To"
                  value={toFrom}
                  iconPosition="left"
                  onChange={handleDateRange}
                  className="therma-input-focus"
                />
              )}
            </FlexItem>
          </FlexPaddedContent>
          <ButtonContainer size="0px 10px 10px">
            {showToFrom && (
              <Button
                id="time-filter__button--apply"
                content="Apply"
                onClick={applyRange}
                disabled={isDisabled}
                primary
              />
            )}
          </ButtonContainer>
        </PopperContainer>
      </TimeFilterPopup>
    </div>
  );
};
