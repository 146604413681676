import mixpanel from 'mixpanel-browser';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { ModalContext } from '../../contexts';
import { StoreContext } from '../../store';
import { JoyrideFlows } from '../../store/reducers';

const FLOW1 = `You’ve set up your first sensor and alert! The data for this sensor will populate to your dashboard in 20 minutes.
For more tips and best practices, check out the “support” option in your profile. Now let’s get to sensing!`;

const FLOW2 = `You’ve invited your first team member. After registering an account they will be able to set up your sensors.
For more tips and best practices, check out the “support” option in your profile. Now let’s get to sensing!`;

export const NiceWorkModal: React.FC = () => {
  const { store } = useContext(StoreContext);
  const { openModal, hideModal } = useContext(ModalContext);
  const { showNiceWork, flow } = store.pages.dashboard;
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (showNiceWork === true) {
      openModal({
        size: 'tiny',
        fullScreenOnMobile: false,
        fullScreenOnTablet: false,
        closeIcon: true,
        content: (
          <NiceWorkContent
            text={flow === JoyrideFlows.PRIMARY_FLOW ? FLOW1 : FLOW2}
          />
        ),
        hasModalActions: false,
        padding: 'none',
        widthSize: '500px',
      });
      setModalOpen(true);
    } else if (showNiceWork === false && isModalOpen) {
      hideModal();
      setModalOpen(false);
    }
  }, [showNiceWork]);

  return null;
};

const NiceWorkContent: React.FC<{ text: string }> = (props) => {
  const { text } = props;
  const { dispatch } = useContext(StoreContext);
  const { hideModal } = useContext(ModalContext);
  const history = useHistory();
  const closeModal = () => {
    mixpanel.track('Onboarding Tutorial Completed');
    dispatch({
      type: 'dashboard:onboarding:nice-work',
      data: { run: false, flow: null },
    });
    hideModal();
    return history.push('/');
  };
  return (
    <Container>
      <div className="graphic-container">
        <Icon className="icon-nice-work white" size="massive" />
      </div>
      <h1 className="nice-work">Nice Work!</h1>
      <div className="nice-text-box">{text}</div>
      <div>
        <NiceButton onClick={closeModal}>Go to my dashboard</NiceButton>
      </div>
    </Container>
  );
};

const NiceButton = styled(Button)`
  &&& {
    background: #fff;
    margin: 0;
    float: right;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #90bbca 18.75%, #8dd2cc 100%);
  padding: 26px 44px;
  border-radius: 4px;

  .graphic-container {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 25px;
  }

  .nice-work {
    color: #fff;
    text-align: center;
    font-size: 40px;
    text-align: center;
    margin-bottom: 35px;
  }
  .nice-text-box {
    padding: 1em;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 20px;
  }
`;
