import { black15, black80 } from '@coinspect/ui';
import React, { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';

import { computerMinWidthPx } from '../../constants';
import { SlidingSidebarContext } from '../../contexts';

export interface SlidingSidebarProps {
  width?: 'very thin' | 'thin' | 'wide' | 'very wide';
  visible?: boolean;
  direction?: 'top' | 'right' | 'bottom' | 'left';
  children: JSX.Element;
}

interface Visible {
  $visible: boolean;
}

const Dimmer = styled.div<Visible>`
  height: 100%;
  width: 100%;
  background-color: ${black80};
  position: absolute;
  pointer-events: all;
  z-index: 201; /* higher than alert-banner 200 */
  display: ${({ $visible }) => ($visible ? 'block' : 'none')};

  @media only screen and (min-width: ${computerMinWidthPx}) {
    display: none;
  }
`;

const CustomSidebar = styled.div<Visible>`
  background: white;
  height: 100%;
  position: absolute;
  pointer-events: all;
  min-width: 200px;
  max-width: 500px;
  width: 80%;
  right: 0;
  border: 1px solid ${black15};
  z-index: 201; /* higher than alert-banner 200 */
  transform: ${({ $visible }) =>
    $visible ? 'translateZ(0)' : 'translate3d(100%, 0%, 0)'};
  will-change: transform;
  transition: transform 0.5s ease;
  overflow-y: auto;

  @media only screen and (min-width: ${computerMinWidthPx}) {
    transform: translate3d(100%, 0%, 0);
  }
`;

const SidebarOverlay = styled.div`
  height: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
  overflow: hidden;
`;

const SidebarContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const BaseSlidingSidebar: FunctionComponent<SlidingSidebarProps> = (
  props,
) => {
  const { closeSidebar } = useContext(SlidingSidebarContext);
  const { visible = false, children } = props;

  return (
    <SidebarOverlay>
      <SidebarContainer>
        <Dimmer onClick={closeSidebar} $visible={visible} />
        <CustomSidebar $visible={visible}>{children}</CustomSidebar>
      </SidebarContainer>
    </SidebarOverlay>
  );
};
