import React from 'react';

const testingStatusMapping = {
  connecting: {
    text: 'Sensor is joining network',
    color: '#007AFF',
  },
  connected: {
    text: 'Less than 60s remaining',
    color: '#007AFF',
  },
  join_failed: {
    text: '',
    color: '',
  },
  testing_failed: {
    text: '',
    color: '',
  },
  done: {
    text: '',
    color: '',
  },
};

export type SignalTestingStatus =
  | 'connecting'
  | 'connected'
  | 'done'
  | 'join_failed'
  | 'testing_failed';

export const StatusResult: React.FC<{
  testingStatus: SignalTestingStatus;
}> = ({ testingStatus }) => {
  const mapping = testingStatusMapping[testingStatus];
  return <span style={{ color: mapping.color }}>{mapping.text}</span>;
};
