import React from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import styled from 'styled-components';

const testingResultMapping = {
  Good: {
    bg: '#4C8A45',
    icon: 'icon-check-circle',
    text: 'Good',
  },
  Poor: {
    bg: '#C93400',
    icon: 'icon-error',
    text: 'Poor',
  },
  None: {
    bg: '#D70015',
    icon: 'icon-cancel',
    text: 'None',
  },
  'In progress': {
    bg: '',
    icon: '',
    text: '',
  },
};

export type TestingResult = 'Good' | 'Poor' | 'None' | 'In progress';

const StatusLoading = styled(Loader).attrs(({ $small }) => ({
  className: $small ? 'small' : '',
}))`
  &&&&&&:after {
    border-color: #007aff transparent transparent;
  }
  &&&&&&:before {
    border-color: #d9d9d9;
  }
`;

export const StatusBadge: React.FC<{ testingResult: TestingResult }> = ({
  testingResult,
}) => {
  if (testingResult === 'In progress') {
    return (
      <div>
        <StatusLoading active $small inline />
      </div>
    );
  }
  return (
    <div
      style={{
        borderRadius: 4,
        backgroundColor: testingResultMapping[testingResult].bg,
        color: 'white',
        width: 66,
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      <Icon
        className={testingResultMapping[testingResult].icon}
        style={{ lineHeight: 0 }}
      />
      {testingResultMapping[testingResult].text}
    </div>
  );
};
