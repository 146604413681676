export default [
  10,
  15,
  30,
  45,
  60,
  75,
  90,
  120,
  150,
  180,
  240,
  360,
  480,
  600,
  720,
  1440,
];
