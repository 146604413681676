export const NOTIF_OPTIONS = [
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'Text message',
    value: 'sms',
  },
  {
    text: 'Phone call',
    value: 'call',
  },
  {
    text: 'Push notification',
    value: 'pushNotification',
  },
];
