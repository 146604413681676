import React from 'react';

const ArrowIcon = ({ style = {} }: { style?: React.CSSProperties }) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style }}
  >
    <path
      d="M8 15.1667C7.81111 15.1667 7.65289 15.1027 7.52534 14.9747C7.39734 14.8471 7.33334 14.6889 7.33334 14.5V4.38337L4.73334 6.9667C4.61111 7.08892 4.45822 7.15292 4.27467 7.1587C4.09156 7.16403 3.93334 7.10003 3.8 6.9667C3.67778 6.84448 3.61667 6.68892 3.61667 6.50003C3.61667 6.31114 3.67778 6.15559 3.8 6.03337L7.53334 2.30003C7.6 2.23337 7.67222 2.18603 7.75 2.15803C7.82778 2.13048 7.91111 2.1167 8 2.1167C8.08889 2.1167 8.17222 2.13048 8.25 2.15803C8.32778 2.18603 8.4 2.23337 8.46667 2.30003L12.2 6.03337C12.3222 6.15559 12.3833 6.30825 12.3833 6.49137C12.3833 6.67492 12.3222 6.83337 12.2 6.9667C12.0667 7.10003 11.9082 7.1667 11.7247 7.1667C11.5416 7.1667 11.3833 7.10003 11.25 6.9667L8.66667 4.38337V14.5C8.66667 14.6889 8.60289 14.8471 8.47534 14.9747C8.34734 15.1027 8.18889 15.1667 8 15.1667Z"
      fill="#D3E5F0"
    />
  </svg>
);

export default ArrowIcon;
