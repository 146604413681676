import React, { FC } from 'react';
import styled from 'styled-components';

const ScrollableContent = styled.div<{ padding: string }>`
  position: relative;
  width: calc(100% + 60px);
  height: 380px;
  left: -30px;
  overflow-y: scroll;
  padding: ${({ padding = '0px 108px' }) => padding};
  margin-top: 20px;
  box-shadow: inset 0px -2px 0px #dee2e6, inset 0px 2px 0px #dee2e6;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

export interface StandardModalContentScrollableInterface {
  padding?: string;
}

export const StandardModalContentScrollable: FC<StandardModalContentScrollableInterface> = (
  props,
) => {
  const { children, padding = '0px 30px' } = props;

  return <ScrollableContent padding={padding}>{children}</ScrollableContent>;
};
