import { stoneBlue30, thermaGrey, white100 } from '@coinspect/ui';
import React, { FunctionComponent, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { tabletMaxWidth } from 'src/constants';
import styled from 'styled-components';

import { AccountContext, SlidingSidebarContext } from '../../contexts';
import { AppLogo } from '../base';
import { MobileSliderMenu } from './';

export const MobileNavbarContainer = styled.div`
  && {
    transform: translate3d(0, 0, 0);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
    background-color: ${thermaGrey};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'logo navicon';
    color: ${white100};
    padding: 15px;
    box-shadow: 0 2px 2px 0 ${stoneBlue30};

    .logo {
      grid-area: logo;
    }

    .navicon {
      grid-area: navicon;
      justify-self: end;
      align-self: center;
    }
  }
`;

const MobileNavIcon = styled.div`
  .bars {
    font-size: 25px;
    align-self: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
`;

export const MobileNavBarPlaceholder = styled.div`
  height: 69px;
`;

const MobileNav = styled.div`
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    position: relative;
  }
`;

const noNavRegExp = new RegExp(['registration', 'two-factor-auth'].join('|'));

export const MobileNavbar: FunctionComponent = () => {
  const { pathname } = useLocation();
  const userContext = useContext(AccountContext);
  const { openSidebar } = useContext(SlidingSidebarContext);
  const { user } = userContext;
  if (!user) {
    return null;
  }

  const openMobileNavigationMenu = () => {
    openSidebar({
      children: <MobileSliderMenu user={user} userContext={userContext} />,
    });
  };

  return (
    <MobileNav>
      <MobileNavBarPlaceholder />
      <MobileNavbarContainer className="therma-mobile-nav-container">
        <Link className="logo" to="/">
          <AppLogo color="#FFF" width="150" height="100%" heightIE="33px" />
        </Link>
        {!noNavRegExp.test(pathname) && (
          <MobileNavIcon className="navicon">
            <Icon
              className="icon-hamburger icon large"
              onClick={openMobileNavigationMenu}
            />
          </MobileNavIcon>
        )}
      </MobileNavbarContainer>
    </MobileNav>
  );
};
