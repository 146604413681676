import firebase from 'firebase';
import moment from 'moment';

// import { uniqueId,  } from 'lodash';
import * as localStorage from '../../hooks/use-local-storage';
import { Feature, UserProfile } from './types';

export const isDueForPasswordRenewal = (user: UserProfile) => {
  const { signedUpDate, passwordChangeDate } = user;

  if (!signedUpDate && !passwordChangeDate) return true;

  const lastChangeDate = moment(passwordChangeDate || signedUpDate);
  const now = moment(); // now
  const diff = now.diff(lastChangeDate, 'days');

  // TODO: move to constant file
  return diff >= 90;
};

export const setIsRenewingPassword = (is = true) => {
  localStorage.setItem('isRenewingPassword', is);
};

export const isRenewingPassword = () => {
  return Boolean(localStorage.getItem('isRenewingPassword', false));
};

export const featuresToObject = (features: Feature[] = []) => {
  return features.reduce((_features, feat) => {
    return { ..._features, [feat.name]: feat.enabled };
  }, {});
};

export const maskEmail = (email: string | null) => {
  if (!email) {
    return; // noop
  }
  const [name, domain] = email.split('@');
  const doms = domain.split('.');
  return `${name[0]}**@${domain[0]}***.${doms[doms.length - 1]}`;
};

export const clearVerifier = () => {
  localStorage.removeItem('verifier');
};

export const createVerifier = (...args: string[]) => {
  const verifier = args.join('/');
  localStorage.setItem('verifier', verifier);
};

export const verify = (...args: string[]) => {
  const verifier = args.join('/');
  const storedVerifier = localStorage.getItem('verifier', null);
  return storedVerifier === verifier;
};

export const GOOGLE_PROVIDER = 'google';
export const APPLE_PROVIDER = 'apple';

export const isPasswordProvider = (): boolean => {
  const { providerData } = firebase.auth().currentUser as firebase.User;
  return !!(providerData || []).find(
    (provider) => provider && provider.providerId === 'password',
  );
};

const loggedInFlag = 'vct';
export const setLoggedIn = (isLoggedIn?: boolean): void =>
  localStorage.setItem(loggedInFlag, isLoggedIn);
export const isAlreadyLoggedIn = (): boolean =>
  localStorage.getItem(loggedInFlag, false);
export const deletedLoggedIn = (): void =>
  localStorage.removeItem(loggedInFlag);
