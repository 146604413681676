import { request } from '../contexts';

export class EnergyDeviceService {
  public static async browseDeviceByUUID(deviceUUID: string) {
    const { data } = await request({
      method: 'GET',
      url: '/api/v2/energy-devices',
      baseURL:
        process.env.ENERGY_GATEWAY_DOMAIN ??
        'https://gateway.energy.dev.helloatka.com',
      params: {
        uuids: deviceUUID,
      },
    });

    return data;
  }
}
