import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';

import {
  AccountContext,
  AxiosContext,
  useAccountContext,
} from '../../contexts';
import {
  APPLE_PROVIDER,
  GOOGLE_PROVIDER,
} from '../../contexts/account-context/utils';
import { StoreContext } from '../../store';
import { BrowserBanner } from '../browser-banner';
import { SignInButtons } from '../buttons';
import { EquipmentPage } from '../equipment';
import ErrorBoundary from '../error-boundary/error-boundary';
import { ForgotPassword } from '../firebaseui/forgot-password';
import { LoginBasePage } from '../firebaseui/login/login-base-page';
import MobileLandingPage from '../firebaseui/mobile-landing-page';
import { ResendActivation } from '../firebaseui/resend-account-activation';
import { ResetPassword } from '../firebaseui/reset-password';
import { SignUpPage } from '../firebaseui/signup/sign-up';
import { SignInEmailForm } from '../forms';
import { NiceWorkModal } from '../modals';
import { TopNav } from '../navigation/topnav';
import {
  AddAlertPage,
  AddPeoplePage,
  BulkAddAlertsPage,
  DashboardPage,
  EditEquipmentPage,
  EditLocationPage,
  EditPeoplePage,
  EnergyAlertsPage,
  EnergyDashboardPage,
  EnergyEquipmentPage,
  EnergyLocationsPage,
  EnergyPeoplePage,
  EnergyReportsAddPage,
  EnergyReportsPage,
  LoadingPage,
  RegistrationPage,
  ReportPage,
  SessionPortalPage,
  TwoFactorAuthPage,
  UserPage,
} from '../pages';
import { NotFoundPage } from '../pages/404';
import CreateTeamPage from '../pages/create-team-page/create-team-page';
import { EnergyLocationDetailsPageLazy } from '../pages/energy-location-details';
import RenewPasswordPage from '../pages/renew-pass-page';
import { FragmentSupportingSwitch } from './utils/switch-with-fragment';

const AdminRoute = (args: RouteProps): JSX.Element => {
  const userContext = useContext(AccountContext);

  if (userContext.isAdmin()) {
    return <Route component={args.component} />;
  } else {
    return <Redirect to={{ pathname: '/' }} />;
  }
};

const PublicPages: FunctionComponent = () => {
  const [displayDownloadApp, setDisplayDownloadApp] = useState(isMobile);
  const { do2FA } = useAccountContext();
  const { dispatch } = useContext(StoreContext);
  const account = useContext(AccountContext);

  const handleGoogleAuth = () => {
    return account.createAuthProvider(GOOGLE_PROVIDER);
  };

  const handleAppleAuth = () => {
    return account.createAuthProvider(APPLE_PROVIDER, [
      'email',
      'name',
      'profile',
    ]);
  };

  return (
    <ErrorBoundary>
      <Switch>
        <FragmentSupportingSwitch>
          {do2FA && (
            <>
              <Route
                exact
                path="/two-factor-auth"
                component={() => <TwoFactorAuthPage />}
              />
              <Route
                path="/"
                render={() => <Redirect to="/two-factor-auth" />}
              />
            </>
          )}
          {!do2FA && (
            <>
              <Route
                path="/app/sensor/:sensorId"
                render={(args) => {
                  dispatch({
                    data: [args.match.params.sensorId],
                    type: 'dashboard:deviceFilter:preload',
                  });
                  const searchParams = new URLSearchParams(
                    args.location.search,
                  );
                  if (searchParams.get('mp_ac') === '1') {
                    const alertUUID = searchParams.get('alert_notif_uuid');
                    dispatch({
                      data: alertUUID,
                      type: 'dashboard:alert:preload',
                    });
                  }
                  return <Redirect to="/" />;
                }}
              />
              {!displayDownloadApp && (
                <>
                  <Route
                    exact
                    path="/"
                    render={() => (
                      <LoginBasePage
                        component={
                          <SignInButtons
                            displayText={'Log in'}
                            isLoading={account.isSSOSignInLoading}
                            handleGoogleAuth={() => handleGoogleAuth()}
                            handleAppleAuth={() => handleAppleAuth()}
                          />
                        }
                      />
                    )}
                  />

                  <Route exact path="/invite" component={SignUpPage} />
                </>
              )}
              {/* If isMobile browser view */}
              {displayDownloadApp && (
                <>
                  <Route
                    exact
                    path="/"
                    component={() => (
                      <MobileLandingPage
                        setDisplayDownloadApp={setDisplayDownloadApp}
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/invite"
                    component={() => (
                      <MobileLandingPage
                        setDisplayDownloadApp={setDisplayDownloadApp}
                      />
                    )}
                  />
                </>
              )}
              <Route
                exact
                path="/signin"
                render={() => (
                  <LoginBasePage
                    component={
                      <SignInEmailForm displayText="Sign in" token={false} />
                    }
                  />
                )}
              />
              <Route
                exact
                path="/forgot-password"
                render={() => {
                  account.setError('');
                  return <LoginBasePage component={<ForgotPassword />} />;
                }}
              />
              <Route
                exact
                path="/reset-password"
                render={() => <LoginBasePage component={<ResetPassword />} />}
              />
              <Route
                exact
                path="/resend-activation"
                render={() => (
                  <LoginBasePage component={<ResendActivation />} />
                )}
              />
              {/* <Route exact path="/" render={() => <Redirect to="/" />} /> */}

              <Route exact path="/signup" component={SignUpPage} />

              {/* Any other route redirect to / */}
              <Route path="*" render={() => <Redirect to="/" />} />
            </>
          )}
        </FragmentSupportingSwitch>
      </Switch>
    </ErrorBoundary>
  );
};

const PrivatePages: FunctionComponent = () => {
  const userContext = useContext(AccountContext);
  const axiosContext = useContext(AxiosContext);
  const { dispatch } = useContext(StoreContext);

  const { user, do2FA, doPassRenewal } = userContext;

  if (!user) {
    return null;
  }

  const { cancelPendings } = axiosContext;
  const [prevRoute, setPrevRoute] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);

    if (prevRoute !== '' && prevRoute !== location.pathname) {
      cancelPendings();

      return;
    }
    setPrevRoute(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <BrowserBanner />
      {/* NOTE: Energy Dashboard is showcasing designs of a new Navbar */}
      {!window.location.pathname.includes('energy') && <TopNav />}
      <ErrorBoundary>
        {(() => {
          if (user.signedUpDate) {
            if (do2FA || doPassRenewal) {
              return (
                <Switch>
                  <Route
                    exact
                    path="/two-factor-auth-registration"
                    component={TwoFactorAuthPage}
                  />
                  <Route
                    exact
                    path="/renew-password"
                    component={() => <RenewPasswordPage />}
                  />
                  {(() => {
                    if (do2FA) {
                      return (
                        <Route
                          path="/"
                          render={() => (
                            <Redirect to="/two-factor-auth-registration" />
                          )}
                        />
                      );
                    } else if (doPassRenewal) {
                      return (
                        <Route
                          path="/"
                          render={() => <Redirect to="/renew-password" />}
                        />
                      );
                    }
                  })()}
                </Switch>
              );
            } else {
              return (
                <Switch>
                  <Route exact path="/" component={DashboardPage} />
                  <Route
                    path="/sensors"
                    render={() => <Redirect to="/equipments" />}
                  />
                  <AdminRoute path="/equipments" component={EquipmentPage} />
                  <AdminRoute path="/users" component={UserPage} />
                  <Route path="/reports" component={ReportPage} />
                  <Route
                    path="/energy/equipment/create"
                    component={EditEquipmentPage}
                  />
                  <Route
                    path="/energy/equipment/edit"
                    component={EditEquipmentPage}
                  />
                  <Route
                    path="/energy/equipment/:equipmentInfo"
                    component={EnergyEquipmentPage}
                  />
                  <Route
                    path="/energy/locations/create"
                    component={EditLocationPage}
                  />
                  <Route
                    path="/energy/locations/edit/:locationUUID"
                    component={EditLocationPage}
                  />
                  <Route
                    path="/energy/locations/:locationUUID"
                    component={EnergyLocationDetailsPageLazy}
                  />
                  <Route
                    path="/energy/locations"
                    component={EnergyLocationsPage}
                  />
                  <Route
                    path="/energy/people/create-team"
                    component={CreateTeamPage}
                  />
                  <Route path="/energy/add-alert" component={AddAlertPage} />
                  <Route
                    path="/energy/bulk-add-alerts"
                    component={BulkAddAlertsPage}
                  />
                  <Route
                    path="/energy/edit-alert/:alertConfigId"
                    component={AddAlertPage}
                  />
                  <Route
                    path="/energy/people/edit-team/:teamId"
                    component={CreateTeamPage}
                  />
                  <Route
                    path="/energy/people/create"
                    component={AddPeoplePage}
                  />
                  <Route
                    path="/energy/people/edit/:userId"
                    component={EditPeoplePage}
                  />
                  <Route path="/energy/people" component={EnergyPeoplePage} />
                  <Route
                    path="/energy/reports/create"
                    component={EnergyReportsAddPage}
                  />
                  <Route path="/energy/reports" component={EnergyReportsPage} />
                  <Route path="/energy/alerts" component={EnergyAlertsPage} />
                  <Route path="/energy" component={EnergyDashboardPage} />
                  <Route path="/session-portal" component={SessionPortalPage} />
                  <Route
                    path="/app/sensor/:sensorId"
                    render={(args) => {
                      dispatch({
                        data: [args.match.params.sensorId],
                        type: 'dashboard:deviceFilter:preload',
                      });
                      const searchParams = new URLSearchParams(
                        args.location.search,
                      );
                      if (searchParams.get('mp_ac') === '1') {
                        const alertUUID = searchParams.get('alert_notif_uuid');
                        dispatch({
                          data: alertUUID,
                          type: 'dashboard:alert:preload',
                        });
                      }
                      return <Redirect to="/" />;
                    }}
                  />
                  <Route path="/" render={() => <Redirect to="/" />} />
                  <Route component={NotFoundPage} />
                </Switch>
              );
            }
          } else {
            return (
              <Switch>
                <Route
                  exact
                  path="/registration"
                  component={RegistrationPage}
                />
                <Route
                  path="/"
                  render={() => <Redirect to="/registration" />}
                />
                <Route component={NotFoundPage} />
              </Switch>
            );
          }
        })()}
      </ErrorBoundary>
    </>
  );
};

export const BasePage: FunctionComponent = () => {
  const userContext = useContext(AccountContext);
  const { user, isInitializing } = userContext;

  if (isInitializing) {
    return <LoadingPage />;
  }

  if (!user) {
    return (
      <ErrorBoundary>
        <PublicPages />
      </ErrorBoundary>
    );
  }

  return (
    <>
      <NiceWorkModal />
      <PrivatePages />
    </>
  );
};
