import React from 'react';

import { ErrorPage } from '../pages';

export interface ErrorBoundaryState {
  error?: unknown;
  errorInfo?: {
    componentStack?: string | null;
  } | null;
}

export interface ErrorPageProps extends ErrorBoundaryState {
  children?: React.ReactNode;
}

class ErrorBoundary extends React.Component<
  ErrorPageProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryState) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  public componentDidCatch(
    error: ErrorBoundaryState['error'],
    errorInfo: ErrorBoundaryState['errorInfo'],
  ) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });

    // You can also log error messages to an error reporting service here
  }

  public render() {
    if (!this.state.errorInfo) {
      return this.props.children;
    }
    return (
      <ErrorPage error={this.state.errorInfo?.componentStack ?? undefined} />
    );
  }
}

export default ErrorBoundary;
