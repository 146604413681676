// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { grey100, thermaH2FontFamily } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

interface LocationFormProps {
  count?: number;
  isNew: boolean;
  name: string | React.ReactNode;
  type?: 'sensor' | 'location' | 'equipment';
  label?: string;
  unit?: string;
  subheader?: string | React.ReactNode;
}

const SensorNumber = styled.div`
  margin-top: 5px;
  color: ${grey100};
  font-family: ${thermaH2FontFamily};
`;

const TitleHeader = styled.div`
  padding-left: 0;
  max-width: 100%;

  &&&& .title-header {
    font-family: ${thermaH2FontFamily};
    font-size: 16px;
    margin: 0;
    line-height: 25px;
  }
`;

const AlertReminderCount = styled.div`
  display: inline-block;
  background-color: rgb(107, 164, 184);
  border-radius: 5px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  padding: 0px 10px 3px;
  line-height: 1.5em;
  font-weight: normal;
`;

export const LineItemHeader: FunctionComponent<LocationFormProps> = (props) => {
  const { isNew, type = '', name, label = '', subheader } = props;

  return (
    <TitleHeader>
      {!!label && <AlertReminderCount> {label} </AlertReminderCount>}
      {React.isValidElement(name) ? (
        name
      ) : (
        <Header className="title-header ellipse-text" as="h2">
          {isNew ? <span>New {`${type}`}</span> : name}
        </Header>
      )}
      <SensorNumber>{subheader}</SensorNumber>
    </TitleHeader>
  );
};
