import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const ButtonAsLinkStyle = styled.button`
  background: none !important;
  border: none;
  font-family: 'Muli', sans-serif;
  padding: 0 !important;
  color: #05f;
  text-decoration: underline;
  cursor: pointer;
`;

interface ButtonAsLinkProps {
  onClick?: () => void | Promise<void>;
  className?: string;
  children: React.ReactNode;
}
export const ButtonAsLink: FunctionComponent<ButtonAsLinkProps> = (props) => {
  const { onClick, children, className = '' } = props;
  if (!onClick) {
    return null;
  }

  return (
    <ButtonAsLinkStyle onClick={onClick} className={className}>
      {children}
    </ButtonAsLinkStyle>
  );
};
