import { createMedia } from '@artsy/fresnel';
import {
  computerMinWidth,
  largeScreenMinWidth,
  mobileMinWidth,
  tabletMinWidth,
  w397,
  w414,
  w415,
  w430,
  w540,
  w768,
  w1024,
  w1078,
  w1079,
  w1280,
  wideScreenMinWidth,
} from 'src/constants';

const AppMedia = createMedia({
  breakpoints: {
    mobile: mobileMinWidth,
    tablet: tabletMinWidth,
    computer: computerMinWidth,
    largeScreen: largeScreenMinWidth,
    widescreen: wideScreenMinWidth,
    w397: w397,
    w414: w414,
    w415: w415,
    w430: w430,
    w540: w540,
    w1024: w1024,
    w1079: w1079,
    w1078: w1078,
    w768: w768,
    w1280: w1280,
  },
});

export const mediaStyles = AppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = AppMedia;
