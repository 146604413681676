/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: This needs to be rewritten in order to remove the disable clauses.
// The payload type is impossible to determine.
import React, { createContext } from 'react';

import {
  StandardModal,
  StandardModalProps,
} from '../components/modals/standard-modal';

export const StandardModalContext = createContext({
  hideModal: (): void => {},
  modalData: null,
  openModal: (payload: StandardModalProps | string | JSX.Element): void => {},
  setModalData: (payload: any): void => {},
});

export class StandardizedModal extends React.Component<
  StandardModalProps & { children?: React.ReactNode },
  any
> {
  constructor(props: StandardModalProps) {
    super(props);
    this.state = {
      modalState: {
        isOpen: false,
        modalData: null,
      },
    };
  }

  public render() {
    const setModalState = (
      payload: StandardModalProps | string | JSX.Element,
    ) => {
      let modalState;

      if (typeof payload !== 'object') {
        modalState = {
          content: payload,
          isOpen: true,
        };
      } else {
        modalState = {
          isOpen: true,
          ...payload,
        };
      }

      this.setState({
        modalState: {
          ...modalState,
        },
      });
    };

    const handleOutsideModalClick = (
      event: any,
      payload?: StandardModalProps,
    ) => {
      // just check if click event occured in modal dimmer to make it simple
      if (
        event?.target.classList.contains('modals') &&
        event?.target.classList.contains('dimmer') &&
        !this.state.modalState?.disableCloseOnOutsideClick
      ) {
        setModalState({ isOpen: false });
        payload?.onClose && payload?.onClose();
      }
    };

    const standardModalContextAPI: StandardModalContext = {
      openModal: (payload: StandardModalProps | string | JSX.Element) => {
        setModalState(payload);
        document.addEventListener('mousedown', (event) =>
          handleOutsideModalClick(event, payload as StandardModalProps),
        );
      },

      hideModal: () => {
        setModalState({ isOpen: false });
        document.removeEventListener('mousedown', (event) =>
          handleOutsideModalClick(event),
        );
      },

      modalData: this.state.modalState.modalData,

      setModalData: (payload: any) => {
        this.setState({
          modalState: {
            modalData: payload,
          },
        });
      },
    };

    return (
      <StandardModalContext.Provider value={standardModalContextAPI}>
        <StandardModal {...this.state.modalState} />
        {this.props.children}
      </StandardModalContext.Provider>
    );
  }
}

export interface StandardModalContext {
  hideModal: () => void;
  modalData: any;
  openModal: (payload: StandardModalProps | string | JSX.Element) => void;
  setModalData: (payload: any) => void;
}
