/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: This needs to be rewritten in order to remove the disable clauses.
// The prop children is used both in as component prop and in the state.
import React, { createContext } from 'react';

import { BaseSlidingSidebar, SlidingSidebarProps } from '../components';
import { setScrollable, setUnscrollable } from '../lib/scrollable';
export const SlidingSidebarContext = createContext({
  closeSidebar: (): void => undefined,
  openSidebar: (options: SlidingSidebarProps) => ({ options }),
});

export class SlidingSidebar extends React.Component<
  { children?: React.ReactNode },
  any
> {
  constructor(props: { children?: React.ReactNode }) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  public closeSidebar = () => {
    this.setState({
      visible: false,
    });
  };

  public render() {
    const slidingSidebarContextAPI: any = {
      closeSidebar: () => {
        this.closeSidebar();
        setScrollable();
      },

      openSidebar: (options: SlidingSidebarProps) => {
        this.setState({
          visible: true,
          ...options,
        });
        setUnscrollable();
      },
    };

    const { children, ...rest } = this.state;

    return (
      <SlidingSidebarContext.Provider value={slidingSidebarContextAPI}>
        <BaseSlidingSidebar {...rest}>{children}</BaseSlidingSidebar>
        {this.props.children}
      </SlidingSidebarContext.Provider>
    );
  }
}
export interface SlidingSidebar {
  closeSidebar: () => void;
  openSidebar: (options: SlidingSidebarProps) => void;
}
