/* eslint-disable @typescript-eslint/no-empty-function */
import { NotificationMethod } from '@coinspect/atka-types';
import { formatNumber, FormInput } from '@coinspect/ui';
import { isValidEmail, isValidPhoneNumber } from '@coinspect/utils';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, FormContext, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { AccountContext } from '../../../contexts';
import { useTeamService, useUserService } from '../../../hooks';
import { TeamBaseModel, UserModel } from '../../../services';
import { StoreContext } from '../../../store';
import { Button } from '../../buttons';
import { EnergyDropdown } from '../../dropdown';
import ErrorBoundary from '../../error-boundary/error-boundary';
import EnergyDeleteModal from '../../modals/energy-delete-modal';
import EnergyModal from '../../modals/energy-modal';
import EnergyMobileNav from '../energy-dashboard-page/energy-mobile-nav';
import EnergyNav from '../energy-dashboard-page/energy-nav';
import ArrowIcon from '../energy-dashboard-page/icons/ArrowIcon';
import {
  EnergyReportsHeader,
  EnergyReportsHeaderMenu,
  EnergyReportsHeaderText,
  ReportsContent,
  ReportsPageContainer,
} from '../energy-reports-page/index-styles';
import { NOTIF_OPTIONS } from '../utils/notification-options';

type EditPeopleForm = {
  email: string;
  notificationMethods: NotificationMethod[];
  phonenumber: string;
  countryCode: string;
  role: string;
  teams: string[];
};

const phonePrefix = ['+1', '+30', '+44', '+51', '+52', '+63', '+64'];
const phonePrefixOptions = phonePrefix.map((countryCode) => {
  return { text: countryCode, value: countryCode };
});

const ROLE_OPTIONS = [
  {
    text: 'Admin',
    value: 'admin',
  },
  {
    text: 'Viewer',
    value: 'user',
  },
];

const getBaseValue = (val: string) =>
  `${(val.trim() || '').replace(/[^0-9]/gi, '')}`;

export const EditPeoplePage: React.FC = (args) => {
  const history = useHistory();
  const { store } = useContext(StoreContext);
  const { user: currentUser } = useContext(AccountContext);
  const [initialTeams, setInitialTeams] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const teamsMap = store.pages.teams.byUUID;
  const allTeams = Object.values(teamsMap);
  const userIdParam = args.match.params.userId;
  const ownAccount = currentUser?.uuid === userIdParam;
  const user = store.pages.users.allUsers.byUUID[userIdParam];

  const [showEnergyDeleteModal, setEnergyDeleteModal] = useState(false);
  const [setOnSubmitError] = useState('');

  const { browseAllUsers, editUsers, editSelf, deleteUser } = useUserService();
  const { browseTeam, editTeam } = useTeamService();

  const formMethods = useForm<EditPeopleForm>({
    mode: 'onChange',
  });

  const { setValue } = formMethods;

  useEffect(() => {
    const fetchData = async () => {
      await browseTeam();
      await browseAllUsers();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (user) {
      setValue('role', user.role);
      setValue('email', user.email);
      const [cc, pn] = user.phoneNumber.split('-');
      setValue('countryCode', cc);
      setValue('phonenumber', formatNumber(pn));
      setValue(
        'notificationMethods',
        user.notificationMethods as NotificationMethod[],
      );
      const teams = allTeams
        .filter((team) =>
          team.members.map((member) => member.userUUID).includes(user.uuid),
        )
        .map((team) => team.uuid);
      setValue('teams', teams);
      setInitialTeams(teams);
    }
  }, [user]);

  const closeModal = () => {
    onCancel();
  };

  const handleDeleteUser = async () => {
    try {
      await deleteUser(user.uuid);
    } catch (err) {
      setOnSubmitError(err.response.data.errors[0].details);
      return;
    }

    history.push('/energy/people?tab=people');
  };

  const onSubmit = async (data: EditPeopleForm) => {
    setIsUpdating(true);

    const request = {
      firstName: user.firstName,
      lastName: user.lastName,
      preferredTempUnit: user.preferredTempUnit,
      email: data.email,
      phoneNumber: `${data.countryCode}-${getBaseValue(data.phonenumber)}`,
      role: data.role,
      notificationMethods: data.notificationMethods,
    };

    const res = await (ownAccount
      ? editSelf(request as UserModel)
      : editUsers(userIdParam, request as UserModel));
    if (res.fail) {
      return res.fail;
    }

    // Teams have members, users do not have teams
    const teamsPromises = [] as Promise<TeamBaseModel>[];
    const { add, remove } = data.teams.reduce(
      (acc, teamId) => {
        if (!initialTeams.includes(teamId)) {
          acc.add.push(teamId);
        }
        acc.remove = acc.remove.filter((t) => t !== teamId);
        return acc;
      },
      {
        add: [] as string[],
        remove: initialTeams,
      },
    );

    // Add member to team
    add.forEach((teamId) => {
      const req = {
        name: teamsMap[teamId].name,
        locations: teamsMap[teamId].locations.map((l) => l.locationUUID),
        members: [
          ...teamsMap[teamId].members.map((m) => m.userUUID),
          user.uuid,
        ],
      };
      teamsPromises.push(editTeam(teamId, req));
    });

    // Remove member from team
    remove.forEach((teamId) => {
      const req = {
        name: teamsMap[teamId].name,
        locations: teamsMap[teamId].locations.map((l) => l.locationUUID),
        members: teamsMap[teamId].members
          .map((m) => m.userUUID)
          .filter((u) => u !== user.uuid),
      };
      teamsPromises.push(editTeam(teamId, req));
    });

    await Promise.all(teamsPromises).finally(() => {
      setIsUpdating(false);
      history.push('/energy/people');
    });
  };

  const onCancel = () => {
    history.push('/energy/people');
  };

  const isFormInvalid = () => {
    return false;
  };

  return (
    <ErrorBoundary>
      <ReportsPageContainer style={{ paddingBottom: '250px' }}>
        <EnergyNav page="PEOPLE" />
        <EnergyMobileNav page="PEOPLE" />
        <ReportsContent>
          <EnergyReportsHeader>
            <EnergyReportsHeaderMenu>
              <EnergyReportsHeaderText>
                <Link to="/energy/people">
                  <ArrowIcon
                    style={{
                      height: '24px',
                      transform: 'rotate(270deg)',
                      width: '24px',
                    }}
                  />
                </Link>
                Edit people
              </EnergyReportsHeaderText>
            </EnergyReportsHeaderMenu>
          </EnergyReportsHeader>
          <EnergyModal
            handleClose={() => history.push(`/energy/people`)}
            title={`${user?.firstName || ''} ${user?.lastName || ''}`}
            hideOverlay
            setTopMargin="25px"
          >
            <Form>
              <FormContext {...formMethods}>
                <Controller
                  as={
                    <EnergyDropdown options={ROLE_OPTIONS} label="User role" />
                  }
                  name="role"
                  onChange={(event) => event[1].value}
                  defaultValue={''}
                  style={{ marginBottom: '16px' }}
                />
                <FormInput
                  className="energy-user-fields"
                  name="email"
                  label="Email address"
                  labelClassName="energy-user-label"
                  style={{ height: '60px' }}
                  required
                  customErrors={{
                    email: 'Invalid email format.',
                    required: 'Email address is required.',
                  }}
                  validation={{
                    email: (val): boolean => isValidEmail(val),
                  }}
                />
                <div style={{ display: 'flex' }}>
                  <Controller
                    as={
                      <EnergyDropdown
                        options={phonePrefixOptions}
                        fluid
                        style={{
                          height: '60px',
                          borderRight: '2px solid #41484D',
                          width: '100px',
                        }}
                        defaultValue={phonePrefix[0]}
                      />
                    }
                    name="countryCode"
                    onChange={(event) => event[1].value}
                    defaultValue={phonePrefix[0]}
                  />
                  <div style={{ flex: 1 }}>
                    <FormInput
                      className="energy-user-fields"
                      name="phonenumber"
                      label="Phone number"
                      labelClassName="energy-user-label"
                      error={!!formMethods.errors.phonenumber}
                      required
                      customErrors={{
                        phoneNumber: 'Invalid phone number.',
                        required: 'Phone number is required.',
                      }}
                      validation={{
                        phoneNumber: (val): boolean =>
                          isValidPhoneNumber(
                            `${formMethods.watch('countryCode')}${val}`,
                          ),
                      }}
                    />
                  </div>
                </div>
                <Controller
                  as={
                    <EnergyDropdown
                      text="Alert notification methods"
                      options={NOTIF_OPTIONS}
                      fluid
                      selection
                      multiple
                      defaultValue={[]}
                      label="Alert notification methods"
                    />
                  }
                  name="notificationMethods"
                  onChange={(event) => event[1].value}
                  defaultValue={[]}
                  style={{ marginBottom: '16px' }}
                />
                <Controller
                  as={
                    <EnergyDropdown
                      text="Teams"
                      options={allTeams.map((team) => {
                        return {
                          value: team.uuid,
                          text: team.name,
                        };
                      })}
                      multiple
                      selection
                      search
                      label="Teams"
                    />
                  }
                  name="teams"
                  defaultValue={[]}
                  onChange={(event) => event[1].value}
                />
                <div
                  style={{
                    display: 'flex',
                    gap: '16px',
                    justifyContent: 'flex-end',
                    marginTop: '24px',
                  }}
                >
                  <Button
                    style={{ color: '#FFABAB' }}
                    onClick={() => setEnergyDeleteModal(true)}
                    as="text"
                  >
                    Delete User
                  </Button>
                  <Button onClick={onCancel} as="outline">
                    Cancel
                  </Button>
                  <Button
                    onClick={formMethods.handleSubmit((data) => {
                      onSubmit(data);
                    })}
                    disabled={isFormInvalid() || isUpdating}
                    loading={isUpdating}
                  >
                    Save
                  </Button>
                </div>
              </FormContext>
            </Form>
          </EnergyModal>
        </ReportsContent>
        {showEnergyDeleteModal && (
          <EnergyDeleteModal
            entityName="user"
            handleClose={() => closeModal()}
            handleDelete={() => handleDeleteUser()}
          />
        )}
      </ReportsPageContainer>
    </ErrorBoundary>
  );
};
