import { FormInput } from '@coinspect/ui';
import { isValidEmail } from '@coinspect/utils';
import React from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Button, Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { isValidJoiEmail } from '../../lib';

interface ForgotPasswordFormProps {
  onSubmit: (email: string) => Promise<void>;
}

export interface ForgotPasswordFormFields {
  email: string;
}

const SendEmailButton = styled(Button)`
  padding-top: 30px;
  &&& {
    margin-top: 1.5em;
  }
`;

interface FormData {
  email: string;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (
  props,
) => {
  const methods = useForm<FormData>();
  const { isSubmitting } = methods.formState;

  const handleSendEmail = methods.handleSubmit(async (data) => {
    await props.onSubmit(data.email);
    methods.setValue('email', '');
  });

  return (
    <>
      <FormContext {...methods}>
        <Form onSubmit={handleSendEmail} noValidate>
          <FormInput
            className="forgot-password-form__email"
            name="email"
            label="Email:"
            type="email"
            customErrors={{
              required: 'Email address is required.',
            }}
            validation={{
              email: (val) => isValidEmail(val) && isValidJoiEmail(val),
            }}
          />

          <SendEmailButton
            className="forgot-password-form__button--send-email"
            primary
            fluid
            type="submit"
            disabled={isSubmitting}
          >
            Send email
          </SendEmailButton>
        </Form>
      </FormContext>

      <Link
        to="/"
        style={{
          color: '#dc7f9b',
          display: 'flex',
          fontFamily: 'proxima-nova',
          fontSize: '14px',
          fontWeight: 'bold',
          justifyContent: 'center',
          paddingTop: '40px',
        }}
      >
        Back to log in
      </Link>
    </>
  );
};
