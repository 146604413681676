import { DataStoreAction } from '../';
import { ReportConfigType, ReportProps } from '../../services';

export const REPORT_ACTIONS = {
  SET_REPORT_FORM_DATA: 'reportsPage:reportData:set',
};

export type ReportPageState = {
  dateRange: {
    end: string;
    labelIndex: number;
    start: string;
  };
  activeForms: number;
  reportForm: {
    type: ReportConfigType | '';
    fields: ReportProps | null;
  };
};

export const initialReportPageStore: ReportPageState = {
  dateRange: {
    start: '',
    end: '',
    labelIndex: -1,
  },
  activeForms: 0,
  reportForm: {
    type: '',
    fields: null,
  },
};

export function reportPageReducer(
  state: ReportPageState,
  action: DataStoreAction,
) {
  switch (action.type) {
    case 'reportForm:dateRange:set':
      const { start, end, labelIndex } = action.data;
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          start,
          end,
          labelIndex,
        },
      };
    case REPORT_ACTIONS.SET_REPORT_FORM_DATA:
      return {
        ...state,
        reportForm: {
          ...state.reportForm,
          type: action.data.type,
          fields: action.data.fields,
        },
      };
  }
  return state;
}
