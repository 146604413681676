import {
  FlexPaddedContent,
  greyMystic,
  redErrorColor,
  white100,
} from '@coinspect/ui';
import omit from 'lodash/omit';
import mixpanel from 'mixpanel-browser';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Accordion, Button, Dropdown, Icon, List } from 'semantic-ui-react';
import styled from 'styled-components';

import { mobileMaxWidthPx } from '../../constants/responsive-constants';
import {
  AlertType,
  useDeviceService,
  useDialogs,
  useFeatures,
  useLocationService,
  useQuery,
} from '../../hooks';
import { Device, DeviceInfo, DeviceModel } from '../../services';
import { StoreContext } from '../../store';
import { SmartAlertButton } from '../buttons';
import { AddTextButton } from '../buttons/add-text-button';
import { FormCloseButton } from '../forms';
import { LineItemHeader } from '../locations/line-item-header';
import { SensorFormContainer } from '../locations/location-line-item';
import { useDevicePage } from '../pages/use-device-page';
import { Tooltip } from '../tooltip';
import { AccordionContent, useAccordion } from '../ui/accordion';
import { DeviceForm } from './device-form';

const ListItem = styled(List.Item)`
  border-bottom: 1px solid ${greyMystic};
  &:first-child {
    border-top: 1px solid ${greyMystic};
  }
  &:last-child {
    border-bottom: 0px;
  }
`;

const HeaderContainer = styled(FlexPaddedContent)`
  justify-content: space-between;
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    display: block;
  }
`;

const SensorHeader = styled.div`
  display: grid;
  width: 50%;
  align-items: center;
  margin-left: 0;
  grid-template-columns: 50px 1fr;
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    padding-left: 0px;
    margin-left: -8px;
    width: 100%;
    align-items: start;
  }
  .icon {
    margin-right: 30px;
  }
`;

const LocationActionButtons = styled.div`
  display: flex;
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding-left: 61px;
    padding-top: 15px;
    &&&& {
      flex-direction: unset;
      justify-content: center;
      padding-left: 5px;
      .circular.button {
        margin-right: 10px;
        width: 43px;
        height: 43px;
      }
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  &&& {
    opacity: 1 !important;

    &&& i.icon {
      margin-left: -20px;
    }

    &&& div.menu {
      width: 173px;
      font-family: proxima-nova;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const PANEL_NAME_PREFIX = 'device-list-item-';

export interface DeviceListItemProps {
  device: DeviceInfo;
  isFetchingAlerts: boolean;
  isScrollLocation?: boolean;
  setAddAlertClicked: (uuid: string, value?: AlertType | null) => void;
  children?: React.ReactNode;
}

export const DeviceListItem: FunctionComponent<DeviceListItemProps> = (
  props,
) => {
  const { isEquipmentEnabled } = useFeatures();
  const { openPanel, togglePanel, initPanel, panels } = useAccordion();
  const { store } = useContext(StoreContext);
  const { addActiveForm, removeActiveForm, hasActiveForm } = useDevicePage();
  const { openDeleteDialog, closeDialog } = useDialogs();
  const [isEditing, setIsEditing] = useState(false);
  const [isTogglingSmartAlert, setDeviceTogglingSmartAlert] = useState<boolean>(
    false,
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const {
    editDevice,
    deleteDevice,
    toggleDeviceSmartAlert,
  } = useDeviceService();
  const {
    device,
    isFetchingAlerts,
    isScrollLocation,
    setAddAlertClicked,
  } = props;
  const panelName = `${PANEL_NAME_PREFIX}${device.uuid}`;
  const { deviceAlertConfigs } = store.relationships;
  const { getLocationInfo } = useLocationService();

  const [isSmartAlertActive, setSmartAlertActive] = useState(
    device.isSmartAlert,
  );

  const query = useQuery();

  const [tutorialDevices] = useState<DeviceModel[]>([]);
  const [tutorialDevice] = useState('');
  const scrollRef = useRef<HTMLInputElement>(null);

  const { latestHumidity } = store.pages.sensors;
  const [hasHumidity, setHasHumidity] = useState<boolean>(false);

  const defaultTabColor = isEquipmentEnabled
    ? device.serialNumber
      ? white100
      : redErrorColor
    : white100;

  useEffect(() => {
    setHasHumidity(Boolean(latestHumidity?.[device.uuid]));
  }, [latestHumidity]);

  const toggleDevicePanelsNoAlerts = () => {
    if (
      !isFetchingAlerts &&
      (!deviceAlertConfigs.hasOwnProperty(device.uuid) ||
        deviceAlertConfigs[device.uuid].length === 0)
    ) {
      openPanel(panelName);
    } else {
      initPanel(panelName, false);
    }
  };

  useEffect(() => {
    if (scrollRef && scrollRef.current && tutorialDevice == device.uuid) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [tutorialDevice]);

  useEffect(() => {
    const sensorIdList = query.getAll('sensorId');

    if (
      isScrollLocation &&
      scrollRef &&
      scrollRef.current &&
      sensorIdList[0] === device.uuid
    ) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }

    if (!sensorIdList.length) {
      toggleDevicePanelsNoAlerts();
      return;
    }

    sensorIdList.forEach((sensorId) => {
      if (sensorId === device.uuid) {
        toggleDevicePanelsNoAlerts();
      }
    });
  }, []);

  const saveDeviceEdit = async (payload: Device): Promise<void> => {
    await editDevice(device.uuid, omit(payload));
    removeActiveForm();
    setIsEditing(false);
  };

  function confirmDelete() {
    openDeleteDialog({
      content: (
        <>
          Are you sure you want to delete&nbsp;
          <strong>{device.name || device.serialNumber}</strong>?
          <p>
            This also deletes all associated alert data, and cannot be undone.
          </p>
        </>
      ),
      onConfirm: async () => {
        await deleteDevice(device.uuid);

        mixpanel.track('Sensor Deleted', {
          sensor_id: device.uuid,
          sensor_name: device.name,
          sensor_serial_number: device.serialNumber,
          location_name: (await getLocationInfo(device.locationUUID)).name,
        });

        closeDialog();
      },
      disableButtonsOnQuery: 'device:delete',
    });
  }

  async function toggleSmartAlert() {
    setDeviceTogglingSmartAlert(true);
    try {
      const deviceSmartAlert = await toggleDeviceSmartAlert(
        device.uuid,
        !isSmartAlertActive,
      );
      setSmartAlertActive(deviceSmartAlert.is_smart_alert);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Error in updating device status: ${err}`);
    } finally {
      setDeviceTogglingSmartAlert(false);
    }
  }

  function showEditing() {
    setIsEditing(true);
    addActiveForm();
    openPanel(panelName);
  }

  function hideEditing() {
    setIsEditing(false);
    removeActiveForm();
  }

  return (
    <div
      ref={scrollRef}
      id={tutorialDevice == device.uuid ? 'missing-alerts-device' : ''}
    >
      <ListItem>
        <Accordion.Title
          style={{
            background: isDragging ? 'lightgrey' : defaultTabColor,
          }}
          active={panels[panelName]}
        >
          <Droppable droppableId={`${device.uuid}|title`}>
            {(provided, snapshot) => {
              setIsDragging(snapshot.isDraggingOver);
              return (
                <HeaderContainer
                  ref={provided.innerRef}
                  withIndention
                  size="20px 30px 20px 30px"
                >
                  <SensorHeader
                    onClick={() => {
                      if (hasActiveForm() || isFetchingAlerts) {
                        return;
                      }
                      togglePanel(panelName);
                    }}
                  >
                    <Icon
                      name={isFetchingAlerts ? 'circle notch' : 'chevron down'}
                      rotated={
                        panels[panelName] ? undefined : 'counterclockwise'
                      }
                      className={
                        isFetchingAlerts ? 'rotating' : 'ease-transition'
                      }
                    />
                    <LineItemHeader
                      name={device.name}
                      subheader={device.serialNumber}
                      isNew={false}
                      label={
                        tutorialDevice == device.uuid &&
                        tutorialDevices.length > 1
                          ? `Sensors Remaining ${tutorialDevices.length}`
                          : ''
                      }
                    />
                    {isEditing && (
                      <FormCloseButton
                        onClick={hideEditing}
                        style={{ right: '32px' }}
                      />
                    )}
                  </SensorHeader>
                  <LocationActionButtons>
                    {!isEditing && (
                      <>
                        {hasHumidity ? (
                          <StyledDropdown
                            icon=""
                            trigger={
                              <AddTextButton
                                label="Add alert"
                                className="add-sensor"
                                disabled={hasActiveForm()}
                              />
                            }
                            id="alert-1"
                            options={[
                              {
                                key: 'Temperature',
                                text: 'Temperature',
                                value: 'temperature',
                                selected: false,
                              },
                              {
                                key: 'Humidity',
                                text: 'Humidity',
                                value: 'humidity',
                                selected: false,
                              },
                            ]}
                            disabled={hasActiveForm()}
                            value={null}
                            selectOnBlur={false}
                            onChange={(
                              e: unknown,
                              opt: { value: AlertType | null | undefined },
                            ) => {
                              openPanel(panelName);
                              setAddAlertClicked(device.uuid, opt.value);
                            }}
                          />
                        ) : (
                          <AddTextButton
                            onClick={() => {
                              openPanel(panelName);
                              setAddAlertClicked(device.uuid, 'temperature');
                            }}
                            label="Add alert"
                            className="add-sensor"
                            disabled={hasActiveForm()}
                          />
                        )}
                        <Tooltip
                          content="Delete"
                          trigger={
                            <Button
                              disabled={hasActiveForm()}
                              floated="right"
                              circular
                              icon
                              onClick={(e) => {
                                e.stopPropagation();
                                confirmDelete();
                              }}
                              className="therma-off-white"
                            >
                              <Icon
                                className="icon-delete"
                                size="large"
                                aria-label="Delete sensor"
                              />
                            </Button>
                          }
                        />
                        <Tooltip
                          content="Edit"
                          trigger={
                            <Button
                              disabled={hasActiveForm()}
                              floated="right"
                              circular
                              icon
                              onClick={(e) => {
                                if (isFetchingAlerts) return;
                                e.stopPropagation();
                                showEditing();
                              }}
                              className="therma-off-white"
                            >
                              <Icon
                                className="icon-edit"
                                size="large"
                                aria-label="Edit sensor"
                              />
                            </Button>
                          }
                        />
                        <SmartAlertButton
                          isActive={isSmartAlertActive}
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleSmartAlert();
                          }}
                          isQueryInProgress={isTogglingSmartAlert}
                        />
                      </>
                    )}
                  </LocationActionButtons>
                </HeaderContainer>
              );
            }}
          </Droppable>
        </Accordion.Title>
        <AccordionContent active={panels[panelName]}>
          {isEditing && (
            <SensorFormContainer withIndention size="30px 75px 15px 107px">
              <DeviceForm
                locationUUID={props.device.locationUUID}
                onCancel={hideEditing}
                onSubmit={saveDeviceEdit}
                device={props.device}
                editing={isEditing}
              />
            </SensorFormContainer>
          )}
          {!isEditing && props.children}
        </AccordionContent>
      </ListItem>
    </div>
  );
};
