import { darkBlue80, FieldLabel, stoneBlue60 } from '@coinspect/ui';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import inflection from 'lodash-inflection';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { mobileMaxWidth, tabletMaxWidth, w768, w1024 } from '../../constants';
import { DashboardCard } from './';

const DashboardMiniMetricsWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1150px) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  @media only screen and (max-width: ${tabletMaxWidth}px) {
    .label {
      text-align: left;
    }

    .label.dashboard-mini-metrics-value {
      width: 200px;
      text-align: left;
    }
  }

  .label {
    font-size: 18px;
    padding: 0;
    color: ${stoneBlue60};
    @media only screen and (min-width: ${w768}px) and (max-width: ${w1024}px) {
      margin: 0;
    }
    @media only screen and (max-width: ${mobileMaxWidth}px) {
      width: 100%;
      text-align: center;
      margin: 0;
    }
  }

  && > .dashboard-mini-metrics-value {
    font-size: 42px;
    padding: 0 10px 0 0;
    color: ${darkBlue80};

    @media only screen and (max-width: ${tabletMaxWidth}px) {
      padding: 0 0 15px 0;
      margin: 0;
      width: 100%;
      text-align: center;
    }
  }
`;

interface DashboardMiniMetricsProps {
  period: number;
  value: number;
  className?: string;
}

export const DashboardMiniMetrics: React.FC<DashboardMiniMetricsProps> = (
  props,
) => {
  const { period, value, className } = props;
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    setLabel(
      `${inflection.pluralize(
        'Alert',
        +value,
        false,
      )} over the past ${inflection.pluralize('day', +period, period !== 1)}`,
    );
  }, [value]);

  return (
    <DashboardCard query="metrics:fetch" className={className}>
      <DashboardMiniMetricsWrapper>
        <FieldLabel
          className="dashboard-mini-metrics-value dashboard-mini-metrics__value"
          content={value}
        />
        <FieldLabel className="dashboard-mini-metrics__label" content={label} />
      </DashboardMiniMetricsWrapper>
    </DashboardCard>
  );
};
