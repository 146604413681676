import React from 'react';
import styled from 'styled-components';
const GoogleIconSrc = require('../google-icon.png');

export const TagImg = styled.img`
  width: 18px;
`;

export const GoogleIcon = () => {
  return <TagImg src={GoogleIconSrc} alt="google-icon" />;
};
