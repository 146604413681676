import {
  darkViolet20,
  darkViolet80,
  FlexPaddedContent,
  greyGeyser,
  PillIcon,
  thermaBlack,
} from '@coinspect/ui';
import React, { FunctionComponent, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { RESOURCE_HUB } from '../../constants';
import { AccountContext, SlidingSidebarContext } from '../../contexts';
import { useEditUser } from '../../hooks';
import { ButtonAsLink } from '../buttons';
import { AvatarCircle } from '../users';

export const HeaderUserMenu: FunctionComponent = () => {
  const history = useHistory();
  const accountContext = useContext(AccountContext);
  const { user } = accountContext;
  const { closeSidebar } = useContext(SlidingSidebarContext);
  if (!user) {
    return null;
  }
  const { firstName = '', lastName = '' } = user;
  const { editUser } = useEditUser();
  const Avatar = (
    <AvatarCircle
      firstName={firstName}
      lastName={lastName}
      size={40}
      fontSize={14}
      margin="0 0 0 30px"
      bgColor="#FFF"
      fontColor={thermaBlack}
    />
  );

  const UserMenuDropdown = styled(Dropdown.Menu)`
    &&& {
      margin-top: 17px;
      box-shadow: 0 2px 10px ${darkViolet20};
    }
  `;
  const DropdownItem = styled(Dropdown.Item)`
    &&&.item {
      min-width: 250px;
      border-bottom: 1px solid ${greyGeyser};
    }
  `;
  const DropdownItemContent = styled.div`
    flex-grow: 1;
    margin-left: 10px;
    & .name {
      margin-bottom: 2px;
      color: ${darkViolet80};
      font-size: 16px;
      font-weight: 700;
      max-width: 200px;
    }
    & .link {
      font-size: 14px;
    }
  `;

  const logoutClick = () => {
    closeSidebar();
    accountContext.logout();
  };

  return (
    <>
      <Dropdown icon={null} trigger={Avatar}>
        <UserMenuDropdown direction="left" className="user-menu">
          <DropdownItem
            onClick={() =>
              editUser(user, {
                header: 'Edit my account',
                isFieldsDisabled: false,
              })
            }
          >
            <FlexPaddedContent size="0">
              <PillIcon as="div">
                <AvatarCircle
                  className="user-menu__avatar-icon"
                  firstName={firstName}
                  lastName={lastName}
                  size={40}
                  fontSize={14}
                  fontColor="#858D9B"
                  bgColor="#F2F3F7"
                />
              </PillIcon>
              <DropdownItemContent>
                <div className="name ellipse-text capitalize user-menu__full-name">
                  {firstName} {lastName}
                </div>
                <ButtonAsLink className="link">My account</ButtonAsLink>
              </DropdownItemContent>
            </FlexPaddedContent>
          </DropdownItem>
          {/* 
                //Disabling this dropdown item until we can update the Therma branded assets in the setup tutorial
          
          <DropdownItem
            onClick={() => {
              history.push('/');
              dispatch({
                type: 'dashboard:onboarding:disableQuiz',
              });
            }}
          >
            <FlexPaddedContent size="0">
              <Icon
                className="icon-video-player-smartphone-horizontal"
                size="large"
              />
              <DropdownItemContent>
                <div className="name user-menu__support">Setup Tutorial</div>
              </DropdownItemContent>
            </FlexPaddedContent>
          </DropdownItem> */}
          <DropdownItem onClick={() => window.open(RESOURCE_HUB, '_blank')}>
            <FlexPaddedContent size="0">
              <Icon className="icon-support-1" size="large" />
              <DropdownItemContent>
                <div className="name user-menu__support">Support</div>
              </DropdownItemContent>
            </FlexPaddedContent>
          </DropdownItem>
          {user && user.role === 'account_owner' && (
            <DropdownItem onClick={() => history.push('/session-portal')}>
              <FlexPaddedContent size="0">
                <Icon className="icon-account" size="large" />
                <DropdownItemContent>
                  <div className="name user-menu__account-management">
                    Account Management
                  </div>
                </DropdownItemContent>
              </FlexPaddedContent>
            </DropdownItem>
          )}
          <DropdownItem onClick={logoutClick}>
            <FlexPaddedContent size="0">
              <Icon className="icon-sign-out" size="large" />
              <DropdownItemContent>
                <div className="name user-menu__sign-out">Sign out</div>
              </DropdownItemContent>
            </FlexPaddedContent>
          </DropdownItem>
        </UserMenuDropdown>
      </Dropdown>
    </>
  );
};
