import { thermaH2FontFamily } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { NoUsersFound } from '../ui';

interface EmptyUsersProps {
  message?: string;
}

const EmptyUsersContainer = styled.div`
  background: none;
  text-align: center;
  height: 50vh;
  display: grid;
  align-items: center;
  grid-template-rows: 1fr;
`;

const SubHeaderText = styled.p`
  width: 320px;
  font-size: 16px;
  font-weight: 300;
  margin: 0 auto;
  font-family: ${thermaH2FontFamily};
`;

export const EmptyUsers: FunctionComponent<EmptyUsersProps> = (props) => {
  const {
    message = ' Build your team by inviting users via email or SMS.',
  } = props;
  return (
    <EmptyUsersContainer className="empty-users">
      <Header icon>
        <NoUsersFound />
        <Header className="empty-users__header" as="h2">
          No users found
        </Header>
        <Header.Subheader>
          <SubHeaderText className="empty-users_sub-header">
            {message}
          </SubHeaderText>
        </Header.Subheader>
      </Header>
    </EmptyUsersContainer>
  );
};
