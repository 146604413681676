import { neutralGray700 } from '@coinspect/ui';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ResponsiveImageContainer } from '../../style/login-signup-styles';
import { CarsGasIcon } from '../base/cars-gas-icon';
import { CO2Prevented } from '../base/co2-prevented-icon';
import { CoalBurnedIcon } from '../base/coal-burned-icon';
import { FoodProtectedIcon } from '../base/food-protected-icon';
import { HomesEnergyIcon } from '../base/homes-energy-icon';
import { Text, TextProps } from '../text';
import { ClimateImpactData, formatClimateImpact } from './climate-service';
import { useClimateImpactService } from './use-climate-impact';

const ClimateCard = styled.div<{ row: 'top' | 'bottom' }>`
  background-color: #ffffff;
  padding: ${({ row }) => (row === 'top' ? '25px 20px' : '15px 20px')};
  border-radius: 14px;

  display: grid;
  grid-template-columns: min-content 1fr;
  column-gap: 16px;
`;

const IconContainer = styled.div`
  display: flex;
  align-self: center;
`;

const ClimateCardText: React.FC<TextProps> = ({
  children,
  asNew,
  useNew,
  style,
}) => {
  return (
    <Text
      asNew={asNew}
      useNew={useNew}
      style={{
        color: neutralGray700,
        fontWeight: 'bold',
        fontFamily: 'proxima-nova',
        ...style,
      }}
    >
      {children}
    </Text>
  );
};

export const ClimateImpact = () => {
  const { browseClimateImpact } = useClimateImpactService();
  const [climateImpactData, setClimateImpactData] = useState<ClimateImpactData>(
    {
      weightProtected: '0',
      co2ePrevented: '0',
      distanceDriven: '0',
      coalBurned: '0',
      homeEnergyUsed: '0',
    },
  );

  useEffect(() => {
    const fetchData = async () => {
      const data = await browseClimateImpact();
      const formattedData = formatClimateImpact(data);
      setClimateImpactData(formattedData);
    };

    fetchData();
  }, []);

  return (
    <ResponsiveImageContainer
      greaterThanOrEqual={'computer'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90dvh',
      }}
    >
      <div style={{ width: '850px' }}>
        <div>
          <Text
            asNew="headline"
            useNew
            style={{
              textAlign: 'center',
              marginBottom: '36px',
              color: 'rgba(26, 17, 51, 0.8)',
            }}
          >
            Being a part of GlacierGrid means
          </Text>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: '15px',
            }}
          >
            <ClimateCard row="top">
              <IconContainer>
                <FoodProtectedIcon />
              </IconContainer>
              <div>
                <ClimateCardText
                  asNew="headline"
                  useNew
                  style={{
                    margin: 0,
                    fontSize: '26px',
                  }}
                >
                  {climateImpactData.weightProtected}
                </ClimateCardText>
                <ClimateCardText asNew="bodyRegular" useNew>
                  of food protected
                </ClimateCardText>
              </div>
            </ClimateCard>

            <ClimateCard row="top">
              <IconContainer>
                <CO2Prevented />
              </IconContainer>
              <div>
                <ClimateCardText
                  asNew="headline"
                  useNew
                  style={{
                    margin: 0,
                    fontSize: '26px',
                  }}
                >
                  {climateImpactData.co2ePrevented} metric tons
                </ClimateCardText>
                <ClimateCardText asNew="bodyRegular" useNew>
                  of CO2e prevented
                </ClimateCardText>
              </div>
            </ClimateCard>
          </div>
        </div>

        <div>
          <Text
            asNew="bodyRegular"
            useNew
            style={{
              textAlign: 'center',
              marginTop: '56px',
              marginBottom: '16px',
              fontFamily: 'proxima-nova',
              color: 'rgba(26, 17, 51, 0.8)',
              display: 'block',
              fontWeight: 'bold',
            }}
          >
            equal to preventing the impact from
          </Text>

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              columnGap: '15px',
            }}
          >
            <ClimateCard row="bottom">
              <IconContainer>
                <CarsGasIcon />
              </IconContainer>
              <div>
                <ClimateCardText
                  asNew="headline"
                  useNew
                  style={{
                    margin: 0,
                    fontSize: '26px',
                  }}
                >
                  {climateImpactData.distanceDriven}
                </ClimateCardText>
                <ClimateCardText asNew="bodyRegular" useNew>
                  driven by a gas car
                </ClimateCardText>
              </div>
            </ClimateCard>

            <ClimateCard row="bottom">
              <IconContainer>
                <CoalBurnedIcon />
              </IconContainer>
              <div>
                <ClimateCardText
                  asNew="headline"
                  useNew
                  style={{
                    margin: 0,
                    fontSize: '26px',
                  }}
                >
                  {climateImpactData.coalBurned}
                </ClimateCardText>
                <ClimateCardText asNew="bodyRegular" useNew>
                  of coal burned
                </ClimateCardText>
              </div>
            </ClimateCard>

            <ClimateCard row="bottom">
              <IconContainer>
                <HomesEnergyIcon />
              </IconContainer>
              <div>
                <div>
                  <ClimateCardText
                    asNew="headline"
                    useNew
                    style={{
                      margin: 0,
                      fontSize: '26px',
                    }}
                  >{`${climateImpactData.homeEnergyUsed} homes’`}</ClimateCardText>
                </div>
                <ClimateCardText asNew="bodyRegular" useNew>
                  energy use for a year
                </ClimateCardText>
              </div>
            </ClimateCard>
          </div>
        </div>
      </div>
    </ResponsiveImageContainer>
  );
};
