import { isMobile } from 'react-device-detect';

import { DataStoreAction } from '../../store';

// https://stackoverflow.com/a/59496175
export const JoyrideFlows = {
  // add new entry for new flow here
  PRIMARY_FLOW: 'primaryFlow',
  SECONDARY_FLOW: 'secondaryFlow',
  NEW_FEATURE_FLOW: 'newFeatureFlow',
  ALERT_CONFIG_TUTORIAL_FLOW: 'alertConfigTutorialFlow',
} as const;

export type JoyrideFlowsType = typeof JoyrideFlows[keyof typeof JoyrideFlows];

export enum JoyrideActions {
  WILL_RUN_TOUR = 'willRunTour', // determine if flow should run
  SET_TOUR = 'setTour', // update tour state
  PAUSE_TOUR = 'pauseTour', // pause tour but can be run later
  STOP_TOUR = 'stopTour', // stop tour
  STOP_TOUR_ALL = 'stopTourAll',
}

export const joyrideFlowInitialState: JoyrideFlowState = {
  [JoyrideFlows.PRIMARY_FLOW]: {
    willRun: false,
    run: false,
    stepIndex: 0,
  },
  [JoyrideFlows.SECONDARY_FLOW]: {
    willRun: false,
    run: false,
    stepIndex: 0,
  },
  [JoyrideFlows.NEW_FEATURE_FLOW]: {
    willRun: false,
    run: false,
    stepIndex: 0,
  },
  [JoyrideFlows.ALERT_CONFIG_TUTORIAL_FLOW]: {
    willRun: false,
    run: false,
    stepIndex: 0,
  },
  activeTour: undefined,
};

interface FlowState {
  willRun: boolean; // determine if a flow is allowed to run. also needed since we can pause tours for a while
  run: boolean; // state of the flow if it is active or not
  stepIndex: number; // index of the flow
}
export interface JoyrideFlowState {
  [JoyrideFlows.PRIMARY_FLOW]: FlowState;
  [JoyrideFlows.SECONDARY_FLOW]: FlowState;
  [JoyrideFlows.NEW_FEATURE_FLOW]: FlowState;
  [JoyrideFlows.ALERT_CONFIG_TUTORIAL_FLOW]: FlowState;
  activeTour?: JoyrideFlowsType;
}

export function joyrideFlowReducer(
  state: JoyrideFlowState,
  action: DataStoreAction,
): JoyrideFlowState {
  const { data, type } = action;
  const flow = data?.flow as JoyrideFlowsType;

  if (isMobile) {
    return {
      ...state,
      ...joyrideFlowInitialState,
    };
  }

  switch (type) {
    case JoyrideActions.WILL_RUN_TOUR:
      return {
        ...state,
        [flow]: {
          ...state[flow],
          stepIndex: 0,
          willRun: false,
        },
      };
    case JoyrideActions.SET_TOUR:
      return {
        ...state,
        [flow]: {
          ...state[flow],
          run: state[flow].willRun,
          stepIndex: data.stepIndex,
        },
        activeTour: state[flow].willRun ? flow : undefined,
      };
    case JoyrideActions.PAUSE_TOUR:
      return {
        ...state,
        [flow]: {
          ...state[flow],
          run: false,
        },
      };
    case JoyrideActions.STOP_TOUR:
    /**
     * if a tour is stoped
     * just stop all tours since each tour has it's own way to start anyway
     * this is to make sure `willRun` of other tours will always be false
     * and so other `SET_TOUR` won't start a tour since `willRun` is false
     */

    case JoyrideActions.STOP_TOUR_ALL:
      return {
        ...joyrideFlowInitialState,
      };
    default:
      return state;
  }
}
