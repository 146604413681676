import { toCelsius } from '@coinspect/utils';
import { normalize, schema } from 'normalizr';
import { useContext } from 'react';

import { DeviceListSchema, setDeviceConnectionStatus } from '../../hooks';
import { TempUnits } from '../../services';
import { StoreContext } from '../../store';
import { EquipmentDeviceService } from './equipment-device-service';
import { EquipmentPayloadData, EquipmentService } from './equipment-service';
import { destructureEquipmentTypeInstallation } from './equipment-utils';

const EquipmentSchema = new schema.Entity(
  'equipment',
  {},
  {
    idAttribute: 'uuid',
  },
);

export interface CheckSerialProps {
  serialNumber: string;
  deviceUUID?: string;
}

const EquipmentListSchema = [EquipmentSchema];

export function useEquipmentService() {
  const { dispatch, store } = useContext(StoreContext);

  async function browseEquipments() {
    const equipments = await EquipmentService.browseEquipments();

    const normalized = normalize(equipments, EquipmentListSchema);
    dispatch({
      data: normalized,
      type: 'equipment:set',
    });
  }

  const checkSerialNumber = async ({
    serialNumber,
    deviceUUID = '',
  }: CheckSerialProps): Promise<boolean> => {
    const isUnique = await EquipmentService.checkSerialNumber(
      serialNumber,
      deviceUUID,
    );
    if (!isUnique) return false;
    return true;
  };

  async function addEquipment(payload: EquipmentPayloadData) {
    dispatch({
      type: 'equipment:add_REQUEST',
    });
    try {
      // check the serial number here
      const equipment = await EquipmentService.addEquipment(payload);

      let equipmentDevice;
      try {
        equipmentDevice = await EquipmentDeviceService.addEquipmentDevice({
          serialNumber: payload.meta.deviceSerialNumber as string,
          name: payload.name,
          locationUUID: payload.locationUUID,
          equipmentUUID: equipment.uuid,
          testingMode: true,
          testingStatus: 'connecting',
        });
      } catch (e) {
        EquipmentService.deleteEquipment(equipment.uuid);
        throw e;
      }

      setDeviceConnectionStatus(equipmentDevice, dispatch);

      const normalized = normalize([equipment], EquipmentListSchema);
      dispatch({
        data: normalized,
        type: 'equipment:add',
      });

      const normalizedDevice = normalize([equipmentDevice], DeviceListSchema);
      dispatch({
        data: normalizedDevice,
        type: 'device:add',
      });

      return [equipment, equipmentDevice];
    } finally {
      dispatch({
        type: 'equipment:add_FINALLY',
      });
    }
  }

  async function deleteEquipment(uuid: string) {
    dispatch({
      type: 'equipment:delete_REQUEST',
    });
    try {
      await EquipmentService.deleteEquipment(uuid);

      dispatch({
        data: uuid,
        type: 'equipment:delete',
      });
    } finally {
      dispatch({
        type: 'equipment:delete_FINALLY',
      });
    }
  }

  async function updateEquipment(payload: EquipmentPayloadData, uuid: string) {
    dispatch({
      type: 'equipment:edit_REQUEST',
    });

    try {
      const equipments = await EquipmentService.editEquipment(payload, uuid);
      if (equipments.errors) return [equipments];
      const normalized = normalize([equipments], EquipmentListSchema);
      dispatch({
        data: normalized,
        type: 'equipment:edit',
      });
      return [equipments];
    } finally {
      dispatch({
        type: 'equipment:edit_FINALLY',
      });
    }
  }

  function getRecommendedValues(
    equipmentTypeInstallation: string,
    preferredTempUnit: TempUnits,
  ) {
    const { recommended_alerts } = store.config;
    const [equipmentType, installation] = destructureEquipmentTypeInstallation(
      equipmentTypeInstallation,
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const config = recommended_alerts?.[equipmentType][installation];
    switch (preferredTempUnit) {
      case 'c':
        return {
          min: toCelsius(config[0].triggerValue).toFixed(2),
          max: toCelsius(config[1].triggerValue).toFixed(2),
        };
      case 'f':
        return {
          min: config[0].triggerValue,
          max: config[1].triggerValue,
        };
    }
  }

  function checkIfEquipmentHasMaxTempAlert(
    equipmentDeviceUUID: string,
  ): boolean {
    if (!equipmentDeviceUUID) {
      return false;
    }

    try {
      const alertConfigsUUIDs =
        store.relationships.deviceAlertConfigs[equipmentDeviceUUID];
      const alertConfigs = alertConfigsUUIDs.map(
        (uuid) => store.entities.alertConfigs.byUUID[uuid],
      );
      const maxTempAlerts = alertConfigs.filter(
        (alertConfig) =>
          alertConfig.triggerComparator === '>' &&
          alertConfig.field === 'temperature',
      );
      const hasMaxTempAlerts = maxTempAlerts.length > 0;
      return hasMaxTempAlerts;
    } catch (e) {
      return false;
    }
  }

  return {
    browseEquipments,
    addEquipment,
    deleteEquipment,
    updateEquipment,
    getRecommendedValues,
    checkSerialNumber,
    checkIfEquipmentHasMaxTempAlert,
  };
}
