import { Button, FormInput } from '@coinspect/ui';
import { isValidEmail } from '@coinspect/utils';
import 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { Form, Item } from 'semantic-ui-react';

import { AccountContext } from '../../contexts';
import { isValidJoiEmail } from '../../lib';
import { Invite } from '../../services';
import { SignUpContainer } from '../../style/login-signup-styles';
import { PasswordField } from '../forms/invite-form/password-field';
import { FailedPasswordResetMessage } from '../warning-messages/failed-password-reset';
import { SuccessfulPasswordResetMessage } from '../warning-messages/success-password-reset';

export interface SignInEmailFormFields {
  confirmPassword: string;
  email: string;
  password: string;
  invite?: string;
}

interface SignInEmailFormProps {
  invite?: Invite;
  token?: string | boolean;
  isTokenValid?: boolean;
  displayText?: string;
}

interface FormData {
  email: string;
  password: string;
}

export const SignInEmailForm: React.FC<SignInEmailFormProps> = (props) => {
  const account = useContext(AccountContext);
  const { invite, token, isTokenValid } = props;
  const methods = useForm<FormData>({
    mode: 'onChange',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();

  // if the current URL is /signup forgot password is not rendered
  const isSignupInvite = location.pathname !== '/signup';

  useEffect(() => {
    if (!invite || !invite.email) {
      return;
    }

    if (invite?.email) {
      methods.setValue('email', invite?.email);
      methods.triggerValidation('email');
    }
  }, [invite]);

  const handleOnSubmitEmailForm = methods.handleSubmit(async (data) => {
    setIsLoading(true);

    if (!token) {
      await account.authorizeEmailPassword(data.email, data.password);
      setIsLoading(false);
      return;
    }

    if (!isTokenValid) {
      setIsLoading(false);
      return;
    }

    try {
      await account.createUserWithEmailAndPassword(data);
    } finally {
      setIsLoading(false);
    }
  });

  return (
    <>
      <SignUpContainer>
        <FormContext {...methods}>
          <Form onSubmit={handleOnSubmitEmailForm} noValidate>
            {account.error && <FailedPasswordResetMessage />}
            {account.resetPasswordSuccess && <SuccessfulPasswordResetMessage />}
            <FormInput
              name="email"
              type="email"
              label="Email:"
              placeholder="Email"
              showrequired={!!invite}
              customErrors={{
                email: 'Invalid email format',
                required: 'Email is required',
              }}
              validation={{
                email: (val: string) =>
                  isValidEmail(val) && isValidJoiEmail(val),
              }}
              className="no-width-auto"
              disabled={Boolean(invite?.email)}
            />

            <PasswordField
              name="password"
              type="password"
              label="Password:"
              required
              placeholder="Password"
              showrequired={!!invite}
              customErrors={{
                passwordLength: 'Password must be more than 6 characters',
                required: 'Password is required',
              }}
              validation={{
                passwordLength: (val: string) => val.length > 5,
              }}
              className="no-width-auto"
            />

            {invite && (
              <>
                <PasswordField
                  name="confirmPassword"
                  label="Confirm password:"
                  type="password"
                  required
                  placeholder="Confirm password"
                  showrequired={!!invite}
                  customErrors={{
                    match: `Passwords don't match`,
                    passwordLength: 'Password must be more than 6 characters.',
                    required: 'Please confirm your password',
                  }}
                  validation={{
                    match: (val: string) => val === methods.watch('password'),
                    passwordLength: (val: string) => val.length > 5,
                  }}
                  className="no-width-auto"
                />
              </>
            )}

            {isSignupInvite && (
              <Link
                to="/forgot-password"
                style={{
                  color: '#dc7f9b',
                  display: 'flex',
                  fontFamily: 'proxima-nova',
                  fontWeight: 'bold',
                  justifyContent: 'right',
                  textDecoration: 'underline',
                }}
              >
                Forgot password?
              </Link>
            )}

            <Item.Group>
              <Item>
                <Button
                  fluid
                  primary
                  loading={isLoading}
                  disabled={!methods.formState.isValid}
                >
                  {token ? 'Sign up' : 'Log in'}
                </Button>
              </Item>
            </Item.Group>
          </Form>
        </FormContext>
      </SignUpContainer>
    </>
  );
};
