import { ACCOUNT_BLACKLIST } from './account-blacklist';

const HOTJAR_ID = 2661815;
const HOTJAR_SV = 6;
const HOTJAR_URL = 'https://static.hotjar.com/c/hotjar-';
const HOTJAR_URL_JS_SV = '.js?sv=';

// eslint-disable-next-line no-undef
interface HotjarWindow extends Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hj: any;
  _hjSettings: {
    hjid: number;
    hjsv: number;
  };
}

function initHotJar() {
  ((w) => {
    w.hj =
      w.hj ||
      function() {
        // eslint-disable-next-line prefer-rest-params
        (w.hj.q = w.hj.q || []).push(arguments);
      };
    w._hjSettings = {
      hjid: HOTJAR_ID,
      hjsv: HOTJAR_SV,
    };
    const head = document.getElementsByTagName('head')[0];
    const scriptElem = document.createElement('script');
    scriptElem.async = true;
    scriptElem.src =
      HOTJAR_URL + w._hjSettings.hjid + HOTJAR_URL_JS_SV + w._hjSettings.hjsv;
    head.appendChild(scriptElem);
  })((window as unknown) as HotjarWindow);
}

export const initializeHotjar = (email: string) => {
  if (ACCOUNT_BLACKLIST.includes(email)) return;
  initHotJar();
};
