import React, { FunctionComponent } from 'react';

export const CO2Prevented: FunctionComponent = () => {
  return (
    <span>
      <svg
        width="48"
        height="49"
        viewBox="0 0 48 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.5" width="48" height="48" rx="24" fill="#E1EDF1" />
        <g clipPath="url(#clip0_1733_15265)">
          <path
            d="M23.249 14.75C24.3633 13.9143 25.6882 13.4054 27.0755 13.2803C28.4627 13.1552 29.8573 13.4189 31.1031 14.0418C32.3489 14.6647 33.3966 15.6222 34.1289 16.807C34.8611 17.9918 35.249 19.3572 35.249 20.75C35.249 22.1428 34.8611 23.5082 34.1289 24.693C33.3966 25.8778 32.3489 26.8353 31.1031 27.4582C29.8573 28.0811 28.4627 28.3448 27.0755 28.2197C25.6882 28.0946 24.3633 27.5857 23.249 26.75"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M26.257 16.24L27.832 17.305C28.0375 17.442 28.2059 17.6275 28.3225 17.8452C28.439 18.0629 28.5 18.3061 28.5 18.553V20.27C28.5 20.6168 28.3799 20.9529 28.16 21.2211C27.9401 21.4893 27.6341 21.673 27.294 21.741L24.75 22.25L25.294 24.426"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M35.212 21.5129L32.006 22.5809C31.6586 22.6959 31.3651 22.9339 31.1809 23.2501C30.9968 23.5664 30.9345 23.939 31.006 24.2979L31.598 27.1859"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.25 27.918V16.25C20.25 15.4544 19.9339 14.6913 19.3713 14.1287C18.8087 13.5661 18.0456 13.25 17.25 13.25C16.4544 13.25 15.6913 13.5661 15.1287 14.1287C14.5661 14.6913 14.25 15.4544 14.25 16.25V27.918C13.6934 28.4193 13.271 29.0518 13.0212 29.758C12.7714 30.4642 12.7021 31.2217 12.8197 31.9615C12.9372 32.7012 13.2379 33.3999 13.6942 33.9939C14.1506 34.5879 14.7483 35.0583 15.4328 35.3624C16.1174 35.6666 16.8671 35.7947 17.6138 35.7352C18.3605 35.6757 19.0805 35.4305 19.7083 35.0218C20.336 34.6132 20.8517 34.054 21.2083 33.3953C21.5648 32.7365 21.7511 31.9991 21.75 31.25C21.7488 30.6203 21.6145 29.9979 21.356 29.4237C21.0975 28.8495 20.7206 28.3364 20.25 27.918V27.918Z"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.25 17.75V29.75"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.25 32.75C18.0784 32.75 18.75 32.0784 18.75 31.25C18.75 30.4216 18.0784 29.75 17.25 29.75C16.4216 29.75 15.75 30.4216 15.75 31.25C15.75 32.0784 16.4216 32.75 17.25 32.75Z"
            stroke="#6BA4B8"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1733_15265">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(12 12.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};
