import {
  Schedule,
  ScheduleBrowseParams,
  ScheduleService,
} from '../services/schedule-service';

export function useScheduleService() {
  async function editScheduleBulk(bulkScheduleData: Schedule) {
    const { data } = await ScheduleService.editSchedule(bulkScheduleData);

    return data;
  }

  async function deletePreviousSchedules(scheduleData: Schedule) {
    const { data } = await ScheduleService.deleteSchedule(scheduleData);

    return data;
  }

  async function getSchedules(browseParams: ScheduleBrowseParams) {
    const { data } = await ScheduleService.browseSchedules(browseParams);

    return data;
  }

  async function getSchedulesByLocation(scheduleData: Schedule) {
    const { data } = await ScheduleService.getSchedules(scheduleData);

    return data;
  }

  return {
    editScheduleBulk,
    deletePreviousSchedules,
    getSchedules,
    getSchedulesByLocation,
  };
}
