import axios, { CancelTokenSource } from 'axios';
import { sortBy } from 'lodash';
import { normalize, schema } from 'normalizr';
import React, { useContext, useEffect, useRef } from 'react';
import { DraggableLocation } from 'react-beautiful-dnd';
import { Icon } from 'semantic-ui-react';

import { EquipmentAlertConfigService } from '../components/equipment-alert-config/equipment-alert-config-service';
import { EquipmentNotifConfigService } from '../components/equipment-alert-config/equipment-notif-config-service';
import { AccountContext } from '../contexts';
import {
  AlertService,
  BaseEntity,
  NotifConfigService,
  TempUnits,
} from '../services';
import { StoreContext } from '../store';
import { SensorPageActions } from '../store/reducers';
import { convertToPreferredUnit } from '../utils/temperature-util';
import { useBannerAlerts } from './use-banner-alerts';
import { useFeatures } from './use-features';
export interface Rule {
  day: number;
  fromTime: string;
  toTime: string;
}
export interface Schedule {
  rules: Rule[];
  settings: {
    timezone: string;
  };
}

export type AlertType =
  | 'battery'
  | 'temperature'
  | 'humidity'
  | 'both'
  | 'hvac_zone_temperature'
  | 'gateway_offline'
  | 'hvac_zone_temperature'
  | 'thermostat_connectivity'
  | 'gateway';

type BaseEntityNoUUID = Omit<BaseEntity, 'uuid'>;
export interface NormalAlertModel
  extends AlertNotifConfigPayload,
    BaseEntityNoUUID {
  notifConfigs?: string[];
  snoozeEndAt?: Date;
  listOrder: number;
}

export const NotifConfig = new schema.Entity(
  'notifConfig',
  {},
  {
    idAttribute: 'uuid',
  },
);

const AlertConfig = new schema.Entity(
  'alertConfig',
  {
    notifConfigs: [NotifConfig],
  },
  {
    idAttribute: 'uuid',
  },
);

export type ScheduleType = {
  rules: {
    day: unknown;
    fromTime: unknown;
    toTime: unknown;
  }[];
  settings: {
    timezone: string | undefined;
  };
};
export interface AlertNotifConfigPayload {
  deviceUUID?: string;
  field: AlertType;
  methods?: string[];
  recipients: string[];
  teamRecipients?: string[];
  triggerComparator: string;
  triggerValue?: number;
  timeframe: number;
  schedule?: ScheduleType;
  sleepSchedule?: Record<string, unknown>;
  status?: 'active' | 'disabled';
  isUpdating?: boolean;
  isSmartAlert?: boolean;
  meta?: {
    isRecommendedAlert?: boolean;
    hvacSetpointBuffer?: number;
    triggerValue?: number;
  };
  alertType: AlertType; // equipment based
  uuid?: string;
  equipmentUUID?: string;
  entityType?: string;
  entityUUID?: string;
}

export type EditAlertNotifConfigPayload = Omit<
  AlertNotifConfigPayload,
  'deviceUUID'
> & { snoozeEndAt?: Date | string | null | undefined };

export const AlertConfigs = [AlertConfig];

export function useAlertConfigService() {
  const { isEquipmentEnabled } = useFeatures();
  const { store, dispatch } = useContext(StoreContext);
  const bannerAlertHooks = useBannerAlerts();
  const isReorderInProgress = store.pages.sensors.isReorderInProgress;

  const { alertConfigs: storedAlertConfigs } = store.entities;
  const { user } = useContext(AccountContext);
  const cancelToken = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    return () => {
      if (cancelToken.current) {
        cancelToken.current.cancel(
          'Cancelling alert config old request stacked on top.',
        );
      }
    };
  }, []);

  function getAlertConfigsMapToPreferredUnitFunc(prefTempUnit?: TempUnits) {
    return (alertConfig: NormalAlertModel) => {
      const { triggerValue, field, ...restProps } = alertConfig;
      return {
        ...restProps,
        field,
        triggerValue: convertToPreferredUnit(triggerValue, field, prefTempUnit),
      };
    };
  }

  async function browseAlertConfigs(prefTempUnit?: TempUnits): Promise<void> {
    let alertConfigs;
    if (isEquipmentEnabled) {
      alertConfigs = await EquipmentAlertConfigService.browseEquipmentAlertConfig();
    } else {
      alertConfigs = await AlertService.browse();
    }

    // convert to preferred unit
    const toPreferredUnitAlertConfigs = alertConfigs.map(
      getAlertConfigsMapToPreferredUnitFunc(prefTempUnit),
    );

    const data = normalize(toPreferredUnitAlertConfigs, AlertConfigs);

    dispatch({
      data,
      type: 'alertConfig:set',
    });
  }

  async function browseEnergyAlertConfigs(
    deviceOrEquipmentUUID: string,
    prefTempUnit?: TempUnits,
  ): Promise<void> {
    // NOTE: We should absolutely be sending entityType,
    // but mobile doesn't, and it's working out for them for now
    // If we break, at least it will be consistent
    // TODO: This is a temporary fix until we get something better.
    const alertConfigs = await AlertService.browse({
      entityUUID: deviceOrEquipmentUUID,
      withBatteryAlert: false,
    });

    // convert to preferred unit
    const toPreferredUnitAlertConfigs = alertConfigs.map(
      getAlertConfigsMapToPreferredUnitFunc(prefTempUnit),
    );

    const data = normalize(toPreferredUnitAlertConfigs, AlertConfigs);

    dispatch({
      data,
      type: 'alertConfig:set',
    });
  }

  async function addAlertConfig(
    alertConfigPayload: AlertNotifConfigPayload,
    config?: {
      isEnergyAlert: boolean;
    },
  ): Promise<void> {
    const {
      deviceUUID,
      field,
      methods,
      recipients,
      teamRecipients,
      triggerComparator,
      triggerValue = 15,
      timeframe,
      status,
      schedule = {},
      isSmartAlert,
      meta: { isRecommendedAlert, hvacSetpointBuffer } = {},
      equipmentUUID,
      entityType,
      entityUUID,
    } = alertConfigPayload;

    const alertPayload = {
      deviceUUID,
      field,
      schedule,
      timeframe,
      triggerComparator,
      triggerValue: triggerValue,
      isSmartAlert,
      isRecommendedAlert,
      status,
      entityType,
      entityUUID,
      meta: { hvacSetpointBuffer },
    };

    const { isEnergyAlert = false } = config || {};

    dispatch({ type: 'alertConfig:add_REQUEST' });
    try {
      let alertConfig;

      if (isEquipmentEnabled || isEnergyAlert) {
        alertConfig = await EquipmentAlertConfigService.addEquipmentAlertConfig(
          {
            ...alertPayload,
            deviceUUID,
            equipmentUUID,
            entityType,
            entityUUID,
          },
          user?.companyUUID as string,
        );
      } else {
        alertConfig = await AlertService.add(alertPayload);
      }

      // save triggerValue to store in according to pref temp unit
      alertConfig = {
        ...alertConfig,
        triggerValue: triggerValue,
      };

      const notifConfigPayload = { methods, recipients, teamRecipients };
      const notifConfig = isEquipmentEnabled
        ? await EquipmentNotifConfigService.addEquipmentNotifConfig(
            { ...notifConfigPayload, companyUUID: user?.companyUUID as string },
            alertConfig.uuid,
          )
        : await NotifConfigService.add(notifConfigPayload, alertConfig.uuid);
      alertConfig.notifConfigs = [notifConfig];
      alertConfig.triggerValue = convertToPreferredUnit(
        triggerValue,
        field,
        user?.preferredTempUnit as TempUnits,
      );
      const normalizedAdd = normalize([alertConfig], AlertConfigs);
      dispatch({
        data: normalizedAdd,
        type: 'alertConfig:add',
      });
    } finally {
      dispatch({ type: 'alertConfig:add_FINALLY' });
    }
  }

  async function editAlertConfig(
    alertConfigPayload: EditAlertNotifConfigPayload,
    uuid: string,
    notifConfigUUID?: string,
    config?: { isEnergyAlert: boolean },
  ): Promise<void> {
    dispatch({
      type: 'alertConfig:edit_REQUEST',
    });
    try {
      const {
        field,
        methods,
        recipients,
        triggerComparator,
        triggerValue,
        timeframe,
        schedule,
        isSmartAlert,
        status,
        meta: { isRecommendedAlert } = {},
        entityType,
      } = alertConfigPayload;

      const { isEnergyAlert = false } = config || {};

      const alertPayload = {
        field,
        schedule,
        timeframe,
        triggerComparator,
        triggerValue,
        isSmartAlert,
        isRecommendedAlert,
        entityType,
        ...(status !== null && status !== undefined ? { status } : {}),
        meta: {
          ...alertConfigPayload.meta,
        },
      };

      const requests = [
        isEquipmentEnabled || isEnergyAlert
          ? EquipmentAlertConfigService.editEquipmentAlertConfig(
              {
                ...alertPayload,
                alertConfigUUID: uuid,
                companyUUID: user?.companyUUID as string,
              },
              uuid,
            )
          : AlertService.edit(alertPayload, uuid),
      ];

      if (notifConfigUUID) {
        const notifConfigPayload = {
          methods,
          recipients,
        };
        requests.push(
          isEquipmentEnabled
            ? EquipmentNotifConfigService.editEquipmentNotifConfig(
                notifConfigPayload,
                notifConfigUUID,
                uuid,
              )
            : NotifConfigService.edit(
                notifConfigPayload,
                notifConfigUUID,
                uuid,
              ),
        );
      }

      const responses = await Promise.all(requests);

      // save triggerValue to store in according to pref temp unit
      responses[0].triggerValue = convertToPreferredUnit(
        triggerValue,
        field,
        user?.preferredTempUnit as TempUnits,
      );

      dispatch({
        data: normalize([responses[0]], AlertConfigs),
        type: 'alertConfig:edit',
      });

      if (notifConfigUUID) {
        dispatch({
          data: normalize(responses[1], NotifConfig),
          type: 'alertConfig:edit',
        });
      }
    } finally {
      dispatch({
        type: 'alertConfig:edit_FINALLY',
      });
    }
  }

  async function deleteAlertConfig(
    deviceUUID: string,
    alertConfigUUID: string,
  ) {
    dispatch({
      type: 'alertConfig:delete_REQUEST',
    });
    try {
      let removedRecord;

      if (isEquipmentEnabled) {
        removedRecord = await EquipmentAlertConfigService.deleteEquipmentAlertConfig(
          alertConfigUUID,
        );
      } else {
        removedRecord = await AlertService.destroy(alertConfigUUID);
      }

      dispatch({
        data: alertConfigUUID,
        type: 'alertConfig:delete',
      });
      return isEquipmentEnabled ? {} : removedRecord.data;
    } finally {
      dispatch({
        type: 'alertConfig:delete_FINALLY',
      });
    }
  }

  async function browseAlertConfigsByDevice(
    deviceUUIDs: string[],
    prefTempUnit?: TempUnits,
    withBatteryAlert?: boolean,
  ) {
    if (cancelToken.current) {
      cancelToken.current.cancel(
        'Cancelling alert config old request stacked on top.',
      );
    }
    const newCancelToken = axios.CancelToken.source();
    cancelToken.current = newCancelToken;
    dispatch({ type: 'alertConfig:browse:byDevice_REQUEST' });

    try {
      const alertConfigs = await AlertService.browseAlertConfigsByDevice(
        deviceUUIDs,
        newCancelToken.token,
        withBatteryAlert,
      );

      await Promise.all(
        alertConfigs.map(async (alertConfig: NormalAlertModel) => {
          if (!alertConfig.notifConfigs?.length) {
            await AlertService.destroy(alertConfig.uuid);
          }
        }),
      );

      const filteredAlertConfig = alertConfigs.filter(
        (alertConfig: NormalAlertModel) => alertConfig.notifConfigs?.length,
      );
      // convert to preferred unit
      const toPreferredUnitAlertConfigs = filteredAlertConfig.map(
        getAlertConfigsMapToPreferredUnitFunc(prefTempUnit),
      );
      const data = normalize(toPreferredUnitAlertConfigs, AlertConfigs);
      dispatch({
        data,
        type: 'alertConfig:append',
      });
    } finally {
      dispatch({ type: 'alertConfig:browse:byDevice_FINALLY' });
    }
  }

  function resetAlertConfigs() {
    dispatch({
      type: 'alertConfig:hard_reset',
    });
    return Promise.resolve();
  }

  function setAlertStatus({
    alertConfigUUID,
    status,
    snoozeEndAt,
  }: {
    alertConfigUUID: string;
    status?: 'disabled' | 'active';
    snoozeEndAt?: string | null;
  }) {
    let alertConfig = storedAlertConfigs.byUUID[alertConfigUUID];

    alertConfig = {
      ...alertConfig,
      status: status ? status : alertConfig.status,
      // if something is passed null or string then use it
      // else fallback to current value
      snoozeEndAt:
        snoozeEndAt !== undefined ? snoozeEndAt : alertConfig.snoozeEndAt,
    };

    const data = normalize([alertConfig], AlertConfigs);
    dispatch({
      type: 'alertConfig:edit',
      data,
    });
  }

  function getDeviceAlerts(deviceUUID: string) {
    const alertConfigs = Object.values(
      storedAlertConfigs.byUUID,
    ) as NormalAlertModel[];
    return alertConfigs.filter(
      (alertConfig) => alertConfig.deviceUUID === deviceUUID,
    );
  }

  function getSortedDeviceAlerts(deviceUUID: string) {
    return sortBy(getDeviceAlerts(deviceUUID), 'listOrder');
  }

  function sortListOrderValues(alerts: NormalAlertModel[]) {
    return alerts.map((alert, i) => ({ ...alert, listOrder: i }));
  }

  function appendAlertConfigs(alerts: NormalAlertModel[]) {
    const data = normalize(alerts, AlertConfigs);
    dispatch({
      data,
      type: 'alertConfig:append',
    });
  }

  function getAlertIndex(alerts: NormalAlertModel[], uuid: string) {
    return alerts.findIndex((o) => o.uuid === uuid);
  }

  /**
   * @returns finalized destination index
   */
  function updateStoredAlertConfigs(
    alertConfigUUID: string,
    source: DraggableLocation,
    destination: DraggableLocation,
  ) {
    const fromIndex = source.index;
    const toIndex = destination.index;
    const alertConfig: NormalAlertModel =
      storedAlertConfigs.byUUID[alertConfigUUID];

    if (source.droppableId === destination.droppableId) {
      const deviceAlerts = getSortedDeviceAlerts(source.droppableId);

      // remove from source index
      deviceAlerts.splice(fromIndex, 1);
      // add to destination index
      deviceAlerts.splice(toIndex, 0, alertConfig);

      // update local store
      appendAlertConfigs(sortListOrderValues(deviceAlerts));
      return getAlertIndex(deviceAlerts, alertConfigUUID);
    } else {
      const sourceDeviceAlerts = getSortedDeviceAlerts(source.droppableId);
      const destDeviceAlerts = getSortedDeviceAlerts(destination.droppableId);

      // remove from source device
      sourceDeviceAlerts.splice(fromIndex, 1);

      // update deviceUUID
      const updatedAlertConf = {
        ...alertConfig,
        deviceUUID: destination.droppableId,
      };

      // add to destination device
      if (destDeviceAlerts.length === 1) {
        destDeviceAlerts.push(updatedAlertConf);
      } else {
        destDeviceAlerts.splice(toIndex, 0, updatedAlertConf);
      }

      // update local store
      appendAlertConfigs([
        ...sortListOrderValues(sourceDeviceAlerts),
        ...sortListOrderValues(destDeviceAlerts),
      ]);

      return getAlertIndex(destDeviceAlerts, alertConfigUUID);
    }
  }

  const setReorderInProgress = (inProgress: boolean) => {
    dispatch({
      type: SensorPageActions.setReorderInProgress,
      data: inProgress,
    });
  };

  async function reorderAlertConfig(
    alertConfigUUID: string,
    source: DraggableLocation,
    destination: DraggableLocation,
  ) {
    // save current configs into new object
    const oldValues = { ...storedAlertConfigs.byUUID };

    try {
      setReorderInProgress(true);

      const listOrder = updateStoredAlertConfigs(
        alertConfigUUID,
        source,
        destination,
      );

      await AlertService.reorderAlertConfig({
        alertConfigUUID,
        deviceUUID: destination.droppableId,
        listOrder,
      });
    } catch (e) {
      // revert local store
      appendAlertConfigs(Object.values(oldValues));

      // show alert
      bannerAlertHooks.set([
        {
          icon: <Icon name="cloud" />,
          message:
            'Oooops! This one is on us. There was a server error moving the alert. Please try again.',
        },
      ]);
    } finally {
      setReorderInProgress(false);
    }
  }

  async function getAllEquipmentAlertConfigs() {
    const devices = await AlertService.browse({ entityType: 'equipment' });

    return devices;
  }

  function hasRecommendedAlert(deviceUUID: string) {
    let flag = false;
    const deviceAlertConfigs =
      store.relationships.deviceAlertConfigs[deviceUUID];

    if (!deviceAlertConfigs) {
      return flag;
    }

    deviceAlertConfigs.every(function(alertConfigUUID) {
      const alertConfig = store.entities.alertConfigs.byUUID[alertConfigUUID];
      if (alertConfig.meta?.isRecommendedAlert) {
        flag = true;
        return false;
      } else {
        flag = false;
        return true;
      }
    });

    return flag;
  }

  async function setSnooze(
    deviceUUID: string,
    snoozeEnd: Date | null,
    isHvac?: boolean,
  ) {
    dispatch({
      type: 'alertConfig:edit_REQUEST',
    });
    try {
      let alertConfig;
      if (isHvac) {
        alertConfig = await AlertService.setEnergySnooze(deviceUUID, snoozeEnd);
      } else {
        alertConfig = await AlertService.setSnooze(deviceUUID, snoozeEnd);
      }

      dispatch({
        data: normalize(alertConfig.data, AlertConfigs),
        type: 'alertConfig:set',
      });
    } finally {
      dispatch({
        type: 'alertConfig:edit_FINALLY',
      });
    }
  }

  async function previewAlert(methods: string[]) {
    await AlertService.preview(methods);
  }

  return {
    isReorderInProgress,
    addAlertConfig,
    browseAlertConfigs,
    browseEnergyAlertConfigs,
    deleteAlertConfig,
    editAlertConfig,
    browseAlertConfigsByDevice,
    resetAlertConfigs,
    setAlertStatus,
    reorderAlertConfig,
    getAllEquipmentAlertConfigs,
    hasRecommendedAlert,
    setSnooze,
    previewAlert,
  };
}
