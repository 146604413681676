import { thermaBlack, thermaBlue } from '@coinspect/ui';
import mixpanel from 'mixpanel-browser';
import React, { useContext } from 'react';
import { Step, TooltipRenderProps } from 'react-joyride';

import { ModalContext } from '../../contexts';
import { StoreContext } from '../../store';
import { DashboardActions, JoyrideFlowsType } from '../../store/reducers';
import { getCompletedSteps } from './utils';

export interface JoyrideTooltipProps extends TooltipRenderProps, Step {
  flow?: JoyrideFlowsType;
}

export const JoyrideTooltip: React.FC<JoyrideTooltipProps> = (props) => {
  const { hideModal } = useContext(ModalContext);
  const { dispatch, store } = useContext(StoreContext);
  const {
    joyrideFlows,
    pages: {
      dashboard: { isRepeatTutorial, thermaGoal = '' },
    },
  } = store;
  const { activeTour } = joyrideFlows;

  const {
    tooltipProps,
    step: { styles, showSkipButton, title, content },
  } = props;

  return (
    <div {...tooltipProps}>
      <div style={{ ...styles?.tooltip }}>
        <div style={{ ...styles?.tooltipContainer }}>
          <h4 style={{ ...styles?.tooltipTitle }}>{title}</h4>

          <div style={{ ...styles?.tooltipContent }}>{content}</div>

          <div
            style={{
              ...styles?.tooltipFooter,
              marginTop: '27px',
              fontSize: '13px',
            }}
          >
            {/* Only displayed in the secondary flow to close modal and continue tour */}
            <div>
              <span style={{ color: thermaBlack }} className="bold">
                Someone else setting up the sensors?
              </span>
              &nbsp;
              <span
                style={{
                  color: thermaBlue,
                }}
                className="underline cursor-pointer"
                onClick={() => {
                  if (!isRepeatTutorial) {
                    mixpanel.track('Onboarding Cards Completed', {
                      user_goal: thermaGoal,
                    });
                  }
                  // same as handleContinue in congrats-screen
                  hideModal();
                  dispatch({
                    type: DashboardActions.goDashboard,
                  });
                }}
              >
                Add them now.
              </span>
            </div>

            {showSkipButton && (
              <div>
                <span
                  style={{
                    fontWeight: 'normal',
                    textDecoration: 'none',
                    fontSize: '18px',
                  }}
                >
                  Done?
                </span>
                <span
                  style={{ ...styles?.buttonSkip }}
                  onClick={() => {
                    if (activeTour && !isRepeatTutorial) {
                      mixpanel.track('Onboarding Tutorial Skipped', {
                        onboarding_steps_completed: getCompletedSteps(
                          activeTour,
                          joyrideFlows,
                        ),
                      });
                    }
                  }}
                >
                  Click here to skip
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
