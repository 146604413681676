import React, { useState } from 'react';

import { Invite } from '../../../services';
import { BulkInviteSection } from './bulk-invite-section';
import { InviteModes } from './index';
import { SingleInviteForm } from './single-invite-form';

interface InviteFormModeToggler {
  onSubmit: (invites: Invite[]) => Promise<void>;
  onCancel: () => void;
}

export const InviteFormModeToggler: React.FC<InviteFormModeToggler> = (
  props,
) => {
  const { onSubmit, onCancel } = props;

  const [singleInviteMode, setSingleInviteMode] = useState(true);
  const [inviteType, setInviteType] = useState<InviteModes>('email');

  return singleInviteMode ? (
    <SingleInviteForm
      showWhen={singleInviteMode}
      onCancel={onCancel}
      onSubmit={onSubmit}
      onChange={setInviteType}
      setSingleInviteMode={setSingleInviteMode}
    />
  ) : (
    <BulkInviteSection
      inviteType={inviteType}
      onCancelPrepare={setSingleInviteMode}
    />
  );
};
