import { NewFeatureModal, NewFeatureModalTypes } from '@coinspect/atka-types';
import React, { useContext } from 'react';

import { StandardModalProps } from '../components/modals/standard-modal';
import { SubContent } from '../components/modals/standard-modal-body';
import { AnnouncementSvg } from '../components/ui/images/svgs/announcement-svg';
import { ListItemTag } from '../components/users/teams-section';
import { NotifMethodDisplayValue } from '../components/users/user-details-form-fields';
import { AccountContext, StandardModalContext } from '../contexts';
import { UserService } from '../services';
import { useEditUser } from './use-edit-user';

interface featureModalContentProps {
  [key: string]: StandardModalProps;
}

export function useNewFeatureModals() {
  const { openModal, hideModal } = useContext(StandardModalContext);
  const { editUser } = useEditUser();
  const { user, setAuthedUser } = useContext(AccountContext);

  const NewFeatureUpdateContent: featureModalContentProps = {
    [NewFeatureModal.NOTIF_METHODS]: {
      content: (
        <>
          Alert notification method selection is now a part of your profile.
          This means any selected method(s) will apply to all alerts.
          <SubContent>
            The following notification method(s) have been moved from your
            alerts to your profile. Review your settings to ensure you don’t
            miss any action!
          </SubContent>
        </>
      ),
      subContent: (
        <>
          {user?.notificationMethods?.map((notif, ind) => (
            <ListItemTag key={ind}>
              {NotifMethodDisplayValue[notif]}
            </ListItemTag>
          ))}
        </>
      ),
      primaryButton: {
        content: 'Review Profile',
        onClick: () => {
          hideModal();
          editUser(user, {
            header: 'Edit my account',
            isFieldsDisabled: false,
          });
        },
      },
    },
  };

  function announceNewFeature(newFeatureUpdateName: NewFeatureModalTypes) {
    openModal({
      header: "We've made a change!",
      headerIcon: <AnnouncementSvg />,
      secondaryButton: {
        content: 'Dismiss',
        onClick: () => {
          hideModal();
        },
      },
      ...(NewFeatureUpdateContent[newFeatureUpdateName] ?? {}),
    });
  }

  function hasUserSeenFeatureModal(newFeatureModalName: NewFeatureModalTypes) {
    const flag = user?.meta && newFeatureModalName in user?.meta;

    try {
      return flag;
    } finally {
      // * only set if user hasn't seen the flag yet
      if (!flag) {
        setUserSeenFeatureModal(newFeatureModalName);
      }
    }
  }

  async function setUserSeenFeatureModal(
    newFeatureModalName: NewFeatureModalTypes,
  ) {
    if (user) {
      setAuthedUser({
        ...user,
        meta: { ...user.meta, [newFeatureModalName]: true },
      });
    }
    await UserService.setHasSeenNewFeatureModal(newFeatureModalName);
  }

  return {
    announceNewFeature,
    hasUserSeenFeatureModal,
  };
}
