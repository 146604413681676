import { normalize, schema } from 'normalizr';
import { useContext } from 'react';

import {
  Device,
  DeviceModel,
  DeviceService,
  EditDevice,
  EnergyDeviceService,
  SignalTestingProps,
  StartTimerProps,
  UserService,
} from '../services';
import { DataStoreAction, StoreContext } from '../store';
import {
  ConfigKeys,
  ConfigStoreActions,
  ConnectionStatusType,
  SensorPageActions,
} from '../store/reducers';

const DeviceSchema = new schema.Entity(
  'device',
  {},
  {
    idAttribute: 'uuid',
  },
);

export const DeviceListSchema = [DeviceSchema];

export const START_TIMER_KEY = 'START_TIMER_KEY';

export function useDeviceService() {
  const { dispatch } = useContext(StoreContext);

  async function browseDevices(): Promise<void> {
    const devices = await DeviceService.browse();
    const normalized = normalize(devices, DeviceListSchema);
    return dispatch({
      data: normalized,
      type: 'device:set',
    });
  }

  async function browseByDevice(uuid: string): Promise<void> {
    const device = await DeviceService.read(uuid);
    const normalized = normalize([device], DeviceListSchema);
    dispatch({
      data: normalized,
      type: 'device:set',
    });
  }

  async function addDevice(payload: Device): Promise<DeviceModel> {
    dispatch({ type: 'device:add_REQUEST' });
    try {
      const device = await DeviceService.add(payload);
      // add entry to connection status after creating a device
      setDeviceConnectionStatus(device, dispatch);
      const normalized = normalize([device], DeviceListSchema);
      dispatch({
        data: normalized,
        type: 'device:add',
      });
      return device;
    } finally {
      dispatch({ type: 'device:add_FINALLY' });
    }
  }

  async function editDevice(uuid: string, payload: EditDevice): Promise<void> {
    dispatch({ type: `device:edit:${uuid}_REQUEST` });
    try {
      const device = await DeviceService.edit(uuid, payload);
      const normalized = normalize([device], DeviceListSchema);
      dispatch({
        data: normalized,
        type: 'device:edit',
      });
    } finally {
      dispatch({ type: `device:edit:${uuid}_FINALLY` });
    }
  }

  async function deleteDevice(uuid: string): Promise<Response> {
    dispatch({ type: 'device:delete_REQUEST' });

    try {
      const data = await DeviceService.delete(uuid);
      dispatch({
        data: uuid,
        type: 'device:delete',
      });

      return data;
    } finally {
      dispatch({ type: 'device:delete_FINALLY' });
    }
  }

  async function toggleDeviceSmartAlert(
    uuid: string,
    isSmartAlert: boolean,
  ): Promise<{ uuid: string; is_smart_alert: boolean }> {
    const result = await DeviceService.toggleDeviceSmartAlert(
      uuid,
      isSmartAlert,
    );

    dispatch({
      data: {
        uuid: result.uuid,
        isSmartAlert: result.is_smart_alert,
      },
      type: 'device:toggle:smart_alert',
    });

    return result;
  }

  async function browseDevicesByLocation(
    locationUUIDs: string[],
    method?: 'get' | 'post',
  ): Promise<DeviceModel[]> {
    dispatch({ type: 'device:browse:byLocation_REQUEST' });
    try {
      const devices = await DeviceService.browseDevicesByLocation(
        locationUUIDs,
        method,
      );

      const normalized = normalize(devices, DeviceListSchema);

      dispatch({
        data: normalized,
        type: 'device:append',
      });

      return devices;
    } finally {
      dispatch({ type: 'device:browse:byLocation_FINALLY' });
    }
  }

  async function getRecommendedAlertsConfig() {
    const { data } = await UserService.getConfig(
      ConfigKeys.RECOMMENDED_ALERTS_CONFIG,
    );

    dispatch({
      type: ConfigStoreActions.set,
      data: {
        key: ConfigKeys.RECOMMENDED_ALERTS_CONFIG,
        values: data,
      },
    });
  }

  function resetDevices() {
    dispatch({
      type: 'device:hard_reset',
    });

    return Promise.resolve();
  }

  async function checkIfUniqueSensorId({
    sensorId,
    deviceUUID,
  }: {
    sensorId: string;
    deviceUUID: string;
  }): Promise<{ unique: boolean }> {
    const isUniqueSensorId: {
      unique: boolean;
    } = await DeviceService.checkIfUniqueSensorId({
      sensorId,
      deviceUUID,
    });

    return isUniqueSensorId;
  }

  async function checkIfUniqueSensorNameInLocation({
    sensorName,
    locationUUID,
    deviceUUID = '',
  }: {
    sensorName: string;
    locationUUID: string;
    deviceUUID: string;
  }): Promise<{ unique: boolean }> {
    const isUniqueSensorName: {
      unique: boolean;
    } = await DeviceService.checkIfUniqueSensorNameInLocation({
      sensorName,
      locationUUID,
      deviceUUID,
    });

    return isUniqueSensorName;
  }

  async function startSensorSignalTest({
    deviceUUIDs,
    locationUUID,
    isTesting,
  }: SignalTestingProps) {
    dispatch({
      type: SensorPageActions.connectionStatusTesting,
      data: {
        isTesting,
        deviceUUIDs,
        locationUUID,
      },
    });
    try {
      await DeviceService.testSignal(deviceUUIDs, isTesting);
    } finally {
      dispatch({
        type: SensorPageActions.connectionStatusTesting,
        data: {
          isTesting: true,
          deviceUUIDs: deviceUUIDs,
          locationUUID,
        },
      });
    }
  }

  async function startTimer({
    deviceUUIDs,
    isTestAgain,
    timeout,
  }: StartTimerProps) {
    deviceUUIDs.map((deviceUUID) => {
      return DeviceService.startTimer(deviceUUID, isTestAgain, timeout);
    });
  }

  async function getSignalTestResult(deviceUUIDs: string[], startDate: Date) {
    return DeviceService.getSignalTestResult(deviceUUIDs, startDate);
  }

  const startTimerOnce = (fn: () => void) => {
    const executed =
      localStorage.getItem(START_TIMER_KEY) === 'true' ? true : false;
    if (!executed) {
      localStorage.setItem(START_TIMER_KEY, 'true');
      fn();
    }
  };

  async function getHvacTransitionStatus(deviceUUID: string) {
    const { data } = await EnergyDeviceService.browseDeviceByUUID(deviceUUID);

    return data[0].metadata;
  }

  return {
    addDevice,
    browseDevices,
    browseByDevice,
    deleteDevice,
    editDevice,
    toggleDeviceSmartAlert,
    browseDevicesByLocation,
    resetDevices,
    checkIfUniqueSensorId,
    checkIfUniqueSensorNameInLocation,
    getRecommendedAlertsConfig,
    startSensorSignalTest,
    startTimer,
    getSignalTestResult,
    startTimerOnce,
    getHvacTransitionStatus,
  };
}

export function setDeviceConnectionStatus(
  device: DeviceModel,
  dispatch: (action: DataStoreAction) => void,
) {
  const connectionMapping: ConnectionStatusType = {
    testingResult: 'In progress',
    testingStatus: 'connecting',
    isTesting: true,
    locationUUID: device.locationUUID,
    deviceUUID: device.uuid,
  };

  dispatch({
    type: SensorPageActions.connectionStatusSet,
    data: connectionMapping,
  });
}
