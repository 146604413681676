import React, { FunctionComponent } from 'react';
import { Link, Route } from 'react-router-dom';

import {
  BoldedText,
  CheckForEmailText,
  ContactContainer,
  NewUserContainer,
  RequestResendContainer,
  SignInFooterStyled,
} from '../../style/login-signup-styles';
import { ContactUs } from '../sign-in-footer/contact-us';
import { TermsAndConditions } from '../sign-in-footer/terms-and-conditions';

export const SignInFooter: FunctionComponent = () => {
  return (
    <SignInFooterStyled>
      <Route
        exact
        path={['/', '/invite', '/signin']}
        component={() => {
          return (
            <>
              <div>
                {/* New user */}
                <NewUserContainer>
                  <BoldedText>New user?</BoldedText> &nbsp;
                  <CheckForEmailText>
                    Check for an email from support@glaciergrid.com containing
                    your unique link to activate your account.
                  </CheckForEmailText>
                </NewUserContainer>

                <RequestResendContainer>
                  <BoldedText>Can&apos;t find the email?&nbsp;</BoldedText>

                  <Link
                    to="/resend-activation"
                    style={{
                      color: '#dc7f9b',
                      fontFamily: 'proxima-nova',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                    }}
                    className="request-resend-link"
                  >
                    Request resend
                  </Link>
                </RequestResendContainer>

                <ContactContainer>
                  <ContactUs />
                </ContactContainer>

                <TermsAndConditions />
              </div>
            </>
          );
        }}
      />
    </SignInFooterStyled>
  );
};
