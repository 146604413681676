import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AccountContext, LocalStorageContext } from '../../../contexts';
import {
  APPLE_PROVIDER,
  GOOGLE_PROVIDER,
} from '../../../contexts/account-context/utils';
import { InviteService } from '../../../services';
import {
  SignUpArea,
  SignUpButtonContainer,
  SignUpContent,
  SignUpFooterContainer,
  SignUpFormContainer,
  SignUpHeader,
  SignupLayout,
  SignupOuterLayout,
} from '../../../style/login-signup-styles';
import { SignInButtons } from '../../buttons';
import { SignInEmailForm } from '../../forms/sign-in-email-form';
import { ThermaLogoHeader } from '../../log-in-sign-up/logo-container';
import { SignupWelcomeHeader } from '../../log-in-sign-up/sign-up-welcome';
import { ContactUs } from '../../sign-in-footer/contact-us';
import { TermsAndConditions } from '../../sign-in-footer/terms-and-conditions';
import { FailedMessage } from '../../warning-messages/error-message';

export interface SignUpPageProps {
  isSignUp?: string;
  isInvite?: string;
}

export const SignUpPage: React.FC<SignUpPageProps> = (props) => {
  const { isInvite, isSignUp } = props;

  const location = useLocation();
  const history = useHistory();

  const queryString = new URLSearchParams(location.search);
  const token = queryString.get('token') || '';

  const account = useContext(AccountContext);
  const { getItem, setItem } = useContext(LocalStorageContext);

  const storedIsInviteDeleted = getItem('isInviteDeleted', false);

  const [displayText, setDisplayText] = useState('Sign up');
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [, setShowEmailForm] = useState(false);
  const [invite, setInvite] = useState();
  const [error, setErrorMessage] = useState(null);
  const isInviteURL = location.pathname.includes('invite');
  const isSignupURL = location.pathname.includes('signup');

  useEffect(() => {
    setItem('isInviteDeleted', false);
    (async () => {
      if (!token) {
        return;
      }

      try {
        const inviteData = await InviteService.read(token);
        if (!inviteData) {
          setDisplayText('Log in');
          history.push('/');
          account.setError(
            'Invite does not exist. For more information, please contact your company administrator.',
          );
          // setErrorMessage(
          //   'Invite does not exist. For more information, please contact your company administrator.',
          // );
          return;
        }

        if (token && !isInvite && isSignUp) {
          setShowEmailForm(true);
        }

        setInvite(inviteData);
        setIsTokenValid(true);
      } catch (err) {
        const { data } = err.response;
        const { errors = [] } = data;
        setDisplayText('Log in');
        history.push('/');
        if (errors.length && errors[0].details) {
          setErrorMessage(errors[0].details);
        }
        return;
      }
    })();
    return () => {
      setItem('isInviteDeleted', false);
    };
  }, [location]);

  useEffect(() => {
    if (storedIsInviteDeleted) {
      account.setError(
        'Invite does not exist. For more information, please contact your company administrator.',
      );
      // setErrorMessage(
      //   'Invite does not exist. For more information, please contact your company administrator.',
      // );
      return;
    } else {
      account.setError('');
    }
  }, [history.location.pathname]);

  const handleGoogleAuth = () => {
    return account.createAuthProvider(GOOGLE_PROVIDER);
  };

  const handleAppleAuth = () => {
    return account.createAuthProvider(APPLE_PROVIDER, [
      'email',
      'name',
      'profile',
    ]);
  };

  return (
    <>
      <SignupOuterLayout>
        <SignupLayout>
          <SignUpArea>
            <SignUpHeader>
              <ThermaLogoHeader />
              <SignupWelcomeHeader role={invite?.role} />
            </SignUpHeader>

            <SignUpContent>
              {error && <FailedMessage message={error} />}

              <SignUpButtonContainer>
                {isInviteURL && (
                  <>
                    <SignInButtons
                      token={token}
                      displayText={displayText}
                      setShowEmailForm={setShowEmailForm}
                      isLoading={account.isSSOSignInLoading}
                      handleGoogleAuth={() => handleGoogleAuth()}
                      handleAppleAuth={() => handleAppleAuth()}
                    />
                  </>
                )}
              </SignUpButtonContainer>

              <SignUpFormContainer>
                {isSignupURL && (
                  <>
                    <SignInEmailForm
                      token={token}
                      isTokenValid={isTokenValid}
                      invite={invite}
                    />
                  </>
                )}
              </SignUpFormContainer>
            </SignUpContent>

            <SignUpFooterContainer>
              <ContactUs />
              <TermsAndConditions />
            </SignUpFooterContainer>
          </SignUpArea>
        </SignupLayout>
      </SignupOuterLayout>
    </>
  );
};
