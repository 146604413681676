import { white85, white100 } from '@coinspect/ui';
import { mobileMaxWidth, tabletMaxWidth } from 'src/constants';
import { Media } from 'src/contexts/app-media';
import styled from 'styled-components';

// LOG IN STYLES

interface SignInLayout {
  mobilebackground?: string;
}
// Just added
export const OuterLayout = styled.div`
  background: linear-gradient(0deg, #dbedf8 20%, #e8e9ee 100%);
  height: 110dvh;
`;

export const SignInArea = styled.div`
  width: 650px;
  background: ${white100};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* Just added */
  border-radius: 14px;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  align-items: center;
`;

export const SignInImg = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: space-around;
`;

// just added
export const LogoContainer = styled.div`
  width: 206px;
  height: 91px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
  padding-bottom: 34px;
`;

export const LoginHeader = styled.div`
  padding-top: 65px;
  /* padding-bottom: 130px; */
  text-align: center;
`;

export const AuthMessageContainer = styled.div`
  /* padding-top: 15px; */
  padding-bottom: 15px;
`;

// just added
export const WelcomeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: futura-maxi;
  font-weight: bold;
  font-size: 24px;
  color: #1a1133cc;
  padding-top: 25px;
  padding-bottom: 10px;
`;

export const ButtonsContainer = styled.div`
  gap: 18px;
  width: 250px;
  padding-bottom: 20px;
`;

export const SignInFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 320px;
  height: 212px;
  flex-grow: 0;
`;

export const LoginImage = styled.img`
  border-style: none;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10%;
  transform: translateY(10%);
  padding-left: 30px;
  border-radius: 14px;
`;

export const ButtonsAndForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto 25% 0;
  flex-grow: 1;
`;

export const ResponsiveImageContainer = styled(Media)`
  position: relative;
  width: 100%;
`;

// Sign in with email area footer

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-grow: 0;
  padding-top: 95px;
`;

export const InnerFooterContainer = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NewUserContainer = styled.div`
  text-align: left;
  font-family: proxima-nova;
  padding-bottom: 12px;
`;

export const RequestResendContainer = styled.div`
  padding-bottom: 5px;
  text-align: left;
`;

export const ContactUsLink = styled.a`
  color: #568393;
  font-family: proxima-nova;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding-top: 15px;

  &:hover {
    color: #568393;
  }
`;

export const TermsAndConditionsContainer = styled.div`
  padding-top: 10px;
  color: #7f8182;
`;

export const PrivacyAndTermsLink = styled.a`
  padding-top: 14px;
  text-align: center;
  font-size: 14px;
  text-decoration: underline;
  color: #7f8182;

  &:hover {
    color: #7f8182;
  }
`;

export const BoldedText = styled.span`
  font-family: proxima-nova;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  color: #545656;
`;

export const CheckForEmailText = styled.span`
  font-family: proxima-nova;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  color: #545656;
`;

export const ResendContainer = styled.div`
  padding-top: 150px;
`;

export const ResetContainer = styled.div`
  padding-top: 150px;
`;

export const ActivationLinkText = styled.span`
  width: 250px;
  height: 72px;
  font-family: proxima-nova;
  font-weight: 400;
  font-size: 16px;
  padding-top: 250px;
`;

export const ContactContainer = styled.div`
  padding-top: 54px;
`;

export const SignUpContainer = styled.div`
  padding-top: 0px;
`;

export const ResendAccText = styled.div`
  font-family: proxima-nova;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
`;

export const ResendSubText = styled.div`
  font-family: proxima-nova;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 18px;
`;

export const LoginSigninFieldContainer = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
`;

// RESPONSIVE STYLE

export const SignInLayout = styled.div<SignInLayout>`
  display: flex;
  padding-left: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
  border-radius: 14px;
  height: 110dvh;
  column-gap: 20px;

  // Medium screens
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    background-image: ${({ mobilebackground }) => `url(${mobilebackground})`};
    background-position: center;
    background-size: cover;
    padding-left: 30px;
    height: 100%;

    ${SignInArea} {
      background-color: ${white85};
      width: 90%;
      margin-left: 175px;
      margin-right: 175px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
    ${ResponsiveImageContainer}
    ${LoginImage} {
      height: 100%;
    }
    ${LogoContainer} {
      padding-top: 20px;
      padding-bottom: 8px;
    }
    ${WelcomeContainer} {
      padding-top: 10px;
    }
    ${ButtonsAndForm},
    ${ButtonsContainer} {
      padding: 0px;
    }
    ${LoginSigninFieldContainer} {
      padding-top: 29px;
      padding-bottom: 18px;
    }
    ${SignInFooterContainer} {
      padding-left: 30px;
      padding-right: 30px;
      width: 520px;
    }
    ${RequestResendContainer} {
      padding-top: 5px;
    }
    ${LoginHeader} {
      padding-top: 65px;
    }
  }

  // Mobile screens
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    background-image: ${({ mobilebackground }) => `url(${mobilebackground})`};
    background-position: center;
    background-size: cover;
    height: 100%;

    ${SignInArea} {
      background-color: ${white85};
      width: 90%;
      margin-left: 15px;
      padding-top: 30px;
      padding-bottom: 30px;
      margin-right: 15px;
    }
    ${ButtonsAndForm},
    ${ButtonsContainer} {
      gap: 0px;
      padding: 0px;
      width: 330px;
    }
    ${AuthMessageContainer} {
      padding-top: 5px;
    }
    ${SignInFooterContainer} {
      padding-left: 15px;
      padding-right: 30px;
      width: 330px;
    }
    ${LoginHeader} {
      padding-top: 65px;
    }
    ${NewUserContainer} {
      padding-left: 15px;
      padding-right: 15px;
    }
    ${RequestResendContainer} {
      padding-left: 15px;
      padding-right: 15px;
    }
    ${ContactUsLink} {
      padding-left: 15px;
      padding-right: 15px;
    }
    ${PrivacyAndTermsLink} {
      padding-left: 15px;
      padding-right: 15px;
    }
    ${TermsAndConditionsContainer} {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  // Regular screens
  @media only screen and (max-width: 700px) {
    background-image: ${({ mobilebackground }) => `url(${mobilebackground})`};
    background-position: center;
    background-size: cover;
    height: 100%;

    ${SignInArea} {
      background-color: ${white85};
      width: 90%;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 10px;
    }
    ${ButtonsAndForm},
    ${ButtonsContainer} {
      gap: 0px;
      padding: 0px;
      width: 210px;
    }
    ${AuthMessageContainer} {
      padding-top: 5px;
    }
    ${SignInFooterContainer} {
      padding-left: 15px;
      padding-right: 30px;
      width: 280px;
    }
    ${LoginHeader} {
      padding-top: 65px;
    }
    ${NewUserContainer} {
      padding-left: 15px;
      padding-right: 15px;
    }
    ${BoldedText} {
      font-size: 12px;
    }
    ${CheckForEmailText} {
      font-size: 12px;
    }
    ${RequestResendContainer} {
      padding-left: 15px;
      padding-right: 15px;
    }
    ${ContactUsLink} {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
    }

    ${ContactContainer} {
      padding-top: 20px;
      font-size: 12px;
    }
    ${PrivacyAndTermsLink} {
      font-size: 12px;
    }
    ${TermsAndConditionsContainer} {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
    }
    ${ResendContainer} {
      font-size: 12px;
    }
    .request-resend-link {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 320px) {
    background-image: ${({ mobilebackground }) => `url(${mobilebackground})`};
    background-position: center;
    background-size: cover;
    padding-left: 30px;
    height: 100%;

    ${SignInArea} {
      background-color: ${white85};
      width: 90%;
      padding-left: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 10px;
    }
    ${ButtonsAndForm}
    ${ButtonsContainer} {
      gap: 0px;
      padding: 0px;
      width: 190px;
    }
    ${AuthMessageContainer} {
      padding-top: 5px;
    }
    ${SignInFooterContainer} {
      padding-left: 15px;
      padding-right: 30px;
      width: 280px;
    }
    ${LoginHeader} {
      padding-top: 65px;
    }
    ${NewUserContainer} {
      padding-left: 15px;
      padding-right: 15px;
    }
    ${BoldedText} {
      font-size: 12px;
    }
    ${CheckForEmailText} {
      font-size: 12px;
    }
    ${RequestResendContainer} {
      padding-left: 15px;
      padding-right: 15px;
    }
    ${ContactUsLink} {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
    }
    ${ContactContainer} {
      padding-top: 30px;
      font-size: 12px;
    }
    ${PrivacyAndTermsLink} {
      font-size: 12px;
    }
    ${TermsAndConditionsContainer} {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
    }
    .request-resend-link {
      font-size: 12px;
    }
  }
`;

export const SignInFooterStyled = styled.div`
  text-align: center;

  // Large and Medium screen
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    ${InnerFooterContainer}
  }
`;

// SIGN UP STYLES

export const SignUpArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 640px;
  background: ${white100};
  border-radius: 14px;
  padding-top: 80px;
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 30px;
`;

export const SignUpInner = styled.div`
  padding-top: 80px;
  padding-left: 90px;
  padding-right: 90px;
  padding-bottom: 30px;
`;

export const SignUpHeader = styled.div`
  text-align: center;
`;

export const SignUpSubheader = styled.div`
  padding-top: 34px;
`;

export const SignUpContent = styled.div`
  padding-top: 0px;
`;

export const SignUpFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SignUpButtonContainer = styled.div`
  width: 350px;
`;

export const SignUpFormContainer = styled.div`
  width: 350px;
`;

export const SignupOuterLayout = styled.div`
  background: linear-gradient(
    180deg,
    #e8e9ee 0%,
    rgba(219, 237, 248, 0.73) 100%
  );
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  height: 110dvh;
`;

export const SignupLayout = styled.div`
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 14px;
  height: 100%;

  // Medium screens
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    padding-left: 30px;
    padding-right: 30px;
    height: 100%;

    ${SignUpInner} {
      height: 100%;
    }
    ${SignUpArea} {
      width: 80%;
      margin-left: 145px;
      margin-right: 145px;
      padding-top: 80px;
      padding-bottom: 30px;
      padding-left: 60px;
      padding-right: 60px;
    }
    ${SignUpContent} {
      padding: 0px;
    }
    ${SignUpFooterContainer} {
      text-align: center;
    }
  }

  // Mobile
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;

    ${SignUpInner}
    ${SignUpArea} {
      width: 90%;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
      padding-right: 30px;
    }
    ${SignUpFooterContainer} {
      padding-left: 20px;
      padding-right: 20px;
      text-align: center;
    }
    ${SignUpButtonContainer} {
      width: 100%;
    }
    ${SignUpFormContainer} {
      width: 250px;
    }
    ${SignUpContent} {
      padding: 0px;
    }
    ${ContactUsLink} {
      font-size: 12px;
    }
    ${TermsAndConditionsContainer} {
      font-size: 12px;
    }
    ${PrivacyAndTermsLink} {
      font-size: 12px;
    }
  }

  // 700px screen
  @media only screen and (max-width: 700px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;

    ${SignUpArea} {
      width: 90%;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
      padding-right: 30px;
    }
    ${WelcomeContainer} {
      font-size: 20px;
    }
    ${SignUpFooterContainer} {
      width: 220px;
      text-align: center;
    }
    ${SignUpHeader} {
      padding-top: 15px;
    }
    ${SignUpButtonContainer} {
      width: 100%;
    }
    ${SignUpFormContainer} {
      width: 100%;
    }
    ${SignUpContent} {
      padding: 0px;
    }
    ${ContactUsLink} {
      font-size: 12px;
      padding-top: 0px;
    }
    ${TermsAndConditionsContainer} {
      font-size: 12px;
    }
    ${PrivacyAndTermsLink} {
      font-size: 12px;
    }
  }

  // Smaller screen
  @media only screen and (max-width: 320px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;

    ${SignUpArea} {
      width: 90%;
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 30px;
      padding-right: 30px;
      display: flex;
      align-items: center;
    }
    ${WelcomeContainer} {
      font-size: 20px;
    }
    ${SignUpFooterContainer} {
      width: 220px;
      text-align: center;
    }
    ${SignUpHeader} {
      padding-top: 15px;
    }
    ${SignUpButtonContainer} {
      width: 100%;
    }
    ${SignUpFormContainer} {
      width: 100%;
    }
    ${SignUpContent} {
      padding: 0px;
    }
    ${ContactUsLink} {
      font-size: 12px;
      padding-top: 0px;
    }
    ${TermsAndConditionsContainer} {
      font-size: 12px;
    }
    ${PrivacyAndTermsLink} {
      font-size: 12px;
    }
  }
`;
