import firebase from 'firebase/app';

import { config } from '../components/firebaseui/config';
import { LoginCredentials } from '../contexts';
import { UserService } from './user-service';
firebase.initializeApp(config);
const logger = console;

const sendPasswordResetEmail = async (email: string) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    return true;
  } catch (err) {
    logger.error(err);
    return false;
  }
};

const confirmPasswordReset = async (code: string, newPassword: string) => {
  const passResetUrl = `https://identitytoolkit.googleapis.com/v1/accounts:resetPassword?key=${config.apiKey}`;
  return fetch(passResetUrl, {
    method: 'POST',
    body: JSON.stringify({ oobCode: code, newPassword: newPassword }),
    headers: { 'Content-Type': 'application/json' },
  })
    .then(async (res) => {
      const { email } = await res.json();
      //const email = resJson.email
      UserService.updatePasswordChangeDate(undefined, email);
      return true;
    })
    .catch(() => {
      return false;
    });
};

const createUserWithEmailAndPassword = async (params: LoginCredentials) => {
  const { email, password } = params;
  const auth = firebase.auth();

  try {
    await auth.createUserWithEmailAndPassword(email, password);
  } catch (e) {
    if (e.code !== 'auth/email-already-in-use') {
      return;
    }

    const signInMethods = await auth.fetchSignInMethodsForEmail(email);

    if (signInMethods.includes('password')) {
      await UserService.updatePassword(email, password);
      await auth.signInWithEmailAndPassword(email, password);
    }

    throw new Error(
      'Email already in use, please login with your correct credential.',
    );
    // setError(
    //   'Email already in use, please login with your correct credential.',
    // );

    // return history.push(`/${location.search}`);
  }
};

function createAuthProvider(
  providerName: string,
  scopes: string[] = ['profile', 'email'],
) {
  const providerCreator: {
    apple: () => firebase.auth.OAuthProvider;
    [key: string]: () => firebase.auth.AuthProvider;
  } = {
    apple: () => {
      const provider = new firebase.auth.OAuthProvider('apple.com');
      scopes.forEach((scope) => provider.addScope(scope));
      return provider;
    },
    google: () => {
      const provider = new firebase.auth.GoogleAuthProvider();
      scopes.forEach((scope) => provider.addScope(scope));
      return provider;
    },
  };
  const providerFn = providerCreator[providerName];

  if (!providerFn) throw new Error(`Invalid provider ${providerName}.`);

  return providerFn();
}

export {
  sendPasswordResetEmail,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  createAuthProvider,
};
