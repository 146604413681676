import { toSentenceCase } from 'common-utils-pkg';
import moment from 'moment';

import { EnergyMessagesNotifType } from '../components/pages/energy-dashboard-page/messages/energy-message-helpers';
import {
  AlertNotifModel,
  AlertNotifRecipient,
} from '../services/alert-notif-service';
import { TempUnits } from '../services/user-service';
import { convertToPreferredUnit, getAlertBuffer } from './temperature-util';

export type AlertLogType = {
  alert: string;
  date: string;
  endedAt: Date | undefined;
  recipients: AlertNotifRecipient[];
  sentTo: string;
  time: string;
  field: string;
  deviceName: string | undefined;
  locationName: string | undefined;
  activeMins: number | null;
  triggerValue: number | undefined;
  rawSummary: string | undefined;
  dateTime: string;
  rateTempC: string;
  rateTempF: string;
  notifType?: EnergyMessagesNotifType[];
  lastSensorValue?: number;
  reason: string;
  hvacSetpointBuffer?: number;
};

export const extractLog = (log: AlertNotifModel): AlertLogType => {
  const { metadata, createdAt, endedAt, activeMins } = log;
  const {
    summary: alert,
    recipients = [],
    field = 'temperature',
    deviceName,
    locationName,
    triggerValue,
    rawSummary,
    rateTempChange,
    reason = '',
    lastSensorValue,
    hvacSetpointBuffer,
  } = metadata;
  return {
    alert,
    date: moment(createdAt).format('MMMM DD, YYYY'),
    endedAt,
    recipients,
    sentTo: recipients
      .map(
        (recipient: AlertNotifRecipient) =>
          `${recipient.firstName} ${recipient.lastName}`,
      )
      .join(', '),
    time: moment(createdAt).format('h:mm A'),
    field,
    deviceName,
    locationName,
    activeMins,
    triggerValue,
    rawSummary,
    dateTime: moment(createdAt).format('h:mm A MMM DD'),
    rateTempC: getRateTempString({
      direction: rateTempChange?.direction,
      value: rateTempChange?.c,
      unit: 'C',
    }),
    rateTempF: getRateTempString({
      direction: rateTempChange?.direction,
      value: rateTempChange?.f,
      unit: 'F',
    }),
    reason,
    lastSensorValue,
    hvacSetpointBuffer,
  };
};

export const getAlertDescription = (
  alertNotif: AlertNotifModel,
  tempUnit = 'f' as TempUnits,
) => {
  switch (alertNotif.metadata.field) {
    case 'battery':
      return 'Sensor connectivity, low battery';
    case 'humidity':
      return `${getTriggerComparator(
        alertNotif.metadata.triggerComparator as string,
      )} ${alertNotif.metadata.triggerValue ??
        ''}% humidity for ${getAlertTimeFrame(
        alertNotif.metadata.timeframe as number,
      )}`;
    case 'hvac_zone_temperature':
      return getSendAfterText(
        alertNotif.metadata.timeframe as number,
        tempUnit,
        alertNotif?.metadata.hvacSetpointBuffer,
      );
    case 'missing_data':
      return `Missing data for ${getAlertTimeFrame(
        alertNotif.metadata.timeframe as number,
      )}`;
    case 'temperature':
      return `${getTriggerComparator(
        alertNotif.metadata.triggerComparator as string,
      )} ${convertToPreferredUnit(
        alertNotif.metadata.triggerValue ?? 0,
        'temperature',
        tempUnit,
      )}°${tempUnit.toUpperCase()} for ${getAlertTimeFrame(
        alertNotif.metadata.timeframe as number,
      )}`;
    case 'thermostat_connectivity':
      return `Thermostat offline for ${getAlertTimeFrame(
        alertNotif.metadata.timeframe as number,
      )}`;
  }
};

export const getAlertTimeFrame = (timeframe: number): string => {
  if (timeframe >= 60) {
    const hours = timeframe / 60;
    return `${hours} hr${hours !== 1 ? 's' : ''}`;
  } else {
    return `${timeframe} min${timeframe !== 1 ? 's' : ''}`;
  }
};

export const getRateTempString = ({
  direction,
  value,
  unit,
}: {
  direction?: string;
  value?: number;
  unit: string;
}) => {
  if (!direction || (!value && value !== 0)) {
    return '';
  }
  return `${toSentenceCase(direction)} ${value}°${unit} per hour`;
};

export const getSendAfterText = (
  timeframe: number,
  tempUnit: string,
  buffer?: number,
): string => {
  return buffer
    ? `More than ${getAlertBuffer(
        buffer,
        tempUnit,
      )}°${tempUnit.toUpperCase()} from setpoint for ${getAlertTimeFrame(
        timeframe,
      )}`
    : `Not reaching setpoint for ${getAlertTimeFrame(timeframe)}`;
};

export const getTriggerComparator = (triggerComparator: string) => {
  return triggerComparator === '>' ? 'Above' : 'Below';
};
