import { CancelToken } from 'axios';

import { request } from '../contexts';

export interface DashboardBrowseParams {
  sensorType?: string;
  locationUUID?: string | string[];
  deviceUUID?: string | string[];
  start?: string;
  end?: string;
}

interface ResponseBase {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any[];
  meta: Record<string, unknown>;
}

export interface ResponseDataNumber extends ResponseBase {
  data: number;
}

export interface DeviceSensorData {
  battery: number | null;
  temperature: number | null;
  humidity: number | null;
  time: string;
}

export interface LatestSensorData {
  [key: string]: DeviceSensorData;
}

export interface TSDBResult {
  time: string;
  value: number;
}

export interface ResponseLatestSensor extends ResponseBase {
  data: {
    [key: string]: TSDBResult;
  };
}

export type SensorType = 'temperature' | 'battery' | 'humidity';

export interface GetLatestSensorOption {
  devices: string[];
  sensorType: SensorType;
}

const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);
const timePeriod = {
  end: new Date().toISOString(),
  start: startDate.toISOString(),
};

type ControlEventType =
  | 'override'
  | 'load_shifting'
  | 'peak_shaving'
  | 'demand_response'
  | 'shifting';

export interface ControlEventsParams {
  endDate: string;
  entityType: 'energy_device';
  entityUUID: string;
  executedDate: string;
  startDate: string;
  status: 'enabled' | 'disabled';
  type: ControlEventType[];
}

interface ControlEventsType extends Omit<ControlEventsParams, 'type'> {
  type: ControlEventType;
}

export class DataService {
  public static async browseGraph(
    browseParams: DashboardBrowseParams,
    cancelToken?: CancelToken,
  ) {
    const { sensorType, ...params } = this.getParams(browseParams);
    const { data } = await request.get(`/api/data/graph/${sensorType}`, {
      params,
      cancelToken,
    });
    return data;
  }

  public static async browseDeviceGraph(
    browseParams: DashboardBrowseParams,
    cancelToken?: CancelToken,
  ) {
    const { sensorType, ...params } = this.getParams(browseParams);
    const { data } = await request.get(
      `/api/data/graph/${sensorType}/devices`,
      {
        params,
        cancelToken,
      },
    );
    return data;
  }

  // Employee time saved is not filter based
  public static async getEmployeeTimeSaved(): Promise<ResponseDataNumber> {
    const { data } = await request.get(`/api/data/graph/employee-time`);
    return data;
  }

  public static async getLatestSensorData(
    options: GetLatestSensorOption,
  ): Promise<ResponseLatestSensor> {
    const { sensorType, devices } = options;
    const { data } = await request.post(
      `/api/data/graph/${sensorType}/latest`,
      {
        deviceUUID: devices,
      },
    );
    return data;
  }

  private static getParams(
    params: DashboardBrowseParams,
  ): DashboardBrowseParams {
    const defaultBrowseParams: DashboardBrowseParams = {
      sensorType: 'temperature',
      ...timePeriod,
    };

    return {
      ...defaultBrowseParams,
      ...params,
    };
  }

  public static async getControlEvent(props: ControlEventsParams) {
    const {
      endDate,
      entityType,
      entityUUID,
      executedDate,
      status,
      type,
    } = props;
    const { data } = await request({
      method: 'GET',
      url: `/api/v1/control-event`,
      baseURL:
        process.env.ENERGY_GATEWAY_DOMAIN ??
        'https://gateway.energy.dev.helloatka.com',
      params: {
        endDate: `le${endDate}`,
        entityType,
        entityUUID,
        executed: true,
        executedDate: `ge${executedDate}`,
        limit: process.env.CONTROL_EVENT_LIMIT ?? 500,
        status,
      },
    });

    /**
     * Filter data on the following values:
     * - type
     */
    const allEvents = data.data;
    const filteredEvents = allEvents.filter((event: ControlEventsType) =>
      type.includes(event.type),
    );

    return {
      data: filteredEvents,
    };
  }
}
