import { plainGrey } from '@coinspect/ui';
import React from 'react';

type FormCloseButtonProps = {
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const FormCloseButton = (props: FormCloseButtonProps) => {
  const { style, onClick } = props;
  return (
    <i
      aria-hidden="true"
      className="icon icon-close"
      style={{
        position: 'absolute',
        right: 0,
        color: plainGrey,
        fontSize: '16px',
        ...style,
      }}
      onClick={onClick}
    />
  );
};
