import mixpanel from 'mixpanel-browser';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { tabletMinWidth } from 'src/constants';
import styled from 'styled-components';

import { AccountContext } from '../../../contexts';
import { Role } from '../../../services';
import AppLogoLarge from './icons/AppLogoLarge';
import AppLogoSmall from './icons/AppLogoSmall';
import { BellIcon } from './icons/BellIcon';
import HelpIcon from './icons/HelpIcon';
import HomeIcon from './icons/HomeIcon';
import LocationIcon from './icons/LocationIcon';
import LogoutIcon from './icons/LogoutIcon';
import { PeopleIcon } from './icons/PeopleIcon';
import { ReportsIcon } from './icons/ReportsIcon';

const NavContainer = styled.div`
  display: none;
  @media only screen and (min-width: ${tabletMinWidth}px) {
    padding-left: 12px;
    z-index: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 56px;
    transition: width 0.2s ease-in-out;
    overflow: hidden;
    background: black;
    position: fixed;
    left: 0px;
    font-size: 0px;
    font-weight: 700;
    #large-logo {
      display: none;
    }
    &:hover {
      width: 166px;
      overflow: hidden;
      transition: width 0.2s ease-in-out;
      font-size: 14px;
      align-items: flex-start !important;
      .text {
        padding-left: 16px;
      }
      #small-logo {
        display: none;
      }
      #large-logo {
        display: block;
      }
    }
  }
`;

const NavIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LogOutButton = styled.div`
  color: #8b9198;
  display: flex;
  align-items: center;
  padding-left: 6px;
  padding-top: 28px;

  &:hover {
    cursor: pointer;
  }
`;

const EnergyNav = ({ page }: { page?: string }) => {
  const { user, logout } = useContext(AccountContext);
  const adminOrOwner = [Role.admin, Role.account_owner].includes(
    user?.role || Role.user,
  );
  return (
    <NavContainer>
      <NavIconContainer>
        <Link
          to="/"
          onClick={() => {
            mixpanel.track('Home tab clicked');
          }}
        >
          <div style={{ paddingTop: 28, display: 'flex' }}>
            <AppLogoLarge id="large-logo" />
            <AppLogoSmall id="small-logo" />
          </div>
        </Link>
        <Link to="/energy">
          <div
            style={{
              color: `${!page || page === 'HOME' ? '#B2FFFF' : '#8B9198'}`,
              paddingTop: 40,
              paddingLeft: 8,
              display: 'flex',
            }}
          >
            <HomeIcon
              fill={`${!page || page === 'HOME' ? '#B2FFFF' : '#8B9198'}`}
            />{' '}
            <span className="text">HOME</span>
          </div>
        </Link>
        <Link
          to="/energy/locations"
          onClick={() => {
            mixpanel.track('Locations tab clicked');
          }}
        >
          <div
            style={{
              color: `${page === 'LOCATIONS' ? '#B2FFFF' : '#8B9198'}`,
              paddingTop: 28,
              paddingLeft: 4,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LocationIcon
              fill={`${page === 'LOCATIONS' ? '#B2FFFF' : '#8B9198'}`}
            />{' '}
            <span style={{ paddingLeft: 12 }}>LOCATIONS</span>
          </div>
        </Link>
        {adminOrOwner && (
          <Link
            to="/energy/people"
            onClick={() => {
              mixpanel.track('People tab clicked');
            }}
          >
            <div
              style={{
                alignItems: 'center',
                color: `${page === 'PEOPLE' ? '#B2FFFF' : '#8B9198'}`,
                display: 'flex',
                paddingLeft: 6,
                paddingTop: 28,
              }}
            >
              <PeopleIcon
                fill={`${page === 'PEOPLE' ? '#B2FFFF' : '#8B9198'}`}
              />{' '}
              <span style={{ paddingLeft: 12 }}>PEOPLE</span>
            </div>
          </Link>
        )}
        <Link
          to="/energy/reports"
          onClick={() => {
            mixpanel.track('Report tab clicked');
          }}
        >
          <div
            style={{
              alignItems: 'center',
              color: `${page === 'REPORTS' ? '#B2FFFF' : '#8B9198'}`,
              display: 'flex',
              paddingLeft: 8,
              paddingTop: 28,
            }}
          >
            <ReportsIcon
              fill={`${page === 'REPORTS' ? '#B2FFFF' : '#8B9198'}`}
            />{' '}
            <span style={{ paddingLeft: 16 }}>REPORTS</span>
          </div>
        </Link>
        <Link
          to="/energy/alerts"
          onClick={() => {
            mixpanel.track('Alerts tab clicked');
          }}
        >
          <div
            style={{
              alignItems: 'center',
              color: `${page === 'ALERTS' ? '#B2FFFF' : '#8B9198'}`,
              display: 'flex',
              paddingLeft: 3,
              paddingTop: 28,
            }}
          >
            <BellIcon fill={`${page === 'ALERTS' ? '#B2FFFF' : '#8B9198'}`} />{' '}
            <span style={{ paddingLeft: 14 }}>ALERTS</span>
          </div>
        </Link>
      </NavIconContainer>
      <NavIconContainer
        style={{ justifyContent: 'flex-end', paddingBottom: 100 }}
      >
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.glaciergrid.com/helpcenter"
        >
          <div
            style={{
              color: '#8B9198',
              display: 'flex',
              paddingLeft: 8,
            }}
          >
            <HelpIcon />
            <span className="text">HELP</span>
          </div>
        </a>
        <LogOutButton onClick={() => logout()}>
          <LogoutIcon />
          <span style={{ paddingLeft: 11 }}>LOGOUT</span>
        </LogOutButton>
      </NavIconContainer>
    </NavContainer>
  );
};

export default EnergyNav;
