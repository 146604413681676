import { FormInput } from '@coinspect/ui';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { AccountContext } from '../../../contexts';
import { Text } from '../../text';

type SetpointInputProps = {
  alignLabel?: string;
  label: string;
  fieldSuffix: '%' | string;
  subText?: string; // text below the field
  className?: string;
  labelClassName?: string;
  name: string;
  inputWrapperStyle?: React.CSSProperties;
  config?: {
    min: number;
    max: number;
  };
  disabled: boolean;
};

const SetpointInputButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  width: 24px;
  height: 24px;
  color: #fff;
  background-color: transparent;
  border: 1px solid #41484d;
  cursor: pointer;
`;

const StyledInputWrapper = styled.div`
  width: 64px;

  > .field {
    margin-bottom: 0 !important;
  }

  .field .ui > input[type='text'] {
    text-align: center;
  }
`;

export const SetpointInput = (props: SetpointInputProps) => {
  const { user } = useContext(AccountContext);
  const tempUnit = `\u00B0${user?.preferredTempUnit ?? 'f'}`.toUpperCase();
  const {
    alignLabel = 'center',
    label,
    fieldSuffix,
    subText,
    inputWrapperStyle,
    config,
    ...rest
  } = props;
  const { setValue, getValues } = useFormContext<Record<string, string>>();
  const handleIncrement = () => {
    const val = Number(getValues(props.name));
    if (config && val === config.max) return;
    setValue(props.name, (val + 1).toString(), { shouldValidate: true });
  };
  const handleDecrement = () => {
    const val = Number(getValues(props.name));
    if (config && val === config.min) return;
    setValue(props.name, Math.max(0, val - 1).toString(), {
      shouldValidate: true,
    });
  };
  const displaySuffix = fieldSuffix ?? tempUnit;

  const SetpointInputLabel = styled.div`
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
    text-align: ${alignLabel};
  `;

  return (
    <div>
      <label
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        htmlFor={props.name}
      >
        {label && <SetpointInputLabel>{label}</SetpointInputLabel>}
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <SetpointInputButton
            onClick={handleDecrement}
            disabled={props.disabled}
          >
            -
          </SetpointInputButton>
          <StyledInputWrapper style={inputWrapperStyle}>
            <FormInput {...rest} />
          </StyledInputWrapper>
          <div style={{ fontSize: '12px' }}>{displaySuffix}</div>
          <SetpointInputButton
            onClick={handleIncrement}
            disabled={props.disabled}
          >
            +
          </SetpointInputButton>
        </div>
        {subText && <SubText text={subText} />}
      </label>
    </div>
  );
};

const SubText: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div style={{ marginLeft: '38px', marginTop: '4px' }}>
      <Text useNew asNew="bodySmall" style={{ color: '#A1AAAA' }}>
        {text}
      </Text>
    </div>
  );
};
