export const readableEquipmentInfo = (string?: string) => {
  switch (string) {
    case 'freezer':
      return 'Freezer';
    case 'refrigerator':
      return 'Refrigerator';
    case 'free_standing':
      return 'Reach-in';
    case 'built_in':
      return 'Walk-in';
    case 'other':
    default:
      return 'Other';
  }
};
