import { thermaBlack, thermaBlue } from '@coinspect/ui';
import React from 'react';
import { Step } from 'react-joyride';

import { TourStyles } from '../../style/tour-styles';
import { sharedStepOptions } from '../index';

export const secondaryFlow = (): Step[] => {
  return [
    {
      ...sharedStepOptions,
      title: 'Where are my sensors?',
      content: (
        <div>
          <p>{`You can track your sensors with the order number in your email. Once
          your sensors arrive you'll be able to set up your dashboard.`}</p>
        </div>
      ),
      target: '.dont-have-my-sensors',
      placement: 'top-start',
      styles: {
        ...TourStyles.styles,
        tooltip: {
          backgroundColor: 'white',
        },
        tooltipTitle: {
          ...TourStyles.styles.tooltipTitle,
          color: thermaBlue,
        },
        tooltipContent: {
          ...TourStyles.styles.tooltipContent,
          color: thermaBlack,
        },
        options: {
          ...TourStyles.styles.options,
          arrowColor: 'white',
        },
      },
      showSkipButton: false,
      disableOverlayClose: true,
    },
    {
      ...sharedStepOptions,
      title: 'Add users',
      content: 'Add users who will be adding the sensors!',
      target: '.users-tab',
      placement: 'bottom-end',
    },
    {
      ...sharedStepOptions,
      title: 'Invite users',
      content: 'Add users who will be adding the sensors!',
      target: '#invite-users-btn',
    },
    {
      ...sharedStepOptions,
      title: 'Enter email or phone',
      content:
        'Make sure to add them as an Admin to ensure they have permission to set up the sensors.',
      target: '#invite-email-address',
    },
    {
      ...sharedStepOptions,
      title: 'Enter and invite',
      content:
        'Make sure to add the user as an Admin to ensure they have permission to set up the sensors.',
      target: '#invite-single-user-btn',
      placement: 'top-end',
    },
  ];
};
