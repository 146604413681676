import {
  black12,
  black15,
  black80,
  greyAluminium,
  greyGeyser,
  thermaBlue,
  thermaOffWhite,
  ThermaSecondaryAction100,
  ThermaSecondaryAction400,
} from '@coinspect/ui';
import { mobileMaxWidth, tabletMaxWidth } from 'src/constants';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  .block{
    display: block;
  }

  .flex{
    display: flex;
  }

  .right,
  .float-right {
    float: right;
  }

  .left,
  .float-left{
    float: left;
  }

  .ease-transition {
    transition: 0.275s all ease;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .hidden {
    display: none;
  }

  .hidden-imp {
    display: none !important;
  }

  .not-visible {
    visibility: hidden;
  }

  .text-right {
    text-align: right;
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }

  .text-center{
    text-align: center;
  }

  .text-bold, .bold {
    font-weight: bold;
  }

  .ellipse-text {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .unscrollable {
    @media only screen and (max-width: ${tabletMaxWidth}px) {
      position: fixed;
      max-height: 100vh;
      overflow-y: hidden;
      width: 100%;
    }
  }

  .mobile-fixed {
    @media only screen and (max-width: ${mobileMaxWidth}px) {
      position: fixed;
    }
  }

  .break-all {
    word-break: break-all;
  }

  .break-word {
    word-break: break-word;
  }

  .location-dropdown.not-exist > .text {
    color: ${greyGeyser} !important;
  }

  .location-dropdown > .label.not-exist,
  .location-dropdown > .label.not-exist:hover {
    color: ${greyAluminium} !important;
  }

  .no-width-auto > .ui.input > input {
    width: inherit !important;
  }

  .inline {
    display: inline;
  }

  .table--layout-fixed {
    table-layout: fixed;
  }

  .capitalize-first-letter {
    text-transform: lowercase;
  }
  .capitalize-first-letter::first-letter {
    text-transform: capitalize;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .pointer-events-none {
    pointer-events: none;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .rotating {
    animation: rotation 2s infinite linear;
    line-height: 11px !important;
  }

  /* Only use data-title as tooltip as last resort */
  [data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }
  [data-title]:after {
    content: attr(data-title);
    float: left;
    min-width: min-content;
    border: 1px solid #D4D4D5;
    line-height: 1.4285em;
    max-width: 250px;
    background: #FFFFFF;
    font-weight: normal;
    font-style: normal;
    color: ${black80};
    border-radius: 0.28571429rem;
    box-shadow: 0px 2px 4px 0px ${black12}, 0px 2px 10px 0px ${black15};
    font-size: 12px;
    visibility: hidden;
    margin: 0.71428571em 0em 0em;
    backface-visibility: hidden;
    z-index: 1000;
    transform-origin: left top;
    margin-left: 0em;
    padding: 5px 10px;
    right: auto;
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(0px, 55px, 0px);
    white-space: pre;
  }
  [data-title] {
    position: relative;
  }

  .underline {
    text-decoration: underline;
  }

  .inline-block {
    display: inline-block;
  }

  .bg-none {
    background: none !important;
  }

  .absolute {
    position: absolute;
  }

  .relative {
    position: relative;
  }

  .equipment-alert-config-form.button {
    background-color: ${thermaOffWhite}
  }
  .equipment-alert-config-form.button > i {
    color: #0A0A0A;
  }
  .equipment-alert-config-form.button:hover {
    background-color: #BBC0CC
  }
  .equipment-alert-config-form.active.button {
    background-color: ${ThermaSecondaryAction100}
  }
  .equipment-alert-config-form.active.button > i {
    color: ${thermaBlue};
  }
  .equipment-alert-config-form.active.button:hover {
    background-color: #A6C8D4
  }
  .equipment-alert-config-form.active.button:hover > i {
    color: ${ThermaSecondaryAction400};
  }

  /* override style of react-toastify */
  div#base-toast.Toastify__toast {
    min-height: 50px;
    background-color: #263036;
    color: white;
}
`;
