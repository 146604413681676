// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import { Switch } from 'react-router-dom';

// https://stackoverflow.com/a/67143069/4110257
/* eslint-disable @typescript-eslint/no-explicit-any, prefer-spread */
export function FragmentSupportingSwitch({ children }: { children: any }) {
  const flattenedChildren: any[] = [];
  flatten(flattenedChildren, children);
  return React.createElement.apply(
    React,
    [Switch, null].concat(flattenedChildren),
  );
}

function flatten(target: any, children: any) {
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === React.Fragment) {
        flatten(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
}
