import capitalize from 'lodash/capitalize';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { mobileMaxWidth } from 'src/constants';
import styled from 'styled-components';

import { UserProfile } from '../../contexts';
import {
  useDeleteUser,
  useEditUser,
  useTeamService,
  useUserRole,
} from '../../hooks';
import { StoreContext } from '../../store';
import { Tooltip } from '../tooltip';
import { Team } from '../users/';
import { UserListItem } from './user-list-item';

interface UserListProps {
  users: UserProfile[];
  account: UserProfile;
}

interface HeaderText {
  gridArea: string;
}
const HeaderText = styled.span<HeaderText>`
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    grid-area: ${({ gridArea }) => gridArea};
  }
`;

export const UserList: FunctionComponent<UserListProps> = (props) => {
  const { store } = useContext(StoreContext);
  const { isQueryInProgress } = store.pages;
  const { teams = [] } = store.entities;
  const { users } = props;
  const { deleteUser } = useDeleteUser();
  const { editUser } = useEditUser();
  const { displayRole } = useUserRole();
  const { browseTeam } = useTeamService();

  // Need to grab team data on users page as well
  useEffect(() => {
    (async () => {
      await browseTeam();
    })();
  }, []);

  const capitalizeUserName = (word: string) => {
    return word.replace(/\w+/g, capitalize);
  };

  function handleEditUser(user: UserProfile, isFieldsDisabled: boolean) {
    editUser(user, {
      header: `${user.firstName} ${user.lastName}`,
      isFieldsDisabled: isFieldsDisabled,
    });
  }

  return (
    <>
      {users &&
        users.map((user: UserProfile, key: number) => {
          const userName = capitalizeUserName(
            `${user.firstName} ${user.lastName}`,
          );
          const userRole = displayRole(user.role);
          let actions;

          // Finds a users team
          Object.values<Team>(teams.byUUID).forEach((team) => {
            const members = team.members;
            const foundMember = members.find(
              (member) => member.userUUID === user.uuid,
            );

            if (!user.teams) {
              user.teams = [];
            }

            if (foundMember) {
              user.teams.push(team);
            }
          });

          if (user.role !== 'account_owner') {
            actions = (
              <>
                <Tooltip
                  content="Delete"
                  trigger={
                    <Button
                      floated="right"
                      circular
                      icon
                      onClick={() => deleteUser(user)}
                      className="therma-off-white"
                      disabled={isQueryInProgress['verify:user:delete']}
                    >
                      <Icon
                        className="icon-delete"
                        size="large"
                        aria-label="Delete User"
                      />
                    </Button>
                  }
                />
                <Tooltip
                  content="Edit"
                  trigger={
                    <Button
                      floated="right"
                      circular
                      icon
                      onClick={() => handleEditUser(user, false)}
                      className="therma-off-white"
                    >
                      <Icon
                        className="icon-edit"
                        size="large"
                        aria-label="Edit User"
                      />
                    </Button>
                  }
                />
              </>
            );
          } else {
            actions = (
              <>
                <Tooltip
                  content="Edit"
                  trigger={
                    <Button
                      floated="right"
                      circular
                      icon
                      onClick={() => handleEditUser(user, true)}
                      className="therma-off-white"
                    >
                      <Icon
                        className="icon-edit"
                        size="large"
                        aria-label="Edit User"
                      />
                    </Button>
                  }
                />
              </>
            );
          }

          return (
            <UserListItem
              className="user-list-item"
              bottom={key !== users.length - 1}
              key={user.uuid}
              teams={user.teams}
              userName={user.signedUpDate ? userName : user.email}
              userRole={userRole}
              actions={actions}
              user={user}
              editUserFunc={() => handleEditUser(user, false)}
            />
          );
        })}
    </>
  );
};
