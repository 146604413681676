import React, { createContext, useState } from 'react';

import { useDevicePage } from '../components/pages/use-device-page';
import { useAccordion } from '../components/ui/accordion';

export interface LocationDeviceContextProps {
  isAddingDevice: boolean;
  setIsAddingDevice: React.Dispatch<React.SetStateAction<boolean>>;
  showAddDevice: (panelName: string) => void;
}

interface LocationDeviceProps {
  children: React.ReactNode;
}

export const LocationDeviceContext = createContext<LocationDeviceContextProps>({
  isAddingDevice: false,
  setIsAddingDevice: () => null,
  showAddDevice: () => null,
});

export function LocationDevice(props: LocationDeviceProps) {
  const { children } = props;
  const [isAddingDevice, setIsAddingDevice] = useState(false);
  const { addActiveForm } = useDevicePage();
  const { openPanel } = useAccordion();

  const showAddDevice = (panelName: string) => {
    openPanel(panelName);
    setIsAddingDevice(true);
    addActiveForm();
  };

  const api: LocationDeviceContextProps = {
    isAddingDevice,
    setIsAddingDevice,
    showAddDevice,
  };

  return (
    <LocationDeviceContext.Provider value={api}>
      {children}
    </LocationDeviceContext.Provider>
  );
}
