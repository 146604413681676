import * as FeatureFlag from '@coinspect/feature-flag';
import { toBoolean, toSentenceCase } from 'common-utils-pkg';
import firebase from 'firebase/app';
import 'firebase/auth';
import get from 'lodash/get';
import mixpanel from 'mixpanel-browser';
import { normalize } from 'normalizr';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  AxiosContext,
  LocalStorageContext,
  setToken as setRequestToken,
} from '..';
import { TEST_USERS } from '../../App';
import {
  ADDED_REPORT_UUID,
  useDialogs,
  useFeatures,
  useLocationService,
  UserListSchema,
  useTeamService,
  useUserRole,
} from '../../hooks';
import { Role, UserService } from '../../services';
import * as firebaseServices from '../../services/firebase-service';
import { StoreContext } from '../../store';
import { FeaturesState } from '../../store/reducers';
import { deletedLoggedIn, isAlreadyLoggedIn } from '../account-context/utils';
import * as _2fa from '../utils/send2faCode';
import { Features } from '.';
import { Feature, LoginCredentials, UserProfile } from './types';
import { initializeHotjar } from './use-hotjar';
import { initLogRocket, useLogRocket } from './use-logrocket';
import {
  createVerifier,
  featuresToObject,
  isDueForPasswordRenewal,
  isPasswordProvider,
  setIsRenewingPassword,
} from './utils';

const MIXPANEL_IDENTIFIED_LOCAL = 'mixpanel-identified-2';

const _fnPlaceholder = (...args: unknown[]) => {
  throw new Error(`function hasnt been implemented. args: ${args}`);
};

export interface LogOutOps {
  isInviteDeleted?: boolean;
}

export interface UserAccountContext {
  error: string;
  isInitializing: boolean;
  isSSOSignInLoading: boolean;
  user?: UserProfile | null;
  resetPasswordSuccess: boolean;
  do2FA: boolean;
  isCaptchaReady: boolean;
  doPassRenewal: boolean;

  authorizeEmailPassword: (email: string, password: string) => Promise<void>;
  createAuthProvider: (
    providerName: string,
    scopes?: string[],
  ) => Promise<void>;
  createUserWithEmailAndPassword: (creds: LoginCredentials) => Promise<void>;
  isAdmin: () => boolean;
  logout: (options?: LogOutOps) => void;
  setError: (error: string) => void;
  setResetPWsuccess: (success: boolean) => void;
  setAuthedUser: (user: UserProfile) => void;
  setDoPassRenewal: (is: boolean) => void;
  authorizeFirebaseIdToken: () => void;
}

export const AccountContext = createContext<UserAccountContext>({
  error: '',
  isInitializing: true,
  isSSOSignInLoading: false,
  resetPasswordSuccess: false,
  user: null,
  do2FA: false,
  isCaptchaReady: false,
  doPassRenewal: false,

  authorizeEmailPassword: _fnPlaceholder,
  createAuthProvider: _fnPlaceholder,
  createUserWithEmailAndPassword: _fnPlaceholder,
  isAdmin: _fnPlaceholder,
  logout: _fnPlaceholder,
  setError: _fnPlaceholder,
  setResetPWsuccess: _fnPlaceholder,
  setAuthedUser: _fnPlaceholder,
  setDoPassRenewal: _fnPlaceholder,
  authorizeFirebaseIdToken: _fnPlaceholder,
});

const Account: React.FC<{ children: React.ReactNode }> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { store, dispatch } = useContext(StoreContext);
  const { role: axiosRole } = useContext(AxiosContext);
  const { setItem, removeItem, getItem } = useContext(LocalStorageContext);
  const { browseTeam } = useTeamService();
  const { browseLocations } = useLocationService();
  const { displayRole } = useUserRole();
  const { openAlertDialog, closeDialog } = useDialogs();
  const { set: setFeatures, flags: featureFlags } = useFeatures();

  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>();
  const [authedUser, setAuthedUser] = useState<UserProfile | null>();
  const [isInitializing, setIsInitializing] = useState<boolean>(true);
  const [isSSOSignInLoading, setIsSSOSignInLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [resetPasswordSuccess, setResetPWsuccess] = useState<boolean>(false);
  const [do2FA, setDo2FA] = useState<boolean>(false);
  const [isCaptchaReady, setCaptchReady] = useState(false);
  const [doPassRenewal, setDoPassRenewal] = useState(false);
  const [authProvider, setAuthProvider] = useState('Email');

  const [devFlags, setDevFlags] = useState({
    EQUIPMENT_PAGE: true,
    // default to true if no env var set
    ENERGY_DASHBOARD: process.env.ENERGY_DASHBOARD
      ? toBoolean(process.env.ENERGY_DASHBOARD)
      : true,
  });

  const { LogRocket } = useLogRocket();
  const { users } = store.entities;

  const handleAuthError = (defaultError: string) => {
    setError(defaultError);
    setIsSSOSignInLoading(false);
    return firebase.auth().signOut();
  };

  const updateFlags = (_features: Feature[] = []) => {
    const _flags: FeaturesState = {
      ...featureFlags,
      ...featuresToObject(_features),
      ...devFlags,
    };

    setFeatures(_flags);

    return _flags;
  };

  // interupts normal user login to check for features
  const doFeatureChecks = async (
    user: UserProfile,
    features: FeaturesState,
  ) => {
    if (!firebaseUser) return true;

    const { PASSWORD_ROTATION, TWO_FACTOR_AUTHENTICATION } = Features;
    const isPassRotationEnabled = Boolean(features[PASSWORD_ROTATION]);
    const is2faEnabled = Boolean(features[TWO_FACTOR_AUTHENTICATION]);

    const notTestUser = !TEST_USERS.map((u) => u.email).includes(user.email);

    // TWO_FACTOR_AUTHENTICATION CHECK
    const { enrolledFactors } = firebaseUser.multiFactor;

    type info = firebase.auth.MultiFactorInfo;
    const hasPhoneFactor = (f: info) => f && f.displayName === 'phone number';
    const isEnrolled = Boolean(enrolledFactors.find(hasPhoneFactor));
    if (is2faEnabled && !isEnrolled && notTestUser && isPasswordProvider()) {
      console.debug('2FA enrollment needed');
      _2fa.initCaptcha(() => setCaptchReady(true));
      setDo2FA(true);
      setIsInitializing(false);
      _2fa.isTwoAuthFlow(firebaseUser, user, logout);
      return true;
    }

    // PASSWORD_ROTATION CHECK
    if (
      isPassRotationEnabled &&
      user?.signedUpDate &&
      notTestUser &&
      isPasswordProvider()
    ) {
      setIsInitializing(false);
      setDoPassRenewal(isPassRotationEnabled && isDueForPasswordRenewal(user));
      return true;
    }

    return false;
  };

  const authorizeFirebaseIdToken = async () => {
    if (!firebaseUser) throw new Error('No current User');

    const queryString = new URLSearchParams(location.search);
    const inviteToken = queryString.get('token') || undefined;
    let authedUserRecord;
    try {
      authedUserRecord = await UserService.authenticateFirebaseUser(
        firebaseUser,
        inviteToken,
      );
      setError('');
    } catch (err) {
      const defaultError = `Sorry, couldn't log in with that email`;

      if (get(err, 'response.data.code')) {
        return handleAuthError(get(err, 'response.data.message', defaultError));
      }

      return handleAuthError(defaultError);
    }

    if (!authedUserRecord || !authedUserRecord.user) {
      history.push(`/${location.search}`);
      return;
    }

    const { user, token } = authedUserRecord;

    setAuthedUser(user);
    browseTeam();
    browseLocations();
    const features = updateFlags(user.company.features || []);

    if (!isAlreadyLoggedIn() && (await doFeatureChecks(user, features))) return;

    if (!user.signedUpDate) {
      setIsSSOSignInLoading(false);
      setIsInitializing(false);
      // forced to redirect on registration if the required fields are missing.
      history.push('/registration');
      return;
    }

    dispatch({
      data: normalize([user], UserListSchema),
      type: 'user:set',
    });

    token && setRequestToken(token);

    initLogRocket(user.email);
    LogRocket.identify(user.uuid, {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
    });
    initializeHotjar(user.email);

    setIsSSOSignInLoading(false);
    setIsInitializing(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handle2faError = async (err: any) => {
    if (err.code === 'auth/multi-factor-auth-required') {
      console.debug('2FA verification needed');

      window.resolver = err.resolver;

      _2fa.initCaptcha(() => setCaptchReady(true));
      setDo2FA(true);

      // The user is enrolled in MFA, must be verified
      await _2fa.verifyUser();

      return true;
    }
    return false;
  };

  const createAuthProvider = async (
    providerName: string,
    scopes: string[] = ['profile', 'email'],
  ) => {
    try {
      setIsSSOSignInLoading(true);

      const provider = firebaseServices.createAuthProvider(
        providerName,
        scopes,
      );
      await firebase.auth().signInWithPopup(provider);
      setAuthProvider(toSentenceCase(providerName));
    } catch (err) {
      if (err.code === 'auth/popup-closed-by-user') {
        return setIsSSOSignInLoading(false);
      }

      if (await handle2faError(err)) return;
      setError(`Sorry, couldn't log in with that email`);
      setIsSSOSignInLoading(false);
      console.error(err);
    }
  };

  const authorizeEmailPassword = async (email: string, password: string) => {
    createVerifier(email, password);
    try {
      setIsRenewingPassword(false);
      setFirebaseUser(undefined);
      setRequestToken('');
      const { user } = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      setFirebaseUser(user);
    } catch (err) {
      if (await handle2faError(err)) return;
      console.error(err);
      setError(`Sorry, couldn't log in with that email`);
    }
  };

  const createUserWithEmailAndPassword = async (creds: LoginCredentials) => {
    try {
      await firebaseServices.createUserWithEmailAndPassword(creds);
    } catch (e) {
      if (e.message) setError(e.message);
      return history.push(`/${location.search}`);
    }
  };

  const logout = async (options?: LogOutOps) => {
    setIsInitializing(true);
    removeItem(MIXPANEL_IDENTIFIED_LOCAL);
    await firebase.auth().signOut();
    setAuthedUser(null);
    setFirebaseUser(null);
    setRequestToken('');
    setDo2FA(false);
    setDoPassRenewal(false);
    setFeatures({});
    if (options?.isInviteDeleted) {
      setItem('isInviteDeleted', true);
    } else {
      setItem('isInviteDeleted', false);
    }
    removeItem(ADDED_REPORT_UUID);

    dispatch({
      type: 'dukeTheNuke',
    });
    deletedLoggedIn();
    // TODO: find a way to reinitialize axios service before login to and enable this
    // AxiosService.cancellAlRequests();
    history.push('/');
    window.location.reload();
  };

  const isAdmin = () => {
    if (!authedUser || !authedUser.role) return false;

    return [Role.account_owner, Role.admin].includes(authedUser.role);
  };

  const accountContextApi: UserAccountContext = {
    error,
    isInitializing,
    isSSOSignInLoading,
    resetPasswordSuccess,
    user: authedUser,
    do2FA,
    isCaptchaReady,
    doPassRenewal,

    authorizeEmailPassword,
    createAuthProvider,
    createUserWithEmailAndPassword,
    isAdmin,
    logout,
    setError,
    setResetPWsuccess,
    setAuthedUser,
    setDoPassRenewal,
    authorizeFirebaseIdToken,
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((profile) => {
      setFirebaseUser(profile);
      if (!profile) setIsInitializing(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const DEV_UTILS_ENABLE = Boolean(process.env.DEV_UTILS_ENABLE || false);

    if (DEV_UTILS_ENABLE) {
      window.setEnableFeature = (featureName: string, enabled = true) => {
        setDevFlags({ ...devFlags, [featureName]: enabled });
        console.debug(
          `${featureName} feature ${enabled ? 'enabled' : 'disabled'}`,
        );
      };
    }
  }, []);

  useEffect(() => {
    if (authedUser && users.byUUID[authedUser.uuid]) {
      const {
        email,
        firstName,
        lastName,
        phoneNumber,
        preferredTempUnit,
        profileImg,
        passwordChangeDate,
        role,
      } = users.byUUID[authedUser.uuid];

      setAuthedUser({
        ...authedUser,
        email,
        firstName,
        lastName,
        phoneNumber,
        preferredTempUnit,
        profileImg,
        passwordChangeDate,
        role,
      });
      setIsInitializing(false);
    }
  }, [users]);

  useEffect(() => {
    if (firebaseUser && firebaseUser.uid) {
      authorizeFirebaseIdToken();
    }
  }, [firebaseUser]);

  useEffect(() => {
    if (!axiosRole) return;
    if (authedUser && authedUser.role !== axiosRole) {
      closeDialog();
      if (axiosRole === 'user') {
        openAlertDialog({
          content: `Your role has been changed from Admin to Viewer.
          For more information, please contact your company administrator.`,
          header: 'Account changed',
          onConfirm: () => {
            window.location.href = '/';
          },
        });
      } else {
        openAlertDialog({
          content: `Your role has been changed from Viewer to Admin.`,
          header: 'Account changed',
          onConfirm: () => {
            window.location.reload();
          },
        });
      }
    }
  }, [axiosRole]);

  useEffect(() => {
    setIsSSOSignInLoading(false);

    if (authedUser) {
      const isIdentified = getItem(MIXPANEL_IDENTIFIED_LOCAL, false);

      if (!isIdentified) {
        mixpanel.reset();

        mixpanel.identify(authedUser.uuid);

        mixpanel.people.set({
          Company: authedUser.company.name,
          'Company UUID': authedUser.company.uuid,
          'Customer Source': authedUser.company.customerSource,
          'User Role': displayRole(authedUser.role),
          $city: mixpanel.get_property('$city'),
          $country_code: mixpanel.get_property('$country_code'),
          $email: authedUser.email,
          $first_name: authedUser.firstName,
          $last_name: authedUser.lastName,
          $name: `${authedUser.firstName} ${authedUser.lastName}`,
          $phone: authedUser.phoneNumber,
          $region: mixpanel.get_property('$region'),
          $timezone: mixpanel.get_property('$timezone'),
        });

        mixpanel.track('User Signed In', {
          auth_method: authProvider,
        });

        mixpanel.people.set_once({
          $created: new Date(),
        });

        setItem(MIXPANEL_IDENTIFIED_LOCAL, true);
      }
    }
  }, [authedUser]);

  useEffect(() => {
    updateFlags();
  }, [devFlags]);

  return (
    <AccountContext.Provider value={accountContextApi}>
      <div id="2fa-captcha" className="hidden" />
      <FeatureFlag.elements.FeatureFlagsContext flags={featureFlags}>
        {props.children}
      </FeatureFlag.elements.FeatureFlagsContext>
    </AccountContext.Provider>
  );
};

const useAccountContext = () => useContext(AccountContext);

export { Account, useAccountContext };
