import { darkViolet80, thermaBlue, white85 } from '@coinspect/ui';
import mixpanel from 'mixpanel-browser';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { NavLink, NavLinkProps, useHistory } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { tabletMaxWidth } from '../../constants';
import { AccountContext } from '../../contexts';
import { useFeatures } from '../../hooks';
import { useBannerAlerts } from '../../hooks/use-banner-alerts';
import * as localStorage from '../../hooks/use-local-storage';
import { getHelloThermaLink } from '../../lib';
import { JWTResponse, UserService } from '../../services';
import { StoreContext } from '../../store';
import { ContactSalesModal } from '../dashboard';

const LinkLabel = styled(NavLink)`
  color: ${white85};
  padding: 4px 0;
  margin: 14px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  &:hover {
    color: #fff;
  }
  &.active {
    border-bottom: 2px #fff solid;
  }

  @media only screen and (max-width: ${tabletMaxWidth}px) {
    display: block;
    color: ${darkViolet80};
    font-weight: normal;
    font-size: 18px;
    padding: 10px 0;
    margin: 14px 0;
    &.active {
      font-weight: bold;
      color: ${darkViolet80};
    }
    &:hover {
      color: ${darkViolet80};
    }
  }
`;

export const EnergyDashboardLabelWrapper = styled.div`
  display: inline-block;
  & .ui.button {
    color: #fff;
    font-size: 16px;
    background-color: ${thermaBlue} !important;
  }

  @media only screen and (max-width: ${tabletMaxWidth}px) {
    display: block;
    padding: 10px 0;
    margin: 14px 0;
    & .ui.button {
      color: ${darkViolet80};
      background-color: #fff !important;
      padding: 0;
      font-weight: normal;
      font-size: 18px;
    }
  }
`;

const AdminLink = (args: NavLinkProps): JSX.Element => {
  const userContext = useContext(AccountContext);

  if (userContext.isAdmin()) {
    return <LinkLabel {...args} />;
  } else {
    return <></>;
  }
};

const energyLink = process.env.ENERGY_DASHBOARD_LINK ?? '/energy';
const EnergyDashboardLink = (): JSX.Element => {
  const { store } = useContext(StoreContext);
  const { user } = useContext(AccountContext);
  const onClickEnergyDashboard = () => {
    mixpanel.track('Energy Dashboard Clicked', {
      user_role: user?.role,
      user_name: `${user?.firstName} ${user?.lastName}`,
      company_name: user?.company.name,
    });
  };
  if (store.pages.energyLocations.allLocations.all.length > 0) {
    return (
      <EnergyDashboardLabelWrapper>
        <Button as={NavLink} to={energyLink} onClick={onClickEnergyDashboard}>
          Energy Dashboard
        </Button>
      </EnergyDashboardLabelWrapper>
    );
  }

  return <></>;
};

const onClickGetMoreTherma = () => {
  mixpanel.track('Get More GlacierGrid Clicked');
};

const MoreThermaButton = (
  args: React.ComponentProps<typeof Button>,
): JSX.Element => {
  const { isGetMoreSensorsEnabled } = useFeatures();
  const { user } = useContext(AccountContext);
  const isAccountOwner = user?.role === 'account_owner';

  if (isGetMoreSensorsEnabled && isAccountOwner) {
    return user?.company.customerSource === 'ecomm' ? (
      <Button {...args} />
    ) : (
      <ContactSalesModal onClick={args.onClick} />
    );
  } else {
    return <></>;
  }
};

export const NavList: FunctionComponent = () => {
  const history = useHistory();
  const { user } = useContext(AccountContext);
  const marketingURL = getHelloThermaLink('checkout');
  const { set } = useBannerAlerts();
  const { isEquipmentEnabled } = useFeatures();
  const errorMessage = `Sorry! Only Account Owners can purchase additional GlacierGrid sensors at this time. Please contact your Account Owner or support@glaciergrid.com`;
  // For getting a jwt for wp checkout
  useEffect(() => {
    (async () => {
      if (user && user.companyUUID) {
        const hash: JWTResponse = await UserService.getUserJWT(user);
        const marketingURLHash = `${marketingURL}?data=${hash}`;

        // For redirecting back to wp-checkout page for upselling
        const redirectToCheckout = localStorage.getItem('redirectToCheckout');
        if (redirectToCheckout === 'true') {
          localStorage.setItem('redirectToCheckout', 'false');

          // If user is not an account_owner, we will not redirect them back to wp checkout. Instead show error.
          if (user.role === 'account_owner') {
            if (user?.company.customerSource === 'ecomm') {
              window.location.href = marketingURLHash;
            } else {
              set([
                {
                  icon: <Icon name="exclamation triangle" />,
                  message: errorMessage,
                },
              ]);
            }
          } else {
            set([
              {
                icon: <Icon name="exclamation triangle" />,
                message: errorMessage,
              },
            ]);
          }
        }
      }
    })();
  }, []);

  return (
    <>
      <LinkLabel
        to="/"
        exact={true}
        activeClassName="active dashboard-tab--active"
        className="dashboard-tab"
      >
        Dashboard
      </LinkLabel>

      {/* if isEquipmentEnabled is activated EQUIPMENT_PAGE flags true */}
      {isEquipmentEnabled ? (
        <AdminLink
          to="/equipments"
          activeClassName="active equipments-tab--active"
          className="equipments-tab"
        >
          Equipment
        </AdminLink>
      ) : (
        // Otherwise
        <AdminLink
          to="/sensors"
          activeClassName="active sensors-tab--active"
          className="sensors-tab"
        >
          Sensors
        </AdminLink>
      )}

      <AdminLink
        to="/users"
        activeClassName="active users-tab--active"
        className="users-tab"
      >
        Users
      </AdminLink>
      <LinkLabel
        to="/reports"
        activeClassName="active reports-tab--active"
        className="reports-tab"
      >
        Reports
      </LinkLabel>
      <MoreThermaButton
        primary
        as="a"
        onClick={() => {
          onClickGetMoreTherma();
          history.push({
            pathname: '/session-portal',
          });
        }}
      >
        Buy more
      </MoreThermaButton>

      <EnergyDashboardLink />
    </>
  );
};
