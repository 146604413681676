import React, { useMemo } from 'react';
import { DropdownProps } from 'semantic-ui-react';

import { EnergyDropdown } from '../dropdown';

export interface LimitDropdownProps extends DropdownProps {
  limits: number[];
  onLimitChange: (newLimit: number) => void;
  selectedLimit: number;
}

export function LimitDropdown({
  limits,
  onLimitChange,
  selectedLimit,
}: LimitDropdownProps) {
  const limitOptions = useMemo(
    () =>
      limits.map((limit) => {
        return { text: limit, value: limit };
      }),
    [limits],
  );

  return (
    <EnergyDropdown
      onChange={(_event, data) => onLimitChange(data.value as number)}
      options={limitOptions}
      value={selectedLimit}
    />
  );
}

export default LimitDropdown;
