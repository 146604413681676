import { ButtonContainer } from '@coinspect/ui';
import uniqueId from 'lodash/uniqueId';
import React, { FC, useContext } from 'react';
import {
  Button,
  Header,
  Icon,
  Modal,
  SemanticCOLORS,
  SemanticICONS,
} from 'semantic-ui-react';
import styled, {
  createGlobalStyle,
  css,
  FlattenSimpleInterpolation,
} from 'styled-components';

import {
  mobileMaxWidth,
  mobileMaxWidthPx,
  tabletMaxWidth,
  tabletMinWidth,
  w1079,
} from '../../constants/responsive-constants';
import { ModalContext } from '../../contexts';
import { StoreContext } from '../../store/store-context';

interface WrappedModalProps {
  bgcolor?: string;
  modalPadding?: string;
}

export type ButtonEmphasis = 'primary' | 'secondary';

export interface BaseModalButtons {
  content: string;
  onClick?: (...args: any[]) => void | Promise<void>;
  icon?: SemanticICONS;
  color?: SemanticCOLORS;
  emphasis?: ButtonEmphasis;
}

export interface BaseModalProps {
  buttons?: BaseModalButtons[]; // array of buttons
  content?: string | JSX.Element; // content of modal
  header?: string | JSX.Element; // header of modal || else element not rendered
  subheader?: string | JSX.Element; // subheader of modal || else element not rendered
  isOpen?: boolean; // open modal
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  closeIcon?: boolean;
  padding?: 'standard' | 'none';
  hasModalActions?: boolean; // show modal buttons or not. you might have your own buttons in the content
  padSize?: string;
  fullScreenOnMobile?: boolean;
  fullScreenOnTablet?: boolean;
  disableButtonsOnQuery?: string;
  onClose?: () => void;
  closeOnOutsideClick?: boolean;
  widthSize?: string | FlattenSimpleInterpolation; // can accept css styling
  mobileModalPadding?: string;
  tabletWidth?: string;
  mobileWidth?: string;
  primaryButtonText?: string; // replaces the default 'OK'
}

const MobileModalStyle = createGlobalStyle`
  @media only screen and (max-width: ${mobileMaxWidth}px ){
    .ui.page.dimmer {
      padding: 0;
      .ui.modal {
        width: 100%;
        min-height: 100vh;
        margin: 0;
        border-radius: 0;
        padding: 14px;
      }
    }
    && {
      .content {
        padding: 0;
      }
    }
  }
`;

const TabletModalStyle = createGlobalStyle`
  @media only screen and (min-width: ${tabletMinWidth}px ) and (max-width: ${tabletMaxWidth}px ){
    .ui.page.dimmer {
      padding: 0;
      .ui.modal {
        width: 100%;
        min-height: auto;
        margin: 0;
        border-radius: 0;
        padding: 14px;
      }
    }
  }
`;

const ModalCloseIcon = styled(Icon)`
  && {
    @media only screen and (max-width: ${mobileMaxWidth}px) {
      margin-top: 25px;
    }

    position: absolute;
    right: 0;
    margin: 30px;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    color: #959ca7;
  }
`;

const WrappedModal = styled(Modal)<WrappedModalProps>`
  &&&& {
    ${({ widthsize }) =>
      typeof widthsize === 'string'
        ? css`
            width: widthsize;
          `
        : widthsize}
    padding: ${({ padsize }) => padsize};
    ${({ modalpadding }) =>
      modalpadding === 'none' &&
      ' &.ui.modal, &.ui.modal > .content { padding: 0; } '}
    @media only screen and (max-width: ${mobileMaxWidthPx}) {
      .content {
        padding: ${({ mobilemodalpadding }) =>
          mobilemodalpadding
            ? mobilemodalpadding + ' !important'
            : '1rem !important'};
      }
      width: ${({ mobilewidth }) => mobilewidth};
      min-height: auto;
    }
    @media only screen and (min-width: ${tabletMinWidth}px ) and (max-width: ${w1079}px ){
      width: ${({ tabletwidth }) => tabletwidth};
      min-height: auto;
    }
  }
`;

const mappedButtons = (
  allButtons: BaseModalButtons[],
  disableButtonsOnQuery: string,
) => {
  const { store } = useContext(StoreContext);
  const { isQueryInProgress } = store.pages;

  return allButtons
    .map((button: BaseModalButtons) => {
      const { content, emphasis, ...rest } = button;
      const buttonProps = {
        ...rest,
        [emphasis || 'primary']: true,
      };
      return (
        <Button
          key={uniqueId(`${content}_`)}
          {...buttonProps}
          disabled={isQueryInProgress[disableButtonsOnQuery]}
          // do not show loading for secondary buttons (i.e. cancel)
          loading={
            emphasis !== 'secondary' && isQueryInProgress[disableButtonsOnQuery]
          }
        >
          {button.icon && <Icon name={button.icon} />}
          {content}
        </Button>
      );
    })
    .reverse();
};

export const BaseModal: FC<BaseModalProps> = (props) => {
  const { primaryButtonText = 'OK' } = props;
  const { hideModal } = useContext(ModalContext);
  const { store } = useContext(StoreContext);
  const { isQueryInProgress } = store.pages;

  const defaultButton = {
    content: primaryButtonText,
    key: primaryButtonText,
    onClick: () => {
      hideModal();
    },
  };

  const {
    buttons = [defaultButton],
    closeIcon,
    onClose = null,
    content = 'This is a default content. Can accept string or React Element',
    header = null,
    subheader = null,
    isOpen,
    padding = 'standard',
    size,
    hasModalActions = true,
    padSize,
    fullScreenOnMobile = false,
    fullScreenOnTablet = false,
    disableButtonsOnQuery = '',
    widthSize,
    mobileModalPadding,
    tabletWidth,
    mobileWidth,
  } = props;

  const CloseIconActions = () => {
    hideModal();
    onClose && onClose();
  };

  return (
    <>
      {fullScreenOnTablet && <TabletModalStyle />}
      {fullScreenOnMobile && <MobileModalStyle />}
      <WrappedModal
        open={isOpen}
        size={size}
        modalpadding={padding}
        padsize={padSize}
        widthsize={widthSize}
        mobilemodalpadding={mobileModalPadding}
        tabletwidth={tabletWidth}
        mobilewidth={mobileWidth}
      >
        {closeIcon && (
          <ModalCloseIcon
            className="icon-close"
            aria-hidden="true"
            onClick={CloseIconActions}
            disabled={isQueryInProgress[disableButtonsOnQuery]}
          />
        )}
        {header && (
          <Header as="h2">
            {header}
            {subheader && <Header.Subheader>{subheader}</Header.Subheader>}
          </Header>
        )}
        <Modal.Content className="break-word">{content}</Modal.Content>
        {hasModalActions && (
          <Modal.Actions>
            <ButtonContainer>
              {mappedButtons(buttons, disableButtonsOnQuery)}
            </ButtonContainer>
          </Modal.Actions>
        )}
      </WrappedModal>
    </>
  );
};
