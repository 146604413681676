import { darkBlue80, thermaGreen, thermaH2FontFamily } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import styled from 'styled-components';

const DEFAULT_TOP_FONT_SIZE = 40;
const DEFAULT_BOTTOM_FONT_SIZE = 20;

interface PieGraphProps {
  size: number;
  value: number;
  label: string;
  className?: string;
}

const PieGraphWrapper = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: ${(props): number => props.height}px;
  }
`;

const PieGraph: FunctionComponent<PieGraphProps> = (props) => {
  const { size, label } = props;
  const value = props.value > 0 ? props.value : 0;
  const pieData = [
    {
      name: 'filled',
      value,
    },
    {
      name: 'empty',
      value: 100 - value,
    },
  ];
  return (
    <PieGraphWrapper height={size}>
      <PieChart width={size} height={size}>
        {props.value >= 0 && (
          <text
            className="time-alert-free__chart-value"
            direction="inherit"
            x="50%"
            y="50%"
          >
            <tspan
              x="50%"
              dx="0"
              textAnchor="middle"
              style={{
                fill: darkBlue80,
                fontFamily: `${thermaH2FontFamily}`,
                fontSize: DEFAULT_TOP_FONT_SIZE,
                fontWeight: 'bold',
                stroke: 'transparent',
              }}
            >
              {`${value}%`}
            </tspan>
          </text>
        )}

        <text
          className="time-alert-free__chart-label"
          direction="inherit"
          x="50%"
          y={props.value >= 0 ? '65%' : '52%'}
        >
          <tspan
            x="50%"
            dx="0"
            textAnchor="middle"
            style={{
              fill: darkBlue80,
              fontFamily: `${thermaH2FontFamily}`,
              fontSize: DEFAULT_BOTTOM_FONT_SIZE,
              stroke: 'transparent',
            }}
          >
            {label}
          </tspan>
        </text>

        <Pie
          className="time-alert-free__chart-image"
          data={pieData}
          dataKey="value"
          innerRadius={70}
          outerRadius={100}
          cx="50%"
          cy="50%"
          fill="#DADFE8"
          startAngle={90}
          endAngle={-270}
          paddingAngle={0}
          blendStroke
        >
          <Cell key="filled" fill={thermaGreen} />
        </Pie>
      </PieChart>
    </PieGraphWrapper>
  );
};

export { PieGraph };
