import { addDecimalPoint } from '@coinspect/utils';
import React, { FunctionComponent } from 'react';
import { Placeholder } from 'semantic-ui-react';
import { convertToPreferredUnit } from 'src/utils/temperature-util';
import styled from 'styled-components';

import { TempUnits } from '../../services';

interface TemperatureDisplay {
  temperature: number | null;
  tempUnit: TempUnits;
  title?: string;
}

interface HumidityDisplay {
  humidity: number | null;
}

export const ValuePlaceholder = styled(Placeholder)`
  &&&&& {
    width: 80%;
    &.ui.placeholder .line {
      background: transparent;
    }
  }
`;

export const Temperature: FunctionComponent<TemperatureDisplay> = ({
  temperature,
  tempUnit,
}) => {
  if (temperature === null || temperature === undefined) {
    return <span>--</span>;
  }

  return (
    <div className="device-temp-data">
      {`
				${convertToPreferredUnit(temperature, 'temperature', tempUnit)}
				\u00b0
				${tempUnit.toUpperCase()}
			`}
    </div>
  );
};

export const Humidity: FunctionComponent<HumidityDisplay> = ({ humidity }) => {
  if (humidity === null || humidity === undefined) {
    return <span>--</span>;
  } else {
    return <span>{addDecimalPoint(humidity)}%</span>;
  }
};
