import { roundNumber } from '@coinspect/utils';
import axios, { CancelTokenSource } from 'axios';
import { useContext, useEffect, useRef } from 'react';

import {
  matchAlertNotifClosestGraphTime,
  matchControlEventClosestGraphTime,
  mutateHvacGraphData,
} from '../components/temperature-humidity-graph/utils/graph-utils';
import { AccountContext } from '../contexts';
import {
  AlertNotifService,
  DashboardBrowseParams,
  DataService,
  EnergyLocationService,
  LatestSensorData,
  SensorType,
} from '../services';
import { Filters, StoreContext } from '../store';
import { EquipmentGraphActions } from '../store/reducers/equipment-graph-reducer';

export function useDataService() {
  const { dispatch } = useContext(StoreContext);
  const { user } = useContext(AccountContext);
  const browseGraphCancelToken = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    return () => {
      if (browseGraphCancelToken.current) {
        browseGraphCancelToken.current.cancel(
          'Cancelling browseGraph old request stacked on top.',
        );
      }
    };
  }, []);

  async function browseGraph(browseParams: DashboardBrowseParams) {
    if (browseGraphCancelToken.current) {
      browseGraphCancelToken.current.cancel(
        'Cancelling browseGraph old request stacked on top.',
      );
    }
    const newCancelToken = axios.CancelToken.source();
    browseGraphCancelToken.current = newCancelToken;

    dispatch({ type: 'browseGraph:set_REQUEST' });
    try {
      const { data = [] } = await DataService.browseGraph(
        browseParams,
        newCancelToken.token,
      );
      const { sensorType } = browseParams;

      dispatch({
        data: {
          graphData: data,
          sensorType,
        },
        type: `${sensorType}:graph:set`,
      });
    } finally {
      dispatch({ type: 'browseGraph:set_FINALLY' });
    }
  }

  async function browseEquipmentGraphData(
    browseParams: {
      end: string;
      start: string;
      uuid: string;
    },
    graphType: 'temperature' | 'humidity',
    type?: 'hvac',
    maxTempValue?: number,
  ) {
    const { end, start, uuid } = browseParams;
    if (browseGraphCancelToken.current !== null) {
      browseGraphCancelToken.current.cancel(
        'Cancelling browseGraph old request stacked on top.',
      );
    }
    const newCancelToken = axios.CancelToken.source();
    browseGraphCancelToken.current = newCancelToken;

    dispatch({ type: 'browseGraph:set_REQUEST' });
    try {
      const promises = [];

      if (type === 'hvac') {
        promises.push(
          // hvac graph data
          EnergyLocationService.browseHvacGraph({
            equipmentUUID: uuid,
            startTime: start,
            endTime: end,
          }),
        );
      } else {
        promises.push(
          // temperature graph data
          graphType === 'temperature' &&
            DataService.browseDeviceGraph(
              { end, start, deviceUUID: [uuid], sensorType: 'temperature' },
              newCancelToken.token,
            ),
          // humidity graph data
          graphType === 'humidity' &&
            DataService.browseDeviceGraph(
              { end, start, deviceUUID: [uuid], sensorType: 'humidity' },
              newCancelToken.token,
            ),
          // active alerts
          AlertNotifService.browse(
            {
              endDate: end,
              startDate: start,
              deviceUUID: [uuid],
              limit: 30,
            },
            newCancelToken.token,
          ),
        );
      }

      // eco mode
      promises.push(
        DataService.getControlEvent({
          entityUUID: uuid,
          endDate: end,
          executedDate: start,
          entityType: 'energy_device',
          startDate: start,
          status: 'enabled',
          type: [
            'load_shifting',
            'peak_shaving',
            'demand_response',
            'shifting',
          ],
        }),
      );

      // remove either temperature or humidity graph promise based on the active graph type
      const result = await Promise.allSettled(
        promises.filter((promise) => !!promise),
      );

      const matchedAlertNotif =
        type !== 'hvac' &&
        graphType === 'temperature' &&
        result[0].status === 'fulfilled' &&
        result[1].status === 'fulfilled'
          ? matchAlertNotifClosestGraphTime(
              result[1].value.data.toReversed(),
              result[0].value.data,
              user?.preferredTempUnit,
            )
          : [];

      // hvac - [hvac, ecoMode]
      // not hvac - [temp/humid, alertNotif, ecoMode]
      const ecoModeIdx = type === 'hvac' ? 1 : 2;

      let fetchedGraphData = {
        ...(graphType === 'temperature' && {
          temperature:
            type !== 'hvac' && result[0].status === 'fulfilled'
              ? result[0].value.data
              : [],
        }),
        ...(graphType === 'humidity' && {
          humidity:
            type !== 'hvac' && result[0].status === 'fulfilled'
              ? result[0].value.data
              : [],
        }),
        alertSent: matchedAlertNotif,
        ecoMode:
          result[ecoModeIdx].status === 'fulfilled' &&
          result[0].value.data.length !== 0
            ? matchControlEventClosestGraphTime(
                result[ecoModeIdx].value.data,
                result[0].value.data,
                maxTempValue,
                type,
              )
            : [],
      };

      // handle hvac graph data points differently
      if (type === 'hvac' && result[0].status === 'fulfilled') {
        fetchedGraphData = {
          ...fetchedGraphData,
          ...mutateHvacGraphData(result[0].value.data),
        };
      }

      dispatch({
        type: EquipmentGraphActions.setGraphData,
        data: {
          graphData: fetchedGraphData,
        },
      });
    } finally {
      dispatch({ type: 'browseGraph:set_FINALLY' });
    }
  }

  async function getEmployeeTimeSaved() {
    dispatch({
      type: 'employeeTimeSaved:fetch_REQUEST',
    });
    const { data } = await DataService.getEmployeeTimeSaved();

    dispatch({
      data: Number(data.toFixed(1)),
      type: 'employeeTimeSaved:set',
    });

    dispatch({
      type: 'employeeTimeSaved:fetch_FINALLY',
    });
  }

  function getLatestSensorData(
    devices: string[],
    sensorType: SensorType,
    start: string,
  ) {
    return DataService.getLatestSensorData({
      devices,
      sensorType,
      start,
    });
  }

  async function getAllLatestSensorData(devices: string[], filters: Filters) {
    dispatch({ type: 'latestSensorData:set_REQUEST' });
    const { sensorType = 'temperature' } = filters;
    const { start = '' } = filters.date;
    try {
      const [
        temperatureResult,
        batteryResult,
        humidityResult,
      ] = await Promise.all([
        await DataService.getLatestSensorData({
          devices,
          sensorType,
          start,
        }),
        await DataService.getLatestSensorData({
          devices,
          sensorType: 'battery',
          start,
        }),
        await DataService.getLatestSensorData({
          devices,
          sensorType: 'humidity',
          start,
        }),
      ]);

      if (!devices.length && !temperatureResult && !batteryResult) {
        return;
      }

      const result = temperatureResult ? temperatureResult : batteryResult;
      const deviceUUIDs = devices.length ? devices : Object.keys(result.data);
      const formattedData = deviceUUIDs.reduce(
        (data: LatestSensorData, deviceUUID: string) => {
          const { value: battery = null } =
            batteryResult.data[deviceUUID] || {};
          const { value: humidity = null } =
            humidityResult.data[deviceUUID] || {};
          const { value: sensorData = null } =
            temperatureResult.data[deviceUUID] || {};
          const { time: time = '' } = temperatureResult.data[deviceUUID] || {};

          data[deviceUUID] = {
            battery,
            temperature: roundNumber(sensorData, 1),
            humidity: roundNumber(humidity, 1),
            time,
          };

          return data;
        },
        {},
      );
      dispatch({
        data: formattedData,
        type: 'latestSensorData:set',
      });
    } finally {
      return dispatch({ type: 'latestSensorData:set_FINALLY' });
    }
  }

  return {
    browseGraph,
    getLatestSensorData,
    getAllLatestSensorData,
    getEmployeeTimeSaved,
    browseEquipmentGraphData,
  };
}
