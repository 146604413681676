import { useContext } from 'react';

import { StoreContext } from '../../store';
import { ClimateService } from './climate-service';

export function useClimateImpactService() {
  const { dispatch } = useContext(StoreContext);

  async function browseClimateImpact(energyLocationUUID?: string) {
    try {
      dispatch({ type: 'browseClimateImpact:get_REQUEST' });

      return ClimateService.browseClimateImpact(energyLocationUUID);
    } finally {
      dispatch({ type: 'browseClimateImpact:get_FINALLY' });
    }
  }

  function resetClimateImpactRequest() {
    dispatch({
      type: 'browseClimateImpact:get_RESET',
    });

    return Promise.resolve();
  }

  return {
    browseClimateImpact,
    resetClimateImpactRequest,
  };
}
