import moment from 'moment';

import { REPORT_SCHEDULE, ReportConfigSchedule } from '../services';

export const reportNextDeliveryDate = (
  deliveryDate: string,
  schedule: ReportConfigSchedule,
) => {
  const DISPLAY_DATE_FORMAT = 'MM/DD/YY';
  const NEXT_MONTH_DATE_FORMAT = 'MM/01/YY';

  switch (schedule) {
    case REPORT_SCHEDULE.DAILY:
      const tomorrow = moment().add(1, 'day');
      return moment(tomorrow).format(DISPLAY_DATE_FORMAT);
    case REPORT_SCHEDULE.WEEKLY:
    case REPORT_SCHEDULE.BI_WEEKLY:
      const nextWeek = moment().day(8);
      return moment(nextWeek).format(DISPLAY_DATE_FORMAT);
    case REPORT_SCHEDULE.MONTHLY:
      const nextMonth = moment().add(1, 'months');
      return moment(nextMonth).format(NEXT_MONTH_DATE_FORMAT);
  }
};
