import { unFormatNumber } from '@coinspect/ui';
import _get from 'lodash/get';
import React, { FC, useEffect, useState } from 'react';
import { FormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import { isMobileOnly } from '../../constants';
import { useFeatures } from '../../hooks';
import { isFormAcceptable } from '../../lib/isFormAcceptable';
import { UserService } from '../../services';
import StandardModalActions from '../modals/standard-modal-actions';
import StandardModalBody from '../modals/standard-modal-body';
import {
  UserDetailsActionsFrom,
  UserDetailsFormFields,
  UserDetailsFormProps,
} from './user-details-form-fields';

const UserDetailsForm: FC<UserDetailsFormProps> = (props) => {
  const {
    geolocationData,
    withRoleToggle = false,
    withNameFields = false,
    fields,
    onCancel,
    onSubmit,
    validationOnLoad = false,
    disableEmail = false,
    disableTeams = false,
    disableField = false,
    uuid,
    isModal = false,
    formMethods,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { is2faEnabled } = useFeatures();

  useEffect(() => {
    if (validationOnLoad) {
      formMethods.triggerValidation();
    }

    formMethods.register({ name: 'role' });
    formMethods.register({ name: 'preferredTempUnit' });
    if (!withNameFields) {
      formMethods.register({ name: 'firstName' });
      formMethods.register({ name: 'lastName' });
    }
  }, []);

  const handleFormSubmit = formMethods.handleSubmit(async (data) => {
    let hasError = false;
    setIsLoading(true);
    const { countryCode } = data;
    const newPhoneNumber = `${countryCode}-${unFormatNumber(data.phoneNumber)}`;
    const oldPhoneNumber = props.fields.phoneNumber;

    delete data.countryCode;
    try {
      const res = await onSubmit({
        ...data,
        phoneNumber: newPhoneNumber,
      });

      if (is2faEnabled && oldPhoneNumber && oldPhoneNumber !== newPhoneNumber) {
        UserService.unenrollUser2fa(props.fields.uuid as string);
      }

      if (res) {
        for (const key of Object.keys(res)) {
          formMethods.setError(key, `${key}Duplicate`);
          hasError = true;
        }
        if (hasError) {
          setIsLoading(false);
        }
      }
    } catch (e) {
      if (e.code === 'auth/invalid-phone-number') {
        formMethods.setError('phoneNumber', 'invalidaNumber2fa');
      }
      setIsLoading(false);
    }
  });

  useEffect(() => {
    if (geolocationData) {
      const countryCode = _get(
        geolocationData,
        'results[0].address_components[0].short_name',
        '',
      );

      formMethods.setValue(
        'preferredTempUnit',
        countryCode === 'US' ? 'f' : 'c',
      );
    }
  }, [geolocationData]);

  const location = useLocation();
  const isEnergy = location.pathname.includes('energy');

  return isModal ? (
    <StandardModalBody>
      <StandardModalBody.Content>
        <FormContext {...formMethods}>
          <Form className="user-details-form" noValidate>
            <UserDetailsFormFields
              withRoleToggle={withRoleToggle}
              withNameFields={withNameFields}
              fields={fields}
              disableEmail={disableEmail}
              disableTeams={disableTeams}
              disableField={disableField}
              uuid={uuid}
            />
          </Form>
        </FormContext>
      </StandardModalBody.Content>
      <StandardModalBody.Actions
        primaryButton={{
          className: isEnergy ? 'energy-user-save-button' : '',
          content: 'Save',
          onClick: handleFormSubmit,
          disabled:
            isLoading ||
            (!isEnergy && onCancel
              ? !isFormAcceptable(formMethods.formState)
              : !formMethods.formState.isValid),
          loading: isLoading,
        }}
        secondaryButton={{
          className: isEnergy ? 'energy-user-cancel-button' : '',
          content: 'Cancel',
          onClick: onCancel,
        }}
      >
        {fields.role === 'account_owner' && !isEnergy && (
          <StandardModalActions.AfterButtonArea>
            Need to update your subscription?
            {isMobileOnly() ? <br /> : ' '}
            <a href="mailto:support@glaciergrid.com">Email us</a>
          </StandardModalActions.AfterButtonArea>
        )}
      </StandardModalBody.Actions>
    </StandardModalBody>
  ) : (
    <FormContext {...formMethods}>
      <Form className="user-details-form" noValidate>
        <UserDetailsFormFields
          withRoleToggle={withRoleToggle}
          withNameFields={withNameFields}
          fields={fields}
          disableEmail={disableEmail}
          disableTeams={disableTeams}
          uuid={uuid}
        />
      </Form>
      <UserDetailsActionsFrom
        onCancel={onCancel}
        onSubmit={handleFormSubmit}
        cancelDisabled={isLoading}
        submitDisabled={
          isLoading ||
          (onCancel
            ? !isFormAcceptable(formMethods.formState)
            : !formMethods.formState.isValid)
        }
        submitLoading={isLoading}
      />
    </FormContext>
  );
};

export { UserDetailsForm };
