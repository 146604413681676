import React, { useContext } from 'react';

import { UserProfile } from '../contexts';
import {
  useDialogs,
  useNotifConfig,
  useTeamService,
  useUserService,
} from '../hooks';
import { LocationDeviceNotifConfig } from '../services';
import { StoreContext } from '../store';

function formatDisplayData(
  locationDeviceNotifConfig: LocationDeviceNotifConfig[],
): string[] {
  let data: string[] = [];

  for (const loc of locationDeviceNotifConfig) {
    /**
     * If no devices, meaning alertConfigs are location based only
     */
    if (!loc.devices || loc.devices.length === 0) {
      for (const alertConfigs of loc.alertConfigs) {
        const locationInfo = `${loc.name} Alert ${alertConfigs.index}`;
        data = [...new Set([...data, locationInfo])];
      }
      continue;
    }

    for (const device of loc.devices) {
      for (const { index } of device.alertConfigs) {
        const info = `${loc.name ||
          loc.storeNumber ||
          loc.address} ${device.name || device.serialNumber} Alert ${index}`;
        data = [...new Set([...data, info])];
      }
    }
  }

  return data;
}

export const useDeleteUser = () => {
  const { dispatch } = useContext(StoreContext);
  const { browseNotifConfigByUserUUID } = useNotifConfig();
  const { browseTeamsLocallyOnlyUserUUID } = useTeamService();
  const { openDeleteDialog, openAlertDialog, closeDialog } = useDialogs();
  const UserService = useUserService();

  function doDeleteUser({ uuid }: UserProfile) {
    (async () => {
      const res = await UserService.deleteUser(uuid);
      if (res) {
        closeDialog();
      }
    })();
  }

  function showDeleteUserModal(user: UserProfile) {
    openDeleteDialog({
      content: (
        <>
          Are you sure you want to delete&nbsp;
          <strong>
            {user.firstName} {user.lastName}
          </strong>
          ? This deletes all associated data and cannot be undone.
        </>
      ),
      onConfirm: async () => {
        await doDeleteUser(user);
      },
      disableButtonsOnQuery: 'user:delete',
    });
  }

  function teamBlock(teams: string[]) {
    if (teams.length === 1) {
      return <strong>{teams[0]}</strong>;
    }
    return (
      <ul>
        {teams.map((data, index) => {
          return (
            <li key={'block-user-delete-teams-' + index}>
              <strong>{data}</strong>
            </li>
          );
        })}
      </ul>
    );
  }

  function notifConfigBlock(notifConfigs: string[]) {
    if (notifConfigs.length === 1) {
      return <strong>{notifConfigs[0]}</strong>;
    }
    return (
      <ul>
        {notifConfigs.map((data, index) => {
          return (
            <li key={'block-user-delete-' + index}>
              <strong>{data}</strong>
            </li>
          );
        })}
      </ul>
    );
  }

  function pluralize(data: string[], word: string) {
    if (!data.length) {
      return '';
    }
    if (data.length > 1) {
      return `${word}s`;
    }
    return word;
  }

  function blockUserDelete({
    locationDeviceNotifConfigs,
    teamsWithOnlyUser,
    user,
  }: {
    locationDeviceNotifConfigs: LocationDeviceNotifConfig[];
    teamsWithOnlyUser: string[];
    user: UserProfile;
  }) {
    const displayData = formatDisplayData(locationDeviceNotifConfigs) || [];
    const pluralizedTeam = pluralize(teamsWithOnlyUser, 'team');
    const pluralizedNotif = pluralize(displayData, 'alert');
    const content = (
      <>
        This is the only user listed on
        {displayData.length > 1 || teamsWithOnlyUser.length > 1 ? ':' : ' '}
        {displayData.length ? notifConfigBlock(displayData) : ' '}
        {displayData.length > 0 && teamsWithOnlyUser.length > 0 && ' and '}
        {teamsWithOnlyUser.length > 0 ? teamBlock(teamsWithOnlyUser) : ' '}
        {teamsWithOnlyUser.length === 1 ||
        (teamsWithOnlyUser.length === 0 && displayData.length === 1)
          ? '.'
          : ''}
        <br />
        Please update the {pluralizedNotif}{' '}
        {displayData.length > 0 && teamsWithOnlyUser.length > 0 && 'and'}{' '}
        {pluralizedTeam} before removing&nbsp;
        <strong>
          {user.firstName} {user.lastName}
        </strong>
        .
      </>
    );

    openAlertDialog({
      content,
      header: 'User cannot be removed',
    });
  }

  async function processDelete(user: UserProfile) {
    dispatch({
      type: 'verify:user:delete_REQUEST',
    });

    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const locationDeviceNotifConfigs: any = await browseNotifConfigByUserUUID(
        user.uuid,
      );

      const teamsWithOnlyUser: string[] = browseTeamsLocallyOnlyUserUUID(
        user.uuid,
      );

      if (!locationDeviceNotifConfigs.length && !teamsWithOnlyUser.length) {
        return showDeleteUserModal(user);
      }

      return blockUserDelete({
        locationDeviceNotifConfigs,
        teamsWithOnlyUser,
        user,
      });
    } finally {
      dispatch({
        type: 'verify:user:delete_FINALLY',
      });
    }
  }

  return {
    deleteUser: processDelete,
  };
};
