import { UserModel } from '../../services';
import { DataStoreAction, initialStoreEntity, NormalEntity } from '../reducer';

export type UserPageState = {
  allUsers: NormalEntity<UserModel>;
  isDataLoaded: boolean;
  isInviteDeleted: boolean;
  resendInviteCtr: number;
};

export const initialUserPageStore: UserPageState = {
  allUsers: initialStoreEntity<UserModel>(),
  isDataLoaded: false,
  isInviteDeleted: false,
  resendInviteCtr: 0,
};

export const userPageReducer = (
  state: UserPageState,
  action: DataStoreAction,
) => {
  // if action is rsend single invite
  // will match "resend-invite:{uuid}_REQUEST"
  // will ignore "resend-invite:all_REQUEST"
  if (/(resend-invite:)[^(all)]/.exec(action.type)) {
    const isRequest = action.type.includes('_REQUEST');
    return {
      ...state,
      resendInviteCtr: isRequest
        ? state.resendInviteCtr + 1
        : state.resendInviteCtr - 1,
    };
  }

  switch (action.type) {
    case 'userPage:allUsers:set':
      return {
        ...state,
        allUsers: {
          all: [...state.allUsers.all, ...action.data.result],
          byUUID: {
            ...state.allUsers.byUUID,
            ...action.data.entities['user'],
          },
        },
      };
    case 'userPage:allUsers:edit':
      return {
        ...state,
        allUsers: {
          all: [...state.allUsers.all],
          byUUID: {
            ...state.allUsers.byUUID,
            ...action.data.entities['user'],
          },
        },
      };
    case 'userPage:allUsers:reset':
      return {
        ...state,
        allUsers: {
          all: action.data.result,
          byUUID: action.data.entities['user'],
        },
      };
    case 'userPage:isDataLoaded:set': {
      return {
        ...state,
        isDataLoaded: action.data,
      };
    }
    case 'userPage:isInviteDeleted:set': {
      return {
        ...state,
        isInviteDeleted: action.data,
      };
    }
  }
  return state;
};
