import {
  grey100,
  PaddedContent,
  PillContent,
  primaryColor,
  thermaH2FontFamily,
} from '@coinspect/ui';
import { isValidNumber, removeDecimalPoint } from '@coinspect/utils';
import React, { Fragment, FunctionComponent, useContext } from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { isNumeric } from 'validator';

import {
  mobileMaxWidthPx,
  mobileMinWidthPx,
  tabletMaxWidthPx,
  tabletMinWidthPx,
} from '../../../constants/responsive-constants';
import {
  AlertNotifConfigPayload,
  useAlertConfigService,
  useFeatures,
  useUserService,
} from '../../../hooks';
import { TempUnits } from '../../../services';
import { StoreContext } from '../../../store';
import { convertToPreferredUnit } from '../../../utils/temperature-util';
import { getNewAlertText } from '../../equipment-alert-config/equipment-alert-list-heading-utils';
import { EquipmentPayloadData } from '../../equipment/equipment-service';
import { FormCloseButton } from '../../forms';
import { UserInfoTooltip } from '../../user-info-tooltip';
import { getEquipmentTriggerValue } from '../alert-config';

const AlertLineChildren = styled(PillContent)`
  grid-area: action;
  align-self: center;
  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${tabletMaxWidthPx}) {
    padding-top: 15px;
  }
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 1px;
    padding: 15px 0 5px 0;
    justify-content: start;
    &&&& {
      justify-content: flex-end;
      .circular.button:last-child {
        margin-left: 0;
      }
      .circular.button {
        margin-left: 15px;
      }
    }
  }
`;

const AlertTitle = styled.div`
  &&&&& {
    font-family: ${thermaH2FontFamily};
    font-size: 16px;
    font-weight: bold;
    grid-area: title;
  }
`;

const AlertDescription = styled.p`
  grid-area: desc;
  color: ${grey100};
  margin-bottom: 1px;
`;

type AlertListHeadingContainerProps = {
  dragEnabled: boolean;
};
const AlertListHeadingContainer = styled(PaddedContent)<
  AlertListHeadingContainerProps
>`
  display: grid;
  align-items: normal;
  grid-template-areas:
    'icon title action'
    'icon desc action';
  grid-template-columns: 7px 1fr 140px;
  position: relative;
  .drag-icon {
    visibility: hidden;
    color: rgb(216, 219, 225);
    font-size: 24px;
    cursor: inherit;
  }
  ${({ dragEnabled }) =>
    dragEnabled &&
    ` &:hover .drag-icon {
    visibility: visible;
  }`}

  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${tabletMaxWidthPx}) {
    grid-template-areas:
      'icon title title'
      'icon desc desc'
      'action action action';
    grid-template-columns: 50px 8fr;
    padding-left: 0px;
  }

  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    align-items: center;
    grid-template-areas:
      'icon title title'
      'desc desc desc'
      'action action action';
    grid-template-columns: 1fr 1fr auto;
    padding: 20px 30px 20px 60px;

    ${AlertDescription} {
      margin-top: 16px;
    }
  }

  @media only screen and (min-width: ${mobileMinWidthPx}) and (max-width: ${mobileMaxWidthPx}) {
    grid-template-columns: 0px 8fr 6fr;
  }
`;

const BoldLabel = styled.span`
  font-weight: bold;
  word-break: break-all;
`;

const ActiveAlertScheduleText = styled.span`
  font-weight: bold;
  display: block;
  font-size: 12px;
  color: ${primaryColor};
`;

const DeviceName = styled.span`
  word-break: break-all;
`;

const DraggableIcon = styled(Icon)`
  position: absolute;
  left: 75px;
  top: 25px;

  @media only screen and (min-width: ${mobileMinWidthPx}) and (max-width: ${mobileMaxWidthPx}) {
    top: 25px;
    left: 30px;
    position: absolute;
  }
`;

export interface AlertListHeadingProps {
  index: number;
  hasActiveAlerts: boolean;
  alertFields: AlertNotifConfigPayload;
  preferredTempUnit?: TempUnits;
  deviceName: string;
  showFormCloseBtn?: boolean;
  onAlertFormCancel?: () => void;
  isRecommendedAlert?: boolean;
  equipmentUUID?: string; // for equipment
  // HACK: alert list heading rerenders, we only want the render caused by changes in the alert config form
  from?: 'equipment-alert-config-form';
  isEditing?: boolean;
  alertConfigUUID?: string;
  formType?: 'add';
  children?: React.ReactNode;
}

type alertHeadingString = JSX.Element | string | boolean;

export const AlertListHeading: FunctionComponent<AlertListHeadingProps> = (
  props,
) => {
  const { isEquipmentEnabled } = useFeatures();
  const { store } = useContext(StoreContext);
  const { equipment } = store.entities;
  const { hydrateUser } = useUserService();
  const { isDragAllowed } = useFeatures();
  const { isReorderInProgress } = useAlertConfigService();
  let multiAlertHeadingText = 'New alert';
  const {
    alertFields,
    children,
    hasActiveAlerts,
    index,
    preferredTempUnit = 'f',
    deviceName,
    showFormCloseBtn,
    onAlertFormCancel,
    equipmentUUID,
    formType,
  } = props;

  const {
    triggerComparator,
    timeframe,
    recipients,
    field: alertType,
  } = alertFields;

  const equipmentData = isEquipmentEnabled
    ? // fallback to alertFields deviceUUID for editing
      equipment.byUUID[(equipmentUUID as string) ?? alertFields.deviceUUID]
    : null;

  const triggerValue = convertToPreferredUnit(
    getEquipmentTriggerValue(
      alertFields,
      equipmentData as EquipmentPayloadData,
    ),
    alertType,
    preferredTempUnit as TempUnits,
  );

  // HACK: To update the New alert/s without causing a rerender
  if (
    isEquipmentEnabled &&
    !props.isEditing &&
    getNewAlertText(
      equipmentData as EquipmentPayloadData,
      props.isEditing as boolean,
      alertFields,
      preferredTempUnit,
    ).length > 2
  ) {
    multiAlertHeadingText = 'New alerts';
  }

  function getRecipientInfo() {
    if (!recipients || recipients.length === 0) {
      return [];
    }

    const recipientList = recipients.reduce(function(
      prevVal: JSX.Element[],
      curVal,
      idx,
    ) {
      const recipient = hydrateUser(curVal);
      if (recipient) {
        prevVal.push(
          <UserInfoTooltip
            user={recipient}
            key={curVal}
            max={recipients.length}
            idx={idx}
          />,
        );
      }
      return prevVal;
    },
    []);

    return recipientList;
  }

  function fragmentProcessor([isTrue, fragment]: [boolean, JSX.Element]):
    | JSX.Element
    | boolean {
    if (!isTrue) {
      return isTrue;
    }
    return fragment;
  }

  // this is a bit nasty. To support the complex gate fields with nested, but consistent logic, it is
  // easiest to understand as a list of tuple that has a boolean check as to whether or not to add the
  // fragment. if we don't add the fragment then we break out and return back.
  function getNonDefaultDescriptionFragments(): Array<alertHeadingString> {
    // Is this AI? - ideal candidate for useMemo afaik
    const resultFragments = [];
    let descriptionFragments: Array<[boolean, JSX.Element]> = [
      [true, <Fragment key={'is'}>is </Fragment>],
      [
        !!triggerComparator,
        <BoldLabel key={'comparator' + triggerComparator}>
          {triggerComparator === '<' ? 'below' : 'above'}&nbsp;
        </BoldLabel>,
      ],
      [
        isNumeric(triggerValue + ''),
        <Fragment key={'triggerValue' + triggerValue}>
          {(() => {
            const ComponentName = (isEquipmentEnabled
              ? `span`
              : `BoldLabel`) as keyof JSX.IntrinsicElements;
            return (
              <>
                {alertType === 'temperature' && (
                  <ComponentName>
                    {triggerValue && removeDecimalPoint(triggerValue)}
                    &deg;{`${preferredTempUnit.toUpperCase()} `}
                  </ComponentName>
                )}
                {alertType === 'humidity' && (
                  <ComponentName>{triggerValue}&#37; humidity </ComponentName>
                )}
              </>
            );
          })()}
          for&nbsp;
        </Fragment>,
      ],
      [
        isValidNumber(timeframe),
        <Fragment key={'timeframe' + timeframe}>
          <BoldLabel>{timeframe} minutes</BoldLabel>, notify{' '}
        </Fragment>,
      ],
      [
        !!recipients && recipients.length > 0,
        <Fragment key={'recipients'}>
          {(getRecipientInfo() as JSX.Element[]).map((ele) => ele)}
        </Fragment>,
      ],
    ];

    if (
      isEquipmentEnabled &&
      (props.from === 'equipment-alert-config-form' || props.isEditing)
    ) {
      const equipmentBasedHeadings = getNewAlertText(
        equipmentData as EquipmentPayloadData,
        props.isEditing as boolean,
        alertFields,
        preferredTempUnit,
        store.entities.alertConfigs,
        props.alertConfigUUID,
      );
      // remove the comparator and trigger value from device based alert config
      descriptionFragments.splice(1, 2);

      // override alert list heading if equipment based
      descriptionFragments = [
        ...descriptionFragments.slice(0, 1),
        ...equipmentBasedHeadings,
        ...descriptionFragments.slice(1),
      ];
    }

    for (const fragment of descriptionFragments) {
      const processed = fragmentProcessor(fragment);
      if (!processed) {
        resultFragments.push('...');
        break;
      }
      resultFragments.push(processed);
    }

    return resultFragments;
  }

  function getDescriptionString(): Array<alertHeadingString> {
    let baseString: Array<alertHeadingString> = [
      `${alertType === 'battery' ? 'If ' : 'When '} `,
    ];
    baseString.push(
      <Fragment key={deviceName}>
        {' '}
        <DeviceName>{deviceName}</DeviceName>{' '}
      </Fragment>,
    );
    if (alertType === 'battery') {
      baseString.push(
        <Fragment key={'defaultLine'}>
          sensor or hub experiences low connectivity or power-related issues,
          notify
        </Fragment>,
      );
      if (!recipients || !recipients.length) {
        baseString.push(<Fragment key={'ellipsis'}>... </Fragment>);
      } else {
        baseString.push(
          <Fragment key={'recipients'}>
            &nbsp;
            {(getRecipientInfo() as JSX.Element[]).map((ele) => ele)}
          </Fragment>,
        );
      }
    } else {
      baseString = baseString.concat(getNonDefaultDescriptionFragments());
    }

    return baseString;
  }

  return (
    <AlertListHeadingContainer
      dragEnabled={isDragAllowed && !isReorderInProgress}
      size="20px 30px 20px 104px"
    >
      {index !== 0 && <DraggableIcon className="icon-braille drag-icon" />}
      <AlertTitle className="header">
        {alertType === 'battery'
          ? 'Connection notifications'
          : isEquipmentEnabled &&
            !props.isEditing &&
            !!equipmentUUID &&
            formType === 'add'
          ? multiAlertHeadingText
          : `Alert ${index}`}
        {showFormCloseBtn && (
          <FormCloseButton
            onClick={onAlertFormCancel}
            style={{ right: '32px' }}
          />
        )}
      </AlertTitle>
      <AlertLineChildren>{children}</AlertLineChildren>
      <AlertDescription>
        {getDescriptionString()}
        {hasActiveAlerts && (
          <ActiveAlertScheduleText>
            Scheduled alerts are active
          </ActiveAlertScheduleText>
        )}
      </AlertDescription>
    </AlertListHeadingContainer>
  );
};
