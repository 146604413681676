import React, { FC } from 'react';
import styled from 'styled-components';

const DEFAULT_SIZE = 60;
const DEFAULT_FONT_SIZE = 20;
const DEFAULT_MARGIN = 0;
const DEFAULT_BG_COLOR = '#e7eef5';
const DEFAULT_BORDER_COLOR = '#d1d7e3';
const DEFAULT_FONT_COLOR = '#757f91';

interface AvatarCircleBaseProps {
  className?: string;
  firstName: string;
  lastName: string;
}

interface AvatarCircleProps extends AvatarCircleBaseProps {
  size?: number;
  fontSize?: number;
  margin?: string;
  bgColor?: string;
  borderColor?: string;
  fontColor?: string;
}

const AvatarCircleBase: FC<AvatarCircleBaseProps> = ({
  className,
  firstName,
  lastName,
}) => {
  const generateInitials = (fName: string, lName: string) => {
    return (
      <>
        {fName ? fName[0].toUpperCase() : '-'}
        {lName ? lName[0].toUpperCase() : '-'}
      </>
    );
  };

  return (
    <div className={className}>{generateInitials(firstName, lastName)}</div>
  );
};

export const AvatarCircle = styled(AvatarCircleBase)<AvatarCircleProps>`
  min-width: ${({ size }) => size || DEFAULT_SIZE}px;
  width: ${({ size }) => size || DEFAULT_SIZE}px;
  height: ${({ size }) => size || DEFAULT_SIZE}px;
  border-radius: 50%;
  font-size: ${({ fontSize }) => fontSize || DEFAULT_FONT_SIZE}px;
  color: ${({ fontColor }) => fontColor || DEFAULT_FONT_COLOR};
  text-align: center;
  background-color: ${({ bgColor }) => bgColor || DEFAULT_BG_COLOR};
  font-weight: bold;
  border: 1px solid ${({ borderColor }) => borderColor || DEFAULT_BORDER_COLOR};
  margin: ${({ margin }) => margin || DEFAULT_MARGIN};
  line-height: ${({ size }) => size || DEFAULT_SIZE}px;
`;
