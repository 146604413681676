import { useContext } from 'react';

import { StoreContext } from '../../../store';

export interface AccordionContextProps {
  panels?: {
    [key: string]: boolean;
  };
  togglePanel?: (panelName: string) => void;
  openPanel?: (panelName: string) => void;
  initPanel?: (panelName: string, initState?: boolean) => void;
  closePanel?: (panelName: string) => void;
}

export const useAccordion = () => {
  const { store, dispatch } = useContext(StoreContext);
  const panels = store.ui.accordion;

  function openPanel(panelName: string): void {
    dispatch({
      data: panelName,
      type: 'accordion:open',
    });
  }

  function closePanel(panelName: string): void {
    dispatch({
      data: panelName,
      type: 'accordion:close',
    });
  }

  function closeAllPanels(): void {
    dispatch({
      type: 'accordion:closeAll',
    });
  }

  function togglePanel(panelName: string): void {
    if (!panels[panelName]) {
      return openPanel(panelName);
    }
    return closePanel(panelName);
  }

  function initPanel(panelName: string, initState = true): void {
    if (panels.hasOwnProperty(panelName)) {
      return; // noop;
    }
    if (initState) {
      return openPanel(panelName);
    }
    return closePanel(panelName);
  }

  return {
    closeAllPanels,
    closePanel,
    initPanel,
    openPanel,
    panels,
    togglePanel,
  };
};
