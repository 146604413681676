import { LocationModel } from '../../../../services';

/**
 *
 * Creates a dropdown data from store.pages.locations.allLocations.byUUID
 * The store data is from useLocationService -> browseLocations
 * which is called from the AccountContext
 */
export const createLocationsDropdown = (
  allLocations: {
    [key: string]: LocationModel;
  },
  allLocationsSelected: boolean,
  options?: { withAllLocations: boolean },
): { text: string; value: string }[] => {
  const { withAllLocations = true } = options || {};
  const locationsDropdownOptions = [];
  const allLocationsText = allLocationsSelected
    ? `Unselect all locations`
    : `Select all locations`;

  const allLocationsValue = allLocationsSelected ? `unselect` : `all`;

  if (withAllLocations) {
    locationsDropdownOptions.push({
      text: allLocationsText,
      value: allLocationsValue,
    });
  }

  for (const locationUUID in allLocations) {
    const locationObj = allLocations[locationUUID];
    locationsDropdownOptions.push({
      text: locationObj.name,
      value: locationObj.uuid,
    });
  }

  return locationsDropdownOptions;
};
