import { API_VERSION } from '../../constants';
import { request } from '../../contexts';

export type InstallationOptions = 'built_in' | 'free_Standing' | 'other';
export type EquipmentTypeOptions = 'freezer' | 'refrigerator' | 'other';

export type EquipmentPayloadData = {
  name: string;
  installation: InstallationOptions;
  equipmentType: EquipmentTypeOptions;
  setPoint?: number;
  meta: {
    serialNumber: string;
    deviceSerialNumber: string;
    temperatureMinThreshold?: number;
    temperatureMaxThreshold: number;
    humidityMinThreshold?: number;
    humidityMaxThreshold?: number;
    status: 'active' | 'disabled';
  };
  locationUUID: string;
  companyUUID: string;
  uuid?: string;
};

export class EquipmentService {
  public static async browseEquipments() {
    const { data } = await request.get(`/api/${API_VERSION}/equipments`);

    return data.data;
  }

  public static async addEquipment(payload: EquipmentPayloadData) {
    const { data } = await request.post(`/api/${API_VERSION}/equipments`, {
      ...payload,
    });

    return data.data;
  }

  public static async editEquipment(
    payload: EquipmentPayloadData,
    uuid: string,
  ) {
    const { data } = await request.put(
      `/api/${API_VERSION}/equipments/${uuid}`,
      {
        ...payload,
      },
    );

    return data.data;
  }

  public static async deleteEquipment(uuid: string) {
    const { data } = await request.delete(
      `/api/${API_VERSION}/equipments/${uuid}`,
    );

    return data.data;
  }

  public static async checkSerialNumber(
    serialNumber: string,
    deviceUUID: string,
  ): Promise<boolean> {
    const { data } = await request.get(
      `/api/${API_VERSION}/devices/check-serial/`,
      {
        params: {
          deviceUUID,
          serialNumber,
        },
      },
    );

    return data.data;
  }
}
