import { aqua100, greyDorian } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

interface StyledDotProps {
  indexState: 'active' | null;
}

const StyledDot = styled('div')`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: ${({ indexState }: StyledDotProps): string =>
    indexState === 'active' ? aqua100 : greyDorian};
  margin: 5px;
  display: inline-block;
`;

interface PageDotsProps {
  numberOfSlides: number;
  currentSlide: number;
}

export const PageDots: FunctionComponent<PageDotsProps> = (props) => {
  const { currentSlide, numberOfSlides } = props;
  return (
    <>
      {Array.from(new Array(numberOfSlides), (_: undefined, idx: number) => {
        return (
          <StyledDot
            key={idx}
            indexState={currentSlide === idx ? 'active' : null}
          />
        );
      })}
    </>
  );
};
