import { FormInput } from '@coinspect/ui';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Radio } from 'semantic-ui-react';
import styled from 'styled-components';

import { EnergyTimeFieldErrorStyle } from '../../../../style/global-energy-styles';
import { EnergyDropdown } from '../../../dropdown';
import { convertTo12Hour, FormattedSchedule } from '../utils/schedule-utils';

const RadioToggle = styled(Radio)`
  &.ui.fitted.toggle.checkbox {
    label:before {
      background-color: #121212;
      border: 2px solid #a1aaaa;
    }

    input:checked {
      ~ label:before {
        background-color: #435e6a !important;
        border: none;
      }

      ~ label:after {
        background-color: #7dd2f6;
      }
    }
  }
`;

const WeekdayLabel = styled.label`
  color: #ffffff !important;
  margin: 0px 85px 1em 20px;
`;

const ClosedLabel = styled.label`
  color: grey;
  padding-left: 25px;
  position: absolute;
  right: 908px;
  margin-right: 14px;
`;

const FormInputRowContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 15px;
`;

const FormInputsContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  position: absolute;
  right: 550px;
  margin-right: 200px;
  padding-top: 20px;
`;

const DashContainer = styled.div`
  padding: 0px 25px;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
`;

const LocationHoursLabelContainer = styled.label`
  font-size: 22px;
  margin: 20px 20px 20px 0px;
`;

const LocationHoursContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

const defaultSchedule = [
  {
    closedHoursString: '',
    isOpen: false,
    name: 'Monday',
    twelveHourEnd: '10:00PM',
    twelveHourStart: '10:00AM',
  },
  {
    closedHoursString: '',
    isOpen: false,
    name: 'Tuesday',
    twelveHourEnd: '10:00PM',
    twelveHourStart: '10:00AM',
  },
  {
    closedHoursString: '',
    isOpen: false,
    name: 'Wednesday',
    twelveHourEnd: '10:00PM',
    twelveHourStart: '10:00AM',
  },
  {
    closedHoursString: '',
    isOpen: false,
    name: 'Thursday',
    twelveHourEnd: '10:00PM',
    twelveHourStart: '10:00AM',
  },
  {
    closedHoursString: '',
    isOpen: false,
    name: 'Friday',
    twelveHourEnd: '10:00PM',
    twelveHourStart: '10:00AM',
  },
  {
    closedHoursString: '',
    isOpen: false,
    name: 'Saturday',
    twelveHourEnd: '10:00PM',
    twelveHourStart: '10:00AM',
  },
  {
    closedHoursString: '',
    isOpen: false,
    name: 'Sunday',
    twelveHourEnd: '10:00PM',
    twelveHourStart: '10:00AM',
  },
];

export const isValidTimeInput = (value: string) => {
  const validStringRegEx = /((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))/;

  if (validStringRegEx.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const EnergyLocationHoursSelector = () => {
  const formMethods = useFormContext<Record<string, string>>();

  const { watch } = formMethods;
  const { location } = useHistory();

  const initialSchedule = location.state.schedule || defaultSchedule;

  const timezones = useMemo(
    () =>
      moment.tz.names().map((tzName) => ({
        text: `(GMT${moment.tz(tzName).format('Z')}) ${tzName} (${moment
          .tz(tzName)
          .zoneAbbr()})`,
        value: tzName,
      })),
    [],
  );

  return (
    <LocationHoursContainer>
      <LocationHoursLabelContainer>
        Select location hours
      </LocationHoursLabelContainer>
      <div>Business occupied hours *</div>
      <Controller
        as={
          <EnergyDropdown
            text={formMethods.watch('scheduleTimeZone')}
            options={timezones.map(({ text, value }) => ({
              key: value,
              text,
              value,
            }))}
            width="300px"
            scrolling
            onChange={(event) => event[1].value}
            search
            placeholder="Select time zone"
          />
        }
        defaultValue={
          initialSchedule[0].timezone
            ? initialSchedule[0].timezone
            : 'Select time zone'
        }
        name="scheduleTimeZone"
        onChange={(event) => event[1].value}
        style={{ margin: '20px 20px 20px 0px', width: '400px' }}
      />
      {initialSchedule?.map((schedule: FormattedSchedule) => {
        return (
          <FormInputRowContainer key={schedule.name}>
            <Controller
              as={<RadioToggle toggle />}
              name={`${schedule.name}Open`}
              onChange={(event) => {
                return event[1].checked;
              }}
              defaultValue={schedule.isOpen}
              style={{ marginBottom: '1em' }}
            />
            <WeekdayLabel>{schedule.name}</WeekdayLabel>

            {watch(`${schedule.name}Open`) ? (
              <FormInputsContainer>
                <FormInput
                  defaultValue={
                    schedule.startTime
                      ? convertTo12Hour(schedule.startTime)
                      : '10:00AM'
                  }
                  name={`${schedule.name}StartTime`}
                  className="energy-time-fields"
                  validation={{
                    isValidTimeInput: (val: string) => isValidTimeInput(val),
                  }}
                  customErrors={{
                    isValidTimeInput:
                      'Please enter the time in HH:MM[AM/PM] format',
                  }}
                  customStyle={EnergyTimeFieldErrorStyle}
                />
                <DashContainer>-</DashContainer>
                <FormInput
                  defaultValue={
                    schedule.endTime
                      ? convertTo12Hour(schedule.endTime)
                      : '10:00PM'
                  }
                  name={`${schedule.name}EndTime`}
                  className="energy-time-fields"
                  validation={{
                    isValidTimeInput: (val: string) => isValidTimeInput(val),
                  }}
                  customErrors={{
                    isValidTimeInput:
                      'Please enter the time in HH:MM[AM/PM] format',
                  }}
                  customStyle={EnergyTimeFieldErrorStyle}
                />
              </FormInputsContainer>
            ) : (
              <ClosedLabel>Closed</ClosedLabel>
            )}
          </FormInputRowContainer>
        );
      })}
    </LocationHoursContainer>
  );
};
