import React, { FunctionComponent } from 'react';

import { getHelloThermaLink } from '../../lib';
import {
  PrivacyAndTermsLink,
  TermsAndConditionsContainer,
} from '../../style/login-signup-styles';

export const TermsAndConditions: FunctionComponent = () => {
  const queryString = new URLSearchParams(location.search);
  const token = queryString.get('token') || '';

  return (
    <TermsAndConditionsContainer>
      By signing {token ? 'up' : 'in'}, I agree to the{' '}
      <PrivacyAndTermsLink
        className="sign-in-footer__privacy-policy"
        target="_blank"
        href={getHelloThermaLink('privacy')}
      >
        Privacy Policy
      </PrivacyAndTermsLink>{' '}
      and{' '}
      <PrivacyAndTermsLink
        className="sign-in-footer__terms-of-service"
        target="_blank"
        href={getHelloThermaLink('terms')}
      >
        Terms of Service
      </PrivacyAndTermsLink>
    </TermsAndConditionsContainer>
  );
};
