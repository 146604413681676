import React, { FunctionComponent } from 'react';
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

import { useAlertConfigService } from '../../../hooks';
import { PANEL_NAME_PREFIX } from '../../devices/device-list-item';
import { useAccordion } from '../../ui/accordion/accordion';
import {
  AlertConfigLineItem,
  AlertConfigLineItemProps,
} from './alert-config-line-item';

export function getStyle(
  style: DraggingStyle | NotDraggingStyle | undefined,
  isDroppingOnClosedList: boolean,
) {
  return isDroppingOnClosedList
    ? {
        ...style,
        transitionDuration: '0.001s',
      }
    : style;
}

export const AlertConfigLineItemDraggable: FunctionComponent<AlertConfigLineItemProps> = (
  props,
) => {
  const { panels } = useAccordion();
  const { isReorderInProgress } = useAlertConfigService();

  // Disabling alert config dragging FE-243
  // const { isDragEnabled, isDragAllowed } = useFeatures();
  const isDragEnabled = false;
  const isDragAllowed = false;

  return props.index === 0 || !isDragEnabled ? (
    <AlertConfigLineItem {...props} />
  ) : (
    <Draggable
      draggableId={props.alertConfig.uuid ?? ''}
      index={props.index}
      isDragDisabled={!isDragAllowed || isReorderInProgress}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getStyle(
            provided.draggableProps.style,
            snapshot.isDropAnimating &&
              !panels?.[`${PANEL_NAME_PREFIX}${snapshot.draggingOver}`],
          )}
        >
          <AlertConfigLineItem {...props} />
        </div>
      )}
    </Draggable>
  );
};
