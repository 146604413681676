import { GrayGraphite, thermaBlue } from '@coinspect/ui';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { EmptyAlertsLog, EmptyGraph } from '../ui';

interface PlaceholderInfo {
  img?: JSX.Element;
  title: string;
  subtext?: string;
  linkText?: string;
  linkClick?: () => void;
}

const EMPTY_PLACEHOLDER = {
  title: '',
};

const graphAll = {
  img: <EmptyGraph />,
  title: 'Welcome to GlacierGrid!',
  subtext:
    'After your sensors are setup, wait about 15 minutes and your data will appear here',
};

const PLACEHOLDER_INFO: { [key: string]: PlaceholderInfo } = {
  graphViewer: { ...graphAll },
  graphAdmin: { ...graphAll, linkText: 'Ready to set up your sensors?' },
  alerts: {
    img: <EmptyAlertsLog />,
    title: "Relax, you're alert free!",
  },
};

interface DashboardPlaceholderProps {
  type: string;
}

const PlaceholderContainer = styled.div`
  text-align: center;
  width: 100%;
  padding: 75px 40px;
`;

const PlaceholderTitle = styled.span`
  margin-top: 30px;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
`;

const PlaceholderSubtitle = styled.span`
  margin-top: 9px;
  line-height: 32px;
  font-size: 18px;
  font-weight: 400;
  color: ${GrayGraphite};
`;

const PlaceholderLink = styled.a`
  font-weight: 700;
  font-size: 16px;
  color: ${thermaBlue};
  line-height: 16px;
  border-bottom: 2px solid ${thermaBlue};
  :hover {
    cursor: pointer;
    color: ${thermaBlue};
  }
`;

export const DashboardPlaceholder: React.FC<DashboardPlaceholderProps> = ({
  type,
}) => {
  const [info, setInfo] = useState<PlaceholderInfo>(EMPTY_PLACEHOLDER);

  useEffect(() => {
    if (type) {
      setInfo({ ...PLACEHOLDER_INFO[type] });
    }
  }, []);

  return (
    <PlaceholderContainer>
      {info.img ?? <></>}
      <br />
      <PlaceholderTitle>{info.title}</PlaceholderTitle>
      {info.subtext && (
        <>
          <br />
          <PlaceholderSubtitle>{info.subtext}</PlaceholderSubtitle>
        </>
      )}
      {info.linkText && info.linkClick && (
        <>
          <br />
          <PlaceholderLink onClick={info.linkClick}>
            {info.linkText}
          </PlaceholderLink>
        </>
      )}
    </PlaceholderContainer>
  );
};

export default DashboardPlaceholder;
