import React from 'react';
import { Divider, DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledDivider = styled(Divider)`
  font-family: 'proxima-nova';
  font-style: normal !important;
  font-weight: normal !important;
  text-transform: none !important;
  color: #8c8ca2 !important;
  font-size: 16px !important;
  line-height: 24px;
  margin: 30px 0 !important;
`;

export const AdvancedDivider: React.FunctionComponent<DividerProps> = (
  props: DividerProps,
) => <StyledDivider {...props} horizontal />;
