import { ButtonContainer, FormDropdown, FormInput } from '@coinspect/ui';
import { debounce } from 'lodash';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Button, DropdownItemProps, Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { CANADA_STATES, USA_STATES } from '../../constants/';
import { useLocationService } from '../../hooks';
import { isFormAcceptable } from '../../lib/isFormAcceptable';
import { Location, LocationModel, SearchedAutocomplete } from '../../services';
import { StoreContext } from '../../store';

export interface LocationFormProps {
  location?: LocationModel;
  onSubmit: (fields: Location) => void;
  onCancel?: () => void;
}

interface LocationSearchProps {
  address?: string;
  latitude?: string;
}

const countryValues = [
  { text: 'USA', value: 'United States' },
  { text: 'Canada', value: 'Canada' },
];

const InputContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 0;
`;

const InputItem = styled.div`
  width: 100%;
  padding-right: 20px;
  &:last-child {
    padding-right: 0;
  }
  &&&& {
    .selection {
      min-height: auto;
      max-height: 2.5em;
    }
    .text {
      font-size: 14px;
      top: -4px;
    }
  }
`;

export const LocationForm: FunctionComponent<LocationFormProps> = (
  props: LocationFormProps,
) => {
  const { store } = useContext(StoreContext);
  const methods = useForm<Location>({
    defaultValues: {
      ...props.location,
    },
    mode: 'onChange',
  });
  const { isQueryInProgress } = store.pages;
  const [latitude, setLatitude] = useState<string | undefined>(
    props.location?.latitude,
  );
  const [longitude, setLongitude] = useState<string | undefined>(
    props.location?.longitude,
  );
  const [country, setCountry] = useState(props.location?.country);
  const [locFormDetails, setLocFormDetails] = useState([]);
  const { searchGeocode } = useLocationService();
  const countryWatch = methods.watch('country');
  const addressWatch = methods.watch('address');

  const [route, setRoute] = useState(
    props.location?.line1?.split(',')[1] ?? '',
  );
  const [streetNumber, setStreetNumber] = useState(
    props.location?.line1?.split(',')[0] ?? '',
  );
  const [geoLocation, setGeoLocation] = useState('');

  useEffect(() => {
    if (!countryWatch || !isAddressLoaded) return;
    methods.setValue('state', '');
  }, [countryWatch]);
  const [isAddressLoaded, setIsAddressLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (!addressWatch) return;
        const geo = await searchGeocode(addressWatch);
        if (!geo[0]) return;
        setGeoLocation(geo[0]);
        setIsAddressLoaded(false);
        setLatitude(
          geo[0].geometry ? geo[0].geometry.location.lat.toString() : '',
        );
        setLongitude(
          geo[0].geometry ? geo[0].geometry.location.lng.toString() : '',
        );
        const addressDetails = geo[0].address_components.map(
          (address: {
            long_name?: string;
            short_name?: string;
            types?: Array<string>;
          }) => {
            const { types } = address;
            const filteredType = types?.filter((t: string) => {
              return (
                (t === 'locality' ||
                  t === 'street_number' ||
                  t === 'route' ||
                  t === 'postal_code' ||
                  t === 'country' ||
                  t === 'administrative_area_level_1') &&
                t
              );
            });

            return (
              filteredType &&
              filteredType?.length > 0 && {
                type: filteredType[0],
                value: address.short_name,
                text: address.long_name,
              }
            );
          },
        );
        setLocFormDetails(addressDetails);
      } finally {
        setIsAddressLoaded(true);
      }
    })();
  }, [addressWatch]);

  useEffect(() => {
    if (!methods.formState.touched.address) return;
    locFormDetails.length &&
      locFormDetails.map(
        (add: { type: string; value: string; text: string }) => {
          if (!add) return;
          switch (add.type) {
            case 'locality':
              methods.setValue('locality', add.value);
              methods.triggerValidation('locality');
              break;
            case 'postal_code':
              methods.setValue('postalCode', add.value);
              methods.triggerValidation('postalCode');
              break;
            case 'country':
              setCountry(add.text);
              methods.setValue('country', add.text);
              methods.triggerValidation('country');
              break;
            case 'administrative_area_level_1':
              methods.setValue('state', add.value);
              methods.triggerValidation('state');
              break;
            case 'route':
              setRoute(add.value);
              break;
            case 'street_number':
              setStreetNumber(add.value);
              break;
          }
        },
      );
  }, [locFormDetails]);

  const submitLocation = methods.handleSubmit(async (data) => {
    const validData = {
      ...data,
      line1: geoLocation ? `${streetNumber},${route}` : '',
      longitude,
      latitude,
    };
    await props.onSubmit(validData);
  });

  return (
    <FormContext {...methods}>
      <Form
        className="location-form"
        size="big"
        onSubmit={submitLocation}
        noValidate
      >
        <FormInput
          className="location-form__name"
          name="name"
          label="Location name:"
          required
          customErrors={{
            required: 'Location name is required.',
          }}
          description="Add a location for each unique address you manage"
        />

        <LocationSearchInput address={props.location?.address} />
        <FormInput
          className="location-form__address_2"
          name="line2"
          label="Address line 2:"
          labeltext="(optional)"
        />
        <InputContainer>
          <InputItem>
            <FormInput className="" name="locality" label="City:" required />
          </InputItem>
          <InputItem>
            <FormDropdown
              className=""
              name="state"
              label="State"
              selection
              search
              fluid
              options={
                country === 'USA' || countryWatch === 'United States'
                  ? USA_STATES
                  : CANADA_STATES
              }
              disabled={!countryWatch}
              required
              selectOnBlur={false}
            />
          </InputItem>
        </InputContainer>
        <InputContainer>
          <InputItem>
            <FormInput
              className=""
              name="postalCode"
              label="ZIP / postal code:"
              required
            />
          </InputItem>
          <InputItem>
            <FormDropdown
              className=""
              name="country"
              label="Country:"
              selection
              search
              fluid
              options={countryValues}
              required
            />
          </InputItem>
        </InputContainer>

        <ButtonContainer right>
          {props.onCancel && (
            <Button
              className="location-form__cancel-button"
              secondary
              type="button"
              content="Cancel"
              onClick={props.onCancel}
              disabled={
                isQueryInProgress['location:add'] ||
                isQueryInProgress['location:edit']
              }
            />
          )}
          <Button
            className="location-form__save-button"
            primary
            type="submit"
            content="Save"
            disabled={
              // methods.formState.dirty ||
              !isFormAcceptable(methods.formState) ||
              isQueryInProgress['location:add'] ||
              isQueryInProgress['location:edit']
            }
            loading={
              isQueryInProgress['location:add'] ||
              isQueryInProgress['location:edit']
            }
          />
        </ButtonContainer>
      </Form>
    </FormContext>
  );
};

const LocationSearchInput = (props: LocationSearchProps) => {
  const { address }: LocationSearchProps = props;
  const { searchAutoComplete } = useLocationService();

  const handleSearch = debounce(async (query: string) => {
    const resultsAutocomplete = await searchAutoComplete(query);
    const joinedResults = [...resultsAutocomplete, query];
    const options =
      joinedResults.length &&
      joinedResults.map((result) => {
        const { description, place_id } = result as SearchedAutocomplete;

        return {
          text: description,
          value: description,
          key: place_id,
        };
      });
    setSearchResults(
      options && options[0].key
        ? options
        : [{ text: query, value: query, key: Date.now() }],
    );
  }, 500);

  const defaultResult = {
    key: address,
    text: address,
    value: address,
  };

  const [searchResults, setSearchResults] = useState<DropdownItemProps[]>([
    defaultResult,
  ]);

  const filteredSearchResults = searchResults.filter((res) => {
    return res.key;
  });

  return (
    <InputContainer>
      <InputItem>
        <FormDropdown
          name="address"
          label="Street Address: "
          selection
          search
          fluid
          options={filteredSearchResults}
          onSearchChange={async ({ currentTarget }) => {
            const { value } = currentTarget as HTMLInputElement;
            handleSearch(value);
          }}
          required
          icon=""
        />
      </InputItem>
    </InputContainer>
  );
};
