import React from 'react';
import { useLocation } from 'react-router-dom';

import { WelcomeContainer } from '../../style/login-signup-styles';

interface SignupWelcomeProp {
  role?: string;
}

export const SignupWelcomeHeader: React.FC<SignupWelcomeProp> = (props) => {
  const { role } = props;
  const location = useLocation();

  const hasInvite = window.location.href;

  const isSignUpInvite =
    location.pathname === '/signup' || hasInvite.includes('invite');

  return (
    <>
      <WelcomeContainer>
        <p>
          {isSignUpInvite && role === 'account_owner'
            ? 'Activate your account'
            : 'Join your team!'}
        </p>
      </WelcomeContainer>
    </>
  );
};
