import { darkViolet80, FilterRailAddressText } from '@coinspect/ui';
import { roundNumber, toCelsius } from '@coinspect/utils';
import React from 'react';
import { TempUnits } from 'src/services';

import {
  convertToPreferredUnit,
  convertToSavableTriggerValue,
} from '../../utils/temperature-util';
import { Text } from '../text';
import { EquipmentFormData } from './equipment-form';
import {
  EquipmentPayloadData,
  EquipmentTypeOptions,
  InstallationOptions,
} from './equipment-service';

export const transformToEquipmentFormData = (
  equipmentPayload: EquipmentPayloadData,
): EquipmentFormData => {
  const { installation, equipmentType, meta, ...rest } = equipmentPayload;
  return {
    ...rest,
    equipmentTypeInstallation: combineEquipmentTypeInstallation(
      equipmentType,
      installation,
    ),
    setPoint: equipmentPayload?.setPoint?.toString(),
    meta: {
      ...meta,
      temperatureMinThreshold: meta?.temperatureMinThreshold?.toString(),
      temperatureMaxThreshold: meta.temperatureMaxThreshold?.toString(),
      humidityMinThreshold: meta?.humidityMinThreshold?.toString(),
      humidityMaxThreshold: meta?.humidityMaxThreshold?.toString(),
    },
  };
};

export const transformToEquipmentPayloadData = (
  equipmentFormData: EquipmentFormData,
  locationUUID: string,
  companyUUID: string,
  preferredTempUnit: TempUnits,
): EquipmentPayloadData => {
  const [equipmentType, installation] = destructureEquipmentTypeInstallation(
    equipmentFormData.equipmentTypeInstallation,
  );
  // remove not needed fields before sending as payload
  const { setPoint, meta, ...rest } = equipmentFormData;
  return {
    ...rest,
    // if equipmentType is other, save installation as other also
    installation:
      equipmentType === 'other'
        ? 'other'
        : (installation as InstallationOptions),
    equipmentType: equipmentType as EquipmentTypeOptions,
    locationUUID,
    companyUUID,
    setPoint: setPoint
      ? preferredTempUnit === 'f'
        ? Number(roundNumber(toCelsius(parseFloat(setPoint)), 2))
        : Number(roundNumber(parseFloat(setPoint), 2))
      : undefined,
    meta: {
      ...meta,
      temperatureMinThreshold:
        convertToSavableTriggerValue(
          parseFloat(meta?.temperatureMinThreshold as string),
          'temperature',
          preferredTempUnit,
        ) ?? undefined,
      temperatureMaxThreshold: convertToSavableTriggerValue(
        parseFloat(meta?.temperatureMaxThreshold as string),
        'temperature',
        preferredTempUnit,
      ) as number,
      humidityMinThreshold: parseFloat(meta?.humidityMinThreshold as string),
      humidityMaxThreshold: parseFloat(meta?.humidityMaxThreshold as string),
    },
  };
};

/**
 *
 * @param equipmentTypeInstallation InstallationOptions+EquipmentTypeOptions
 * @returns [InstallationOptions, EquipmentTypeOptions]
 */
export function destructureEquipmentTypeInstallation(
  equipmentTypeInstallation: string,
): string[] {
  return equipmentTypeInstallation.split('+');
}

export function combineEquipmentTypeInstallation(
  equipmentType: EquipmentTypeOptions,
  installation: InstallationOptions,
): string {
  if (equipmentType === 'other') {
    return equipmentType;
  }
  return `${equipmentType}+${installation}`;
}

const headerStyle = { display: 'flex', columnGap: '5px' };

export function displayEquipmentMainHeader(
  equipmentData: EquipmentPayloadData,
) {
  const mainHeader = (
    <div
      style={{
        ...headerStyle,
        columnGap: '8px',
        fontFamily: 'proxima-nova',
        fontSize: '16px',
        color: darkViolet80,
      }}
    >
      <span style={{ fontWeight: 'bold' }}>{equipmentData.name}</span>
      <span>{` | `}</span>
      <span style={{}}>{equipmentData.meta.deviceSerialNumber}</span>
    </div>
  );
  return mainHeader;
}

export function displayEquipmentSubHeader(
  equipmentData: EquipmentPayloadData,
  preferredTempUnit: TempUnits,
) {
  const styledTempUnit = (
    <span
      style={{ textTransform: 'uppercase' }}
    >{`˚${preferredTempUnit}`}</span>
  );
  const hasSetPoint = isValidNumberOrEmpty(equipmentData.setPoint);
  const setPoint = convertToPreferredUnit(
    Number(equipmentData.setPoint),
    'temperature',
    preferredTempUnit,
  );

  const hasMinTemp = isValidNumberOrEmpty(
    equipmentData.meta.temperatureMinThreshold,
  );
  const minTemp = convertToPreferredUnit(
    Number(equipmentData.meta.temperatureMinThreshold),
    'temperature',
    preferredTempUnit,
  );
  const maxTemp = convertToPreferredUnit(
    Number(equipmentData.meta.temperatureMaxThreshold),
    'temperature',
    preferredTempUnit,
  );

  const hasMinHumid = isValidNumberOrEmpty(
    equipmentData.meta.humidityMinThreshold,
  );
  const minHumid = equipmentData.meta.humidityMinThreshold;
  const hasMaxHumid = isValidNumberOrEmpty(
    equipmentData.meta.humidityMaxThreshold,
  );
  const maxHumid = equipmentData.meta.humidityMaxThreshold;

  const subHeader = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        color: FilterRailAddressText,
      }}
    >
      <div style={headerStyle}>
        {!!hasMinTemp && (
          <>
            <Text as="span">
              {`Min ${minTemp}`}
              {styledTempUnit}
            </Text>
            <Text as="span">•</Text>
          </>
        )}
        {!!hasSetPoint && (
          <>
            <Text as="span">
              {`Set point ${setPoint}`}
              {styledTempUnit}
            </Text>
            <Text as="span">•</Text>
          </>
        )}
        <>
          <Text as="span">
            {`Max ${maxTemp}`}
            {styledTempUnit}
          </Text>
        </>
      </div>
      <div style={headerStyle}>
        {!!hasMinHumid && <Text as="span">{`Min ${minHumid}% humidity`}</Text>}
        {!!hasMinHumid && !!hasMaxHumid && <Text as="span">•</Text>}
        {!!hasMaxHumid && <Text as="span">{`Max ${maxHumid}% humidity`}</Text>}
      </div>
    </div>
  );

  return subHeader;
}

export function isValidNumberOrEmpty(number: number | undefined) {
  return !isNaN((number ?? undefined) as number);
}
