import { FormDropdown, thermaBlue } from '@coinspect/ui';
import React, { useEffect, useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import { Invite } from '../../../services';
import StandardModalBody from '../../modals/standard-modal-body';
import { FormToggle } from '../../toggle';
import { EmailField } from './email-field';
import { InviteModes, InviteOptions, ROLE_OPTIONS } from './index';
import { PhoneNumberField } from './phone-number-field';
import { TeamSelector } from './team-selector';

interface SingleInviteForm {
  onSubmit: (invite: Invite[]) => Promise<void>;
  onCancel: () => void;
  showWhen: boolean;
  onChange?: React.Dispatch<InviteModes>;
  setSingleInviteMode: (val: boolean) => void;
}

const InviteModeToggleContainer = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  a {
    cursor: pointer;
    color: ${thermaBlue};
    text-decoration-line: underline;
  }
`;

export const SingleInviteForm: React.FC<SingleInviteForm> = (props) => {
  const { onCancel, onSubmit, showWhen, onChange, setSingleInviteMode } = props;

  if (!showWhen) return null;

  const defaultValues = {
    email: '',
    phoneNumber: '',
    role: 'admin',
    type: 'email',
    teams: [],
  };

  const invisibleLabel = 'ㅤ'; // set label with invisible character

  interface InviteValuesInterface {
    email?: string;
    phoneNumber?: string;
    countryCode?: string;
  }

  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<Invite>({
    defaultValues,
    mode: 'onChange',
  });
  const { handleSubmit, watch, formState, setValue } = methods;
  const inviteType = watch('type');
  const email = watch('email');
  const phoneNumber = watch('phoneNumber');
  const countryCode = watch('countryCode');

  const submitHandler = async (invite: Invite) => {
    const { email: inviteEmail } = invite;
    setIsLoading(true);
    try {
      await onSubmit([{ ...invite, email: inviteEmail?.toLowerCase() }]);
    } catch (e) {
      setIsLoading(false);
    }
  };
  const [inviteValues, setInviteValues] = useState<InviteValuesInterface>({});

  const setInviteValue = (field: 'countryCode' | 'phoneNumber' | 'email') => {
    if (inviteValues[field]) {
      setValue(field, inviteValues[field], true);
    }
  };

  useEffect(() => {
    onChange?.(inviteType as InviteModes);

    if (inviteType === 'sms') {
      setInviteValue('countryCode');
      setInviteValue('phoneNumber');
    } else {
      setInviteValue('email');
    }
  }, [inviteType]);

  useEffect(() => {
    setInviteValues({
      email: email || inviteValues.email,
      phoneNumber: phoneNumber || inviteValues.phoneNumber,
      countryCode: countryCode || inviteValues.countryCode,
    });
  }, [email, phoneNumber, countryCode]);

  return (
    <FormContext {...methods}>
      <Form>
        <StandardModalBody>
          <StandardModalBody.Content>
            <Header>Send invite via</Header>
            <FormToggle
              className="inline"
              name="type"
              options={InviteOptions}
            />

            <br />

            <CollapseFields>
              {inviteType === 'sms' ? (
                <PhoneNumberField />
              ) : (
                <EmailField name="email" />
              )}

              <FormDropdown
                name="role"
                label={invisibleLabel}
                options={ROLE_OPTIONS}
                selection
                fluid
                search
                className="role-selector"
                labelStyle={{
                  color: 'red',
                  height: 'calc(19.6px)',
                }}
              />
            </CollapseFields>
          </StandardModalBody.Content>
          <StandardModalBody.Actions
            primaryButton={{
              content: 'Invite',
              disabled: !formState.isValid || isLoading,
              onClick: handleSubmit(submitHandler),
            }}
            secondaryButton={{
              content: 'Cancel',
              onClick: onCancel,
              disabled: isLoading,
            }}
          >
            <StandardModalBody.Actions.BeforeButtonArea>
              <TeamSelector />
            </StandardModalBody.Actions.BeforeButtonArea>
            <StandardModalBody.Actions.AfterButtonArea>
              <InviteModeToggleContainer>
                <a onClick={() => setSingleInviteMode(false)}>
                  Inviting more than one user?
                </a>
              </InviteModeToggleContainer>
            </StandardModalBody.Actions.AfterButtonArea>
          </StandardModalBody.Actions>
        </StandardModalBody>
      </Form>
    </FormContext>
  );
};

const Header = styled.span`
  font-weight: bold;
  margin-bottom: 8px;
`;

const CollapseFields = styled.div`
  display: flex;
  .field {
    flex-grow: 1;
    :focus-within,
    &.error {
      z-index: 1;
    }
    &:not(:only-child):first-child {
      input:not(:focus) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    &:not(:only-child):last-child {
      margin-left: -2px;
      input:not(:focus),
      .ui.dropdown:not(.active) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &:not(:only-child):not(:first-child):not(:last-child) {
      margin-left: -2px;
      input:not(:focus) {
        border-radius: 0;
      }
    }

    // extras
    .role-selector {
      min-width: 100px;
    }
    .cleaveWrapper {
      margin-bottom: unset;
    }
    .cleaveSiblingWrapper {
      line-height: 18.6px;
    }
    .input-error {
      margin-top: unset;
      margin-bottom: unset;
    }
  }
`;
