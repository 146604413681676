/* globals PositionError, Position, PositionOptions */

import { useEffect, useState } from 'react';

export const useGeolocation = (
  options?: PositionOptions,
  watchEnable = false,
) => {
  const [error, setError] = useState<PositionError | null>(null);
  const [position, setPosition] = useState<Position | null>(null);

  useEffect(() => {
    try {
      const { geolocation } = navigator || {};

      if (!geolocation) {
        setError({
          message: 'Geolocation is not supported',
        } as PositionError);
        return;
      }

      geolocation.getCurrentPosition(setPosition, setError, options);

      const watchId = !watchEnable
        ? null
        : geolocation.watchPosition(setPosition, setError, options);

      return () => {
        if (watchId) {
          geolocation.clearWatch(watchId);
        }
      };
    } catch (err) {
      setError(err.message);
    }
  }, [options, watchEnable]);

  return {
    error,
    position,
  };
};

export default useGeolocation;
