import { API_VERSION } from '../../constants';
import { request } from '../../contexts';
import { formatClimateUnit } from './utils';

export type ClimateImpactData = {
  weightProtected: string;
  co2ePrevented: string;
  distanceDriven: string;
  coalBurned: string;
  homeEnergyUsed: string;
};

export type ClimateImpactDataReturn = {
  weightProtected: number;
  co2ePrevented: number;
  distanceDriven: number;
  coalBurned: number;
  homeEnergyUsed: number;
};

export class ClimateService {
  public static async browseClimateImpact(
    energyLocationUUID?: string,
  ): Promise<ClimateImpactDataReturn> {
    let url = `/api/${API_VERSION}/climate/impact`;
    if (energyLocationUUID) {
      url += `/company?locationUUID=${energyLocationUUID}`;
    }
    const { data: result } = await request.get(url);

    return result.data;
  }
}

export function formatClimateImpact(
  result: ClimateImpactDataReturn,
  shortHand = false,
): ClimateImpactData {
  const data = {
    weightProtected: formatClimateUnit(
      result.weightProtected,
      'pound',
      shortHand ? 'short' : 'long',
    ),
    co2ePrevented: formatClimateUnit(result.co2ePrevented, 'kilogram')
      .split(' kg')
      .join(' '),
    distanceDriven: formatClimateUnit(result.distanceDriven, 'mile'),
    coalBurned: formatClimateUnit(result.coalBurned, 'pound'),
    homeEnergyUsed: formatClimateUnit(result.homeEnergyUsed, 'kilogram')
      .split(' kg')
      .join(' '),
  };

  return data;
}
