import { PaginationParams } from '@coinspect/utils';
import mixpanel from 'mixpanel-browser';
import { normalize, schema } from 'normalizr';
import { useContext } from 'react';

import { AccountContext, UserProfile } from '../contexts';
import {
  extendParams,
  UserModel,
  UserProfileEdit,
  UserService,
} from '../services';
import { StoreContext } from '../store';
import { useUserRole } from './use-user-role';

export interface UserModelMeta {
  limit: number;
  page: number;
  total: number;
}

const UserSchema = new schema.Entity(
  'user',
  {},
  {
    idAttribute: 'uuid',
  },
);

export const UserListSchema = [UserSchema];

export function useUserService() {
  const { user } = useContext(AccountContext);
  const { store, dispatch } = useContext(StoreContext);
  const { allUsers } = store.pages.users;
  const { displayRole } = useUserRole();

  async function normalizeAndUpdateStoreUsers(
    userModels: UserModel[],
    type: string,
  ) {
    const normalized = normalize(userModels, UserListSchema);
    dispatch({ data: normalized, type });
  }

  async function browseAllUsers(params?: PaginationParams): Promise<void> {
    const userModels = await UserService.browseAllUsers(params);
    normalizeAndUpdateStoreUsers(userModels.data, 'userPage:allUsers:reset');
  }

  async function resetUsers(): Promise<void> {
    const self = await UserService.self();
    normalizeAndUpdateStoreUsers([self], 'user:userPage:hard_reset');
  }

  async function getSelfUserModel(): Promise<UserModel> {
    return UserService.self();
  }

  async function populateUserPage(
    options?: PaginationParams,
  ): Promise<{
    data: UserModel[];
    metadata: UserModelMeta;
  }> {
    dispatch({ type: 'user:browse:paginated_REQUEST' });

    try {
      const userData = await UserService.browseAllUsers(
        extendParams(user as UserProfile, options),
      );

      const data = userData.data;

      normalizeAndUpdateStoreUsers(data, 'user:set');

      return userData;
    } finally {
      dispatch({ type: 'user:browse:paginated_FINALLY' });
    }
  }

  async function editSelf(payload: UserModel): Promise<UserProfileEdit> {
    dispatch({
      type: 'user:edit_REQUEST',
    });
    try {
      const userModels = await UserService.editSelf(payload);
      if (userModels.fail) {
        return userModels;
      }
      normalizeAndUpdateStoreUsers([userModels], 'user:edit');
      return userModels;
    } finally {
      dispatch({
        type: 'user:edit_FINALLY',
      });
    }
  }

  async function setOptOutDate(): Promise<UserProfileEdit> {
    try {
      const userModels = await UserService.setOptOutDate();
      if (userModels.fail) {
        return userModels;
      }
      return userModels;
    } catch (error) {
      throw Error(`${error}`);
    }
  }

  async function editUsers(
    userUUID: string,
    payload: UserModel,
  ): Promise<UserProfileEdit> {
    dispatch({
      type: 'user:edit_REQUEST',
    });
    try {
      const userModels = await UserService.edit(userUUID, payload);
      if (userModels.fail) {
        return userModels;
      }
      normalizeAndUpdateStoreUsers([userModels], 'user:edit');
      return userModels;
    } finally {
      dispatch({
        type: 'user:edit_FINALLY',
      });
    }
  }

  async function deleteUser(userUUID: string) {
    dispatch({ type: 'user:delete_REQUEST' });
    try {
      const userModels = await UserService.delete(userUUID);
      mixpanel.track('User Deleted', {
        user_name: `${userModels.firstName} ${userModels.lastName}`,
        user_email: userModels.email,
        user_role: displayRole(userModels.role),
      });
      dispatch({
        data: userUUID,
        type: 'user:delete',
      });
      return userModels;
    } finally {
      dispatch({ type: 'user:delete_FINALLY' });
    }
  }

  function hydrateUser(userUUID: string): UserModel {
    return allUsers.byUUID[userUUID];
  }

  function updatePassword(
    email: string,
    password: string,
  ): Promise<UserProfile> {
    return UserService.updatePassword(email, password);
  }

  function updatePasswordChangedate(
    uid = '',
    email = '',
  ): Promise<UserProfile> {
    return UserService.updatePasswordChangeDate(uid, email);
  }

  async function getUserInfo(userUUID: string): Promise<UserModel> {
    return store.pages.users.allUsers.byUUID[userUUID];
  }

  return {
    browseAllUsers,
    deleteUser,
    editSelf,
    editUsers,
    hydrateUser,
    updatePassword,
    updatePasswordChangedate,
    normalizeAndUpdateStoreUsers,
    populateUserPage,
    resetUsers,
    getSelfUserModel,
    setOptOutDate,
    getUserInfo,
  };
}
