import { FormPhoneNumber, unFormatNumber } from '@coinspect/ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { isNumeric } from 'validator';

const phoneNumberValidator = (phoneNumber: string) => {
  // TODO: to be used for validation
  // const countryCode = methods.getValues(
  //   `invites[${idx}].countryCode`,
  // );

  const unformatted = unFormatNumber(phoneNumber);

  if (!isNumeric(unformatted) || unformatted.length !== 10) {
    return false;
  }
  return true;
};

export const PhoneNumberField: React.FC = () => {
  const { watch } = useFormContext();
  const phoneNumber = watch('phoneNumber');
  const countryCode = watch('countryCode');

  const formVal = `${countryCode}-${(phoneNumber || '').replace(
    /[^0-9]/gi,
    '',
  )}`;

  return (
    <FormPhoneNumber
      name="phoneNumber"
      value={formVal}
      required
      formLabel="Phone number:"
      customErrors={{
        phoneNumber: 'Invalid phone number format.',
        required: 'Phone number is required.',
      }}
      countryCodeName="countryCode"
      validation={{
        phoneNumber: phoneNumberValidator,
      }}
    />
  );
};
