import { formatNumber } from '@coinspect/ui';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import { useUserRole } from '../../hooks';

interface UserCardProps {
  withContactInfo?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string | null;
  role: 'user' | 'admin' | 'account_owner' | undefined | string;
}

const UserCardComponent: FC<UserCardProps> = (props) => {
  const {
    withContactInfo,
    firstName = '',
    lastName = '',
    email = '',
    phoneNumber,
    role,
  } = props;
  const history = useHistory();
  const { displayRole } = useUserRole();

  return (
    <Header as="h2" className="ellipse-text">
      <span id="user-profile__name" className="capitalize">
        {firstName} {lastName}
      </span>
      {history.location.pathname !== '/registration' && (
        <Header.Subheader id="user-profile__role">
          {displayRole(role)}
        </Header.Subheader>
      )}

      {withContactInfo && (
        <>
          <Header.Subheader id="user-profile__email">{email}</Header.Subheader>
          <Header.Subheader id="user-profile__phone">
            {formatNumber(phoneNumber || '', true)}
          </Header.Subheader>
        </>
      )}
    </Header>
  );
};

export const UserCard = styled(UserCardComponent)`
  margin-bottom: 30px;
`;
