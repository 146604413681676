import React from 'react';

import { StoreContext } from '../store';
import { BannerAlert, BannerAlertActions } from '../store/reducers';

export function useBannerAlerts() {
  const { dispatch } = React.useContext(StoreContext);

  const append = (alert: BannerAlert) => {
    dispatch({
      data: alert,
      type: BannerAlertActions.append,
    });
  };

  const dismiss = (index: number) => {
    dispatch({
      data: index,
      type: BannerAlertActions.dismiss,
    });
  };

  const set = (alerts: BannerAlert[] = []) => {
    dispatch({
      type: BannerAlertActions.set,
      data: alerts,
    });
  };

  return {
    append,
    dismiss,
    set,
  };
}
