import { stoneBlue } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import { Popup, PopupProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { isTabletAndBelow } from '../../constants';

type ToolTipProps = {
  thin?: boolean;
  headerClass?: string;
  contentClass?: string;
} & PopupProps;

const BaseTooltip = styled(Popup)`
  &&& {
    z-index: 1000;

    &.thin {
      padding: 5px 10px;
    }

    && .header {
      color: ${stoneBlue};
      font-size: 12px;
    }

    .content {
      font-size: 12px;
      color: ${stoneBlue};
    }
  }
`;

export const Tooltip: FunctionComponent<ToolTipProps> = (props) => {
  const {
    children,
    header,
    className,
    headerClass,
    contentClass,
    thin = true,
    content,
    ...rest
  } = props;

  return (
    <BaseTooltip
      position="bottom left"
      className={`${className} ${thin && 'thin'}`}
      basic
      hideOnScroll
      disabled={isTabletAndBelow()}
      on="hover"
      {...rest}
    >
      {header && <Popup.Header className={headerClass}>{header}</Popup.Header>}
      {content && (
        <Popup.Content className={contentClass}>{content}</Popup.Content>
      )}
      {children}
    </BaseTooltip>
  );
};
