import { thermaBlue, thermaOffWhite } from '@coinspect/ui';
import React, { useContext } from 'react';

import { useInviteService } from '../../hooks';
import { InviteModel } from '../../services';
import { StoreContext } from '../../store';

export const ResendInvite: React.FC<{ invite: InviteModel }> = (props) => {
  const { store } = useContext(StoreContext);
  const { resendInvite } = useInviteService();
  const { uuid } = props.invite;
  const isQueryInProgress =
    store.pages.isQueryInProgress[`resend-invite:${uuid}`] ||
    store.pages.isQueryInProgress[`resend-invite:all`];

  return (
    <a
      className="resend-invite"
      style={{
        fontWeight: 'bold',
        color: isQueryInProgress ? thermaOffWhite : thermaBlue,
        cursor: isQueryInProgress ? 'not-allowed' : 'pointer',
        transition: 'color 0.5s ease',
      }}
      onClick={() => !isQueryInProgress && resendInvite(uuid)}
    >
      {' '}
      {isQueryInProgress ? 'Sending' : 'Resend'}
    </a>
  );
};
