import { API_VERSION } from '../../constants';
import { request } from '../../contexts';
import { SignalTestingStatus } from '../modals';

export class EquipmentDeviceService {
  public static async addEquipmentDevice(payload: {
    serialNumber: string;
    locationUUID: string;
    name: string;
    equipmentUUID: string;
    testingMode: boolean;
    testingStatus: SignalTestingStatus;
  }) {
    const { data } = await request.post(`/api/${API_VERSION}/devices`, {
      ...payload,
    });

    return data.data;
  }
}
