import { LocationModel } from '../../services';
import { DataStoreAction, initialStoreEntity, NormalEntity } from '../reducer';

export type LocationsState = {
  allLocations: NormalEntity<LocationModel>;
};

export const initialLocationsStore = {
  allLocations: initialStoreEntity<LocationModel>(),
};

export const locationsReducer = (
  state: LocationsState,
  action: DataStoreAction,
) => {
  switch (action.type) {
    case 'allLocations:set':
      return {
        ...state,
        allLocations: {
          all: [...new Set(state.allLocations.all.concat(action.data.result))],
          byUUID: {
            ...state.allLocations.byUUID,
            ...action.data.entities['location'],
          },
        },
      };
  }
  return state;
};
