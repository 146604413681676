import { ReactNode } from 'react';

import { JoyrideFlowFunctions } from '../../../joyride-steps';
import { JoyrideFlowState, JoyrideFlowsType } from '../../../store/reducers';

export const getCompletedSteps = (
  activeTour: JoyrideFlowsType,
  joyrideFlows: JoyrideFlowState,
): ReactNode[] => {
  const currentStepIndex = joyrideFlows[activeTour].stepIndex;
  const currentSteps = JoyrideFlowFunctions[activeTour]();
  const stepsCompleted = activeTour
    ? currentSteps
        .filter((step, idx) => idx < currentStepIndex)
        .map((step) => step.title)
    : [];

  return stepsCompleted;
};
