import { white100 } from '@coinspect/ui';
import React from 'react';
import { Step } from 'react-joyride';
import styled from 'styled-components';

import { sharedStepOptions } from '../index';

const BestPracticesLink = styled.a`
  color: ${white100};
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    color: ${white100};
  }
`;

export const alertConfigTutorialFlow = (): Step[] => {
  return [
    {
      ...sharedStepOptions,
      placement: 'right',
      target: '#alert-one-container',
      title: 'Save your Alert 1',
      content:
        'Alert 1 is a standard alert for all sensors that will notify you when your sensor is offline. Be sure to confirm the recipient is correct.',
    },
    {
      ...sharedStepOptions,
      placement: 'left',
      target: '#missing-alerts-device',
      title: 'Add an alert',
      content: (
        <>
          Alerts keep you in the know while you’re away. Check out our&nbsp;
          <BestPracticesLink
            href="https://www.glaciergrid.com/resource/best-practices-for-alerts/"
            target="_blank"
            rel="noopener noreferrer"
          >
            best practices guide
          </BestPracticesLink>
          &nbsp;for tips or enable smart alerts to let GlacierGrid do the work.
        </>
      ),
      disableOverlayClose: true,
    },
    {
      ...sharedStepOptions,
      placement: 'right',
      target: '#alert-two-container',
      title: 'Configure and save your alert',
      content:
        'Choose your thresholds, who to notify, and how to notify them. Additionally you have the option to schedule alerts if you’d like muted times.',
      disableOverlayClose: true,
    },
  ];
};
