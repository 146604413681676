import { normalize, schema } from 'normalizr';
import { useContext, useState } from 'react';

import { LocalStorageContext } from '../contexts';
import {
  CreateReportConfig,
  ReportConfigSchedule,
  ReportConfigService,
} from '../services';
import { StoreContext } from '../store';

export const ADDED_REPORT_UUID = 'addReportConfig';

export interface QueryProps {
  page?: number | undefined;
  perPage?: number | undefined;
  keyword?: string | undefined;
  sortOrder?: 'asc' | 'desc';
}

export interface PaginationMetadata {
  recordCount: number;
  totalPage: number;
  page: number;
  previousPage?: number | undefined;
  nextPage?: number | undefined;
  lastPage?: number | undefined;
  firstPage?: number | undefined;
}

const ReportConfigSchema = new schema.Entity(
  'reportConfig',
  {},
  {
    idAttribute: 'uuid',
  },
);

const ReportConfigListSchema = [ReportConfigSchema];

export function useReportConfigService() {
  const { dispatch } = useContext(StoreContext);
  const { setItem } = useContext(LocalStorageContext);
  const [isBrowseFetching, setIsBrowseFetching] = useState(false);
  const [paginationMetadata, setPaginationMetadata] = useState<
    PaginationMetadata
  >({ recordCount: 0, totalPage: 0, page: 1 });
  const resetDateRange = () => {
    dispatch({
      data: {
        start: '',
        end: '',
        labelIndex: -1,
      },
      type: 'reportForm:dateRange:set',
    });
  };

  async function browseReportConfigs(query: QueryProps, actionType = 'reset') {
    try {
      setIsBrowseFetching(true);

      const [reportConfigs, metadata] = await ReportConfigService.browse(query);
      const normalized = normalize(reportConfigs, ReportConfigListSchema);

      dispatch({
        data: normalized,
        type: `reportConfig:${actionType}`,
      });

      setPaginationMetadata({
        ...paginationMetadata,
        ...metadata.pagination,
      });

      return [reportConfigs, metadata];
    } finally {
      setIsBrowseFetching(false);
    }
  }

  async function addReportConfig(
    payload: CreateReportConfig,
    actionType = 'add',
  ) {
    dispatch({ type: 'reportConfig:add_REQUEST' });

    try {
      const reportConfig = await ReportConfigService.add(payload);
      const normalized = normalize([reportConfig], ReportConfigListSchema);
      setItem(ADDED_REPORT_UUID, reportConfig.uuid);
      dispatch({
        data: normalized,
        type: `reportConfig:${actionType}`,
      });

      resetDateRange();

      return reportConfig;
    } finally {
      dispatch({ type: 'reportConfig:add_FINALLY' });
    }
  }

  async function editReportConfig(
    uuid: string,
    payload: CreateReportConfig,
  ): Promise<void> {
    dispatch({ type: 'reportConfig:edit_REQUEST' });

    try {
      const reportConfig = await ReportConfigService.edit(uuid, payload);
      const normalized = normalize([reportConfig], ReportConfigListSchema);

      dispatch({
        data: normalized,
        type: 'reportConfig:edit',
      });

      resetDateRange();

      return reportConfig;
    } finally {
      dispatch({ type: 'reportConfig:edit_FINALLY' });
    }
  }

  async function deleteReportConfig(uuid: string): Promise<unknown> {
    dispatch({ type: 'reportConfig:delete_REQUEST' });

    try {
      const data = await ReportConfigService.delete(uuid);
      dispatch({
        data: uuid,
        type: 'reportConfig:delete',
      });

      return data;
    } catch (err) {
      dispatch({
        data: uuid,
        type: 'reportConfig:delete',
      });

      return false;
    } finally {
      dispatch({ type: 'reportConfig:delete_FINALLY' });
    }
  }

  async function getLatestReportHistory(uuid: string) {
    const data = await ReportConfigService.latestReportHistory(uuid);

    if (data) {
      dispatch({
        data,
        type: 'reportConfig:history:set',
      });
    }

    return data;
  }

  function getScheduleDateRange(
    startDate: string,
    endDate: string,
    reportSchedule: ReportConfigSchedule,
  ) {
    const date2 = new Date(endDate);
    const date1 = new Date(startDate);
    const diffTime = Math.abs(date2.valueOf() - date1.valueOf());
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));

    switch (diffHours) {
      case 12:
        return 'Last 12 hours';
      case 24:
        return 'Last 24 hours';
      case 168:
        return 'Last 7 days';
      case 336:
        return 'Last 14 days';
      case 720:
        return reportSchedule === 'monthly' ? 'Last month' : 'Last 30 days';
      case 1440:
        return 'Last 2 months';
      case 4320:
        return 'Last 6 months';
      case 8760:
        return 'Last year';
      default:
        return 'Last 24 hours';
    }
  }

  return {
    browseReportConfigs,
    addReportConfig,
    editReportConfig,
    deleteReportConfig,
    getLatestReportHistory,
    resetDateRange,
    isBrowseFetching,
    paginationMetadata,
    setPaginationMetadata,
    getScheduleDateRange,
  };
}
