import React, { FunctionComponent, useContext } from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { StandardModalContext } from '../../contexts';
import { useInviteService } from '../../hooks';
import { Invite, InviteModel } from '../../services';
import { StoreContext } from '../../store';
import { InviteFormModeToggler } from '../forms/invite-form';

const ButtonWrapper = styled.span`
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

interface InviteListProps {
  invites: InviteModel[];
}

export const InvitationModal: FunctionComponent<InviteListProps> = (props) => {
  const { store } = useContext(StoreContext);
  const { hideModal, openModal } = useContext(StandardModalContext);
  const { addInvite, resendAllPending } = useInviteService();

  const isResendAll = store.pages.isQueryInProgress[`resend-invite:all`];
  const isRsendSingle = store.pages.users.resendInviteCtr > 0;

  const submitInvite = async (fields: Invite[]) => {
    try {
      await addInvite(fields);
      hideModal();
    } catch (err) {
      throw new Error(err);
    }
  };
  return (
    <ButtonWrapper>
      <Button
        onClick={async () => {
          await resendAllPending();
        }}
        disabled={props.invites?.length <= 0 || isResendAll || isRsendSingle}
        loading={isResendAll}
        secondary
        content="Resend all pending"
        id="resend-invite-users-btn"
        style={{ margin: '5px' }}
      />

      <Button
        onClick={() =>
          openModal({
            allContent: (
              <InviteFormModeToggler
                onSubmit={submitInvite}
                onCancel={hideModal}
              />
            ),
            header: 'Invite user',
          })
        }
        primary
        content="Invite users"
        id="invite-users-btn"
        style={{ margin: '5px' }}
      />
    </ButtonWrapper>
  );
};
