/* eslint-disable @typescript-eslint/no-explicit-any */
import { isValidNumber, toFahrenheit } from '@coinspect/utils';
import moment from 'moment';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  CartesianGrid,
  Label as GraphLabel,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Divider, Icon, Segment } from 'semantic-ui-react';
import { Media } from 'src/contexts/app-media';
import { GraphDataPoint } from 'src/store/reducers';
import styled from 'styled-components';

import { GraphHeader } from '../';
import { DashboardLoader } from '../../../components/dashboard-loader';
import {
  mobileMaxWidth,
  mobileMaxWidthPx,
  tabletMaxWidth,
} from '../../../constants';
import { AccountContext, UserProfile } from '../../../contexts';
import { StoreContext } from '../../../store';
import { Tooltip as PopupTooltip } from '../../tooltip';
import DashboardPlaceholder from '../dashboard-placeholder';
import { GraphToggle } from '../graph-toggle';
import { connectNullData } from './connect-null-data';
import { CustomActiveDot, GraphTooltip, XAxisTick, YAxisTick } from './utils';
interface DataPoint {
  x: number;
  y: number | null;
  time: string;
  mean: number;
  deviceUUID: string;
}

const formatDate = (
  isoDate: string,
  all?: boolean,
  customDateFormat?: string,
) => {
  const format = all ? 'MM/DD/YYYY' : 'MM/DD';
  return moment(isoDate, 'YYYY-M-DTH:mm:ssZ').format(
    customDateFormat ?? format,
  );
};

interface XAxisProp {
  values: string[];
  labels: { [key: number]: string };
  allLabels?: { [key: number]: string };
}

interface YAxisProp {
  labels: (string | number)[];
}

interface GraphData {
  [deviceUUID: string]: DataPoint[];
}

interface TemperatureGraphProps {
  xAxis: XAxisProp;
  yAxis: YAxisProp;
  graphErrorMessage: string;
  graphHash: {
    [key: string]: DataPoint[];
  };
  devicesColor: { [key: string]: string };
  isLoading: boolean;
  marginLeft?: number;
  marginRight?: number;
  interval?: number;
  oneDataPoint?: string[];
  emptyGraph?: boolean;
  hasDevices?: boolean;
}

interface GraphProps {
  graphData: GraphDataPoint[];
  isFetching: boolean;
  className?: string;
}

const GraphContainer = styled(Segment)`
  && {
    padding: 0;
    @media only screen and (max-width: ${tabletMaxWidth}px) {
      border-radius: 0px;
    }
  }
`;

const GraphControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    margin-left: 20px;
  }
  @media only screen and (max-width: ${tabletMaxWidth}px) {
    padding: 15px 0;
  }
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    padding-top: 15px;
  }
`;

const GraphHorizontalScroll = styled.div`
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    width: 150vw;

    .recharts-responsive-container .recharts-wrapper {
      width: 100vw !important;
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  overflow: hidden;
  min-height: 400px;
  @media only screen and (max-width: ${mobileMaxWidth}px) {
    min-height: auto;
  }
`;

const MAX_GRAPH_DATA_POINTS = 30;

const TemperatureGraph: FunctionComponent<TemperatureGraphProps> = (props) => {
  const {
    xAxis,
    yAxis,
    graphErrorMessage,
    graphHash,
    devicesColor,
    marginLeft = 40,
    marginRight = 80,
    interval,
    oneDataPoint = [],
  } = props;

  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart
          margin={{
            top: 50,
            bottom: 50,
            left: marginLeft,
            right: marginRight,
          }}
        >
          <CartesianGrid vertical={false} />
          {/* //FIXME: XAxis type seems not to receive a children */}
          {/* eslint-disable */}
          {/* @ts-ignore */}
          <XAxis
            stroke="#ccc"
            dataKey={'x'}
            scale="point"
            ticks={Array(30)
              .fill(0)
              .map((_, i) => i)}
            tickLine={true}
            axisLine={false}
            tick={<XAxisTick labels={xAxis.labels} />}
            allowDuplicatedCategory={false}
            interval={0}
          >
            {graphErrorMessage !== '' && (
              <GraphLabel
                value={graphErrorMessage}
                position="top"
                offset={150}
              />
            )}
          </XAxis>
          <YAxis
            stroke="#ccc"
            dataKey={'y'}
            ticks={[0, 20, 40, 60, 80, 100, 120]}
            tickLine={false}
            axisLine={true}
            tick={<YAxisTick labels={yAxis.labels} />}
          />
          <Tooltip
            isAnimationActive={false}
            content={
              <GraphTooltip
                xAxisAllLabels={xAxis.allLabels}
                interval={interval}
              />
            }
            cursor={false}
          />
          {Object.keys(graphHash).map((key: string) => {
            return (
              <Scatter
                key={key}
                data={graphHash[key]}
                line={{ stroke: devicesColor[key], strokeWidth: 1.5 }}
                fill={devicesColor[key]}
                lineJointType="monotoneX"
                shape={
                  <CustomActiveDot
                    color={devicesColor[key]}
                    hasSingleDataPoint={oneDataPoint.includes(key)}
                  />
                }
              />
            );
          })}
        </ScatterChart>
      </ResponsiveContainer>
    </>
  );
};

TemperatureGraph.displayName = 'TemperatureGraph';

export function getInterval(start: string, end: string) {
  const diffRangeInMinutes = moment(end).diff(start, 'minutes');
  let groupTimeFilter = 1;

  if (diffRangeInMinutes > MAX_GRAPH_DATA_POINTS) {
    groupTimeFilter = Math.round(diffRangeInMinutes / MAX_GRAPH_DATA_POINTS);
  }
  return groupTimeFilter;
}

export function allGraphXTickLabels(
  graphXTicks: string[],
  interval: number,
  all = false,
  isArray = false,
  customTimeFormat?: string,
  customDateFormat?: string,
): { [key: number]: string } {
  // only display all x axis labels
  // when interval is less then 300 and all is true
  // if (interval > 300 && all) {
  //   return {};
  // }

  // return all x axis labels for now
  // tooltip will change based on the interval

  return graphXTicks.reduce(
    (list: { [key: number]: string }, tick: string, idx: number) => {
      if (idx % 3 !== 0 && !all) {
        list[idx] = '';
      } else if (interval > 48) {
        list[idx] = formatDate(tick, all, customDateFormat);
      } else {
        list[idx] = moment(tick).format(customTimeFormat ?? 'H:mm');
      }
      return list;
    },
    isArray ? [] : {},
  );
}

function generateYTickLabels(yMin: number, yMax: number, delta: number) {
  let labels = ['', yMin, '', yMax - delta * (2 / 4), '', yMax, ''];

  // no delta meaning min === max
  // so only display 1 y-axis tick
  if (delta === 0) {
    labels = ['', '', '', `${yMax}`, '', '', ''];
  }

  return {
    // y-axis labels
    labels,
  };
}

function hideTooltip() {
  const elem = document.querySelectorAll('.recharts-tooltip-wrapper');
  if (elem?.[0]) {
    const tooltip = elem[0] as HTMLElement;
    tooltip.style.visibility = 'hidden';
    tooltip.className = 'recharts-tooltip-wrapper';
  }
}

const TemperatureGraphDisplay: FunctionComponent<TemperatureGraphProps> = (
  args: TemperatureGraphProps,
) => {
  const { isAdmin } = useContext(AccountContext);

  return args.emptyGraph && !args.hasDevices ? (
    <DashboardPlaceholder type={isAdmin() ? 'graphAdmin' : 'graphViewer'} />
  ) : (
    <TemperatureGraph {...args} />
  );
};

export const Graph: FunctionComponent<GraphProps> = (props) => {
  const { graphData, isFetching } = props;
  const { store, dispatch } = useContext(StoreContext);
  const { user } = useContext(AccountContext);
  const { preferredTempUnit } = user as UserProfile;
  const {
    filters,
    devicesColor,
    showFilterRail,
    humidityGraphData,
  } = store.pages.dashboard;
  const { isQueryInProgress } = store.pages;
  const { sensorType } = filters;

  const { devices } = store.entities;

  const [graphHash, setGraphHash] = useState<{
    [key: string]: DataPoint[];
  }>({});
  const [xAxis, setXAxis] = useState<XAxisProp>({
    values: [],
    labels: [],
    allLabels: [], // for data alignment when x-axis is time
  });
  const [yAxis, setYAxis] = useState<YAxisProp>({ labels: [] });
  const [graphErrorMessage, setGraphErrorMessage] = useState<string>('');
  const [theInterval, setInterval] = useState<number>(0);
  const [oneDataPoint, setOneDataPoint] = useState<string[]>([]);
  const [graphPoint, setGraphPoint] = useState<GraphDataPoint[]>([]);
  const [emptyGraph, setEmptyGraph] = useState(false);
  const hasLoadedDevice = devices.all.length ? true : false;

  useEffect(() => {
    const { start, end } = filters.date;
    const interval = getInterval(start as string, end as string);
    setInterval(interval);
    const values: string[] = Array.from(
      Array(30),
      (_: undefined, idx: number) => {
        const startDate = new Date(start as string);
        startDate.setMinutes(startDate.getMinutes() + idx * interval);
        return startDate.toISOString();
      },
    );
    const labels = allGraphXTickLabels(values, interval);
    const allLabels = allGraphXTickLabels(values, interval, true);
    setXAxis({
      labels,
      values,
      allLabels,
    });
  }, [filters.date]);

  // dummy data to show x-axis even if there is no actual data
  // because recharts won't render x-axis if no data supplied
  const setDumyGraphHash = () => {
    setGraphHash({
      '': Array(30)
        .fill({
          deviceUUID: '',
          mean: 0,
          time: '',
          x: 0,
          y: null,
        })
        .map((val, i) => {
          return {
            ...val,
            x: i,
          };
        }),
    });
  };

  const setGraphAsEmpty = (
    message = 'No results found. Please try another search.',
  ) => {
    setEmptyGraph(true);
    setDumyGraphHash();
    setGraphErrorMessage(message);
    return setYAxis({
      labels: ['', '', '', '', '', ''],
    });
  };

  useEffect(() => {
    setGraphPoint(graphData);
    return () => {
      setGraphPoint([]);
      setGraphErrorMessage('');
      setGraphAsEmpty();
    };
  }, [graphData]);

  useEffect(() => {
    hideTooltip();
    if (graphPoint.length === 0 && !isFetching) {
      return setGraphAsEmpty();
    }

    const isHumidity = sensorType === 'humidity';
    const orderedData = graphData;
    const yMeans = orderedData
      .filter((measure: GraphDataPoint) => isValidNumber(measure.mean))
      .map((measure: GraphDataPoint) => measure.mean);

    // if no yMeans - all mean are null and no data
    if (!yMeans.length) {
      return setGraphAsEmpty();
    }

    const yMin = Math.min(...yMeans);
    const yMax = Math.max(...yMeans);
    const deltaValue = yMax - yMin;

    const scaleY = (oldValue: number) => {
      const oldMax = yMax;
      const oldMin = yMin;
      const newMax = 5;
      const newMin = 1;

      const newVal =
        ((oldValue - oldMin) / (oldMax - oldMin)) * (newMax - newMin) + newMin;

      const yPercentage = (newVal / 5) * 100;
      // if no delta value set point to half of y axis
      return deltaValue === 0 ? 60 : yPercentage;
    };

    const sensorsWithManyData: {
      [key: string]: {
        dataPointCtr: number;
      };
    } = {};
    const reducedGraphData: GraphData = orderedData.reduce(
      (acc: { [key: string]: DataPoint[] }, point: GraphDataPoint) => {
        const { device_uuid: deviceUuid, mean, time } = point;
        // if device_UUID does not exist in list of devices
        // do not add in the graph
        if (devices.byUUID && !(deviceUuid in devices.byUUID)) {
          return acc;
        }

        if (!acc.hasOwnProperty(point.device_uuid)) {
          acc[deviceUuid] = [];
        }

        const y = isValidNumber(mean) ? scaleY(+mean.toFixed(2)) : null;

        acc[deviceUuid] = acc[deviceUuid].concat({
          deviceUUID: deviceUuid,
          mean:
            // ignore temp conversion if humidity
            isValidNumber(mean) && preferredTempUnit === 'f' && !isHumidity
              ? toFahrenheit(mean)
              : mean,
          time,
          x: acc[deviceUuid].length,
          y,
        });

        // take note of the number of data points of each sensor
        if (y) {
          if (deviceUuid in sensorsWithManyData === false) {
            sensorsWithManyData[deviceUuid] = {
              dataPointCtr: 1,
            };
          } else {
            sensorsWithManyData[deviceUuid].dataPointCtr++;
          }
        }

        return acc;
      },
      {},
    );

    // get keys of sensors with only 1 data point
    setOneDataPoint(
      Object.keys(reducedGraphData).filter((key) => {
        return (
          sensorsWithManyData[key] &&
          sensorsWithManyData[key].dataPointCtr === 1
        );
      }),
    );

    const keys = Object.keys(reducedGraphData);

    // no keys means no data
    if (!keys.length) {
      return setGraphAsEmpty();
    }

    if (reducedGraphData[keys[0]]) {
      const yTicks = generateYTickLabels(yMin, yMax, deltaValue);
      setYAxis(yTicks);
    }

    const unNullGraphData = connectNullData(reducedGraphData);
    setGraphHash(unNullGraphData);

    // if this point is reached then it means data was just fine
    // reset graph error message
    setGraphErrorMessage('');
    // remove empty graph placeholder
    setEmptyGraph(false);
  }, [
    graphData,
    preferredTempUnit,
    sensorType,
    isQueryInProgress['browseGraph:set'],
  ]);

  const showGraphToggle = humidityGraphData.length || sensorType === 'humidity';

  return (
    <GraphContainer>
      <GraphHeader heading={showGraphToggle ? <GraphToggle /> : 'Temperature'}>
        <GraphControls>
          <PopupTooltip
            className="graph-toggle__tooltip"
            content={
              showFilterRail ? 'Expand to full screen' : 'Exit full screen'
            }
            trigger={
              <Icon
                as={Media}
                greaterThanOrEqual={'tablet'}
                className={`graph-toggle ${
                  showFilterRail ? 'icon-fullscreen' : 'icon-exit'
                }`}
                size="big"
                color="grey"
                onClick={() =>
                  dispatch({ type: 'dashboard:filterRail:toggle' })
                }
              />
            }
          />
        </GraphControls>
      </GraphHeader>
      <Divider className="flat" />
      <GraphHorizontalScroll key={graphErrorMessage}>
        {isQueryInProgress['browseGraph:set'] || isFetching ? (
          <LoaderContainer>
            <DashboardLoader query="browseGraph:set" active={isFetching} />
          </LoaderContainer>
        ) : (
          <>
            <Media lessThan={'w1078'}>
              <TemperatureGraphDisplay
                isLoading={isQueryInProgress['browseGraph:set'] || isFetching}
                emptyGraph={emptyGraph}
                xAxis={xAxis}
                yAxis={yAxis}
                graphErrorMessage={graphErrorMessage}
                graphHash={graphHash}
                devicesColor={devicesColor}
                marginLeft={0}
                marginRight={40}
                interval={theInterval}
                oneDataPoint={oneDataPoint}
                hasDevices={hasLoadedDevice}
              />
            </Media>
            <Media greaterThanOrEqual={'w1079'}>
              <TemperatureGraphDisplay
                isLoading={isQueryInProgress['browseGraph:set'] || isFetching}
                emptyGraph={emptyGraph}
                xAxis={xAxis}
                yAxis={yAxis}
                graphErrorMessage={graphErrorMessage}
                graphHash={graphHash}
                devicesColor={devicesColor}
                interval={theInterval}
                oneDataPoint={oneDataPoint}
                hasDevices={hasLoadedDevice}
              />
            </Media>
          </>
        )}
      </GraphHorizontalScroll>
    </GraphContainer>
  );
};

/**
 * keep this for now
 * this is the offset logic
 */
// interface ITickObj {
//   [key: string]: {
//     count: number;
//     firstIdx: number;
//     lastIdx: number;
//   };
// }

// function allGraphXTickLabels(
//   graphXTicks: string[],
//   interval: number,
// ): { [key: number]: string } {
//   const xTicksObj: ITickObj = {};
//   let numberOfXTicks = 0;
//   const newGraphXTicks = graphXTicks.reduce(
//     (list: { [key: number]: string }, tick: string, idx: number) => {
//       if (interval > 300) {
//         const val = formatDate(tick);
//         if (val in xTicksObj) {
//           xTicksObj[val].count++;
//           xTicksObj[val].lastIdx = idx;
//         } else {
//           xTicksObj[val] = {
//             count: 1,
//             firstIdx: idx,
//             lastIdx: idx,
//           };
//           numberOfXTicks++;
//         }
//       } else if (idx % 3 !== 0) {
//         list[idx] = '';
//       } else {
//         list[idx] = moment(tick).format('H:mm');
//       }
//       return list;
//     },
//     {},
//   );

//   const adjustedXTicks: string[] = Array(MAX_GRAPH_DATA_POINTS).fill('');

//   Object.keys(xTicksObj).forEach((val, idx) => {
//     const obj = xTicksObj[val];
//     const diff = obj.lastIdx - Math.floor(obj.count / 2);
//     if (numberOfXTicks >= 30) {
//       if (idx % 3 === 0) {
//         adjustedXTicks[idx] = val;
//       }
//     } else {
//       adjustedXTicks[diff] = val;
//     }
//   });

//   return interval > 300 ? adjustedXTicks : newGraphXTicks;
// }
