import { toFahrenheit, toFixed } from '@coinspect/utils';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import isString from 'lodash/isString';
import template from 'lodash/template';
import { useContext, useState } from 'react';

import { AccountContext, LocalStorageContext } from '../contexts';
import { useAlertNotif } from '../hooks';
import { AlertNotifRecipient } from '../services';
import { StoreContext } from '../store';

interface FetchAlertNotifProps {
  limit: number;
}

export interface RowDataModel {
  alert: string;
  date: string;
  endedAt: Date | undefined;
  recipients: AlertNotifRecipient[];
  sentTo: string;
  time: string;
  field: string;
  deviceName: string | undefined;
  locationName: string | undefined;
  activeMins: number | null;
  triggerValue: number | string | undefined;
  rawSummary: string | undefined;
}

interface PageOptions {
  isDesktop: boolean;
}

export function useFetchAlertNotif(props: FetchAlertNotifProps) {
  const { user } = useContext(AccountContext);
  const { limit } = props;
  const [newPage, setNewPage] = useState<number>(0);
  const [newDesktopPage, setNewDesktopPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { store, dispatch } = useContext(StoreContext);
  const { alertNotifs } = store.entities;
  const {
    filters,
    currentPage = 0,
    currentDesktopPage = 0,
  } = store.pages.dashboard;
  const { browseAlertNotif } = useAlertNotif();
  const { isQueryInProgress } = store.pages;
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(
    axios.CancelToken.source(),
  );
  const { start: startDate, end: endDate } = filters.date;
  const { setItem } = useContext(LocalStorageContext);
  const [isDesktop, setIsDeskTop] = useState(true);

  function setPageThenFetch({
    nextPage,
    cancelToken,
    shouldAppend,
    additionalFilters,
  }: {
    nextPage: number;
    cancelToken?: CancelToken;
    shouldAppend?: boolean;
    additionalFilters?: {
      field?: string;
      reason?: string;
      notifType?: string[];
      locationUUID?: string[];
      recipientUUID?: string[];
      activeOnly?: boolean;
    };
  }) {
    if (isDesktop) {
      setNewDesktopPage(nextPage);
    } else {
      setNewPage(nextPage);
    }

    setIsLoading(true);
    (async () => {
      await browseAlertNotif(
        {
          deviceUUID: filters.devices,
          endDate,
          limit,
          page: nextPage,
          startDate,
          ...(additionalFilters ? additionalFilters : {}),
        },
        cancelToken,
        shouldAppend,
      );
      setIsLoading(false);
    })();
  }

  function handleCancelToken() {
    let currentCancelToken = cancelTokenSource;
    if (isQueryInProgress['alertNotif:reset']) {
      cancelTokenSource.cancel('Cancelling request due to a newer request');
      currentCancelToken = axios.CancelToken.source();
      setCancelTokenSource(currentCancelToken);
    }

    return currentCancelToken;
  }

  function handlePaginationClick(value = 0, options?: PageOptions) {
    const currentCancelToken = handleCancelToken();

    const page = options?.isDesktop
      ? Number(currentDesktopPage) + Number(value)
      : Number(currentPage) + Number(value);
    if ((alertNotifs.all.length < limit && value > 0) || page < 0) {
      return;
    }

    if (!options?.isDesktop) {
      setItem('currentLocalPage', page);
      setIsDeskTop(false);
      dispatch({
        data: {
          currentPage: page,
        },
        type: 'dashboard:currentPage:set',
      });
    } else {
      setItem('currentDesktopPage', page);
      setIsDeskTop(true);
      dispatch({
        data: {
          currentDesktopPage: page,
        },
        type: 'dashboard:currentPage:set',
      });
    }
    setPageThenFetch({ nextPage: page, cancelToken: currentCancelToken.token });
    setCancelTokenSource(currentCancelToken);
  }

  function displayPreferredTemp(rowData: RowDataModel) {
    const { triggerValue, alert, rawSummary } = rowData;
    if (!triggerValue || !rawSummary) {
      return alert;
    }
    const compiled = template(rawSummary);
    const value = isString(triggerValue)
      ? parseFloat(triggerValue)
      : triggerValue;
    const sensorValue =
      user?.preferredTempUnit === 'f' ? toFahrenheit(value) : value;

    return compiled({
      triggerValue: toFixed(sensorValue),
      tempUnit: user?.preferredTempUnit.toUpperCase(),
    });
  }

  return {
    handlePaginationClick,
    isLoading,
    newPage,
    setPageThenFetch,
    displayPreferredTemp,
    handleCancelToken,
    newDesktopPage,
  };
}
