import React from 'react';

import {
  ButtonsContainer,
  LoginHeader,
  OuterLayout,
  SignInArea,
  SignInFooterContainer,
  SignInLayout,
} from '../../../style/login-signup-styles';
import { BrowserBanner } from '../../browser-banner';
import { ClimateImpact } from '../../climate-impact';
import { ThermaLogoHeader } from '../../log-in-sign-up/logo-container';
import { WelcomeBackHeader } from '../../log-in-sign-up/welcome-back';
import { SignInFooter } from '../sign-in-footer';

interface LoginBaseProp {
  component?: JSX.Element;
}

export const LoginBasePage: React.FC<LoginBaseProp> = (props) => {
  const { component } = props;

  return (
    <OuterLayout>
      <SignInLayout className="sign-in-layout">
        <BrowserBanner />
        <SignInArea>
          <LoginHeader>
            <ThermaLogoHeader />
            <WelcomeBackHeader />
          </LoginHeader>

          <ButtonsContainer>{component}</ButtonsContainer>

          <SignInFooterContainer>
            <SignInFooter />
          </SignInFooterContainer>
        </SignInArea>

        <ClimateImpact />
      </SignInLayout>
    </OuterLayout>
  );
};
