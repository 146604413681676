import {
  ModalHeaderText,
  thermaBlue,
  ThermaSecondaryAction100,
  ThermaText300,
} from '@coinspect/ui';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Icon, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

import { mobileMaxWidth, mobileMaxWidthPx } from '../../constants';
import { StandardModalContext } from '../../contexts';
import { StoreContext } from '../../store';
import { StandardModalButtons } from './standard-modal-actions';
import StandardModalBody from './standard-modal-body';

export interface StandardModalProps {
  allContent?: JSX.Element; //content and actions
  content?: string | JSX.Element; // content of modal
  subContent?: string | JSX.Element; // content of modal
  subHeader?: string | JSX.Element; // content of modal
  header?: string | JSX.Element; // header of modal
  headerIcon?: JSX.Element;
  isOpen?: boolean; // open modal
  width?: string;
  disableButtonsOnQuery?: string;
  onClose?: () => void;
  primaryButton?: StandardModalButtons;
  secondaryButton?: StandardModalButtons;
  leftButton?: StandardModalButtons;
  headerTagline?: string | JSX.Element; // text under the header
  disableCloseOnOutsideClick?: boolean;
  removeCloseIcon?: boolean; // remove close icon from modal
  scrollable?: boolean; // set content body scrollable
  scrollablePadding?: string; // set scrollable area padding
}

interface WrappedModalProps {
  isEnergy: boolean;
  widthsize?: string;
}

const DEFAULT_SIZE = 40;
const DEFAULT_FONT_SIZE = 20;

interface IconCircleProps {
  size?: number;
  fontSize?: number;
}

export const IconCircle = styled.div<IconCircleProps>`
  min-width: ${({ size }) => size || DEFAULT_SIZE}px;
  width: ${({ size }) => size || DEFAULT_SIZE}px;
  height: ${({ size }) => size || DEFAULT_SIZE}px;
  border-radius: 50%;
  font-size: ${({ fontSize }) => fontSize || DEFAULT_FONT_SIZE}px;
  color: ${thermaBlue};
  background-color: ${ThermaSecondaryAction100};
  margin: 0 12px 0 0;
  padding: 8px;
  line-height: ${({ size }) => size || DEFAULT_SIZE}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WrappedModal = styled(Modal)<WrappedModalProps>`
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    width: 329px !important;
    padding: 20px 16px !important;
  }
  @media only screen and (min-width: ${mobileMaxWidthPx}) {
    width: ${({ widthsize = '512px' }) => widthsize} !important;
    padding: 30px !important;
  }

  &&& {
    > .header {
      padding: 0px !important;
    }

    > .header {
      font-family: futura-maxi;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: ${ModalHeaderText};
      display: flex;
      width: 100%;
      align-items: flex-start;
    }
  }
`;

const ModalCloseIcon = styled(Icon)`
  font-size: 14px !important;
  margin: 0px 0px 0px 17px !important;

  && {
    @media only screen and (max-width: ${mobileMaxWidth}px) {
      margin-top: 25px;
    }
    position: relative;
    top: -6px;

    cursor: pointer;
    color: ${ThermaText300};
  }
`;

const FlexPad = styled.div`
  flex-grow: 1;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: proxima-nova;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

const HeaderTagline = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${ThermaText300};
  font-family: 'proxima-nova';
  line-height: 19px;
  margin-top: 2px;
`;

export const StandardModal: FC<StandardModalProps> = (props) => {
  const {
    isOpen,
    disableButtonsOnQuery = '',
    primaryButton,
    secondaryButton,
    leftButton,
    header,
    headerIcon,
    subHeader,
    content,
    subContent,
    onClose,
    allContent,
    headerTagline = '',
    removeCloseIcon,
    width = '512px',
    scrollable = false,
    scrollablePadding,
  } = props;
  const { store } = useContext(StoreContext);
  const { isQueryInProgress } = store.pages;
  const [disableButtons, setDisableButtons] = useState(false);
  const { hideModal } = useContext(StandardModalContext);

  useEffect(() => {
    setDisableButtons(isQueryInProgress[disableButtonsOnQuery]);
  }, [isQueryInProgress[disableButtonsOnQuery]]);

  const doClose = () => {
    hideModal();
    onClose && onClose();
  };

  const location = useLocation();
  const isEnergy = location.pathname.includes('energy');

  return (
    <WrappedModal
      className={isEnergy ? 'energy-standard-modal' : ''}
      open={isOpen}
      widthsize={width}
    >
      <Modal.Header className={isEnergy ? 'energy-standard-modal_header' : ''}>
        {header && (
          <HeaderContainer>
            {headerIcon && <IconCircle>{headerIcon}</IconCircle>}
            {header && (
              <div>
                <div
                  className={
                    isEnergy ? 'energy-standard-modal_header-text' : ''
                  }
                >
                  {header}
                </div>
                {headerTagline && (
                  <HeaderTagline>{headerTagline}</HeaderTagline>
                )}
              </div>
            )}
          </HeaderContainer>
        )}
        <FlexPad />
        {!removeCloseIcon && !isEnergy ? (
          <>
            <ModalCloseIcon
              className="icon-close"
              aria-hidden="true"
              onClick={doClose}
              disabled={isQueryInProgress[disableButtonsOnQuery]}
            />
          </>
        ) : (
          <Icon
            className="icon-cancel"
            onClick={doClose}
            style={{ color: 'white' }}
          />
        )}
      </Modal.Header>
      {allContent ? (
        <div className={isEnergy ? 'energy-standard-modal_content' : ''}>
          {allContent}
        </div>
      ) : (
        <StandardModalBody
          scrollable={scrollable}
          scrollablePadding={scrollablePadding}
        >
          {subHeader && (
            <StandardModalBody.SubHeader>
              {subHeader}
            </StandardModalBody.SubHeader>
          )}
          {content && (
            <StandardModalBody.Content>{content}</StandardModalBody.Content>
          )}
          {subContent && (
            <StandardModalBody.SubContent>
              {subContent}
            </StandardModalBody.SubContent>
          )}
          <StandardModalBody.Actions
            primaryButton={
              primaryButton
                ? { disabled: disableButtons, ...primaryButton }
                : null
            }
            secondaryButton={
              secondaryButton
                ? { disabled: disableButtons, ...secondaryButton }
                : null
            }
            leftButton={
              leftButton ? { disabled: disableButtons, ...leftButton } : null
            }
          />
        </StandardModalBody>
      )}
    </WrappedModal>
  );
};
