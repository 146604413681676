import React from 'react';
import { PopupProps } from 'semantic-ui-react';
import styled from 'styled-components';

import { REPORT_FORM_BREAKPOINT } from '../../services';
import { Tooltip } from '../tooltip';

type IconOutsideFieldProps = {
  icon: JSX.Element | string;
  children: React.ReactNode;
  iconStyle?: React.CSSProperties;
  iconPopupProps?: PopupProps;
  className?: string;
};

const IconContainer = styled.div`
  display: none;

  @media only screen and (min-width: ${REPORT_FORM_BREAKPOINT}) {
    display: block;
  }
`;

export const IconOutsideField: React.FC<IconOutsideFieldProps> = (props) => {
  const { icon, children, iconStyle, iconPopupProps, className } = props;
  const isIcon = typeof icon === 'string';

  return (
    <div className="relative">
      <Tooltip
        {...iconPopupProps}
        trigger={
          <IconContainer className="absolute cursor-pointer" style={iconStyle}>
            {isIcon ? <i className={icon as string} /> : icon}
          </IconContainer>
        }
      />
      <div className={className}>{children}</div>
    </div>
  );
};
