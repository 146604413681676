import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import { Header } from 'semantic-ui-react';

import { ForgotPasswordForm } from '../../components/forms/forgot-password-form';
import { sendPasswordResetEmail } from '../../services';
import {
  ConfirmingContainer,
  ForgotPasswordContainer,
  PinkLink,
} from '../../style/forgot-password-styles';
import {
  ResendAccText,
  ResendSubText,
  ResetContainer,
} from '../../style/login-signup-styles';

export const ForgotPassword: FunctionComponent = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = async (payload: string) => {
    const response = await sendPasswordResetEmail(payload);

    const message = !response
      ? `Invalid email address.`
      : 'We’ve sent you an email to reset your password.';

    setSuccessMessage(message);
    setSubmitted(true);
  };

  let forgotPasswordHeader = (
    <>
      <ResetContainer>
        <Header as="h2" className="password-form-header">
          <ResendAccText>Reset your password</ResendAccText>
          <ResendSubText>
            Please enter your email address. We will send you an email to reset
            your password.
          </ResendSubText>
        </Header>
      </ResetContainer>
    </>
  );

  if (successMessage.length) {
    forgotPasswordHeader = (
      <>
        <ConfirmingContainer>
          <p className="forgot-password-form__header--success">
            {successMessage}
          </p>
        </ConfirmingContainer>

        <Link to="/">
          <PinkLink>Back to log in</PinkLink>
        </Link>
      </>
    );
  }

  return (
    <ForgotPasswordContainer>
      {forgotPasswordHeader}
      {!submitted && <ForgotPasswordForm onSubmit={handleSubmit} />}
    </ForgotPasswordContainer>
  );
};
