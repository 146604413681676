import { GrayGraphite, thermaOffWhite } from '@coinspect/ui';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextArea, TextAreaProps } from 'semantic-ui-react';
import styled from 'styled-components';

type HiddenTextAreaProps = {
  name: string;
  maxLength?: number;
} & TextAreaProps;

const TEXT_AREA_STATE = {
  NOT_ENGAGED: 'not-engaged',
  ACTIVE: 'active',
  IDLE: 'idle',
} as const;
export type TextAreaStateTypes = typeof TEXT_AREA_STATE[keyof typeof TEXT_AREA_STATE];

export const StyledTextArea = styled(TextArea)`
  width: 100%;
  border: 1px solid ${thermaOffWhite};
  outline: none;
  resize: none;
  border-radius: 4px;
  padding: 8px;

  &::placeholder {
    font-size: 18px;
    font-family: 'proxima-nova';
    font-weight: 100;
    color: ${GrayGraphite};
  }
`;

const HiddenTextAreaStyled = styled(StyledTextArea)<{
  state: TextAreaStateTypes;
}>`
  &&&:focus {
    background-color: #f6f6f6;
    border: 2px solid #f6f6f6;
  }
`;

export const HiddenTextArea: React.FC<HiddenTextAreaProps> = (props) => {
  const { label, name, ...rest } = props;
  const { watch } = useFormContext<Record<string, string>>();
  const fieldValue = watch(name);
  const fieldId = `${name}-hidden-text-area`;

  const [textAreaState, setTextAreaState] = useState<TextAreaStateTypes>(
    fieldValue ? TEXT_AREA_STATE.IDLE : TEXT_AREA_STATE.NOT_ENGAGED,
  );

  useEffect(() => {
    setTimeout(() => {
      if (textAreaState === 'active') {
        const elem = document.getElementById(fieldId);
        elem?.focus();
      }
    }, 100);
  }, [textAreaState]);

  return (
    <div>
      {textAreaState === TEXT_AREA_STATE.NOT_ENGAGED && (
        <div
          onClick={() => {
            setTextAreaState(TEXT_AREA_STATE.ACTIVE);
          }}
          className="cursor-pointer"
        >
          {label}
        </div>
      )}

      {textAreaState !== TEXT_AREA_STATE.NOT_ENGAGED && (
        <Controller
          as={<HiddenTextAreaStyled {...rest} />}
          name={name}
          onBlur={(_e) => {
            if (!fieldValue) {
              setTextAreaState(TEXT_AREA_STATE.NOT_ENGAGED);
            }
          }}
          state={textAreaState}
          id={fieldId}
          defaultValue={fieldValue}
        />
      )}
    </div>
  );
};
