import { primaryColor } from '@coinspect/ui';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { StandardModalContext } from '../../../contexts';
import { START_TIMER_KEY, useDeviceService } from '../../../hooks';
import { StoreContext } from '../../../store';
import { SensorPageActions } from '../../../store/reducers';
import { InfoSvg } from '../../ui/images/svgs/info-svg';
import { ActivateSensorModal } from '../activate-test-sensor-modal';
import {
  clearConnectionStatusLocation,
  ConnectionTestStatusModal,
  SignalTestingStatus,
} from '../connection-test-status-modal';

export const TestAgainTextStyled = styled.span<{ disabled?: boolean }>`
  font-weight: 700;
  color: ${primaryColor};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`;

interface Status {
  testingStatus: SignalTestingStatus;
  disabled: boolean;
  deviceUUID: string;
  locationUUID: string;
}

export const TestAgainText: React.FC<Status> = (props) => {
  const { testingStatus, disabled, deviceUUID, locationUUID } = props;
  const { dispatch, store } = useContext(StoreContext);
  const { devices } = store.entities;
  const { openModal, hideModal } = useContext(StandardModalContext);

  const {
    startSensorSignalTest,
    startTimer,
    startTimerOnce,
  } = useDeviceService();

  localStorage.removeItem(START_TIMER_KEY);

  const testStatus = {
    connecting: 'Joining',
    connected: 'Testing',
    done: 'Test again',
    join_failed: 'Test again',
    testing_failed: 'Test again',
  };

  /**
   * Flow should be Joining > Testing (disabled) > Test Again
   */

  const statusText = testingStatus ? testStatus[testingStatus] : 'Test Again';
  const device = devices.byUUID[deviceUUID];

  return (
    <TestAgainTextStyled
      disabled={disabled}
      onClick={() => {
        hideModal();
        startSensorSignalTest({
          locationUUID,
          deviceUUIDs: [deviceUUID],
          isTesting: true,
        });

        dispatch({
          type: SensorPageActions.connectionStatusSet,
          data: {
            testingResult: 'In progress',
            testingStatus: 'connecting',
            isTesting: true,
            locationUUID: locationUUID,
            deviceUUID: deviceUUID,
          },
        });
        openModal({
          disableCloseOnOutsideClick: true,
          removeCloseIcon: true,
          headerIcon: <InfoSvg />,
          header: (
            <>
              Hold the ACT button for 5s to activate <br /> sensor and test your
              connection
            </>
          ),
          content: (
            <>
              {window.addEventListener('beforeunload', () => {
                startTimerOnce(() =>
                  startTimer({
                    deviceUUIDs: [deviceUUID],
                    isTestAgain: false,
                  }),
                );
              })}
              <ActivateSensorModal serialNumber={device.serialNumber} />
            </>
          ),
          primaryButton: {
            content: 'I have pressed ACT button',
            onClick: () => {
              hideModal();
              startTimerOnce(() =>
                startTimer({
                  deviceUUIDs: [deviceUUID],
                  isTestAgain: false,
                }),
              );
              openModal({
                header: 'Connection test status',
                disableCloseOnOutsideClick: true,
                removeCloseIcon: true,
                allContent: (
                  <ConnectionTestStatusModal locationUUID={locationUUID} />
                ),
                width: '752px',
                onClose: () => {
                  clearConnectionStatusLocation(dispatch, locationUUID);
                },
              });
            },
          },
          onClose: () => {
            startTimerOnce(() =>
              startTimer({
                deviceUUIDs: [deviceUUID],
                isTestAgain: false,
              }),
            );
          },
        });
      }}
    >
      {statusText}
    </TestAgainTextStyled>
  );
};
