import { CancelToken } from 'axios';
import { normalize, schema } from 'normalizr';
import { useContext } from 'react';

import {
  AlertNotifBrowseParams,
  AlertNotifService,
  DashboardBrowseParams,
} from '../services';
import { StoreContext } from '../store';

const AlertNotifSchema = new schema.Entity(
  'alertNotif',
  {},
  {
    idAttribute: 'uuid',
  },
);

const ActiveAlertSchema = new schema.Entity(
  'activeAlert',
  {},
  {
    idAttribute: 'uuid',
  },
);

export function useAlertNotif() {
  const { dispatch } = useContext(StoreContext);

  async function browseAlertNotif(
    params: AlertNotifBrowseParams,
    cancelToken?: CancelToken,
    shouldAppend?: boolean,
  ) {
    let alertData;
    const resetOrAppend = shouldAppend ? 'append' : 'reset';
    dispatch({ type: `alertNotif:${resetOrAppend}_REQUEST` });
    try {
      const result = await AlertNotifService.browse(
        {
          limit: 5,
          page: 0,
          ...params,
        },
        cancelToken,
      );
      const { data, ...rest } = result;
      const normalized = normalize(data, [AlertNotifSchema]);
      dispatch({
        data: normalized,
        meta: rest,
        type: `alertNotif:${resetOrAppend}`,
      });
      alertData = { data: normalized, ...rest };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    } finally {
      dispatch({ type: `alertNotif:${resetOrAppend}_FINALLY` });
    }

    return alertData;
  }

  async function readTimeAlertFree(params: DashboardBrowseParams) {
    dispatch({
      type: 'timeAlertFree:fetch_REQUEST',
    });

    const data = await AlertNotifService.readTimeAlertFree(params);
    dispatch({
      data,
      type: 'timeAlertFree:set',
    });

    dispatch({
      type: 'timeAlertFree:fetch_FINALLY',
    });
  }

  async function readMetrics() {
    dispatch({
      type: 'metrics:fetch_REQUEST',
    });

    const data = await AlertNotifService.readMetrics();

    dispatch({
      type: 'metrics:fetch_FINALLY',
    });

    return data;
  }

  async function browseActiveAlertNotif() {
    const activeAlert = await AlertNotifService.browseActiveAlert();
    const normalized = normalize(activeAlert, [ActiveAlertSchema]);
    dispatch({
      data: normalized,
      type: 'activeAlert:set',
    });
    return activeAlert;
  }

  async function readLatestEnergyAlert(energyDeviceUUID: string) {
    const activeAlert = await AlertNotifService.browseActiveEnergyAlert({
      energyDeviceUUID,
      limit: 5,
      field: 'hvac_zone_temperature',
    });
    const normalized = normalize(activeAlert, [ActiveAlertSchema]);
    dispatch({
      data: normalized,
      type: 'activeAlert:set',
    });
    return activeAlert;
  }

  return {
    browseActiveAlertNotif,
    browseAlertNotif,
    readLatestEnergyAlert,
    readMetrics,
    readTimeAlertFree,
  };
}
