import { aqua100, darkGrey, PaddedContent, white100 } from '@coinspect/ui';
import React, { isValidElement } from 'react';
import { Header } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  mobileMaxWidthPx,
  tabletMinWidthPx,
  w1079,
} from '../../../constants/responsive-constants';

interface OnboardingSlideProps {
  altText?: string;
  header?: string;
  image?: string;
  isVisible: boolean;
  content: string | React.ReactNode;
  slideTitle: string;
}

const SlideImage = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 753px;
  height: auto;
  position: relative;
  top: 134px;
  @media only screen and (min-width: ${tabletMinWidthPx}) and (max-width: ${w1079}px) {
    max-width: 100%;
  }
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    max-width: 100%;
    position: unset;
    top: 0;
  }
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  min-height: 475px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    min-height: 100px;
    position: unset;
  }
`;

const Slide = styled.div`
  display: block;
  background: ${aqua100};
  border-radius: 4px;
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    position: relative;
  }
`;

const SliderContent = styled(PaddedContent)`
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12),
    0px 16px 32px rgba(0, 0, 0, 0.12), 0px 1px 48px rgba(0, 0, 0, 0.12);
  && {
    position: relative;
    background: ${white100};
    color: ${darkGrey};
    & .content-title {
      margin-bottom: 1em;
    }
    & .content-description {
      font-size: 16px;
      line-height: 1.8em;
      min-height: 72px;
      margin-top: 15px;
    }

    && .header {
      font-size: 22px;
    }
  }
`;

const SlideTitleContainer = styled.div`
  position: absolute;
  margin: 0;
  top: 47px;
  padding: 0 0 0 40px;
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    position: relative;
    padding: 0 15px;
    z-index: 1;
    top: 0;
  }
`;

const SlideHeader = styled.div`
  position: relative;
  background: linear-gradient(
    180deg,
    rgb(144, 187, 202) 18.75%,
    rgb(141, 210, 204) 100%
  );
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const SlideTitle = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 40px;
  color: ${white100};
  @media only screen and (max-width: ${mobileMaxWidthPx}) {
    height: auto;
    padding: 15px;
    text-align: center;
  }
`;

const ContentContainer = styled.div`
  padding: 100px 50px;
  font-size: 18px;
`;

const ContentHeader = styled(Header)`
  font-size: 24px;
`;

const ContentParagraph = styled.p`
  font-size: 18px !important;
`;

export const OnboardingSlide: React.FC<OnboardingSlideProps> = (props) => {
  const { altText, header, content, image, isVisible, slideTitle } = props;

  if (!isVisible) return null;

  if (isValidElement(content)) {
    return (
      <Slide key={header}>
        <SlideHeader>
          <SlideTitleContainer>
            <SlideTitle className="onboarding-slide__title">
              {slideTitle}
            </SlideTitle>
          </SlideTitleContainer>
        </SlideHeader>
        <ContentContainer className="onboarding-slide__content">
          {content}
        </ContentContainer>
      </Slide>
    );
  }

  return (
    <Slide key={header}>
      <SlideHeader>
        <SlideTitleContainer>
          <SlideTitle className="onboarding-slide__title">
            {slideTitle}
          </SlideTitle>
        </SlideTitleContainer>
        <ImagePlaceholder>
          <SlideImage
            className="onboarding-slide__image"
            src={image}
            alt={altText}
          />
        </ImagePlaceholder>
      </SlideHeader>

      <SliderContent size="40px 40px 0px 40px">
        <ContentHeader
          as="h2"
          className="content-title onboarding-slide__header"
        >
          {header}
        </ContentHeader>
        <ContentParagraph className="content-description onboarding-slide__content">
          {content}
        </ContentParagraph>
      </SliderContent>
    </Slide>
  );
};
