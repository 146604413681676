import mixpanel from 'mixpanel-browser';
import React, { createContext, useContext } from 'react';
import Joyride, { ACTIONS, CallBackProps } from 'react-joyride';

import { JoyrideTooltip, JoyrideTooltipProps } from '../components';
import { getCompletedSteps } from '../components/joyride-tooltip/utils';
import { JoyrideFlowFunctions } from '../joyride-steps';
import { StoreContext } from '../store';
import { JoyrideActions, JoyrideFlows } from '../store/reducers';

export const JoyrideContext = createContext({});
export interface JoyrideProps {
  children: React.ReactNode;
}

export const JoyrideProvider = ({ children }: JoyrideProps) => {
  const { store, dispatch } = useContext(StoreContext);
  const {
    joyrideFlows,
    pages: {
      dashboard: { isRepeatTutorial },
    },
  } = store;
  const { activeTour } = joyrideFlows;

  const handleCallback = (data: CallBackProps) => {
    const { action } = data;
    if (action === ACTIONS.CLOSE) {
      if (activeTour && !isRepeatTutorial) {
        mixpanel.track('Onboarding Tutorial Skipped', {
          onboarding_steps_completed: getCompletedSteps(
            activeTour,
            joyrideFlows,
          ),
        });
      }

      dispatch({
        type: JoyrideActions.STOP_TOUR_ALL,
      });
    }
  };

  return (
    <JoyrideContext.Provider value={{}}>
      {Object.values(JoyrideFlows).map((flow) => (
        <Joyride
          key={flow}
          continuous={true}
          run={joyrideFlows[flow].run}
          steps={JoyrideFlowFunctions[flow]()}
          stepIndex={joyrideFlows[flow].stepIndex}
          tooltipComponent={(props: JoyrideTooltipProps) => (
            <JoyrideTooltip {...props} flow={flow} />
          )}
          disableScrolling
          callback={handleCallback}
        />
      ))}

      {children}
    </JoyrideContext.Provider>
  );
};
