import isEqual from 'lodash/isEqual';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button } from 'semantic-ui-react';

import { isTabletAndBelow } from '../../../constants';
import { LocalStorageContext } from '../../../contexts';
import { StoreContext } from '../../../store';
import { defaultFilters } from '../../../store/reducers';
const {
  date: { labelIndex: defaultLabelIndex },
  ...defaultFilteredFilters
} = defaultFilters();

interface ClearFilterButton {
  clearSearchBar: () => void;
  searchBarValue: string;
}

const ClearFilterButton: FunctionComponent<ClearFilterButton> = (props) => {
  const { store, dispatch } = useContext(StoreContext);
  const { filters } = store.pages.dashboard;
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { clearSearchBar, searchBarValue } = props;
  const { setItem } = useContext(LocalStorageContext);

  useEffect(() => {
    const {
      date: { labelIndex },
      ...filteredFilters
    } = filters;

    delete filteredFilters['sensorType'];

    const otherEquality = isEqual(filteredFilters, defaultFilteredFilters);
    if (otherEquality && labelIndex === defaultLabelIndex && !searchBarValue) {
      return setIsDisabled(true);
    }
    return setIsDisabled(false);
  }, [filters, searchBarValue]);

  const clearFilters = (): void => {
    setItem('currentLocalPage', 0);
    dispatch({
      type: 'dashboard:filters:set:default',
    });
    dispatch({
      data: {
        currentPage: 0,
      },
      type: 'dashboard:currentPage:set',
    });
    clearSearchBar();
  };

  return (
    <Button
      id="button--clear-filter"
      primary
      disabled={isDisabled}
      onClick={clearFilters}
      fluid={isTabletAndBelow()}
    >
      Clear filters
    </Button>
  );
};

export { ClearFilterButton };
