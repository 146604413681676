import axios, { CancelTokenSource } from 'axios';

const withCancelToken = (
  fn: (...args: unknown[]) => unknown,
): [CancelTokenSource, (...args: unknown[]) => unknown] => {
  const CancelToken = axios.CancelToken;
  const cancelTokenSource = CancelToken.source();

  return [
    cancelTokenSource,
    (...args: unknown[]) => {
      return fn(...args, cancelTokenSource);
    },
  ];
};

export default withCancelToken;
export { withCancelToken };
