import React, { FunctionComponent } from 'react';
import { Message } from 'semantic-ui-react';

export const SuccessfulPasswordResetMessage: FunctionComponent = () => {
  return (
    <Message positive>
      <Message.Header>Password reset successfully</Message.Header>
    </Message>
  );
};
