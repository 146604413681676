import { useState } from 'react';

export function useCarousel() {
  const [carouselIndex, setCarouselIndex] = useState<number>(0);
  const [carouselSlides, setCarouselSlides] = useState<number>(0);

  function initCarousel(slides: number) {
    setCarouselSlides(slides);
  }

  function nextSlide() {
    setCarouselIndex(
      carouselSlides !== carouselIndex ? carouselIndex + 1 : carouselIndex,
    );
  }

  function previousSlide() {
    setCarouselIndex(0 !== carouselIndex ? carouselIndex - 1 : carouselIndex);
  }

  function isActiveSlide(slide: number) {
    return slide === carouselIndex;
  }

  return {
    carouselIndex,
    carouselSlides,
    initCarousel,
    isActiveSlide,
    nextSlide,
    previousSlide,
  };
}
