import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Tooltip } from '../tooltip';

const PaginationButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;

export interface PaginationProps {
  children?: React.ReactNode;
  page?: number;
  perPage?: number;
  totalRecords?: number;
  disabled?: boolean;
  onClickPrevious?: (e: React.SyntheticEvent) => void;
  onClickNext?: (e: React.SyntheticEvent) => void;
}

export function Pagination(props: PaginationProps) {
  const {
    page = 1,
    perPage = 10,
    totalRecords = 0,
    onClickPrevious,
    onClickNext,
    disabled,
  } = props;
  const rangeMin = Math.max(1, (page - 1) * perPage + 1);
  const rangeMax = Math.min(totalRecords, rangeMin + perPage - 1);
  const totalPage = Math.ceil(totalRecords / perPage);

  return (
    <div>
      <span>
        {rangeMin} - {rangeMax}
      </span>
      <span> of </span>
      <span>{totalRecords}</span>
      <Tooltip
        content="Previous"
        trigger={
          <PaginationButton
            onClick={onClickPrevious}
            disabled={page <= 1 || disabled}
          >
            <Icon name="chevron left" />
          </PaginationButton>
        }
      />
      <Tooltip
        content="Next"
        trigger={
          <PaginationButton
            onClick={onClickNext}
            disabled={page >= totalPage || disabled}
          >
            <Icon name="chevron right" />
          </PaginationButton>
        }
      />
    </div>
  );
}

export default Pagination;
