import React, { FunctionComponent, useContext, useRef } from 'react';
import styled from 'styled-components';

import { useIsOverflow } from '../../hooks';
import { LocationModel, TeamLocationModel } from '../../services';
import { NormalEntity, StoreContext } from '../../store';
import { Tooltip } from '../tooltip';
import { Team } from '../users/';
import { TeamLocations } from './team-tags';
const TeamsComponentWrapper = styled.div`
  margin: 1em 0;
`;

const TeamLabelsSection = styled.div`
  margin-top: 7px;
  padding: ${({ isOverflow = false }) => (isOverflow ? '4px 2px' : '0px')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 60px;
  overflow-y: auto;

  border: ${({ isOverflow = false }) =>
    isOverflow ? '1px solid #D2D7E2' : 'none'};

  &::-webkit-scrollbar {
    width: 4.09px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
`;

export const ListItemTag = styled.span`
  background: #f6f7f7;
  border: 1px solid #d8dbe2;
  border-radius: 4px;
  padding: 4px 8px;
  height: 23px;
  box-sizing: border-box;
  color: #8c8ca2;
  font-family: proxima-nova;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;
  margin: 0px 4px 6px 0px;
`;

const LocationTooltip = styled(Tooltip)`
  width: 200px;
  padding: 12px 16px;
`;

export interface TeamsComponentProps {
  teams: Team[];
  allLocations: NormalEntity<LocationModel>;
}

export const TeamsComponent: FunctionComponent<TeamsComponentProps> = ({
  teams = [],
  allLocations,
}) => {
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  return (
    <TeamsComponentWrapper>
      <label className="bold">Teams:</label>
      <br />
      <TeamLabelsSection ref={ref} isOverflow={isOverflow}>
        {teams.map((team: Team, ind: number) => (
          <LocationTooltip
            position="top left"
            key={ind}
            on="click"
            disabled={false}
            trigger={<ListItemTag>{team.name}</ListItemTag>}
            content={<TeamLocations team={team} allLocations={allLocations} />}
          />
        ))}
      </TeamLabelsSection>
    </TeamsComponentWrapper>
  );
};

export interface TeamsSectionProps {
  uuid: string;
  disabled?: boolean;
}

export const TeamsSection: FunctionComponent<TeamsSectionProps> = (props) => {
  const { uuid, disabled = false } = props;
  const { store } = useContext(StoreContext);
  const allTeams = store.pages.teams.byUUID;
  const allLocations = store.pages.locations.allLocations;
  let userTeams: Team[] = [];

  // Finds a users team
  Object.values<Team>(allTeams).forEach((team) => {
    const members = team.members;
    const foundMember = members.find((member) => member.userUUID === uuid);

    if (foundMember) {
      const _team = {
        ...team,
      };
      // Maps a location name to team location
      _team.locations.forEach((location: TeamLocationModel) => {
        if (location.locationUUID) {
          const locationName = allLocations.byUUID[location.locationUUID].name;
          if (_team.locationNames) {
            _team.locationNames.concat([locationName]);
          } else {
            _team.locationNames = [locationName];
          }
        }
      });
      userTeams = [...userTeams, _team];
    }
  });

  return userTeams.length > 0 && !disabled ? (
    <TeamsComponent teams={userTeams} allLocations={allLocations} />
  ) : (
    <></>
  );
};
