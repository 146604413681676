import { FormError, FormInput } from '@coinspect/ui';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, FormContext, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Form, FormFieldProps } from 'semantic-ui-react';
import { CreateTeam } from 'src/services';
import styled from 'styled-components';

import {
  useInviteService,
  useTeamService,
  useUserService,
} from '../../../hooks';
import { StoreContext } from '../../../store';
import { EnergyFieldErrorStyle } from '../../../style/global-energy-styles';
import { Button } from '../../buttons';
import { EnergyDropdown } from '../../dropdown';
import ErrorBoundary from '../../error-boundary/error-boundary';
import EnergyDeleteModal from '../../modals/energy-delete-modal';
import EnergyModal from '../../modals/energy-modal';
import EnergyMobileNav from '../energy-dashboard-page/energy-mobile-nav';
import EnergyNav from '../energy-dashboard-page/energy-nav';
import ArrowIcon from '../energy-dashboard-page/icons/ArrowIcon';
import { EnergyReportsMainContent } from '../energy-reports-add/styles';
import {
  createLocationsDropdown,
  createRecipientsDropdown,
} from '../energy-reports-add/utils';
import {
  EnergyReportsHeader,
  EnergyReportsHeaderMenu,
  EnergyReportsHeaderText,
  ReportsContent,
  ReportsPageContainer,
} from '../energy-reports-page/index-styles';

export const FORM_WIDTHS = '450px';

type NewTeamFormData = {
  teamName: string;
  teamMembers: string[];
  teamLocations: string[];
};

const TeamsModalText = styled.div`
  color: #ffffff;
`;

const CreateTeamPage = (args) => {
  const formMethods = useForm<NewTeamFormData>({
    mode: 'onChange',
  });

  const [showAddAnotherTeamModal, setAnotherTeamModal] = useState(false);
  const [showEnergyDeleteModal, setEnergyDeleteModal] = useState(false);
  const [isSubmitSuccessful, setSubmitSuccessful] = useState(false);
  const [onSubmitError, setOnSubmitError] = useState('');

  const history = useHistory();

  const { browseAllUsers } = useUserService();
  const { browseInvites } = useInviteService();
  const { addTeam, editTeam, deleteTeam } = useTeamService();
  const { reset, setValue } = formMethods;

  const { store } = useContext(StoreContext);
  const allUsers = store.pages.users.allUsers;
  const allLocations = store.pages.locations.allLocations.byUUID;
  const allInvites = Object.values(store.entities.invites.byUUID);
  const allTeams = store.pages.teams.byUUID;
  const teamId = args.match.params.teamId;
  const team = teamId && allTeams[teamId];
  const isEditMode = !!teamId && !!team;

  const onCancel = () => {
    if (showEnergyDeleteModal) {
      setEnergyDeleteModal(false);
      return;
    }
    history.push('/energy/people?tab=teams');
  };

  const handleDeleteTeam = async () => {
    try {
      await deleteTeam(team.uuid);
    } catch (err) {
      setOnSubmitError(err.response.data.errors[0].details);
      return;
    }

    history.push('/energy/people?tab=teams');
  };

  const onSubmit = async (data: NewTeamFormData) => {
    const teamData: CreateTeam = {
      locations: data.teamLocations,
      members: data.teamMembers,
      name: data.teamName,
    };

    setOnSubmitError('');

    if (isEditMode) {
      try {
        await editTeam(teamId, teamData);
        setSubmitSuccessful(true);
        onCancel();
      } catch (err) {
        setOnSubmitError(err.response.data.errors[0].details);
      }
    } else {
      try {
        await addTeam(teamData);
        setAnotherTeamModal(true);
      } catch (err) {
        setOnSubmitError(err.response.data.errors[0].details);
      }
    }
  };

  const isFormInvalid = () => {
    const formData: FormFieldProps = formMethods.getValues();

    if (
      (formData.name,
      formData.teamLocations?.length && formData.teamMembers?.length)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const submitCreateAnotherTeamModal = async () => {
    setAnotherTeamModal(false);
    setSubmitSuccessful(true);
  };

  const closeModal = () => {
    setAnotherTeamModal(false);
    onCancel();
  };

  useMemo(() => {
    isFormInvalid();
  }, [formMethods.getValues()]);

  useEffect(() => {
    const fetchPeopleAndInvites = async () => {
      await browseAllUsers();
      await browseInvites();
    };
    fetchPeopleAndInvites();
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setValue('teamName', team.name);
      setValue(
        'teamMembers',
        team.members.map(({ userUUID }: { userUUID: string }) => userUUID),
      );
      setValue(
        'teamLocations',
        team.locations.map(
          ({ locationUUID }: { locationUUID: string }) => locationUUID,
        ),
      );
    }
  }, [isEditMode]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        teamName: '',
        teamMembers: [],
        teamLocations: [],
      });
      setSubmitSuccessful(false);
    }
  }, [isSubmitSuccessful]);

  return (
    <ErrorBoundary>
      <ReportsPageContainer>
        <EnergyNav page="PEOPLE" />
        <EnergyMobileNav page="PEOPLE" />
        <ReportsContent>
          <EnergyReportsHeader>
            <EnergyReportsHeaderMenu>
              <EnergyReportsHeaderText>
                <Link to="/energy/people?tab=teams">
                  <ArrowIcon
                    style={{
                      height: '24px',
                      transform: 'rotate(270deg)',
                      width: '24px',
                    }}
                  />
                </Link>
                {isEditMode ? 'Edit' : 'Create'} team
              </EnergyReportsHeaderText>
            </EnergyReportsHeaderMenu>
          </EnergyReportsHeader>
          <FormContext {...formMethods}>
            <Form>
              <EnergyReportsMainContent>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div>
                    {isEditMode ? (
                      <p>
                        People in a team will only view that team&apos;s
                        location/s and equipment. You may also use teams as a
                        recipient for alerts. A team can have multiple
                        locations.
                        <br />A person can be in multiple teams.
                      </p>
                    ) : (
                      <p>
                        Use teams to group people as alert recipients and assign
                        access to location/s. <br />A team can have multiple
                        locations. A person can be in multiple teams.
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div style={{ width: FORM_WIDTHS }}>
                    <FormInput
                      showrequired
                      className="energy-user-fields"
                      name="teamName"
                      label="Team name"
                      labelClassName="energy-user-label"
                      required
                      customErrors={{
                        required: 'Team name is required.',
                      }}
                      customStyle={EnergyFieldErrorStyle}
                    />
                    {onSubmitError && (
                      <FormError
                        error={{ type: 'duplicateTeam' }}
                        customErrors={{
                          duplicateTeam: `${onSubmitError}`,
                        }}
                        customStyle={EnergyFieldErrorStyle}
                      />
                    )}
                  </div>
                </div>
                <div style={{ display: 'flex', gap: '40px' }}>
                  <div>
                    <Controller
                      as={
                        <EnergyDropdown
                          text="Select locations"
                          options={createLocationsDropdown(allLocations)}
                          multiple
                          selection
                          search
                          label="Locations *"
                          width={FORM_WIDTHS}
                          error={!!formMethods.errors['teamLocations']}
                        />
                      }
                      name="teamLocations"
                      defaultValue={[]}
                      rules={{
                        validate: {
                          required: (value) => value.length !== 0,
                        },
                      }}
                      onChange={(event) => event[1].value}
                    />

                    {formMethods.errors['teamLocations'] && (
                      <FormError
                        error={{ type: 'required' }}
                        customErrors={{
                          required: 'Please select at least one location.',
                        }}
                        customStyle={EnergyFieldErrorStyle}
                      />
                    )}
                  </div>
                  <div>
                    <Controller
                      as={
                        <EnergyDropdown
                          text="Select people"
                          options={createRecipientsDropdown(
                            allUsers.byUUID,
                            allInvites,
                            {
                              withAllUsers: false,
                            },
                          )}
                          multiple
                          selection
                          search
                          label="People *"
                          width={FORM_WIDTHS}
                          error={!!formMethods.errors['teamMembers']}
                        />
                      }
                      name="teamMembers"
                      defaultValue={[]}
                      rules={{
                        validate: {
                          required: (value) => value.length !== 0,
                        },
                      }}
                      onChange={(event) => event[1].value}
                    />

                    {formMethods.errors['teamMembers'] && (
                      <FormError
                        error={{ type: 'required' }}
                        customErrors={{
                          required: 'Please select at least one person.',
                        }}
                        customStyle={EnergyFieldErrorStyle}
                      />
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '40px',
                    justifyContent: 'flex-end',
                    marginTop: '250px',
                  }}
                >
                  {isEditMode && (
                    <Button
                      style={{ color: '#FFABAB' }}
                      onClick={() => setEnergyDeleteModal(true)}
                      as="text"
                    >
                      Delete Team
                    </Button>
                  )}
                  <Button onClick={onCancel} as="text">
                    Cancel
                  </Button>
                  <Button
                    onClick={formMethods.handleSubmit((data) => {
                      onSubmit(data);
                    })}
                    as="filled"
                    disabled={isFormInvalid()}
                  >
                    Save
                  </Button>
                </div>
              </EnergyReportsMainContent>
            </Form>
          </FormContext>
        </ReportsContent>
        {showAddAnotherTeamModal && (
          <CreateAnotherTeamModal
            handleClose={() => closeModal()}
            handleSubmit={() => submitCreateAnotherTeamModal()}
          />
        )}
        {showEnergyDeleteModal && (
          <EnergyDeleteModal
            entityName="team"
            handleClose={() => closeModal()}
            handleDelete={() => handleDeleteTeam()}
          />
        )}
      </ReportsPageContainer>
    </ErrorBoundary>
  );
};

export default CreateTeamPage;

const CreateAnotherTeamModal = (props: {
  handleClose: () => void;
  handleSubmit: () => void;
}) => {
  const { handleClose, handleSubmit } = props;

  return (
    <EnergyModal title="Add another team?" handleClose={handleClose}>
      <>
        <TeamsModalText>
          We recommend adding all teams at once before going on to add other
          entities.
        </TeamsModalText>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '16px' }}
        >
          <Button title="Done" onClick={handleClose} as="outline" hideOutline />
          <Button
            title="Add team"
            onClick={handleSubmit}
            as="outline"
            hideOutline
          />
        </div>
      </>
    </EnergyModal>
  );
};
