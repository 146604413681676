import { formatNumber } from '@coinspect/ui';
import React, { FunctionComponent } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { useDialogs, useInviteService, useUserRole } from '../../hooks';
import { InviteModel, InviteStatus } from '../../services';
import { InviteTimer, ResendInvite } from '../resend-invite';
import { Tooltip } from '../tooltip';
import { UserListItem } from './user-list-item';

interface InviteListProps {
  invites: InviteModel[];
}

export const UserInviteList: FunctionComponent<InviteListProps> = (props) => {
  const { invites } = props;
  const { deleteInvite } = useInviteService();
  const { openDeleteDialog, closeDialog } = useDialogs();
  const { displayRole } = useUserRole();

  const handleDelete = async (token: string) => {
    try {
      await deleteInvite(token);
    } finally {
      closeDialog();
    }
  };

  const confirmDelete = (token: string, itemToDelete: string) => {
    openDeleteDialog({
      onConfirm: () => {
        handleDelete(token);
      },
      content: (
        <>
          Are you sure you want to delete <b>{itemToDelete}</b>?
        </>
      ),
      disableButtonsOnQuery: 'invite:delete',
    });
  };

  return (
    <>
      {invites &&
        invites.map((invite: InviteModel, key: number) => {
          const { email = '', phoneNumber = '', status = '' } = invite;
          const itemToDelete = email || formatNumber(phoneNumber, true);
          return (
            <UserListItem
              bottom={key < invites.length}
              key={invite.uuid}
              userName={
                invite.type === 'email'
                  ? email
                  : formatNumber(phoneNumber, true)
              }
              userRole={displayRole(invite.role)}
              inviteStatus={
                <div
                  style={{
                    marginRight: status === InviteStatus.accepted ? '13%' : '',
                  }}
                >
                  <div>
                    <i className="capitalize-first-letter inline-block">
                      {status}
                    </i>
                    <ResendInvite invite={invite} key={invite.uuid} />
                  </div>
                  <InviteTimer invite={invite} />
                </div>
              }
              actions={
                <Tooltip
                  content="Delete"
                  trigger={
                    <Button
                      floated="right"
                      circular
                      icon
                      onClick={() => confirmDelete(invite.token, itemToDelete)}
                      className="therma-off-white"
                    >
                      <Icon
                        className="icon-delete"
                        size="large"
                        aria-label="Delete User"
                      />
                    </Button>
                  }
                />
              }
            />
          );
        })}
    </>
  );
};
