import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

type LazyLoadProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onVisible: <T>(...args: any) => Promise<T> | Promise<void>;
  children?: React.ReactNode;
};

export const LazyLoad: React.FC<LazyLoadProps> = (props) => {
  const { onVisible, children } = props;

  return <VisibilitySensor onChange={onVisible}>{children}</VisibilitySensor>;
};
