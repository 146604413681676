import { unFormatNumber } from '@coinspect/ui';
import { hasValidSearch, PaginationParams } from '@coinspect/utils';

import { request } from '../contexts';
import { BaseEntity } from './index';

export interface Invite {
  email?: string;
  phoneNumber?: string;
  role: string;
  type: string;
  teams?: string[];
  countryCode?: string;
}

export enum InviteStatus {
  accepted = 'accepted',
}

export interface InviteModel extends BaseEntity, Invite {
  companyUUID: string;
  email?: string;
  invitedByUUID: string;
  phoneNumber?: string;
  token: string;
  status?: string;
}

export interface InviteAddModel {
  success: InviteModel[];
  fail?: Invite[];
}

export class InviteService {
  public static async browse(options?: PaginationParams) {
    let url = '/api/account/invite';

    if (hasValidSearch(options)) {
      url += `?searchKeyword=${options?.searchKeyword}`;
    }

    const { data: response } = await request.get(url);
    return response.data;
  }

  public static async read(token: string) {
    try {
      const { data: response } = await request.get(
        `/api/account/invite/${token}`,
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  public static async add(payload: { invites: Invite[] }) {
    const invites = payload.invites.map(
      ({ countryCode, type, email, phoneNumber, ...rest }: Invite) => ({
        ...rest,
        email: type === 'email' ? email : '',
        phoneNumber:
          type === 'sms'
            ? `${countryCode}-${unFormatNumber(
                countryCode + ' ' + phoneNumber,
              )}`
            : '',
        type,
      }),
    );

    const { data: response } = await request.post('/api/account/invite', {
      ...payload,
      invites,
    });
    return response.data;
  }

  public static async delete(deviceUUID: string) {
    const { data: response } = await request.delete(
      `/api/account/invite/${deviceUUID}`,
    );
    return response.data;
  }

  public static async resendInvite(inviteUUID: string) {
    const { data: response } = await request.put(
      `/api/account/invite/resend/${inviteUUID}`,
    );
    return response.data;
  }

  public static async resendAll() {
    const { data: response } = await request.put(
      `/api/account/invite/resend-all`,
    );
    return response.data;
  }

  public static formatInvitePhoneNumber(
    countryCode: string,
    phoneNumber: string,
  ): string {
    return `${countryCode}-${unFormatNumber(countryCode + ' ' + phoneNumber)}`;
  }
}
