import { white100 } from '@coinspect/ui';
import copy from 'copy-to-clipboard';
import React from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

import { isAppleDevice } from '../../lib';
import { AppLogo } from '../base';
import iosNewLandingPageImage from '../ui/images/mobile-browser-landing-image/iOS-landing-image-new-2x.png';
import defaultLandingPageImage from '../ui/images/mobile-browser-landing-image/new-default-landing-image-2x.png';

interface MobileLandingPageProps {
  setDisplayDownloadApp: (value: boolean) => void;
}

const SignUpPageArea = styled.div`
  background: ${white100};
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-flow: column;
`;

const AppMessageArea = styled.div`
  margin: 0px 40px 0px;
  text-align: center;
  font-family: proxima-nova;
  color: #000000;
  font-style: normal;
  font-weight: normal;
`;

const NavigationLinks = styled.div`
  text-align: center;
`;

const SignUpLogo = styled.div`
  margin-top: 80px;
  align-self: center;
`;

const MessageHeader = styled.div`
  margin: 32px 0px 0px;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  font-family: futura-maxi;
`;

const MessageBody = styled.div`
  margin: 32px 0px 0px;
  font-weight: 400;
  font-size: 17px;
  line-height: 34px;
`;

const MessageLines = styled.span`
  display: block;
  line-height: 21px;
`;

const DownloadAppButton = styled(Button)`
  &&& {
    margin: 32px 0px 0px;
    padding: 8px 16px;
    font-weight: 700;
    font-size: 14px;
    line-height: 0px;
    height: 40px;
    background-color: #3277a9;
    border-radius: 8px;
    border: 0;
    text-transform: uppercase;
  }
`;

const ContinueToWeb = styled.a`
  display: block;
  color: #3277a9;
  margin-top: 15px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;
  text-transform: uppercase;

  &:hover {
    color: #3277a9;
  }
`;

const ImageArea = styled.div`
  margin: 10px 16px 0px;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
`;

const MobileImg = styled.img`
  width: 100%;
  height: auto;
  margin: 32px 0;
  margin-bottom: 64px;
`;

const PadDiv = styled.div`
  flex-grow: 1;
`;

function hasInvite() {
  const currentUrl = window.location.href;
  const hadInvite = currentUrl.includes('invite');
  return hadInvite;
}

const copyInviteURL = (): boolean => {
  const currentUrl = window.location.href;
  return copy(currentUrl);
};

export const MobileLandingPage: React.FC<MobileLandingPageProps> = (props) => {
  const { setDisplayDownloadApp } = props;

  const appUrl = isAppleDevice()
    ? 'https://apps.apple.com/us/app/therma/id1491822471'
    : 'https://play.google.com/store/apps/details?id=com.therma.app';

  const goToApp = () => {
    // copy invite link url to clipboard
    copyInviteURL();
    window.open(appUrl, '_blank');
  };

  const proceedWithWeb = () => {
    setDisplayDownloadApp(false);
  };

  return (
    <SignUpPageArea>
      <AppMessageArea>
        <SignUpLogo>
          <AppLogo color="#000" width="200" height="0" heightIE="0" />
        </SignUpLogo>
        <MessageHeader>Cooling Intelligence Platform</MessageHeader>
        {hasInvite() && isAppleDevice() ? (
          <MessageBody>
            <MessageLines>Download the app</MessageLines>
            <MessageLines>
              <b>&ldquo;Allow Paste&rdquo; from GlacierGrid</b>
            </MessageLines>
            <MessageLines>Seamlessly add sensors</MessageLines>
          </MessageBody>
        ) : (
          <MessageBody>
            <MessageLines>Get Energy Management System</MessageLines>
            <MessageLines>and Refrigeration Monitoring</MessageLines>
            <MessageLines>at your fingertips</MessageLines>
          </MessageBody>
        )}
      </AppMessageArea>
      <NavigationLinks>
        <DownloadAppButton
          onClick={goToApp}
          content="Download the app"
          primary
        />
        <ContinueToWeb onClick={proceedWithWeb}>
          Continue to mobile site
        </ContinueToWeb>
      </NavigationLinks>

      <ImageArea>
        <PadDiv />
        {hasInvite() && isAppleDevice() ? (
          <MobileImg src={iosNewLandingPageImage} alt="ios-landing-image" />
        ) : (
          <MobileImg
            src={defaultLandingPageImage}
            alt="default-landing-image"
          />
        )}
      </ImageArea>
    </SignUpPageArea>
  );
};

export default MobileLandingPage;
