import debounce from 'lodash/debounce';
import isUndefined from 'lodash/isUndefined';
import { normalize, schema } from 'normalizr';
import { useCallback, useContext, useEffect, useState } from 'react';

import { DeviceModel, LocationModel, LocationService } from '../services';
import { StoreContext } from '../store/store-context';
import { useDeviceService } from './use-device';
import { useFeatures } from './use-features';

const LocationSchema = new schema.Entity(
  'location',
  {},
  {
    idAttribute: 'uuid',
  },
);

const LocationListSchema = [LocationSchema];

const DeviceSchema = new schema.Entity(
  'device',
  {},
  {
    idAttribute: 'uuid',
  },
);

const DeviceListSchema = [DeviceSchema];

export interface SearchResult extends LocationModel {
  devices: DeviceModel[];
}

export interface SearchFilterOptions {
  page?: number;
  perPage?: number;
}

export interface SearchResultMeta {
  page?: number;
  perPage?: number;
  searchKey?: string;
  totalRecords?: number;
  currentPage?: number;
  nextPage?: string;
  prevPage?: string;
  totalPage?: number;
}

function useLocationDeviceFilterSearch(
  initialOptions = {
    page: 1,
    perPage: 5,
  },
) {
  const { isEquipmentEnabled } = useFeatures();
  const { dispatch, store } = useContext(StoreContext);
  const { filterRailSearchKey: searchKey } = store.pages.dashboard;
  const [options, setOptions] = useState<SearchFilterOptions>(initialOptions);
  const [data, setData] = useState<SearchResult[]>([]);
  const [meta, setMeta] = useState<SearchResultMeta>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { browseByDevice } = useDeviceService();

  const fetch = useCallback(
    debounce(async (searchKeyArg: string, optionsArg: SearchFilterOptions) => {
      const res = await LocationService.search(
        searchKeyArg,
        optionsArg,
        isEquipmentEnabled,
      );

      setIsLoading(false);
      dispatch({
        type: 'location-device:get_FINALLY',
      });
      dispatch({
        type: 'dashboard:filterRail:meta:set',
        data: res.metadata,
      });
      setData(res.data);
      setMeta(res.metadata);
    }, 1000),
    [],
  );

  const setPreloadedFilterRailSearch = async (deviceUUID: string) => {
    if (isUndefined(store.entities.devices.byUUID[deviceUUID])) {
      await browseByDevice(deviceUUID);
    }
    dispatch({
      type: 'dashboard:filterRail:search:set',
      data: store.entities.devices.byUUID[deviceUUID].serialNumber,
    });
  };

  useEffect(() => {
    dispatch({
      type: 'location-device:get_REQUEST',
    });
    setIsLoading(true);
    fetch(searchKey, options);
  }, [searchKey, options]);

  useEffect(() => {
    const locations = data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const devices = locations.reduce((acc: any[], location: any) => {
      return [...acc, ...location.devices];
    }, []);

    const normalizedDevices = normalize(devices, DeviceListSchema);
    dispatch({
      data: normalizedDevices,
      type: isEquipmentEnabled ? 'equipment:reset' : 'device:reset',
    });

    (async () => {
      if (data && data.length) {
        if (store.pages.dashboard.filters.preloaded) {
          await setPreloadedFilterRailSearch(
            store.pages.dashboard.filters.devices[0],
          );
        }

        dispatch({
          type: 'dashboard:deviceFilters:reset',
        });
      }
    })();

    const normalizedLocations = normalize(locations, LocationListSchema);

    dispatch({
      data: normalizedLocations,
      type: 'location:reset',
    });
  }, [data]);

  return {
    setOptions,
    data,
    meta,
    isLoading,
  };
}

export default useLocationDeviceFilterSearch;
export { useLocationDeviceFilterSearch };
