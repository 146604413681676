import React, { ReactNode } from 'react';
import { Loader } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import { Text } from '../text';

interface ButtonProps {
  as?: 'filled' | 'outline' | 'text';
  title?: string;
  onClick: (...args: any[]) => void;
  children?: ReactNode;
  disabled?: boolean;
  hideOutline?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
}

const primaryHover = '#97DBF8';
const primaryDisabled = '#6A7070';

const buttonStyles = css`
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid transparent;
  cursor: pointer;
`;

const FilledButton = styled.button`
  ${buttonStyles}
  background: var(--new-primary-color);

  &:disabled {
    background: rgba(211, 229, 240, 0.12);
    color: ${primaryDisabled};
    &:hover {
      background: rgba(211, 229, 240, 0.12);
      color: ${primaryDisabled};
    }
  }

  &:hover {
    background: ${primaryHover};
  }
`;

const OutlineButton = styled.button<{ hideOutline?: boolean }>`
  ${buttonStyles}
  background: transparent;
  border: 1px solid var(--new-primary-color);
  color: var(--new-primary-color);

  &:disabled {
    border-color: ${primaryDisabled};
    color: ${primaryDisabled};
    &:hover {
      border-color: ${primaryDisabled};
      color: ${primaryDisabled};
    }
  }

  &:hover {
    border-color: ${primaryHover};
    color: ${primaryHover};
  }

  /* Hide border override */
  border-width: ${({ hideOutline }) => hideOutline && '0px'};
`;

const TextButton = styled.button`
  ${buttonStyles}
  background: transparent;
  color: var(--new-primary-color);

  &:disabled {
    color: ${primaryDisabled};
    opacity: 50%;
    cursor: not-allowed;
    &:hover {
      color: ${primaryDisabled};
    }
  }

  &:hover {
    color: ${primaryHover};
  }
`;

const ButtonStyled = ({
  as = 'filled',
  title = '',
  children,
  loading = false,
  style,
  ...props
}: ButtonProps) => {
  const content = (
    <div style={{ display: 'flex', position: 'relative', ...style }}>
      {loading && (
        <div style={{ height: '20px' }}>
          <EnergyButtonLoader active />
        </div>
      )}
      <Text useNew asNew="button">
        {children || title}
      </Text>
    </div>
  );

  if (as === 'outline') {
    return (
      <OutlineButton {...props} style={style}>
        {content}
      </OutlineButton>
    );
  } else if (as === 'text') {
    return (
      <TextButton {...props} style={style}>
        {content}
      </TextButton>
    );
  } else {
    return (
      <FilledButton {...props} style={style}>
        {content}
      </FilledButton>
    );
  }
};

export const Button = (props: ButtonProps) => {
  return <ButtonStyled {...props} />;
};

const EnergyButtonLoader = styled(Loader)`
  && {
    position: relative;
    top: 60%;

    ::after {
      border-color: rgba(255, 255, 255, 0.8) transparent transparent;
    }

    ::before,
    ::after {
      width: 1.9rem;
      height: 1.9rem;
    }
  }
`;
