import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export interface AppLogoProps {
  width?: string;
  height?: string;
  heightIE: string;
  color?: string;
  vertical?: boolean;
}

const LogoSvg = styled.svg<{ $heightIE: string }>`
  margin-top: 12px;
  @media all and (-ms-high-contrast: none) {
    height: ${({ $heightIE }) => $heightIE};
  }
`;

export const AppLogo: FunctionComponent<AppLogoProps> = (props) => {
  const {
    width = '200',
    heightIE,
    color = '#8DD2CC',
    vertical = false,
  } = props;

  return (
    <span>
      {vertical ? (
        <LogoSvg
          className="therma-logo"
          width={width}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 147 39"
          preserveAspectRatio="none"
          $heightIE={heightIE}
        >
          <g clipPath="url(#clip0_3404_5485)">
            <path
              d="M8.14 30.22H15.53C15.57 31.36 15.44 32.44 15.13 33.45C14.82 34.46 14.34 35.36 13.7 36.14C13.05 36.92 12.25 37.53 11.29 37.97C10.33 38.41 9.24 38.63 8.01 38.63C6.85 38.63 5.79 38.44 4.81 38.05C3.83 37.66 2.98 37.12 2.26 36.42C1.54 35.72 0.98 34.89 0.59 33.91C0.2 32.94 0 31.88 0 30.72C0 29.56 0.2 28.5 0.61 27.54C1.02 26.58 1.58 25.75 2.31 25.07C3.04 24.38 3.89 23.86 4.88 23.49C5.87 23.12 6.94 22.93 8.09 22.93C9.12 22.93 10.04 23.07 10.86 23.36C11.68 23.65 12.41 24.04 13.04 24.55C13.68 25.06 14.22 25.66 14.68 26.36L12.07 27.88C11.7 27.22 11.18 26.69 10.52 26.28C9.86 25.87 9.05 25.67 8.09 25.67C7.19 25.67 6.37 25.87 5.63 26.28C4.89 26.69 4.3 27.27 3.86 28.03C3.42 28.79 3.21 29.69 3.21 30.73C3.21 31.77 3.42 32.66 3.85 33.44C4.28 34.22 4.86 34.82 5.6 35.25C6.34 35.68 7.17 35.89 8.09 35.89C8.7 35.89 9.25 35.81 9.74 35.64C10.23 35.48 10.64 35.24 10.99 34.94C11.34 34.64 11.64 34.3 11.88 33.91C12.12 33.52 12.31 33.1 12.44 32.65H8.14V30.21V30.22Z"
              fill={color}
            />
            <path
              d="M21.69 23.24V35.55H27.67V38.23H18.63V23.24H21.69Z"
              fill={color}
            />
            <path
              d="M34.42 33.26L34.48 33.95L32.51 38.23H29.06L36.66 22.36L44.26 38.23H40.79L38.86 34.08L38.9 33.31L36.65 28.11L34.4 33.27L34.42 33.26ZM33.11 32.41H40.31L40.67 34.83H32.75L33.11 32.41Z"
              fill={color}
            />
            <path
              d="M48.99 33.32C49.44 34.06 50.03 34.62 50.76 35.01C51.49 35.4 52.28 35.6 53.14 35.6C53.85 35.6 54.49 35.51 55.05 35.32C55.61 35.13 56.11 34.88 56.56 34.57C57.01 34.26 57.4 33.91 57.73 33.54V36.99C57.12 37.48 56.45 37.85 55.73 38.13C55.01 38.4 54.1 38.54 53 38.54C51.9 38.54 50.8 38.35 49.82 37.98C48.84 37.61 47.99 37.07 47.27 36.37C46.55 35.67 45.99 34.85 45.6 33.9C45.21 32.95 45.01 31.9 45.01 30.74C45.01 29.58 45.21 28.53 45.6 27.58C45.99 26.63 46.55 25.81 47.27 25.11C47.99 24.41 48.84 23.88 49.82 23.5C50.8 23.13 51.86 22.94 53 22.94C54.14 22.94 55.01 23.08 55.73 23.35C56.45 23.62 57.12 24 57.73 24.49V27.94C57.4 27.57 57.01 27.23 56.56 26.91C56.11 26.6 55.61 26.35 55.05 26.16C54.49 25.97 53.86 25.88 53.14 25.88C52.28 25.88 51.49 26.08 50.76 26.47C50.03 26.86 49.44 27.42 48.99 28.15C48.54 28.88 48.32 29.74 48.32 30.74C48.32 31.74 48.54 32.59 48.99 33.32Z"
              fill={color}
            />
            <path d="M64.46 23.24V38.23H61.35V23.24H64.46Z" fill={color} />
            <path
              d="M71.25 23.24V38.23H68.29V23.24H71.25ZM70.27 23.24H77.83V25.83H70.27V23.24ZM70.27 29H77.4V31.55H70.27V29ZM70.27 35.64H77.83V38.23H70.27V35.64Z"
              fill={color}
            />
            <path
              d="M84.51 23.24V38.23H81.45V23.24H84.51ZM83.31 23.24H86.48C87.66 23.24 88.67 23.44 89.5 23.84C90.33 24.24 90.96 24.8 91.41 25.51C91.85 26.22 92.07 27.06 92.07 28.02C92.07 28.98 91.85 29.79 91.41 30.52C90.97 31.24 90.33 31.8 89.5 32.2C88.67 32.6 87.67 32.8 86.48 32.8H83.31V30.36H86.29C86.83 30.36 87.3 30.27 87.69 30.09C88.08 29.91 88.39 29.65 88.6 29.32C88.81 28.98 88.92 28.58 88.92 28.11C88.92 27.64 88.81 27.24 88.6 26.9C88.39 26.56 88.08 26.31 87.69 26.13C87.3 25.95 86.83 25.86 86.29 25.86H83.31V23.25V23.24ZM88.08 31.25L92.96 38.23H89.34L84.84 31.25H88.07H88.08Z"
              fill={color}
            />
            <path
              d="M102.6 30.22H109.99C110.03 31.36 109.9 32.44 109.59 33.45C109.28 34.46 108.8 35.36 108.16 36.14C107.51 36.92 106.71 37.53 105.75 37.97C104.79 38.41 103.7 38.63 102.47 38.63C101.31 38.63 100.25 38.44 99.27 38.05C98.29 37.66 97.44 37.12 96.72 36.42C96 35.72 95.44 34.89 95.05 33.91C94.66 32.94 94.46 31.88 94.46 30.72C94.46 29.56 94.66 28.5 95.07 27.54C95.48 26.58 96.04 25.75 96.77 25.07C97.5 24.38 98.35 23.86 99.34 23.49C100.33 23.12 101.4 22.93 102.55 22.93C103.58 22.93 104.5 23.07 105.32 23.36C106.14 23.65 106.87 24.04 107.5 24.55C108.14 25.06 108.68 25.66 109.14 26.36L106.53 27.88C106.16 27.22 105.64 26.69 104.98 26.28C104.32 25.87 103.51 25.67 102.55 25.67C101.65 25.67 100.83 25.87 100.09 26.28C99.35 26.69 98.76 27.27 98.32 28.03C97.88 28.79 97.67 29.69 97.67 30.73C97.67 31.77 97.88 32.66 98.31 33.44C98.74 34.22 99.32 34.82 100.06 35.25C100.8 35.68 101.63 35.89 102.55 35.89C103.16 35.89 103.71 35.81 104.2 35.64C104.69 35.48 105.1 35.24 105.45 34.94C105.8 34.64 106.1 34.3 106.34 33.91C106.58 33.52 106.77 33.1 106.9 32.65H102.6V30.21V30.22Z"
              fill={color}
            />
            <path
              d="M116.16 23.24V38.23H113.1V23.24H116.16ZM114.96 23.24H118.13C119.31 23.24 120.32 23.44 121.15 23.84C121.98 24.24 122.61 24.8 123.06 25.51C123.5 26.22 123.72 27.06 123.72 28.02C123.72 28.98 123.5 29.79 123.06 30.52C122.62 31.24 121.98 31.8 121.15 32.2C120.32 32.6 119.32 32.8 118.13 32.8H114.96V30.36H117.94C118.48 30.36 118.95 30.27 119.34 30.09C119.73 29.91 120.04 29.65 120.25 29.32C120.46 28.98 120.57 28.58 120.57 28.11C120.57 27.64 120.46 27.24 120.25 26.9C120.04 26.56 119.73 26.31 119.34 26.13C118.95 25.95 118.48 25.86 117.94 25.86H114.96V23.25V23.24ZM119.74 31.25L124.62 38.23H121L116.5 31.25H119.73H119.74Z"
              fill={color}
            />
            <path d="M130.08 23.24V38.23H126.97V23.24H130.08Z" fill={color} />
            <path
              d="M137.04 23.24V38.23H133.91V23.24H137.04ZM135.88 38.23V35.45H138.9C139.56 35.45 140.18 35.36 140.76 35.18C141.35 35 141.86 34.72 142.3 34.33C142.74 33.94 143.09 33.46 143.34 32.86C143.59 32.27 143.71 31.56 143.71 30.73C143.71 29.9 143.58 29.19 143.34 28.6C143.09 28.01 142.74 27.52 142.3 27.13C141.86 26.74 141.34 26.46 140.76 26.28C140.17 26.1 139.55 26.01 138.9 26.01H135.88V23.23H139.01C140.58 23.23 141.95 23.54 143.13 24.15C144.31 24.76 145.23 25.63 145.88 26.75C146.54 27.87 146.87 29.19 146.87 30.72C146.87 32.25 146.54 33.57 145.88 34.69C145.22 35.81 144.31 36.68 143.13 37.29C141.95 37.9 140.58 38.21 139.01 38.21H135.88V38.23Z"
              fill={color}
            />
            <path
              d="M79.96 15.4L71.89 6.04H62.11L59.83 9.35H70.37L72.73 12.08H54.33L60.44 3.31H69.8L67.09 0H58.71L47.98 15.4H79.96Z"
              fill={color}
            />
            <path
              d="M70 0L78.07 9.35H87.85L90.13 6.04H79.59L77.23 3.31H95.64L89.52 12.08H80.16L82.87 15.39H91.25L101.98 0H70Z"
              fill={color}
            />
          </g>
          <defs>
            <clipPath id="clip0_3404_5485">
              <rect width="146.87" height="38.64" fill="white" />
            </clipPath>
          </defs>
        </LogoSvg>
      ) : (
        <LogoSvg
          className="therma-logo"
          width={width}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 377 30"
          preserveAspectRatio="none"
          $heightIE={heightIE}
        >
          <path
            d="M117.622 14.4884H131.435C131.516 16.5947 131.266 18.5814 130.693 20.4485C130.12 22.3156 129.224 23.9668 128.011 25.402C126.794 26.8372 125.295 27.9601 123.505 28.7774C121.716 29.5914 119.674 30 117.379 30C115.216 30 113.221 29.6445 111.394 28.9336C109.564 28.2226 107.977 27.2226 106.629 25.9336C105.281 24.6445 104.24 23.1063 103.505 21.3156C102.771 19.5249 102.403 17.5648 102.403 15.4352C102.403 13.3056 102.784 11.3488 103.546 9.57475C104.307 7.79734 105.369 6.27907 106.73 5.01661C108.092 3.75415 109.692 2.78073 111.536 2.09635C113.379 1.41196 115.381 1.06977 117.541 1.06977C119.462 1.06977 121.19 1.33223 122.727 1.86046C124.26 2.3887 125.622 3.11628 126.811 4.05316C127.997 4.98671 129.019 6.09967 129.874 7.3887L124.988 10.1927C124.294 8.98339 123.327 8.00332 122.087 7.25249C120.847 6.50166 119.33 6.12625 117.541 6.12625C115.859 6.12625 114.323 6.50166 112.938 7.25249C111.549 8.00332 110.447 9.07641 109.635 10.4684C108.82 11.8638 108.415 13.5216 108.415 15.4419C108.415 17.3621 108.816 19 109.615 20.4352C110.417 21.8704 111.502 22.9801 112.877 23.7708C114.252 24.5615 115.805 24.9535 117.541 24.9535C118.687 24.9535 119.718 24.804 120.624 24.4983C121.531 24.196 122.313 23.7674 122.966 23.2159C123.62 22.6644 124.173 22.0332 124.628 21.3223C125.083 20.6113 125.43 19.8339 125.669 18.9934H117.622V14.495V14.4884Z"
            fill={color}
          />
          <path
            d="M142.966 1.61794V24.3156H154.138V29.2492H137.241V1.61794H142.966Z"
            fill={color}
          />
          <path
            d="M166.751 20.093L166.872 21.3555L163.189 29.2492H156.742L170.957 0L185.171 29.2492H178.684L175.081 21.5914L175.162 20.1694L170.957 10.5781L166.751 20.0897V20.093ZM164.308 18.5149H177.76L178.441 22.9767H163.627L164.308 18.5149Z"
            fill={color}
          />
          <path
            d="M194 20.1927C194.842 21.5482 195.941 22.588 197.302 23.3123C198.663 24.0365 200.146 24.3987 201.747 24.3987C203.081 24.3987 204.268 24.2259 205.309 23.887C206.35 23.5448 207.29 23.0831 208.133 22.505C208.972 21.9269 209.7 21.2957 210.316 20.6113V26.9668C209.167 27.8605 207.92 28.5581 206.573 29.0598C205.225 29.5581 203.523 29.8106 201.467 29.8106C199.412 29.8106 197.349 29.4684 195.523 28.7841C193.693 28.0997 192.106 27.113 190.758 25.8239C189.41 24.5349 188.369 23.0166 187.634 21.2658C186.899 19.515 186.532 17.5748 186.532 15.4419C186.532 13.309 186.899 11.3688 187.634 9.61794C188.369 7.86711 189.41 6.34884 190.758 5.0598C192.106 3.77076 193.693 2.78405 195.523 2.09967C197.353 1.41528 199.334 1.07309 201.467 1.07309C203.6 1.07309 205.225 1.32226 206.573 1.82392C207.92 2.32558 209.167 3.02326 210.316 3.91694V10.2724C209.703 9.58804 208.975 8.95681 208.133 8.37874C207.29 7.80066 206.35 7.33887 205.309 6.99668C204.268 6.65449 203.081 6.48505 201.747 6.48505C200.146 6.48505 198.663 6.84718 197.302 7.57143C195.941 8.29568 194.839 9.3289 194 10.6711C193.16 12.0133 192.739 13.6047 192.739 15.4485C192.739 17.2924 193.16 18.8505 194 20.206V20.1927Z"
            fill={color}
          />
          <path
            d="M222.927 1.61794V29.2492H217.12V1.61794H222.927Z"
            fill={color}
          />
          <path
            d="M235.621 1.61794V29.2492H230.094V1.61794H235.621ZM233.777 1.61794H247.911V6.39535H233.777V1.61794ZM233.777 12.2359H247.112V16.9336H233.777V12.2359ZM233.777 24.4751H247.911V29.2525H233.777V24.4751Z"
            fill={color}
          />
          <path
            d="M260.403 1.61794V29.2492H254.677V1.61794H260.403ZM258.162 1.61794H264.086C266.3 1.61794 268.184 1.98671 269.731 2.72425C271.277 3.46179 272.467 4.48837 273.296 5.80399C274.121 7.1196 274.536 8.66113 274.536 10.4219C274.536 12.1827 274.121 13.691 273.296 15.0199C272.467 16.3488 271.281 17.3821 269.731 18.1196C268.184 18.8571 266.3 19.2259 264.086 19.2259H258.162V14.7243H263.725C264.74 14.7243 265.613 14.5615 266.347 14.2292C267.082 13.9003 267.648 13.4252 268.049 12.8073C268.45 12.1894 268.649 11.4452 268.649 10.5781C268.649 9.71096 268.45 8.96678 268.049 8.34884C267.648 7.7309 267.082 7.25581 266.347 6.92691C265.613 6.59801 264.74 6.43189 263.725 6.43189H258.162V1.61794ZM267.092 16.3821L276.221 29.2492H269.454L261.046 16.3821H267.092Z"
            fill={color}
          />
          <path
            d="M294.236 14.4884H308.049C308.13 16.5947 307.881 18.5814 307.308 20.4485C306.735 22.3156 305.839 23.9668 304.625 25.402C303.409 26.8372 301.909 27.9601 300.12 28.7774C298.331 29.5914 296.288 30 293.994 30C291.83 30 289.835 29.6445 288.009 28.9336C286.179 28.2226 284.592 27.2226 283.244 25.9336C281.896 24.6445 280.854 23.1063 280.12 21.3156C279.385 19.5249 279.018 17.5648 279.018 15.4352C279.018 13.3056 279.399 11.3488 280.16 9.57475C280.922 7.79734 281.983 6.27907 283.345 5.01661C284.706 3.75415 286.307 2.78073 288.15 2.09635C289.994 1.41196 291.995 1.06977 294.155 1.06977C296.076 1.06977 297.805 1.33223 299.342 1.86046C300.875 2.3887 302.236 3.11628 303.426 4.05316C304.612 4.98671 305.633 6.09967 306.489 7.3887L301.603 10.1927C300.909 8.98339 299.941 8.00332 298.701 7.25249C297.461 6.50166 295.945 6.12625 294.155 6.12625C292.474 6.12625 290.937 6.50166 289.552 7.25249C288.164 8.00332 287.062 9.07641 286.25 10.4684C285.434 11.8638 285.03 13.5216 285.03 15.4419C285.03 17.3621 285.431 19 286.229 20.4352C287.031 21.8704 288.117 22.9801 289.491 23.7708C290.866 24.5615 292.42 24.9535 294.155 24.9535C295.304 24.9535 296.332 24.804 297.239 24.4983C298.145 24.196 298.927 23.7674 299.581 23.2159C300.235 22.6644 300.787 22.0332 301.242 21.3223C301.697 20.6113 302.044 19.8339 302.283 18.9934H294.236V14.495V14.4884Z"
            fill={color}
          />
          <path
            d="M319.581 1.61794V29.2492H313.856V1.61794H319.581ZM317.34 1.61794H323.264C325.478 1.61794 327.362 1.98671 328.909 2.72425C330.455 3.46179 331.645 4.48837 332.471 5.80399C333.296 7.1196 333.711 8.66113 333.711 10.4219C333.711 12.1827 333.296 13.691 332.471 15.0199C331.642 16.3488 330.455 17.3821 328.909 18.1196C327.362 18.8571 325.478 19.2259 323.264 19.2259H317.34V14.7243H322.907C323.921 14.7243 324.794 14.5615 325.529 14.2292C326.263 13.9003 326.83 13.4252 327.231 12.8073C327.632 12.1894 327.83 11.4452 327.83 10.5781C327.83 9.71096 327.632 8.96678 327.231 8.34884C326.83 7.7309 326.263 7.25581 325.529 6.92691C324.794 6.59801 323.921 6.43189 322.907 6.43189H317.34V1.61794ZM326.27 16.3821L335.399 29.2492H328.632L320.225 16.3821H326.27Z"
            fill={color}
          />
          <path
            d="M345.606 1.61794V29.2492H339.8V1.61794H345.606Z"
            fill={color}
          />
          <path
            d="M358.621 1.61794V29.2492H352.774V1.61794H358.621ZM356.457 29.2492V24.1163H362.102C363.328 24.1163 364.491 23.9535 365.586 23.6213C366.681 23.2924 367.642 22.7741 368.468 22.0631C369.293 21.3522 369.944 20.4518 370.409 19.3588C370.874 18.2658 371.109 16.9568 371.109 15.4319C371.109 13.907 370.877 12.598 370.409 11.505C369.94 10.412 369.293 9.51163 368.468 8.80066C367.639 8.0897 366.678 7.57143 365.586 7.24253C364.491 6.91362 363.332 6.74751 362.102 6.74751H356.457V1.61794H362.304C365.239 1.61794 367.81 2.18272 370.011 3.31562C372.215 4.44851 373.927 6.04651 375.157 8.11296C376.383 10.1794 377 12.6179 377 15.4352C377 18.2525 376.387 20.691 375.157 22.7575C373.93 24.8239 372.215 26.4219 370.011 27.5548C367.81 28.6877 365.239 29.2525 362.304 29.2525H356.457V29.2492Z"
            fill={color}
          />
          <path
            d="M59.8016 29.5781L44.7046 12.3389H26.413L22.1602 18.4452H41.8672L46.2716 23.4718H11.8687L23.2959 7.30897H40.8057L35.7374 1.20266H20.0642L0 29.5781H59.8016Z"
            fill={color}
          />
          <path
            d="M41.1663 1.20266L56.2632 18.4452H74.5515L78.8076 12.3389H59.0973L54.6963 7.30897H89.0992L77.672 23.4718H60.1622L65.2304 29.5781H80.9037L100.968 1.20266H41.1663Z"
            fill={color}
          />
        </LogoSvg>
      )}
    </span>
  );
};
