import React from 'react';
import { toast } from 'react-toastify';
import { ToastOptions } from 'react-toastify/dist/types';
import { Icon } from 'semantic-ui-react';

export const snackbar = {
  /**
   * Opens a toast and closes after 4s
   */
  open: (message: string, options?: ToastOptions) => {
    toast(message, {
      toastId: 'base-toast',
      position: 'bottom-center',
      hideProgressBar: true,
      autoClose: 4000,
      closeOnClick: true,
      closeButton: (
        <Icon
          className="icon-close"
          style={{ display: 'flex', alignSelf: 'center' }}
        />
      ),
      ...options,
    });
  },
};
