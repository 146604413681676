// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
  darkViolet80,
  grey25,
  grey100,
  greyGeyser45,
  white100,
} from '@coinspect/ui';
import inflection from 'lodash-inflection';
import map from 'lodash/map';
import React, { FunctionComponent, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { useDialogs, useTeamService } from '../../hooks';
import { CreateTeam, TeamModel } from '../../services';
import { TeamPageData } from '../pages/user-page';
import { Tooltip } from '../tooltip';
import { TeamForm } from '../users';

interface TeamListOptions {
  setTeamPageData: (data: TeamPageData) => void;
  setCurrentTeamTotal: (data: number) => void;
  pageData: TeamPageData;
  filteredTeams: TeamModel[];
}
interface TeamListItemsProps {
  team: TeamModel;
  options: TeamListOptions;
}

const TeamListItemStyle = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 20px 24px;
  margin: 10px 0;
  line-height: 36px;
  color: ${darkViolet80};
  border-bottom: 1px solid ${greyGeyser45};
  background: ${white100};
  border-radius: 5px;
  box-shadow: 0 0 4px 0 ${grey25};
  &:last-child {
    border-bottom: 0;
  }
`;

const NameArea = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionsArea = styled.div`
  align-self: center;
  min-width: 100px;
  max-width: auto;
`;

const TeamName = styled.h2`
  font-size: 16px;
  color: ${darkViolet80};
  margin: 0;
  padding: 0;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TeamNumbers = styled.span`
  color: ${grey100};
  line-height: 1.2em;
  padding-top: 5px;
  font-size: 14px;
`;

export const TeamListItem: FunctionComponent<TeamListItemsProps> = (props) => {
  const { team, options } = props;
  const {
    setTeamPageData,
    setCurrentTeamTotal,
    pageData,
  }: TeamListOptions = options;
  const [isEditing, setIsEditing] = useState(false);
  const { openDeleteDialog, closeDialog } = useDialogs();
  const {
    deleteTeam,
    editTeam,
    hasActiveForm,
    toggleActiveForm,
  } = useTeamService();

  const handleTeamDelete = () => {
    openDeleteDialog({
      content: (
        <>
          Are you sure you want to delete <strong>{team.name}</strong>?
        </>
      ),
      onConfirm: async () => {
        try {
          await deleteTeam(team.uuid);
          setCurrentTeamTotal(pageData.total - 1);
          setTeamPageData({
            ...pageData,
            page: pageData.page - 1,
            total: pageData.total - 1,
          });
          closeDialog();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Error in deleting team: ${error}`);
        }
      },
      disableButtonsOnQuery: 'teams:delete',
    });
  };

  function toggleEditor(state: boolean) {
    toggleActiveForm();
    setIsEditing(state);
  }

  async function handleTeamEdit(uuid: string, data: CreateTeam) {
    try {
      await editTeam(uuid, data);
      toggleEditor(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error in editing team: ${error}`);
    }
  }

  return isEditing ? (
    <TeamForm
      onSubmit={(data) => handleTeamEdit(team.uuid, data)}
      onCancel={() => {
        toggleEditor(false);
      }}
      team={{
        ...team,
        locations: map(team.locations, 'locationUUID'),
        members: map(team.members, 'userUUID'),
      }}
    />
  ) : (
    <TeamListItemStyle className="team-list-item">
      <NameArea className="ellipse-text team-list-item__info">
        <TeamName className="team-list-item__info--name">{team.name}</TeamName>
        <TeamNumbers className="team-list-item__info--locationNumber">
          {inflection.pluralize('Location', team.locations.length, true)}
        </TeamNumbers>
        <TeamNumbers className="team-list-item__info--members">
          {inflection.pluralize('Member', team.members.length, true)}
        </TeamNumbers>
      </NameArea>
      <ActionsArea>
        <Tooltip
          content="Delete"
          trigger={
            <Button
              floated="right"
              circular
              icon
              disabled={hasActiveForm()}
              onClick={handleTeamDelete}
              className="therma-off-white team-list-item__delete-button"
            >
              <Icon
                className="icon-delete"
                size="large"
                aria-label="Delete User"
              />
            </Button>
          }
        />
        <Tooltip
          content="Edit"
          trigger={
            <Button
              floated="right"
              circular
              icon
              disabled={hasActiveForm()}
              onClick={() => {
                toggleEditor(true);
              }}
              className="therma-off-white team-list-item__edit-button"
            >
              <Icon className="icon-edit" size="large" aria-label="Edit User" />
            </Button>
          }
        />
      </ActionsArea>
    </TeamListItemStyle>
  );
};
